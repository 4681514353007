import React from 'react';

import { Stepper, Step, StepLabel } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';

import './promoCheckout.css';

function CTAWelcomeComponent({ email }) {

  return (<>
    <div className="cta-welcome">
      <Stepper activeStep={3} className="cta-mobile-stepper">
        <Step key={1} completed={false}>
          <StepLabel></StepLabel>
        </Step>
        <Step key={2} completed={false}>
          <StepLabel></StepLabel>
        </Step>
        <Step key={3} completed={false}>
          <StepLabel></StepLabel>
        </Step>
      </Stepper>
      <CheckIcon className="cta-welcome-mark" />
      <div className="cta-welcome-caption"><span>Welcome to Sportsbox 3DGolf</span></div>
      <div>
        An email with your login credentials is sent to: <b>{email}</b>.
        <br /><br />
        Click the icon below to download the app.
      </div>
      <div className="cta-welcome-apps">
        <a href="https://apps.apple.com/us/app/sportsbox-3dgolf/id1578921026">
          <img
            alt="Apple"
            src="/images/offer/apple.svg"
            width={170}
          />
        </a>
        <a href="https://play.google.com/store/apps/details?id=com.sportsbox.golfai">
          <img
            alt="Google"
            src="/images/offer/google.svg"
            width={170}
          />
        </a>
      </div>
       <div className="cta-invitational">
        <img
          alt="Invitational"
          src="/images/offer/golf_pass.png"
          width={350}
        />
      </div>
    </div>
  </>);
}

export const CTAWelcome = CTAWelcomeComponent;