import React from "react";

import { Stepper, Step, StepLabel, Button } from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";

import "./offerPaid.css";

function CTAWelcomeComponent({ email, product }) {
  return (
    <>
      <div className="cta-welcome">
        <Stepper activeStep={2} className="cta-mobile-stepper">
          <Step key={1} completed={false}>
            <StepLabel></StepLabel>
          </Step>
          <Step key={2} completed={false}>
            <StepLabel></StepLabel>
          </Step>
          <Step key={2} completed={false}>
            <StepLabel></StepLabel>
          </Step>
        </Stepper>
        <CheckIcon className="cta-welcome-mark" />
        <div className="cta-welcome-caption">
          <span>Welcome to Sportsbox</span>
        </div>
        <div>
          A confirmation email has been sent to: <b>{email}</b>.
          <br />
          <br />
          {product.includes("studio_offer") ? (
            <>
              Book your demo TODAY and experience the power of Sportsbox 3D
              Studio!&nbsp; <br />
              <br />
              <Button
                variant="contained"
                color="secondary"
                href="https://meetings.hubspot.com/edwin-fuh/studio-demos-from-website"
                target="_blank"
                rel="noopener noreferrer"
              >
                BOOK A DEMO
              </Button>
            </>
          ) : (
            "Complete your Sportsbox journey today by downloading our app. Log into the app with the email you signed up with."
          )}
        </div>
        <div className="cta-welcome-apps">
          <a href="https://apps.apple.com/us/app/sportsbox-3dgolf/id1578921026">
            <img alt="Apple" src="/images/offer/apple.svg" width={170} />
          </a>
          <a href="https://play.google.com/store/apps/details?id=com.sportsbox.golfai">
            <img alt="Google" src="/images/offer/google.svg" width={170} />
          </a>
        </div>
      </div>
    </>
  );
}

export const CTAWelcome = CTAWelcomeComponent;
