import React, { forwardRef, useState, useEffect } from 'react';

import { Box, Card, CardContent, CircularProgress, Container, Grid, Typography } from '@material-ui/core';
import {
  AddBox,
  ArrowDownward,
  Check,
  ChevronLeft,
  ChevronRight,
  Clear,
  DeleteOutline,
  Edit,
  FilterList,
  FirstPage,
  LastPage,
  Remove,
  SaveAlt,
  Search,
  ViewColumn,
  Receipt,
} from "@material-ui/icons";
import MaterialTable from "material-table";
import Swal from 'sweetalert2';
import moment from 'moment';
import { CsvBuilder } from 'filefy';
import jsPDF from 'jspdf';
import firebase, { functionBaseUrl, axiosWithToken } from '../../common/firebase';
import { StripePayment } from '../../components/stripePayment';

const tableIcons = {
  ExportCSV: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  ExportPDF: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => (
    <ArrowDownward {...props} ref={ref} />
  )),
  ThirdStateCheck: forwardRef((props, ref) => (
    <Remove {...props} ref={ref} />
  )),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  Receipt: forwardRef((props, ref) => <Receipt {...props} ref={ref} />),
};

const tableHeader = [
  { field: "paymentDate", title: "Payment Date",
    render: (rowData) => {
      return moment(String(rowData.paymentDate), "x").format("MM/DD/YYYY");
    }   
  },
  { field: "amount", title: "Amount",
    render: (rowData) => {
      return "$" + rowData?.amount
    }
  },
];

function LessonComponent({ history }) {
  const [isLoading, setIsLoading] = useState(true);
  const [payments, setPayments] = useState([]);

  const loadData = async () => {
    try {
      setIsLoading(true);
      const firebaseUser = firebase.auth().currentUser;
      let tempPayments = [];
      const paymentsSnap = await firebase.firestore().collection('lessonPayments').where('userId', '==', firebaseUser.uid).get();
      paymentsSnap.forEach(async function (doc) {
        tempPayments.push({ ...doc.data(), id: doc.id })
      });
      setPayments(tempPayments);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      Swal.fire({
        title: '<p style="font-size:70%;">Error loading data</p>',
        icon: 'error',
        confirmButtonText: 'Ok',
        allowOutsideClick: true,
      });
    }
  }

  useEffect(() => {
    loadData();
  }, [])

  async function handleResult(data) {
    const firebaseUser = firebase.auth().currentUser;
    const user = await firebase.firestore().collection("users").doc(firebaseUser.uid).get();
    if (!user && firebaseUser.uid !== "") {
      Swal.fire({
        title: '<p style="font-size:70%;">No user found</p>',
        icon: 'error',
        confirmButtonText: 'Ok',
        allowOutsideClick: true,
      });
    }
    const userData = user.data();
    await firebase.firestore().collection('lessonPayments').add({
      userId: firebaseUser.uid,
      userEmail: userData.email.toLowerCase().replace(/ /g, ''),
      paymentDate: Date.now(),
      status: 'Paid',
      amount: (data.amount / 100).toFixed(2),
      transactionId: data.id
    });

    await axiosWithToken(functionBaseUrl + '/api/userCoach', {
      method: 'POST',
      data: {
        coachEmail: "sportsboxlessons@gmail.com",
        email: userData.email.toLowerCase().replace(/ /g, ''),
      }
    }).catch(err => {
      console.log(err);
    })

    await axiosWithToken(functionBaseUrl + '/api/hubLessonUser', {
      method: 'POST',
      data: {
        firstName: userData.firstName,
        lastName: userData.lastName,
        email: userData.email.toLowerCase().replace(/ /g, ''),
        lessonsPaidFor: payments.length === 0 ? 1 : (payments.length + 1),
        subscriptionType: userData.subscriptionType,
      }
    }).catch(err => {
      console.log(err);
    })
    
    await Swal.fire({
      title: `<p style="font-size:70%;">Thank you for your payment!</p>`,
      showConfirmButton: false,
      icon: 'success',
      customClass : { 
        container : 'my-swal' 
      },
      timer: 2000,
      showCloseButton: true,
      allowOutsideClick: true
    }).then(async () => {
      await history.push('/dashboard');
    })
  }

  return (<>
    {isLoading && (
      <Container maxWidth="xs" style={{ textAlign: 'center', paddingTop: '50px' }}>
        <CircularProgress />
      </Container>
    )}
    {!isLoading && (
      <Container
        component="main"
        style={{ paddingTop: '50px' }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <h2 style={{ textAlign: 'center' }}>3D Lesson Payment</h2>
          </Grid>
          <Grid
            item
            xs={12}
          >
            <Grid container justify="center">
              <Grid
                item
                sm={6}
                xs={12}
              >
                <Box border={1} borderColor="primary.main" style={{ marginBottom: "5%" }}>
                  <Card>
                    <CardContent fullWidth>
                      <Typography style={{ textAlign: 'center', marginTop: 20, marginBottom: 20 }}>
                        <span style={{ fontSize: '20px' }}>Amount: <b>$35</b></span><br/>
                      </Typography>
                      <StripePayment amount={35} handleResult={handleResult} />
                    </CardContent>
                  </Card>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
          >
            <h4>History:</h4>
            <MaterialTable
              style={{ marginTop: "10px", marginBottom: "10px" }}
              icons={tableIcons}
              columns={tableHeader}
              title=""
              data={payments}
              options={{
                selection: true,
                sorting: true,
                search: true,
                paging: true,
                exportAllData: true,
                exportButton: true,
                pageSizeOptions: [5, 10, 20, 50, 100],
                exportCsv: (columns, data) => {
                  const columnTitles = columns.map(columnDef => columnDef.title);
                  const csvData = data.map(rowData =>
                    columns.map(columnDef => {
                      const col = tableHeader.find(c => c.field == columnDef.field);
                      if (col.render) {
                        return col.render(rowData);
                      }
                      return rowData[columnDef.field];
                    }),
                  );
                  const builder = new CsvBuilder('data.csv')
                    .setColumns(columnTitles)
                    .addRows(csvData)
                    .exportFile();
                  return builder;
                },
                exportPdf: (columns, data) => {
                  const doc = new jsPDF();
                  const columnTitles = columns.map(columnDef => columnDef.title);
                  const pdfData = data.map(rowData =>
                    columns.map(columnDef => {
                      const col = tableHeader.find(c => c.field == columnDef.field);
                      if (col.render) {
                        return col.render(rowData);
                      }
                      return rowData[columnDef.field];
                    }),
                  );
                  doc.autoTable({
                     head: [columnTitles],
                     body: pdfData,
                  });
                  doc.save(`data.pdf`);
                },
                rowStyle: (x) => {
                  if (x.tableData.id % 2) {
                    return { backgroundColor: "#f2f2f2" };
                  }
                },
              }}
            />
          </Grid>
        </Grid>
      </Container>
    )}
  </>);
}

export const Lesson = LessonComponent;