import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { AppBar, Toolbar, Divider, Container, Box, Button, Typography, IconButton, Menu, MenuItem } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { KeyboardArrowDown } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: 'none',
  },
  desktop: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  mobile: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
  menuButton: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
  toolbarContainer: {
    borderBottom: '1px solid #F2F4F7',
    background: '#FFFFFF',
    [theme.breakpoints.down('sm')]: {
      borderBottom: 'none',
      background: 'none',
      padding: 0,
      paddingTop: '20px',
    },
  },
  logInButton: {
    padding: '10px 18px',
    fontWeight: 600,
    background: '#5C0DEE',
    boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
    borderRadius: '8px',
    margin: '32px 0',
    color: '#FFFFFF',
    textTransform: 'none',
    textAlign: 'center',
    marginLeft: 'auto',
    '&:hover': {
      background: '#3c1585',
    },
  },
}));

const Topbar = (props) => {
  const { className, ...rest } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [activeMenu, setActiveMenu] = React.useState(0);

  const handleClick = (event, menu) => {
    setAnchorEl(event.currentTarget);
    setActiveMenu(menu);
  };

  const handleClose = () => {
    setActiveMenu(0);
    setAnchorEl(null);
  };

  const classes = useStyles();

  return (
    <AppBar
      {...rest}
      className={clsx(classes.root, className)}
      style={{ background: 'none' }}
      position="absolute"
    >
      <div
        style={{
          color: '#FFFFFF',
          background: 'linear-gradient(63.44deg, #5C0DEE 16.72%, #7836F4 83.39%)',
          padding: '14px',
          textAlign: 'center',
        }}
        className={classes.desktop}
      >
        <strong>For coaches</strong> Book a demo with a 3DGolf specialist. <a style={{ "color": "white" }} target="_blank" rel="noopener noreferrer" href="https://meetings.hubspot.com/edwin-fuh/demo-team"><u>Book now</u></a>
      </div>

      <Container
        component="main"
        maxWidth="xl"
        className={classes.toolbarContainer}
      >
        <Toolbar style={{ justifyContent: 'space-between' }}>
          <Typography
            variant="h6"
            noWrap
            component="a"
            href={window.location.href.includes('/sign') ? "https://www.sportsbox.ai/" : "/dashboard"}
            style={{ marginRight: '40px' }}
          >
            <img
              alt="Logo"
              src="/images/logos/Logo.svg"
              width={190}
              className={classes.desktop}
            />
            <img
              alt="Logo"
              src="/images/logos/LogoDarkmode.svg"
              height={43}
              className={classes.mobile}
              style={{ marginTop: '5px' }}
            />
          </Typography>
          <Box className={classes.desktop}>
            <Button aria-controls="about-menu" aria-haspopup="true" /*onMouseOver={(e) => handleClick(e, 1)}*/ onClick={(e) => handleClick(e, 1)}>
              About <KeyboardArrowDown />
            </Button>
            <Menu
              id="about-menu"
              anchorEl={anchorEl}
              getContentAnchorEl={null}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              open={activeMenu == 1}
              onClose={handleClose}
            //MenuListProps={{ onMouseLeave: handleClose }}
            >
              <MenuItem onClick={() => window.location.href = "https://www.sportsbox.ai/about"}>Company Info</MenuItem>
              <MenuItem onClick={() => window.location.href = "https://www.sportsbox.ai/demo"}>Product Demo</MenuItem>
              <MenuItem onClick={() => window.location.href = "https://www.sportsbox.ai/meet-the-team"}>Meet the Team</MenuItem>
              <MenuItem onClick={() => window.location.href = "https://www.sportsbox.ai/ambassadors"}>Ambassadors</MenuItem>
              <MenuItem onClick={() => window.location.href = "https://www.sportsbox.ai/in-the-news"}>In the News</MenuItem>
              <MenuItem onClick={() => window.location.href = "https://www.sportsbox.ai/press-releases"}>Press Releases</MenuItem>
            </Menu>
            <Button onClick={() => window.location.href = "https://www.sportsbox.ai/education"}>
              Education
            </Button>
            <Button aria-controls="find-coach-menu" aria-haspopup="true" /*onMouseOver={(e) => handleClick(e, 2)}*/ onClick={(e) => handleClick(e, 2)}>
              Find a Coach <KeyboardArrowDown />
            </Button>
            <Menu
              id="find-coach-menu"
              anchorEl={anchorEl}
              getContentAnchorEl={null}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              open={activeMenu == 2}
              onClose={handleClose}
            //MenuListProps={{ onMouseLeave: handleClose }}
            >
              <MenuItem onClick={() => window.location.href = "https://www.sportsbox.ai/directory"}>All 3DGolf Coaches</MenuItem>
              <MenuItem onClick={() => window.location.href = "https://www.sportsbox.ai/staff-coach-directory"}>Official Staff Members</MenuItem>
            </Menu>
            <Button onClick={() => window.location.href = "https://shop.sportsbox.ai/"}>
              Pro Shop
            </Button>
            <Button aria-controls="support-menu" aria-haspopup="true" /*onMouseOver={(e) => handleClick(e, 3)}*/ onClick={(e) => handleClick(e, 3)}>
              Support <KeyboardArrowDown />
            </Button>
            <Menu
              id="support-menu"
              anchorEl={anchorEl}
              getContentAnchorEl={null}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              open={activeMenu == 3}
              onClose={handleClose}
            //MenuListProps={{ onMouseLeave: handleClose }}
            >
              <MenuItem onClick={() => window.location.href = "https://help.sportsbox.ai/"}>Help Center</MenuItem>
              <MenuItem onClick={() => window.location.href = "https://21571110.hubspotpreview-na1.com/_hcms/preview/content/86361016749?_preview=true&from_buffer=false&portalId=21571110&preview_key=xLtOCRzg"}>Webinar Series</MenuItem>
              <MenuItem onClick={() => window.location.href = "https://www.sportsbox.ai/learning"}>Learning</MenuItem>
              <MenuItem onClick={() => window.location.href = "https://www.sportsbox.ai/contact"}>Contact</MenuItem>
            </Menu>
            <Button aria-controls="more-menu" aria-haspopup="true" /*onMouseOver={(e) => handleClick(e, 4)}*/ onClick={(e) => handleClick(e, 4)}>
              More <KeyboardArrowDown />
            </Button>
            <Menu
              id="more-menu"
              anchorEl={anchorEl}
              getContentAnchorEl={null}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              open={activeMenu == 4}
              onClose={handleClose}
            //MenuListProps={{ onMouseLeave: handleClose }}
            >
              <MenuItem onClick={() => window.location.href = "https://www.sportsbox.ai/student"}>For Players</MenuItem>
              <MenuItem onClick={() => window.location.href = "https://www.sportsbox.ai/coach"}>For Coaches</MenuItem>
              <MenuItem onClick={() => window.location.href = "https://3dgolf.sportsbox.ai/sign-in"}>Login</MenuItem>
            </Menu>
          </Box>
          <Button className={clsx(classes.logInButton, classes.desktop)} href={window.location.href.includes('sign-up') ? (window.location.href.includes('?') ? ("/sign-in?" + window.location.href.split("?")[1]) : "/sign-in") : (window.location.href.includes('?') ? ("/account?" + window.location.href.split("?")[1]) : "/account")}>
            {window.location.href.includes('sign-up') ? "Log in" : "Account"}
          </Button>
          <IconButton
            edge="end"
            className={classes.menuButton}
            color="inherit"
            aria-label="open drawer"
            aria-controls="mobile-menu"
            aria-haspopup="true"
            onClick={(e) => handleClick(e, 5)}
          >
            <MenuIcon />
          </IconButton>
          <Menu
              id="mobile-menu"
              anchorEl={anchorEl}
              getContentAnchorEl={null}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              open={activeMenu == 5}
              onClose={handleClose}
            >
              <h5>About</h5>
              <MenuItem onClick={() => window.location.href = "https://www.sportsbox.ai/about"}>Company Info</MenuItem>
              <MenuItem onClick={() => window.location.href = "https://www.sportsbox.ai/demo"}>Product Demo</MenuItem>
              <MenuItem onClick={() => window.location.href = "https://www.sportsbox.ai/meet-the-team"}>Meet the Team</MenuItem>
              <MenuItem onClick={() => window.location.href = "https://www.sportsbox.ai/ambassadors"}>Ambassadors</MenuItem>
              <MenuItem onClick={() => window.location.href = "https://www.sportsbox.ai/in-the-news"}>In the News</MenuItem>
              <MenuItem onClick={() => window.location.href = "https://www.sportsbox.ai/press-releases"}>Press Releases</MenuItem>
              <Divider />
              <MenuItem onClick={() => window.location.href = "https://www.sportsbox.ai/education"}>Education</MenuItem>
              <Divider />
              <h5>Find a Coach</h5>
              <MenuItem onClick={() => window.location.href = "https://www.sportsbox.ai/directory"}>All 3DGolf Coaches</MenuItem>
              <MenuItem onClick={() => window.location.href = "https://www.sportsbox.ai/staff-coach-directory"}>Official Staff Members</MenuItem>
              <Divider />
              <MenuItem onClick={() => window.location.href = "https://shop.sportsbox.ai/"}>Pro Shop</MenuItem>
              <Divider />
              <h5>Support</h5>
              <MenuItem onClick={() => window.location.href = "https://help.sportsbox.ai/"}>Help Center</MenuItem>
              <MenuItem onClick={() => window.location.href = "https://21571110.hubspotpreview-na1.com/_hcms/preview/content/86361016749?_preview=true&from_buffer=false&portalId=21571110&preview_key=xLtOCRzg"}>Webinar Series</MenuItem>
              <MenuItem onClick={() => window.location.href = "https://www.sportsbox.ai/learning"}>Learning</MenuItem>
              <MenuItem onClick={() => window.location.href = "https://www.sportsbox.ai/contact"}>Contact</MenuItem>
              <Divider />
              <h5>More</h5>
              <MenuItem onClick={() => window.location.href = "https://www.sportsbox.ai/student"}>For Players</MenuItem>
              <MenuItem onClick={() => window.location.href = "https://www.sportsbox.ai/coach"}>For Coaches</MenuItem>
              <MenuItem onClick={() => window.location.href = "https://3dgolf.sportsbox.ai/sign-in"}>Login</MenuItem>
            </Menu>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

Topbar.propTypes = {
  className: PropTypes.string,
};

export default Topbar;
