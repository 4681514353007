import React from "react";

import { Stepper, Step, StepLabel } from "@material-ui/core";

import "./offerPaid.css";

function CTASignUpComponent() {
  return (
    <>
      <Stepper activeStep={2} className="cta-mobile-stepper">
        <Step key={1} completed={false}>
          <StepLabel></StepLabel>
        </Step>
        <Step key={2} completed={false} className="inactive">
          <StepLabel></StepLabel>
        </Step>
        <Step key={2} completed={false} className="inactive">
          <StepLabel></StepLabel>
        </Step>
      </Stepper>
      {window.location.href.includes("/mark-crossfield") && (
        <>
          <div className="cta-caption">
            Subscribe to Sportsbox to Win a Free Lesson with
            <span>Mark Crossfield</span>
          </div>
          <div className="cta-subcaption">
            Simply subscribe and submit your first swing inside the app to be
            entered to win!
          </div>
        </>
      )}

      {window.location.href.includes("/holiday-offer") && (
        <>
          <div className="cta-caption">
            Unwrap your best game yet: Holiday Deals on
            <span>Sportsbox 3DGolf</span>
          </div>
          <div className="cta-invitational">
            <img alt="Gifts" src="/images/gifts.png" width={350} />
          </div>
        </>
      )}
    </>
  );
}

export const CTASignUp = CTASignUpComponent;
