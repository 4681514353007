import React, { useState } from "react";
import {
  Button,
  CircularProgress,
  Container,
  TextField,
  Grid,
} from "@material-ui/core";
import Swal from "sweetalert2";
import "./promoCheckout.css";
import firebase from "../../common/firebase";

function SignUpComponent({ finishSignUp }) {
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const checkUserAcc = async () => {
    try {
      const providers = await firebase
        .auth()
        .fetchSignInMethodsForEmail(email.toLowerCase().replace(/ /g, ""));

      return providers.length === 0;
    } catch (err) {
      console.log(err);
    }
  };

  const validateEmail = (email) => {
    if (!email) return false;
    const verify = /\S+@\S+\.\S+/;
    return verify.test(email);
  };

  const handleSignUp = async () => {
    if (!validateEmail(email)) {
      Swal.fire({
        title: '<p style="font-size:70%;">Please enter a valid Email</p>',
        icon: "warning",
        confirmButtonText: "Ok",
        allowOutsideClick: true,
      });
      return;
    }

    setIsLoading(true);
    const noUsers = await checkUserAcc();

    if (noUsers) {
      finishSignUp(email);
    } else {
      Swal.fire({
        icon: "warning",
        title:
          '<p style="font-size:70%;">This offer is dedicated to new customers only</p>',
        confirmButtonText: "Ok",
        allowOutsideClick: true,
        customClass : { 
          container : 'my-swal' 
        },
      });
      setIsLoading(false);
    }
  };

  return (
    <>
      {!isLoading && (
        <Grid container spacing={2} className="offer-signup">
          <Grid item xs={12} className="offer-block-caption-wrapper">
            <span className="offer-block-caption">Email</span>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              required
              className="offer-input"
              label="Email Address"
              onChange={(event) => setEmail(event.target.value)}
              value={email}
              variant="outlined"
              inputProps={{ maxLength: 254 }}
            />
          </Grid>
          <Grid item xs={12} style={{ textAlign: "center" }}>
            <Button
              className="offer-button"
              disabled={ validateEmail(email) ? false : true}
              variant="contained"
              onClick={() => handleSignUp()}
            >
              continue
            </Button>
          </Grid>
        </Grid>
      )}
      {isLoading && (
        <Container maxWidth="xl" style={{ textAlign: "center", padding: 200 }}>
          <CircularProgress />
        </Container>
      )}
    </>
  );
}

export const SignUp = SignUpComponent;
