import React from 'react';
import { Grid, Stepper, Step, StepLabel } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import './promoCheckout.css';

function CTASelectOfferComponent({ product }) {
  return (<>
    <Stepper activeStep={3} className="cta-mobile-stepper">
      <Step key={1} completed={false}>
        <StepLabel></StepLabel>
      </Step>
      <Step key={2} completed={false}>
        <StepLabel></StepLabel>
      </Step>
      <Step key={3} completed={false} className="inactive">
        <StepLabel></StepLabel>
      </Step>
    </Stepper>
    <div className="cta-invitational">
      <img
        alt="Invitational"
        src="/images/offer/golf_pass.png"
        width={350}
      />
    </div>
    {product === "gpod" ? 
      <div className="cta-caption" >
        <span>GPOD + SPORTSBOX<br /></span>        
        BUNDLE <span><br /></span>
      </div>
    :
      <div className="cta-caption" >
        TRY <span>SPORTSBOX<br /></span>        
        AT HOME <span><br /></span>
      </div>
    }
    <div className="cta-subcaption">
      Then $15.99/month after your promo period. Cancel anytime.
      {product === "gpod" && (<div><br />Combining the best magnetic tripod for the fastest and easiest swing captures and 3 months of Sportsbox 3D Player!</div>)}
    </div>
    <div className="cta-golfpass-benefits-wrapper">
      <ul className="cta-golfpass-benefits">
      <li><CheckIcon className="cta-benefit-mark" /> Auto swing recording</li>
        <li><CheckIcon className="cta-benefit-mark" /> Instant data feedback after each swing</li>
        <li><CheckIcon className="cta-benefit-mark" /> Pro ranges comparison</li>
        <li><CheckIcon className="cta-benefit-mark" /> Drill videos recommended for your swing</li>
      </ul>
    </div>
    <div className="cta-total">
      <Grid container spacing={2} className="cta-total-today">
        <Grid
          item
          xs={6}
        >
          Billed Today
        </Grid>
        <Grid
          item
          xs={6}
          className="cta-total-sum"
        >
          {product === "gpod" ? "$100" : "$14.39"} USD
        </Grid>
      </Grid>
      <Grid container spacing={2} className="cta-total-after">
        <Grid
          item
          xs={6}
        >
          Billed after trial
        </Grid>
        <Grid
          item
          xs={6}
          className="cta-total-sum"
        >
          $15.99 monthly
        </Grid>
      </Grid>
    </div>
  </>);
}

export const CTASelectOffer = CTASelectOfferComponent;