import React from 'react';
import {Grid, CircularProgress} from '@material-ui/core';
import firebase, { axiosWithToken } from '../../common/firebase'
import {AccountProfile, AccountDetails, AccountNotFound, SessionList} from './components';
import { withStyles } from '@material-ui/styles';
import { functionBaseUrl } from '../../common/firebase'

const useStyles = () => ({
  root: {margin: "auto"},
});

class Account extends React.Component {
  constructor(props) {
    const firebaseUser = firebase.auth().currentUser
    super(props)
    this.state = {
      email : firebaseUser ? firebaseUser.email : "",
      uid : firebaseUser ? firebaseUser.uid : "",
      error : "",
      loading : true,
      sessionElements : [],
      role: '',
      user: {},
      isStaffEnterprise: false
    }
  }

  componentDidMount () {
    firebase.auth().onAuthStateChanged(async user => {
      if (user) {
        await axiosWithToken(functionBaseUrl + '/api/users/' + user.uid, {
          method: 'get',
        })
          .then(async response => {          
            const doc = response.data;
            this.setState({
              user: {
                uid: user.uid,
                email: user.email,
                contactNumber: doc.contactNumber,
                profileURL : doc.picture,
                userCreated : doc.userCreated,//makeDate(doc.userCreated),
                role : doc.role,//response.data.user.customClaims.role,
                requestId: doc.requestId,
                document: user.uid,
                subscriptionType: doc.subscriptionType
                //addressId: doc.addressId
              },
              attributes: {
                firstName : doc.firstName,
                lastName : doc.lastName,
                dominantHand : doc.dominantHand,
                height : doc.height,
                hipSize: doc.hipSize,
                weight : doc.weight,
                disability : doc.disability,
                gender : doc.gender,
                dob : doc.dob,
              },
              coach: {
                coachAddress: {
                  city: doc.coachAddress?.city,
                  state: doc.coachAddress?.state,
                  country: doc.coachAddress?.country
                },
                coachFacility: doc.coachFacility,
                certification: doc.certification,
                role: doc.role
              },
              //sessions : response.data.sessions,
              //address : response.data.address,
              //addressToString: response.data.addressToString,
              error : '',
              role: doc.role
            });
            if (doc.subscriptionType.includes('enterprise') && doc.enterpriseAccountId) {
              const entUser = await firebase.firestore().collection("enterpriseAccounts").doc(doc.enterpriseAccountId).get();
              const entData = entUser.data();
              if (entData && 'autoRenewal' in entData && entData.autoRenewal === false) {
                this.setState({ isStaffEnterprise: true })
                await axiosWithToken(functionBaseUrl + '/api/teachableUser/' + this.state.uid, {
                  method: 'GET',
                })
                  .catch(err => {
                    console.log(err)
                  });
              }
            }      
            this.setState({loading : false});
          })
          .catch(error => {
            console.log(error);
            this.setState({error : "There was an error retrieving your information"});
            this.setState({loading : false});
          });
      }
    });
  }

  render() {
    return !this.state.loading ? (
      <div style={{marginTop: '25px', marginLeft: '20px'}}>
        <Grid
        container
        >
          <Grid
            item
            lg={4}
            md={4}
            xl={4}
            sm={12}
            xs={12}
            style = {{display: (this.state.error) ? "none" : (this.state.loading) ? "none" : "block"}}
          >
           <AccountProfile 
            user = {{ ...this.state.user, ...this.state.attributes }}   
            //address = {{...this.state.address, string: this.state.addressToString}}         
            />
          </Grid>
          <Grid
            item            
            lg={4}
            md={4}
            xl={4}
            sm={12}
            xs={12}
            style = {{display: (this.state.error) ? "none" : (this.state.loading) ? "none" : "block"}}
          >
            <AccountDetails            
            user = {{ ...this.state.attributes/*, ...this.state.address*/, ...this.state.user }}
            isStaffEnterprise = {this.state.isStaffEnterprise}
            />
          </Grid>
          {(this.state.user.subscriptionType.includes('free') === false || !this.state.user.subscriptionType.includes('student') === false || this.state.role === 'admin') ?
            <Grid
              item            
              lg={4}
              md={4}
              xl={3}
              sm={12}
              xs={12}
              style = {{display: (this.state.error) ? "none" : (this.state.loading) ? "none" : "block"}}         
            >
            <SessionList coach = {{ ...this.state.coach }} />
            </Grid> : 
          <div></div>}
            <Grid
              item
              lg={12}
              md={12}
              xl={12}
              xs={12}
              style = {{display: (this.state.error && !this.state.loading) ? "block" : "none"}}
            >
              <AccountNotFound            
              error = {{error : this.state.error}}
              />
            </Grid>
        </Grid>
      </div>
    ) : (<Grid
      style = {{margin : "auto", padding : 100}}
    >
      <CircularProgress style = {{display: (this.state.loading) ? "block" : "none"}}/>
    </Grid>);
  }
};

export default withStyles(useStyles)(Account);