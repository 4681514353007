import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import React from "react";
import { Pad } from "../../../../components";
import {
  WatchlistFormGoalColumn,
  WatchlistFormGoalItem,
  WatchlistFormGoalSubContainer,
  WatchlistTrackerFormContainer,
} from "./styled";
import ClearIcon from "@material-ui/icons/Clear";

export function WatchlistTrackerForm({
  form,
  onChange,
  onRemove,
  watchlistData,
}) {
  const positions = [
    { id: "ADR", name: "Address" },
    { id: "TOP", name: "Top" },
    { id: "IMP", name: "Impact" },
    { id: "FIN", name: "Finish" },
    { id: "FCH", name: "Forward Swing Club Horizontal" },
    { id: "DCH", name: "Downswing Club Horizontal" },
    { id: "BCH", name: "Backswing Club Horizontal" },
  ];
  const phases = [
    { id: "backswing", name: "Backswing" },
    { id: "downswing", name: "Downswing" },
    { id: "followthrough", name: "Followthrough" },
  ];

  return (
    <div>
      {watchlistData ? (
        <WatchlistTrackerFormContainer>
          <div style={{ width: "50%" }}>
            <FormControl
              style={{ background: "#FFF" }}
              fullWidth
              variant="outlined"
            >
              <InputLabel id="tracker-label">Tracker</InputLabel>
              <Select
                lableId="tracker-label"
                value={form.trackerType}
                onChange={(e) => onChange(e, form.id)}
                name="trackerId"
                label="Tracker"
              >
                {watchlistData.map((el) => (
                  <MenuItem value={el.trackerId}>{el.trackerId}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <Pad height="10px" />
            <FormControl
              style={{ background: "#FFF" }}
              fullWidth
              variant="outlined"
            >
              <InputLabel id="swingPosition-label">Swing position</InputLabel>
              <Select
                lableId="swingPosition-label"
                value={form.swingPosition}
                onChange={(e) => onChange(e, form.id)}
                name="position"
                label="Swing position"
              >
                {[...positions].map((el) => (
                  <MenuItem value={el.id}>{el.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <WatchlistFormGoalColumn>
            <ClearIcon
              onClick={() => onRemove(form.id)}
              style={{ alignSelf: "flex-end", cursor: "pointer" }}
            />
            <div>Set Goal</div>
            <Pad height="20px" />
            <WatchlistFormGoalSubContainer>
              <WatchlistFormGoalColumn>
                <WatchlistFormGoalItem>{form.rangeMin}</WatchlistFormGoalItem>
                <div>Min</div>
              </WatchlistFormGoalColumn>
              <Pad width="10px" />
              <WatchlistFormGoalColumn>
                <WatchlistFormGoalItem>{form.rangeMax}</WatchlistFormGoalItem>
                <div>Max</div>
              </WatchlistFormGoalColumn>
            </WatchlistFormGoalSubContainer>
          </WatchlistFormGoalColumn>
        </WatchlistTrackerFormContainer>
      ) : (
        ""
      )}
    </div>
  );
}
