import React from "react";
import { withStyles } from "@material-ui/styles";
import { tableOptions, useStyles } from "./config";
import MaterialTable from "material-table";
import { NewItemButton, StatusBadge } from "./styled";
import { format } from "date-fns";
import { PracticeGuideRow } from "./components";
import { Pad } from "../../components";
import { PracticeGuideDetailsModal } from "./components/PracticeGuideDetailsModal";
import { useState } from "react";
import { PracticeGuideHeader } from "./components/common/PracticeGuideHeader";
import { TableSorter } from "./components/styled";
import { TableSorterRow } from "./components/common/TableSorterRow";
import { TipsAndDrillsDetailsModal } from "./components/TipsAndDrillsDetailsModal";
import { AlbumDetailsModal } from "./components/AlbumDetailsModal";
import { OutlinedButton } from "./components/common/styled";
import { useCallback } from "react";
import {
  createPracticeGuide,
  createTipsAndDrillsVideo,
  getPracticeGuides,
  getTipsAndDrills,
  updateTipsAndDrillsVideo,
  deleteTipsAndDrillsVideo,
  deletePracticeGuide,
  updatePracticeGuide,
  checkForDelete,
  getPracticeGuidesServer,
  getTipsAndDrillsServer,
} from "./api_test";
import { useEffect } from "react";
import { TipsAndDrillsRow } from "./components/tipsAndDrills/TipsAndDrillsRow";
import {
  copyAlbum,
  createAlbum,
  deleteAlbum,
  getAlbums,
  getAlbumsServer,
  reorderAlbums,
  updateAlbum,
} from "./api_test/albumApi";
import { AlbumRow } from "./components/album/AlbumRow";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  archiveItem,
  copyItem,
  getWatchlistCsvData,
  guidesFileSizeScript,
  searchItems,
} from "./api_test/common";
import { getThumbnailForVideo } from "./api_test/helper";
import { DeleteConfirm } from "./components/common/DeleteConfirm";
import { Pagination } from "@material-ui/lab";
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import { DraggableAlbumRow } from "./components/album/DraggableAlbumRows";

function PracticeGuide() {
  const [tableData, setTableData] = useState({
    practiceGuides: [],
    tipsAndDrills: [],
    albums: [],
  });
  const [data, setData] = useState([]);
  const [modal, setModal] = useState({
    practiceGuides: { open: false, initId: null },
    tipsAndDrills: { open: false, initId: null },
    albums: { open: false, initId: null },
    delete: { open: false, initId: null, type: null },
  });
  const [view, setView] = useState("albums");
  const [paginationTotal, setPaginationTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [sort, setSort] = useState({ field: "createdAt", direction: "desc" });
  const [status, setStatus] = useState("published");
  const [loading, setLoading] = useState({ table: false });

  useEffect(() => {
    setCurrentPage(1);
    setSort({ field: "createdAt", direction: "desc" });
    setStatus("published");
  }, [view]);

  const loadingWrap = async (asyncFn) => {
    setLoading({ table: true });
    await asyncFn();
    setLoading({ table: false });
  };

  const fetchFn = async () => {
    const limit = 10;
    const offset = limit * (currentPage - 1);
    const { field, direction } = sort;
    if (view === "practiceGuides") {
      const { data, total } = await getPracticeGuidesServer({
        limit,
        offset,
        search: "",
        sort: field,
        direction,
        status,
      });
      setPaginationTotal(total);
      setData(data);
    } else if (view === "tipsAndDrills") {
      const { data, total } = await getTipsAndDrillsServer({
        limit,
        offset,
        search: "",
        sort: field,
        direction,
        status,
      });
      setPaginationTotal(total);
      setData(data);
    } else if (view === "albums") {
      const { data, total } = await getAlbumsServer({
        limit,
        offset,
        search: "",
        sort: field,
        direction,
        status,
      });
      setPaginationTotal(total);
      setData(data);
    }
  };

  useEffect(() => {
    const fetch = async () => {
      setLoading({ table: true });
      const limit = 10;
      const offset = limit * (currentPage - 1);
      const { field, direction } = sort;
      if (view === "practiceGuides") {
        const { data, total } = await getPracticeGuidesServer({
          limit,
          offset,
          search: "",
          sort: field,
          direction,
        });
        setPaginationTotal(total);
        setData(data);
      } else if (view === "tipsAndDrills") {
        const { data, total } = await getTipsAndDrillsServer({
          limit,
          offset,
          search: "",
          sort: field,
          direction,
        });
        setPaginationTotal(total);
        setData(data);
      } else if (view === "albums") {
        const { data, total } = await getAlbumsServer({
          limit,
          offset,
          search: "",
          sort: field,
          direction,
        });
        setPaginationTotal(total);
        setData(data);
      }
      setLoading({ table: false });
    };
    loadingWrap(fetchFn);
  }, [view, currentPage, sort, status]);

  const paginationCount = Math.ceil(paginationTotal / 10);

  const ViewSwitch = () => {
    switch (view) {
      case "albums": {
        if (status === "published") {
          return (
            <DraggableAlbumRow
              sort={sort}
              onSort={handleSort}
              items={data}
              onEdit={(el) => {
                console.log(el);
                setModal((prev) => ({
                  ...prev,
                  albums: {
                    open: true,
                    initId: el.id,
                  },
                }));
              }}
              onDelete={(el) => {
                setModal((prev) => ({
                  ...prev,
                  delete: {
                    open: true,
                    initId: el.id,
                    initType: "album",
                  },
                }));
              }}
              onCopy={(el) => handleItemCopy(el.id)}
              onReorder={handleAlbumReorder}
            />
          );
        } else {
          return (
            <>
              <TableSorterRow type="albums" sort={sort} onSort={handleSort} />
              {data.map((el, i) => (
                <AlbumRow
                  onEdit={() => {
                    setModal((prev) => ({
                      ...prev,
                      albums: {
                        open: true,
                        initId: el.id,
                      },
                    }));
                  }}
                  onDelete={() => {
                    setModal((prev) => ({
                      ...prev,
                      delete: {
                        open: true,
                        initId: el.id,
                        initType: "album",
                      },
                    }));
                  }}
                  onCopy={() => handleItemCopy(el.id)}
                  data={el}
                  key={el.id}
                />
              ))}
            </>
          );
        }
      }
      case "practiceGuides":
        return (
          <>
            <TableSorterRow
              type="practiceGuides"
              sort={sort}
              onSort={handleSort}
            />
            {data.map((el) => (
              <PracticeGuideRow
                onEdit={() => {
                  setModal((prev) => ({
                    ...prev,
                    practiceGuides: {
                      open: true,
                      initId: el.id,
                    },
                  }));
                }}
                onDelete={() => {
                  setModal((prev) => ({
                    ...prev,
                    delete: {
                      open: true,
                      initId: el.id,
                      initType: "guide",
                    },
                  }));
                }}
                onCopy={() => handleItemCopy(el.id)}
                data={el}
                key={el.id}
              />
            ))}
          </>
        );

      case "tipsAndDrills":
        return (
          <>
            <TableSorterRow
              type="tipsAndDrills"
              sort={sort}
              onSort={handleSort}
            />
            {data.map((el) => (
              <TipsAndDrillsRow
                onEdit={() => {
                  setModal((prev) => ({
                    ...prev,
                    tipsAndDrills: {
                      open: true,
                      initId: el.id,
                    },
                  }));
                }}
                onDelete={() => {
                  setModal((prev) => ({
                    ...prev,
                    delete: {
                      open: true,
                      initId: el.id,
                      initType: "drill",
                    },
                  }));
                }}
                onCopy={() => handleItemCopy(el.id)}
                data={el}
                key={el.id}
              />
            ))}
          </>
        );
    }
  };

  const handleLoader = (name, value) => {
    setLoading((prev) => ({ ...prev, [name]: value }));
  };

  const handleItemCopy = useCallback(
    async (id) => {
      handleLoader("table", true);
      await copyItem(view, id);
      await fetchFn();
      handleLoader("table", false);
    },
    [view]
  );

  const handleItemDelete = useCallback(
    async (id) => {
      handleLoader("table", true);
      await archiveItem(view, id);
      await fetchFn();
      handleLoader("table", false);
    },
    [view]
  );

  const handlePracticeGuideCreate = useCallback(async (data) => {
    handleLoader("table", true);
    await createPracticeGuide(data);
    await fetchFn();
    handleLoader("table", false);
  }, []);

  const handlePracticeGuideUpdate = useCallback(async (id, data) => {
    handleLoader("table", true);
    await updatePracticeGuide(id, data);
    await fetchFn();
    handleLoader("table", false);
  }, []);

  const handleTipsAndDrillsCreate = useCallback(async (data) => {
    handleLoader("table", true);
    await createTipsAndDrillsVideo(data);
    await fetchFn();
    handleLoader("table", false);
  }, []);

  const handleTipsAndDrillsUpdate = useCallback(async (id, data) => {
    handleLoader("table", true);
    await updateTipsAndDrillsVideo(id, data);
    await fetchFn();
    handleLoader("table", false);
  }, []);

  const handleAlbumCreate = useCallback(async (data) => {
    handleLoader("table", true);
    await createAlbum(data);
    await fetchFn();
    handleLoader("table", false);
  }, []);

  const handleAlbumUpdate = useCallback(async (id, data) => {
    handleLoader("table", true);
    await updateAlbum(id, data);
    await fetchFn();
    handleLoader("table", false);
  }, []);

  const handleAlbumReorder = useCallback(async (ids) => {
    handleLoader("table", true);
    await reorderAlbums(ids);
    await fetchFn();
    handleLoader("table", false);
  });

  const handleSearch = useCallback(
    async (input) => {
      handleLoader("table", true);
      const searched = await searchItems(view, input);
      setData(searched);
      handleLoader("table", false);
    },
    [view]
  );
  const handleStatus = (e) => {
    setStatus(e.target.value);
  };

  const handleSort = (field, direction) => setSort({ field, direction });

  const handlePaginationChange = useCallback(
    async (event, value) => setCurrentPage(value),
    [view]
  );

  return (
    <Pad padding="20px" width="100%">
      <PracticeGuideHeader
        selected={view}
        onSelect={(viewName) => setView(viewName)}
        onSearch={handleSearch}
      />
      <Pad height="20px" />
      <div
        style={{
          display: "flex",
          height: "fit-content",
          alignItems: "center",
          justifyContent: "start",
        }}
      >
        <FormControl style={{ width: "30%" }} variant="outlined">
          <InputLabel id="status-label">Status Filter</InputLabel>
          <Select
            lableId="status-label"
            value={status}
            onChange={handleStatus}
            name="status"
            label="Status Filter"
          >
            <MenuItem value="published">Published</MenuItem>
            <MenuItem value="private">Private</MenuItem>
            <MenuItem value="archived">Archived</MenuItem>
          </Select>
        </FormControl>
        <NewItemButton
          onClick={() =>
            setModal((prev) => ({
              ...prev,
              [view]: { open: true, initId: null },
            }))
          }
        >
          CREATE NEW
        </NewItemButton>
      </div>
      {/* <input
        type="text"
        value={search}
        onChange={(e) => setSearch(e.target.value)}
      />
      <button onClick={}>test</button> */}
      <Pad height="20px" />
      {loading.table ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "600px",
            width: "100%",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <div style={{ minHeight: "650px" }}>
          <ViewSwitch />
        </div>
      )}
      <Pagination
        count={paginationCount}
        page={currentPage}
        onChange={handlePaginationChange}
      />

      <PracticeGuideDetailsModal
        onSave={handlePracticeGuideCreate}
        onUpdate={handlePracticeGuideUpdate}
        open={modal.practiceGuides.open}
        initId={modal.practiceGuides.initId}
        onClose={() =>
          setModal((prev) => ({
            ...prev,
            practiceGuides: { open: false, initId: null },
          }))
        }
      />
      <TipsAndDrillsDetailsModal
        onSave={handleTipsAndDrillsCreate}
        onUpdate={handleTipsAndDrillsUpdate}
        open={modal.tipsAndDrills.open}
        initId={modal.tipsAndDrills.initId}
        onClose={() =>
          setModal((prev) => ({
            ...prev,
            tipsAndDrills: { open: false, initId: null },
          }))
        }
      />
      <AlbumDetailsModal
        open={modal.albums.open}
        initId={modal.albums.initId}
        onSave={handleAlbumCreate}
        onUpdate={handleAlbumUpdate}
        onClose={() =>
          setModal((prev) => ({
            ...prev,
            albums: { open: false, initId: null },
          }))
        }
      />
      <DeleteConfirm
        open={modal.delete.open}
        onClose={() =>
          setModal((prev) => ({
            ...prev,
            delete: { open: false, initId: null, initType: null },
          }))
        }
        onConfirm={handleItemDelete}
        initId={modal.delete.initId}
        initType={modal.delete.initType}
      />
      {/* <button onClick={() => guidesFileSizeScript()}>test</button> */}
    </Pad>
  );
}

export default withStyles(useStyles)(PracticeGuide);
