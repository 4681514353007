import React, { Component } from 'react';
import { createMuiTheme, MuiThemeProvider, Typography, MenuItem, Menu, Fade } from '@material-ui/core';
import PropTypes from 'prop-types';
import MaterialTable from 'material-table';
import axios from 'axios';
import 'firebase/firestore';
import firebase from '../../../../common/firebase'
import { forwardRef } from 'react';
import {AddBox, ArrowDownward, Check, ChevronLeft, ChevronRight, Clear, DeleteOutline, Edit, FilterList, FirstPage, LastPage, Remove, SaveAlt, Search, ViewColumn} from '@material-ui/icons';
import palette from '../../../../theme/palette';
import moment from 'moment';

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

const tableHeads = {sessions: [
  {field: 'name', title: 'Name'},
  {field: 'purpose', title: 'Purpose'},
  {field: 'type', title: 'Type'},
  {field: 'env', title: 'Env'},
  {field: 'camera', title: 'Camera'},
  {field: 'windSpeed', title: 'Wind'},
  {field: 'date', title: 'Date', customSort: (a, b) => new Date(a.date) - new Date(b.date), defaultSort: 'desc'},
]}

const theme = createMuiTheme({
  typography: {
    h6: {
      fontWeight: 500,
      fontSize: '20px',
      letterSpacing: '-0.05px',
      lineHeight: '20px',
    }
  },
  palette: {
    secondary: {
      main: palette.primary.main,
    },
  },
})

class DisplaySessionTable extends Component {
  constructor (props) {
    const firebaseUser = firebase.auth().currentUser
    super(props);
    this.state = {
      selectedRows: {
        sessions: []
      },
      exportMenu: {
        anchor: null,
        type: ''
      },
      deleteMenuSess: {
        anchor: null,
        type: ''
      },
      uid : firebaseUser ? firebaseUser.uid : "",
      cameraName : '',
      cameraSetups : []
    }
  }

  getTableHeader = (name) => {
    return tableHeads[name];
  }

  handleExportPush = (type) => (event, data) => {
    this.setState({exportMenu: {anchor: event.currentTarget, type: type}, selectedRows: {...this.state.selectedRows, [type]: data}})
  }

  /*exportAsCsv = () => {
    if(this.state.selectedRows[this.state.exportMenu.type]){
      const ids = this.state.selectedRows[this.state.exportMenu.type].map((item) => item.id)
      const headers = Object.keys(this.props[this.state.exportMenu.type][ids[0]]).sort()
      for( let i = 0; i < headers.length; i++){ 
        if ( excludedHeaders.includes(headers[i])) { 
          headers.splice(i, 1)
          i-- 
        }
      }
      let csvContent = 'data:text/csv;charset=utf-8,'
      csvContent += 'id,'+headers.join(',') + '\r\n'
      for(const id of ids) {
        csvContent += id+','
        for(const header of headers) {
          if(!excludedHeaders.includes(header)){
            let content = this.props[this.state.exportMenu.type][id][header]
            if(typeof content === 'string'){
              content = content.replace(/"/g, '""');
            }
            content = '"'+content+'"' || ''
            csvContent += content + ','
          }
        }
        csvContent += '\r\n'
      }
      const encodedUri = encodeURI(csvContent)
      const link = document.createElement('a')
      link.setAttribute('href', encodedUri)
      link.setAttribute('download', this.state.exportMenu.type+'.csv')
      document.body.appendChild(link)
      link.click()
    }
  }*/

  downloadVideos = () => {
    const FileDownload = require('js-file-download');
    const sessionIds = this.state.selectedRows.sessions.map((item) => item.id)
    for(let i = 0; i < sessionIds.length; ++i) {
      firebase.firestore().collection('sessions').doc(sessionIds[i]).get().then(function(querySnapshot) {
        let res = querySnapshot.data();
        let videoIds = res.videoIds;
        for(const vid of videoIds) {
          firebase.firestore().collection('videos').doc(vid).get().then(function(snapshot) {
            let out = snapshot.data();
            var config = {
              method: 'GET',
              url: "https://afternoon-waters-89417.herokuapp.com/" + out.videoPath,
              headers: { 
                'Authorization': "Basic ZmlsZXVwbG9hZGVyOm51RTkxVXdvNUZBVFhTWWZFa01N",
                'Content-Type': 'multipart/form-data'
              },
              responseType: 'blob'
            };

            if (!out.videoOrigName.toLowerCase().includes('.mp4') && !out.videoOrigName.toLowerCase().includes('.mov')) {
              const videoUrl = out.videoPath.split('?')[0].split('.');
              const videoExt = `.${videoUrl[videoUrl.length - 1]}`;
              if (out.videoOrigName.substr(-videoExt.length) !== videoExt) {
                out.videoOrigName = `${out.videoOrigName}${videoExt}`;
              }
            }
      
            axios(config)
            .then((response) => {
              FileDownload(response.data, out.videoOrigName);
            })
            .catch(function (error) {
              console.log(error);
            });
          })
        }
      })
    }
  }

  handleDeleteSess = (type) => (event, data) => {
    this.setState({deleteMenuSess: {anchor: event.currentTarget, type: type}, selectedRows: {...this.state.selectedRows, [type]: data}})
  }

  deleteSessions = () => {
    const ids = this.state.selectedRows.sessions.map((item) => item.id)
    const fieldValue = firebase.firestore.FieldValue;
    let count = 0;
    for(let i = 0; i < ids.length; ++i) {
      firebase.firestore().collection('sessions').doc(ids[i]).get().then(function(querySnapshot) {
        let res = querySnapshot.data();
        let parentId = res.parentUserId;
        let videoId = res.videoIds;
        const sess = firebase.firestore().collection('users').doc(parentId);
        sess.update({'sessionIds': fieldValue.arrayRemove(ids[i])});
        for(const vid of videoId) {
          firebase.firestore().collection('videos').doc(vid).delete();
        }
        firebase.firestore().collection('sessions').doc(ids[i]).delete();
        ++count;
        if(count === ids.length) {
          alert(ids.length + " session(s) were deleted, page will refresh now");
          setTimeout(function() {
            window.location.reload()
          }, 1000);
        }
      })
      .catch(err => {
          console.log("Error deleting documents: ", err);
      });
    }
  }

  sessionDate = (date) => {
    return moment(String(date), "x").format('MM/DD/YYYY');
  }

  render(){
    let sessionData = [];
    if (this.props.sessions) {
      const keys = Object.keys(this.props.sessions)
      sessionData = keys.map((key) => {
        if(this.props.sessions[key]) {
          const session = this.props.sessions[key];
          let cameraName = ""
          let sessionEnv = ""
          if(session.cameraSetupId) {
            for(const camera of this.props.cameraSetups) {
              if(camera.id === session.cameraSetupId) {
                cameraName = camera.name
              }
            }
          }
          if(session.sessionEnv === 'pracRangeIndoor') {
            sessionEnv = 'Practice Range Indoor'
          } else if(session.sessionEnv === 'pracRangeOutdoor') {
            sessionEnv = 'Practice Range Outdoor'
          } else if(session.sessionEnv === 'open') {
            sessionEnv = 'Open Air'
          } else if(session.sessionEnv === 'simulator') {
            sessionEnv = 'Simulator'
          } else if(session.sessionEnv === 'indoor') {
            sessionEnv = 'Indoors'
          } else {
            sessionEnv = 'Golf Course'
          }
          const out = {
            id: session.id,
            name: session.sessionName,
            purpose: session.sessionPurpose,
            type: session.sessionType === 'upload' ? 'Video Upload' : 'Live',
            env: sessionEnv,
            camera: cameraName,
            windSpeed: !isNaN(session.windSpeed) ? session.windSpeed + ' mph ' : session.windSpeed,
            date: this.sessionDate(session.sessionDate)//(new Date(session.sessionDate).getMonth() + 1) + '/' + new Date(session.sessionDate).getDate() + '/' + new Date(session.sessionDate).getFullYear()%100,
          }
          return out;
        }
        return null;
      })
    }
    const tableElements = [];
    if(sessionData.length > 0) {
      tableElements.push(
        <MaterialTable
          style={{marginBottom: '10px'}}
          icons={tableIcons}
          key='sessionTable'
          title='Sessions'
          columns={this.getTableHeader('sessions')}
          data={sessionData.map(row => this.state.selectedRows.sessions.find(selected => selected.id === row.id) ? {...row, tableData: { checked: true}} : row)}
          options={{
            selection: true,
            sorting: true,
            exportAllData: true,
            exportButton: true,
            pageSizeOptions: [5, 10, 20, 50, 100],
            paginationPosition: 'both',
          }}
          actions={[{
            tooltip: 'Download selected sessions',
            icon: tableIcons.Export,
            onClick: this.handleExportPush('sessions')
          },{
            tooltip: 'Delete selected sessions',
            icon: tableIcons.Delete,
            onClick: this.handleDeleteSess('sessions')
          }]}/>)
    }
    if (tableElements.length < 1) {
      return (<Typography align='center' style={{marginTop: '20px'}}>There are no sessions to display</Typography>)
    }
    return (
    <MuiThemeProvider theme={theme}>
      {tableElements}
      <Menu
        id="menu"
        anchorEl={this.state.exportMenu.anchor}
        keepMounted
        open={Boolean(this.state.exportMenu.anchor)}
        onClose={() => {this.setState({exportMenu: {anchor: null, type: ''}})}}
        TransitionComponent={Fade}
      >
        {/*<MenuItem onClick={this.exportAsCsv}>Export As CSV</MenuItem>*/}
        <MenuItem onClick={this.downloadVideos}>Download Session Videos</MenuItem>
      </Menu>
      <Menu
        id="menu2"
        anchorEl={this.state.deleteMenuSess.anchor}
        keepMounted
        open={Boolean(this.state.deleteMenuSess.anchor)}
        onClose={() => {this.setState({deleteMenuSess: {anchor: null, type: ''}})}}
        TransitionComponent={Fade}
      >
        <MenuItem onClick={this.deleteSessions}>Delete</MenuItem>
      </Menu>
    </MuiThemeProvider>)
  }
}
DisplaySessionTable.propTypes = {
  sessions: PropTypes.array,
  searchOrder: PropTypes.string,
  compress: PropTypes.bool
}
export default DisplaySessionTable