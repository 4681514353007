import React, { useState, useEffect } from 'react';

import { Button, Grid } from '@material-ui/core';
import StarIcon from '@material-ui/icons/Star';


function PlansTestimonialsCoachesComponent() {
  const [currentTestimonial, setCurrentTestimonial] = useState(1);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTestimonial(currentTestimonial => (currentTestimonial === 4 ? 1 : currentTestimonial + 1));
    }, 8000);

    return () => clearInterval(interval);
  }, [currentTestimonial]);

  return (<>
    <Grid container>
      <Grid
        item
        xs={12}
        md={8}
        className="plans-testimonials-body"
      >
        <div className="plans-testimonials-stars">
          <StarIcon className="plans-testimonials-star" />
          <StarIcon className="plans-testimonials-star" />
          <StarIcon className="plans-testimonials-star" />
          <StarIcon className="plans-testimonials-star" />
          <StarIcon className="plans-testimonials-star" />
        </div>
        {currentTestimonial == 1 && (<>
          <div className="plans-testimonials-text">
            Sportsbox and 3D data will revolutionize teaching and learning. It's amazing to compare swings of golfers over time with quantifiable data.
          </div>
          <div className="plans-testimonials-name">
            — Sean Foley<br />(Sportsbox AI Investor)
          </div>
        </>)}
        {currentTestimonial == 2 && (<>
          <div className="plans-testimonials-text">
            Your coaching will be more effective, your students' practice will be more effective and the students will reap the benefits. It’s a win-win!
          </div>
          <div className="plans-testimonials-name">
            — Erika Larkin<br />(Sportsbox AI Investor)
          </div>
        </>)}
        {currentTestimonial == 3 && (<>
          <div className="plans-testimonials-text">
            Sportsbox 3D Golf is a must-have teaching tool for every instructor. It's a game-changer for the industry.
          </div>
          <div className="plans-testimonials-name">
            — David Leadbetter<br />(Sportsbox AI Investor)
          </div>
        </>)}
        {currentTestimonial == 4 && (<>
          <div className="plans-testimonials-text">
            My entire career I have emphasized the importance of measuring instead of guessing. Sportsbox allows us to quantify the issues and find solutions to make golfers better, quicker.
          </div>
          <div className="plans-testimonials-name">
            — Mike Adams<br />(Sportsbox AI Investor)
          </div>
        </>)}
        <div className="plans-testimonials-selectors">
          <Button onClick={() => setCurrentTestimonial(1)} className={currentTestimonial == 1 ? 'active' : ''} />
          <Button onClick={() => setCurrentTestimonial(2)} className={currentTestimonial == 2 ? 'active' : ''} />
          <Button onClick={() => setCurrentTestimonial(3)} className={currentTestimonial == 3 ? 'active' : ''} />
          <Button onClick={() => setCurrentTestimonial(4)} className={currentTestimonial == 4 ? 'active' : ''} />
        </div>
      </Grid>
      <Grid
        item
        xs={12}
        md={4}
        className={`plans-testimonials-photo coach${currentTestimonial}`}
      />
    </Grid>
  </>);
}

export default PlansTestimonialsCoachesComponent;