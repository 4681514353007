import React, { forwardRef, useEffect, useState } from 'react';

import {
  AddBox,
  ArrowDownward,
  Check,
  ChevronLeft,
  ChevronRight,
  Clear,
  DeleteOutline,
  Edit,
  FilterList,
  FirstPage,
  LastPage,
  Remove,
  SaveAlt,
  Search,
  ViewColumn,
  Receipt,
} from '@material-ui/icons';
import { Button, Grid, CircularProgress, Container } from '@material-ui/core';
import MaterialTable from 'material-table';
import Swal from 'sweetalert2';

import firebase, { functionBaseUrl, axiosWithToken } from '../../common/firebase';
import { AddEnterpriseAccount } from './AddEnterpriseAccount';
import { EditEnterpriseAccount } from './EditEnterpriseAccount';
import { ImportEnterpriseAccounts } from './ImportEnterpriseAccounts';
import { RemoveEnterpriseAccount } from './RemoveEnterpriseAccount';
import DisplayList from './DisplayList';

const tableHeader = [
  { field: 'email', title: 'Email Address' },
  { field: 'userName', title: 'Name' },
  { field: 'enterpriseAccount.autoRenewal', title: 'Staff Enterprise', render: rowData => {
    if (rowData?.enterpriseAccount?.autoRenewal === false) { 
      return "Yes" 
    } else {
      return "No"
    }
  }},
  { field: 'enterpriseAccount.coachesCount', title: 'Number of Coaches' },
  { field: 'enterpriseAccount.students.length', title: 'Number of Students' },
  { field: 'enterpriseAccount.annualPrice', title: 'Annual Pricing' },
  { field: 'enterpriseAccount.status', title: 'Status' },
  { field: 'enterpriseAccount.exception', title: 'Exception' },
  { field: 'enterpriseAccount.note', title: 'Note' },
];

const staffTableHeader = [
  { field: 'email', title: 'Email Address' },
  { field: 'onboardingCompleted', title: 'Onboarding Completed' },
  //{ field: 'students.length', title: '# of Total Students' },
  { field: 'paidStudents', title: '# of Paid Student Accounts' },
  { field: 'freeStudents', title: '# of Free Student Accounts' },
  { field: 'amountReceived', title: 'Amount Received',
    render: (rowData) => {
      return "$" + rowData?.amountReceived
    },
    customSort: (a, b) => a.amountReceived - b.amountReceived,
    defaultSort: 'desc'
  },
  { field: 'amountPending', title: 'Amount Pending',
    render: (rowData) => {
      return "$" + rowData?.amountPending
    },
    customSort: (a, b) => a.amountPending - b.amountPending,
  },
  { field: 'unpaidStudents', title: 'Invoice Paid', 
    render: rowData => {
      if (rowData?.unpaidStudents === 0) { 
        return "Yes" 
      } else {
        return "No"
      }
    },
  },
];

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  Receipt: forwardRef((props, ref) => <Receipt {...props} ref={ref} />),
};

function EnterpriseAccountListComponent() {
  const [accounts, setAccounts] = useState([]);
  const [staffAccounts, setStaffAccounts] = useState([]);
  const [isAddPopupOpened, setIsAddPopupOpened] = useState(false);
  const [userData, setUserData] = useState(false);
  const [isEditPopupOpened, setIsEditPopupOpened] = useState(false);
  const [isImportPopupOpened, setIsImportPopupOpened] = useState(false);
  const [isRemovePopupOpened, setIsRemovePopupOpened] = useState(false);
  const [popUp, setPopUp] = useState(false);
  const [popUpType, setPopUpType] = useState('staff');
  const [rowData, setRowData] = useState({});

  const openCreateAccount = () => {
    setIsAddPopupOpened(true);
  }

  const closeCreateAccount = () => {
    setIsAddPopupOpened(false);
    loadData();
  }

  const closeEditAccount = () => {
    setIsEditPopupOpened(false);
    loadData();
  }

  const handleEdit = (event, data) => {
    setUserData(data);
    setIsEditPopupOpened(true);
  }

  const openImportAccount = () => {
    setIsImportPopupOpened(true);
  }

  const closeImportAccount = () => {
    setIsImportPopupOpened(false);
    loadData();
  }

  const openRemoveAccount = () => {
    setIsRemovePopupOpened(true);
  }

  const closeRemoveAccount = () => {
    setIsRemovePopupOpened(false);
    loadData();
  }

  const openPopUp = (type, rowData) => {
    setPopUp(true);
    setPopUpType(type)
    setRowData(rowData);
  }

  const closePopUp = () => {
    setPopUp(false);
  }

  const handleInvoice = async (event, data) => {
    Swal.fire({
      title: '<p style="font-size:70%;">Are you sure you want to send invoice to ' + data.firstName + ' ' + data.lastName + ' (' + data.email + ')?</p>',
      icon: 'warning',
      confirmButtonText: 'Yes',
      showCancelButton: true,
      cancelButtonText: 'No',
      cancelButtonColor: 'red',
      allowOutsideClick: false
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await axiosWithToken(`${functionBaseUrl}/api/userEnterpriseSendInvoice/${data.uid}`, {
            method: 'post',
          });
          Swal.fire({
            title: '<p style="font-size:70%;">Invoice successfully sent</p>',
            icon: 'success',
            confirmButtonText: 'Ok',
            allowOutsideClick: false,
            timer: 2000
          })
        } catch (e) {
          Swal.fire('<p style="font-size:70%;">Error occured</p>');
        }
      }
    });
  }

  const handleDelete = async (event, data) => {
    Swal.fire({
      title: '<p style="font-size:70%;">Are you sure you want to delete this account?</p>',
      icon: 'warning',
      confirmButtonText: 'Yes',
      showCancelButton: true,
      cancelButtonText: 'No',
      cancelButtonColor: 'red',
      allowOutsideClick: false
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await axiosWithToken(`${functionBaseUrl}/api/users/${data.uid}`, {
            method: 'delete',
          })
          Swal.fire({
            title: '<p style="font-size:70%;">Account successfully deleted!</p>',
            icon: 'success',
            showCancelButton: false,
            showConfirmButton: false,
            showCloseButton: false,
            allowOutsideClick: false,
            timer: 3000
          }).then(() => {
            window.location.reload();
          })
        } catch (e) {
          Swal.fire('<p style="font-size:70%;">Error occured</p>');
        }
      }
    });
  }

  const loadData = async () => {
    const response = await axiosWithToken(`${functionBaseUrl}/api/usersEnterprise`, {
      method: 'get',
    });
    setAccounts(response.data.userInfo);

    const staffResponse = await firebase.firestore().collection('enterpriseAccounts').where('autoRenewal', '==', false).where('status', '==', 'Subscription Paid').get();
    let staff = [];
    staffResponse.forEach(async function (doc) {
      staff.push({ ...doc.data(), id: doc.id })
    });
    setStaffAccounts(staff);
  }

  useEffect(() => {
    loadData();
  }, [])

  return (<>
    <Grid item xs={12} style={{ textAlign: 'right', paddingTop: '2rem' }}>
      <Button
        variant="outlined"
        color="primary"
        style={{ marginRight: '2rem' }}
        onClick={openRemoveAccount}
      >
        DOWNGRADE ACCOUNT
      </Button>
      <Button
        variant="contained"
        color="secondary"
        onClick={openImportAccount}
        style={{ marginRight: '2rem' }}
      >
        IMPORT ACCOUNTS
      </Button>
      <Button
        variant="contained"
        color="primary"
        onClick={openCreateAccount}
      >
        CREATE NEW ACCOUNT
      </Button>
    </Grid>
    <Grid item xs={12}>
      <h3>Enterprise Accounts</h3>
      {accounts.length > 0 ? (
        <MaterialTable
          style={{ marginTop: '10px', marginBottom: '10px' }}
          icons={tableIcons}
          columns={tableHeader}
          title=""
          data={accounts}
          options={{
            selection: false,
            sorting: true,
            search: true,
            paging: true,
            pageSizeOptions: [5, 10, 20, 50, 100],
            exportAllData: true,
            exportButton: true,
            /*rowStyle: x => {
              if (x.tableData.id % 2) {
                return { backgroundColor: '#f2f2f2' }
              }
            }*/
          }}
          actions={[rowData => ({
            tooltip: 'Edit',
            icon: tableIcons.Edit,
            disabled: !rowData.subscriptionType.includes('enterprise_master') || !rowData?.enterpriseAccount?.coachesCount,
            onClick: handleEdit,
          })/*, rowData => ({
            tooltip: 'Send invoice',
            icon: tableIcons.Receipt,
            disabled: !rowData.subscriptionType.includes('enterprise_master') || !rowData?.enterpriseAccount?.coachesCount,
            onClick: handleInvoice,
          })*/, rowData => ({
            tooltip: 'Delete Account',
            icon: tableIcons.Delete,
            disabled: rowData.role === 'admin',
            onClick: handleDelete,
          })]}
          onRowClick={(event, rowData) => openPopUp('enterprise', rowData)}
        />
      ) : 
        <Container style={{ textAlign: 'center', paddingTop: '50px' }}>
          <CircularProgress />
        </Container>
      }
      <AddEnterpriseAccount
        open={isAddPopupOpened}
        close={closeCreateAccount}
      />
      <ImportEnterpriseAccounts
        open={isImportPopupOpened}
        close={closeImportAccount}
      />
      <RemoveEnterpriseAccount
        open={isRemovePopupOpened}
        close={closeRemoveAccount}
      />
      {isEditPopupOpened &&
        <EditEnterpriseAccount
          user={userData}
          open={isEditPopupOpened}
          close={closeEditAccount}
        />
      }
    </Grid>

    <Grid item xs={12}>
      <h3>Paid Staff Accounts</h3>
      {staffAccounts.length > 0 ? (
        <MaterialTable
          style={{ marginTop: '10px', marginBottom: '10px' }}
          icons={tableIcons}
          columns={staffTableHeader}
          title=""
          data={staffAccounts}
          options={{
            selection: false,
            sorting: true,
            search: true,
            paging: true,
            pageSizeOptions: [5, 10, 20, 50, 100],
            exportAllData: true,
            exportButton: true,
            /*rowStyle: x => {
              if (x.tableData.id % 2) {
                return { backgroundColor: '#f2f2f2' }
              }
            }*/
          }}
          onRowClick={(event, rowData) => openPopUp('staff', rowData)}
        />
      ) : 
        <Container style={{ textAlign: 'center', paddingTop: '50px' }}>
          <CircularProgress />
        </Container>
      }
    </Grid>

    {popUp && (
      <Grid item xs={12}>
        <DisplayList
          open={popUp}
          type={popUpType}
          rowData={rowData}
          onClose={closePopUp}
        />
      </Grid>
    )}
  </>);
}

export const EnterpriseAccountList = EnterpriseAccountListComponent;