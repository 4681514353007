import React, { useState } from "react";
import { Button, Grid } from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import { listOfSubscriptions } from "../../common/envConfig";

function PlansCompareComponent({ plansType, getStarted, subData, period }) {
  const [playersPlan, setPlayersPlan] = useState("free");
  const [coachesPlan, setCoachesPlan] = useState("premium");

  const displayButton = (plan) => {
    if (Object.keys(subData).length === 0) {
      return true;
    }
    if (subData?.platform !== "Stripe") {
      return true;
    }
    if (!subData?.productId?.includes(period?.toLowerCase())) {
      return true;
    }

    if (subData?.productId?.includes(plan)) {
      if (!subData?.autoRenewal && subData?.endDate < Date.now()) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  };

  return (
    <>
      <Grid container className="plan-compare-table">
        <Grid item xs={12} className="plan-compare-caption">
          Compare features
        </Grid>
        {plansType == "players" && (
          <>
            <Grid item xs={12} className="plan-compare-mobile">
              <Button
                onClick={() => setPlayersPlan("free")}
                className={`${playersPlan == "free" ? "selected" : ""}`}
              >
                Free
              </Button>
              <Button
                onClick={() => setPlayersPlan("student")}
                className={`${playersPlan == "student" ? "selected" : ""}`}
              >
                3D Player
              </Button>
            </Grid>
          </>
        )}
        {plansType == "coaches" && (
          <>
            <Grid item xs={12} className="plan-compare-mobile">
              {/*<Button onClick={() => setCoachesPlan('lite')} className={`${coachesPlan == 'lite' ? 'selected' : ''}`}>Lite</Button>*/}
              <Button
                onClick={() => setCoachesPlan("premium")}
                className={`${
                  coachesPlan == "premium" || coachesPlan == "lite"
                    ? "selected"
                    : ""
                }`}
              >
                Premium
              </Button>
              <Button
                onClick={() => setCoachesPlan("enterprise")}
                className={`${coachesPlan == "enterprise" ? "selected" : ""}`}
              >
                Enterprise
              </Button>
            </Grid>
          </>
        )}
        <>
          <Grid item xs={12} md={1} />
        </>
        <Grid item xs={5} md={3} className="plan-compare-features">
          <div className="plan-compare-header"></div>
          <div className="plan-compare-rows">
            <div className="plan-compare-row">Billed monthly</div>
            <div>Billed Annually</div>
            <div className="plan-compare-features-section">General</div>
            {plansType == "coaches" && (
              <>
                <div className="plan-compare-row"># of student accounts</div>
                <div># of coach licenses</div>
              </>
            )}
            {plansType == "coaches" && (
              <div className="plan-compare-row"># of devices</div>
            )}
            {plansType == "players" ? (
              <div className="plan-compare-row">Swings</div>
            ) : (
              <div>Swings</div>
            )}
            {plansType == "players" && <div>Goals</div>}
            <div className="plan-compare-features-section">Analysis Tools</div>
            <div className="plan-compare-row-double">Measurements</div>
            <div>Record video for 2D & 3D analysis</div>
            {plansType == "coaches" && (
              <>
                <div className="plan-compare-row">2D video drawing tools</div>
              </>
            )}
            {plansType == "players" ? (
              <div className="plan-compare-row">
                Auto-swing detection and voice guidance
              </div>
            ) : (
              <div>Auto-swing detection and voice guidance</div>
            )}
            <div className="plan-compare-features-section">Practice Tools</div>
            {plansType == "players" ? (
              <div className="plan-compare-row">
                Inbox to communicate with coaches/students
              </div>
            ) : (
              <div>Inbox to communicate with coaches/students</div>
            )}
            {plansType == "players" && (
              <>
                <div>Create reference swing</div>
              </>
            )}
            <div className="plan-compare-row">
              Compare two swings in 2D and in 3D
            </div>
            {plansType == "coaches" && (
              <div>Screen recording with voiceover</div>
            )}
            {plansType == "players" && (
              <>
                <div>Foresight Integration</div>
                <div>Sportsbox Drill Library</div>
              </>
            )}
            {plansType == "coaches" && (
              <>
                <div className="plan-compare-row">
                  Create and assign custom watchlists
                </div>
                <div>Foresight Integration</div>
                <div className="plan-compare-row-double">
                  Assign practice guides
                </div>
                <br />
                <div>Many more coming soon</div>
              </>
            )}
          </div>
        </Grid>
        {plansType == "coaches" && (
          <>
            {/*<Grid
          item
          xs={7}
          md={3}
          className={`plan-compare ${coachesPlan == 'lite' ? 'selected' : ''}`}
        >
          <div className="plan-compare-header">
            <div className="plan-compare-header-caption">Starters</div>
            <div className="plan-compare-header-name">Pro Lite</div>
            <div>New at coaching</div>
            <div>
              <Button
                onClick={() => getStarted('sportsbox_pro_lite', (subData && subData?.freeTrialUsed) ? false : true)}
                className="plan-button"
              >
                {(subData && subData.freeTrialUsed) ? 'Get Started' : 'Get 14-Day Trial'}
              </Button>
            </div>
          </div>
          <div className="plan-compare-rows">
            <div className="plan-compare-row">$65</div>
            <div>$65/mo<br />($650 billed annually)</div>
            <div className="plan-compare-features-section"></div>
            <div className="plan-compare-row">15 students</div>
            <div>1</div>
            <div className="plan-compare-row">1/user</div>
            <div>Sessions capped by cloud storage</div>
            <div className="plan-compare-row">50GB</div>
            <div className="plan-compare-features-section"></div>
            <div className="plan-compare-row">All but Kinematic Sequence</div>
            <div><CheckIcon className="plan-card-feature-mark" /></div>
            <div className="plan-compare-row"><CheckIcon className="plan-card-feature-mark" /></div>
            <div>—</div>
            <div className="plan-compare-features-section"></div>
            <div><CheckIcon className="plan-card-feature-mark" /></div>
            <div className="plan-compare-row"><CheckIcon className="plan-card-feature-mark" /></div>
            <div><CheckIcon className="plan-card-feature-mark" /></div>
            <div className="plan-compare-row"><CheckIcon className="plan-card-feature-mark" /></div>
            <div className="plan-compare-row-double">Access to over 50 practice guides to assign to your students -- Including assessment feature to measure their progress after each practice guide session</div>
            <div>
              <Button
                onClick={() => getStarted('sportsbox_pro_lite', (subData && subData?.freeTrialUsed) ? false : true)}
                className="plan-button"
              >
                {(subData && subData.freeTrialUsed) ? 'Get Started' : 'Get 14-Day Trial'}
              </Button>
            </div>
          </div>
        </Grid>*/}
            <Grid
              item
              xs={7}
              md={3}
              className={`plan-compare ${
                coachesPlan == "premium" ? "selected" : ""
              }`}
            >
              <div className="plan-compare-header">
                <div className="plan-compare-header-caption">Professionals</div>
                <div className="plan-compare-header-name">3D Pro</div>
                <div>Level up your lessions with 3D</div>
                {displayButton("_pro_") && (
                  <div>
                    <Button
                      onClick={() =>
                        getStarted(
                          listOfSubscriptions.PREMIUM,
                          subData && subData?.freeTrialUsed ? false : true
                        )
                      }
                      className="plan-button"
                    >
                      {subData && subData.freeTrialUsed
                        ? "Subscribe Now"
                        : "Try Free for 2 Weeks"}
                    </Button>
                  </div>
                )}
              </div>
              <div className="plan-compare-rows">
                <div className="plan-compare-row">$79.99</div>
                <div>
                  $66.66/mo
                  <br />
                  ($799.99 billed annually)
                </div>
                <div className="plan-compare-features-section"></div>
                <div className="plan-compare-row">Unlimited</div>
                <div>1</div>
                <div className="plan-compare-row">2/user</div>
                <div>Unlimited</div>
                <div className="plan-compare-features-section"></div>
                <div className="plan-compare-row">
                  All Trackers and Indicators
                </div>
                <div>
                  <CheckIcon className="plan-card-feature-mark" />
                </div>
                <div className="plan-compare-row">
                  <CheckIcon className="plan-card-feature-mark" />
                </div>
                <div>
                  <CheckIcon className="plan-card-feature-mark" />
                </div>
                <div className="plan-compare-features-section"></div>
                <div>
                  <CheckIcon className="plan-card-feature-mark" />
                </div>
                <div className="plan-compare-row">
                  <CheckIcon className="plan-card-feature-mark" />
                </div>
                <div>
                  <CheckIcon className="plan-card-feature-mark" />
                </div>
                <div className="plan-compare-row">
                  <CheckIcon className="plan-card-feature-mark" />
                </div>
                <div>Add-on</div>
                <div className="plan-compare-row">
                  <CheckIcon className="plan-card-feature-mark" />
                </div>
                <div className="plan-compare-row-double">
                  Access to over 50 practice guides to assign to your students
                  -- Including assessment feature to measure their progress
                  after each practice guide session
                </div>
                {displayButton("_pro_") && (
                  <div>
                    <Button
                      onClick={() =>
                        getStarted(
                          listOfSubscriptions.PREMIUM,
                          subData && subData?.freeTrialUsed ? false : true
                        )
                      }
                      className="plan-button"
                    >
                      {subData && subData.freeTrialUsed
                        ? "Subscribe Now"
                        : "Try Free for 2 Weeks"}
                    </Button>
                  </div>
                )}
              </div>
            </Grid>
            <Grid
              item
              xs={7}
              md={3}
              className={`plan-compare ${
                coachesPlan == "enterprise" ? "selected" : ""
              }`}
            >
              <div className="plan-compare-header">
                <div className="plan-compare-header-caption">Teams</div>
                <div className="plan-compare-header-name">Enterprise</div>
                <div>Contact sales</div>
                <div>
                  <Button
                    href="mailto:sales@sportsbox.ai"
                    className="plan-button-outlined"
                  >
                    Contact sales
                  </Button>
                </div>
              </div>
              <div className="plan-compare-rows">
                <div className="plan-compare-row">—</div>
                <div>—</div>
                <div className="plan-compare-features-section"></div>
                <div className="plan-compare-row">Unlimited</div>
                <div>3 or more</div>
                <div className="plan-compare-row">2/user</div>
                <div>Unlimited</div>
                <div className="plan-compare-features-section"></div>
                <div className="plan-compare-row">
                  All Trackers and Indicators
                </div>
                <div>
                  <CheckIcon className="plan-card-feature-mark" />
                </div>
                <div className="plan-compare-row">
                  <CheckIcon className="plan-card-feature-mark" />
                </div>
                <div>
                  <CheckIcon className="plan-card-feature-mark" />
                </div>
                <div className="plan-compare-features-section"></div>
                <div>
                  <CheckIcon className="plan-card-feature-mark" />
                </div>
                <div className="plan-compare-row">
                  <CheckIcon className="plan-card-feature-mark" />
                </div>
                <div>
                  <CheckIcon className="plan-card-feature-mark" />
                </div>
                <div className="plan-compare-row">
                  <CheckIcon className="plan-card-feature-mark" />
                </div>
                <div>Add-on</div>
                <div className="plan-compare-row">
                  <CheckIcon className="plan-card-feature-mark" />
                </div>
                <div className="plan-compare-row-double">
                  Access to over 50 practice guides to assign to your students
                  -- Including assessment feature to measure their progress
                  after each practice guide session
                </div>
                <div>
                  <Button
                    href="mailto:sales@sportsbox.ai"
                    className="plan-button-outlined"
                  >
                    Contact Sales
                  </Button>
                </div>
              </div>
            </Grid>
          </>
        )}
        {plansType == "players" && (
          <>
            <Grid
              item
              xs={7}
              md={3}
              className={`plan-compare ${
                playersPlan == "free" ? "selected" : ""
              }`}
            >
              <div className="plan-compare-header">
                <div className="plan-compare-header-caption">Free Plan</div>
                <div className="plan-compare-header-name">Free</div>
                <div>Try it out first!</div>
                <div>
                  <Button
                    onClick={() =>
                      getStarted(listOfSubscriptions.STUDENT_FREE, false)
                    }
                    className="plan-button"
                  >
                    Get started
                  </Button>
                </div>
              </div>
              <div className="plan-compare-rows">
                <div className="plan-compare-row">Free</div>
                <div>Free</div>
                <div className="plan-compare-features-section"></div>
                <div className="plan-compare-row">5 per month</div>
                <div>1 recommended goal</div>
                <div className="plan-compare-features-section"></div>
                <div className="plan-compare-row">
                  Limited to your goal
                </div>
                <div>—</div>
                <div className="plan-compare-row">—</div>
                <div className="plan-compare-features-section"></div>
                <div className="plan-compare-row">
                  <CheckIcon className="plan-card-feature-mark" />
                </div>
                <div>—</div>
                <div className="plan-compare-row">—</div>
                <div>—</div>
                <div className="plan-compare-row-double">—</div>
                <div>
                  <Button
                    onClick={() =>
                      getStarted(listOfSubscriptions.STUDENT_FREE, false)
                    }
                    className="plan-button"
                  >
                    Get started
                  </Button>
                </div>
              </div>
            </Grid>
            <Grid
              item
              xs={7}
              md={3}
              className={`plan-compare ${
                playersPlan == "student" ? "selected" : ""
              }`}
            >
              <div className="plan-compare-header">
                <div className="plan-compare-header-caption">Avid Golfers</div>
                <div className="plan-compare-header-name">3D Player</div>
                <div>Plan automatically renews until canceled</div>
                {displayButton(listOfSubscriptions.STUDENT_LITE) && (
                  <div>
                    <Button
                      onClick={() =>
                        getStarted(
                          listOfSubscriptions.STUDENT_LITE,
                          subData && subData?.freeTrialUsed ? false : true
                        )
                      }
                      className="plan-button"
                    >
                      Subscribe Now
                      {/*(subData && subData.freeTrialUsed) ? 'Subscribe Now' : 'Try Free for 2 Weeks'*/}
                    </Button>
                  </div>
                )}
              </div>
              <div className="plan-compare-rows">
                <div className="plan-compare-row">$15.99/mo</div>
                <div>
                  $9.16/mo
                  <br />
                  ($110 billed annually)
                </div>
                <div className="plan-compare-features-section"></div>
                <div className="plan-compare-row">Unlimited</div>
                <div>Unlimited Sportsbox and Custom Goals</div>
                <div className="plan-compare-features-section"></div>
                <div className="plan-compare-row">
                  Limited
                  <br />
                  (Chest Turn, Pelvis Turn, Chest Sway, Pelvis Sway, Chest Lift,
                  Pelvis Lift)
                </div>
                <div>
                  <CheckIcon className="plan-card-feature-mark" />
                </div>
                <div className="plan-compare-row">
                  <CheckIcon className="plan-card-feature-mark" />
                </div>
                <div className="plan-compare-features-section"></div>
                <div className="plan-compare-row">
                  <CheckIcon className="plan-card-feature-mark" />
                </div>
                <div>
                  <CheckIcon className="plan-card-feature-mark" />
                </div>
                <div className="plan-compare-row">
                  <CheckIcon className="plan-card-feature-mark" />
                </div>
                <div>Add-on</div>
                <div className="plan-compare-row-double">
                  Access to +50 practice guides.
                  <br />
                  Assessment feature to measure progress after each practice
                  guide/session
                </div>
                {displayButton(listOfSubscriptions.STUDENT_LITE) && (
                  <div>
                    <Button
                      disabled={subData?.productId?.includes("_student_lite_")}
                      onClick={() =>
                        getStarted(
                          listOfSubscriptions.STUDENT_LITE,
                          subData && subData?.freeTrialUsed ? false : true
                        )
                      }
                      className="plan-button"
                    >
                      Subscribe Now
                      {/*(subData && subData.freeTrialUsed) ? 'Subscribe Now' : 'Try Free for 2 Weeks'*/}
                    </Button>
                  </div>
                )}
              </div>
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
}

export default PlansCompareComponent;
