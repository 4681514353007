import React, { Component } from "react";
import {
  Card,
  CardContent,
  Grid,
  TextField,
  Link,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Container,
  CircularProgress,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { ToggleButtonGroup, ToggleButton } from "@material-ui/lab";
import PropTypes from "prop-types";
import { Link as RouterLink, withRouter } from "react-router-dom";
import firebase, {
  axiosWithToken,
  functionBaseUrl,
} from "../../../common/firebase";
import axios from "axios";
import "./calendar.css";
import Swal from "sweetalert2";
import PlansView from "../../plans/PlansView";
import { StripeSubscription } from "../../../components/stripeSubscription";
import { listOfSubscriptions } from "../../../common/envConfig";
import moment from "moment";
const freeTrialPromoCodes = ["5IRON3D", "SB3DDryvebox", "SB3DPNGC", "MICHELLE"];

class UserInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      uid: "",
      userFirstName: "",
      userLastName: "",
      email: "",
      password: "",
      confirmPassword: "",
      tosAgree: false,
      //ppAgree: false,
      userConsent: true,
      authOption: "email",
      code: "",
      checkCode: "",
      role: "",
      tempDOB: "",
      selectedPlan: "",
      paymentInterval: "monthly",
      cardNumber: "",
      expirationDate: "",
      securityCode: "",
      streetName: "",
      zip: "",
      amount: 0,
      currentStep: "plansTable",
      billing: "monthly",
      studentLiteMonthly: "15.99",
      liteMonthly: "65", //'52',
      plusMonthly: "129",
      premiumMonthly: "189", //'151.20',
      studentLiteAnnually: "110",
      liteAnnually: "650", //'520',
      plusAnnually: "1290",
      premiumAnnually: "1600", //'1134',
      enterpriseAnnually: "3500",
      studentLiteAnnuallyPerMonth: "9.12",
      liteAnnuallyPerMonth: "54", //'43',
      plusAnnuallyPerMonth: "108",
      premiumAnnuallyPerMonth: "158", //'94.50',
      studentLiteAnnuallyDiscount: "71.40",
      liteAnnuallyDiscount: "17",
      plusAnnuallyDiscount: "17",
      premiumAnnuallyDiscount: "29.50",
      txToken: "",
      isLoading: false,
      promoCode: "",
      promoCodeApplied: "",
      newPlanCode: "UNLOCK3D",
      showPromoCode: false,
      agree: false,
      startDate: this.dateFormatter(Date.now()),
      /*q1: '',
      q2: '',
      q3: '',
      q4: '',
      openCheck: true,*/
      referralCode: "",
    };
  }
  componentDidMount() {
    localStorage.setItem("freeTrial", "false");
    let referralCode = window.location.href;
    if (referralCode.includes("REFERRALCODE=")) {
      referralCode = referralCode.split("REFERRALCODE=")[1];
      this.setState({ referralCode: referralCode });
    }
  }
  fixPrice = (x, d) => {
    return Number(x)
      .toFixed(d)
      .replace(/\.?0+$/, "");
  };
  dobFormat = (dob) => {
    let [year, month, day] = dob.split("-");
    return month + "/" + day + "/" + year;
  };
  createUserEntry = async (uid) => {
    await axios(functionBaseUrl + "/api/userMobile", {
      method: "POST",
      data: {
        uid: uid,
        firstName: this.state.userFirstName.replace(/ /g, ""),
        lastName: this.state.userLastName.replace(/ /g, ""),
        userName:
          this.state.userFirstName.replace(/ /g, "") +
          " " +
          this.state.userLastName.replace(/ /g, ""),
        email: this.state.email.toLowerCase().replace(/ /g, ""),
        averageScore: 0,
        goals: [],
        dob: this.dobFormat(this.props.values.dob),
        height: this.props.values.height,
        hipSize: "7.2",
        gender: this.props.values.gender,
        dominantHand: this.props.values.dominantHand,
        picture: "",
        role: "Sportsbox-Instructor",
        weeklyPractices: 0,
        coachAddress: { city: "", state: "", country: "" },
        coachFacility: [
          { name: "", address: "", students: 0, lessons: 0, environment: [] },
        ],
        certification: [],
        userConsent: this.state.userConsent,
      },
    }).catch((err) => {
      console.log(err);
    });

    if (this.state.selectedPlan.includes("free")) {
      await axiosWithToken(functionBaseUrl + "/api/users/" + uid, {
        method: "patch",
        data: {
          userInfo: {
            subscriptionType: listOfSubscriptions.STUDENT_FREE,
          },
        },
      });
      await axiosWithToken(functionBaseUrl + "/api/userEmailVerf", {
        method: "PATCH",
        data: {
          id: uid,
          status: false,
        },
      });
    }
  };
  createSubscription = async (data) => {
    let referralPC = localStorage.getItem("promoCode");
    await axiosWithToken(functionBaseUrl + "/api/subscription", {
      method: "POST",
      data: {
        userId: this.state.uid,
        plan: this.state.selectedPlan,
        planDescription: "",
        planType: this.state.paymentInterval,
        transactionId: data.id, // From Stripe
        payload: "",
        packageId: "",
        orderId: "",
        amount: (data.amount / 100).toFixed(2), // From Stripe
        startDate: String(Date.now()),
        endDate: this.getEndDate(
          this.state.selectedPlan + "_" + this.state.paymentInterval
        ),
        lastUpdated: String(Date.now()),
        autoRenewal: true,
        platform: "Stripe",
        promoCode: referralPC ? referralPC : this.state.promoCodeApplied,
        referralCode: this.state.referralCode,
        productId: this.getProductId(
          this.state.selectedPlan,
          this.state.paymentInterval
        ),
        freeTrialUsed:
          localStorage.getItem("freeTrial") === "true" ? true : false,
      },
    })
      .then(async (response) => {
        if (localStorage.getItem("freeTrial") === "true") {
          await axiosWithToken(functionBaseUrl + "/api/hubUser", {
            method: "POST",
            data: {
              firstName: this.state.userFirstName.replace(/ /g, ""),
              lastName: this.state.userLastName.replace(/ /g, ""),
              email: this.state.email.toLowerCase().replace(/ /g, ""),
              subscriptionType: this.getPlan(this.state.selectedPlan),
              freeTrialUsed: true,
            },
          }).catch((err) => {
            console.log(err);
          });
        }
        if (
          localStorage.getItem("freeTrial") !== "true" &&
          this.state.referralCode !== ""
        ) {
          await axiosWithToken(functionBaseUrl + "/api/referral", {
            method: "POST",
            data: {
              referralCode: this.state.referralCode,
              firstName: this.state.userFirstName.replace(/ /g, ""),
              lastName: this.state.userLastName.replace(/ /g, ""),
              email: this.state.email.toLowerCase().replace(/ /g, ""),
              externalIdentifier: response.data.id,
              amount: (data.amount / 100).toFixed(2),
              note:
                this.getPlan(this.state.selectedPlan) +
                " " +
                this.state.paymentInterval,
            },
          })
            .then(async (result) => {
              await firebase
                .firestore()
                .collection("subscriptions")
                .doc(response.data.id)
                .update({
                  referrals: [result.data.referral.id],
                  referralCode: this.state.referralCode,
                });
            })
            .catch((err) => {
              console.log(err);
            });
        }
        if (
          localStorage.getItem("freeTrial") !== "true" &&
          this.state.referralCode === "" &&
          referralPC &&
          referralPC !== ""
        ) {
          const referral = await firebase
            .firestore()
            .collection("users")
            .where("referralCode", "==", referralPC)
            .get();
          if (referral.size > 0) {
            await axiosWithToken(functionBaseUrl + "/api/referral", {
              method: "POST",
              data: {
                referralCode: referralPC,
                firstName: this.state.userFirstName.replace(/ /g, ""),
                lastName: this.state.userLastName.replace(/ /g, ""),
                email: this.state.email.toLowerCase().replace(/ /g, ""),
                externalIdentifier: response.data.id,
                amount: (data.amount / 100).toFixed(2),
                note:
                  this.getPlan(this.state.selectedPlan) +
                  " " +
                  this.state.paymentInterval,
              },
            })
              .then(async (result) => {
                await firebase
                  .firestore()
                  .collection("subscriptions")
                  .doc(response.data.id)
                  .update({
                    referrals: [result.data.referral.id],
                    referralCode: referralPC,
                  });
              })
              .catch((err) => {
                console.log(err);
              });
          }
        }
        await axiosWithToken(functionBaseUrl + "/api/userEmailVerf", {
          method: "PATCH",
          data: {
            id: this.state.uid,
            status: true,
          },
        })
          .then(async () => {
            await firebase
              .auth()
              .signInWithEmailAndPassword(
                this.state.email.toLowerCase().replace(/ /g, ""),
                this.state.password
              );
            await this.props.history.push("/dashboard");
            localStorage.setItem("showPopUp", true);
            window.location.reload();
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  signIn = async () => {
    await firebase
      .auth()
      .signInWithEmailAndPassword(
        this.state.email.toLowerCase().replace(/ /g, ""),
        this.state.password
      );
    this.props.history.push("/dashboard");
  };

  getEndDate = (productId) => {
    let endDate = "";
    if (
      productId.includes("yearly") ||
      productId.includes("annually") ||
      productId.includes("enterprise")
    ) {
      endDate = String(new Date().setDate(new Date().getDate() + 365));
    }
    if (productId.includes("monthly")) {
      endDate = String(new Date().setDate(new Date().getDate() + 31));
    }

    return endDate;
  };

  getProductId = (plan, billing) => {
    let productId = "";
    if (plan.includes("studentLite")) {
      productId = "sportsbox_student_lite_" + billing;
    } else if (plan.includes("enterprise")) {
      productId = listOfSubscriptions.ENTERPRISE_MASTER;
    } else if (plan.includes("free")) {
      productId = listOfSubscriptions.STUDENT_FREE;
    } else if (plan.includes("premium")) {
      productId = "sportsbox_pro_premium_" + billing;
    } else {
      productId = "sportsbox_pro_lite_" + billing;
    }
    return productId;
  };

  handlePlanChange = (event) => {
    this.setState({ selectedPlan: event.target.value });
  };
  handlePaymentIntervalChange = (event, newInterval) => {
    if (newInterval) {
      this.setState({ paymentInterval: newInterval });
    }
  };
  handleFirstNameChange = (event) => {
    this.setState({ userFirstName: event.target.value });
  };
  handleLastNameChange = (event) => {
    this.setState({ userLastName: event.target.value });
  };
  handleCardNumberChange = (event) => {
    this.setState({ cardNumber: event.target.value });
  };
  handleExpirationDateChange = (event) => {
    this.setState({ expirationDate: event.target.value });
  };
  handleSecurityCodeChange = (event) => {
    this.setState({ securityCode: event.target.value });
  };
  handleStreetNameChange = (event) => {
    this.setState({ streetName: event.target.value });
  };
  handleZipChange = (event) => {
    this.setState({ zip: event.target.value });
  };
  handleClickShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };
  handleAgree = () => {
    this.setState({ agree: !this.state.agree });
  };
  handleTOSAgree = () => {
    this.setState({ tosAgree: !this.state.tosAgree });
  };
  handleUserConsent = () => {
    this.setState({ userConsent: !this.state.userConsent });
  };
  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  handleChange = (prop) => (event) => {
    if (prop === "dob") {
      this.setState({ tempDOB: event.target.value });
    }
    if (prop === "gender" || prop === "height" || prop === "dominantHand") {
      const options = { year: "numeric", month: "numeric", day: "numeric" };
      const date = new Date(
        new Date().setFullYear(new Date().getFullYear() - 13)
      )
        .toLocaleDateString("en-ZA", options)
        .replaceAll("/", "-");
      if (Date.parse(this.state.tempDOB) > Date.parse(date)) {
        Swal.fire({
          title:
            '<p style="font-size:70%;">Sorry, you are not eligible for a Sportsbox.ai account at this time.</p>',
          confirmButtonText: "Ok",
          allowOutsideClick: false,
        }).then(() => {
          window.location.href = "https://3dgolf.sportsbox.ai/welcome";
        });
      }
    }
    this.props.setValues({ ...this.props.values, [prop]: event.target.value });
  };
  handleChangeCouponCode = (prop) => (event) => {
    this.setState({ [prop]: event.target.value });
  };
  handleContactChange = (event) => {
    // The phone number field has the event as the value itself
    this.props.setValues({ ...this.props.values, contact: event });
  };
  handleLocalChange = (prop) => (event) => {
    const options = { year: "numeric", month: "numeric", day: "numeric" };
    const date = new Date(new Date().setFullYear(new Date().getFullYear() - 13))
      .toLocaleDateString("en-ZA", options)
      .replaceAll("/", "-");
    if (Date.parse(this.state.tempDOB) > Date.parse(date)) {
      Swal.fire({
        title:
          '<p style="font-size:70%;">Sorry, you are not eligible for a Sportsbox.ai account at this time.</p>',
        confirmButtonText: "Ok",
        allowOutsideClick: false,
      }).then(() => {
        window.location.href = "https://3dgolf.sportsbox.ai/welcome";
      });
    }
    this.setState({ [prop]: event.target.value });
  };
  handleCheckboxChange = (event) => {
    this.setState({ hasDisability: event.target.checked });
  };
  handleOptionChange = (event, option) => {
    this.setState({ authOption: option });
  };

  checkUserAcc = async () => {
    try {
      const providers = await firebase
        .auth()
        .fetchSignInMethodsForEmail(
          this.state.email.toLowerCase().replace(/ /g, "")
        );
      // Handle case for when a user is in authentication but does not have a user document created in firebase
      //const snapUser = await firebase.firestore().collection('users').where('email', '==', this.state.email.toLowerCase().replace(/ /g, '')).get();
      if (providers.length > 0) {
        await axios(functionBaseUrl + "/api/userInAuth", {
          method: "POST",
          data: {
            email: this.state.email.toLowerCase().replace(/ /g, ""),
          },
        }).catch((err) => {
          console.log(err);
        });

        return true;
      }
      return providers.length === 0;
    } catch (err) {
      console.log(err);
    }
  };

  validatePassword = (password) => {
    const verify = /^(?=.*\d)(?=.*[.!@#$%^&*])(?=.*[a-z]).{8,}$/;
    return verify.test(password);
  };

  validateEmail = (email) => {
    const verify = /\S+@\S+\.\S+/;
    return verify.test(email);
  };

  handleStartStep = async () => {
    if (!this.state.userFirstName) {
      Swal.fire({
        title: '<p style="font-size:70%;">Please enter your First Name</p>',
        icon: "warning",
        confirmButtonText: "Ok",
        allowOutsideClick: true,
      });
      return;
    }
    if (!this.state.userLastName) {
      Swal.fire({
        title: '<p style="font-size:70%;">Please enter your Last Name</p>',
        icon: "warning",
        confirmButtonText: "Ok",
        allowOutsideClick: true,
      });
      return;
    }
    if (!this.state.email) {
      Swal.fire({
        title: '<p style="font-size:70%;">Please enter your Email</p>',
        icon: "warning",
        confirmButtonText: "Ok",
        allowOutsideClick: true,
      });
      return;
    }
    if (!this.validateEmail(this.state.email)) {
      Swal.fire({
        title: '<p style="font-size:70%;">Please enter a valid Email</p>',
        icon: "warning",
        confirmButtonText: "Ok",
        allowOutsideClick: true,
      });
      return;
    }
    if (!this.state.tempDOB) {
      Swal.fire({
        title: '<p style="font-size:70%;">Please enter your Date of Birth</p>',
        icon: "warning",
        confirmButtonText: "Ok",
        allowOutsideClick: true,
      });
      return;
    }
    /*if (this.state.selectedPlan === 'free') {
      if (!this.props.values.gender) {
        Swal.fire({
          title: '<p style="font-size:70%;">Please enter Gender</p>',
          icon: 'warning',
          confirmButtonText: 'Ok',
          allowOutsideClick: true
        })
        return;
      }
      if (!this.props.values.height) {
        Swal.fire({
          title: '<p style="font-size:70%;">Please enter Height</p>',
          icon: 'warning',
          confirmButtonText: 'Ok',
          allowOutsideClick: true
        })
        return;
      }
      if (!this.props.values.dominantHand) {
        Swal.fire({
          title: '<p style="font-size:70%;">Please enter Dominant Hand</p>',
          icon: 'warning',
          confirmButtonText: 'Ok',
          allowOutsideClick: true
        })
        return;
      }
    }*/
    if (!this.state.password) {
      Swal.fire({
        title: '<p style="font-size:70%;">Please enter Password</p>',
        icon: "warning",
        confirmButtonText: "Ok",
        allowOutsideClick: true,
      });
      return;
    }
    if (!this.validatePassword(this.state.password)) {
      Swal.fire({
        title: '<p style="font-size:70%;">Password must contain:</p>',
        html: `
        <ul>
          <li>• At least 8 characters</li>
          <li>• At least one number</li>
          <li>• A special character (e.g., !@#$%&*)</li>
        </ul>
        `,
        icon: "warning",
        confirmButtonText: "Ok",
        allowOutsideClick: true,
      });
      return;
    }
    if (!this.state.confirmPassword) {
      Swal.fire({
        title:
          '<p style="font-size:70%;">Please enter Password Confirmation</p>',
        icon: "warning",
        confirmButtonText: "Ok",
        allowOutsideClick: true,
      });
      return;
    }
    if (this.state.password !== this.state.confirmPassword) {
      Swal.fire({
        title: '<p style="font-size:70%;">Passwords do not match</p>',
        icon: "warning",
        confirmButtonText: "Ok",
        allowOutsideClick: true,
      });
      return;
    }
    this.setState({ isLoading: true });
    const noUsers = await this.checkUserAcc();
    if (noUsers) {
      await firebase
        .auth()
        .createUserWithEmailAndPassword(
          this.state.email.toLowerCase().replace(/ /g, ""),
          this.state.password
        )
        .then(async (creds) => {
          if (this.state.selectedPlan !== "free") {
            await axiosWithToken(functionBaseUrl + "/api/userEmailVerf", {
              method: "PATCH",
              data: {
                id: creds.user.uid,
                status: true,
              },
            });
          }
          await this.createUserEntry(creds.user.uid);
          await creds.user.sendEmailVerification();
          if (this.state.selectedPlan !== "free") {
            this.setState({
              uid: creds.user.uid,
              isLoading: false,
              currentStep: "plans",
            });
          } else {
            this.setState({ uid: creds.user.uid, isLoading: false });
            await this.signIn();
          }
        })
        .catch((err) => {
          this.setState({ isLoading: false });
          Swal.fire({
            title: '<p style="font-size:70%;">' + err.message + "</p>",
            icon: "warning",
            confirmButtonText: "Ok",
            allowOutsideClick: false,
          });
          return;
        });
    } else {
      Swal.fire({
        title:
          '<p style="font-size:70%;">User with provided email already exists</p>',
        confirmButtonText: "Ok",
        allowOutsideClick: true,
      });
      this.setState({ isLoading: false });
    }
  };

  dateFormatter = (date) => {
    return moment(String(date), "x").format("MM/DD/YYYY");
  };

  updateTablePrices = () => {
    if (this.state.liteMonthly !== "65") {
      document.getElementById("liteMonthly").style.color = "green";
    } else {
      document.getElementById("liteMonthly").style.color = "gray";
    }
    if (this.state.premiumMonthly !== "189") {
      document.getElementById("premiumMonthly").style.color = "green";
    } else {
      document.getElementById("premiumMonthly").style.color = "gray";
    }

    if (this.state.liteAnnuallyDiscount !== "17") {
      document.getElementById("liteAnnuallyDiscount").style.color = "green";
    } else {
      document.getElementById("liteAnnuallyDiscount").style.color = "red";
    }
    if (this.state.premiumAnnuallyDiscount !== "17") {
      document.getElementById("premiumAnnuallyDiscount").style.color = "green";
    } else {
      document.getElementById("premiumAnnuallyDiscount").style.color = "red";
    }

    if (this.state.liteAnnually !== "650") {
      document.getElementById("liteAnnually").style.color = "green";
    } else {
      document.getElementById("liteAnnually").style.color = "red";
    }
    if (this.state.premiumAnnually !== "1600") {
      document.getElementById("premiumAnnually").style.color = "green";
    } else {
      document.getElementById("premiumAnnually").style.color = "red";
    }
  };

  validatePromoCode = () => {
    if (
      this.state.promoCodeApplied !== this.state.promoCode &&
      this.state.promoCode.length > 0
    ) {
      if (this.state.promoCode === this.state.newPlanCode) {
        this.setState({ promoCodeApplied: this.state.promoCode });
        Swal.fire({
          title:
            '<p style="font-size:70%;">Promo code was successfully applied!</p>',
          icon: "success",
          confirmButtonText: "Ok",
          allowOutsideClick: false,
          timer: 2000,
        });
      } else {
        axios(functionBaseUrl + "/api/validatePromoCode", {
          method: "post",
          data: {
            code: this.state.promoCode,
            userEmail: this.state.email.toLowerCase().replace(/ /g, ""),
            timeOffset: 1,
          },
        })
          .then((response) => {
            this.setState({ promoCodeApplied: this.state.promoCode });
            if (response.data.validCode) {
              this.setState({
                liteMonthly: "65",
                premiumMonthly: "189",
                liteAnnually: "650",
                premiumAnnually: "1890",
                liteAnnuallyPerMonth: "54",
                premiumAnnuallyPerMonth: "158",
                liteAnnuallyDiscount: "17",
                premiumAnnuallyDiscount: "17",
              });
              if (response.data.data.applicability.includes("all")) {
                if (response.data.data.type === "percentage") {
                  this.setState({
                    liteMonthly:
                      ("65" * (100 - response.data.data.discount)) / 100,
                    premiumMonthly:
                      ("189" * (100 - response.data.data.discount)) / 100,
                    liteAnnually:
                      ("650" * (100 - response.data.data.discount)) / 100,
                    premiumAnnually:
                      ("1890" * (100 - response.data.data.discount)) / 100,
                  });
                } else {
                  this.setState({
                    liteMonthly: "65" - response.data.data.discount,
                    premiumMonthly: "189" - response.data.data.discount,
                    liteAnnually: "650" - response.data.data.discount,
                    premiumAnnually: "1890" - response.data.data.discount,
                  });
                }
              }
              if (response.data.data.applicability.includes("allMonthly")) {
                if (response.data.data.type === "percentage") {
                  this.setState({
                    liteMonthly:
                      ("65" * (100 - response.data.data.discount)) / 100,
                    premiumMonthly:
                      ("189" * (100 - response.data.data.discount)) / 100,
                  });
                } else {
                  this.setState({
                    liteMonthly: "65" - response.data.data.discount,
                    premiumMonthly: "189" - response.data.data.discount,
                  });
                }
              }
              if (response.data.data.applicability.includes("allAnnually")) {
                if (response.data.data.type === "percentage") {
                  this.setState({
                    liteAnnually:
                      ("650" * (100 - response.data.data.discount)) / 100,
                    premiumAnnually:
                      ("1890" * (100 - response.data.data.discount)) / 100,
                  });
                } else {
                  this.setState({
                    liteAnnually: "650" - response.data.data.discount,
                    premiumAnnually: "1890" - response.data.data.discount,
                  });
                }
              }
              if (
                response.data.data.applicability.includes(
                  listOfSubscriptions.LITE_MONTHLY
                ) ||
                response.data.data.applicability.includes(
                  "sportsbox_golf_lite_monthly"
                )
              ) {
                if (response.data.data.type === "percentage") {
                  this.setState({
                    liteMonthly:
                      ("65" * (100 - response.data.data.discount)) / 100,
                  });
                } else {
                  this.setState({
                    liteMonthly: "65" - response.data.data.discount,
                  });
                }
              }
              if (
                response.data.data.applicability.includes(
                  listOfSubscriptions.PREMIUM_MONTHLY
                )
              ) {
                if (response.data.data.type === "percentage") {
                  this.setState({
                    premiumMonthly:
                      ("189" * (100 - response.data.data.discount)) / 100,
                  });
                } else {
                  this.setState({
                    premiumMonthly: "189" - response.data.data.discount,
                  });
                }
              }
              if (
                response.data.data.applicability.includes(
                  listOfSubscriptions.LITE_ANNUALLY
                ) ||
                response.data.data.applicability.includes(
                  "sportsbox_pro_lite_yearly"
                ) ||
                response.data.data.applicability.includes(
                  "sportsbox_golf_lite_annually"
                ) ||
                response.data.data.applicability.includes(
                  "sportsbox_golf_lite_yearly"
                )
              ) {
                if (response.data.data.type === "percentage") {
                  this.setState({
                    liteAnnually:
                      ("650" * (100 - response.data.data.discount)) / 100,
                  });
                } else {
                  this.setState({
                    liteAnnually: "650" - response.data.data.discount,
                  });
                }
              }
              if (
                response.data.data.applicability.includes(
                  listOfSubscriptions.PREMIUM_ANNUALLY
                ) ||
                response.data.data.applicability.includes(
                  "sportsbox_pro_premium_yearly"
                )
              ) {
                if (response.data.data.type === "percentage") {
                  this.setState({
                    premiumAnnually:
                      ("1890" * (100 - response.data.data.discount)) / 100,
                  });
                } else {
                  this.setState({
                    premiumAnnually: "1890" - response.data.data.discount,
                  });
                }
              }
              this.setState({
                liteAnnuallyPerMonth: Math.ceil(this.state.liteAnnually / 12),
                premiumAnnuallyPerMonth: Math.ceil(
                  this.state.premiumAnnually / 12
                ),
                liteAnnuallyDiscount: Math.ceil(
                  ((780 - this.state.liteAnnually) / 780) * 100
                ),
                premiumAnnuallyDiscount: Math.ceil(
                  ((2268 - this.state.premiumAnnually) / 2268) * 100
                ),
              });
              this.updateTablePrices();
              Swal.fire({
                title:
                  '<p style="font-size:70%;">Promo code was successfully applied!</p>',
                icon: "success",
                confirmButtonText: "Ok",
                allowOutsideClick: false,
                timer: 2000,
              });
            } else {
              this.setState({
                liteMonthly: "65",
                premiumMonthly: "189",
                liteAnnually: "650",
                premiumAnnually: "1890",
                liteAnnuallyPerMonth: "54",
                premiumAnnuallyPerMonth: "158",
                liteAnnuallyDiscount: "17",
                premiumAnnuallyDiscount: "17",
              });
              this.updateTablePrices();
              Swal.fire({
                title:
                  '<p style="font-size:70%;">' + response.data.message + "</p>",
                icon: "error",
                confirmButtonText: "Ok",
                allowOutsideClick: false,
                timer: 2000,
              });
              this.setState({ promoCodeApplied: "" });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    } else {
      if (this.state.promoCode.length > 0) {
        Swal.fire({
          title: '<p style="font-size:70%;">Promo code is already applied</p>',
          icon: "error",
          confirmButtonText: "Ok",
          allowOutsideClick: false,
          timer: 2000,
        });
      }
    }
  };

  handlePlansStep = async () => {
    if (!this.state.selectedPlan) {
      Swal.fire({
        title: '<p style="font-size:70%;">Please choose a plan</p>',
        confirmButtonText: "Ok",
        allowOutsideClick: true,
      });
      return;
    }
    if (this.state.selectedPlan === "free") {
      await axiosWithToken(functionBaseUrl + "/api/users/" + this.state.uid, {
        method: "PATCH",
        data: {
          userInfo: {
            subscriptionType: listOfSubscriptions.STUDENT_FREE,
            role: "Sportsbox-User",
          },
        },
      }).catch((err) => {
        console.log(err);
      });
    }
    this.setState({ currentStep: "details" });
  };

  getPlan = (plan) => {
    if (plan.includes("studentLite")) {
      return "3D Player";
    } else if (plan.includes("lite")) {
      return "3D Pro";
    } else if (plan.includes("premium")) {
      return "3D Pro";
    } else if (plan.includes("enterprise_master")) {
      return "Enterprise Master";
    } else if (plan.includes("enterprise_coach")) {
      return "Enterprise Coach";
    } else {
      return "Free";
    }
  };

  /*checkAnswers = () => {
    if (this.state.q1 === '3' && this.state.q2 === '1' && this.state.q3 === '6' && this.state.q4 === '3') {
      this.setState({ openCheck: false });
      Swal.fire({
        title: '<p style="font-size:70%;">Congratulations! You have successfully completed the questionnaire</p>',
        icon: 'success',
        timer: 3000,
        showConfirmButton: true,
        confirmButtonText: "Continue",
        showCancelButton: false,
        customClass : { 
          container : 'my-swal' 
        },
      });
    } else {
      let wrongAnswers = [];
      if (this.state.q1 !== '3') {
        wrongAnswers.push('1')
      }
      if (this.state.q2 !== '1') {
        wrongAnswers.push('2')
      }
      if (this.state.q3 !== '6') {
        wrongAnswers.push('3')
      }
      if (this.state.q4 !== '3') {
        wrongAnswers.push('4')
      }
      Swal.fire({
        title: '<p style="font-size:70%;">Sorry, your ' + (wrongAnswers.length > 1 ? 'answers' : 'answer') + ' for: ' + wrongAnswers.toString() + (wrongAnswers.length > 1 ? ' are' : ' is') + ' not correct. Please try again!</p>',
        icon: 'error',
        showConfirmButton: true,
        showCancelButton: false,
        confirmButtonText: "Ok",
        customClass : { 
          container : 'my-swal' 
        },
      });
    }
  }*/

  handlePlanButton = (key) => () => {
    if (key.includes("Trial")) {
      localStorage.setItem("freeTrial", "true");
      key = key.replace("Trial", "");
    }
    this.setState({ selectedPlan: key, currentStep: "start" });
  };
  render() {
    const { values, classes } = this.props;
    let couponCode = window.location.href;
    couponCode = couponCode.split("couponCode=")[1];
    return (
      <div className={classes.paper}>
        {this.state.currentStep !== "plansTable" && (
          <>
            <img
              src="/images/logos/SportsboxLogo.png"
              alt="logo"
              width={300}
              style={{ marginTop: 10 }}
            />
            <Typography component="h1" variant="h5">
              Create Account
            </Typography>
          </>
        )}
        {this.state.currentStep === "plansTable" && !this.state.isLoading && (
          <PlansView
            subscriptionType={this.state.subscriptionType}
            billing={this.state.billing}
            promoCode={this.state.promoCode}
            promoCodeApplied={this.state.promoCodeApplied}
            handleChange={(field) => (e) =>
              this.setState({ [field]: e.target.value })}
            validatePromoCode={this.validatePromoCode}
            actions={{
              free: this.handlePlanButton("free"),
              practice: this.handlePlanButton("studentLite"),
              lite: this.handlePlanButton("lite"),
              premium: this.handlePlanButton("premium"),
              liteTrial: this.handlePlanButton("liteTrial"),
              premiumTrial: this.handlePlanButton("premiumTrial"),
              enterprise: () =>
                window.open(
                  "https://sportsbox-21571110.hubspotpagebuilder.com/sportsbox-3d-golf-enterprise-form",
                  "_blank"
                ),
            }}
            error={this.state.error}
            loading={this.state.loading}
            success={this.state.success}
            useTabs
            disableBillingOptions
            tab={window.location.href.includes("student") ? "student" : "coach"}
          />
        )}
        <Container
          style={{ display: this.state.isLoading ? "none" : "" }}
          maxWidth="xs"
        >
          <form className={this.props.classes.form}>
            {!this.state.isLoading && (
              <Grid container spacing={2}>
                {this.state.currentStep === "start" && (
                  <>
                    {/*this.state.selectedPlan === 'studentLite' && (
                    <Dialog
                      fullWidth
                      open={this.state.openCheck}
                      aria-labelledby='form-dialog-title'>
                      <DialogTitle id={'form-dialog-title'}>
                        <h4>PLEASE FIRST WATCH THIS 3-MINUTE QUICK START VIDEO AND THEN ANSWER THE QUESTIONS BELOW</h4>
                      </DialogTitle>
                      <DialogContent>
                        <Grid
                          container
                          spacing={2}
                        >
                          <Grid
                            item
                            xs={12}
                          >
                            <video title='practiceVid' width="100%" height={window.matchMedia('(max-width: 700px)').matches ? "200" : "400"} src="/images/3DPracticePlan.mp4#t=0.5" scrolling="no" controlsList="nodownload" controls="controls" allowfullscreen preload="metadata" autoplay playsInline type="video/mp4" />
                          </Grid>
                          <Grid
                            item
                            xs={12}
                          >
                            <Typography>
                            <b>Please answer these questions:</b>
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xs={6}
                          >
                            <FormControl component="fieldset">
                              <FormLabel component="legend"><b>1. What type of filming angle is required to capture a swing for analysis in Sportsbox?</b></FormLabel>
                              <RadioGroup aria-label="1" name="q1" value={this.state.q1} onChange={this.handleLocalChange('q1')}>
                                <FormControlLabel value="1" control={<Radio />} label="Down-the-Line (Camera pointed at target)" />
                                <hr />
                                <FormControlLabel value="2" control={<Radio />} label="45° angle (Camera pointed at golfer from a 45-degree angle to the right of the golfer if facing forward)" />
                                <hr />
                                <FormControlLabel value="3" control={<Radio />} label="Face-On (Golfer facing the camera)" />
                                <hr />
                                <FormControlLabel value="4" control={<Radio />} label="Behind (Camera behind the golfer pointing at the backside of the golfer)" />
                                <hr />
                              </RadioGroup>
                            </FormControl>
                          </Grid>
                          <Grid
                            item
                            xs={6}
                          >
                            <FormControl component="fieldset">
                              <FormLabel component="legend"><b>2. When importing a swing video into the app, it must be in slow motion</b></FormLabel>
                              <RadioGroup aria-label="2" name="q2" value={this.state.q2} onChange={this.handleLocalChange('q2')}>
                                <FormControlLabel value="1" control={<Radio />} label="True" />
                                <hr />
                                <FormControlLabel value="2" control={<Radio />} label="False" />
                                <hr />
                              </RadioGroup>
                            </FormControl>
                          </Grid>
                          <Grid
                            item
                            xs={6}
                          >
                            <FormControl component="fieldset">
                              <FormLabel component="legend"><b>3. What are possible reasons you may experience problems with Auto Swing Detection and Voice Guidance?</b></FormLabel>
                              <RadioGroup aria-label="3" name="q3" value={this.state.q3} onChange={this.handleLocalChange('q3')}>
                                <FormControlLabel value="1" control={<Radio />} label="Taking practice swings/waggles in between shots you want recorded" />
                                <hr />
                                <FormControlLabel value="2" control={<Radio />} label="Lighting is too dark and you didn’t change the brightness setting to appropriate level" />
                                <hr />
                                <FormControlLabel value="3" control={<Radio />} label="You didn’t give enough pause/time in between swings (rapid fire one shot after another)" />
                                <hr />
                                <FormControlLabel value="4" control={<Radio />} label="You didn’t wait for the voice guidance to finish saying “All set. 3, 2, 1. GO” before taking the first swing" />
                                <hr />
                                <FormControlLabel value="5" control={<Radio />} label="Went out of camera frame in between swings or took more than 10 seconds between shots (was not prepared with balls you wanted to hit before starting session)" />
                                <hr />
                                <FormControlLabel value="6" control={<Radio />} label="All of the above" />
                                <hr />
                              </RadioGroup>
                            </FormControl>
                          </Grid>
                          <Grid
                            item
                            xs={6}
                          >
                            <FormControl component="fieldset">
                              <FormLabel component="legend"><b>4. Where do you find your Watchlist?</b></FormLabel>
                              <RadioGroup aria-label="4" name="q4" value={this.state.q4} onChange={this.handleLocalChange('q4')}>
                                <FormControlLabel value="1" control={<Radio />} label="2D Screen" />
                                <hr />
                                <FormControlLabel value="2" control={<Radio />} label="3D Screen" />
                                <hr />
                                <FormControlLabel value="3" control={<Radio />} label="All of the above" />
                                <hr />
                              </RadioGroup>
                            </FormControl>
                          </Grid>
                        </Grid>
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={() => { this.setState({ currentStep: 'plansTable' }) }} color="primary">
                          Go Back
                        </Button>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => { this.checkAnswers() }}
                          disabled={(this.state.q1 === '' || this.state.q2 === '' || this.state.q3 === '' || this.state.q4 === '') ? true : false}
                        >
                          Continue
                        </Button>
                      </DialogActions>
                    </Dialog>
                  )*/}
                    <Grid item xs={12} style={{ paddingBottom: 20 }}>
                      <IconButton
                        edge="start"
                        onClick={() => {
                          this.setState({ currentStep: "plansTable" });
                        }}
                      >
                        <ArrowBackIcon />
                      </IconButton>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        //autoComplete="userFirstName"
                        autoFocus
                        fullWidth
                        required
                        id="userFirstName"
                        label="First Name"
                        name="userFirstName"
                        onChange={this.handleLocalChange("userFirstName")}
                        value={this.state.userFirstName}
                        variant="outlined"
                        inputProps={{ maxLength: 254 }}
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <TextField
                        //autoComplete="userLastName"
                        //autoFocus
                        fullWidth
                        required
                        id="userLastName"
                        label="Last Name"
                        name="userLastName"
                        onChange={this.handleLocalChange("userLastName")}
                        value={this.state.userLastName}
                        variant="outlined"
                        inputProps={{ maxLength: 254 }}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        //autoComplete="email"
                        //autoFocus
                        fullWidth
                        required
                        id="email"
                        label="Email Address"
                        name="email"
                        onChange={this.handleLocalChange("email")}
                        value={this.state.email}
                        variant="outlined"
                        inputProps={{ maxLength: 254 }}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        InputLabelProps={{
                          shrink: true,
                        }}
                        required
                        fullWidth
                        variant="outlined"
                        id="date"
                        label="Date of Birth"
                        onChange={this.handleChange("dob")}
                        type="date"
                        value={values.dob}
                      />
                    </Grid>

                    {this.state.selectedPlan === "free" ? (
                      <Grid item xs={6}>
                        <FormControl fullWidth variant="outlined">
                          <InputLabel id="gender-select">Gender</InputLabel>
                          <Select
                            id="gender"
                            label="Gender"
                            labelId="gender-select"
                            onChange={this.handleChange("gender")}
                            value={values.gender}
                          >
                            <MenuItem value="">
                              <em>None</em>
                            </MenuItem>
                            <MenuItem value={"MALE"}>Male</MenuItem>
                            <MenuItem value={"FEMALE"}>Female</MenuItem>
                            <MenuItem value={"PREFER NOT TO ANSWER"}>
                              Prefer not to answer
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                    ) : (
                      <div></div>
                    )}

                    {this.state.selectedPlan === "free" ? (
                      <Grid item xs={6}>
                        <TextField
                          fullWidth
                          id="height"
                          label={"Height (e.g. 5'5\")"}
                          name="height"
                          onChange={this.handleChange("height")}
                          value={values.height}
                          variant="outlined"
                          inputProps={{ maxLength: 5 }}
                        />
                      </Grid>
                    ) : (
                      <div></div>
                    )}

                    {this.state.selectedPlan === "free" ? (
                      <Grid item xs={6}>
                        <FormControl fullWidth variant="outlined">
                          <InputLabel id="hand-select">
                            Dominant Hand
                          </InputLabel>
                          <Select
                            //required
                            id="hand"
                            label="Dominant Hand"
                            labelId="hand-select"
                            onChange={this.handleChange("dominantHand")}
                            value={values.dominantHand}
                          >
                            <MenuItem value="">
                              <em>None</em>
                            </MenuItem>
                            <MenuItem value={"Right"}>Right</MenuItem>
                            <MenuItem value={"Left"}>Left</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                    ) : (
                      <div></div>
                    )}

                    <Grid item xs={12}>
                      <FormControl fullWidth variant="outlined">
                        <InputLabel htmlFor="outlined-adornment-password">
                          Password *
                        </InputLabel>
                        <OutlinedInput
                          label="Password"
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                edge="end"
                                onClick={this.handleClickShowPassword}
                                onMouseDown={this.handleMouseDownPassword}
                              >
                                {this.state.showPassword ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                          required
                          id="outlined-adornment-password"
                          onChange={this.handleLocalChange("password")}
                          type={this.state.showPassword ? "text" : "password"}
                          value={this.state.password}
                        />
                      </FormControl>
                      {this.state.password.length > 0 && (
                        <Typography style={{ color: "black" }}>
                          • At least 8 characters <br />
                          • At least one number <br />
                          • A special character (e.g., !@#$%&*) <br />
                        </Typography>
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl fullWidth variant="outlined">
                        <InputLabel htmlFor="outlined-adornment-password">
                          Confirm Password *
                        </InputLabel>
                        <OutlinedInput
                          required
                          id="checkPassword"
                          label="Confirm Password"
                          onChange={this.handleLocalChange("confirmPassword")}
                          type={this.state.showPassword ? "text" : "password"}
                          value={this.state.confirmPassword}
                        />
                      </FormControl>
                      {this.state.confirmPassword.length > 0 &&
                        this.state.password !== this.state.confirmPassword && (
                          <Typography style={{ color: "red" }}>
                            The passwords do not match
                          </Typography>
                        )}
                    </Grid>
                    <Grid item xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            value={this.state.tosAgree}
                            onClick={this.handleTOSAgree}
                            checked={this.state.tosAgree ? "checked" : null}
                          />
                        }
                        label={
                          <div>
                            <span>I have read and agree to Sportsbox's </span>
                            <Link
                              onClick={async (e) => {
                                e.preventDefault();
                                window.open(
                                  window.location.href.replace(
                                    "sign-up",
                                    "terms-of-service"
                                  )
                                );
                              }}
                            >
                              Terms of Service &
                            </Link>
                            <Link
                              onClick={async (e) => {
                                e.preventDefault();
                                window.open(
                                  window.location.href.replace(
                                    "sign-up",
                                    "privacy"
                                  )
                                );
                              }}
                            >
                              {" "}
                              Privacy Policy.
                            </Link>
                          </div>
                        }
                      />
                    </Grid>
                    {/*<Grid
                    item
                    xs={12}
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          value={this.state.ppAgree}
                          onClick={this.handleLocalChange('ppAgree')}
                          checked={this.state.ppAgree ? 'checked' : null}
                        />
                      }
                      label={
                        <div>
                          <span>I have read and agree to Sportsbox's </span>
                          <Link onClick={async (e) => {
                            e.preventDefault();
                            window.open(window.location.href.replace('sign-up', 'privacy'))
                          }}>Privacy Policy.
                          </Link>
                        </div>
                      }
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          value={this.state.userConsent}
                          onClick={this.handleUserConsent}
                          checked={this.state.userConsent ? 'checked' : null}
                        />
                      }
                      label={
                        <div>
                          <span>Sportsbox.ai uses the videos that users upload to improve its 
                            3D motion capture and analysis, powered by AI, and thereby provide 
                            better coaching. Do you agree to allow Sportsbox.ai to use your 
                            uploaded videos to provide better guidance and instruction?
                          </span>
                        </div>
                      }
                    />
                  </Grid>*/}
                    <Button
                      color="primary"
                      disabled={
                        this.state.tosAgree === false ||
                        this.state.email.length === 0 ||
                        this.state.userFirstName.length === 0 ||
                        this.state.userLastName.length === 0 ||
                        this.state.password.length === 0 ||
                        this.state.confirmPassword.length === 0
                      }
                      fullWidth
                      variant="contained"
                      onClick={() => {
                        this.handleStartStep();
                      }}
                    >
                      Next
                    </Button>
                  </>
                )}

                {this.state.currentStep === "plans" && (
                  <>
                    <Grid container spacing={2}>
                      <Grid item xs={12} style={{ paddingBottom: 20 }}>
                        <IconButton
                          edge="start"
                          onClick={() => {
                            this.setState({ currentStep: "start" });
                          }}
                        >
                          {/*<ArrowBackIcon />*/}
                        </IconButton>
                      </Grid>

                      {this.state.selectedPlan !== "" &&
                        this.state.selectedPlan !== "free" && (
                          <Card style={{ width: "100%" }}>
                            <CardContent>
                              <Grid
                                item
                                xs={12}
                                style={{
                                  paddingBottom: 20,
                                  textAlign: "center",
                                }}
                              >
                                {freeTrialPromoCodes.includes(couponCode) ? (
                                  <h3 style={{ paddingBottom: 10 }}>
                                    Congrats, you've unlocked 1 free month to 3D
                                    Player!
                                  </h3>
                                ) : (
                                  <h3 style={{ paddingBottom: 10 }}>
                                    {this.getPlan(this.state.selectedPlan)}
                                  </h3>
                                )}
                                <ToggleButtonGroup
                                  value={this.state.paymentInterval}
                                  exclusive
                                  onChange={this.handlePaymentIntervalChange}
                                >
                                  <ToggleButton
                                    value="monthly"
                                    aria-label="Monthly"
                                  >
                                    Monthly
                                  </ToggleButton>
                                  <ToggleButton
                                    value="annually"
                                    aria-label="Annually"
                                  >
                                    Annually (Save 17%)
                                  </ToggleButton>
                                </ToggleButtonGroup>
                                {freeTrialPromoCodes.includes(couponCode) ? (
                                  <div>
                                    {this.state.selectedPlan ===
                                      "studentLite" &&
                                      this.state.paymentInterval ===
                                        "monthly" && (
                                        <>
                                          <div style={{ paddingTop: 20 }}>
                                            <span style={{ fontSize: "34px" }}>
                                              $0
                                            </span>{" "}
                                            / MONTH for 1 month
                                          </div>
                                          <div style={{ paddingTop: 20 }}>
                                            <span>$15.99/month after</span>
                                          </div>
                                        </>
                                      )}
                                    {this.state.selectedPlan ===
                                      "studentLite" &&
                                      this.state.paymentInterval ===
                                        "annually" && (
                                        <>
                                          <div style={{ paddingTop: 20 }}>
                                            <span style={{ fontSize: "34px" }}>
                                              $0
                                            </span>{" "}
                                            / MONTH for 1 month
                                          </div>
                                          <div style={{ paddingTop: 20 }}>
                                            <span>Renews at $110/year</span>
                                          </div>
                                        </>
                                      )}
                                  </div>
                                ) : (
                                  <div>
                                    {this.state.selectedPlan ===
                                      "studentLite" &&
                                      this.state.paymentInterval ===
                                        "monthly" && (
                                        <div style={{ paddingTop: 20 }}>
                                          <span style={{ fontSize: "34px" }}>
                                            ${this.state.studentLiteMonthly}
                                          </span>{" "}
                                          / MONTH
                                        </div>
                                      )}
                                    {this.state.selectedPlan ===
                                      "studentLite" &&
                                      this.state.paymentInterval ===
                                        "annually" && (
                                        <>
                                          <div style={{ paddingTop: 20 }}>
                                            <span style={{ fontSize: "34px" }}>
                                              $
                                              {
                                                this.state
                                                  .studentLiteAnnuallyPerMonth
                                              }
                                            </span>{" "}
                                            / MONTH
                                          </div>
                                          <div style={{ paddingTop: 20 }}>
                                            <span style={{ paddingRight: 60 }}>
                                              ${this.state.studentLiteAnnually}{" "}
                                              BILLED ANNUALLY
                                            </span>{" "}
                                            <strong>
                                              Save $
                                              {Math.round(
                                                this.state.studentLiteMonthly *
                                                  12 -
                                                  this.state
                                                    .studentLiteAnnually *
                                                    1
                                              )}
                                            </strong>
                                          </div>
                                        </>
                                      )}
                                  </div>
                                )}
                                {this.state.selectedPlan === "lite" &&
                                  this.state.paymentInterval === "monthly" && (
                                    <div style={{ paddingTop: 20 }}>
                                      <span style={{ fontSize: "34px" }}>
                                        ${this.state.liteMonthly}
                                      </span>{" "}
                                      / MONTH
                                    </div>
                                  )}
                                {this.state.selectedPlan === "lite" &&
                                  this.state.paymentInterval === "annually" && (
                                    <>
                                      <div style={{ paddingTop: 20 }}>
                                        <span style={{ fontSize: "34px" }}>
                                          ${this.state.liteAnnuallyPerMonth}
                                        </span>{" "}
                                        / MONTH
                                      </div>
                                      <div style={{ paddingTop: 20 }}>
                                        <span style={{ paddingRight: 60 }}>
                                          ${this.state.liteAnnually} BILLED
                                          ANNUALLY
                                        </span>{" "}
                                        <strong>
                                          Save $
                                          {Math.round(
                                            this.state.liteMonthly * 12 -
                                              this.state.liteAnnually * 1
                                          )}
                                        </strong>
                                      </div>
                                    </>
                                  )}
                                {this.state.selectedPlan === "premium" &&
                                  this.state.paymentInterval === "monthly" && (
                                    <div style={{ paddingTop: 20 }}>
                                      <span style={{ fontSize: "34px" }}>
                                        ${this.state.premiumMonthly}
                                      </span>{" "}
                                      / MONTH
                                    </div>
                                  )}
                                {this.state.selectedPlan === "premium" &&
                                  this.state.paymentInterval === "annually" && (
                                    <>
                                      <div style={{ paddingTop: 20 }}>
                                        <span style={{ fontSize: "34px" }}>
                                          ${this.state.premiumAnnuallyPerMonth}
                                        </span>{" "}
                                        / MONTH
                                      </div>
                                      <div style={{ paddingTop: 20 }}>
                                        <span style={{ paddingRight: 60 }}>
                                          ${this.state.premiumAnnually} BILLED
                                          ANNUALLY
                                        </span>{" "}
                                        <strong>
                                          Save $
                                          {Math.round(
                                            this.state.premiumMonthly * 12 -
                                              this.state.premiumAnnually * 1
                                          )}
                                        </strong>
                                      </div>
                                    </>
                                  )}
                                {this.state.selectedPlan === "enterprise" &&
                                  this.state.paymentInterval === "monthly" && (
                                    <div style={{ paddingTop: 20 }}>
                                      <span style={{ fontSize: "34px" }}>
                                        Enterprise plan is only available with
                                        annually subscription
                                      </span>
                                      {this.setState({
                                        paymentInterval: "annually",
                                      })}
                                    </div>
                                  )}
                                {this.state.selectedPlan === "enterprise" &&
                                  this.state.paymentInterval === "annually" && (
                                    <>
                                      <div style={{ paddingTop: 20 }}>
                                        <span style={{ fontSize: "34px" }}>
                                          $98
                                        </span>{" "}
                                        / MONTH / COACH
                                      </div>
                                      <div style={{ paddingTop: 20 }}>
                                        <span style={{ paddingRight: 60 }}>
                                          $3500 BILLED ANNUALLY
                                        </span>
                                      </div>
                                    </>
                                  )}
                              </Grid>
                              <Button
                                color="primary"
                                fullWidth
                                variant="contained"
                                disabled={
                                  this.state.paymentInterval === null ||
                                  this.state.paymentInterval === undefined
                                }
                                onClick={() => {
                                  this.handlePlansStep();
                                }}
                              >
                                {freeTrialPromoCodes.includes(couponCode)
                                  ? "Redeem Now"
                                  : "Continue to Payment"}
                              </Button>
                              {freeTrialPromoCodes.includes(couponCode) && (
                                <p style={{ textAlign: "center" }}>
                                  Cancel anytime
                                </p>
                              )}
                            </CardContent>
                          </Card>
                        )}

                      {(this.state.selectedPlan === "" ||
                        this.state.selectedPlan === "free") && (
                        <Button
                          color="primary"
                          fullWidth
                          variant="contained"
                          onClick={() => {
                            this.handlePlansStep();
                          }}
                        >
                          Next
                        </Button>
                      )}
                    </Grid>
                  </>
                )}

                {this.state.currentStep === "details" && (
                  <>
                    <Grid container spacing={2}>
                      <Grid
                        item
                        xs={12}
                        justify="left"
                        style={{ paddingBottom: 20 }}
                      >
                        <IconButton
                          edge="start"
                          onClick={() => {
                            this.setState({ currentStep: "plans" });
                          }}
                        >
                          <ArrowBackIcon />
                        </IconButton>
                      </Grid>

                      {this.state.selectedPlan === "free"
                        ? this.signIn()
                        : this.state.selectedPlan !== "" &&
                          this.state.paymentInterval !== "" && (
                            <>
                              {this.state.selectedPlan !== "free" && (
                                <Grid item xs={12}>
                                  {localStorage.getItem("freeTrial") ===
                                    "true" &&
                                    (this.state.selectedPlan === "lite" ||
                                      this.state.selectedPlan ===
                                        "premium") && (
                                      <b style={{ fontSize: "125%" }}>
                                        You will not be charged until your free
                                        trial ends on{" "}
                                        {`${moment()
                                          .add(2, "weeks")
                                          .format("MM/DD/YYYY")}`}
                                        .
                                      </b>
                                    )}
                                  <StripeSubscription
                                    email={this.state.email}
                                    fullName={this.state.userFirstName + " " + this.state.userLastName}
                                    productId={this.getProductId(
                                      this.state.selectedPlan,
                                      this.state.paymentInterval
                                    )}
                                    handleResult={this.createSubscription}
                                    freeTrial={
                                      window.localStorage.getItem(
                                        "freeTrial"
                                      ) === "true" &&
                                      (this.state.selectedPlan === "lite" ||
                                        this.state.selectedPlan === "premium")
                                        ? 14
                                        : 0
                                    }
                                  />
                                </Grid>
                              )}
                            </>
                          )}
                    </Grid>
                  </>
                )}
              </Grid>
            )}

            <Grid
              container
              style={{ paddingTop: 20, paddingBottom: 10 }}
              justify="center"
            >
              <Grid item>
                <Link component={RouterLink} to="/sign-in" variant="body2">
                  {"Already have an account? Sign in"}
                </Link>
              </Grid>
            </Grid>
          </form>
        </Container>

        {this.state.isLoading && (
          <Container
            maxWidth="xl"
            style={{ textAlign: "center", padding: 200 }}
          >
            <CircularProgress />
          </Container>
        )}
      </div>
    );
  }
}
UserInfo.propTypes = {
  classes: PropTypes.object,
  errors: PropTypes.object,
  nextStep: PropTypes.func,
  runValidation: PropTypes.func,
  setValues: PropTypes.func,
  submitPage: PropTypes.func,
  values: PropTypes.object,
};

export default withRouter(UserInfo);
