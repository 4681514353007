import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  InputLabel,
  Grid,
  TextField,
  InputAdornment,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Swal from "sweetalert2";
import { functionBaseUrl, axiosWithToken } from "../../common/firebase";
import { listOfSubscriptions } from "../../common/envConfig";

const subscriptionTypes = [
  { name: "Bootcamp Only", plan: listOfSubscriptions.PREMIUM_MONTHLY },
  {
    name: "Bootcamp + 3D Pro Annual",
    plan: listOfSubscriptions.PREMIUM_ANNUALLY + "_bootcamp",
  },
  {
    name: "Bootcamp + Enterprise Master",
    plan: listOfSubscriptions.ENTERPRISE_MASTER + "_bootcamp",
  },
  { name: "3D Pro Annual", plan: listOfSubscriptions.PREMIUM_ANNUALLY },
  { name: "Enterprise Master", plan: listOfSubscriptions.ENTERPRISE_MASTER },
];

function AddBootcampAccountComponent({ open, close }) {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [dob, setDOB] = useState("");
  const [coachesNumber, setCoachesNumber] = useState(1);
  const [subscriptionPrice, setSubscriptionPrice] = useState("");
  const [subscriptionType, setSubscriptionType] = useState(
    listOfSubscriptions.PREMIUM_MONTHLY
  );
  const [autoRenewal, setAutorenewal] = useState(false);
  const [phone, setPhone] = useState("");
  const [salesRep, setSalesRep] = useState("");
  const [bootcamp, setBootcamp] = useState(true);

  const handleFirstName = (e) => {
    setFirstName(e.target.value);
  };
  const handleLastName = (e) => {
    setLastName(e.target.value);
  };
  const handleEmail = (e) => {
    setEmail(e.target.value);
  };
  const handlePhone = (e) => {
    setPhone(e.target.value);
  };
  const handleDOB = (e) => {
    setDOB(e.target.value);
  };
  const handleCoachesNumber = (e) => {
    setCoachesNumber(e.target.value);
  };
  const handleSubscriptionPrice = (e) => {
    setSubscriptionPrice(e.target.value);
  };
  const handleSubscriptionType = (e) => {
    setSubscriptionType(e.target.value);
    if (
      e.target.value.includes("enterprise") ||
      e.target.value.includes("monthly")
    ) {
      setAutorenewal(false);
    }
    if (
      e.target.value.includes("bootcamp") ||
      e.target.value.includes("monthly")
    ) {
      setBootcamp(true);
    } else {
      setBootcamp(false);
    }
  };
  const handleAutoRenewal = (e) => {
    setAutorenewal(e.target.checked);
    if (e.target.checked) {
      setCoachesNumber(1);
    }
  };
  const handleSalesRep = (e) => {
    setSalesRep(e.target.value);
  };
  const clearState = () => {
    setFirstName("");
    setLastName("");
    setEmail("");
    setDOB("");
    setCoachesNumber(1);
    setSubscriptionPrice("");
    setAutorenewal(false);
    setSubscriptionType(listOfSubscriptions.PREMIUM_MONTHLY);
    setPhone("");
    setSalesRep("");
  };

  const dobFormat = (dob) => {
    let [year, month, day] = dob.split("-");
    return month + "/" + day + "/" + year;
  };

  const validEmail = (email) => {
    const regex = /\S+@\S+\.\S+/;
    return regex.test(email);
  };

  const handleCreate = async () => {
    if (!firstName || !lastName || !email || !subscriptionPrice || !salesRep) {
      //close();
      Swal.fire(
        '<p style="font-size:70%;">Please fill in all required fields</p>'
      );
      return;
    }
    const options = { year: "numeric", month: "numeric", day: "numeric" };
    const date = new Date(new Date().setFullYear(new Date().getFullYear() - 13))
      .toLocaleDateString("en-ZA", options)
      .replaceAll("/", "-");
    if (Date.parse(dob) > Date.parse(date)) {
      //close();
      Swal.fire({
        title:
          '<p style="font-size:70%;">Sorry, this user is not eligible for a Sportsbox.ai account at this time.</p>',
        confirmButtonText: "Ok",
        icon: "warning",
        allowOutsideClick: false,
        customClass: {
          container: "my-swal",
        },
      }).then(() => {
        window.location.reload();
      });
      return false;
    }
    if (!validEmail(email)) {
      //close();
      Swal.fire('<p style="font-size:70%;">Please enter a valid Email</p>');
      return;
    }
    if (coachesNumber < 1) {
      //close();
      Swal.fire(
        '<p style="font-size:70%;">Coaches Number has to greater than 0</p>'
      );
      return;
    }

    await axiosWithToken(`${functionBaseUrl}/api/usersBootcamp`, {
      method: "post",
      data: {
        firstName: firstName,
        lastName: lastName,
        email: email.toLowerCase().replace(/ /g, ""),
        dob: dob.includes("undefined") ? "" : dobFormat(dob),
        coachesCount: coachesNumber,
        price: subscriptionPrice,
        autoRenewal: autoRenewal,
        subscriptionType: subscriptionType,
        phone: phone,
        salesRep: salesRep,
        bootcamp: bootcamp,
      },
    })
      .then((response) => {
        close();
        if (response.status === 200) {
          clearState();
          Swal.fire({
            title:
              '<p style="font-size:70%;">Account successfully created for ' +
              email +
              " and the password is " +
              response.data.password +
              "</p>",
            icon: "success",
            confirmButtonText: "Ok",
            allowOutsideClick: false,
            customClass: {
              container: "my-swal",
            },
          });
        } else {
          if (response.data.message.includes("upgraded")) {
            clearState();
          }
          Swal.fire({
            title:
              '<p style="font-size:70%;">' + response.data.message + "</p>",
            icon: "warning",
            confirmButtonText: "Ok",
            allowOutsideClick: true,
            customClass: {
              container: "my-swal",
            },
          });
        }
        return false;
      })
      .catch((error) => {
        console.log(error);
        Swal.fire({
          title:
            '<p style="font-size:70%;">Error creating Bootcamp account</p>',
          icon: "error",
          confirmButtonText: "Ok",
          allowOutsideClick: true,
          customClass: {
            container: "my-swal",
          },
        });
      });
  };

  return (
    <>
      <Dialog
        fullWidth
        open={open}
        onClose={close}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id={"form-dialog-title"}>
          <div>
            <b>New Bootcamp Account</b>
            <CloseIcon
              style={{
                float: "right",
                marginTop: "-10px",
                marginRight: "-15px",
              }}
              onClick={close}
            />
          </div>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item sm={6} xs={12}>
              <TextField
                autoComplete="firstname"
                autoFocus
                fullWidth
                required
                label="First Name"
                onChange={handleFirstName}
                value={firstName}
                variant="outlined"
                inputProps={{ maxLength: 254 }}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <TextField
                autoComplete="lastName"
                fullWidth
                required
                label="Last Name"
                onChange={handleLastName}
                value={lastName}
                variant="outlined"
                inputProps={{ maxLength: 254 }}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <TextField
                autoComplete="email"
                fullWidth
                required
                label="Email Address"
                onChange={handleEmail}
                value={email}
                variant="outlined"
                inputProps={{ maxLength: 254 }}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <TextField
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
                variant="outlined"
                id="date"
                label="Date of Birth"
                onChange={handleDOB}
                type="date"
                value={dob}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <TextField
                autoComplete="salesRep"
                fullWidth
                required
                label="Sales Representative"
                onChange={handleSalesRep}
                value={salesRep}
                variant="outlined"
                inputProps={{ maxLength: 254 }}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={autoRenewal}
                    onChange={handleAutoRenewal}
                    disabled={
                      subscriptionType.includes("enterprise_master") ||
                      subscriptionType.includes("monthly")
                        ? true
                        : false
                    }
                  />
                }
                label="Auto Renewal"
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl variant="outlined" margin="normal" fullWidth>
                <InputLabel id="subscription-type-label">
                  Subscription Type*
                </InputLabel>
                <Select
                  fullWidth
                  required
                  label="Subscription Type"
                  labelId="subscription-type-label"
                  onChange={handleSubscriptionType}
                  value={subscriptionType}
                >
                  {subscriptionTypes.map((s) => (
                    <MenuItem value={s.plan}>{s.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            {subscriptionType.includes("enterprise_master") && (
              <Grid item sm={6} xs={12}>
                <TextField
                  fullWidth
                  required
                  label="Number of Coaches"
                  onChange={handleCoachesNumber}
                  value={coachesNumber}
                  variant="outlined"
                  type="Number"
                  InputProps={{
                    inputProps: { min: 0 },
                  }}
                />
              </Grid>
            )}
            <Grid item sm={6} xs={12}>
              <TextField
                fullWidth
                required
                label="Price"
                onChange={handleSubscriptionPrice}
                value={subscriptionPrice}
                type="Number"
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                  inputProps: { min: 0 },
                }}
              />
              {/*bootcamp && (
              <TextField
                style={{ marginTop: '20px' }}
                fullWidth
                required
                label="Bootcamp Price"
                onChange={handleBootcampPrice}
                value={bootcampPrice}
                type="Number"
                variant="outlined"
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  inputProps: { min: 0 }
                }}
              />
            )*/}
            </Grid>
            <Grid item sm={6} xs={12}>
              <TextField
                autoComplete="phone"
                fullWidth
                label="Phone Number"
                onChange={handlePhone}
                value={phone}
                variant="outlined"
                inputProps={{ maxLength: 254 }}
              />
            </Grid>
            {/*<Grid
            item
            sm={6}
            xs={12}
          >
            <TextField
              autoComplete="facilityName"
              fullWidth
              required
              label="Facility Name"
              onChange={handleFacilityName}
              value={facilityName}
              variant="outlined"
              inputProps={{ maxLength: 254 }}
            />
          </Grid>
          <Grid
            item
            xs={12}
          >
            <TextField
              autoComplete="facilityAddress"
              fullWidth
              required
              label="Facility Address"
              onChange={handleFacilityAddress}
              value={facilityAddress}
              variant="outlined"
              inputProps={{ maxLength: 254 }}
            />
          </Grid>*/}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={close} color="primary">
            Cancel
          </Button>
          <Button variant="contained" color="primary" onClick={handleCreate}>
            CREATE
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export const AddBootcampAccount = AddBootcampAccountComponent;
