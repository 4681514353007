import React from 'react';
import './apps.css';
import { Grid } from '@material-ui/core';

function AppsComponent({}) {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  if (/android/i.test(userAgent)) {
    // The user is using an Android device
    window.location.href = "https://play.google.com/store/apps/details?id=com.sportsbox.golfai";
  } else if (/iPad|iPhone|iPod/i.test(userAgent) && !window.MSStream) {
    // The user is using an iOS device (iPhone, iPad, or iPod Touch)
    window.location.href = "https://apps.apple.com/us/app/sportsbox-3dgolf/id1578921026";
  }
  
  return (<>
      <Grid container spacing={2} style={{ marginTop: "10%" }}>
      <div className="cta-welcome">
        <img src="/images/logos/sportsbox_icon.png" alt="logo" width={150} style={{ marginBottom: "5%" }} />
        <div className="cta-welcome-caption"><span>Welcome to Sportsbox 3DGolf</span></div>
        <div className="cta-welcome-apps">
          <a href="https://apps.apple.com/us/app/sportsbox-3dgolf/id1578921026">
            <img
              alt="Apple"
              src="/images/offer/apple.svg"
              width={170}
            />
          </a>
          <a href="https://play.google.com/store/apps/details?id=com.sportsbox.golfai">
            <img
              alt="Google"
              src="/images/offer/google.svg"
              width={170}
            />
          </a>
        </div>
      </div>
    </Grid>
  </>);
}

export const Apps = AppsComponent;