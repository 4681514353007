import React from 'react';
import { 
  Typography,
  Box,
  Paper,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Button,
  Snackbar,
} from '@material-ui/core';
import {
  Timeline,
  TimelineDot,
  TimelineConnector,
  TimelineSeparator,
  TimelineContent,
  TimelineItem
} from '@material-ui/lab';
import MuiAlert from '@material-ui/lab/Alert';
import CloseIcon from "@material-ui/icons/Close";
import { withStyles } from '@material-ui/styles';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import GolfCourseIcon from '@material-ui/icons/GolfCourse';
import DescriptionIcon from '@material-ui/icons/Description';
//import { Link } from 'react-router-dom';
import firebase, { axiosWithToken, functionBaseUrl } from '../../common/firebase';
import './Dashboard.css';
import NewAccountCheck from './NewAccountCheck';
import NoSubscriptionDialog from '../../components/NoSubscriptionDialog';
import moment from 'moment';
import BootcampCheck from './BootcampCheck';
import Integration from './Integration';
import Swal from 'sweetalert2';

const useStyles = () => ({
  paper: {
    marginTop: 800,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  page: {    
    flexDirection: 'column',
    alignItems: 'center',
  },
});

class Dashboard extends React.Component {
  constructor(props) {
    const firebaseUser = firebase.auth().currentUser;
    super(props)
    this.state = {
      uid: firebaseUser.uid,
      email: "",
      firstName: "",
      lastName: "",
      role: "",
      subStatus: "",
      subscriptionType: "",
      userData: {},
      openPlanUpgrade: false,
      openSubscriptionEnds: false,
      openSubscriptionEnded: false,
      openInvoiceDue: false,
      open: false,
      popUpOpen: false,
      invitesReceived: [],
      activeSub: true,
      isStaffEnterprise: false,
      isCertified: true,
      onboardingCompleted: true,
      loading: true,
      bootcampUser: false,
      bootcampPopUpOpen: false,
      integrationPopUpOpen: false,
    }
  }

  async componentDidMount() {
    if (!this.state.uid) {   
      this.setState({ error: "Please log in" })
    }
    else {
      if (localStorage.getItem('showPopUp') === 'true') {
        this.setState({ open: true })
      }
      const user = await axiosWithToken(functionBaseUrl + '/api/users/' + this.state.uid, {
        method: 'GET'
      })
        .catch(err => {
          console.log(err)
        });
      if (!user && this.state.uid !== "") {
        console.log('No matching documents for UID: ' + this.state.uid);
        this.setState({ error : "The signed in user does not exist" });
      }
      const data = user?.data;
      this.setState({ userData: data, role: data.role, firstName: data.firstName, lastName: data.lastName, dob: data.dob, subscriptionType: data.subscriptionType, email: data.email })
      
      if (window.location.href.toLowerCase().includes('partner=golfgenius')) {
        this.openIntegrationPopUp();
      }

      if (data.subscriptionType.includes('student')) {
        const invites = await axiosWithToken(functionBaseUrl + '/api/allInvitesToInvitee/' + data.email, {
          method: 'GET'
        })
          .catch(err => {
            console.log(err)
          });
        this.setState({ invitesReceived: invites?.data });
      }

      const buffer = await axiosWithToken(functionBaseUrl + '/api/incompleteUserBuffer/' + this.state.uid, {
        method: 'GET'
      })
        .catch(err => {
          console.log(err)
        });
      
      if (buffer?.data) {
        if (buffer?.data?.data?.bootcampUser) {
          this.setState({ bootcampUser: true })
          this.openBootcampPopUp()
        } else {
          this.openPopUp()
        }
      }

      if (!data.subscriptionType.includes('free')) {
        await axiosWithToken(functionBaseUrl + '/api/verifySub/' + this.state.uid, {
          method: 'post'
        })
          .then(async response => {
            this.setState({ subStatus: response.data.success, subData: response.data.data })
            if ('success' in response.data) {
              if (this.state.role !== 'admin') {
                this.setState({ activeSub: response.data.success });
              }
            }
            if (!response.data.success && response.data.data?.plan) {
              this.setState({ openSubscriptionEnded: true });
            }
            /*if (response.data.success && response.data.data?.plan && moment(response.data.data.endDate).diff(moment(), 'days') < 7) {
              this.setState({ openSubscriptionEnds: true });
            }*/
            if (response.data.success && (response.data.data?.plan?.includes('lite') || response.data.data?.plan?.includes('free'))) {
              this.setState({ openPlanUpgrade: true });
            }
          })
          .catch(err => {
            console.log(err)
          });
      }

      if (data.subscriptionType.includes('enterprise_master') && data.enterpriseAccountId) {
        await axiosWithToken(functionBaseUrl + '/api/verifyStaffEnt/' + this.state.uid, {
          method: 'POST',
        })
          .then(async (res) => {
            this.setState({ isStaffEnterprise: res.data.isStaffEnterprise, isCertified: res.data.isCertified, onboardingCompleted: res.data.onboardingCompleted, loading: false })
            if (res.data.isStaffEnterprise) {
              await axiosWithToken(`${functionBaseUrl}/api/invoiceLast`, {
                method: 'post',
                data: { userId: this.state.uid },
              }).then(response => {
                if (response.data.status === 'Unpaid') {
                  this.setState({ openInvoiceDue: true });
                }
              });
            }
          })
          .catch(err => {
            console.log(err)
          });
      }
    }
  }

  dobFormat = (dob) => {
    let [year, month, day] = dob.split('-');
    return (month + "/" + day + "/" + year)
  }

  openPopUp = () => {
    this.setState({ popUpOpen: true });
  }

  closePopUp = () => {
    this.setState({ popUpOpen: false });
  }

  openBootcampPopUp = () => {
    this.setState({ bootcampPopUpOpen: true });
  }

  closeBootcampPopUp = () => {
    this.setState({ bootcampPopUpOpen: false });
    window.location.reload();
  }

  openIntegrationPopUp = () => {
    this.setState({ integrationPopUpOpen: true });
  }

  closeIntegrationPopUp = () => {
    this.setState({ integrationPopUpOpen: false });
    //window.location.reload();
  }

  render() {
    const { classes } = this.props
    return (
      <div className={classes.page}>
        <div className={classes.paper} style={{ marginTop: 80, marginBottom: 20 }}>
          <img src="/images/logos/SportsboxLogo.png" alt="logo" width={"25%"} />
        </div>
        <div className={classes.paper} style={{ marginTop: 20, marginBottom: 30 }}>
          <Typography component="h1" variant="h1" >
            <Box fontFamily="Anton" className="dashboard-title">
              {`Welcome to the SPORTSBOX 3D Golf App!`}
            </Box>
          </Typography>
        </div>
        <div style={{ minWidth: "50%", paddingBottom: "25%" }}>
          <Timeline align="alternate">
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot color="primary">
                  <GolfCourseIcon />
                </TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <Paper elevation={3} className="dashboard-timeline-item">
                  <Typography variant="h5" component="h1" fontFamily="League Spartan">
                    Record
                  </Typography>
                  <Typography fontFamily="Lato">golf session swings</Typography>
                </Paper>
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot color="inherit">
                  <DescriptionIcon color="secondary" />
                </TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <Paper elevation={3} className="dashboard-timeline-item" style={{ marginLeft: "auto" }}>
                  <Typography variant="h5" component="h1" fontFamily="League Spartan">
                    Create
                  </Typography>
                  <Typography fontFamily="Lato">your online session</Typography>
                </Paper>
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot color="inherit">
                  <CloudUploadIcon color="primary" />
                </TimelineDot>
              </TimelineSeparator>
              <TimelineContent>
                <Paper elevation={3} className="dashboard-timeline-item">
                  <Typography variant="h5" component="h1" fontFamily="League Spartan">
                    Upload
                  </Typography>
                  <Typography fontFamily="Lato">your video for use</Typography>
                </Paper>
              </TimelineContent>
            </TimelineItem>
          </Timeline>
        </div>
        {'userConsent' in this.state.userData && (
          <NewAccountCheck
            open={this.state.popUpOpen}
            onClose={this.closePopUp}
            invitesReceived={this.state.invitesReceived}
            subscriptionType={this.state.subscriptionType}
            name={this.state.userName}
            firstName={this.state.firstName}
            lastName={this.state.lastName}
            email={this.state.email}
            uid={this.state.uid}
            subData={this.state.subData}
            userData={this.state.userData}
            fullName={`${this.state.firstName} ${this.state.lastName}`}
            history={this.props.history}
          />
        )}
        {this.state.bootcampUser && this.state.subData && <BootcampCheck
          open={this.state.bootcampPopUpOpen}
          onClose={this.closeBootcampPopUp}
          subscriptionType={this.state.subscriptionType}
          name={this.state.userName}
          firstName={this.state.firstName}
          lastName={this.state.lastName}
          email={this.state.email}
          uid={this.state.uid}
          subData={this.state.subData}
          userData={this.state.userData}
          history={this.props.history}
        />}
        {this.state.integrationPopUpOpen && <Integration 
          open={this.state.integrationPopUpOpen}
          onClose={this.closeIntegrationPopUp}
          subscriptionType={this.state.subscriptionType}
          uid={this.state.uid}
        />}
        {!this.state.popUpOpen && !this.state.bootcampPopUpOpen && !this.state.open && <NoSubscriptionDialog
          activeSub={this.state.activeSub}
          isStaffEnterprise={this.state.isStaffEnterprise}
          isCertified={this.state.isCertified}
          onboardingCompleted={this.state.onboardingCompleted}
          role={this.state.role}
          loading={this.state.loading}
          subscriptionType={this.state.subscriptionType}
        />}
        <Dialog
          fullWidth
          open={this.state.open}
          aria-labelledby='sub-popup'>
          <DialogTitle id={'sub-popup'}>
            <div>
              <h3>Thank you for subscribing!</h3>
              <CloseIcon style={{ float: 'right', marginTop: '-10px', marginRight: '-15px' }} onClick={() => {this.setState({ open: false }); localStorage.setItem('showPopUp', false)}} />
            </div>
          </DialogTitle>
          <DialogContent>
            <Grid
              container
              spacing={2}
            >
              <Grid
                item
                xs={12}
              >
                <Typography>
                  <p style={{ fontSize: "110%" }}>Please check your email for more details and click on these links to download the app:</p>
                  <a href='https://apps.apple.com/us/app/sportsbox-3dgolf/id1578921026'><img width='40%' alt='Download on the App Store' src='https://miro.medium.com/max/600/1*xqT83bMEz92IBYxS9UQNow.png' /></a>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <a href='https://play.google.com/store/apps/details?id=com.sportsbox.golfai'><img width='40%' alt='Get it on Google Play' src='https://miro.medium.com/max/600/1*nZu0dsnlCQltPT1QMCHFAA.png' /></a>
                </Typography>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
        {/*<Snackbar open={this.state.openPlanUpgrade} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} style={{ top: "10%" }}>
          <MuiAlert severity="info" elevation={6} variant="filled">
            Current plan: {getPlan(this.state.subscriptionType)}&nbsp;&nbsp;&nbsp;
            <Button variant="contained" color="secondary" size="small" onClick={() => this.props.history.push('/plans')}>
              UPGRADE
            </Button>
          </MuiAlert>
        </Snackbar>*/}
        {this.state.isStaffEnterprise && this.state.isCertified && this.state.onboardingCompleted && (<Snackbar open={this.state.openInvoiceDue} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} style={{ top: "10%" }}>
          <MuiAlert severity="warning" elevation={6} variant="filled">
            Invoice Due&nbsp;&nbsp;&nbsp;
            <Button variant="contained" color="secondary" size="small" onClick={() => this.props.history.push('/enterprise/pay/students')}>
              PAY INVOICE
            </Button>
          </MuiAlert>
        </Snackbar>)}
        {this.state.subData?.endDate && ( 
          <div>
            <Snackbar open={this.state.openSubscriptionEnds} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} style={{ top: "10%" }}>
              <MuiAlert severity="warning" elevation={6} variant="filled">
                Subscription Ends: {moment(String(this.state.subData?.endDate), "x").format('MM/DD/YYYY')}&nbsp;&nbsp;&nbsp;
                <Button variant="contained" color="secondary" size="small" onClick={() => this.props.history.push('/plans')}>
                  RENEW
                </Button>
              </MuiAlert>
            </Snackbar>
            <Snackbar open={this.state.openSubscriptionEnded} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} style={{ top: "10%" }}>
              <MuiAlert severity="error" elevation={6} variant="filled">
                Subscription Ended: {moment(String(this.state.subData?.endDate), "x").format('MM/DD/YYYY')}&nbsp;&nbsp;&nbsp;
                <Button variant="contained" color="secondary" size="small" onClick={() => this.props.history.push('/plans')}>
                  RENEW
                </Button>
              </MuiAlert>
            </Snackbar>
          </div>
        )}
      </div>
    );
  }
  
}

export default withStyles(useStyles)(Dashboard);