import React, { useState } from "react";

import { Grid, TextField } from "@material-ui/core";
import Swal from "sweetalert2";
import axios from "axios";
import GooglePlacesAutocomplete, {
  geocodeByPlaceId,
} from "react-google-places-autocomplete";

import "./speedAssessment.css";
import firebase, { functionBaseUrl, firebaseConfig } from "../../common/firebase";
import { StripePayment } from "../../components/stripePayment";

const variantId = "43105512194233";
const sbCoach = "coach.human@sportsbox.ai";
const aiCoach = "coach.ai@sportsbox.ai";

function PaymentComponent({ uid, email, userName, finishPayment }) {
  const [name, setName] = useState(userName || "");
  const [address, setAddress] = useState("");
  const [apartment, setApartment] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zip, setZip] = useState("");
  const [googleAddress, setGoogleAddress] = useState(null);

  const handleGoogleAddress = async (data) => {
    setGoogleAddress(data);

    const geocode = await geocodeByPlaceId(data.value.place_id);
    setAddress(
      `${
        geocode[0].address_components.find((d) =>
          d.types.includes("street_number")
        )?.long_name || ""
      } ${
        geocode[0].address_components.find((d) => d.types.includes("route"))
          ?.long_name || ""
      }`
    );
    setCity(
      geocode[0].address_components.find((d) => d.types.includes("sublocality"))
        ?.long_name || ""
    );
    setCity(
      geocode[0].address_components.find((d) => d.types.includes("locality"))
        ?.long_name || ""
    );
    setState(
      geocode[0].address_components.find((d) =>
        d.types.includes("administrative_area_level_1")
      )?.long_name || ""
    );
    setZip(
      geocode[0].address_components.find((d) => d.types.includes("postal_code"))
        ?.long_name || ""
    );
  };

  const createSubscription = async (data) => {
    try {
      await axios(functionBaseUrl + "/api/userCoachHelper", {
        method: "POST",
        data: {
          userEmail: email,
          coachEmail: window.location.href.includes("speed-assessment-human") ? sbCoach : aiCoach,
        },
      }).catch((err) => {
        console.log(err);
      });

      await axios(functionBaseUrl + "/api/order", {
        method: "POST",
        data: {
          variantId: variantId,
          quantity: 1,
          firstName: name.substr(0, name.indexOf(" ")),
          lastName: name.substr(name.indexOf(" ") + 1),
          email: email,
          address1: address,
          address2: apartment,
          city: city,
          zip: zip,
        },
      }).catch((err) => {
        console.log(err);
      });

      await axios(functionBaseUrl + (window.location.href.includes("speed-assessment-human") ? "/api/HSAemail" : "/api/AISAemail"), {
        method: "POST",
        data: {
          email: email,
        },
      }).catch((err) => {
        console.log(err);
      });

      await axios(functionBaseUrl + "/api/updateUserNY/" + uid, {
        method: "POST",
        data: {
          userInfo: window.location.href.includes("/speed-assessment-human") ? 
            {
              speedAssessmentHuman: String(Date.now())
            } 
            :
            {
              speedAssessmentAI: String(Date.now())
            }
        }
      }).catch((err) => {
        console.log(err);
      });

      if (window.location.href.includes("/speed-assessment-human")) {
        const analytics = firebase?.analytics;
        if (typeof analytics === "function") {
          analytics().logEvent("speed_assessment_human", { "step": "complete" });
        }
      }

      if (window.location.href.includes("/speed-assessment-ai")) {
        const analytics = firebase?.analytics;
        if (typeof analytics === "function") {
          analytics().logEvent("speed_assessment_ai", { "step": "complete" });
        }
      }

      finishPayment();
    } catch (e) {
      Swal.fire({
        title:
          '<p style="font-size:70%;">There was an error processing your payment, please try again.</p>',
        icon: "error",
      });
    }
  };

  return (
    <>
      <Grid container spacing={2} className="offer-signup">
        <Grid item xs={12}>
          <div className="cta-subcaption">
            <strong>Shipping Address (Continental U.S. Only)</strong>
          </div>
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            required
            className="offer-input"
            label="Name"
            onChange={(event) => setName(event.target.value)}
            value={name}
            variant="outlined"
            inputProps={{ maxLength: 254 }}
          />
        </Grid>
        <Grid item xs={12} className="address-autocomplete">
          <GooglePlacesAutocomplete
            selectProps={{
              value: googleAddress,
              onChange: handleGoogleAddress,
              placeholder: "Select address...",
            }}
            apiKey={firebaseConfig.apiKey}
            apiOptions={{ language: "en", region: "US" }}
            autocompletionRequest={{
              componentRestrictions: {
                country: ["us"],
              },
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            required
            className="offer-input"
            label="Address"
            onChange={(event) => setAddress(event.target.value)}
            value={address}
            variant="outlined"
            inputProps={{ maxLength: 254 }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            className="offer-input"
            label="Apartment, suite, etc. (optional)"
            onChange={(event) => setApartment(event.target.value)}
            value={apartment}
            variant="outlined"
            inputProps={{ maxLength: 254 }}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            fullWidth
            required
            className="offer-input"
            label="City"
            onChange={(event) => setCity(event.target.value)}
            value={city}
            variant="outlined"
            inputProps={{ maxLength: 254 }}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            fullWidth
            required
            className="offer-input"
            label="State"
            onChange={(event) => setState(event.target.value)}
            value={state}
            variant="outlined"
            inputProps={{ maxLength: 254 }}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            fullWidth
            required
            className="offer-input"
            label="Zip code"
            onChange={(event) => setZip(event.target.value)}
            value={zip}
            variant="outlined"
            inputProps={{ maxLength: 254 }}
          />
        </Grid>
      </Grid>
      <div className="offer-payment" style={{ marginBottom: "2%" }}>
        <div className="cta-subcaption">
          <strong>Payment Information</strong>
        </div>
        <StripePayment
          amount={150}
          email={email}
          handleResult={createSubscription}
          buttonText="Get started"
          //showSavedCard={false}
          name={name}
        />
      </div>
    </>
  );
}

export const Payment = PaymentComponent;
