import React from "react";

export const ReportPageFooter = ({
  userName,
  date,
  club,
  page,
  totalPages,
}) => {
  return (
    <div style={{ marginTop: "auto" }}>
      <div
        style={{
          width: "100%",
          borderBottom: "#E5E7EB 1px solid",
          marginBottom: "20px",
        }}
      ></div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ display: "flex", gap: "20px" }}>
          <div>{userName}</div>
          <div>Date: {date}</div>
          <div>Club: {club}</div>
        </div>
        <div>
          Page {page} of {totalPages}
        </div>
      </div>
    </div>
  );
};
