import React, { useState } from 'react';
import { List, ListItem, ListItemText, Typography, Paper, TextField, Box, Container } from '@material-ui/core';
// import { VirtualizedList } from 'react-virtualized';
const KeyValueList = ({ data }) => {
    const [searchTerm, setSearchTerm] = useState('');
  
    const filteredData = Object.entries(data).filter(([key, value]) =>
      key.toLowerCase().includes(searchTerm.toLowerCase()) ||
      String(value).toLowerCase().includes(searchTerm.toLowerCase())
    );
  
    return (
      <Container maxWidth="sm">
        <Paper elevation={3} width>
          <br/>
          <Typography variant='h2'>Indicators</Typography>
          <Box p={2}>
            <TextField
              fullWidth
              variant="outlined"
              label="Search"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              margin="normal"
            />
          </Box>
          <List sx={{ maxHeight: 400, overflow: 'auto' }}>
            {filteredData.map(([key, value]) => (
              <ListItem key={key} divider>
                <ListItemText
                  primary={<Typography variant="subtitle1" fontWeight="bold">{key}</Typography>}
                  secondary={<Typography variant="body2">{String(value)}</Typography>}
                />
              </ListItem>
            ))}
          </List>
        </Paper>
      </Container>
    );
  };
  
  export default KeyValueList;