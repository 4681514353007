import React, {Component} from 'react';
import {Link as RouterLink} from 'react-router-dom';
import PropTypes from 'prop-types';
import {functionBaseUrl, axiosWithToken} from '../../common/firebase'
import {OPEN_WEATHER_MAP_API_KEY} from '../../common/weather'
import {GOOGLE_REVERSE_GEOCODING_URL, GOOGLE_PARAMETER} from '../../common/geocoding'
import firebase from '../../common/firebase'
import {withStyles} from '@material-ui/styles';
import {Grid, Button, Container, Avatar, TextField, Link,
  Typography, CssBaseline, MenuItem, CircularProgress, List, ListItem, ListItemSecondaryAction, IconButton} from '@material-ui/core';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import {AccountNotFound} from '../account/components';
import axios from 'axios';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import {deviceDetect} from 'react-device-detect';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';

const useStyles = theme => ({
  paper: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  root: {
    marginTop: theme.spacing(4),
  },
  buttonLink: {  // This is a link component surrounding a button component
    width: '100%'
  },
  linkedButton: {  // This is a button component surrounded by a link
    margin: theme.spacing(1, 0),
  }
});

class CreateSession extends Component {
  constructor(props) {
    super(props)
    const firebaseUser = firebase.auth().currentUser
    this.state = {
      uid : firebaseUser.uid,
      error : "",
      loading : true,
      success : false,
      sessionName : "",
      sessionPurpose : "",
      sessionType : "",
      sessionTypes : [],
      sessionEnv : "",
      sessionEnvs : [],
      sessionDate : "",
      userActivities : [],
      activityId : "",
      selectedCameraId: '',
      newCameraSetup: {
        combinations: [{brand: '', model: ''}],
        setupCombination: 1,
        name: ''
      },
      cameraSetups : {},
      addressToString : "",
      weatherDesc : "",
      temperature : "",
      windSpeed : "",
      humidity: "",
      students : []
    }
  }

  componentDidMount() {
    if (!this.state.uid) {   
      this.setState({error : "Please log in to submit a session", loading : false})  
    }
    else {
      axiosWithToken(functionBaseUrl+'/api/users/' + this.state.uid, { //+ '/sessions,sessionCameraSetups', {
        method: 'get',
      }).then(response => {
        const out = response.data;
        //const cameraSetups = response.data.cameraSetups ? response.data.cameraSetups.reduce((result, item) => {return {...result, [item.id]: item}}, {}) : {}
        // update the wind speed and temperature based on current addressToString
        this.updateCurrentWeather()
        this.updateCurrentAddress()
        //out.cameraSetups = out.cameraSetups.reduce((result, item) => { return {...result, [item.id]: item}}, {})
        // for mobile devices only, add the device name as a camera setup
        if (deviceDetect().isMobile) {
          // const model = deviceDetect().mobileModel
          // const vendor = deviceDetect().mobileVendor
          this.setState({
            ...out,
            cameraSetups : this.state.cameraSetups,
            loading : false
          })
        }
        else {
          // set loading state to false so screens are displayed
          this.setState({
            ...out,
            loading : false
          })      
        }
      })
      .catch(err => {
        console.log(err)
        this.setState({loading: false, error : "There was an error retrieving your info"});
      });
    }    
  }

  updateCurrentWeather = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        // units=imperial returns the wind speed in mph and temperature in fahrenheit
        axios("//api.openweathermap.org/data/2.5/weather?lat=" + position.coords.latitude + "&lon=" + position.coords.longitude + "&appid=" + OPEN_WEATHER_MAP_API_KEY + "&units=imperial", {
          method: 'GET'
        })
        .then((res) => {
          this.setState({
            windSpeed: String(res.data.wind.speed),
            temperature: String(res.data.main.temp),
            weatherDesc: res.data.weather[0].description,
            humidity: String(res.data.main.humidity),
          })
        })
      });
    } else {
      console.log("Permission to share current location was not accepted");
    }
  }

  updateCurrentAddress = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        axios(GOOGLE_REVERSE_GEOCODING_URL + position.coords.latitude + "," + position.coords.longitude + GOOGLE_PARAMETER, {
          method: 'GET'
        })
        .then((res) => {
          if(res.data.results[0]) {
            this.setState({
              addressToString: res.data.results[0].name + ": " + res.data.results[0].vicinity
            })
          }
        })
      });
    } else {
      console.log("Permission to share current location was not accepted");
    }
  }

  handleChange = (prop) => (event) => {
    this.setState({[prop]: event.target.value});
  }
  handleCameraChange = (prop) => (event) => {
    this.setState({newCameraSetup: {...this.state.newCameraSetup, [prop]: event.target.value}})
  }
  handleCombinationChange = (index, prop) => (event) => {
    let combinations = this.state.newCameraSetup.combinations
    if (index < combinations.length) {
      if (prop === 'delete') {
        combinations.splice(index, 1)
      } else {
        combinations[index][prop] = event.target.value
      }
    }
    else {
      combinations.push({brand:'', model: ''})
    }
    this.setState({newCameraSetup: {...this.state.newCameraSetup, combinations: combinations}})
  }
  handleCameraSelect = (event) => {
    this.setState({selectedCameraId: event.target.value, cameraInfo: {...this.state.cameraInfo, ...this.state.cameraSetups[event.target.value]}})
  }
  handleSubmit = (event) => {
    event.preventDefault()
    this.setState({loading: true});
    //Create sessions firestore entry
    let output = {
      sessionType : this.state.sessionType,
      sessionEnv : this.state.sessionEnv,
      sessionPurpose : this.state.sessionPurpose,
      sessionName : this.state.sessionName,
      sessionDate : String(new Date(this.state.sessionDate).getTime()),
      parentUserId : this.state.uid,
      sessionUserId: this.state.uid,
      cameraSetupId : this.state.selectedCameraId,
      videosOrig : "", // not sure what to put here
      videoIds : [],
      location: this.state.addressToString,
      activityId : this.state.activityId,
      temperature : String(this.state.temperature),
      weatherDesc : this.state.weatherDesc,
      windSpeed : String(this.state.windSpeed),
      humidity : String(this.state.humidity),
      tags : []
    }
    if(output.cameraSetupId === 'custom') {
      output.newCameraSetup = this.state.newCameraSetup
    }
    axiosWithToken(functionBaseUrl +'/api/sessions', {
      method: 'POST',
      data: output
    })
      .then(res => {
        console.log("Successfully created a new session!")
        this.setState({
          success: true,
          loading: false
        })
      })
      .catch(err => {
        this.setState({loading: false});
        console.log("An error occured: " + err);
      });
  }

  render() {
    const classes = this.props.classes;
    return (
      <Container
        component="main"
        style={{width:'80%', maxWidth: '500px'}}
        className={classes.paper}
      >
        <CssBaseline />
        <Grid
          style = {{display: (this.state.error) ? "none" : (this.state.loading) ? "none" : (this.state.success) ? "none" : "flex"}}
        >
          <div className={this.props.classes.paper}>
            <Avatar className={this.props.classes.avatar}>
              <AssignmentIndIcon />
            </Avatar>
            <Typography
              component="h1"
              variant="h4"
            >
              CREATE A NEW SESSION
            </Typography>
            <form
              className={this.props.classes.form}
              onSubmit={this.handleSubmit}
            >
              <div className={this.props.classes.root}>
                <Typography
                  variant="h6"
                  align="left"
                >
                  SESSION INFORMATION
                </Typography>
                {/*<TextField
                  fullWidth
                  select
                  label="Student"
                  margin="normal"
                  name="student"
                  onChange={this.handleChange("student")}
                  required
                  value={this.state.student}
                  variant="outlined"
                >
                  {this.state.students.map((option) => (
                    <MenuItem key={option.id} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                  </TextField>*/}
                <TextField
                  autoFocus
                  fullWidth
                  label="Session Name"
                  margin="normal"
                  name="sessionName"
                  onChange={this.handleChange("sessionName")}
                  required
                  value={this.state.sessionName}
                  variant="outlined"
                />
                <TextField
                  fullWidth
                  label="Session Purpose"
                  margin="normal"
                  name="sessionPurpose"
                  onChange={this.handleChange("sessionPurpose")}
                  required
                  value={this.state.sessionPurpose}
                  variant="outlined"
                />
                <TextField
                  fullWidth
                  select
                  label="Session Type"
                  margin="normal"
                  name="sessionType"
                  onChange={this.handleChange("sessionType")}
                  required
                  value={this.state.sessionType}
                  variant="outlined"
                >
                  <MenuItem value={'upload'}>Video Upload</MenuItem>
                  <MenuItem value={'live'}>Live Session</MenuItem>
                  {/*{this.state.sessionTypes.map((option) => (
                    <MenuItem key={option.id} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}*/}
                </TextField>
                <TextField
                  fullWidth
                  select
                  label="Session Env"
                  margin="normal"
                  name="sessionEnv"
                  onChange={this.handleChange("sessionEnv")}
                  required
                  value={this.state.sessionEnv}
                  variant="outlined"
                >
                  <MenuItem value={'pracRangeIndoor'}>Practice Range - Indoor</MenuItem>
                  <MenuItem value={'pracRangeOutdoor'}>Practice Range - Outdoor</MenuItem>
                  <MenuItem value={'open'}>Open Air</MenuItem>
                  <MenuItem value={'simulator'}>Simulator</MenuItem>
                  <MenuItem value={'indoor'}>Indoors</MenuItem>
                  <MenuItem value={'golfCourse'}>Golf Course</MenuItem>
                  {/*{this.state.sessionEnvs.map((option) => (
                    <MenuItem key={option.id} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}*/}
                </TextField>
                <TextField
                  fullWidth
                  id="sessionDate"
                  margin="normal"
                  label="Session Date"
                  type="datetime-local"
                  onChange={this.handleChange("sessionDate")}
                  required
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
              <div className={this.props.classes.root}>
                <Typography
                  variant="h6"
                  align="left"
                >
                  ACTIVITY
                </Typography>
                <TextField
                  autoComplete="activityId"
                  fullWidth
                  select
                  label="Activity"
                  margin="normal"
                  name="activityId"
                  onChange={this.handleChange("activityId")}
                  required
                  value = {this.state.activityId}
                  variant="outlined"
                >
                  <MenuItem value={'golf'}>Golf</MenuItem>
                  {/*{this.state.userActivities.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.activityName}
                    </MenuItem>
                  ))}*/}
                </TextField>
              </div>
              <div className={this.props.classes.root}>
                <Typography
                  variant="h6"
                  align="left"
                >
                  CAMERA
                </Typography>
                <TextField
                  fullWidth
                  select
                  label="Camera Setup"
                  margin="normal"
                  name="selectedCamera"
                  onChange={this.handleCameraSelect}
                  required
                  value={this.state.selectedCameraId}
                  variant="outlined"
                >
                  {Object.keys(this.state.cameraSetups).map((id) => (
                    <MenuItem key={id} value={this.state.cameraSetups[id].id} width="50%">
                      {this.state.cameraSetups[id].name}
                    </MenuItem>
                  ))}
                  <MenuItem key='custom' value = 'custom' width="50%">
                    Create New
                  </MenuItem>
                </TextField>
                {this.state.selectedCameraId === 'custom' ? <div style={{paddingLeft: '5%', marginTop: '10px'}}>
                  <Grid container spacing={1}>
                    <Grid item xs={8}>
                      <TextField
                        fullWidth
                        label='Name'
                        name="name"
                        onChange={this.handleCameraChange('name')}
                        required
                        value={this.state.newCameraSetup.name}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        fullWidth
                        label='# of Cameras'
                        name='setupCombination'
                        onChange={this.handleCameraChange('setupCombination')}
                        required
                        value={this.state.newCameraSetup.setupCombination}
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Typography
                      variant="h6"
                      align="left"
                      color='textSecondary'
                      style={{marginTop: '10px'}}>
                      CAMERAS USED 
                    </Typography>
                    <List disablePadding={true}>
                      {this.state.newCameraSetup.combinations.map((combo, index) => (<ListItem key={index} style={{marginBottom: '-15px', paddingTop: 0, paddingLeft: 0}}>
                        <Grid container>
                          <Grid item xs={6}> 
                            <TextField
                              fullWidth
                              required
                              InputLabelProps={{
                                shrink: false
                              }}
                              label={combo.brand.length > 0 ? '' : 'Brand'}
                              onChange={this.handleCombinationChange(index, 'brand')}
                              value={combo.brand}
                              variant='outlined'
                              margin='dense'
                            />
                          </Grid>
                          <Grid item xs={6}> 
                            <TextField
                              fullWidth
                              required
                              InputLabelProps={{
                                shrink: false
                              }}
                              label={combo.model.length > 0 ? '' : 'Model'}
                              onChange={this.handleCombinationChange(index, 'model')}
                              value={combo.model}
                              variant="outlined"
                              margin='dense'
                            />
                          </Grid>
                        </Grid>
                        <ListItemSecondaryAction>
                          <IconButton edge = 'end' aria-label='delete' onClick={this.handleCombinationChange(index, 'delete')}>
                            <DeleteIcon/>
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItem>))}
                    </List>
                  </Grid>
                  <div style={{display:'flex', justifyContent: 'flex-end', marginRight: '16px'}}>
                    <IconButton edge='end' onClick={this.handleCombinationChange(this.state.newCameraSetup.combinations.length)}>
                      <AddIcon/>
                    </IconButton>
                  </div>
                </div> : null}
              </div>
              <div className={this.props.classes.root}>
                <Typography
                  variant="h6"
                  align="left"
                >
                  CONDITIONS
                </Typography>
                <TextField
                  autoComplete="location"
                  fullWidth
                  label="Address"
                  margin="normal"
                  name="location"
                  onChange={this.handleChange("addressToString")}
                  required
                  value={this.state.addressToString}
                  variant="outlined"
                />
                <TextField
                  autoComplete="temperature"
                  fullWidth
                  label="Temperature(F)"
                  margin="normal"
                  name="temperature"
                  onChange={this.handleChange("temperature")}
                  required
                  value={this.state.temperature}
                  variant="outlined"
                />
                <TextField
                  autoComplete="weatherDesc"
                  fullWidth
                  label="Weather Description"
                  margin="normal"
                  name="weatherDesc"
                  onChange={this.handleChange("weatherDesc")}
                  required
                  value={this.state.weatherDesc}
                  variant="outlined"
                />
                <TextField
                  autoComplete="windSpeed"
                  fullWidth
                  label="Wind Speed(mph)"
                  margin="normal"
                  name="windSpeed"
                  onChange={this.handleChange("windSpeed")}
                  required
                  value={this.state.windSpeed}
                  variant="outlined"
                />
                <TextField
                  autoComplete="humidity"
                  fullWidth
                  label="Humidity"
                  margin="normal"
                  name="humidity"
                  onChange={this.handleChange("humidity")}
                  value={this.state.humidity}
                  variant="outlined"
                />
              </div>
              <Button
                className={this.props.classes.submit}
                color="primary"
                fullWidth
                type="submit"
                variant="contained"
              >
                CREATE SESSION
              </Button>
              <Grid container>
                <Grid
                  item
                  xs
                >
                  <Link
                    component={RouterLink}
                    to="/session-details"
                    variant="body2"
                  >
                    {'Go back to sessions'}
                  </Link>
                </Grid>
              </Grid>
            </form>
          </div>
        </Grid>

        {/* Loading screen*/}
        <Grid
          style = {{margin : "auto", padding : 100, display: (this.state.loading) ? "block" : "none"}}
        >
          <CircularProgress />
        </Grid>

        {/* Success screen*/}
        <Grid
          style = {{margin : "auto", paddingTop : 100, display: (this.state.success) ? "block" : "none"}}
        >
          <div className={this.props.classes.paper}>
            <Avatar className={this.props.classes.avatar}>
              <CheckCircleIcon />
            </Avatar>
            <Typography component="h1" variant="h2">
              Success!
            </Typography>

            <Typography component="h5" align = "center">
              Successfully created a new session.
            </Typography>
            <Link className={this.props.classes.buttonLink} component={RouterLink} to="/session-details">
              <Button
                fullWidth
                variant="contained"
                color="primary"
                className={this.props.classes.linkedButton}
                onClick={() => {
                  this.setState({ success: false })
                }}
              >
                Create Another Session
              </Button>
            </Link>
            <Link className={this.props.classes.buttonLink} component={RouterLink} to="/video-upload">
              <Button
                fullWidth
                variant="contained"
                color="primary"
                className={this.props.classes.linkedButton}
              >
                Upload a Video
              </Button>
            </Link>
            <Link className={this.props.classes.buttonLink} component={RouterLink} to="/dashboard">
              <Button
                fullWidth
                variant="contained"
                color="primary"
                className={this.props.classes.linkedButton}
              >
                Back To Dashboard
              </Button>
            </Link>
          </div>
        </Grid>
        {/* Error screen*/}
        <Grid
          item
          lg={12}
          md={12}
          xl={12}
          xs={12}
          style = {{display: (this.state.error && !this.state.loading) ? "block" : "none"}}
        >
          <AccountNotFound            
            error = {{error : this.state.error}}
          />
        </Grid>
      </Container>
    );
  }
};

CreateSession.propTypes = {
  classes:PropTypes.object,
  history: PropTypes.object,
};

export default withStyles(useStyles)(CreateSession);
