import React, { forwardRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import firebase, { functionBaseUrl, axiosWithToken } from '../../common/firebase';
import { Avatar, Typography, Divider, Grid, Dialog, DialogTitle } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import Swal from 'sweetalert2';
import moment from 'moment';
import MaterialTable from "material-table";
import {
  AddBox,
  ArrowDownward,
  Check,
  ChevronLeft,
  ChevronRight,
  Clear,
  DeleteOutline,
  Edit,
  FilterList,
  FirstPage,
  LastPage,
  Remove,
  SaveAlt,
  Search,
  ViewColumn,
  Receipt,
} from "@material-ui/icons";

const DisplayList = (props) => {
  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
    Receipt: forwardRef((props, ref) => <Receipt {...props} ref={ref} />),
  };

  const tableHeader = [
    { field: "userEmail", title: "Email Address" },
    { field: "userName", title: "Name" },
    { field: "startDate", title: "Start Date",
      render: (rowData) => {
        return moment(String(rowData.startDate), "x").format("MM/DD/YYYY");
      }   
    },
    { field: "coachDetails", title: "Plan", 
      render: (rowData) => {
        if (rowData.coachDetails && rowData.coachDetails.productId.includes("student_lite_monthly")) {
          return "3D Player Monthly"
        }
        if (rowData.coachDetails && rowData.coachDetails.productId.includes("student_lite_annually")) {
          return "3D Player Annually"
        }
        return ""
      } 
    },
    { field: "amount", title: "Amount",
      render: (rowData) => {
        return "$" + rowData?.amount
      }
    },
    { field: "revenue", title: "Revenue Share" },
    { field: "coachDetails", title: "Period",
      render: (rowData) => {
        return rowData?.coachDetails?.period
      } 
    },
    {
      field: "cost",
      title: "Cost",
      render: (rowData) => {
        return "$" + (rowData.amount * ((100 - parseInt(rowData.revenue)) / 100) * rowData.coachDetails.period).toFixed(2);
      },
    },
  ];

  const [isLoading, setIsLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [total, setTotal] = useState(0);
  const [amount, setAmount] = useState(0);

  const loadData = async () => {
    try {
      setIsLoading(true);
      const response = await axiosWithToken(`${functionBaseUrl}/api/unpaidStudentsSub`, {
        method: 'POST',
        data: { userId: props.rowData.userId }
      });
      let total = 0;
      let amount = 0;
      let discount = 10;
      for (let index = 0; index < response.data.data.length; index++) {
        if ((response.data.total + index) < 29) {
          discount = 10;
        } else if ((response.data.total + index) < 49) {
          discount = 20; 
        } else {
          discount = 30;
        }
        total += response.data.data[index].amount * response.data.data[index].coachDetails.period;
        amount += response.data.data[index].amount * (100 - discount) / 100 * response.data.data[index].coachDetails.period;
        response.data.data[index].revenue = `${discount}%`;
        //response.data.data[index].amount = (response.data.data[index].amount * (100 - discount) / 100).toFixed(2);
      }
      setUsers(response.data.data);
      setTotal(total.toFixed(2));
      setAmount(amount.toFixed(2));
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      Swal.fire({
        title: '<p style="font-size:70%;">Error loading data</p>',
        icon: 'error',
        confirmButtonText: 'Ok',
        allowOutsideClick: true,
      });
    }
  }

  useEffect(() => {
    if (props.type === 'staff') {
      loadData();
    }
  }, []);

  let content = null;
  if (props.open) {
    if (props.type === 'enterprise') {
      props.onClose();
      content = null;
    } else if (props.type === 'staff') {
      content = (<div style={{ display: 'flex' }}>
        <Grid container style={{ minWidth: '400px' }}>
          <Grid item xs={12}>
            <Typography
              variant='h4'>
              <b>{props.rowData.email}</b>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant='h4'>
              <b>Amount Owed: </b>${amount}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider style={{ width: '60%' }}/>
          </Grid>
          <Grid>
            <div>
              <MaterialTable
                fullWidth
                style={{ marginTop: "10px", marginBottom: "10px", width: "100%" }}
                icons={tableIcons}
                columns={tableHeader}
                title="Students"
                data={users}
                options={{
                  selection: false,
                  sorting: true,
                  search: true,
                  paging: true,
                  exportAllData: true,
                  exportButton: true,
                  rowStyle: (x) => {
                    if (x.tableData.id % 2) {
                      return { backgroundColor: "#f2f2f2" };
                    }
                  },
                }}
              />
            </div>
          </Grid>
        </Grid>
      </div>)
    }
  }

  return props.open ? (
    <div style={{ display: 'flex' }}> 
    <Dialog
      fullWidth
      maxWidth={"lg"}
      style={{ width: '100%' }}
      open={props.open}
      onClose={props.onClose}
      aria-labelledby='form-dialog-title'>
      <DialogTitle>
        <Close style={{float: 'right', marginTop: '-10px', marginRight: '-15px'}} onClick={props.onClose}/>
        {content}
      </DialogTitle>
    </Dialog></div>
  ) : null
};

DisplayList.propTypes = {
  item: PropTypes.object
};

export default DisplayList;