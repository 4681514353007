import React from 'react';
import { AddressElement } from '@stripe/react-stripe-js';
import { firebaseConfig } from '../../common/firebase';

function StripeAddressFormComponent({ defaultValues }) {
  return (
    <AddressElement
      options={{
        mode: 'billing',
        defaultValues,
        autocomplete: {
          mode: 'google_maps_api',
          apiKey: firebaseConfig.apiKey,
        },
      }}
    />
  );
};

export const StripeAddressForm = StripeAddressFormComponent;
