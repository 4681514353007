import React, { Component } from 'react';
import PropTypes from 'prop-types';
import UserInfo from './UserInfo';
import { loginWithGoogle, loginWithFacebook } from '../../../common/firebase';

class UserInfoController extends Component {
  constructor(props){
    super(props);
    this.state = {
      uid: '',
      errors: {}
    }
  }

  handleLoginWithGoogle = () => {
    loginWithGoogle()
    .then (creds => {
      this.props.setValues({...this.props.values, loading: false, userInfo: {...this.props.values.userInfo, uid: creds.user.uid}});
      this.props.submitPage();
    })
    .catch(err => {
      this.handleLoginError(err);
    })
  }

  handleLoginWithFacebook = () => {
    loginWithFacebook()
    .then (creds => {
      this.props.setValues({...this.props.values, loading: false, userInfo: {...this.props.values.userInfo, uid: creds.user.uid}});
      this.props.submitPage();
    })
    .catch(err => {
      this.handleLoginError(err);
    })
  }

  handleLoginError = (err) => {
    if (err.code === 'auth/popup-blocked') {
      this.setState({errors: {signUpOption: 'Popup was blocked by browser'}})
      alert('Popup was blocked by browser');
    } else if (err.code === 'auth/popup-closed-by-user') {
      this.setState({errors: {signUpOption: 'Popup was closed prematurely'}})
      alert('Popup was closed prematurely');
    } else if (err.code === 'auth/account-exists-with-different-credential') {
      this.setState({errors: {signUpOption: 'An account already exists with this email, did you sign up using a different platform?'}})
      alert('An account already exists with this email, did you sign up using a different platform?');
    }
    else {
      console.log(err);
    }
    this.props.setValues({...this.props.values, loading:false});
  }
  isEmpty = (obj) => {
    for(var prop in obj) {
      if(obj.hasOwnProperty(prop)) {
        return false;
      }
    }
    return true;
  }

  validateEmail = (email) => {
    //const verify = /^[A-Z0-9_'%=+!`#~$*?^{}&|-]+([.][A-Z0-9_'%=+!`#~$*?^{}&|-]+)*@[A-Z0-9-]+(\.[A-Z0-9-]+)+$/i;
    const verify = /\S+@\S+\.\S+/
    return verify.test(email)
  }

  handleSubmit = () => {
    //let { submitPage } = this.props;
    let tempErrors = {};
    this.props.setValues({...this.props.values, loading: true});
    if (this.props.values.userInfo.authOption === 'email'){
      if (!this.validateEmail(this.props.values.userInfo.email)) {
        tempErrors = {...tempErrors, ...{email: 'Email is invalid'}};
      }
      if(this.props.values.userInfo.password.length < 6){
        tempErrors = {...tempErrors, ...{password: 'Password must be of length 6'}};
      }
      if (this.props.values.userInfo.password !== this.props.values.userInfo.confirmPassword)
      {
        tempErrors = {...tempErrors, ...{confirmPassword: 'Passwords must match'}};
      }
    }
    /*else if (!(this.props.values.userInfo.authOption === 'google' || this.props.values.userInfo.authOption === 'facebook')){
      tempErrors = {...tempErrors, ...{signUpOption: 'Please select a login method'}};
    }*/
    //console.log("Done with email & pass checks")
    //console.log("errors: " + this.isEmpty(tempErrors))
    if(/*this.isEmpty(response.data.out) &&*/ this.isEmpty(tempErrors)){
      if (this.props.values.userInfo.authOption === 'google') {
        this.handleLoginWithGoogle();
      }
      else if (this.props.values.userInfo.authOption === 'facebook') {
        this.handleLoginWithFacebook();
      }
      else {
        this.props.setValues({...this.props.values, loading: false});
        this.props.submitPage();
      }
    }
    else{
      alert(JSON.stringify(tempErrors))
      tempErrors = {...tempErrors};
      this.props.setValues({...this.props.values, loading: false});
      this.setState({errors: tempErrors})
    }
    /*axiosWithToken(functionBaseUrl + '/api/users/validate', {
      method: 'POST',
      data: this.props.values
    }) 
      .then(response => {
        if(this.isEmpty(response.data.out) && this.isEmpty(tempErrors)){
          if (this.props.values.userInfo.authOption === 'google') {
            this.handleLoginWithGoogle();
          }
          else if (this.props.values.userInfo.authOption === 'facebook') {
            this.handleLoginWithFacebook();
          }
          else {
            console.log("Calling submitPage")
            submitPage();
          }
        }
        else{
          tempErrors = {...tempErrors, ...response.data.out};
          this.props.setValues({...this.props.values, loading:false});
          this.setState({errors: tempErrors})
        }
      })
      .catch(err => {
        this.props.setValues({...this.props.values, loading:false});
        console.log(err.code);
        console.log(err.message);
      })*/
  }
  render() {
    return(<UserInfo
      classes={this.props.classes}
      setValues={this.props.setValues}
      submitPage={this.handleSubmit}
      setUserInfo={this.props.setUserInfo}
      values={this.props.values}
      errors={this.state.errors}
    />)
  }   
}

UserInfoController.propTypes = {
  classes:PropTypes.object,
  setValues: PropTypes.func,
  submitPage: PropTypes.func,
  values: PropTypes.object
}

export default UserInfoController;