import React, { useEffect, useRef, useState } from "react";
import Popover from "@material-ui/core/Popover";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import s from "./styles.module.css";
import CheckBoxOutlineBlankOutlinedIcon from "@material-ui/icons/CheckBoxOutlineBlankOutlined";
import CheckBoxOutlinedIcon from "@material-ui/icons/CheckBoxOutlined";

export const PositionSelect = ({
  values,
  name,
  onChange,
  options,
  label,
  width = "100%",
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = (event) => {
    setIsOpen((prev) => !prev);
  };

  return (
    <div className={s.container} style={{ width }}>
      <div className={s.selectLabel}>{label}</div>
      <div className={s.selectWrapper} onClick={handleClick}>
        <div className={s.selectValue}>
          <span>
            {values.length ? values[0] : "Select position or phase"}{" "}
            {values.length > 1 ? `(${values.length})` : ""}
          </span>
          <KeyboardArrowDownIcon />
        </div>
      </div>
      {isOpen ? (
        <div className={s.optionsContainer}>
          <div className={s.optionsLabel}>Positions</div>
          <div className={s.options}>
            {options.map((el) => (
              <div
                style={{ display: "flex", gap: "5px" }}
                className={s.option}
                key={el.value}
                onClick={() => onChange(el.value)}
              >
                {values.length && values.includes(el.value) ? (
                  <CheckBoxOutlinedIcon style={{ color: "#4009A5" }} />
                ) : (
                  <CheckBoxOutlineBlankOutlinedIcon
                    style={{ color: "#4009A5" }}
                  />
                )}
                <div>{el.text}</div>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <></>
      )}
      {/* <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        PaperProps={{
          style: {
            width: popoverWidth,
          },
        }}
      ></Popover> */}
    </div>
  );
};
