import React, { useState } from 'react';

import { Container, Grid } from '@material-ui/core';

import { RoadMap } from './RoadMap';
import { SignUp } from './SignUp';
import { Payment } from './Payment';
import { CTAWelcome } from './CTAWelcome';
import { CTASignUp } from './CTASignUp';
import { CTASelectOffer } from './CTASelectOffer';
import firebase from '../../common/firebase';
import './promoCheckout.css';

function PromoCheckoutComponent() {
  const [currentStep, setCurrentStep] = useState(1);
  const [email, setEmail] = useState('');
  const [product, setProduct] = useState(
    window.location.href.includes("gpod") ? "gpod" : "sb3d"
  );

  const handleSignUp = (email) => {
    setEmail(email);
    if (firebase.auth().currentUser) {
      firebase.auth().signOut();
    }
    setCurrentStep(2);
  }

  const handlePayment = () => {
    setCurrentStep(3);
  }

  return (<>
    <Container
      component="main"
      maxWidth="xl"
      style={{ paddingTop: '50px' }}
    >
      <Grid container>
        {currentStep === 3 && (<>
          <Grid
            item
            xs={false}
            md={3}
            xl={4}
          >
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            xl={4}
          >
            <CTAWelcome email={email} />
          </Grid>
          <Grid
            item
            xs={false}
            md={3}
            xl={4}
          >
          </Grid>
        </>)}
        {currentStep !== 3 && (<>
          <Grid
            item
            xs={12}
            md={6}
            className="offer-section"
          >
            {currentStep === 1 && (<CTASignUp />)}
            {currentStep === 2 && (<CTASelectOffer product={product} />)}
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            className="offer-section"
          >
            <RoadMap currentStep={currentStep} />
            {currentStep === 1 && (<SignUp finishSignUp={handleSignUp} />)}
            {currentStep === 2 && (<Payment email={email} product={product} finishPayment={handlePayment} />)}
          </Grid>
        </>)}
      </Grid>
    </Container>
  </>);
}

export const PromoCheckout = PromoCheckoutComponent;