import admin from "../../../common/firebase";
import { fbMapper } from "../../../helpers/fbMapper";
import { subDays, getTime, subYears } from "date-fns";

const mapper = (snap) => {
    const output = [];
    snap.forEach((doc) => {
        output.push({ ...doc.data(), uid: doc.id });
    });
    return output;
};

const chunker = (arr) => {
    const chunks = [];
    for (let i = 0; i < arr.length; i += 10) {
        chunks.push(arr.slice(i, i + 10));
    }
    return chunks;
};

export async function getReportByCoach(id) {
    const coach = (
        await admin.firestore().collection("users").doc(id).get()
    ).data();
    if (coach.students.length !== 0) {
        // const students = fbMapper(
        //     await admin
        //         .firestore()
        //         .collection("users")
        //         .where(
        //             admin.firestore.FieldPath.documentId(),
        //             "in",
        //             coach.students
        //         )
        //         .get()
        // );

        const students = (
            await Promise.all(
                chunker(coach.students).map(async (el) => {
                    const users = mapper(
                        await admin
                            .firestore()
                            .collection("users")
                            // .where(
                            //     admin.firestore.FieldPath.documentId(),
                            //     "in",
                            //     el
                            // )
                            .where("coaches", "array-contains", id)
                            .orderBy("userCreated", "desc")
                            .get()
                    );
                    return users;
                })
            )
        ).flat();

        const now = new Date();

        const freeUsers = [];
        const paidUsers = [];

        students.forEach((el) => {
            if (el.subscriptionType) {
                if (el.subscriptionType.includes("free_student")) {
                    freeUsers.push(el);
                } else if (el.subscriptionType.includes("sportsbox_student")) {
                    paidUsers.push(el);
                }
            }
        });

        const freeUsersStats = {
            lastMonth: freeUsers.filter((el) => {
                return getTime(subDays(now, 30)) < el.userCreated;
            }).length,
            lastYear: freeUsers.filter((el) => {
                return getTime(subYears(now, 1)) < el.userCreated;
            }).length,
            historically: freeUsers.length,
        };

        const paidUsersStats = {
            lastMonth: paidUsers.filter(
                (el) => getTime(subDays(now, 30)) < el.userCreated
            ).length,
            lastYear: paidUsers.filter(
                (el) => getTime(subYears(now, 1)) < el.userCreated
            ).length,
            historically: paidUsers.length,
            monthly: paidUsers.filter(
                (el) => !el.subscriptionType.includes("monthly")
            ).length,
            annually: paidUsers.filter(
                (el) => !el.subscriptionType.includes("annually")
            ).length,
        };

        const revenueStatsCalculation = () => {
            const lastMonth = paidUsers.reduce((acc, el) => {
                let temp = acc;
                if (getTime(subDays(now, 30)) < el.userCreated) {
                    if (el.subscriptionType.includes("monthly")) {
                        temp += 15.99;
                    } else if (el.subscriptionType.includes("annually")) {
                        temp += 110;
                    }
                }
                return temp;
            }, 0);
            const lastYear = paidUsers.reduce((acc, el) => {
                let temp = acc;
                if (getTime(subYears(now, 1)) < el.userCreated) {
                    if (el.subscriptionType.includes("monthly")) {
                        temp += 15.99;
                    } else if (el.subscriptionType.includes("annually")) {
                        temp += 110;
                    }
                }
                return temp;
            }, 0);
            return {
                lastMonth: lastMonth.toFixed(2),
                lastYear: lastYear.toFixed(2),
                historically: (
                    paidUsersStats.monthly * 15.99 +
                    paidUsersStats.annually * 110
                ).toFixed(2),
            };
        };

        const mappedStudents = await Promise.all(
            students.map(async (elt) => {
                const nowDate = new Date();
                const sessions = fbMapper(
                    await admin
                        .firestore()
                        .collection("sessions")
                        .where("sessionUserId", "==", elt.uid)
                        .get()
                );
                const watchlists = fbMapper(
                    await admin
                        .firestore()
                        .collection("watchlist")
                        .where("studentId", "==", elt.uid)
                        .get()
                );
                const sessionsStats = {
                    lastWeek: sessions.filter(
                        (el) => getTime(subDays(nowDate, 7)) < +el.sessionDate
                    ).length,
                    lastMonth: sessions.filter(
                        (el) => getTime(subDays(nowDate, 30)) < +el.sessionDate
                    ).length,
                    lastYear: sessions.filter(
                        (el) => getTime(subYears(nowDate, 1)) < +el.sessionDate
                    ).length,
                };
                const watchlistsStats = {
                    lastWeek: watchlists.filter(
                        (el) => getTime(subDays(nowDate, 7)) < el.createdAt
                    ).length,
                    lastMonth: watchlists.filter(
                        (el) => getTime(subDays(nowDate, 30)) < el.createdAt
                    ).length,
                    lastYear: watchlists.filter(
                        (el) => getTime(subYears(nowDate, 1)) < el.createdAt
                    ).length,
                };
                return {
                    ...elt,
                    sessionsStats,
                    watchlistsStats,
                };
            })
        );

        return {
            freeUsersStats,
            paidUsersStats,
            revenueStats: revenueStatsCalculation(),
            students: mappedStudents,
        };
    }
    return {
        freeUsersStats: {
            lastMonth: 0,
            lastYear: 0,
            historically: 0,
        },
        paidUsersStats: {
            lastMonth: 0,
            lastYear: 0,
            historically: 0,
            monthly: 0,
            annually: 0,
        },
        revenueStats: { lastMonth: 0, lastYear: 0, historically: 0 },
        students: [],
    };
}

export async function getReportData() {
    const users = fbMapper(await admin.firestore().collection("users").get());

    const now = new Date();

    const coaches = [];
    const freeUsers = [];
    const paidUsers = [];

    users.forEach((el) => {
        if (el.subscriptionType) {
            if (el.subscriptionType.includes("enterprise_master")) {
                coaches.push(el);
            } else if (el.subscriptionType.includes("free_student")) {
                freeUsers.push(el);
            } else if (el.subscriptionType.includes("sportsbox_student")) {
                paidUsers.push(el);
            }
        }
    });

    const freeUsersStats = {
        lastMonth: freeUsers.filter((el) => {
            return getTime(subDays(now, 30)) < el.userCreated;
        }).length,
        lastYear: freeUsers.filter((el) => {
            return getTime(subYears(now, 1)) < el.userCreated;
        }).length,
        historically: freeUsers.length,
    };

    const paidUsersStats = {
        lastMonth: paidUsers.filter(
            (el) => getTime(subDays(now, 30)) < el.userCreated
        ).length,
        lastYear: paidUsers.filter(
            (el) => getTime(subYears(now, 1)) < el.userCreated
        ).length,
        historically: paidUsers.length,
        monthly: paidUsers.filter(
            (el) => !el.subscriptionType.includes("monthly")
        ).length,
        annually: paidUsers.filter(
            (el) => !el.subscriptionType.includes("annually")
        ).length,
    };

    const revenueStatsCalculation = () => {
        const lastMonth = paidUsers.reduce((acc, el) => {
            let temp = acc;
            if (getTime(subDays(now, 30)) < el.userCreated) {
                if (el.subscriptionType.includes("monthly")) {
                    temp += 15.99;
                } else if (el.subscriptionType.includes("annually")) {
                    temp += 110;
                }
            }
            return temp;
        }, 0);
        const lastYear = paidUsers.reduce((acc, el) => {
            let temp = acc;
            if (getTime(subYears(now, 1)) < el.userCreated) {
                if (el.subscriptionType.includes("monthly")) {
                    temp += 15.99;
                } else if (el.subscriptionType.includes("annually")) {
                    temp += 110;
                }
            }
            return temp;
        }, 0);
        return {
            lastMonth: lastMonth.toFixed(2),
            lastYear: lastYear.toFixed(2),
            historically: (
                paidUsersStats.monthly * 15.99 +
                paidUsersStats.annually * 110
            ).toFixed(2),
        };
    };

    return {
        freeUsersStats,
        paidUsersStats,
        revenueStats: revenueStatsCalculation(),
        coaches,
    };
}
