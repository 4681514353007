import React from "react";
import { Link as RouterLink } from "react-router-dom";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/styles";
import {
  Avatar,
  Typography,
  CircularProgress,
  Button,
  Divider,
  Card,
} from "@material-ui/core";
import firebase from "../../../../../../common/firebase";
import Swal from "sweetalert2";

const useStyles = (theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    minHeight: "fit-content",
    marginBottom: theme.spacing(1),
  },
  avatarContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: theme.spacing(1),
  },
  avatar: {
    width: 50,
    height: 50,
  },
  name: {
    marginLeft: 15,
    fontFamily: "Manrope",
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "20px",
    letterSpacing: "0em",
    textAlign: "left",
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
  heading: {
    fontFamily: "Manrope",
    fontSize: "24px",
    fontWeight: 600,
    lineHeight: "20px",
    letterSpacing: "0em",
    textAlign: "left",
    marginBottom: 15,
  },
  text: {
    fontFamily: "Manrope",
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "20px",
    letterSpacing: "0em",
    textAlign: "left",
    marginBottom: 10,
  },
  button: {
    marginLeft: 7,
  },
  card: {
    backgroundImage: `url("/images/icons/background_pattern.png")`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundColor: "#1D2939",
    padding: theme.spacing(2),
    color: "white",
  },
  cardText: {
    fontFamily: "Manrope",
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "20px",
    letterSpacing: "0em",
    textAlign: "left",
    marginBottom: 10,
    color: "white",
  },
});

class Profile extends React.Component {
  constructor(props) {
    super(props);
    const firebaseUser = firebase.auth().currentUser;

    this.state = {
      name: "",
      avatar: "",
      role: "",
      email: "",
      subscriptionType: "",
      uid: firebaseUser ? firebaseUser.uid : "",
      credits: 0,
      loading: true,
    };
  }

  componentDidMount() {
    firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        const userSnap = await firebase
          .firestore()
          .collection("users")
          .doc(user.uid)
          .get();
        if (!userSnap.exists) {
          console.log("No matching documents for UID: " + user.uid);
          this.setState({
            error: "The signed in user does not exist",
          });
        }
        const userDoc = userSnap.data();
        if (userDoc) {
          this.setState({
            role: userDoc?.role,
            email: userDoc?.email,
            name: userDoc?.firstName + " " + userDoc?.lastName,
            avatar: userDoc?.picture,
            credits: userDoc?.creditCount,
            subscriptionType: userDoc?.subscriptionType,
            loading: false,
          });
        }
      }
    });
  }

  handleLogout = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You will be logged out of your account",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      customClass: {
        container: "my-swal",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        firebase
          .auth()
          .signOut()
          .then(() => {
            this.props.history.push("/sign-in");
          })
          .catch((error) => {
            console.log(error);
          });
      }
    });
  };

  getInitials = (name) => {
    const words = name.split(" ");
    let initials = "";

    for (let i = 0; i < words.length; i++) {
      initials += words[i].charAt(0);
    }
    initials = initials.toUpperCase();

    return initials;
  };

  render() {
    const { classes } = this.props;
    return (
      <div>
        <Typography variant="h4" className={classes.heading}>
          Need Help?
        </Typography>
        {this.state.subscriptionType !== "" &&
          (this.state.subscriptionType.includes("enterprise") ||
            this.state.role === "admin") && (
            <Typography variant="body2" className={classes.text}>
              Contact us directly{"   "}
              <Button
                className={classes.button}
                onClick={() =>
                  (window.location.href = "mailto:enterprise@sportsbox.ai")
                }
              >
                <img src="/images/icons/navigation.png" />
              </Button>
            </Typography>
          )}
        <Typography variant="body2" className={classes.text}>
          Visit our help center
          <Button
            className={classes.button}
            onClick={() => window.open("https://help.sportsbox.ai/")}
          >
            <img src="/images/icons/navigation.png" />
          </Button>
        </Typography>
        {this.state.subscriptionType !== "" &&
          !this.state.subscriptionType.includes("student") && (
            <Card className={classes.card}>
              <Typography variant="h4" className={classes.cardText}>
                {this.state.credits || 0} credits available
                <br />
                <br />
                <Typography
                  component="span"
                  className={classes.cardText}
                  onClick={() => this.props.history.push("/credits")}
                  style={{ cursor: "pointer" }}
                >
                  Buy credits
                </Typography>
              </Typography>
            </Card>
          )}
        <Divider className={classes.divider} />
        <div className={classes.avatarContainer}>
          <Avatar
            component={RouterLink}
            to="/account"
            className={classes.avatar}
            src={this.state.avatar}
            style={{ display: this.state.loading ? "none" : "flex" }}
          >
            {!this.state.avatar ? this.getInitials(this.state.name) : ""}
          </Avatar>
          <div className={classes.root}>
            <Typography variant="h5" className={classes.name}>
              {this.state.name.length > 15
                ? this.state.name.substring(0, 15) +
                  "\n" +
                  this.state.name.substring(15, this.state.name.length)
                : this.state.name}
            </Typography>
            <Typography variant="body2" className={classes.name}>
              {this.state.email.length > 15
                ? this.state.email.substring(0, 15) +
                  "\n" +
                  this.state.email.substring(15, this.state.email.length)
                : this.state.email}
            </Typography>
          </div>
          <Button onClick={() => this.handleLogout()}>
            <img src="/images/icons/logout_button.png" />
          </Button>
          <CircularProgress
            style={{ display: this.state.loading ? "block" : "none" }}
          />
        </div>
      </div>
    );
  }
}

Profile.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(useStyles)(Profile);
