import React from "react";

export const ReportIndicatorCardGroup = ({ groupName, percentage, cards }) => {
  return (
    <>
      <div style={{ borderBottom: "#E5E7EB 1px solid" }}></div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          gap: "50px",
          padding: "10px 0",
        }}
      >
        <div style={{ fontSize: "12px" }}>
          <div style={{ fontWeight: "bold", marginBottom: "10px" }}>
            {groupName}
          </div>
          <div>
            <span style={{ fontWeight: "bold", fontSize: "14px" }}>
              {percentage > 100
                ? 100
                : percentage < 0
                ? 0
                : percentage.toFixed(0)}
            </span>
            <span style={{ fontSize: "8px" }}>/100</span>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            gap: "10px",
            justifyContent: "flex-end",
          }}
        >
          {cards}
        </div>
      </div>
    </>
  );
};
