import MaterialTable from "material-table";
import React from "react";

export function StudentList({ data }) {
  const tableOptions = {
    toolbar: false,
    selection: false,
    sorting: false,
    search: false,
    paging: false,
    rowStyle: (x) => {
      if (x.tableData.id % 2) {
        return { backgroundColor: "#f2f2f2" };
      }
    },
  };
  const cols = [
    { field: "index", title: "#" },
    { field: "firstName", title: "First Name" },
    { field: "lastName", title: "Last Name" },
    { field: "sessions", title: "Sessions (Week/Month/Year)" },
    { field: "watchlists", title: "Watchlists (Week/Month/Year)" },
  ];

  return (
    <>
      <MaterialTable
        style={{ marginTop: "10px", marginBottom: "10px" }}
        key="students"
        columns={cols}
        data={data.map((el, i) => ({
          ...el,
          index: i,
          sessions: `${el.sessionsStats.lastWeek}/${el.sessionsStats.lastMonth}/${el.sessionsStats.lastYear}`,
          watchlists: `${el.watchlistsStats.lastWeek}/${el.watchlistsStats.lastMonth}/${el.watchlistsStats.lastYear}`,
        }))}
        options={tableOptions}
      />
    </>
  );
}
