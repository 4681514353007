import React from 'react';
import { Grid } from '@material-ui/core';
import './promoCheckout.css';

function RoadMapComponent({ currentStep }) {
  return (<>
    <Grid
      container
      spacing={2}
      className="offer-roadmap"
    >
      <div className="offer-active-step">
        <span>1</span> Email
      </div>
      <div
        className={currentStep === 2 ? 'offer-active-step' : 'offer-inactive-step'}
      >
        <span>2</span> Checkout
      </div>
      <div
        className={currentStep > 2 ? 'offer-active-step' : 'offer-inactive-step'}
      >
        <span>3</span> Welcome
      </div>
    </Grid>
  </>);
}

export const RoadMap = RoadMapComponent;