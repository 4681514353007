import admin, {
  axiosWithToken,
  functionBaseUrl,
} from "../../../common/firebase";
import { fbMapper } from "./helper";
import { parseBytes } from "./common";
import { dateMapper, firestorageExistCheck } from "./helper";

const db = admin.firestore().collection("tipsAndDrillsVideos");

export const getTipsAndDrills = async () => {
  const vids = fbMapper(await db.get());
  return vids;
};

export const getPublicTipsAndDrills = async () => {
  const vids = fbMapper(await db.where("status", "==", "published").get());
  return vids;
};

export const getTipsAndDrillsServer = async ({
  limit,
  offset,
  search,
  sort,
  direction,
  status,
}) => {
  const { data } = await axiosWithToken(
    functionBaseUrl + "/api/web/tips-and-drills",
    {
      method: "post",
      data: { limit, offset, search, sort, direction, status },
    }
  );

  return data;
};

export const getTipsAndDrillsById = async (id) => {
  const vid = await db.doc(id).get();
  return vid.data();
};

export const createTipsAndDrillsVideo = async (data) => {
  const {
    title,
    author,
    drillRepeatCountMin,
    drillRepeatCountMax,
    equipment,
    repText,
    keyPoints,
    instructionalVideo,
    repVideo,
    instructionalVideoThumbnail,
    repAudio,
    status,
  } = data;

  const parsed = {
    creatorId: "sportsbox",
    title,
    drillRepeatCountMin: +drillRepeatCountMin,
    drillRepeatCountMax: +drillRepeatCountMax,
    definition: repText,
    drillInstruction: keyPoints.map((el) => el.value),
    author,
    equipment,
    tags: [],
    status,
    isPremium: false,
    likes: [],
    platform: "Cloud",
    accessLevel: "private",
    createdAt: Date.now(),
    updatedAt: Date.now(),
  };

  const created = await db.add(parsed);

  const storageRef = admin.storage().ref();

  if (repVideo.file) {
    const loopVideoExtension = repVideo.file.name.split(".").pop();
    const loopVideoFileRef = storageRef.child(
      `tips_and_drills/${created.id}/loopVideo.${loopVideoExtension}`
    );
    const loopVideoUploadTaskSnapshot = await loopVideoFileRef.put(
      repVideo.file
    );
    const loopVideoDownloadURL =
      await loopVideoUploadTaskSnapshot.ref.getDownloadURL();

    await db.doc(created.id).update({
      loopVideo: {
        videoPath: loopVideoDownloadURL,
        duration: repVideo.duration,
        title: "Title",
        thumbnail: "",
        fileSize: parseBytes(repVideo.file.size),
        thumbnailFileSize: 0,
      },
    });
  } else {
    await db.doc(created.id).update({
      loopVideo: {
        videoPath: "",
        duration: 0,
        title: "Title",
        thumbnail: "",
        fileSize: 0,
        thumbnailFileSize: 0,
      },
    });
  }

  if (instructionalVideo.file) {
    const instructionalVideoExtension = instructionalVideo.file.name
      .split(".")
      .pop();
    const instructionalVideoFileRef = storageRef.child(
      `tips_and_drills/${created.id}/instructionalVideo.${instructionalVideoExtension}`
    );
    const instructionalVideoUploadTaskSnapshot =
      await instructionalVideoFileRef.put(instructionalVideo.file);
    const instructionalVideoDownloadURL =
      await instructionalVideoUploadTaskSnapshot.ref.getDownloadURL();

    const instructionalVideoThumbnailExtension =
      instructionalVideoThumbnail.file.name.split(".").pop();
    const instructionalVideoThumbnailFileRef = storageRef.child(
      `tips_and_drills/${created.id}/instructionalVideoThumbnail.${instructionalVideoThumbnailExtension}`
    );
    const instructionalVideoThumbnailUploadTaskSnapshot =
      await instructionalVideoThumbnailFileRef.put(
        instructionalVideoThumbnail.file
      );
    const instructionalVideoThumbnailDownloadURL =
      await instructionalVideoThumbnailUploadTaskSnapshot.ref.getDownloadURL();

    await db.doc(created.id).update({
      instructionalVideo: {
        videoPath: instructionalVideoDownloadURL,
        duration: instructionalVideo.duration,
        title: "Title",
        thumbnail: instructionalVideoThumbnailDownloadURL,
        fileSize: parseBytes(instructionalVideo.file.size),
        thumbnailFileSize: parseBytes(instructionalVideoThumbnail.file.size),
      },
    });
  } else {
    await db.doc(created.id).update({
      instructionalVideo: {
        videoPath: "",
        duration: 0,
        title: "Title",
        thumbnail: "",
        fileSize: 0,
        thumbnailFileSize: 0,
      },
    });
  }

  if (repAudio.file) {
    const audioTrackExtension = repAudio.file.name.split(".").pop();
    const audioTrackFileRef = storageRef.child(
      `tips_and_drills/${created.id}/audioTrack.${audioTrackExtension}`
    );
    const audioTrackUploadTaskSnapshot = await audioTrackFileRef.put(
      repAudio.file
    );
    const audioTrackDownloadURL =
      await audioTrackUploadTaskSnapshot.ref.getDownloadURL();
    await db.doc(created.id).update({
      audioTrack: {
        audioPath: audioTrackDownloadURL,
        duration: repAudio.duration,
        title: "Title",
        fileSize: parseBytes(repAudio.file.size),
      },
    });
  } else {
    await db.doc(created.id).update({
      audioTrack: {
        audioPath: "",
        duration: 0,
        title: "Title",
        fileSize: 0,
      },
    });
  }
};

export const updateTipsAndDrillsVideo = async (id, data) => {
  const {
    title,
    author,
    drillRepeatCountMin,
    drillRepeatCountMax,
    equipment,
    repText,
    keyPoints,
    instructionalVideo,
    repVideo,
    instructionalVideoThumbnail,
    repVideoThumbnail,
    repAudio,
    status,
  } = data;

  const parsed = {
    title,
    drillRepeatCountMin: +drillRepeatCountMin,
    drillRepeatCountMax: +drillRepeatCountMax,
    definition: repText,
    drillInstruction: keyPoints.map((el) => el.value),
    author,
    status,
    equipment,
    updatedAt: Date.now(),
  };

  const storageRef = admin.storage().ref();

  if (repVideo.file) {
    const loopVideoExtension = repVideo.file.name.split(".").pop();
    const loopVideoFileRef = storageRef.child(
      `tips_and_drills/${id}/loopVideo.${loopVideoExtension}`
    );
    const loopVideoUploadTaskSnapshot = await loopVideoFileRef.put(
      repVideo.file
    );
    const loopVideoDownloadURL =
      await loopVideoUploadTaskSnapshot.ref.getDownloadURL();

    parsed.loopVideo = {
      videoPath: loopVideoDownloadURL,
      duration: repVideo.duration,
      title: "Title",
      fileSize: parseBytes(repVideo.file.size),
    };
  }

  if (repAudio.file) {
    const audioTrackExtension = repAudio.file.name.split(".").pop();
    const audioTrackFileRef = storageRef.child(
      `tips_and_drills/${id}/audioTrack.${audioTrackExtension}`
    );
    const audioTrackUploadTaskSnapshot = await audioTrackFileRef.put(
      repAudio.file
    );
    const audioTrackDownloadURL =
      await audioTrackUploadTaskSnapshot.ref.getDownloadURL();

    parsed.audioTrack = {
      audioPath: audioTrackDownloadURL,
      title: "Title",
      duration: repAudio.duration,
      fileSize: parseBytes(repAudio.file.size),
    };
  }

  if (instructionalVideo.file) {
    const instructionalVideoExtension = instructionalVideo.file.name
      .split(".")
      .pop();

    const instructionalVideoFileRef = storageRef.child(
      `tips_and_drills/${id}/instructionalVideo.${instructionalVideoExtension}`
    );
    const instructionalVideoUploadTaskSnapshot =
      await instructionalVideoFileRef.put(instructionalVideo.file);
    const instructionalVideoDownloadURL =
      await instructionalVideoUploadTaskSnapshot.ref.getDownloadURL();

    parsed.instructionalVideo = {
      videoPath: instructionalVideoDownloadURL,
      duration: instructionalVideo.duration,
      title: "Title",
      fileSize: parseBytes(instructionalVideo.file.size),
    };
  }

  await db.doc(id).update(parsed);

  if (instructionalVideoThumbnail.file) {
    const instructionalVideoThumbnailExtension =
      instructionalVideoThumbnail.file.name.split(".").pop();
    const instructionalVideoThumbnailFileRef = storageRef.child(
      `tips_and_drills/${id}/instructionalVideoThumbnail.${instructionalVideoThumbnailExtension}`
    );
    const instructionalVideoThumbnailUploadTaskSnapshot =
      await instructionalVideoThumbnailFileRef.put(
        instructionalVideoThumbnail.file
      );
    const instructionalVideoThumbnailDownloadURL =
      await instructionalVideoThumbnailUploadTaskSnapshot.ref.getDownloadURL();

    await db.doc(id).update({
      "instructionalVideo.thumbnail": instructionalVideoThumbnailDownloadURL,
      "instructionalVideo.thumbnailFileSize": parseBytes(
        instructionalVideoThumbnail.file.size
      ),
    });
  }
};

export const deleteTipsAndDrillsVideo = async (id) => {
  const storageRef = admin.storage().ref(`tips_and_drills/${id}`);
  storageRef.listAll().then((listResults) => {
    const promises = listResults.items.map((item) => {
      return item.delete();
    });
    Promise.all(promises);
  });

  await db.doc(id).delete();

  const pg = fbMapper(
    await admin.firestore().collection("practiceGuides").get()
  );

  const pgsWithDrill = pg.map((el) => {
    if (el.summary.drill) {
      const drill = el.summary.drill.videoIds.filter((drl) => drl !== id);
      return {
        ...el,
        summary: {
          ...el.summary,
          drill: { ...el.summary.drill, videoIds: drill },
        },
      };
    } else {
      return el;
    }
  });

  const pgsWithWarmup = pg.map((el) => {
    if (el.summary.warmup) {
      const warmup = el.summary.warmup.videoIds.filter((wu) => wu !== id);
      return {
        ...el,
        summary: {
          ...el.summary,
          warmup: { ...el.summary.warmup, videoIds: warmup },
        },
      };
    } else {
      return el;
    }
  });

  await Promise.all(
    pgsWithDrill.map(async (el) => {
      const { id, summary } = el;
      return await admin
        .firestore()
        .collection("practiceGuides")
        .doc(id)
        .update({
          summary,
        });
    })
  );

  await Promise.all(
    pgsWithWarmup.map(async (el) => {
      const { id, summary } = el;
      return await admin
        .firestore()
        .collection("practiceGuides")
        .doc(id)
        .update({
          summary,
        });
    })
  );
};

export const checkForDeleteTipsAndDrills = async (id) => {
  const drills = fbMapper(
    await admin
      .firestore()
      .collection("practiceGuides")
      .where("summary.drill.videoIds", "array-contains", id)
      .get()
  );
  const warmup = fbMapper(
    await admin
      .firestore()
      .collection("practiceGuides")
      .where("summary.warmup.videoIds", "array-contains", id)
      .get()
  );

  return [...drills, ...warmup];
};
