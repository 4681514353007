/**
 * AdvancedSearch.js
 * Handles the advanced search page
 * Contains all functions related to collecting users search query and parsing it into an elastic query
 */

 import React, { Component} from 'react';
 import { withStyles } from '@material-ui/styles';
 
 import { Box, createMuiTheme, Drawer, Grid, MuiThemeProvider} from '@material-ui/core';
 import { MuiPickersUtilsProvider } from '@material-ui/pickers';
 import DateFnsUtils from '@date-io/date-fns';
 
 import PersonIcon from '@material-ui/icons/Person';
 import DescriptionIcon from '@material-ui/icons/Description';
 import CameraIcon from '@material-ui/icons/Camera';
 import VideocamIcon from '@material-ui/icons/Videocam';
 import NotificationsIcon from "@material-ui/icons/Notifications";
 import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
 import CardMembershipIcon from '@material-ui/icons/CardMembership';
 import ListItems from './ListItems';
 import QueryFields, { getDefaultState } from './QueryFields';
 import _ from 'lodash';
 import { axiosWithToken, functionBaseUrl } from '../../../../common/firebase';
 import { isMobile } from 'react-device-detect';
 import { elasticIndex, reportIndex } from '../../../../common/elastic';
 import AdminActions from './AdminActions';
 import moment from 'moment';
import { listOfSubscriptions } from '../../../../common/envConfig';
import Swal from 'sweetalert2';
 
 const theme = createMuiTheme({
     typography: {
         fontSize: 12,
         h2: {
             fontSize: '1.5rem',
         },
         h3: {
             fontSize: '1.2rem',
             color: '#000',
         },
         h4: {
             fontSize: '1rem',
         },
         h5: {
             fontSize: '0.6rem'
         }
     }
 });
 const useStyles = (theme) => ({
     paper: {
     width: '100%',
     padding: theme.spacing(1),
     alignItems: 'center',
     },
     catagoryTitle: {
         fontSize: '1rem',
         color: '#000',
     },
     divider: {
         borderBottom: '1.5px solid grey',
         marginTop: 2
     },
     hideIconPadding: {
         "& .MuiSelect-outlined": {
            paddingRight: "0px"
         }
     }
 });


 const emailExceptionList = ["accuracy.perf@gmail.com","accuracy.faceon@gmail.com","accuracy.dtl@gmail.com","accuracy.collect1@gmail.com","accuracy.collect2@gmail.com","terryrowles@gmail.com","Sportsbox3dstaff@gmail.com","Jclay@pnwgolfcenters.com","Dan@fiveirongolf.com","wancoachtest1@gmail.com","ryan.crawley@pga.com","troondemo@gmail.com","ryan.crawley@pga.com","nikitadniestrov@tidway.com.ua","nashton0610@gmail.com","richk@aithinktank.com","mmoslenko@lagolfshafts.com","6connoro@gmail.com","noppwonglaw@gmail.com","timashton@frontiernet.net","databases.sb@gmail.com","mizuho.lpga@gmail.com","demo1.lpga@gmail.com","lululemon.3dgolf@gmail.com"]

 
 const blackListKeys = ['doc_relations', 'docType', '_id']
 export const iconMap = {
     'users': ['User', (<PersonIcon/>)],
     'sessions': ['Session', (<DescriptionIcon/>)],
     'videos': ['Video', (<VideocamIcon/>)],
     'reportIssues': ['Reports', (<CameraIcon/>)],
     'subscriptions': ['Subscriptions', (<MonetizationOnIcon/>)],
     'invites': ['Invites', (<NotificationsIcon/>)],
     'partnerSubscriptions': ['Partner Subs', (<CardMembershipIcon/>)],
     'payments': ['Payments', (<DescriptionIcon />)],
 }
 
 //Map for relationships between types
 export const parentChildMap = {
     users: {parent: '', children: ['sessions', 'subscriptions', 'invites']},
     sessions: {parent: 'users', children: ['videos']},
     videos: {parent: 'sessions', children: ['analysis'], exceptions: ['analysis']},
     analysis: {parent: 'videos', children: []},
     subscriptions: {parent: 'users', children: []},
     payments: { parent: 'subscriptions', children: [] },
     invites: {parent: 'users', children: []},
     partnerSubscriptions: {parent: 'users', children: []},
 }
 
 export const MAX_FIELD_LENGTH = 100;
 
/** Object with settings for all fields
 * 
 * label: string - label for the field
 * type: string - type of field (no type means basic text)
 *     - text - basic text field
 *     - select - select field
 *     - bool - checkbox field (check: true, minus: false, empty: do not include in query)
 *     - range - range field
 *     - arrayRange - range field for array length
 *     - dateRange - date field
 *     - link - link field, not searchable
 *     - video - video field, not searchable
 * nullable: array - only works for select fields. Allows fields that are included in the nullable array to be null or an empty string
 * converter: function - function to convert the value to a compatible format for the query
 * sortable: function - function to convert to script for elastic sorting
 * options: object - options for select fields {key: label}
 * maxLength: number - max length of text field (default: MAX_FIELD_LENGTH)
 * hidden: string - if set to "search", it will hide from the search query, if set to "table": it will hide from the table
 * special: bool - if set to true, will ignore the default mappings for the type and will need special handling
 * adminLock: 
 */
 export const FieldMaps = {
     users: {
         fullName: {label: 'Full Name'},
         email: {label: 'Email'},
         subscriptionType: {label: "Plan", adminLock: true},
         creditCount: { label: "# of Credits", type: 'range', adminLock: true },
         dobStr: {label: "DOB", adminLock: true},
         gender: {label: 'Gender', type: 'select', options: {Male: "Male", Female: 'Female', "prefer not to answer": 'Prefer Not to Answer'}},
         dominantHand: {label: 'Dominant Hand', type: 'select', options: { Left: "Left", Right: 'Right' }},
         height: {label: "Height", adminLock: true},
         userCreated: {label: 'Account Creation Date', type: 'dateRange', converter: (date) => date.getTime(), sortable: (key) => `(doc["${key}"].value).toEpochMilli()`, adminLock: true},
         coaches: {label: '# of Coaches', type: 'arrayRange', range: [0, 1000], default: [0, 5], sortable: (key) => `doc["${key}"].size()*100`},
         students: {label: '# of Students', type: 'arrayRange', range: [0, 1000], default: [0, 5], sortable: (key) => `doc["${key}"].size()*100`},
         sessionIds: { label: '# of Sessions', type: 'arrayRange', range: [0, 1000], default: [0, 5], sortable: (key) => `doc["${key}"].size()*100` },
         averageScore: {label: "Handicap", adminLock: true, type: 'range', sortable: (key) => `doc["${key}"].value`},
         removeTestAccounts: {label: 'Remove Test Accounts', type: 'bool', adminLock: true, special: true},
         coachEmail: { label: "Coach Email", adminLock: true, special: true, disableNegation: true, hidden: "table" },
         coachName: { label: "Coach Name", adminLock: true, special: true, disableNegation: true, hidden: "table" },
         swingAnalysisOffer: { label: "Swing Analysis Offer  Users", type: 'bool', adminLock: true, special: true, disableNegation: true, hidden: "table" },
         secondarySubType: { label: "Secondary Sub Type", type: 'select', options: { none: "None", [listOfSubscriptions.STUDENT_FREE]: "Free", [listOfSubscriptions.STUDENT_LITE_ANNUALLY]: "3D Player", [listOfSubscriptions.PREMIUM_ANNUALLY]: "3D Pro" }, adminLock: true, },
         permission: { label: "Permission", adminLock: true, type: 'select', options: { "internal": "Internal", "external": "External" }, disableNegation: true },
         handicap: {label: "Handicap", adminLock: true},
         shotShape: {label: "Shot Shape", adminLock: true},
         desiredShotShape: {label: "Desired Shot Shape", adminLock: true},
     },
     sessions: {
         sessionName: {label: 'Name'},
         sessionEnv: {label: 'Enviro.', type: 'select', options: {pracRangeIndoor:"Practice Range - Indoor",pracRangeOutdoor:"Practice Range - Outdoor",open:"Open Air",indoor:"Indoor", Outdoor:"Outdoor",golfCourse:"Golf Course",simulator:"Simulator"}},
         sessionPurpose: {label: 'Purpose'},
         sessionType: {label: 'Type', type: 'select', options: {upload: 'Upload', live: 'Live'}},
         weatherDesc: {label: 'Weather Desc.'},
         windSpeed: {label: 'Wind Speed'},
         location: {label: 'Location'},
         temperature: {label: 'Temperature'},
         sessionDate: {label: 'Date', type: 'dateRange', converter: (date) => date.getTime(), sortable: (key) => `(doc["${key}"].value).toEpochMilli()`},
         videoIds: {label: '# of Videos', type: 'arrayRange', range: [0, 1000], default: [0, 5], sortable: (key) => `doc["${key}"].size()*100`, adminLock: true},
         parentUserId: { label: "Parent User Id", adminLock: true },
     },
     videos: {
         videoPath: {label: 'File', type: 'video'},
         videoOrigName: {label: 'Name'},
         videoType: {label: 'Type', adminLock: true},
         videoSource: {label: 'Source', adminLock: true},
         videoLength: {label: 'Length', type: 'range', converter: (val) => val*1000, adminLock: true},
         videoSize: {label: 'Size', type: 'range', converter: (val) => val*1000000, adminLock: true},
         videoCreated: {label: 'Date', type: 'dateRange', converter: (date) => date.getTime()},
         analyzed: {label: 'Analyzed', type: 'bool', adminLock: true},
         internalLabel: {label: 'Internal Label', adminLock: true},
         ssl2DTag: {label: '2D SSL Tag', type: 'select', options: { '2DSSL_Requested': '2DSSL Requested', '2DSSL_Requested_DTL': '2DSSL Requested DTL', '2DSSL_Invalid': '2DSSL Invalid', '2DSSL_Labeled': '2DSSL Labeled', '2DSSL_Trained': '2DSSL Trained', '2DSSL_Improve': '2DSSL Improve', '2DSSL_Improve_DTL': '2DSSL Improve DTL', '2DSSL_Good': '2DSSL Good', '2DSSL_Testing': '2DSSL Testing' }, adminLock: true},
         "userData.hand": {label: 'Dominant Hand', type: 'select', options: { 'left': 'Left', 'right': 'Right' }},
         "userData.height": {label: 'Height'},
         "userData.age": {label: 'Age', type: 'range'},
         "userData.hipMeasurement": {label: 'Hip Width', type: 'range'},
         "metaData.fps": {label: "FPS", type: "range", adminLock: true},
         "metaData.model": { label: "Phone Model", adminLock: true},
         "metaData.resolutions": { label: "Resolution", adminLock: true},
         "metaData.cameraAngle": { label: "Camera Angle", type: 'select', options: { 'FaceOn': 'Face On', 'DownTheLine': 'DTL' }, nullable:["FaceOn"], adminLock: true}, // Add support for if cameraAngle is not present or is null, show it as FaceOn
         "userData.golfClub": {label: 'Golf Club Used'},
         intrinsicVerbose: {label: "Intrinsic", adminLock: true},
         "metaData.isLowLightModeEnabled": { label: "Low Light Enabled", type: 'bool', adminLock: true},
         parentUserId: { label: "Parent User Id", adminLock: true },
     },
     analysis: {
         //isAnalysisSucessful: {label: 'Analyzed', type: 'bool'},
         createdDate: {label: 'Date', type: 'dateRange', converter: (date) => date.getTime(), sortable: (key) => `(doc["${key}"].value).toEpochMilli()`},
         swingConfidenceScore: {label: 'Confidence Score', type: 'range', converter: (val) => val/100, sortable: (key) => `(doc["${key}"].value*100L)`, adminLock: true},
         swingScore: {label: 'Swing Score', type: 'range', converter: (val) => val/100, sortable: (key) => `doc["${key}"].value`, adminLock: true},
     },
     reportIssues: {
         videoURL: {label: 'File', type: 'video', adminLock: true},
         urgent: {label: 'Urgent', type: 'bool', adminLock: true},
         reviewed: {label: 'Reviewed', type: 'bool', adminLock: true},
         comment: {label: 'Comment', maxLength: 120, adminLock: true},
         buildVersion: {label: 'Build Version', adminLock: true},
         createdDate: {label: 'Date', type: 'dateRange', converter: (date) => date.getTime(), sortable: (key) => `(doc["${key}"].value).toEpochMilli()`, adminLock: true},
         pose2D: {label: 'Pose 2D', type: 'link', adminLock: true},
         pose3D: {label: 'Pose 3D', type: 'link', adminLock: true},
         actionData: {label: 'Action Data', type: 'link', adminLock: true},
         userId: {label: 'User Id', keyword: true, adminLock: true},
         sessionId: {label: 'Session Id', keyword: true, adminLock: true},
         videoId: {label: 'Video Id', adminLock: true},
     },
     subscriptions: {
        userEmail: { label: 'User Email', adminLock: true, hidden: "search" },
        userName: { label: 'User Name', adminLock: true, hidden: "search" },
        transactionId: {label: 'VIP', adminLock: true},
        startDate: {label: 'Start Date', type: 'dateRange', converter: (date) => date.getTime(), sortable: (key) => `(doc["${key}"].value).toEpochMilli()`, adminLock: true},
        lastUpdated: {label: 'Last Updated Date', type: 'dateRange', converter: (date) => date.getTime(), sortable: (key) => `(doc["${key}"].value).toEpochMilli()`, adminLock: true},
        endDate: {label: 'End Date', type: 'dateRange', converter: (date) => date.getTime(), sortable: (key) => `(doc["${key}"].value).toEpochMilli()`, adminLock: true},
        productId: {label: 'Plan', adminLock: true},
        bootcampUser: {label: 'Boot Camp User', type: 'bool', adminLock: true},
        autoRenewal: {label: 'Auto Renewal', type: 'bool', adminLock: true},
        platform: {label: 'Platform', adminLock: true},
        promoCode: {label: 'Promo Code', adminLock: true},
        amount: {label: 'Amount', type: 'arrayRange', adminLock: true},
        userId: { label: 'User Id', adminLock: true, hidden: "search" },
        freeTrialUsed: {label: "Free Trial Used",  type: 'bool', adminLock: true},
        subLabel: { label: 'Subscription Label', type: 'select', options: { 
            'test': 'Test', 'demo': 'Demo', 'partner': 'Partner', 'revenue_generating': 'Revenue Generating', 'staff': 'Staff', 'free_trial': 'Free Trial', 
            'bootcamp': 'Bootcamp', 'invitational': 'Invitational Page', 'offer': 'Offer Page', 'golfpad': 'Golf Pad', 
            'dollarDriverClub': 'Dollar Driver Club', 'golfpass_sb3d': 'Golf Pass - SB3D', 'golfpass_gpod': 'Golf Pass - GPOD',
            'markCrossfield': 'Mark Crossfield' }, adminLock: true },
     },
     invites: {
         code: { label: 'Code', type: 'bool', adminLock: true },
         createdAt: { label: 'Date', type: 'dateRange', converter: (date) => date.getTime(), sortable: (key) => `(doc["${key}"].value).toEpochMilli()`, adminLock: true },
         enterpriseAccountId: { label: 'Enterprise Account Id', adminLock: true },
         expiredIn: { label: 'Expired Date', type: 'dateRange', converter: (date) => date.getTime(), sortable: (key) => `(doc["${key}"].value).toEpochMilli()`, adminLock: true },
         invitee: { label: 'Invitee', adminLock: true },
         inviteeName: { label: 'Invitee Name', adminLock: true },
         lastUpdated: { label: 'Last Updated Date', type: 'dateRange', converter: (date) => date.getTime(), sortable: (key) => `(doc["${key}"].value).toEpochMilli()`, adminLock: true },
         role: { label: 'Role', adminLock: true },
         senderId: { label: 'Sender Id', adminLock: true },
         senderName: { label: 'Sender Name', adminLock: true },
         status: { label: 'Status', adminLock: true },
         type: { label: 'Type', adminLock: true },
     },
     payments: {
        platform: { label: 'Platform', adminLock: true },
        amount: {label: 'Amount', type: 'arrayRange', adminLock: true},
        paymentType: {label: 'Payment Type', adminLock: true, type: 'select', options: {'new': "New", "renewal": "Renewal"}},
        //currency: {label: 'Currency', adminLock: true, type: 'select', options: {'usd': 'USD'}},
        paymentDate: {label: 'Payment Date', type: 'dateRange', converter: (date) => date.getTime(), sortable: (key) => `(doc["${key}"].value).toEpochMilli()`, adminLock: true},
     },
     partnerSubscriptions: {
        userEmail: { label: 'User Email', adminLock: true },
        userName: { label: 'User Name', adminLock: true },
        transactionId: {label: 'VIP', type: 'vip', adminLock: true},
        startDate: {label: 'Start Date', type: 'dateRange', converter: (date) => date.getTime(), sortable: (key) => `(doc["${key}"].value).toEpochMilli()`, adminLock: true},
        lastUpdated: {label: 'Last Updated Date', type: 'dateRange', converter: (date) => date.getTime(), sortable: (key) => `(doc["${key}"].value).toEpochMilli()`, adminLock: true},
        endDate: {label: 'End Date', type: 'dateRange', converter: (date) => date.getTime(), sortable: (key) => `(doc["${key}"].value).toEpochMilli()`, adminLock: true},
        productId: {label: 'Plan', adminLock: true, type: 'select', options: { [listOfSubscriptions.FORESIGHT_MONTHLY]: "Foresight Monthly", [listOfSubscriptions.FORESIGHT_ANNUALLY]: "Foresight Annually" }},
        autoRenewal: {label: 'Auto Renewal', type: 'bool', adminLock: true},
        promoCode: {label: 'Promo Code', adminLock: true},
        amount: {label: 'Amount', type: 'arrayRange', adminLock: true},
        userId: { label: 'User Id', adminLock: true, hidden: "search" },
        freeTrialUsed: {label: "Free Trial Used",  type: 'bool', adminLock: true},
        subLabel: { label: 'Subscription Label', type: 'select', options: { 
            'test': 'Test', 'demo': 'Demo', 'partner': 'Partner', 'revenue_generating': 'Revenue Generating', 'free_trial': 'Free Trial', }, adminLock: true },
        isAddOn: { label: 'Add On', type: 'bool', adminLock: true },
     },
 }
 
 // Default sort for inner_hits, false means no sort will be applied
const innerHitSort = {
    users: false,
    sessions: false,
    videos: false,
    analysis: ["createdDate", "desc"],
    subscriptions: false,
    payments: false,
    invites: false,
    partnerSubscriptions: false,
}
const adminSizeOptions = [5, 20, 50, 100, 500, 1000];
class AdvancedSearch extends Component {
    constructor(props) {
        super(props)
        this.state = {
            target: 'videos',
            results: {
                hits: [],
                total: 0
            },
            sort: {
                users: { key: 'userCreated', dir: 'desc', type: 'users' },
                sessions: { key: 'sessionDate', dir: 'desc', type: 'sessions' },
                videos: { key: 'videoCreated', dir: 'desc', type: 'videos' },
                reportIssues: { key: 'createdDate', dir: 'desc', type: 'reportIssues' },
                subscriptions: { key: 'startDate', dir: 'desc', type: 'subscriptions' },
                payments: { key: 'paymentDate', dir: 'desc', type: 'payments' },
                invites: { key: 'createdAt', dir: 'desc', type: 'invites' },
                partnerSubscriptions: { key: 'startDate', dir: 'desc', type: 'partnerSubscriptions' },
                pagination: { page: 1, size: 20, indices: {}, sizeOptions: this.props.userData.role === 'admin' ? adminSizeOptions : [5, 20, 50, 100] },
                changed: false
            },
            currSearch: {},
            currQueryState: getDefaultState(),
            searchWidth: 0,
            loading: true,
            anchor: "left",
            searchOpen: false,
            showReports: ["admin", "data-admin"].includes(props.userData.role),
            adminOpen: false
        }
    }
    componentDidMount() {
        this.handleSubmit(true)
    }

    refreshPitwIndex = () => {
        return this.props.refreshPit(this.state.target === 'reportIssues' ? reportIndex : null)
    }

    handleDataChange = () => {
        if (this.state.sort.pagination.page === 1) {
            //If currently at the first page, handleSubmit will update the pit on its own so skip the refresh
            this.setState({ sort: { ...this.state.sort, changed: true } }, () => {
                this.handleSubmit(true)
            })
        } else {
            this.refreshPitwIndex().then(res => {
                if (res) {
                    this.setState({ sort: { ...this.state.sort, changed: true } }, () => {
                        this.handleSubmit(true)
                    })
                } else {
                    console.log("Failed to refresh pit")
                    this.setState({ loading: false })
                }
            })
        }
    }

    checkKeyword = (key, mappingsInput) => {
        if (key.includes('.keyword')) {
            return key
        }
        let mappings = mappingsInput || this.props.mappings
        const keyProp = key.replace('.', '.properties.')
        if (_.get(mappings, `${keyProp}`) && _.get(mappings, `${keyProp}.type`) === 'text') {
            return `${key}.keyword`
        }
        return key
    }
    isKeyword = (key, type) => {
        const val = (key.includes('.keyword') || 
            (this.props.mappings[key] && this.props.mappings[key].type === 'keyword') ||
            (FieldMaps[type][key] && FieldMaps[type][key].keyword)
        )
        return val
    }
    handleTargetChange = (event, option) => {
        if (option) {
            this.setState({ target: option, sort: { ...this.state.sort, changed: true } }, () => {
                this.handleSubmit(true)
            })
        }
    }

    //Checks if boolean property of a query is empty or not
    isEmpty = (obj) => {
        let notEmpty = false
        Object.keys(obj).forEach(key => { notEmpty = notEmpty || obj[key].length > 0 })
        return !notEmpty
    }
    //Recursively gets queries for parent properties
    getParentQuery = (type, fields, toggles, sort) => {
        if (parentChildMap[type].parent) {
            const parentType = parentChildMap[type].parent
            let has_parent = {
                parent_type: parentType,
                query: { bool: { filter: [], must: [], must_not: [], should: [] } },
                score: true
            }
            has_parent.query.bool = this.getFields(parentType, fields, toggles)
            //Recurse one more step and check if there is another parent
            const nextParent = this.getParentQuery(parentType, fields, toggles, sort)
            if (nextParent) {
                has_parent.query.bool.must.push({ has_parent: nextParent })
                has_parent.query.bool.should.push({
                    has_parent: {
                        parent_type: parentType, query: { match_all: {} },
                        inner_hits: { _source: Object.keys(FieldMaps[parentType]) }
                    }
                })
            }

            //If the filter is a property if a parent object, include additonal query data for sorting
            if (sort.type === parentType && FieldMaps[sort.type][sort.key]?.sortable) {
                const sortable = FieldMaps[sort.type][sort.key]?.sortable
                //Include parent score in the overall score of the target document
                has_parent.query.bool.must.push({
                    function_score: {
                        script_score: {
                            script: `doc['${this.checkKeyword(sort.key)}'].size() != 0 ? ${sortable(this.checkKeyword(sort.key))} : 0`
                        }
                    }
                })
            }
            if (!this.isEmpty(has_parent.query.bool)) {
                return has_parent
            }
        }
        return null
    }
    //Recursively gets query tags for child fields 
    getChildQuery = (type, fields, toggles, sort) => {
        let output = []
        if (parentChildMap[type].children.length > 0) {
            parentChildMap[type].children.forEach(child => {
                let has_child = {
                    type: child,
                    query: { bool: { filter: [], must: [], must_not: [], should: [] } },
                }
                has_child.query.bool = this.getFields(child, fields, toggles, has_child.query.bool)

                //Recurse again and check if there is another child
                const nextChild = this.getChildQuery(child, fields, toggles, sort)
                if (nextChild) {
                    has_child.query.bool.must = has_child.query.bool.must.concat(
                        nextChild.map(item => { return { has_child: item } }))
                }

                //If the filter is a property if a child object, include additonal query data for sorting
                if (sort.type === child && FieldMaps[sort.type][sort.key]?.sortable) {
                    const sortable = FieldMaps[sort.type][sort.key]?.sortable
                    //Include child score in the overall score of the target document
                    has_child.query.bool.must.push({
                        function_score: {
                            script_score: {
                                script: `doc['${this.checkKeyword(sort.key)}'].size() != 0 ? ${sortable(this.checkKeyword(sort.key))} : 0`
                            }
                        }
                    })
                    has_child.score_mode = 'max'
                }
                if (!this.isEmpty(has_child.query.bool)) {
                    output.push(has_child)
                }
            })
        }
        return output
    }
    //Sets query tags in order to get inner_hits for children. Does not check for fields.
    getChildInner = (type, fields, toggles, sort) => {
        let output = []
        if (parentChildMap[type].children.length > 0) {
            parentChildMap[type].children.forEach(child => {
                const res = {
                    has_child: {
                        type: child,
                        query: { bool: { 
                            filter: [{ match_all: {}}], 
                            must: [], 
                            must_not: [], 
                            should: this.getChildInner(child, fields, toggles, sort) } },
                        inner_hits: {},
                    }
                }
                //Check if bool only has empty arrays
                this.getFields(child, fields, toggles, res.has_child.query.bool)
                //Sort inner_hits by either the default sort or the sort specified by the user
                if (sort && sort.type === child) {
                    res.has_child.inner_hits.sort = [{ [sort.key]: { order: sort.dir } }, { "_shard_doc": "desc" }]
                } else if (innerHitSort[child]) {
                    res.has_child.inner_hits.sort = [{ [innerHitSort[child][0]]: { order: innerHitSort[child][1] } }, { "_shard_doc": "desc" }]
                }
                output.push(res)
            })
        }
        return output
    }

    isDecendant(type, target) {
        if (type === target) {
            return true
        } else if (parentChildMap[type]?.parent) {
            return this.isDecendant(parentChildMap[type].parent, target)
        }
        return false
    }

    //Turns fields into query tags
    getFields(key, fields, toggles, results) {
        const uid = this.props.userData.uid
        if (!results) {
            results = { filter: [], must: [], must_not: [], should: [] }
        }

        // For each of the fields in each group
        if (toggles[key]) {
            Object.keys(fields[key]).forEach(field => {
                const fieldMap = FieldMaps[key][field]
                if (fields[key][field]) {
                    if (fieldMap.special) {
                        if (field === 'swingAnalysisOffer' && fields[key][field]) {
                            results.must.push({
                                exists: {
                                    field: field
                                }
                            })
                        } else if (field === 'removeTestAccounts' && fields[key][field]) {
                            const must_not_terms = ['sb3d*','*sb3d*', '3dsb*', '*3dsb*', '*sportsbox*', '*demo*', 'sportsbox*']
                            const mustNot = must_not_terms.map(term => 
                                ({ wildcard: { email: term } }))
                            const terms = { terms: { 'email.keyword': emailExceptionList}}
                            if (fields[key][field] === 1) {
                                results.must_not = results.must_not.concat(mustNot)
                                results.must_not = results.must_not.concat(terms)
                            } else if (fields[key][field] === 2) {
                                results.must = results.must.concat({ bool: {should: mustNot.concat([terms])}})
                            }
                        } else if ((field === 'coachEmail' || field === 'coachName') && fields[key][field].value ) {
                            let value = field === "coachEmail" ? {email: fields[key][field].value} : {fullName: fields[key][field].value};
                            //Search for users with the coach name/email
                            return axiosWithToken(functionBaseUrl+'/api/elastic/search', {
                                method: 'get',
                                params: { body: {
                                    query: {
                                        bool: {
                                            filter: [
                                                {match: {docType: "users"}},
                                                {match_phrase_prefix: value}
                                            ]
                                        }
                                    },
                                    index: elasticIndex,
                                    _source: false,
                                    size: 50
                                }}
                            }).then(response => {
                                const coachIds = response.data.hits.hits.map(hit => hit._id)
                                //Retrieve coach Ids and apply to search query
                                results.must = results.must.concat({terms: {'coaches.keyword': coachIds}})
                            }).catch(err => { console.log("An error occured while attempting to parse coach data")})
                        }
                    }
                    else if ((!fieldMap.type || fieldMap.type === 'select') && fields[key][field].value) {
                        //Checks if the field is a keyword type. If so use match instead of match_phrase_prefix
                        if (this.isKeyword(field, key)) {
                            results[fields[key][field].negated ? 'must_not' : 'filter'].push({
                                match: { [field]: fields[key][field].value }
                            })
                        } else {
                            if (fieldMap.nullable && fieldMap.nullable.includes(fields[key][field].value)) {
                                // If parameter is set as nullable, allow missing and empty string results to also appear in a search
                                results.filter.push({
                                    bool: {
                                        should: [
                                            {match_phrase_prefix: {
                                                [field]: fields[key][field].value
                                            }},
                                            {
                                                bool: {
                                                  must_not:
                                                  {
                                                    exists: {field: "metaData.cameraAngle"} 
                                                  }
                                                }
                                            },
                                            {
                                              bool: {
                                                must: {
                                                  exists: {field:"metaData.cameraAngle"}
                                                },
                                                must_not:
                                                {
                                                  wildcard: {"metaData.cameraAngle": "*"} 
                                                }
                                              }
                                            }
                                        ],
                                        minimum_should_match: 1
                                    }
                                })
                            } else {
                                //For general fields
                                results[fields[key][field].negated ? 'must_not' : 'filter'].push({
                                    match_phrase_prefix: {
                                        [field]: fields[key][field].value
                                    }
                                })
                            }
                        }
                    } else if (['range', 'dateRange'].includes(fieldMap.type) && fields[key][field].enable) {
                        let converter = FieldMaps[key][field].converter ? FieldMaps[key][field].converter : (val) => val
                        let params = {}
                        //If using between or after
                        if (fieldMap.type !== 'dateRange' || ['between', 'after'].includes(fields[key][field].type)) {
                            params['gte'] = converter(fields[key][field].value[0])
                        }
                        //If using between or before
                        if (fieldMap.type !== 'dateRange' || ['between', 'before'].includes(fields[key][field].type)) {
                            params['lte'] = converter(fields[key][field].value[1])
                        }
                        results[fields[key][field].negated ? 'must_not' : 'filter'].push({
                            range: {
                                [field]: params
                            }
                        })
                    } else if (fieldMap.type === 'arrayRange' && fields[key][field].enable) {
                        results[fields[key][field].negated ? 'must_not' : 'filter'].push({
                            script: {
                                script: `doc['${this.checkKeyword(field)}'].size() <= ${fields[key][field].value[1]} && doc['${this.checkKeyword(field)}'].size() >= ${fields[key][field].value[0]}`
                            }
                        })
                    } else if (fieldMap.type === 'bool' && fields[key][field] !== 0) {
                        // Possible bool values: 0 - do not use, 1 - look for true, 2 look for false
                        results[fields[key][field] === 1 ? 'filter' : 'must_not'].push({
                            match: {
                                [field]: true
                            }
                        })
                    } else if (fieldMap.type === 'exists'  && fields[key][field] !== 0) {
                        results[fields[key][field] === 1 ? 'filter' : 'must_not'].push({
                            exists: {
                                field: field
                            }
                        })
                    }
                }
            })
        }
        //If the current type is users and the user is not admin, limit data according to users data
        if (key === 'users' && this.props.userData.role !== 'admin') {
            //If User is an enterprise user limit to their enterprise
            if (this.props.userData?.subscriptionType?.includes('enterprise')) {
                results.filter.push({
                    "term": { "enterpriseAccountId": this.props.userData.enterpriseAccountId }
                })
            }
            //If user is a student, limit to only their items
            else if (!this.props.subData || JSON.stringify(this.props.subData) === "{}" || (Object.keys(this.props.subData).length > 0 && this.props?.subData?.productId && this.props.subData.productId.includes('students'))) {
                //If the target is users, also show the coach's user data. Does not show the coaches sessions/videos
                if (this.state.target === "users") {
                    results.filter.push({
                        bool: {
                            should: [
                                { "match": { "students": uid } },
                                { "term": { "_id": uid } }
                            ]
                        }
                    })
                } else if (!this.isDecendant(this.state.target, 'sessions')){
                    results.filter.push({ "term": { "_id": uid } })
                }
            }
            //If user is a coach
            else if (JSON.stringify(this.props.userData?.subData) !== '{}' && !this.props.userData?.subscriptionType?.includes('student')) {
                results.filter.push({
                    bool: {
                        should: [
                            { "match": { "coaches": uid } },
                            { "term": { "_id": uid } }
                        ]
                    }
                })
            }
        } else if (key === 'sessions' && this.props.userData.role !== 'admin' && this.isDecendant(this.state.target, 'sessions')) {
            results.filter.push({
                bool: {
                    should: [
                        { "term": { "parentUserId": uid } },
                        { "term": { "sessionUserId": uid } }
                    ]
                }
            })
        }
        return results
    }

    //Turns fields into query tags for reportIssues index in elastic
    getReportFields = (key, fields, toggles, results) => {
        if (!results) {
            results = { filter: [], must: [], must_not: [], should: [] }
        }
        // For each of the fields in each group
        if (toggles[key]) {
            Object.keys(fields[key]).forEach(field => {
                const fieldMap = FieldMaps[key][field]
                const prefix = key === 'reportIssues' ? '' : key + "."
                if (fields[key][field]) {
                    if (!fieldMap.type || fieldMap.type === 'select') {
                        //Checks if the field is a keyword type. If so use match instead of match_phrase_prefix
                        if (this.isKeyword(field, key) && fields[key][field].value) {
                            results[fields[key][field].negated ? 'must_not' : 'filter'].push({
                                match: {
                                    [prefix + field]: fields[key][field].value
                                }
                            })
                        } else if (fields[key][field].value){
                            results[fields[key][field].negated ? 'must_not' : 'filter'].push({
                                match_phrase_prefix: {
                                    [prefix + field]: fields[key][field].value
                                }
                            })
                        }
                    } else if (['range', 'dateRange'].includes(fieldMap.type) && fields[key][field].enable) {
                        let converter = FieldMaps[key][field].converter ? FieldMaps[key][field].converter : (val) => val
                        results[fields[key][field].negated ? 'must_not' : 'filter'].push({
                            range: {
                                [prefix + field]: {
                                    gte: converter(fields[key][field].value[0]),
                                    lte: converter(fields[key][field].value[1])
                                }
                            }
                        })
                    } else if (fieldMap.type === 'bool' && fields[key][field] !== 0) {
                        // Possible bool values: 0 - do not use, 1 - look for true, 2 look for false
                        results[fields[key][field] === 1 ? 'filter' : 'must_not'].push({
                            match: {
                                [prefix + field]: true
                            }
                        })
                    } else if (fieldMap.type === 'exists'  && fields[key][field] !== 0) {
                        results[fields[key][field] === 1 ? 'filter' : 'must_not'].push({
                            exists: {
                                field: field
                            }
                        })
                    }
                }
            })
        }
        return results
    }

    //Creates config object for reportIssues query
    createReportConfig = (config, fields, toggles, sort) => {
        for (let key in fields) {
            config.bool = this.getReportFields(key, fields, toggles, config.bool)
        }
        config.target = 'reportIssues'
        return config
    }

    //Creates config object for query
    createDefaultConfig = (config, target, fields, toggles, sort, onlyInner) => {
        //Limit results to the target type and add any fields it has to query
        config.bool = this.getFields(target, fields, toggles)
        config.bool.filter.push({ match: { docType: target } })
        config.target = target
        config.onlyInner = onlyInner
        //Recursively get any fields for parent types
        const has_parent = this.getParentQuery(target, fields, toggles, sort[target])
        if (has_parent) {
            config.bool.must.push({ has_parent: has_parent })
        }

        //Include inner hits for parent types. Used for getting limted data on parents
        const parent = parentChildMap[target].parent
        if (parent) {
            const should = {
                has_parent: {
                    parent_type: parent, query: { match_all: {} },
                    inner_hits: { size: 1 }
                }
            }
            if (target === "videos") {
                should.has_parent.query = {
                    has_parent: {
                        parent_type: "users", query: { match_all: {} },
                        inner_hits: { size: 1 }
                    }
                }
            }
            config.bool.should.push(should)
        }

        //Recursively get any fields for child types
        const has_child = this.getChildQuery(target, fields, toggles, this.state.sort[target])
        config.bool.must = config.bool.must.concat(has_child.map(item => { return { has_child: item } }))
        config.bool.should = config.bool.should.concat(this.getChildInner(target, fields, toggles, this.state.sort[target]))
        return config
    }
    createConfig = async (checkChanged = true, queryStateNew, target) => {
        //If the submit is from QueryFields then the queryState is different, otherwise use old state
        const queryState = queryStateNew ? queryStateNew : this.state.currQueryState
        target = target ? target : this.state.target
        const { fields, toggles } = queryState
        const changed = queryState.changed || this.state.sort.changed
        let sort = this.state.sort
        // If the query has changed since last submit and the query needs to be updated
        let refreshPromise = null
        if (changed && checkChanged) {
            sort.pagination.indices = {}
            sort.pagination.page = 1
        }
        if (sort.pagination.page === 1) {
            refreshPromise = this.refreshPitwIndex()
        }
        let config = {
            bool: { filter: [], must: [], must_not: [], should: [] },
            size: sort.pagination.size
        }
        if (target === 'reportIssues' && checkChanged) {
            config = this.createReportConfig(config, fields, toggles, sort)
        } else if (checkChanged) {
            config = this.createDefaultConfig(config, target, fields, toggles, sort, target !== 'invites' && target !== 'subscriptions')
        } else {
            //Use old query and ignore any changes to query fields
            config = this.state.currSearch
        }

        //Add sort parameters to query
        const targetSort = sort[target]
        if (targetSort.type === target) {
            if (FieldMaps[targetSort.type][targetSort.key].type === 'arrayRange') {
                config.query = {
                    script_score: {
                        query: {
                            bool: config.bool
                        },
                        script: {
                            source: FieldMaps[targetSort.type][targetSort.key].sortable(this.checkKeyword(targetSort.key)),
                        }
                    }
                }
                config.sort = [{ "_score": targetSort.dir }]
            } else {
                config.bool.must.push({ exists: { field: targetSort.key } })
                config.sort = [{ [this.checkKeyword(targetSort.key)]: targetSort.dir }]
            }
        } else if (target === 'reportIssues') {
            const sortKey = targetSort.type + '.' + targetSort.key
            config.bool.must.push({ exists: { field: sortKey } })
            config.sort = [{ [this.checkKeyword(sortKey, this.props.reportMappings)]: targetSort.dir }]
        } else {
            config.sort = [{ _score: targetSort.dir }]
        }

        //Include tiebreaker based on shard document id
        config.sort.push({ "_shard_doc": "desc" })

        //Add pagination parameters to query
        if (sort.pagination.page > 1) {
            config.search_after = sort.pagination.indices[sort.pagination.page - 1]
        } else {
            delete config.search_after
        }
        if (refreshPromise) {
            await refreshPromise
        }
        return {config: config, sort: sort, target: target, queryState: queryState, targetSort: targetSort}
    }

    handleSubmit = async (checkChanged = true, queryStateNew, target) => {
        this.setState({ loading: true })
        const confRes = await this.createConfig(checkChanged, queryStateNew, target)
        const config = confRes.config
        const sort = confRes.sort
        const queryState = confRes.queryState
        const targetSort = confRes.targetSort
        target = confRes.target
        this.props.queryElasticwPit(config).then(res => {
            sort.pagination.indices[sort.pagination.page] = res.sort
            res.time = (new Date()).getTime()
            res.total = res.total || 0
            this.setState({
                results: this.parseResults(res, target, targetSort),
                sort: sort,
                currSearch: config,
                currQueryState: queryStateNew ? _.cloneDeep(queryState) : this.state.currQueryState,
                loading: false
            })
        }).catch(err => {
            //If pit is expired, switch to new pit and try again
            if (err.response && err.response.data.pit) {
                this.props.queryElasticwPit(config, err.response.data.pit).then(res => {
                    sort.pagination.indices[sort.pagination.page] = res.sort
                    this.setState({ results: this.parseResults(res, target, targetSort), sort: sort, currSearch: config, currQueryState: queryStateNew ? _.cloneDeep(queryState) : this.state.currQueryState, loading: false })
                }).catch(err => { console.log(err); this.setState({ loading: false }) })
            } else {
                this.setState({ loading: false })
                console.log(err.response?.data || err)
            }
        })
    }
    
    //Exports all results into a csv file, returns the data but does not handle downloading
    exportAll = async (headers, headerLabels) => {
        Swal.fire({
            title: 'Exporting Data',
            html: '<div><img width="10%" src="images/loading.gif" alt="Loading" /></div>',
            allowOutsideClick: false,
            allowEscapeKey: false,
            showConfirmButton: false,
            customClass: {
                container: 'my-swal' 
            },
        })
        const resConf = await this.createConfig(true)
        const config = resConf.config
        const sort = resConf.sort
        const targetSort = resConf.targetSort
        const target = resConf.target
        // let headers = headCells[target]
        // let headers = ['videos:videoOrigName', 'videos:userData.fullName', 'sessions:sessionName', 'videos:metaData.model', 'videos:metaData.fps', 'videos:userData.hipMeasurement', 'videos:userData.hipMeasurementMM', 'videos:_id', 'videos:videoPath']
        // headers = headers.map(v=>v.replace(":", "."))
        const numPerBatch = 1000
        let count = numPerBatch
        config.size = numPerBatch
        const output = [headerLabels]
        while (count === numPerBatch) {
            await this.props.queryElasticwPit(config).then(res => {
                res.time = (new Date()).getTime()
                res.total = res.total || 0
                const parsedRes = this.parseResults(res, target, targetSort)
                config.search_after = res.sort
                
                let headerPre = ""
                parsedRes.hits.forEach(data => {
                    let row = []
                    headers.forEach(header => {
                        if (header.includes('hipMeasurementMM')) {
                            row.push(Math.round(_.get(data, (headerPre ? (headerPre+".") : "" )+"videos.userData.hipMeasurement") * 25.4))
                        } else {
                            let value = _.get(data, (headerPre ? (headerPre+".") : "" )+header);
                            if (typeof value === "number" && String(value).length === 13) {
                                value = moment(String(value), "x").format("MM/DD/YYYY");
                            } else if (value === undefined) {
                                value = ""
                            }
                            if (header.includes(".sessionIds") || header.includes(".students") || header.includes(".coaches")) {
                                row.push('"' + value?.length + '"');
                            } else {
                                row.push('"' + value?.toString().replaceAll('"', '″') + '"');
                            }
                        }
                    })        
                    output.push(row.join(","))
                })
                count = res.hits.length
            }).catch(err => {
                console.log(err.response?.data || err)
                count = 0
            })
        }
        const outputStr = output.join("\n")
        const blob = new Blob([outputStr])
        Swal.close();
        return blob
    }

    parseResults = (res, target, targetSort = null) => {
        const newHits = []
        if (target == 'reportIssues') {
            //If the query is a report, associated objects are stored inside the reportIssues document
            res.hits.forEach(hit => {
                const obj = { reportIssues: { _id: hit._id } }
                Object.entries(hit._source).forEach(([key, value]) => {
                    if (Object.keys(FieldMaps).includes(key)) {
                        if (targetSort && Array.isArray(value) && key === targetSort.type) {
                            const dir = targetSort.dir === 'asc' ? 1 : -1
                            value.sort((a, b) => (a[targetSort.key] > b[targetSort.key] ? dir : -dir))
                        }
                        obj[key] = value
                    } else {
                        obj.reportIssues[key] = value
                    }
                })
                newHits.push(obj)
            })
        } else {
            //Otherwise, organize inner_hits and original document into a single object
            res.hits.forEach(hit => {
                let obj = {};
                if (hit._source) {
                    if (target == 'invites') {
                        obj = { invites: { ...hit._source, _id: hit._id } };
                    }
                    if (target == 'subscriptions') {
                        obj = { subscriptions: { ...hit._source, _id: hit._id } };
                    }
                } else {
                    //For all inner_hits, add them to the simplified object
                    Object.entries(hit.inner_hits).forEach(([key, value]) => {
                        obj[key] = value.hits?.[0]?._source
                        if (obj[key])
                            obj[key]._id = value.hits?.[0]?._id
                    });
                }
                newHits.push(obj)
            })
        }
        res.hits = newHits
        return res
    }

    handleSortChange = (sort) => {
        this.setState({ sort: { ...sort, changed: true }, loading: true }, () => {
            this.handleSubmit(true)
        })
    }

    changePage = (event, page) => {
        if (!this.state.loading) {
            let sort = this.state.sort
            sort.pagination.page = page + 1
            this.setState({ sort: sort }, () => {
                this.handleSubmit(false)
            })
        }
    }

    //Update Item
    updateItem = async (id, obj) => {
        //Remove fields that should not be in firestore (e.g. doc_relations, docType)
        blackListKeys.forEach(key => { delete obj[key] })
        //For current stored results, update them with the new values
        // this.state.results.hits.forEach(hit => {
        //     if (hit?.[this.state.target]?._id === id) {
        //         hit[this.state.target] = { ...hit[this.state.target], ...obj }
        //     }
        // })
        this.setState({ loading: true })
        await axiosWithToken(functionBaseUrl + `/api/elastic/doc/${this.state.target}/${id}`, {
            method: 'patch',
            data: {
                data: obj,
                updateFirebase: true
            }
        }).then(response => {
            setTimeout(this.handleDataChange, 2500)
        })
        .catch(err => {
            this.setState({ loading: false })
            console.log(err)
        });
    }
    updateMultipleItems = async (objs) => {
        const ids = Object.keys(objs)
        const targetObjs = ids.map((id) => {
            let newObj = objs[id]
            blackListKeys.forEach(key => { delete newObj[key] })
            return newObj
        })
        this.setState({ loading: true })
        let promises = []
        targetObjs.forEach((obj, i) => {
            promises.push(axiosWithToken(functionBaseUrl + `/api/elastic/doc/${this.state.target}/${ids[i]}`, {
                method: 'patch',
                data: {
                    data: obj,
                    updateFirebase: true
                }
            }))
        })
        await Promise.all(promises).then(response => {
            setTimeout(this.handleDataChange, 2500)
        }).catch(err => {
            this.setState({ loading: false })
            console.log(err)
        });
    }

    deleteItem = async (id, type) => {
        this.setState({ loading: true })
        const apiPath = {
            users: (id) => `users/${id}`,
            sessions: (id) => `sessions/${id}`,
            videos: (id) => `deleteVideo/${id}`,
        }
        await axiosWithToken(functionBaseUrl + '/api/' + apiPath[type](id), {
            method: 'delete'
        }).then(response => {
            //Wait 2 seconds before refreshing the page
            setTimeout(() => this.handleDataChange(), 2000)
        })
            .catch(err => {
                this.setState({ loading: false })
                console.log(err)
            });
    }
    render() {
        const classes = this.props.classes;
        return (
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <MuiThemeProvider theme={theme}>
                    {this.props.userData.role === 'admin' ? <AdminActions
                        open={this.state.adminOpen}
                        onClose={() => this.setState({ adminOpen: false })}
                    /> : null}
                    <Box sx={{ flexGrow: 1 }}>
                        <Grid container spacing={1}>
                            {isMobile ? (<Grid item xs={12}>
                                <QueryFields
                                    handleSubmit={this.handleSubmit}
                                    handleTargetChange={this.handleTargetChange}
                                    reportMappings={this.props.reportMappings}
                                    target={this.state.target}
                                    classes={classes}
                                    isMobile={isMobile}
                                    isAdmin={this.props.userData.role === 'admin'}
                                />
                            </Grid>) :
                                <Drawer
                                    anchor={this.state.anchor}
                                    open={this.state.searchOpen}
                                    onClose={() => this.setState({ searchOpen: false })}
                                    BackdropProps={{ style: { opacity: "0%" } }}
                                    ModalProps={{
                                        keepMounted: true,
                                    }}>
                                    <QueryFields
                                        handleSubmit={this.handleSubmit}
                                        handleTargetChange={this.handleTargetChange}
                                        reportMappings={this.props.reportMappings}
                                        target={this.state.target}
                                        classes={classes}
                                        isMobile={isMobile}
                                        closeSearch={() => this.setState({ searchOpen: false })}
                                        showReports={this.state.showReports}
                                        isAdmin={this.props.userData.role === 'admin'}
                                    />
                                </Drawer>}
                            <Grid item xs={12}>
                                <ListItems
                                    classes={classes}
                                    sort={this.state.sort}
                                    mappings={this.props.mappings}
                                    reportMappings={this.props.reportMappings}
                                    target={this.state.target}
                                    handleSortChange={this.handleSortChange}
                                    changePage={this.changePage}
                                    results={this.state.results}
                                    loading={this.state.loading}
                                    handleDataChange={this.handleDataChange}
                                    updateItem={this.updateItem}
                                    updateMultipleItems={this.updateMultipleItems}
                                    deleteItem={this.deleteItem}
                                    openSearch={() => { this.setState({ searchOpen: true }) }}
                                    openAdmin={() => this.setState({ adminOpen: true })}
                                    isAdmin={this.props.userData.role === 'admin'}
                                    isMobile={isMobile}
                                    exportAll={this.exportAll}/>
                            </Grid>
                        </Grid>
                    </Box>
                </MuiThemeProvider></MuiPickersUtilsProvider>
        )
    }
}

export default withStyles(useStyles)(AdvancedSearch);