import React, { useCallback, useEffect, useState } from "react";
import s from "./change.module.css";
import { InputV2 } from "../../../../components/redesign-components/InputV2/InputV2";
import { ButtonV2 } from "../../../../components/redesign-components/ButtonV2/ButtonV2";
import { useDropzone } from "react-dropzone";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { updateUserDetails } from "../../api";
import { useMediaQuery } from "react-responsive";
import Swal from "sweetalert2";
import { Avatar } from "@material-ui/core";

export const AccountDetailsChange = ({ picture: userPicture, name, email }) => {
  const [form, setForm] = useState({
    name: "",
    email: "",
    password: "",
    repeatPassword: "",
  });

  const [passwordErrors, setPasswordErrors] = useState({
    isNotStrong: false,
    isNotSame: false,
  });

  const [picture, setPicture] = useState(null);

  const [fileState, setFileState] = useState(null);

  const onDrop = useCallback((files) => {
    setFileState(files[0]);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 900px)" });

  useEffect(() => {
    setForm((prev) => ({ ...prev, name, email }));
    setPicture(userPicture);
  }, [name, email, userPicture]);

  useEffect(() => {
    if (fileState) {
      const objectUrl = URL.createObjectURL(fileState);
      setPicture(objectUrl);
      return () => URL.revokeObjectURL(objectUrl);
    }
  }, [fileState]);

  const onSubmit = useCallback(async () => {
    if (form.password) {
      const sameCheck = form.password === form.repeatPassword;
      const strongCheck = RegExp(
        /^(?=.*\d)(?=.*[.!@#$%^&*])(?=.*[a-z]).{8,}$/
      ).test(form.password);

      if (!strongCheck) {
        setPasswordErrors((prev) => ({ ...prev, isNotStrong: true }));
      } else if (!sameCheck) {
        setPasswordErrors({ isNotStrong: false, isNotSame: true });
      } else {
        setPasswordErrors({ isNotSame: false, isNotStrong: false });
        const data = { userName: form.name, password: form.password, email };

        if (fileState) data.picture = fileState;
        await updateUserDetails(data);

        setForm((prev) => ({ ...prev, password: "", repeatPassword: "" }));

        Swal.fire({
          title: '<p style="font-size:70%;">Your changes have been saved!</p>',
          icon: "success",
          confirmButtonText: "Ok",
          allowOutsideClick: false,
          timer: 2000,
          customClass: {
            container: "my-swal",
          },
        });
      }
    } else {
      const data = { userName: form.name, email };

      if (fileState) data.picture = fileState;
      await updateUserDetails(data);

      Swal.fire({
        title: '<p style="font-size:70%;">Your changes have been saved!</p>',
        icon: "success",
        confirmButtonText: "Ok",
        allowOutsideClick: false,
        timer: 2000,
        customClass: {
          container: "my-swal",
        },
      });
    }
  }, [form, fileState, email]);

  const handleInput = (name, value) => {
    setForm((prev) => ({ ...prev, [name]: value }));
  };

  const getInitials = (name) => {
    const words = name.split(" ");
    let initials = "";

    for (let i = 0; i < words.length; i++) {
      initials += words[i].charAt(0);
    }
    initials = initials.toUpperCase();

    return initials;
  };

  const getDisabledState = () => {
    let state = true;
    if (picture) {
      state = false;
    }
    if (form.name !== name) {
      state = false;
    }
    if (
      form.repeatPassword.length > 0 &&
      form.repeatPassword === form.password
    ) {
      state = false;
    }
    return state;
  };

  return (
    <div className={s.container}>
      <div className={isTabletOrMobile ? s.mobileSegmentItem : s.segmentItem}>
        <div className={s.pictureUploadContainer}>
          <div
            className={isTabletOrMobile ? s.mobileSegmentLabel : s.segmentLabel}
          >
            Profile picture
          </div>
          <div className={s.pictureUpload} {...getRootProps()}>
            <div
              className={
                isTabletOrMobile ? s.mobileImageContainer : s.imageContainer
              }
            >
              <Avatar
                style={{
                  width: isTabletOrMobile ? "120px" : "150px",
                  height: isTabletOrMobile ? "120px" : "150px",
                }}
                src={picture}
              >
                {!picture ? (
                  <p style={{ fontSize: "300%" }}>{getInitials(name)}</p>
                ) : (
                  ""
                )}
              </Avatar>
            </div>
            {!isTabletOrMobile && (
              <div className={s.dropzone}>
                <input {...getInputProps()} />

                <div className={s.dropzoneContent}>
                  <CloudUploadIcon />
                  <div>Click to upload or drag and drop</div>
                  <div>SVG, PNG, JPG or GIF (max. 800x400px)</div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <hr />

      <div className={s.segmentColumn}>
        <div className={isTabletOrMobile ? s.mobileSegmentItem : s.segmentItem}>
          <div className={s.segmentLabel}>Name</div>
          <div className={s.segmentContent}>
            <InputV2 onChange={handleInput} name="name" value={form.name} />
          </div>
        </div>
        <div className={isTabletOrMobile ? s.mobileSegmentItem : s.segmentItem}>
          <div className={s.segmentLabel}>Email</div>
          <div className={s.segmentContent}>
            <InputV2
              disabled
              onChange={() => {}}
              name="email"
              value={form.email}
            />
          </div>
        </div>
      </div>

      <hr />

      <div className={s.segmentColumn}>
        <div className={isTabletOrMobile ? s.mobileSegmentItem : s.segmentItem}>
          <div className={s.segmentLabel}>New password</div>
          <div className={s.segmentContent}>
            <InputV2
              type="password"
              name="password"
              value={form.password}
              onChange={handleInput}
            />
            {passwordErrors.isNotStrong && (
              <p style={{ color: "red" }}>Password is not strong enough</p>
            )}
          </div>
          {form.password && (
            <div className={s.segmentContent}>
              <p>Password must contain:</p>
              <ul>
                <li>• At least 8 characters</li>
                <li>• At least one number</li>
                <li>• A special character (e.g., !@#$%&*)</li>
              </ul>
            </div>
          )}
        </div>
        <div className={isTabletOrMobile ? s.mobileSegmentItem : s.segmentItem}>
          <div className={s.segmentLabel}>Repeat new password</div>
          <div className={s.segmentContent}>
            <InputV2
              type="password"
              name="repeatPassword"
              value={form.repeatPassword}
              onChange={handleInput}
            />
            {form.repeatPassword && form.password !== form.repeatPassword && (
              <p style={{ color: "red" }}>Password does not match</p>
            )}
            {passwordErrors.isNotSame && (
              <p style={{ color: "red" }}>Password does not match</p>
            )}
          </div>
        </div>
      </div>
      <ButtonV2
        onClick={onSubmit}
        disabled={getDisabledState()}
        fit
        color="purple"
        text="Save changes"
      />
    </div>
  );
};
