import React, { forwardRef, useState, useEffect } from 'react';

import {
  Card, CircularProgress, Container, Grid, Typography,
  Divider, Button,
} from '@material-ui/core';
import {
  AddBox,
  ArrowDownward,
  Check,
  ChevronLeft,
  ChevronRight,
  Clear,
  DeleteOutline,
  Edit,
  FilterList,
  FirstPage,
  LastPage,
  Remove,
  SaveAlt,
  Search,
  ViewColumn,
  Receipt,
} from "@material-ui/icons";
import { makeStyles } from '@material-ui/core/styles';
import Swal from 'sweetalert2';
import 'jspdf-autotable';
import firebase, { functionBaseUrl, axiosWithToken } from '../../common/firebase';
import moment from 'moment';
import MaterialTable from "material-table";
import { BuyCredits } from './BuyCredits';

const tableIcons = {
  ExportCSV: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  ExportPDF: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => (
    <ArrowDownward {...props} ref={ref} />
  )),
  ThirdStateCheck: forwardRef((props, ref) => (
    <Remove {...props} ref={ref} />
  )),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  Receipt: forwardRef((props, ref) => <Receipt {...props} ref={ref} />),
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    minHeight: 'fit-content',
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    //flexWrap: 'wrap',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
    },
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: '2%',
    //flexWrap: 'wrap',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
    },
  },
  text: {
    fontFamily: 'Manrope',
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '24px',
    letterSpacing: '0em',
    textAlign: 'left',
  },
  divider: {
    margin: theme.spacing(3, 0),
  },
  heading: {
    fontFamily: 'Manrope',
    fontSize: '24px',
    fontWeight: 600,
    lineHeight: '20px',
    letterSpacing: '0em',
    textAlign: 'left',
    marginBottom: 15,
  },
  card: {
    width: '30%',
    maxWidth: '320px',
    height: '260px',
    padding: theme.spacing(5),
    borderRadius: '12px',
    border: '1px solid #EAECF0',
    gap: '24px',
    background: 'linear-gradient(0deg, #FFFFFF, #FFFFFF), linear-gradient(0deg, #EAECF0, #EAECF0)',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      maxWidth: '100%',
    },
  },
  bigCard: {
    width: '100%',
    maxWidth: '808px',
    height: '260px',
    padding: '24px',
    borderRadius: '12px',
    border: '1px solid #CBB1FB',
    gap: '32px',
    marginLeft: '2%',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundColor: '#FCFAFF',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      maxWidth: '100%',
      marginTop: '5%',
      marginLeft: 0,
    },
    '&::after': {
      content: '""',
      position: 'absolute',
      top: '0',
      right: '0',
      width: '25%',
      height: '50%',
      backgroundImage: `url("/images/icons/background_pattern_decorative.png")`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
    },
  },
  cardText: {
    fontFamily: 'Manrope',
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '20px',
    letterSpacing: '0em',
    textAlign: 'left',
    //marginBottom: 10,
    color: 'white',
  },
  bigCardText: {
    fontFamily: 'Manrope',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '20px',
    letterSpacing: '0em',
    textAlign: 'left',
    //marginBottom: 10,
    //color: 'white',
  },
  displaySm: {
    fontFamily: 'Manrope',
    fontSize: '30px',
    fontWeight: 600,
    lineHeight: '38px',
    letterSpacing: '0em',
    textAlign: 'left',
  },
  displayMd: {
    fontFamily: 'Manrope',
    fontSize: '36px',
    fontWeight: 600,
    lineHeight: '44px',
    letterSpacing: '-0.02em',
    textAlign: 'left',
    marginRight: 10,
  },
  button: {
    width: 'fit-content',
    height: '36px',
    padding: '8px 14px',
    borderRadius: '64px',
    gap: '8px',
    background: '#5C0DEE',
    textTransform: 'none',
    marginTop: 15,
    '&:hover': {
      background: '#8e5beb',
    },
  },
}));

function CreditsComponent({ history }) {
  const [id, setId] = useState('');
  const [user, setUser] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [amount, setAmount] = useState(Date.now() >= new Date(2023, 11, 8).getTime() && Date.now() <= new Date(2023, 11, 31).getTime() ? 250 : 300);
  const [credits, setCredits] = useState(30);
  const [custom, setCustom] = useState(false);
  const [unpaidCredit, setUnpaidCredit] = useState({});
  const [invoices, setInvoices] = useState([]);
  const [open, setOpen] = useState(false);
  const [activeSubs, setActiveSubs] = useState(0);

  const loadData = async () => {
    try {
      setIsLoading(true);
      const firebaseUser = firebase.auth().currentUser;
      setId(firebaseUser.uid);
      let userResponse = await axiosWithToken(`${functionBaseUrl}/api/users/${firebaseUser.uid}`, {
        method: 'GET',
      });
      setUser(userResponse?.data);

      let response = await axiosWithToken(`${functionBaseUrl}/api/v1/unpaidCredits/coach/${firebaseUser.uid}`, {
        method: 'GET',
      });
      if (response?.data?.credits?.length > 0) {
        setAmount(response?.data?.credits[0]?.amount);
        setCredits(response?.data?.credits[0]?.creditCount);
        setUnpaidCredit(response?.data?.credits[0]);
        setCustom(true);
      }
      const invoicesResponse = await axiosWithToken(`${functionBaseUrl}/api/v1/appliedCredits/coach/${firebaseUser.uid}`, {
        method: 'GET',
      });
      setInvoices(invoicesResponse?.data?.credits);
      const activeSubsRes = await axiosWithToken(`${functionBaseUrl}/api/subsCount/${firebaseUser.uid}`, {
        method: 'GET',
      });
      setActiveSubs(activeSubsRes?.data?.activeSubs);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      Swal.fire({
        title: '<p style="font-size:70%;">Error loading data</p>',
        icon: 'error',
        confirmButtonText: 'Ok',
        allowOutsideClick: true,
      });
    }
  }

  useEffect(() => {
    loadData();
  }, [])

  async function handleResult() {
    if (custom) {
      await axiosWithToken(`${functionBaseUrl}/api/v1/credits`, {
        method: 'PATCH',
        data: { creditId: unpaidCredit?.id, info: { status: 'complete' } }
      })
        .then(async () => {
          await Swal.fire({
            title: `<p style="font-size:70%;">Thank you for your payment!</p>`,
            showConfirmButton: false,
            icon: 'success',
            customClass: {
              container: 'my-swal'
            },
            timer: 3000,
            allowOutsideClick: true
          }).then(async () => {
            await history.push('/students');
          })
        })
        .catch(async (error) => {
          await Swal.fire({
            title: '<p style="font-size:70%;">Error adding credits</p>',
            icon: 'error',
            customClass: {
              container: 'my-swal'
            },
            confirmButtonText: 'Ok',
            allowOutsideClick: true,
          });
        });
    } else {
      let input = {
        amount: amount,
        creditCount: credits,
        status: 'complete',
        userId: id,
        coachId: id,
        transactionType: 'in'
      }

      await axiosWithToken(`${functionBaseUrl}/api/v1/credits`, {
        method: 'POST',
        data: input
      })
        .then(async () => {
          await Swal.fire({
            title: `<p style="font-size:70%;">Thank you for your payment!</p>`,
            showConfirmButton: false,
            icon: 'success',
            customClass: {
              container: 'my-swal'
            },
            timer: 3000,
            allowOutsideClick: true
          }).then(async () => {
            await history.push('/students');
          })
        })
        .catch(async (error) => {
          await Swal.fire({
            title: '<p style="font-size:70%;">Error adding credits</p>',
            icon: 'error',
            customClass: {
              container: 'my-swal'
            },
            confirmButtonText: 'Ok',
            allowOutsideClick: true,
          });
        });
    }
  }

  function handlePackageChange(amount) {
    setAmount(0);
    if (amount === 300 || amount === 250) {
      setCredits(30);
    } else if (amount === 500 || amount === 425) {
      setCredits(60);
    } else if (amount === 700 || amount === 600) {
      setCredits(100);
    } else if (amount === 1800 || amount === 1600) {
      setCredits(360);
    } else {
      setCredits(0);
    }
    setAmount(amount);
  }

  const tableHeader = [
    { field: "userEmail", title: "Given to" },
    {
      field: "createdAt", title: "Date", render: rowData => (
        <>{moment(rowData.createdAt).format("MM/DD/YYYY")}</>
      ), defaultSort: "desc"
    },
    { field: "method", title: "Method", render: rowData => (
      <>{rowData?.method ? rowData?.method : "Manual upgrade"}</>
    )},
  ];

  const classes = useStyles();

  return (<>
    {isLoading && (
      <Container maxWidth="xs" style={{ textAlign: 'center', paddingTop: '50px' }}>
        <CircularProgress />
      </Container>
    )}
    {!isLoading && (
      <Container
        component="main"
        style={{ paddingTop: '20px' }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div className={classes.root}>
              <Typography          
                variant="h4"
                className={classes.heading}
              >
                Credits
              </Typography>
              <Typography          
                variant="body2"
                className={classes.text}
              >
                Support your students practicing alone.
              </Typography>
            </div>
            <Divider className={classes.divider} />
          </Grid>

          <Grid item xs={12}>
            <div className={classes.container}>
              <Card className={classes.card}>
                <Typography
                  className={classes.text}
                >
                  Your wallet
                </Typography>

                <Typography
                  className={classes.displayMd}
                >
                  {user?.creditCount || 0} credits
                </Typography>

                <Typography
                  className={classes.text}
                >
                  {activeSubs || 0} student{activeSubs > 1 ? 's' : ''} enrolled into auto renew.
                </Typography>

                <Button className={classes.button} onClick={() => setOpen(true)}>
                  <Typography className={classes.cardText}>Buy credits</Typography>
                </Button>
              </Card>

              <Card className={classes.bigCard}>
                <Typography
                  className={classes.text}
                >
                  <div className={classes.textContainer}>
                    Set them up for success <br />
                  </div>
                </Typography>
                <Typography
                  className={classes.bigCardText}
                >
                  <div className={classes.textContainer}>
                    <img src="/images/icons/check_icon.png" />&ensp; Unlimited Swings <br />
                  </div>
                  <div className={classes.textContainer}>
                    <img src="/images/icons/check_icon.png" />&ensp; Handsfree Mode <br />
                  </div>
                  <div className={classes.textContainer}>
                    <img src="/images/icons/check_icon.png" />&ensp; 2D & 3D comparison modes <br />
                  </div>
                  <div className={classes.textContainer}>
                    <img src="/images/icons/check_icon.png" />&ensp; Library of 50+ practice drills <br />
                  </div>
                  <div className={classes.textContainer}>
                    <img src="/images/icons/check_icon.png" />&ensp; Drawing Tools <br />
                  </div>
                </Typography>
              </Card>
            </div>
          </Grid>

          <Grid item xs={12} style={{ marginTop: '2%' }}>
              <Typography
                className={classes.heading}
              >
                <div className={classes.container}>
                  <img src="/images/icons/users-right.png" />&nbsp; Recent usage
                </div>
              </Typography>

              <MaterialTable
                style={{ marginTop: "10px", marginBottom: "10px" }}
                icons={tableIcons}
                columns={tableHeader}
                data={invoices}
                options={{
                  selection: false,
                  sorting: true,
                  search: false,
                  paging: true,
                  pageSizeOptions: [5, 10, 20, 50, 100],
                  showTitle: false,
                  toolbar: false
                }}
              />
          </Grid>
          <BuyCredits 
            open={open} 
            close={() => setOpen(false)} 
            handleResult={handleResult} 
            amount={amount}
            credits={credits}
            custom={custom}
            handlePackageChange={handlePackageChange}
            user={user}
          />
        </Grid>
      </Container>
    )}
  </>);
}

export const Credits = CreditsComponent;