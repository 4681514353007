import React from "react";

const FlexWrap = ({
    children,
    style = {},
    direction = "row",
    justify = "center",
    align = "center",
    wrap = "wrap",
    width = "100%",
}) => {
    return (
        <div
            style={{
                display: "flex",
                flexDirection: direction,
                justifyContent: justify,
                alignItems: align,
                flexWrap: wrap,
                width: width,
                boxSizing: "border-box",
                ...style,
            }}
        >
            {children}
        </div>
    );
};

export default FlexWrap;
