import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import {makeStyles} from '@material-ui/styles';
import CropImage from './CropImage'
import {
  Card,
  CardActions,
  CardContent,
  Avatar,
  Typography,
  Divider,
  Button,
  CardHeader,  
  Grid,
  Menu,
  MenuItem,
  Fade,
  Paper,
  InputBase,
  IconButton,
} from '@material-ui/core';
import CloseIcon from "@material-ui/icons/Close";
import SearchIcon from "@material-ui/icons/Search";
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import DeleteIcon from '@material-ui/icons/Delete';
import { IoMdKey } from 'react-icons/io';
import blue from "@material-ui/core/colors/blue";
import MuiAlert from '@material-ui/lab/Alert';
import { MdChangeCircle } from 'react-icons/md';
import {functionBaseUrl, axiosWithToken} from '../../../../common/firebase';
import Swal from 'sweetalert2';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const useStyles = makeStyles((theme) => ({
  root: {
    minHeight:710,
    minWidth:400
  },
  details: {
    display: 'flex',
    maxWidth:300
  },
  avatar: {
    marginRight: 'auto',
    height: 150,
    width: 150,
    flexShrink: 0,
    flexGrow: 0,
  },
  padding: {
    padding: theme.spacing(1),
  },
  uploadButton: {
    marginRight: theme.spacing(2),
  },
  none: {
    display: "none",
  },
  searchRoot: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: 400
  },
  searchInput: {
    marginLeft: 8,
    flex: 1
  },
  searchIconButton: {
    padding: 10
  },
  searchDivider: {
    width: 1,
    height: 28,
    margin: 4
  },
  button: {
    color: blue[900],
    margin: 10
  },
  deleteButton: {
    color: "red",
    borderColor: "red",
    marginRight: theme.spacing(2)
  },
  passwordButton: {
    color: "blue",
    borderColor: "blue",
    marginRight: theme.spacing(2)
  },
  secondaryButton: {
    color: "gray",
    margin: 10
  }
}));

const AccountProfile = (props) => {
  const {className, ...rest} = props;

  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorEl2, setAnchorEl2] = React.useState(null);

  const [file, setFile] = React.useState('');

  const handleClick = () => {
     let el = document.getElementById("fileElem");
      if (el) {
        el.click();
      }
  };

  const handleFiles = (event) => {
    let file = event.target.files[0]
    if (file) {
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setFile(reader.result)
      });
      reader.readAsDataURL(file);
    }
  }
  const handlePopUpClose = () => {
    setFile('');
  }

  const handleUrlUpload = (event) => {
    event.preventDefault();
    const data = new FormData(event.target);
    uploadImages(data.get('URL'));
  }

  const validatePassword = (password) => {
    var re = /^(?=.*\d)(?=.*[.!@#$%^&*])(?=.*[a-z]).{8,}$/;
    return re.test(password);
  }

  const handlePasswordChange = () => {
    let html = ''
    if(window.matchMedia('(max-width: 700px)').matches) {
      html = `
        <table>
          <tr>
              <td>New:</td>
          </tr>
          <tr>
            <td width="50%"><input id="p1" type="password" class="swal2-input"></td>
          </tr>
          <tr>
              <td>Re-Enter New:</td><br>
          </tr>
          <tr>
            <td width="50%"><input id="p2" type="password" class="swal2-input"></td>
          </tr>
        </table>
        `
    } else {
      html = `
        <table>
          <tr>
              <td>New:</td>
              <td><input id="p1" type="password" class="swal2-input"/></td>
          </tr>
          <tr>
              <td>Re-Enter New:</td>
              <td><input id="p2" type="password" class="swal2-input"/></td>
          </tr>
        </table>
        `
    }
    Swal.fire({
      title: '<p style="font-size:80%;">Update Password</p>',
      html: html,
      focusConfirm: false,
      confirmButtonText: 'Update',
      showCancelButton: true
    }).then((result) => {
      if(result.isConfirmed) {
        const pass = document.getElementById('p1').value;
        const confirmPass = document.getElementById('p2').value;
        if(pass != confirmPass) {
          Swal.fire({
            title: '<p style="font-size:70%;">Password confirmation does not match</p>',
            icon: 'warning',
            confirmButtonText: 'Ok',
            allowOutsideClick: false,
          })
          return false
        }
        if (!validatePassword(pass)) {
          Swal.fire({
            title: '<p style="font-size:70%;">Password must contain:</p>',
            html:`
            <ul>
              <li>• At least 8 characters</li>
              <li>• At least one number</li>
              <li>• A special character (e.g., !@#$%&*)</li>
            </ul>
            `,
            icon: 'warning',
            confirmButtonText: 'Ok',
            allowOutsideClick: true
          })
          return;
        }
        axiosWithToken(functionBaseUrl+'/api/password/', {
          method: 'PATCH',
          data: {
            id: rest.user.uid,
            pass: pass,
            confirmPass: confirmPass
          }
        }).then(response => {
          if (response.status === 201) {
            Swal.fire({
              title: '<p style="font-size:70%;">' + response.data.message + '</p>',
              icon: 'warning',
              confirmButtonText: 'Ok',
              allowOutsideClick: false,
            })
          } else {
            Swal.fire({
              title: '<p style="font-size:70%;">Password successfully updated!</p>',
              icon: 'success',
              confirmButtonText: 'Ok',
              allowOutsideClick: false,
              timer: 2000
            })
          }
        })
        .catch(err => {
          console.log(err)
        })
      }
    })
    .catch(err => {
      console.log(err)
    })
  }

  const handleDeleteButton = () => {
    Swal.fire({
      title: '<p style="font-size:80%;">Enter User Details</p>',
      html: `
        <label for="email">Email:</label>
        <input id="email" class="swal2-input">
        `,
      focusConfirm: false,
      confirmButtonText: 'Delete',
      confirmButtonColor: 'red',
      showCancelButton: true
    }).then((result) => {
      if(result.isConfirmed) {
        axiosWithToken(functionBaseUrl+'/api/userByEmail/'+ document.getElementById('email').value.toLowerCase().replace(/ /g, ''), {
          method: 'DELETE'
        }).then(response => {
          if(response.status === 201) {
            Swal.fire({
              title: '<p style="font-size:70%;">' + response.data.message + '</p>',
              icon: 'warning',
              confirmButtonText: 'Ok',
              allowOutsideClick: false,
            })
          } else {
            Swal.fire({
              title: '<p style="font-size:70%;">Account successfully deleted</p>',
              icon: 'success',
              confirmButtonText: 'Ok',
              allowOutsideClick: false,
              timer: 2000
            })
          }
        })
        .catch(err => {
          console.log(err)
        })
      }
    })
    .catch(err => {
      console.log(err)
    })
  }

  const handleAnyUserPassChange = () => {
    Swal.fire({
      title: '<p style="font-size:80%;">Enter User Details</p>',
      html: `
        <label for="emailPass">Email:</label>
        <input id="emailPass" class="swal2-input">
        `,
      focusConfirm: false,
      confirmButtonText: 'Change',
      confirmButtonColor: 'green',
      showCancelButton: true
    }).then((result) => {
      let userEmail = document.getElementById('emailPass').value.toLowerCase().replace(/ /g, '')
      if(result.isConfirmed) {
        axiosWithToken(functionBaseUrl+'/api/anyUserPass', {
          method: 'PATCH',
          data: {
            email: userEmail,
            password: "ilovegolf"
          }
        }).then(response => {
          if(response.status === 201) {
            Swal.fire({
              title: '<p style="font-size:70%;">' + response.data.message + '</p>',
              icon: 'warning',
              confirmButtonText: 'Ok',
              allowOutsideClick: false,
            })
          } else {
            Swal.fire({
              title: '<p style="font-size:70%;">' + userEmail + ' password successfully changed to "<b>ilovegolf<b>"</p>',
              icon: 'success',
              confirmButtonText: 'Ok',
              allowOutsideClick: true
            })
          }
        })
        .catch(err => {
          console.log(err)
        })
      }
    })
    .catch(err => {
      console.log(err)
    })
  }

  let titleCase = (str) => {
    if(str) {
      str = str.replaceAll('-', ' ');
      var splitStr = str.toLowerCase().split(' ');
      for (var i = 0; i < splitStr.length; i++) {
          splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);     
      }
      return splitStr.join(' '); 
    } else {
      return ""
    }
 }
  
  const uploadImages = (url) => {
    try {
      axiosWithToken(functionBaseUrl+'/api/users/'+ rest.user.uid, {
        method: 'patch',
        data: {    
          userInfo: {
            picture: url
          }
        }
      }).then(response => {
        //console.log("Successfully Uploaded")
        window.location.reload();
      })
    } catch (error) {
      console.log(error)
    }
  }

  const dateDisplay = (date) => {
    return moment(String(date), "x").format('MM/DD/YYYY');
  }

  return (
    <div>
      <Grid
      container
      >
        <Card      
        {...rest}
        className={clsx(classes.root, className)}
        variant="elevation"    
        >
          <CardHeader
          subheader={"Last loaded: " + moment().format('hh:mm A')}
          title="Account"
          titleTypographyProps={{variant:"h5", color:"primary"}}
          />
          <Divider/>
          <CardContent>
            <div className={classes.padding}>
              <div>
                <Typography
                  variant="h4"
                >
                  {rest.user.firstName + " " + rest.user.lastName}
                </Typography>
                <Typography        
                  color="textSecondary"
                  variant="body1"
                  gutterBottom
                >
                  {rest.user.email}
                </Typography>
                <Divider/>                    
              </div>
              <div className={classes.padding}>
                <Avatar
                  className={classes.avatar}
                  src={rest.user.profileURL}
                />
              </div>
              <Divider/>
            </div>
            <div className={classes.details}>
            <div>
              <Typography
              color="textSecondary"
              variant="body1"
              >
                <b>Account Created:</b> {" " + rest.user.userCreated ? dateDisplay(rest.user.userCreated) : ""}
              </Typography>      
              <Typography
              color="textSecondary"
              variant="body1"
              >
                <b>Contact Number:</b> {" " + rest.user.contactNumber ? rest.user.contactNumber : ""}
              </Typography>        
              <Typography
                color="textSecondary"
                variant="body1"
              >
                <b>Date of birth:</b> {" " + rest.user.dob}
              </Typography>
              <Typography
                color="textSecondary"
                variant="body1"
              >
                <b>Gender:</b> {" " + titleCase(rest.user.gender)}
              </Typography>            
              <Typography                
                color="textSecondary"
                variant="body1"
              >
                <b>Height:</b> {rest.user.height ? " " + rest.user.height.replace(".", " feet, ") + "" : ""}
              </Typography>
              {/*<Typography              
                color="textSecondary"
                variant="body1"
              >
                <b>Weight:</b> {" " + rest.user.weight ? rest.user.weight : "" + " "} lbs
              </Typography>*/}
              <Typography              
                color="textSecondary"
                variant="body1"
              >
                <b>Hip Width (Inches):</b> {" " + rest.user.hipSize ? rest.user.hipSize : "" + " "} in
              </Typography>
              <Typography
                color="textSecondary"
                variant="body1"
                //rest.user.dominantHand.charAt(0).toUpperCase() + rest.user.dominantHand.slice(1)
              >
                <b>Dominant Hand:</b> {" " + titleCase(rest.user.dominantHand)}
              </Typography>
              {/*<Typography
                color="textSecondary"
                variant="body1"
                //style={{display: rest.user.disability === "" ? "block" : "none"}}
              >
                <b>Disability:</b> {" " + rest.user.disability}
              </Typography>*/}       
              {/*<Typography
                color="textSecondary"
                variant="body1"
              >
                <b>Address:</b> {rest.address.postal_code ? rest.address.street_number + " " + rest.address.route + ", " + rest.address.locality + ", " + 
                                 rest.address.administrative_area_level_1 + ", " + rest.address.country + ", " + rest.address.postal_code : ""}
              </Typography>*/}         
            </div>
          </div>        
          </CardContent>      
          <Divider />
          <CardActions>
            <div>
              <Button
                fullWidth
                className={classes.uploadButton}
                color="secondary"
                variant="outlined"
                aria-controls="simple-menu"
                aria-haspopup="true"
                startIcon={<CloudUploadIcon />}
                onClick={(event) => {setAnchorEl(event.currentTarget)}}
              >
                Upload picture
              </Button>
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={() => {setAnchorEl(null)}}
                TransitionComponent={Fade}
              >
                <form>
                  <input type="file" id="fileElem" accept="image/*" className={classes.none} onChange={handleFiles}/>
                </form>
                <MenuItem onClick={handleClick}>Choose file</MenuItem>
                <MenuItem
                aria-controls="type-field-menu"
                aria-haspopup="true"
                onClick={(event) => {setAnchorEl2(event.currentTarget)}}
                >
                Enter URL
                </MenuItem>
                <Menu
                  id="type-field-menu"
                  anchorEl={anchorEl2}
                  keepMounted
                  open={Boolean(anchorEl2)}
                  onClose={() => {setAnchorEl2(null)}}
                  TransitionComponent={Fade}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                >
                  <form onSubmit={handleUrlUpload}>
                    <Paper className={classes.searchRoot} elevation={1}>
                      <InputBase className={classes.searchInput} placeholder="Image URL" name="URL" />
                      <IconButton
                        className={classes.button}
                        aria-label="Search"
                        type="submit"
                        onClick={() => {setAnchorEl(null); setAnchorEl2(null);}}
                      >
                        <SearchIcon />
                      </IconButton>
                      <Divider className={classes.searchDivider} />
                      <IconButton
                        color="primary"
                        className={classes.secondaryButton}
                        aria-label="Close"
                        onClick={() => {setAnchorEl2(null)}}
                      >
                        <CloseIcon />
                      </IconButton>
                    </Paper>
                  </form>
                </Menu>
              </Menu>
            </div>   
          </CardActions>
          <CardActions>
            <div>
                <Button
                  fullWidth
                  className={classes.passwordButton}
                  variant="outlined"
                  color="primary"
                  startIcon={<IoMdKey />}
                  onClick={handlePasswordChange}
                >
                  Update Password
                </Button>
              </div>
            </CardActions>
          {rest.user.role === 'admin' ?
            <CardActions>
              <div>
                <Button
                  fullWidth
                  className={classes.deleteButton}
                  variant="outlined"
                  color="secondary"
                  startIcon={<DeleteIcon />}
                  onClick={handleDeleteButton}
                >
                  Delete Any Account
                </Button>
              </div>
            </CardActions> : <div></div>
          }
          {rest.user.role === 'admin' ?
            <CardActions>
              <div>
                <Button
                  fullWidth
                  className={classes.anyUserPassButton}
                  variant="outlined"
                  color="secondary"
                  startIcon={<MdChangeCircle />}
                  onClick={handleAnyUserPassChange}
                >
                  Change Any Account's Password
                </Button>
              </div>
            </CardActions> : <div></div>
          }
        </Card>
      </Grid>
      <CropImage 
        file = {file}
        handlePopUpClose = {handlePopUpClose}
        uploadImages = {uploadImages}
        user = {rest.user}
        Alert = {Alert}
      />
  </div>
  );
};

AccountProfile.propTypes = {
  className: PropTypes.string,
};

export default AccountProfile;