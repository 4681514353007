import React, { useState } from "react";

import {
  Button,
  Grid,
  CircularProgress,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Checkbox,
  FormControlLabel,
  Link,
  TextField,
  Typography,
} from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Swal from "sweetalert2";
import axios from "axios";
import {
  checkUserAcc,
  validatePassword,
  validateEmail,
  dobFormat,
} from "../../common/signup";
import firebase, {
  functionBaseUrl,
  axiosWithToken,
} from "../../common/firebase";
import { listOfSubscriptions } from "../../common/envConfig";

function PlansSignupComponent({ finishSignUp, back, plan }) {
  const [userFirstName, setUserFirstName] = useState("");
  const [userLastName, setUserLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [tosAgree, setTosAgree] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleSignUp = async () => {
    if (!userFirstName) {
      Swal.fire({
        title: '<p style="font-size:70%;">Please enter your First Name</p>',
        icon: "warning",
        confirmButtonText: "Ok",
        allowOutsideClick: true,
      });
      return;
    }
    if (!userLastName) {
      Swal.fire({
        title: '<p style="font-size:70%;">Please enter your Last Name</p>',
        icon: "warning",
        confirmButtonText: "Ok",
        allowOutsideClick: true,
      });
      return;
    }
    if (!email) {
      Swal.fire({
        title: '<p style="font-size:70%;">Please enter your Email</p>',
        icon: "warning",
        confirmButtonText: "Ok",
        allowOutsideClick: true,
      });
      return;
    }
    if (!validateEmail(email)) {
      Swal.fire({
        title: '<p style="font-size:70%;">Please enter a valid Email</p>',
        icon: "warning",
        confirmButtonText: "Ok",
        allowOutsideClick: true,
      });
      return;
    }
    if (!dateOfBirth) {
      Swal.fire({
        title: '<p style="font-size:70%;">Please enter your Date of Birth</p>',
        icon: "warning",
        confirmButtonText: "Ok",
        allowOutsideClick: true,
      });
      return;
    }
    const options = { year: "numeric", month: "numeric", day: "numeric" };
    const date = new Date(new Date().setFullYear(new Date().getFullYear() - 13))
      .toLocaleDateString("en-ZA", options)
      .replaceAll("/", "-");
    if (Date.parse(dateOfBirth) > Date.parse(date)) {
      Swal.fire({
        title:
          '<p style="font-size:70%;">Sorry, you are not eligible for a Sportsbox.ai account at this time.</p>',
        confirmButtonText: "Ok",
        allowOutsideClick: false,
        customClass: {
          container: "my-swal",
        },
      }).then(() => {
        firebase
          .auth()
          .signOut()
          .then(() => {
            console.log("logged out" + firebase.auth().currentUser);
          })
          .catch((error) => {
            console.log(error);
          });
        window.location.reload();
      });
      return false;
    }
    if (!password) {
      Swal.fire({
        title: '<p style="font-size:70%;">Please enter Password</p>',
        icon: "warning",
        confirmButtonText: "Ok",
        allowOutsideClick: true,
      });
      return;
    }
    if (!validatePassword(password)) {
      Swal.fire({
        title: '<p style="font-size:70%;">Password must contain:</p>',
        html: `
        <ul>
          <li>• At least 8 characters</li>
          <li>• At least one number</li>
          <li>• A special character (e.g., !@#$%&*)</li>
        </ul>
        `,
        icon: "warning",
        confirmButtonText: "Ok",
        allowOutsideClick: true,
      });
      return;
    }
    if (!confirmPassword) {
      Swal.fire({
        title:
          '<p style="font-size:70%;">Please enter Password Confirmation</p>',
        icon: "warning",
        confirmButtonText: "Ok",
        allowOutsideClick: true,
      });
      return;
    }
    if (password !== confirmPassword) {
      Swal.fire({
        title: '<p style="font-size:70%;">Passwords do not match</p>',
        icon: "warning",
        confirmButtonText: "Ok",
        allowOutsideClick: true,
      });
      return;
    }
    setIsLoading(true);
    const noUsers = await checkUserAcc(email);
    if (noUsers) {
      await firebase
        .auth()
        .createUserWithEmailAndPassword(
          email.toLowerCase().replace(/ /g, ""),
          password
        )
        .then(async (creds) => {
          await firebase
            .auth()
            .signInWithEmailAndPassword(
              email.toLowerCase().replace(/ /g, ""),
              password
            )
            .catch((err) => {
              console.log(err);
            });

          const currentUser = firebase.auth().currentUser;
          localStorage.setItem('currUser', await currentUser?.getIdToken(true));

          if (!plan.includes("free")) {
            await axiosWithToken(functionBaseUrl + "/api/userEmailVerf", {
              method: "PATCH",
              data: {
                id: creds.user.uid,
                status: true,
              },
            });
          } else {
            creds.user.sendEmailVerification();
          }

          await axios(functionBaseUrl + "/api/userMobile", {
            method: "POST",
            data: {
              uid: creds.user.uid,
              firstName: userFirstName.replace(/ /g, ""),
              lastName: userLastName.replace(/ /g, ""),
              userName:
                userFirstName.replace(/ /g, "") +
                " " +
                userLastName.replace(/ /g, ""),
              email: email.toLowerCase().replace(/ /g, ""),
              phone: phoneNumber,
              averageScore: 0,
              goals: [],
              dob: dobFormat(dateOfBirth),
              height: "",
              hipSize: "7.2",
              gender: "",
              dominantHand: "",
              picture: "",
              role: "Sportsbox-Instructor",
              weeklyPractices: 0,
              coachAddress: { city: "", state: "", country: "" },
              coachFacility: [
                {
                  name: "",
                  address: "",
                  students: 0,
                  lessons: 0,
                  environment: [],
                },
              ],
              certification: [],
              userConsent: true,
              subscriptionType: plan.includes("free")
                ? listOfSubscriptions.STUDENT_FREE
                : plan,
            },
          });

          if (plan?.includes("free")) {
            await axios(
              functionBaseUrl + "/api/updateUserNY/" + creds.user.uid,
              {
                method: "POST",
                data: {
                  userInfo: {
                    subscriptionType: listOfSubscriptions.STUDENT_FREE,
                  },
                },
              }
            );
          }

          setIsLoading(false);
          finishSignUp(
            creds.user.uid,
            email.toLowerCase().replace(/ /g, ""),
            userFirstName.replace(/ /g, "") +
              " " +
              userLastName.replace(/ /g, "")
          );
        })
        .catch((err) => {
          setIsLoading(false);
          Swal.fire({
            title: '<p style="font-size:70%;">' + err.message + "</p>",
            icon: "warning",
            confirmButtonText: "Ok",
            allowOutsideClick: false,
          });
          return;
        });
    } else {
      await axios(functionBaseUrl + "/api/userInAuth", {
        method: "POST",
        data: {
          email: email.toLowerCase().replace(/ /g, ""),
        },
      })
        .then((res) => {
          if (
            res.status === 200 &&
            res.data &&
            "subscriptionType" in res.data === true &&
            (res.data.subscriptionType === "" ||
              res.data.subscriptionType.includes("free"))
          ) {
            setIsLoading(false);
            finishSignUp(res.data.id, email.toLowerCase().replace(/ /g, ""));
          } else {
            setIsLoading(false);
            Swal.fire({
              title:
                '<p style="font-size:70%;">An account already exists with this email, please log in to continue.</p>',
              icon: "warning",
              confirmButtonText: "Ok",
              showConfirmButton: true,
              showCancelButton: true,
              allowOutsideClick: false,
              customClass: {
                container: "my-swal",
              },
            })
              .then((result) => {
                if (result.isConfirmed) {
                  window.location.href = window.location.href.replace(
                    "sign-up",
                    "sign-in?redirectTo=/plans"
                  );
                } else {
                  return null;
                }
              })
              .catch((err) => {
                console.log(err);
              });
          }
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err);
        });
    }
  };

  return (
    <>
      <Grid container className="plan-checkout">
        <Grid item xs={12} md={2} />
        <Grid item xs={12} md={8}>
          <Grid container>
            <Grid item xs={12} className="plans-step-back">
              <a href="#" onClick={() => back()}>
                <ArrowBack />
              </a>
            </Grid>
            {window.location.href.includes("trial=true") && (
              <Grid item xs={12} className="plans-signup-caption-trial">
                14-day Free Trial
              </Grid>
            )}
            <Grid item xs={12} className="plans-signup-caption">
              Account
            </Grid>
            <Grid item xs={6} className="plans-signup-form-element">
              <TextField
                fullWidth
                required
                label="First Name"
                onChange={(event) => setUserFirstName(event.target.value)}
                value={userFirstName}
                variant="outlined"
                inputProps={{ maxLength: 254 }}
              />
            </Grid>
            <Grid item xs={6} className="plans-signup-form-element">
              <TextField
                fullWidth
                required
                label="Last Name"
                onChange={(event) => setUserLastName(event.target.value)}
                value={userLastName}
                variant="outlined"
                inputProps={{ maxLength: 254 }}
              />
            </Grid>
            <Grid item xs={12} className="plans-signup-form-element">
              <TextField
                InputLabelProps={{
                  shrink: true,
                }}
                required
                fullWidth
                variant="outlined"
                label="Date of Birth"
                onChange={(event) => setDateOfBirth(event.target.value)}
                type="date"
                value={dateOfBirth}
              />
            </Grid>
            <Grid item xs={12} className="plans-signup-form-element">
              <TextField
                fullWidth
                //required
                label="Phone Number"
                onChange={(event) => setPhoneNumber(event.target.value)}
                value={phoneNumber}
                variant="outlined"
                inputProps={{ maxLength: 254 }}
              />
            </Grid>
            <Grid item xs={12} className="plans-signup-form-element">
              <TextField
                fullWidth
                required
                label="Email Address"
                onChange={(event) => setEmail(event.target.value)}
                value={email}
                variant="outlined"
                inputProps={{ maxLength: 254 }}
              />
            </Grid>
            <Grid item xs={6} className="plans-signup-form-element">
              <FormControl fullWidth variant="outlined">
                <InputLabel htmlFor="outlined-adornment-password">
                  Password *
                </InputLabel>
                <OutlinedInput
                  label="Password"
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        edge="end"
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                  required
                  id="outlined-adornment-password"
                  onChange={(event) => setPassword(event.target.value)}
                  type={showPassword ? "text" : "password"}
                  value={password}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6} className="plans-signup-form-element">
              <FormControl fullWidth variant="outlined">
                <InputLabel htmlFor="outlined-adornment-password">
                  Confirm Password *
                </InputLabel>
                <OutlinedInput
                  required
                  id="checkPassword"
                  label="Confirm Password"
                  onChange={(event) => setConfirmPassword(event.target.value)}
                  type={showPassword ? "text" : "password"}
                  value={confirmPassword}
                />
              </FormControl>
              {confirmPassword.length > 0 && password !== confirmPassword && (
                <Typography style={{ color: "red" }}>
                  The passwords do not match
                </Typography>
              )}
            </Grid>
            <Grid item xs={12} className="plans-signup-form-element">
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    value={tosAgree}
                    onClick={() => setTosAgree(!tosAgree)}
                    checked={tosAgree ? "checked" : null}
                  />
                }
                label={
                  <div>
                    <span>I have read and agree to Sportsbox's </span>
                    <Link
                      onClick={async (e) => {
                        e.preventDefault();
                        window.open(
                          window.location.href.replace(
                            "sign-up",
                            "terms-of-service"
                          )
                        );
                      }}
                    >
                      Terms of Service
                    </Link>{" "}
                    and{" "}
                    <Link
                      onClick={async (e) => {
                        e.preventDefault();
                        window.open(
                          window.location.href.replace("sign-up", "privacy")
                        );
                      }}
                    >
                      Privacy Policy.
                    </Link>
                  </div>
                }
              />
            </Grid>
            <Grid
              item
              xs={12}
              style={{ textAlign: "center" }}
              className="plans-signup-form-element"
            >
              <Button
                className="plan-button"
                disabled={
                  isLoading ||
                  tosAgree === false ||
                  email.length === 0 ||
                  userFirstName.length === 0 ||
                  userLastName.length === 0 ||
                  password.length === 0 ||
                  confirmPassword.length === 0
                }
                variant="contained"
                onClick={() => handleSignUp()}
              >
                {isLoading && (
                  <>
                    <CircularProgress size="1.2rem" />
                    &nbsp;&nbsp;
                  </>
                )}
                Get started
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={2} />
      </Grid>
    </>
  );
}

export default PlansSignupComponent;
