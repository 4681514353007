import { Dialog, DialogContent, TextField } from "@material-ui/core";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import uuid from "react-uuid";
import { FlexWrap, Pad } from "../../../../components";
import { FilledButton, OutlinedButton } from "../common/styled";
import { PopularWatchlists } from "../common/watchlist/PopularWatchlists";
import { WatchlistModalSwitcher } from "../common/WatchlistModalSwitcher";
import { WatchlistTrackerForm } from "../common/WatchlistTrackerForm";
import { ModalTitle } from "../styled";
import { WatchlistFormContainer } from "./styled";
import { PredefinedWatchlists } from "../common/watchlist/PredefinedWatchlists";

const predefinedWatchlists = [
  {
    id: 1,
    watchlistId: "CUSTOM",
    type: "default",
    name: "Custom Watchlist 1",
    description: "Predefined Custom Watchlist",
    trackers: [
      {
        indicatorType: "Default",
        trackerId: "LdWrstAngArm30",
        position: "",
        rangeMin: 99.9,
        rangeMax: 122.5,
      },
      {
        indicatorType: "Default",
        trackerId: "ChstTrnMaxNrTop",
        position: "TOP",
        rangeMin: -99.5,
        rangeMax: -89.1,
      },
      {
        indicatorType: "Default",
        trackerId: "PlvLftImp",
        position: "IMP",
        rangeMin: -0.6,
        rangeMax: 1.6,
      },
    ],
  },
];

export function WatchlistModal({ open, init, onClose, onSave, watchlistData }) {
  const [selected, setSelected] = useState([]);
  const [view, setView] = useState("popular");
  const [form, setForm] = useState({
    name: "",
    description: "",
  });
  const [trackers, setTrackers] = useState([
    {
      trackerId: "",
      position: "",
      rangeMin: null,
      rangeMax: null,
      id: 1,
    },
  ]);

  useEffect(() => {
    if (init.length !== 0) {
      setSelected(init);
    }
  }, [init]);

  useEffect(() => {
    const checkForUpdate = () => {
      let check = false;

      for (let i = 0; i < trackers.length; i++) {
        const el = trackers[i];
        if (el.trackerId && el.position && !el.rangeMin && !el.rangeMax) {
          check = true;
          break;
        }
      }
      return check;
    };

    if (checkForUpdate()) {
      trackers.map((el) => {
        if (el.trackerId && el.position) {
          setTrackers((prev) => {
            return prev.map((itr) => {
              if (itr.id === el.id) {
                const ranges = watchlistData.customWatchlists.find(
                  (trc) => trc.trackerId === el.trackerId
                );
                const { minTrackerRange, maxTrackerRange } =
                  ranges.positions.find(
                    (pos) => pos.position === el.position
                  ).ranges;

                return {
                  ...itr,
                  rangeMin: minTrackerRange,
                  rangeMax: maxTrackerRange,
                };
              } else return itr;
            });
          });
        }
      });
    }
  }, [trackers]);

  const handleSelect = (id) => {
    if (selected.includes(id)) {
      setSelected((prev) => prev.filter((el) => el !== id));
    } else {
      setSelected((prev) => [...prev, id]);
    }
  };

  const handleView = (viewName) => {
    setView(viewName);
  };

  const handleSave = () => {
    if (view === "popular") {
      const items = watchlistData.popularWatchlists.filter((el) =>
        selected.includes(el.watchlistId)
      );
      const saveData = items.map((el) => {
        return {
          name: el.name,
          description: el.description,
          watchlistId: el.watchlistId,
          type: "default",
          trackers: el.ranges.map((ind) => ({
            position: ind.position,
            trackerId: ind.indicatorId.split(":")[0],
            trackerUnit: ind.unit,
            rangeMin: ind.min,
            rangeMax: ind.max,
            indicatorType: "Default",
            processing: ind.indicatorId.split(":")[1],
          })),
          uuid: uuid(),
          createdAt: Date.now(),
          updatedAt: Date.now(),
        };
      });
      onClose();
      onSave("watchlist", saveData);
      clearState();
    } else if (view === "custom") {
      onClose();
      const mappedTrackers = trackers
        .filter((flt) => flt.trackerId && flt.position)
        .map((el) => {
          const trackerData = watchlistData.trackersData.find(
            (trc) => trc.TrackerID === el.trackerId
          );
          const positionData = watchlistData.positionsData.find(
            (pos) => pos.id === el.position
          );
          return {
            ...el,
            trackerName: `${trackerData.DisplayName} at ${positionData.title}`,
            trackerUnit: trackerData.Unit,
            indicatorType: "Custom",
          };
        });
      onSave("watchlist", [
        {
          ...form,
          trackers: mappedTrackers,
          type: "custom",
          watchlistId: "CUSTOM",
          uuid: uuid(),
        },
      ]);
      clearState();
    } else {
      const items = predefinedWatchlists.filter((el) =>
        selected.includes(el.id)
      );
      const saveData = items.map((el) => {
        return {
          name: el.name,
          description: el.description,
          watchlistId: el.watchlistId,
          type: "default",
          trackers: el.trackers,
          uuid: uuid(),
          createdAt: Date.now(),
          updatedAt: Date.now(),
        };
      });
      onClose();
      onSave("watchlist", saveData);
      clearState();
    }
  };

  const handleInputs = (e) => {
    e.persist();
    setForm((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleTrackerInputs = (e, id) => {
    e.persist();
    setTrackers((prev) => {
      return prev.map((el) => {
        if (el.id === id) {
          return {
            ...el,
            [e.target.name]: e.target.value,
            rangeMax: null,
            rangeMin: null,
          };
        } else return el;
      });
    });
  };

  const handleAddTracker = () => {
    setTrackers((prev) => [
      ...prev,
      {
        trackerId: "",
        position: "",
        rangeMin: null,
        rangeMax: null,
        id: uuid(),
      },
    ]);
  };

  const handleRemoveTracker = (id) => {
    setTrackers((prev) => prev.filter((el) => el.id !== id));
  };

  const clearState = () => {
    setTrackers([
      {
        trackerId: "",
        position: "",
        rangeMin: null,
        rangeMax: null,
        id: 1,
      },
    ]);
    setForm({
      name: "",
      description: "",
    });
    setSelected([]);
  };

  const handleClose = () => {
    onClose();
    clearState();
  };

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={handleClose}>
      <DialogContent style={{ padding: "30px" }}>
        <ModalTitle>Add watchlist trackers</ModalTitle>
        <WatchlistModalSwitcher selected={view} onSelect={handleView} />

        <div style={{ display: view === "popular" ? "block" : "none" }}>
          <div
            style={{
              maxHeight: "650px",
              margin: "10px 0",
              display: "flex",
              overflowY: "scroll",
            }}
          >
            <Pad margin="20px 0" width="100%">
              <PopularWatchlists
                data={watchlistData.popularWatchlists}
                selected={selected}
                onSelect={handleSelect}
              />
            </Pad>
          </div>
        </div>

        <div style={{ display: view === "predefined" ? "block" : "none" }}>
          <div
            style={{
              maxHeight: "650px",
              margin: "10px 0",
              display: "flex",
              overflowY: "scroll",
            }}
          >
            <Pad margin="20px 0" width="100%">
              <PredefinedWatchlists
                data={predefinedWatchlists}
                selected={selected}
                onSelect={handleSelect}
              />
            </Pad>
          </div>
        </div>

        <div style={{ display: view === "create" ? "block" : "none" }}>
          <WatchlistFormContainer>
            <TextField
              style={{ background: "#FFF" }}
              value={form.name}
              onChange={handleInputs}
              variant="outlined"
              name="name"
              label="Title"
              fullWidth
            />
            <Pad height="10px" />
            <TextField
              style={{ background: "#FFF" }}
              value={form.description}
              onChange={handleInputs}
              variant="outlined"
              name="description"
              label="Goal"
              multiline
              rows={3}
              fullWidth
            />
            <Pad height="10px" />
            {trackers.map((el) => (
              <WatchlistTrackerForm
                watchlistData={watchlistData.customWatchlists}
                form={el}
                onChange={handleTrackerInputs}
                onRemove={handleRemoveTracker}
              />
            ))}
            <OutlinedButton onClick={handleAddTracker} color="#580CE3">
              Add Tracker
            </OutlinedButton>
          </WatchlistFormContainer>
        </div>

        <FlexWrap justify="space-between">
          <FilledButton onClick={handleClose} width="48%" color="#808080">
            cancel
          </FilledButton>
          <FilledButton
            onClick={() => handleSave()}
            width="48%"
            color="#580CE3"
          >
            add
          </FilledButton>
        </FlexWrap>
      </DialogContent>
    </Dialog>
  );
}
