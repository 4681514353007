import React, { useState } from "react";
import { TableSorterRow } from "../common/TableSorterRow";
import { AlbumRow } from "./AlbumRow";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

export function DraggableAlbumRow({
  items,
  sort,
  handleSort,
  onEdit,
  onDelete,
  onCopy,
  onReorder,
}) {
  const [itemsState, setItemsState] = useState(items);

  const onDragEnd = (result) => {
    const arr = Array.from(itemsState);
    const startIndex = result.source.index;
    const endIndex = result.destination.index;
    const [removed] = arr.splice(startIndex, 1);
    arr.splice(endIndex, 0, removed);
    setItemsState(arr);
    onReorder(arr.map((el) => el.id));
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <TableSorterRow type="albums" sort={sort} onSort={handleSort} />
      <Droppable droppableId={`album-practice-guide-list`}>
        {(provided) => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            {itemsState.map((el, index) => (
              <Draggable key={el.id} draggableId={el.id} index={index}>
                {(provided) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                  >
                    <AlbumRow
                      onEdit={() => onEdit(el)}
                      onDelete={() => onDelete(el)}
                      onCopy={() => onCopy(el)}
                      data={el}
                      key={el.id}
                    />
                  </div>
                )}
              </Draggable>
            ))}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}
