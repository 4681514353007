import React, { forwardRef } from 'react';

import {
  AddBox,
  ArrowDownward,
  Check,
  ChevronLeft,
  ChevronRight,
  Clear,
  DeleteOutline,
  Edit,
  FilterList,
  FirstPage,
  LastPage,
  Remove,
  SaveAlt,
  Search,
  ViewColumn,
  Receipt,
} from '@material-ui/icons';
import MaterialTable from 'material-table';
import Swal from 'sweetalert2';
import moment from 'moment';

import { functionBaseUrl, axiosWithToken } from '../../../common/firebase';

const tableHeader = [
  {
    field: 'name',
    title: 'Name',
    render: rowData => {
      return `${rowData.firstName} ${rowData.lastName}`;
    },
  },
  { field: 'email', title: 'Email Address' },
  { field: 'students.length', title: 'Students' },
  { field: 'sessionIds.length', title: 'Swings' },
];

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  Receipt: forwardRef((props, ref) => <Receipt {...props} ref={ref} />),
};

function StudioCoachListComponent({ accounts }) {
  const handleDelete = async (data) => {
    console.log(data)
    Swal.fire({
      title: `<p style="font-size:70%;">Are you sure you want to remove ${data?.firstName} ${data?.lastName} (${data?.email})?</p>`,
      icon: 'warning',
      confirmButtonText: 'Yes',
      showCancelButton: true,
      cancelButtonText: 'No',
      cancelButtonColor: 'red',
      allowOutsideClick: false,
      customClass: {
        container : 'my-swal'
      }
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await axiosWithToken(`${functionBaseUrl}/api/studioCoach/` + data.uid, {
            method: 'DELETE',
          });
          Swal.fire({
            title: '<p style="font-size:70%;">Removed successfully</p>',
            icon: 'success',
            confirmButtonText: 'Ok',
            allowOutsideClick: false,
            timer: 2000
          }).then(() => {
            window.location.reload();
          })
        } catch (e) {
          Swal.fire('<p style="font-size:70%;">Error occured</p>');
        }
      }
    });
  }

  return (<div>
    <MaterialTable
      style={{ marginTop: '10px', marginBottom: '10px' }}
      icons={tableIcons}
      columns={tableHeader}
      title=""
      data={accounts}
      options={{
        selection: false,
        sorting: true,
        search: true,
        paging: true,
      }}
      actions={[rowData => ({
        tooltip: 'Remove',
        icon: tableIcons.Delete,
        disabled: rowData.subscriptionType.includes('studio_master') || rowData.role === "admin",
        onClick: () => { handleDelete(rowData); },
      })]}
    />
  </div>);
}

export const StudioCoachList = StudioCoachListComponent;