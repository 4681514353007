import React from "react";
import { Pad } from "../../../components";

export function RevenueStats({ data }) {
  return (
    <div
      style={{
        padding: "15px 20px",
        boxSizing: "border-box",
      }}
    >
      <div
        style={{
          fontSize: "22px",
        }}
      >
        Revenue Statistics
      </div>
      <Pad height="10px" />
      <div
        style={{ display: "flex", flexDirection: "column", fontSize: "18px" }}
      >
        <div>Last Month: {data.lastMonth}</div>
        <div>Last Year: {data.lastYear}</div>
        <div>Historically: {data.historically}</div>
      </div>
    </div>
  );
}
