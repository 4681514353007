import React from "react";

export const ReportIndicatorCard = ({
  percentage,
  indicatorName,
  indicatorValue,
  tourRange,
  zScore,
  color,
  showAdditionalData,
  unit,
  showIcons,
}) => {
  const colorsMapped = {
    red: "#FFFBFA",
    green: "#F6FEF9",
    orange: "#FFFCF5",
  };

  const valueColorsMapped = {
    red: "#B42318",
    green: "#027A48",
    orange: "#B54708",
  };

  const iconColorsMapped = {
    red: "/images/pdf-report/red.svg",
    redUp: "/images/pdf-report/red-up.svg",
    green: "/images/pdf-report/green.svg",
    orange: "/images/pdf-report/orange.svg",
    orangeUp: "/images/pdf-report/orange-up.svg",
  };

  const renderUnitType = (unit, value) => {
    if (value === "n/a") return value;
    const parsedUnit = unit ? unit.toLowerCase() : "";
    const parsedValue = value ? +value : 0;

    // Convert the value based on the unit
    const convertedValue = value;

    switch (parsedUnit) {
      case "degrees":
        return `${convertedValue.toFixed()}°`;
      case "inches":
        return `${convertedValue.toFixed(1)}"`;
      case "mm":
        return `${convertedValue.toFixed()} mm`;
      case "seconds":
        return `${convertedValue.toFixed(2)} sec`;
      case "ratio":
        return `${convertedValue.toFixed(2)} ratio`;
      case "spdhgt":
        return `${convertedValue.toFixed(2)} ratio`;
      case "ms":
        return `${convertedValue.toFixed()} ms`;
      case "percent":
        return `${convertedValue.toFixed()}%`;
      case "mph":
        return `${convertedValue.toFixed(1)} mph`;
      case "m/s/s":
        return `${convertedValue.toFixed(2)} m/s²`;
      case "g":
      case "gs":
        return `${convertedValue.toFixed(1)} g`;
      case "degrees/second":
        return `${convertedValue.toFixed()} d/s`;
      case "order":
        return `${convertedValue.toFixed()}`;
      default:
        return convertedValue.toFixed();
    }
  };

  const renderIcon = (zScore, color) => {
    switch (color) {
      case "green":
        return iconColorsMapped.green;
      case "orange": {
        if (zScore < 0) {
          return iconColorsMapped.orange;
        } else {
          return iconColorsMapped.orangeUp;
        }
      }
      case "red": {
        if (zScore < 0) {
          return iconColorsMapped.red;
        } else {
          return iconColorsMapped.redUp;
        }
      }
      default:
        return iconColorsMapped.green;
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "5px",
        border: "#D2D6DB 1px solid",
        borderRadius: "6px",
        background: colorsMapped[color],
        width: "150px",
      }}
    >
      <div style={{ marginBottom: "5px" }}>
        <span style={{ fontWeight: "bold" }}>
          {percentage > 100 ? 100 : percentage < 0 ? 0 : percentage.toFixed(0)}
        </span>
        /100
      </div>
      <div
        style={{
          fontSize: indicatorName.length < 20 ? "10px" : "9px",
          fontWeight: "bold",
          textAlign: "center",
        }}
      >
        {indicatorName}
      </div>
      <div
        style={{
          display: "flex",
          gap: "5px",
          alignItems: "center",
          padding: "10px 0 10px",
        }}
      >
        {(color === "green" || color === "orange" || color === "red") &&
        showIcons ? (
          <img
            style={{ height: "14px", width: "14px" }}
            src={renderIcon(zScore, color)}
            alt="logo"
          />
        ) : (
          <div></div>
        )}

        <div
          style={{
            width: "100%",
            fontSize: "14px",
            fontWeight: "bold",
            textAlign: "center",
            color: valueColorsMapped[color],
          }}
        >
          {renderUnitType(unit, indicatorValue)}
        </div>
      </div>

      {showAdditionalData && (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              gap: "10px",
              width: "100%",
              marginBottom: "10px",
              borderTop: "#D2D6DB 1px dashed",
              paddingTop: "10px",
            }}
          >
            <div>Tour Range</div>
            <div style={{ color: "#6C737F" }}>{tourRange}</div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              gap: "10px",
              width: "100%",
            }}
          >
            <div>Z Score</div>
            <div style={{ color: "#6C737F" }}>{zScore}</div>
          </div>
        </>
      )}

      {!showAdditionalData && !indicatorName.includes("Order") && (
        <div style={{ color: "#6C737F" }}>Std. Dev</div>
      )}
    </div>
  );
};
