import React from 'react';

import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';

import { Topbar } from './components';


const useStyles = makeStyles(() => ({
  root: {
    paddingTop: 64,
    height: '100%',
    minHeight: '100vh',
    background: 'no-repeat url("/images/offer/background.png"), linear-gradient(180deg, #06092F 0%, #06092F 100%)',
    ['@media (max-width:1024px)']: {
      background: '#06092F',
      paddingTop: 15,
    }
  },
  content: {
    height: '100%',
  },
}));

const Offer = (props) => {
  const { children } = props;

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Topbar />
      <main className={classes.content}>{children}</main>
    </div>
  );
};

Offer.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export default Offer;