import React from 'react';
import { withStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  TextField,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Typography
} from '@material-ui/core';
import { functionBaseUrl, axiosWithToken } from '../../../../common/firebase';
import firebase from '../../../../common/firebase';
import 'firebase/firestore';
import Swal from 'sweetalert2';


const useStyles = () => ({
  root: {},
  number: {borderColor: "#ffffff",}
});

class SessionList extends React.Component {
  constructor (props) {
    super(props)
    const currUser = firebase.auth().currentUser;

    this.state = {
      uid: currUser.uid,
      coachAddress: {},
      coachFacility: {},
      certification: '',
      city: '',
      state: '',
      country: '',
      name: '',
      address: '',
      students: '',
      lessons: '',
      environment: [],
    }
  }

  componentDidMount() {
    if (this.state.uid && this.props.coach.role) {
      this.setState({
        city: this.props.coach.coachAddress.city,
        state: this.props.coach.coachAddress.state,
        country: this.props.coach.coachAddress.country,
        name: this.props.coach.coachFacility.length > 0 ? this.props.coach.coachFacility[0].name : '',
        address: this.props.coach.coachFacility.length > 0 ? this.props.coach.coachFacility[0].address : '',
        students: this.props.coach.coachFacility.length > 0 ? this.props.coach.coachFacility[0].students : 0,
        lessons: this.props.coach.coachFacility.length > 0 ? this.props.coach.coachFacility[0].lessons : 0,
        environment: this.props.coach.coachFacility.length > 0 ? this.props.coach.coachFacility[0].environment : [],
        certification: this.props.coach.certification,
        loading: false
      })
    }
    /*if(this.state.uid) {
      axiosWithToken(functionBaseUrl+'/api/users/' + this.state.uid, {
        method: 'get',
      }).then(response => {
        this.setState({
          city: response.data.coachAddress.city,
          state: response.data.coachAddress.state,
          country: response.data.coachAddress.country,
          name: response.data.coachFacility[0].name,
          address: response.data.coachFacility[0].address,
          students: response.data.coachFacility[0].students,
          lessons: response.data.coachFacility[0].lessons,
          environment: response.data.coachFacility[0].environment,
          certification: response.data.certification,
          loading: false
        })
      })
    }*/
  }

  handleChange = event => {
    if(event.target.name === 'environment') {
      this.setState({      
        [event.target.name]: [event.target.value]
      });
    } else if(event.target.name === 'certification') {
      //console.log(event.target.value.split(/[, ]+/))
      this.setState({      
        [event.target.name]: event.target.value.replaceAll(', ', ',').split(',')
      });
    } else {
      this.setState({      
        [event.target.name]: event.target.value
      });
  }
  }

  handleContactChange = (event) => {
    this.setState({ contactNumber: event });
  }

  handleFieldChange = (event) => {
    event.persist();
    this.state(
        event.target.type === "checkbox"
          ? event.target.checked
          : event.target.value
    );
  };

  titleCase = (str) => {
    if(str) {
      str = str.replaceAll('-', ' ');
      var splitStr = str.toLowerCase().split(' ');
      for (var i = 0; i < splitStr.length; i++) {
          splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);     
      }
      return splitStr.join(' '); 
    } else {
      return ""
    }
  }

  handleSubmit = event => {
    event.preventDefault()
    axiosWithToken(functionBaseUrl+'/api/users/'+ this.state.uid, {
      method: 'patch',
      data: {
        userInfo: {
          coachAddress: {
            city: this.state.city,
            state: this.state.state,
            country: this.state.country
          },
          coachFacility: [{
            name: this.state.name,
            address: this.state.address,
            students: Number(this.state.students),
            lessons: Number(this.state.lessons),
            environment: this.state.environment
          }],
          certification: this.state.certification
        }
      }
    })
    .then(() => {
      Swal.fire({
        title: '<p style="font-size:70%;">Your changes have been saved!</p>',
        icon: 'success',
        confirmButtonText: 'Ok',
        allowOutsideClick: false,
        timer: 2000
      }).then(() => {
        window.location.reload()
      })
    })
    .catch(err => {        
      alert('There was an error updating your info')
      console.log(err);
    });
  }
  
  render () {
    return (
      <div>
        <Card style={{maxWidth:400}}>
          <form
            //autoComplete="off"
            //noValidate
            onSubmit = {this.handleSubmit}
          >
            <CardHeader
              subheader="This information can be edited"
              title="Instructor"
              titleTypographyProps={{variant:"h5", color:"primary"}}
            />
            <Divider />
            <CardContent>
              <Grid
                container
                spacing={1}
              >
                <Grid
                  item
                  md={12}
                  xs={12}
                >
                  <Typography
                    color="textSecondary"
                    variant="body1"
                    marginTop="theme.spacing(1)"
                    >
                      <b>Address:</b>
                  </Typography>
                  <div>
                    <TextField                
                      label="City"
                      margin="dense"
                      name="city"
                      style={{minWidth:200}}
                      onChange={this.handleChange}
                      variant="outlined"                 
                      value={this.state.city}
                    />
                    <TextField                
                      label="State"
                      margin="dense"
                      name="state"
                      style={{maxWidth:100}}
                      onChange={this.handleChange}
                      variant="outlined"                 
                      value={this.state.state}
                    />
                  </div>
                  <div>
                    <TextField
                      label="Country"
                      margin="dense"
                      name="country"
                      style={{minWidth:200}}
                      onChange={this.handleChange}
                      variant="outlined"
                      value={this.state.country}
                    />
                  </div>

                  <Typography
                    color="textSecondary"
                    variant="body1"
                    marginTop="theme.spacing(1)"
                    >
                      <b>Facility:</b>
                  </Typography>
                  <div>
                    <TextField                
                      label="Name"
                      margin="dense"
                      name="name"
                      fullWidth
                      onChange={this.handleChange}
                      variant="outlined"                 
                      value={this.state.name}
                    />
                    <TextField                
                      label="Address"
                      margin="dense"
                      name="address"
                      fullWidth
                      onChange={this.handleChange}
                      variant="outlined"           
                      value={this.state.address}
                    />
                  </div>
                  <div>
                    <TextField
                      label="Students"
                      margin="dense"
                      name="students"
                      style={{width:"50%"}}
                      onChange={this.handleChange}
                      variant="outlined"
                      type='number'
                      inputProps={{ min: 0 }}
                      value={this.state.students}
                    />
                    <TextField
                      label="Lessons/week"
                      margin="dense"
                      name="lessons"
                      style={{width:"50%"}}
                      onChange={this.handleChange}
                      variant="outlined"
                      type='number'
                      inputProps={{ min: 0 }}
                      value={this.state.lessons}
                    />
                  </div>
                  <div>
                  <FormControl              
                    variant="outlined"
                    style={{minWidth: 150}}
                    margin="dense"
                    >
                      <InputLabel id="environment-select">Environment</InputLabel>
                      <Select
                        id="environment"
                        label="Environment"
                        labelId="environment-select"
                        name="environment"
                        value={this.state.environment ? this.state.environment.toString() : ""}
                        onChange={this.handleChange}
                      >
                        
                        <MenuItem value={'Indoor'}>Indoors</MenuItem>
                        <MenuItem value={'Outdoor'}>Outdoors</MenuItem>
                      </Select>
                    </FormControl>
                  </div>

                  <Typography
                    color="textSecondary"
                    variant="body1"
                    marginTop="theme.spacing(1)"
                    >
                      <b>Certification(s):</b>
                  </Typography>
                  <div>
                    <TextField                
                      label="Certification"
                      margin="dense"
                      name="certification"
                      fullWidth
                      onChange={this.handleChange}
                      variant="outlined"                 
                      value={this.state.certification ? this.state.certification.toString() : ""}
                    />
                  </div>
                </Grid>                      
              </Grid>
            </CardContent>        
            <CardActions>
              <Button
                color="primary"
                variant="outlined"
                type="submit"
                //onClick={this.handleSave}
              >
                Save details
              </Button>
            </CardActions>
          </form>
        </Card>
      </div>
    );
  };
}

export default withStyles(useStyles)(SessionList);