import React, { useEffect, useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Avatar, Button, Card, CardHeader, CardContent, Container, Divider, Grid, 
  Typography, useMediaQuery } from '@material-ui/core';
import firebase, { functionBaseUrl, axiosWithToken } from '../../../common/firebase';
import { AddEnterpriseInstructor } from './AddEnterpriseInstructor';
import { EnterpriseInstructorsList } from './EnterpriseInstructorsList';
import Swal from 'sweetalert2';
import NoSubscriptionDialog from '../../../components/NoSubscriptionDialog';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    minHeight: 'fit-content',
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    //flexWrap: 'wrap',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
    },
  },
  text: {
    fontFamily: 'Manrope',
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '24px',
    letterSpacing: '0em',
    textAlign: 'left',
  },
  divider: {
    margin: theme.spacing(3, 0),
  },
  vertDivider: {
    margin: theme.spacing(2, 0),
    //padding: theme.spacing(0, 2),
    marginLeft: 30,
    marginRight: 30,
  },
  heading: {
    fontFamily: 'Manrope',
    fontSize: '24px',
    fontWeight: 600,
    lineHeight: '20px',
    letterSpacing: '0em',
    textAlign: 'left',
    marginBottom: 15,
  },
  card: {
    width: '30%',
    minWidth: '30%',
    maxWidth: '300px',
    height: '150px',
    borderRadius: '12px',
    border: '1px solid #EAECF0',
    padding: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      maxWidth: '100%',
    },
  },
  cardText: {
    fontFamily: 'Manrope',
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '20px',
    letterSpacing: '0em',
    textAlign: 'left',
    //marginBottom: 10,
    //color: 'white',
  },
  displaySm: {
    fontFamily: 'Manrope',
    fontSize: '30px',
    fontWeight: 600,
    lineHeight: '38px',
    letterSpacing: '0em',
    textAlign: 'left',
  },
  displayMd: {
    fontFamily: 'Manrope',
    fontSize: '36px',
    fontWeight: 600,
    lineHeight: '44px',
    letterSpacing: '-0.02em',
    textAlign: 'left',
    marginRight: 10,
  },
  smallCard: {
    width: '200px',
    //maxWidth: '200px',
    height: '120px',
    borderRadius: '12px',
    border: '1px solid #EAECF0',
    padding: theme.spacing(2.5),
    marginRight: '3%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      maxWidth: '48%',
      marginRight: '2%',
      marginBottom: '2%',
    },
  },
  button: {
    width: '100%',
    height: '36px',
    padding: '8px 14px',
    borderRadius: '64px',
    border: '1px solid #E9DDFD',
    gap: '8px',
    background: '#E9DDFD',
    textTransform: 'none',
  },
  coachButton: {
    width: 'fit-content',
    height: '36px',
    padding: '8px 14px',
    borderRadius: '64px',
    gap: '8px',
    background: '#F2F4F7',
    textTransform: 'none',
    marginBottom: 15,
  },
}));

function EnterpriseInstructorsComponent() {
  const [isLoading, setIsLoading] = useState(true);
  const [isAddPopupOpened, setIsAddPopupOpened] = useState(false);
  const [accounts, setAccounts] = useState([]);
  const [currentUser, setCurrentUser] = useState([]);
  const [activeSub, setActiveSub] = useState(true);
  const [role, setRole] = useState('');
  const [isStaffEnterprise, setIsStaffEnterprise] = useState(false);
  const [isCertified, setIsCertified] = useState(true);
  const [onboardingCompleted, setOnboardingCompleted] = useState(true);
  const [dialogLoading, setDialogLoading] = useState(true);
  const [sessions, setSessions] = useState({});
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
    defaultMatches: true,
  });

  const openAddPopup = () => {
    if (accounts?.length >= currentUser?.coachesCount) {
      Swal.fire({
        title: '<p style="font-size:70%;">You have reached the maximum limit of Instructor accounts that can be added for your subscription</p>',
        icon: 'warning',
        confirmButtonText: 'Ok',
        allowOutsideClick: false,
        timer: 5000,
        customClass : { 
          container : 'my-swal' 
        },
      })
      return false;
    }
    setIsAddPopupOpened(true);
  }

  const closeAddPopup = () => {
    setIsAddPopupOpened(false);
    loadData();
  }

  const loadData = async () => {
    setIsLoading(true);

    const firebaseUser = firebase.auth().currentUser;
    const uid = firebaseUser.uid;
    const user = await axiosWithToken(`${functionBaseUrl}/api/usersEnterprise/${uid}`, {
      method: 'get',
    });
    setCurrentUser(user.data?.userInfo);

    const currUser = await axiosWithToken(`${functionBaseUrl}/api/users/${uid}`, {
      method: 'get',
    });
    setRole(currUser.data.role)

    if (!currUser.data.subscriptionType.includes('free')) {
      const sub = await axiosWithToken(`${functionBaseUrl}/api/verifySub/${uid}`, {
        method: 'post',
      });
      setActiveSub(sub.data.success);
    }
    setDialogLoading(false);

    const response = await axiosWithToken(`${functionBaseUrl}/api/usersEnterprise/instructors/${currUser?.data.enterpriseAccountId}`, {
      method: 'get',
    });
    let allAccounts = [currUser.data, ...response.data];
    setAccounts(allAccounts);

    const sessionsCount = await axiosWithToken(`${functionBaseUrl}/api/v1/swingsCount/${uid}`, {
      method: 'get',
    });
    setSessions(sessionsCount?.data);
    setIsLoading(false);
  }

  useEffect(() => {
    loadData();
  }, []);

  const classes = useStyles();

  return (
    <Container
      component="main"
      style={{ paddingTop: '20px' }}
    >
      <div className={classes.root}>
        <Typography          
          variant="h4"
          className={classes.heading}
        >
          Coaches
        </Typography>
        <Typography          
          variant="body2"
          className={classes.text}
        >
          Check and manage your enterprise account.
        </Typography>
        
      </div>
      <Divider className={classes.divider} />
      <div className={classes.container}>
        <Card className={classes.card}>
          <Typography
            variant="h4"
            className={classes.cardText}
          >
            Mobile coach licenses
          </Typography>

          <div className={classes.container}>
            <Typography
              className={classes.displayMd}
            >
              {(currentUser?.coaches?.length + 1) || 0}/{currentUser?.coachesCount || 0}
            </Typography>

            {/*<Typography
              className={classes.displaySm}
            >
              remaining
            </Typography>*/}
          </div>

          <Typography
            className={classes.text}
          >
            Need more seats? We got you.
          </Typography>
          
          <a href="mailto:sales@sportsbox.ai">
            <Button className={classes.button}><Typography className={classes.cardText}>Contact Sales</Typography></Button>
          </a>
        </Card>

        {isDesktop && (<Divider orientation="vertical" flexItem className={classes.vertDivider} />)}

        <div className={classes.root} style={{ marginTop: isDesktop ? 0 : '5%' }}>
          <Typography
            className={classes.heading}
          >
            <div className={classes.container}>
              <img src="/images/icons/sessions.png" />
              Sessions
            </div>
          </Typography>
          <div className={classes.container}>
            <Card className={classes.smallCard}>
              <Typography
                variant="h4"
                className={classes.cardText}
              >
                Last 24 hours
              </Typography>

              <Typography
                className={classes.displayMd}
                style={{ marginTop: 10 }}
              >
                {sessions?.swingsLast24Hours || 0}
              </Typography>
            </Card>

            <Card className={classes.smallCard}>
              <Typography
                variant="h4"
                className={classes.cardText}
              >
                Last 7 days
              </Typography>

              <Typography
                className={classes.displayMd}
                style={{ marginTop: 10 }}
              >
                {sessions?.swingsLast7Days || 0}
              </Typography>
            </Card>

            <Card className={classes.smallCard}>
              <Typography
                variant="h4"
                className={classes.cardText}
              >
                Last month
              </Typography>

              <Typography
                className={classes.displayMd}
                style={{ marginTop: 10 }}
              >
                {sessions?.swingsLastMonth || 0}
              </Typography>
            </Card>

            <Card className={classes.smallCard}>
              <Typography
                variant="h4"
                className={classes.cardText}
              >
                All time
              </Typography>

              <Typography
                className={classes.displayMd}
                style={{ marginTop: 10 }}
              >
                {sessions?.swingsCount || 0}
              </Typography>
            </Card>
          </div>
        </div>
      </div>
      <Divider className={classes.divider}/>
      {/*<Grid item xs={12}>*/}
        <Button className={classes.coachButton} onClick={() => openAddPopup()}>
          <Typography
            className={classes.cardText}
          >
            <div className={classes.container}>
              <img src="/images/icons/plus.png" />{' '}
              Add coach
            </div>
          </Typography>
        </Button>
        <EnterpriseInstructorsList accounts={accounts} />
        <AddEnterpriseInstructor
          open={isAddPopupOpened}
          close={closeAddPopup}
        />
      {/*</Grid>*/}
    </Container>
  );
}

export const EnterpriseInstructors = EnterpriseInstructorsComponent;