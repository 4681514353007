import React, { useState, useEffect } from 'react';
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { Button, CircularProgress, Grid, TextField, Typography, Box, Card, CardContent,
  FormControlLabel, Checkbox } from '@material-ui/core';
import Swal from 'sweetalert2';
import axios from 'axios';
import moment from 'moment';
import { axiosWithToken, functionBaseUrl, stripeKey } from '../../common/firebase';
import { getPlan } from '../../common/plans';
import { StripeSubscriptionForm } from './stripeSubscriptionForm';

const stripePromise = loadStripe(stripeKey);

function StripeSubscriptionComponent({ email, productId, buttonText, handleResult, startDate, hideCoupon, freeTrial, trialEnd, showDetails, amount, fullName, handlePrice, addOn }) {
  const [coupon, setCoupon] = useState("");
  const [clientSecret, setClientSecret] = useState("");
  const [subscriptionAmount, setSubscriptionAmount] = useState(-1);
  const [discount, setDiscount] = useState(0);
  const [discountType, setDiscountType] = useState('');
  const [subscriptionId, setSubscriptionId] = useState('');
  const [message, setMessage] = useState(false);
  const [couponSuccess, setCouponSuccess] = useState(false);
  const [useSavedCard, setUseSavedCard] = useState(null);
  const [card, setCard] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");
  const freeTrialPromoCodes = ['5IRON3D', 'SB3DDryvebox', 'SB3DPNGC', 'MICHELLE'];
  const planPrices = ['15.99', '79.99', '110.00', '799.99'];
  if (hideCoupon === null || hideCoupon === undefined) {
    hideCoupon = false;
  }
  if (showDetails === null || showDetails === undefined) {
    showDetails = true;
  }

  const handleCoupon = (e) => {
    setCoupon(e.target.value.toUpperCase());
    localStorage.setItem('promoCode', e.target.value.toUpperCase());
  }

  const applyCoupon = () => {
    if (freeTrialPromoCodes.includes(coupon)) {
      setMessage(true);
    } else {
      setMessage(false);
    }
    localStorage.setItem('promoCode', coupon);
    init();
  }

  const init = async () => {
    try {
      if (useSavedCard || useSavedCard === null) {
        const responseMethods = await axios(functionBaseUrl + '/api/stripePaymentMethods', {
          method: 'POST',
          data: { email: email },
        })
          .catch((error) => {
            console.log(error);
          });

        if (responseMethods?.data?.paymentMethods?.data?.length > 0) {
          let cardData = responseMethods?.data?.paymentMethods?.data[0];
          const cardExpiry = moment(cardData?.card?.exp_month + '/' + cardData?.card?.exp_year, 'MM/YYYY').valueOf();
          if (cardExpiry > moment().valueOf()) {
            setCard(cardData?.card?.brand?.toUpperCase() + ' •••• ' + cardData?.card?.last4);
            setUseSavedCard(true);
            setPaymentMethod(cardData?.id);
          } else {
            setUseSavedCard(false);
          }
        }
      }

      let input = { email, productId, coupon, addOn }

      await axiosWithToken(`${functionBaseUrl}/api/searchUserByEmail`, {
        method: 'POST',
        data: { email: email.toLowerCase() }
      })
        .then((response) => {
          if (response?.data?.length > 0 && response?.data[0]?.golfpad && productId?.includes("student_lite")) {
            //setCoupon("GP1876");
            input.coupon = "GP1876";
          }
        })
        .catch((error) => {
          console.log(error);
        });

      if (startDate) {
        input.startDate = startDate;
      }
      if (freeTrial) {
        input.freeTrial = freeTrial;
        let offer = window.location.href;
        if (offer.includes('/offer') && productId.includes('student_lite_annually')) {
          input.coupon = 'EFbQ8xhy';
        }
      }
      if (trialEnd) {
        input.trialEnd = trialEnd
        if (coupon.length === 0) {
          if (productId.includes('student_lite')) {
            input.coupon = '0V7yx6Tl';
          }
        }
      }
      if (window.location.href.includes("/mark-crossfield") && productId.includes('student_lite')) {
        input.coupon = 'aNRQuT1C';
      }
      if (freeTrialPromoCodes.includes(coupon)) {
        if (productId.includes('student_lite')) {
          input.coupon = '';
          input.freeTrial = 31;
          localStorage.setItem('promoCode', coupon);
        } else {
          Swal.fire({
            title: '<p style="font-size:70%;">This coupon code is not applicable to the selected plan</p>',
            icon: 'warning',
            confirmButtonText: 'Ok',
            allowOutsideClick: true,
            customClass : { 
              container : 'my-swal' 
            },
          });
          return;
        }
      }
      let couponCode = window.location.href;
      if (couponCode.includes("couponCode=")) {
        couponCode = couponCode.split("couponCode=")[1];
        if (freeTrialPromoCodes.includes(couponCode)) {
          if (productId.includes('student_lite')) {
            setCoupon(couponCode);
            input.coupon = '';
            input.freeTrial = 31;
            setMessage(true);
            localStorage.setItem('promoCode', couponCode);
          } else {
            Swal.fire({
              title: '<p style="font-size:70%;">This coupon code is not applicable to the selected plan</p>',
              icon: 'warning',
              confirmButtonText: 'Ok',
              allowOutsideClick: true,
              customClass : { 
                container : 'my-swal' 
              },
            });
            return;
          }
        } else {
          input.coupon = couponCode;
          setCoupon(couponCode);
          localStorage.setItem('promoCode', couponCode);
        }
      }
      const endPoint = productId.includes('foresight') ? '/api/stripePartnerSub' : '/api/stripeCreateSubscription';
      const response = await axios(functionBaseUrl + endPoint, {
        method: 'POST',
        data: input,
      })
        .catch((error) => {
          console.log(error);
        });

      if (response.status !== 200) {
        Swal.fire({
          title: '<p style="font-size:70%;">' + response.data.message + '</p>',
          icon: 'warning',
          confirmButtonText: 'Ok',
          allowOutsideClick: true,
          customClass : { 
            container : 'my-swal' 
          },
        });
        return;
      }
      setClientSecret(response.data.clientSecret);
      setSubscriptionId(response.data.subscription.id);
      if (response.data.subscription.latest_invoice?.amount_due) {
        setSubscriptionAmount((response.data.subscription.latest_invoice?.amount_due / 100).toFixed(2));
        if (handlePrice) {
          handlePrice((response.data.subscription.latest_invoice?.amount_due / 100).toFixed(2));
        }
      } else {
        setSubscriptionAmount(0);
        if (handlePrice) {
          handlePrice(0);
        }
      }
      if (coupon && !response?.data?.percentOff && !response?.data?.amountOff && planPrices.includes((response.data.subscription.latest_invoice.amount_due / 100).toFixed(2)) && !freeTrialPromoCodes.includes(coupon)) {
        Swal.fire({
          title: '<p style="font-size:70%;">Invalid coupon</p>',
          icon: 'warning',
          confirmButtonText: 'Ok',
          allowOutsideClick: true,
          customClass : { 
            container : 'my-swal' 
          },
        });
      } else {
        if (response.data.percentOff) {
          setDiscount(response.data.percentOff);
          setDiscountType('percent');
        } else if (response.data.amountOff) {
          setDiscount(response.data.amountOff);
          setDiscountType('amount');
        } else {
          setDiscount(0);
          if (coupon && !freeTrialPromoCodes.includes(coupon)) {
            Swal.fire({
              title: '<p style="font-size:70%;">Invalid coupon</p>',
              icon: 'warning',
              confirmButtonText: 'Ok',
              allowOutsideClick: true,
              customClass : { 
                container : 'my-swal' 
              },
            });
          }
        }
        if (coupon && !planPrices.includes((response.data.subscription.latest_invoice.amount_due / 100).toFixed(2))) {
          setCouponSuccess(true);
        }
      }
      if (!response.data.clientSecret) {
        Swal.fire({
          title: '<p style="font-size:70%;">' + response.data.message + '</p>',
          icon: 'warning',
          confirmButtonText: 'Ok',
          allowOutsideClick: true,
          customClass : { 
            container : 'my-swal' 
          },
        });
      }
    } catch (error) {
      Swal.fire({
        title: '<p style="font-size:70%;">An unexpected error occurred. Please try again.</p>',
        icon: 'warning',
        confirmButtonText: 'Ok',
        allowOutsideClick: true,
        customClass : { 
          container : 'my-swal' 
        },
      });
      return;
    }
  }

  const subscribe = async () => {
    try {
      let input = { email, productId, coupon, paymentMethod, addOn }
      if (startDate) {
        input.startDate = startDate;
      }
      if (freeTrial) {
        input.freeTrial = freeTrial;
      }
      if (trialEnd) {
        input.trialEnd = trialEnd;
      }
      if (freeTrialPromoCodes.includes(coupon)) {
        if (productId.includes('student_lite')) {
          input.coupon = '';
          input.freeTrial = 31;
          localStorage.setItem('promoCode', coupon);
        } else {
          Swal.fire({
            title: '<p style="font-size:70%;">This coupon code is not applicable to the selected plan</p>',
            icon: 'warning',
            confirmButtonText: 'Ok',
            allowOutsideClick: true,
            customClass : { 
              container : 'my-swal' 
            },
          });
          return;
        }
      }
      let couponCode = window.location.href;
      if (couponCode.includes("couponCode=")) {
        couponCode = couponCode.split("couponCode=")[1];
        if (freeTrialPromoCodes.includes(couponCode)) {
          if (productId.includes('student_lite')) {
            setCoupon(couponCode);
            input.coupon = '';
            input.freeTrial = 31;
            setMessage(true);
            localStorage.setItem('promoCode', couponCode);
          } else {
            Swal.fire({
              title: '<p style="font-size:70%;">This coupon code is not applicable to the selected plan</p>',
              icon: 'warning',
              confirmButtonText: 'Ok',
              allowOutsideClick: true,
              customClass : { 
                container : 'my-swal' 
              },
            });
            return;
          }
        }
      }
      const response = await axios(functionBaseUrl + '/api/stripeCreateSubscription', {
        method: 'POST',
        data: input,
      });
      if (response.status !== 200) {
        Swal.fire({
          title: '<p style="font-size:70%;">' + response.data.message + '</p>',
          icon: 'warning',
          confirmButtonText: 'Ok',
          allowOutsideClick: true,
          customClass : { 
            container : 'my-swal' 
          },
        });
        return;
      }
      setClientSecret(response.data.clientSecret);
      setSubscriptionId(response.data.subscription.id);
      handleResult({ amount: response.data.subscription.latest_invoice?.amount_paid, id: response.data.subscription.id });
      return;
    } catch (error) {
      Swal.fire({
        title: '<p style="font-size:70%;">An unexpected error occurred. Please try again.</p>',
        icon: 'warning',
        confirmButtonText: 'Ok',
        allowOutsideClick: true,
        customClass : { 
          container : 'my-swal' 
        },
      });
      return;
    }
  }

  useEffect(() => {
    let referralCode = window.location.href;
    if (referralCode.includes("REFERRALCODE=")) {
      referralCode = referralCode.split("REFERRALCODE=")[1];
      setCoupon(referralCode);
    } else {
      referralCode = "";
    }
    
    init();
  }, [productId]);

  const getAmount = (plan) => {
    let amount = 0;
    if (plan.includes('student_lite')) {
      if (plan.includes('monthly')) {
          amount = 15.99;
      } else {
          amount = 110;
      }
    } else if (plan.includes('pro_lite')) {
      if (plan.includes('monthly')) {
          amount = 65;
      } else {
          amount = 650;
      }
    } else if (plan.includes('pro_premium')) {
      if (plan.includes('monthly')) {
          amount = 79.99;
      } else {
          amount = 799.99;
      }
    }
    if (discount > 0 && amount !== '') {
      if (discountType === 'percent') {
        amount *= ((100 - discount) / 100);
      } else {
        amount -= discount;
      }
    }
    amount = amount.toFixed(2);
    return amount;
  }

  const getDate = () => {
    if (startDate) {
      return moment(String(startDate), "x").format("MM/DD/YYYY");
    }
    if (trialEnd) {
      return moment(String(trialEnd), "x").format("MM/DD/YYYY");
    }
    if (freeTrial && freeTrial === 14) {
      return moment().add(2, "weeks").format("MM/DD/YYYY");
    }
    if (freeTrialPromoCodes.includes(coupon) && message) {
      return moment().add(31, "days").format("MM/DD/YYYY");
    }
    return moment().format("MM/DD/YYYY");
  }

  return (<>
    {clientSecret ? (<>
      <Grid
        item
        xs={12}
      >
        {showDetails && (
            <Box border={1} borderColor="primary.main" style={{ width: "100%", marginBottom: "5%" }}>
              <Card>
                <CardContent fullWidth>
                  <Grid container justify="space-between">
                    {freeTrialPromoCodes.includes(coupon) && message && (
                      <b style={{ fontSize: "125%", marginBottom: '5%' }}>You will not be charged for the first month and you may cancel anytime.</b>
                    )}
                    <Typography
                        inline
                        component="h6"
                        variant="h6"
                        align="left"
                    >
                        <b>Plan &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:</b>
                    </Typography>
                    <Typography
                        inline
                        component="h6"
                        variant="h6"
                        align="right"
                    >
                        {getPlan(productId)}
                    </Typography>
                    </Grid>

                    <Grid container justify="space-between">
                    <Typography
                        inline
                        component="h6"
                        variant="h6"
                        align="left"
                    >
                        <b>Amount &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:</b>
                    </Typography>
                    <Typography
                        inline
                        component="h6"
                        variant="h6"
                        align="right"
                    >
                        ${subscriptionAmount > -1 ? subscriptionAmount : getAmount(productId)}
                    </Typography>
                    </Grid>

                    <Grid container justify="space-between">
                    <Typography
                        inline
                        component="h6"
                        variant="h6"
                        align="left"
                    >
                        <b>Billed &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:</b>
                    </Typography>
                    <Typography
                        inline
                        component="h6"
                        variant="h6"
                        align="right"
                    >
                        {productId.includes('monthly') ? 'MONTHLY' : 'ANNUALLY'}
                    </Typography>
                    </Grid>

                    <Grid container justify="space-between">
                    <Typography
                        inline
                        component="h6"
                        variant="h6"
                        align="left"
                    >
                        <b>Payment Date :</b>
                    </Typography>
                    <Typography
                        inline
                        component="h6"
                        variant="h6"
                        align="right"
                    >
                        {getDate()}
                    </Typography>
                    <Typography
                        inline
                        component="h6"
                        variant="h6"
                        align="left"
                        style={{ fontSize: '90%' }}
                    >
                        <p>Plan automatically renews until canceled. Cancel at least a day before the renewal date</p>
                    </Typography>
                  </Grid>
                </CardContent>
              </Card>
            </Box>
        )}
      </Grid>
      {!hideCoupon && (<Grid container style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: 10}}>
        <Grid item>
          <TextField
            label="Coupon"
            margin='dense'
            onChange={handleCoupon}
            value={coupon.toUpperCase()}
            variant="outlined"
          />
        </Grid>
        <Grid item>
          <Button
            color="primary"
            fullWidth
            type="button"
            variant="contained"
            style={{marginTop: 7, height: 40 }}
            onClick={applyCoupon}
          >Apply</Button>
        </Grid>
      </Grid>)}
      {discount > 0 && subscriptionAmount > -1 && subscriptionAmount <= getAmount(productId) && !hideCoupon && (
        <Grid container style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: 10}}>
          <Typography
            style={{
              padding: 10,
              //width: '40%',
              display: 'inline-block',
              verticalAlign: 'top',
            }}
            align={'center'}
          >
            <b>Discount: </b>{discount} {discountType === 'percent' ? '%' : 'USD'}
          </Typography>
          <br />
          {couponSuccess === true && (
            <Typography
              style={{
                display: 'inline-block',
                verticalAlign: 'top',
                color: 'green'
              }}
              align={'center'}
            >
              Coupon Applied!
            </Typography>
          )}
        </Grid>
      )}
    
    {card.length > 0 && (<div>
      <Grid container justify="center">
        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              onClick={() => setUseSavedCard(!useSavedCard)}
              checked={useSavedCard ? true : false}
              value={useSavedCard ? true : false}
            />
          }
          label={<div>Use saved card: <b>{card}</b></div>}
        /><br />
      </Grid>
      {useSavedCard && (
        <Grid container justify="center">
          <Button
            disabled={useSavedCard === null || useSavedCard === false}
            color="primary"
            //fullWidth
            onClick={() => subscribe()}
            variant="contained"
            style={{ marginTop: 20 }}
          >
            <span id="button-text">
              Subscribe
            </span>
          </Button>
        </Grid>
      )}<br />
    </div>)}
    {card.length > 0 && !useSavedCard && ( <div><b>or enter card details</b><br /><br /><br /></div> )}
    {clientSecret && !useSavedCard && (
      <Elements
        options={{
          clientSecret,
          appearance: {
            theme: 'stripe',
          },
        }}
        stripe={stripePromise}
      >
        <StripeSubscriptionForm 
          clientSecret={clientSecret} 
          subscriptionId={subscriptionId} 
          buttonText={buttonText} 
          handleResult={handleResult} 
          fullName={fullName}
          email={email}
        />
      </Elements>)}
    </>): <div style={{display: 'flex', justifyContent: 'center', paddingTop: 100, paddingBottom: 100}}>
      <CircularProgress/>
    </div>}
  </>);
}

export const StripeSubscription = StripeSubscriptionComponent;
