import React, { useEffect, useRef, useState } from "react";
import Popover from "@material-ui/core/Popover";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import s from "./styles.module.css";
import CheckBoxOutlineBlankOutlinedIcon from "@material-ui/icons/CheckBoxOutlineBlankOutlined";
import CheckBoxOutlinedIcon from "@material-ui/icons/CheckBoxOutlined";

export const ClubSelect = ({
  values,
  name,
  onChange,
  options,
  emptyValue,
  showSelectedNumberOnValue,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  function findCategory(value) {
    return options.find((category) =>
      category.options.some((option) => option.value === value)
    )?.label;
  }

  function canAddValue(existingValues, newValue) {
    if (existingValues.length === 0) return true;
    const existingCategory = findCategory(existingValues[0]);
    const newCategory = findCategory(newValue);

    return existingCategory === newCategory;
  }

  const handleOptionClick = (value) => {
    if (values.length) {
      if (!canAddValue(values, value)) return;
    }

    onChange(name, value);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div className={s.container}>
      <div
        style={{ fontSize: "14px" }}
        className={`${s.selectWrapper} ${
          values.length ? s.selectActive : s.selectInActive
        }`}
        onClick={handleClick}
      >
        <div className={s.selectValue}>
          <span>
            {values.length ? (
              showSelectedNumberOnValue ? (
                <>
                  <span
                    style={{
                      backgroundColor: "#E2D2FC",
                      padding: "2px 8px 2px 8px",
                      borderRadius: "14px",
                      marginRight: "5px",
                    }}
                  >
                    {values.length}
                  </span>
                  {emptyValue}
                </>
              ) : (
                `${
                  options
                    .reduce((acc, category) => {
                      return acc.concat(category.options);
                    }, [])
                    .find((el) => el.value === values[0])?.text
                } ${values.length > 1 ? `+${values.length - 1}` : ""}`
              )
            ) : (
              emptyValue
            )}
          </span>
          <KeyboardArrowDownIcon />
        </div>
      </div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        PaperProps={{
          style: {
            minWidth: "200px",
            marginTop: "10px",
            borderRadius: "10px",
          },
        }}
      >
        <div className={s.options}>
          {options.map((el) => (
            <>
              <div className={s.optionsLabel}>{el.label}</div>
              {el.options.map((el) => {
                return (
                  <div
                    style={{ display: "flex", gap: "5px" }}
                    className={s.option}
                    key={el.value}
                    onClick={() => handleOptionClick(el.value)}
                  >
                    {values.length && values.includes(el.value) ? (
                      <CheckBoxOutlinedIcon style={{ color: "#4009A5" }} />
                    ) : (
                      <CheckBoxOutlineBlankOutlinedIcon
                        style={{ color: "#4009A5" }}
                      />
                    )}
                    <div>{el.text}</div>
                  </div>
                );
              })}
            </>
          ))}
        </div>
      </Popover>
    </div>
  );
};
