import {
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Slider,
  Switch,
  Tooltip,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useCallback } from "react";
import { FlexWrap, Pad } from "../../../components";
import SettingsIcon from "@material-ui/icons/Settings";
import { checkCreatedRemoteSession, getCoachData } from "../api";
import { ChangeStudentPrompt } from "./ChangeStudentPrompt";
import RefreshIcon from "@material-ui/icons/Refresh";
import { FilledButton } from "./styled";
import { CustomSelect, MenuItemWithLabel } from "./CustomSelect";

export function RemoteControlSessionMenu({
  init,
  devices,
  selectedDevice,
  students,
  startFn,
  stopFn,
  settingsFn,
  devicesRefreshFn,
  studentsRefreshFn,
  onMenuChange,
  form,
  onStudentChange,
  onClubChange,
  onSettingsChange,
  onDeviceModalOpen,
  onDeviceChange,
}) {
  const [settingForm, setSettingsForm] = useState({
    angle: "",
    camera: "",
    brightness: "",
    zoom: "",
  });
  const [recordButton, setRecordButton] = useState({
    status: "disabled",
    text: "Record",
  });
  const [blockButton, setBlockButton] = useState(false);
  const [changeStudentModal, setChangeStudentModal] = useState({
    open: false,
    eventValue: "",
  });
  const [deviceForm, setDeviceForm] = useState(null);
  const [counter, setCounter] = useState({ sec: 0, min: 0 });
  const [zoomArray, setZoomArray] = useState([]);

  useEffect(() => {
    setDeviceForm(devices);
  }, [devices]);

  useEffect(() => {
    // console.log(recordButton, blockButton);
  }, [form]);

  useEffect(() => {
    if (deviceForm) {
      const { student, golfClub } = form;

      const checkIfRecordButtonIsReady = () => {
        if (!deviceForm.length) return false;

        for (let i = 0; i < deviceForm.length; i++) {
          const el = deviceForm[i];

          const { brightness, zoom, angle } = el.form;
          // console.log(brightness, zoom, angle);

          if (!brightness || !zoom || !angle || !student || !golfClub) {
            return false;
          }
        }

        if (recordButton.status === "disabled") {
          return true;
        }
      };

      let isRecordButtonReady = checkIfRecordButtonIsReady();

      if (isRecordButtonReady) {
        setRecordButton((prev) => ({ ...prev, status: "active" }));
      }
    }
  }, [form, selectedDevice, deviceForm]);

  useEffect(() => {
    if (!init)
      setRecordButton({
        status: "disabled",
        text: "Record",
      });
  }, [init]);

  useEffect(() => {
    // if (deviceForm?.form?.isFrontCameraEnabled) {
    //   setZoomArray([{ value: 1, label: "1x" }]);
    // } else if (
    //   deviceForm &&
    //   deviceForm.cameraCapabilities &&
    //   deviceForm.cameraCapabilities.zoomRangeMin &&
    //   deviceForm.cameraCapabilities.zoomRangeMax
    // ) {
    //   const minZoom = deviceForm.cameraCapabilities.zoomRangeMin;
    //   const maxZoom = deviceForm.cameraCapabilities.zoomRangeMax;
    //   const arr = [];
    //   if (minZoom === 0.5) arr.push({ value: 0.5, label: "0.5x" });
    //   for (let i = 1; i <= maxZoom; i++) {
    //     arr.push({ value: i, label: `${i}x` });
    //   }

    //   setZoomArray(arr);
    // } else {
    //   setZoomArray([{ value: 1, label: "1x" }]);
    // }
    setZoomArray([
      { value: 0.5, label: "0.5x" },
      { value: 1, label: "1x" },
      { value: 2, label: "2x" },
    ]);
  }, [deviceForm]);

  const handleRecordButtonClick = useCallback(async () => {
    if (!blockButton) {
      switch (recordButton.status) {
        case "disabled":
          return;
        case "active": {
          setRecordButton((prev) => ({
            ...prev,
            text: "Loading",
          }));
          setBlockButton(true);
          await startFn({
            studentId: form.student,
            deviceId: form.device,
            command: "startRecording",
            golfClub: form.golfClub,
            lighting: form.lighting,
          });

          setBlockButton(false);
          setRecordButton({
            text: "Recording",
            status: "recording",
          });
          break;
        }
        case "recording": {
          setRecordButton((prev) => ({
            ...prev,
            text: "Loading",
          }));
          setCounter({ min: 0, sec: 0 });
          setBlockButton(true);
          await stopFn({
            studentId: form.student,
            deviceId: form.device,
            command: "stopRecording",
            golfClub: form.golfClub,
            lighting: form.lighting,
          });
          setBlockButton(false);
          setRecordButton({
            text: "Record",
            status: "active",
          });
          break;
        }
      }
    }
  }, [
    startFn,
    stopFn,
    setRecordButton,
    form,
    recordButton,
    setBlockButton,
    blockButton,
  ]);

  const selectValues = {
    golfClub: [
      { value: "D", label: "D" },
      { value: "3W", label: "3W" },
      { value: "5W", label: "5W" },
      { value: "7W", label: "7W" },
      { value: "9W", label: "9W" },
      { value: "2H", label: "2H" },
      { value: "3H", label: "3H" },
      { value: "4H", label: "4H" },
      { value: "5H", label: "5H" },
      { value: "6H", label: "6H" },
      { value: "3i", label: "3i" },
      { value: "4i", label: "4i" },
      { value: "5i", label: "5i" },
      { value: "6i", label: "6i" },
      { value: "7i", label: "7i" },
      { value: "8i", label: "8i" },
      { value: "9i", label: "9i" },
      { value: "PW", label: "PW" },
      { value: "GW", label: "GW" },
      { value: "SW", label: "SW" },
      { value: "LW", label: "LW" },
    ],
    angle: [
      { value: "FaceOn", label: "Face on" },
      { value: "DownTheLine", label: "Down the Line" },
    ],
    autoSwing: ["On", "Off"],
    camera: deviceForm?.cameraCapabilities?.isFrontCameraAvailable
      ? [
          { value: false, label: "Main Camera" },
          { value: true, label: "Front Camera" },
        ]
      : [{ value: false, label: "Main Camera" }],
    brightness: [
      { value: 100, label: "100%" },
      { value: 90, label: "90%" },
      { value: 80, label: "80%" },
      { value: 70, label: "70%" },
      { value: 60, label: "60%" },
      { value: 50, label: "50%" },
      { value: 40, label: "40%" },
      { value: 30, label: "30%" },
      { value: 20, label: "20%" },
      { value: 10, label: "10%" },
      { value: 0, label: "0%" },
    ],
  };

  const getRecordButtonColor = () => {
    switch (recordButton.status) {
      case "disabled":
        return { body: "#C9C9C9", border: "rgb(201, 201, 201, 0.2)" };
      case "active":
        return { body: "#580CE3", border: "rgb(88, 12, 227, 0.2)" };
      case "recording":
        return { body: "#2AE30C", border: "rgb(42, 227, 12, 0.2)" };
      default:
        return { body: "#C9C9C9", border: "rgb(201, 201, 201, 0.2)" };
    }
  };

  const studentsOptions = students.map((el) => {
    return {
      value: el.uid,
      label: `${el.firstName} ${el.lastName}`,
    };
  });

  return (
    <>
      <div style={{ fontWeight: "bold", fontSize: "20px" }}>Remote control</div>
      <div>Pair your device with the website to start session remotely.</div>
      <Pad height="20px" />
      <FlexWrap justify="space-between">
        <div
          style={{
            width: "68%",
            display: "flex",
            flexDirection: "row",
          }}
        >
          <CustomSelect
            value={
              studentsOptions.find((el) => el.value === form.student) || "none"
            }
            label="Students"
            onChange={onStudentChange}
            options={studentsOptions}
          />
          <Tooltip title="Refresh">
            <RefreshIcon
              onClick={studentsRefreshFn}
              style={{ cursor: "pointer", marginTop: "38px" }}
            />
          </Tooltip>
        </div>
        <CustomSelect
          value={selectValues.golfClub.find((el) => el.value === form.golfClub)}
          isSearchable={false}
          width="30%"
          label="Club"
          onChange={onClubChange}
          options={selectValues.golfClub}
        />
      </FlexWrap>
      <Pad height="20px" />

      {form.golfClub && form.student ? (
        <>
          <div style={{ fontWeight: "bold", fontSize: "20px" }}>
            Select Active Devices
          </div>
          <FlexWrap justify="start">
            {devices.map((el) => (
              <div
                style={{
                  margin: "10px",
                  padding: "10px",
                  border: "1px lightGray solid",
                  borderRadius: "5px",
                }}
              >
                {el.deviceName}
              </div>
            ))}

            <FilledButton
              style={{ margin: "10px" }}
              onClick={onDeviceModalOpen}
              width="20%"
              color="#580CE3"
            >
              Select Devices
            </FilledButton>
          </FlexWrap>
        </>
      ) : (
        ""
      )}

      <>
        {deviceForm ? (
          deviceForm.map((el) => {
            return (
              <>
                <div
                  style={{
                    fontWeight: "bold",
                    fontSize: "20px",
                    margin: "10px 0px",
                  }}
                >
                  {el.deviceName} Device Settings
                </div>
                {/* <CustomSelect
                  value={devicesOptions.find((el) => el.value === selectedDevice)}
                  isSearchable={false}
                  label="Connected Device"
                  onChange={onDeviceChange}
                  options={devicesOptions}
                /> */}
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  {/* <CustomSelect
                    value={selectValues.brightness.find(
                      (fnd) => fnd.value === el.form.brightness
                    )}
                    isSearchable={false}
                    width="30%"
                    label="Brightness"
                    onChange={(e) =>
                      onSettingsChange(e, "brightness", el.deviceId)
                    }
                    options={selectValues.brightness}
                  /> */}
                  <MenuItemWithLabel label="Brightness">
                    <Slider
                      defaultValue={10}
                      value={el.form.brightness}
                      onChange={(event, value) =>
                        onSettingsChange({ value }, "brightness", el.deviceId)
                      }
                      getAriaValueText={(eqw) => `${eqw}`}
                      aria-labelledby="discrete-slider"
                      valueLabelDisplay="auto"
                      step={10}
                      marks
                      min={0}
                      max={100}
                      style={{ width: "50%" }}
                    />
                  </MenuItemWithLabel>
                  <MenuItemWithLabel label="Zoom">
                    <RadioGroup
                      row
                      aria-label="position"
                      name="position"
                      defaultValue={1}
                      onChange={(e) =>
                        onSettingsChange(
                          { value: +e.target.value },
                          "zoom",
                          el.deviceId
                        )
                      }
                    >
                      {zoomArray.map((zoomItem) => {
                        return (
                          <FormControlLabel
                            key={zoomItem.value}
                            value={zoomItem.value}
                            control={
                              <Radio
                                color="primary"
                                checked={el.form.zoom === zoomItem.value}
                              />
                            }
                            label={zoomItem.label}
                            labelPlacement="bottom"
                          />
                        );
                      })}
                    </RadioGroup>
                  </MenuItemWithLabel>
                  {/* <CustomSelect
                    value={zoomArray.find((fnd) => fnd.value === el.form.zoom)}
                    isSearchable={false}
                    width="30%"
                    label="Zoom Factor"
                    onChange={(e) => onSettingsChange(e, "zoom", el.deviceId)}
                    options={zoomArray}
                  /> */}
                  <MenuItemWithLabel label="Camera">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        maxWidth: "20%",
                      }}
                    >
                      <div>Front camera</div>
                      <Switch
                        checked={!el.form.isFrontCameraEnabled}
                        onChange={(e) =>
                          onSettingsChange(
                            { value: !e.target.checked },
                            "isFrontCameraEnabled",
                            el.deviceId
                          )
                        }
                        name="camera"
                      />
                      <div>Main camera</div>
                    </div>
                  </MenuItemWithLabel>

                  {/* <CustomSelect
                    value={selectValues.camera.find(
                      (fnd) => fnd.value === el.form.isFrontCameraEnabled
                    )}
                    isSearchable={false}
                    width="30%"
                    label="Camera"
                    onChange={(e) =>
                      onSettingsChange(e, "isFrontCameraEnabled", el.deviceId)
                    }
                    options={selectValues.camera}
                  /> */}

                  <MenuItemWithLabel label="Angle">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        maxWidth: "20%",
                      }}
                    >
                      <div>Down the line</div>
                      <Switch
                        checked={el.form.angle === "DownTheLine" ? false : true}
                        onChange={(e) =>
                          onSettingsChange(
                            {
                              value: e.target.checked
                                ? "FaceOn"
                                : "DownTheLine",
                            },
                            "angle",
                            el.deviceId
                          )
                        }
                        name="camera"
                      />
                      <div>Face on</div>
                    </div>
                  </MenuItemWithLabel>
                </div>
                {/* <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <CustomSelect
                    value={selectValues.angle.find(
                      (fnd) => fnd.value === el.form.angle
                    )}
                    isSearchable={false}
                    width="30%"
                    label="Angle"
                    onChange={(e) => onSettingsChange(e, "angle", el.deviceId)}
                    options={selectValues.angle}
                  />
                  <div style={{ width: "calc(90% / 3)" }}></div>
                </div> */}
              </>
            );
          })
        ) : (
          <></>
        )}
        <FlexWrap style={{ marginTop: "20px" }}>
          <div
            onClick={handleRecordButtonClick}
            style={{
              cursor:
                recordButton.status === "disabled" || blockButton
                  ? "not-allowed"
                  : "pointer",
              width: "120px",
              height: "120px",
              borderRadius: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: getRecordButtonColor().border,
              color: "white",
            }}
          >
            <div
              style={{
                userSelect: "none",
                width: "100px",
                height: "100px",
                borderRadius: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: getRecordButtonColor().body,
              }}
            >
              <div>{recordButton.text}</div>
            </div>
          </div>
          <Pad width="20px" />
        </FlexWrap>
      </>

      <ChangeStudentPrompt
        open={changeStudentModal.open}
        func={() => onStudentChange(changeStudentModal.eventValue)}
        onClose={() => setChangeStudentModal(false)}
      />
    </>
  );
}
