import React, { useEffect, useState } from "react";
import {
  FilledSummaryContainer,
  FilledSummaryItem,
  FilledSummaryItemReps,
  FilledSummaryItemsContainer,
  FilledSummaryItemsSubContainer,
  FilledSummaryTitleContainer,
} from "./styled";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import { Pad } from "../../../../components";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

export function FilledSummary({
  items,
  type,
  title,
  onRemove,
  onAdd,
  onReorder,
}) {
  const [itemsState, setItemsState] = useState(items);
  const onDragEnd = (result) => {
    const arr = Array.from(itemsState);
    const startIndex = result.source.index;
    const endIndex = result.destination.index;
    const [removed] = arr.splice(startIndex, 1);
    arr.splice(endIndex, 0, removed);
    setItemsState(arr);
    onReorder(
      arr.map((el) => el.id),
      type
    );
  };

  useEffect(() => {
    setItemsState(items);
  }, [items]);

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <FilledSummaryContainer>
        <FilledSummaryTitleContainer>
          <div>{title}</div>
          <AddIcon
            onClick={onAdd}
            style={{ fontSize: "24px", color: "purple", cursor: "pointer" }}
          />
        </FilledSummaryTitleContainer>
        <Droppable droppableId={`${title}-list`}>
          {(provided) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              <FilledSummaryItemsContainer>
                {itemsState
                  .filter((el) => el)
                  .map((el, index) => (
                    <Draggable key={el.id} draggableId={el.id} index={index}>
                      {(provided) => (
                        <FilledSummaryItem
                          ref={provided.innerRef}
                          key={el.id}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <video
                            style={{ background: "black" }}
                            height="87px"
                            width="132px"
                          >
                            <source
                              src={el.loopVideo.videoPath}
                              type="video/webm"
                            />
                          </video>
                          <div>{el.title}</div>
                          <FilledSummaryItemsSubContainer>
                            <FilledSummaryItemReps>
                              x{el.drillRepeatCountMin}/{el.drillRepeatCountMax}
                            </FilledSummaryItemReps>
                            <DeleteIcon
                              onClick={() => onRemove(el.id, type)}
                              style={{ fontSize: "24px", cursor: "pointer" }}
                            />
                          </FilledSummaryItemsSubContainer>
                        </FilledSummaryItem>
                      )}
                    </Draggable>
                  ))}
              </FilledSummaryItemsContainer>
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </FilledSummaryContainer>
    </DragDropContext>
  );
}
