import React, { useState } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Grid, TextField, 
  InputAdornment, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Swal from 'sweetalert2';
import { functionBaseUrl, axiosWithToken } from '../../common/firebase';
import { listOfSubscriptions } from '../../common/envConfig';
import moment from 'moment';

function AddStudioAccountComponent({ open, close }) {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [dob, setDOB] = useState('');
  const [coachesCount, setCoachesCount] = useState('');
  const [annualPricing, setAnnualPricing] = useState('');
  const [endDate, setEndDate] = useState('');
  const [baysCount, setBaysCount] = useState('');
  const [prosLicCount, setProsLicCount] = useState('');
  const [playersLicCount, setPlayersLicCount] = useState('');
  const [secondarySubType, setSecondarySubType] = useState(listOfSubscriptions.STUDENT_FREE);
  const [salesRep, setSalesRep] = useState('');

  const handleFirstName = (e) => {
    setFirstName(e.target.value);
  }
  const handleLastName = (e) => {
    setLastName(e.target.value);
  }
  const handleEmail = (e) => {
    setEmail(e.target.value);
  }
  const handleDOB = (e) => {
    setDOB(e.target.value);
  }
  const handleCoachesCount = (e) => {
    setCoachesCount(e.target.value);
  }
  const handleAnnualPricing = (e) => {
    setAnnualPricing(e.target.value);
  }
  const handleEndDate = (e) => {
    setEndDate(e.target.value);
  }
  const handleBaysCount = (e) => {
    setBaysCount(e.target.value);
  }
  const handleProsLicCount = (e) => {
    setProsLicCount(e.target.value);
  }
  const handleSalesRep = (e) => {
    setSalesRep(e.target.value);
  }
  const handlePlayersLicCount = (e) => {
    setPlayersLicCount(e.target.value);
  }
  const handleSecondarySubType = (e) => {
    setSecondarySubType(e.target.value);
  }
  const clearState = () => {
    setFirstName('');
    setLastName('');
    setEmail('');
    setDOB('');
    setCoachesCount('');
    setAnnualPricing('');
    setSalesRep('');
    setEndDate('');
    setBaysCount('');
    setProsLicCount('');
    setPlayersLicCount('');
    setSecondarySubType(listOfSubscriptions.STUDENT_FREE);
  }

  const validEmail = (email) => {
    const regex = /\S+@\S+\.\S+/;
    return regex.test(email);
  }

  const handleCreate = async () => {
    if (!firstName || !lastName || !email || !dob || !coachesCount || !annualPricing) {
      close();
      Swal.fire('<p style="font-size:70%;">Please fill in all required fields</p>');
      return;
    }
    if (!validEmail(email)) {
      close();
      Swal.fire('<p style="font-size:70%;">Please enter a valid Email</p>');
      return;
    }
    if (coachesCount === '' || coachesCount < 1) {
      close();
      Swal.fire('<p style="font-size:70%;">Coaches Number has to greater than 0</p>');
      return;
    }
    const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
    const date = new Date(new Date().setFullYear(new Date().getFullYear() - 13)).toLocaleDateString('en-ZA', options).replaceAll('/', '-');
    if (Date.parse(dob) > Date.parse(date)) {
      close();
      Swal.fire({
        title: '<p style="font-size:70%;">Sorry, this user is not eligible for a Sportsbox.ai account at this time.</p>',
        confirmButtonText: 'Ok',
        icon: 'warning',
        allowOutsideClick: false,
        customClass : { 
          container : 'my-swal' 
        },
      })
        .then(() => {
          window.location.reload();
        })
      return false;
    }

    const data = {
      firstName: firstName,
      lastName: lastName,
      email: email.toLowerCase().replace(/ /g, ''),
      dob: moment(dob, 'YYYY-MM-DD').format('MM/DD/YYYY'),
      coachesCount: coachesCount,
      annualPrice: annualPricing,
      salesRep: salesRep,
      endDate: moment(endDate, 'YYYY-MM-DD').valueOf(),
      baysCount: baysCount,
      prosLicCount: prosLicCount,
      playersLicCount: playersLicCount,
      secondarySubType: secondarySubType,
      status: "Subscription Paid",
    }
    
    await axiosWithToken(`${functionBaseUrl}/api/studioUser`, {
      method: 'POST',
      data: data
    })
    .then((response) => {
      close();
      if (response.status === 200) {
        clearState();
        Swal.fire({
          title: '<p style="font-size:70%;">Account successfully created for ' + email + ' and the password is ' + response.data.password + '</p>',
          icon: 'success',
          confirmButtonText: 'Ok',
          allowOutsideClick: false,
          customClass : { 
            container : 'my-swal' 
          },
        });
      } else {
        if (response.data.message.includes('upgraded')) {
          clearState();
        }
        Swal.fire({
          title: '<p style="font-size:70%;">' + response.data.message + '</p>',
          icon: 'warning',
          confirmButtonText: 'Ok',
          allowOutsideClick: true,
          customClass : { 
            container : 'my-swal' 
          },
        })
      }
      return false;
    })
    .catch((error) => {
      console.log("Error creating studio account: " + error);
      Swal.fire({
        title: '<p style="font-size:70%;">' + error + '</p>',
        icon: 'warning',
        confirmButtonText: 'Ok',
        allowOutsideClick: true,
        customClass : { 
          container : 'my-swal' 
        },
      })
    })
  }

  return (<>
    <Dialog
      fullWidth
      open={open}
      onClose={close}
      aria-labelledby='form-dialog-title'>
      <DialogTitle id={'form-dialog-title'}>
        <div>
          <b>New Studio Account</b>
          <CloseIcon style={{ float: 'right', marginTop: '-10px', marginRight: '-15px' }} onClick={close} />
        </div>
      </DialogTitle>
      <DialogContent>
        <Grid
          container
          spacing={2}
        >
          <Grid
            item
            sm={6}
            xs={12}
          >
            <TextField
              autoComplete="firstname"
              autoFocus
              fullWidth
              required
              label="First Name"
              onChange={handleFirstName}
              value={firstName}
              variant="outlined"
              inputProps={{ maxLength: 254 }}
            />
          </Grid>
          <Grid
            item
            sm={6}
            xs={12}
          >
            <TextField
              autoComplete="lastName"
              fullWidth
              required
              label="Last Name"
              onChange={handleLastName}
              value={lastName}
              variant="outlined"
              inputProps={{ maxLength: 254 }}
            />
          </Grid>
          <Grid
            item
            sm={6}
            xs={12}
          >
            <TextField
              autoComplete="email"
              fullWidth
              required
              label="Email Address"
              onChange={handleEmail}
              value={email}
              variant="outlined"
              inputProps={{ maxLength: 254 }}
            />
          </Grid>
          <Grid
            item
            sm={6}
            xs={12}
          >
            <TextField
              InputLabelProps={{
                shrink: true,
              }}
              required
              fullWidth
              variant="outlined"
              id="date"
              label="Date of Birth"
              onChange={handleDOB}
              type="date"
              value={dob}
            />
          </Grid>
          <Grid
            item
            sm={6}
            xs={12}
          >
            <TextField
              autoComplete="salesRep"
              fullWidth
              label="Sales Representative Email"
              onChange={handleSalesRep}
              value={salesRep}
              variant="outlined"
              inputProps={{ maxLength: 254 }}
            />
          </Grid>
          <Grid
            item
            sm={6}
            xs={12}
          >
            <TextField
              fullWidth
              required
              label="Coaches Count"
              onChange={handleCoachesCount}
              value={coachesCount}
              type="Number"
              variant="outlined"
              InputProps={{
                inputProps: { min: 1 }
              }}
            />
          </Grid>
          <Grid
            item
            sm={6}
            xs={12}
          >
            <TextField
              fullWidth
              required
              label="Annual Pricing"
              onChange={handleAnnualPricing}
              value={annualPricing}
              type="Number"
              variant="outlined"
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                inputProps: { min: 0 }
              }}
            />
          </Grid>
          <Grid
            item
            sm={6}
            xs={12}
          >
            <TextField
              InputLabelProps={{
                shrink: true,
              }}
              required
              fullWidth
              variant="outlined"
              id="endDate"
              label="Subscription End Date"
              onChange={handleEndDate}
              type="date"
              value={endDate}
            />
          </Grid>
          {/*<Grid
            item
            sm={6}
            xs={12}
          >
            <TextField
              autoComplete="status"
              fullWidth
              required
              label="Status"
              onChange={handleStatus}
              value={status}
              variant="outlined"
              inputProps={{ maxLength: 254 }}
            />
          </Grid>*/}
          <Grid
            item
            sm={6}
            xs={12}
          >
            <TextField
              fullWidth
              required
              label="Bays Count"
              onChange={handleBaysCount}
              value={baysCount}
              type="Number"
              variant="outlined"
              InputProps={{
                inputProps: { min: 1 }
              }}
            />
          </Grid>
          <Grid
            item
            sm={6}
            xs={12}
          >
            <TextField
              fullWidth
              required
              label="Pros License Count"
              onChange={handleProsLicCount}
              value={prosLicCount}
              type="Number"
              variant="outlined"
              InputProps={{
                inputProps: { min: 0 }
              }}
            />
            {(prosLicCount > coachesCount) && <p style={{ color: 'red', fontSize: '12px' }}>Pros License Count cannot be greater than Coaches Count</p>}
          </Grid>
          <Grid
            item
            sm={6}
            xs={12}
          >
            <TextField
              fullWidth
              required
              label="Players License Count"
              onChange={handlePlayersLicCount}
              value={playersLicCount}
              type="Number"
              variant="outlined"
              InputProps={{
                inputProps: { min: 0 }
              }}
            />
            {(playersLicCount > coachesCount) && <p style={{ color: 'red', fontSize: '12px' }}>Players License Count cannot be greater than Coaches Count</p>}
          </Grid>
          <Grid
            item
            sm={6}
            xs={12}
          >
            <FormControl
              fullWidth
              variant="outlined"
            >
              <InputLabel>Secondary Subscription Type</InputLabel>
              <Select
                label="Secondary Subscription Type"
                onChange={handleSecondarySubType}
                value={secondarySubType}
              >
                <MenuItem value={"none"}>None</MenuItem>
                <MenuItem value={listOfSubscriptions.STUDENT_FREE}>Free</MenuItem>
                <MenuItem value={listOfSubscriptions.STUDENT_LITE_ANNUALLY}>3D Player</MenuItem>
                <MenuItem value={listOfSubscriptions.PREMIUM_ANNUALLY}>3D Pro</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={close} color="primary">
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleCreate}
          disabled={(prosLicCount > coachesCount) || (playersLicCount > coachesCount)}
        >
          CREATE
        </Button>
      </DialogActions>
    </Dialog>
  </>);
}

export const AddStudioAccount = AddStudioAccountComponent;