import React from 'react';

import { Stepper, Step, StepLabel } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';

import './speedAssessment.css';

function CTAWelcomeComponent({ email }) {

  return (<>
    <div className="cta-welcome">
      <Stepper activeStep={2} className="cta-mobile-stepper">
        <Step key={1} completed={false}>
          <StepLabel></StepLabel>
        </Step>
        <Step key={2} completed={false}>
          <StepLabel></StepLabel>
        </Step>
      </Stepper>
      <CheckIcon className="cta-welcome-mark" />
      <div className="cta-welcome-caption"><span>Check your inbox for instructions</span></div>
      <div>
        An email with next steps has been sent to: <b>{email}</b>.
        <br /><br />
        Complete your Sportsbox journey today by downloading our app. Log in to the app with the email you signed up with.
      </div>
      <div className="cta-welcome-apps">
        <a href="https://apps.apple.com/us/app/sportsbox-3dgolf/id1578921026">
          <img
            alt="Apple"
            src="/images/offer/apple.svg"
            width={170}
          />
        </a>
      </div>
    </div>
  </>);
}

export const CTAWelcome = CTAWelcomeComponent;