import React, { useState, useEffect } from "react";
import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  Container,
  Grid,
  Typography,
} from "@material-ui/core";
import Swal from "sweetalert2";
import firebase, {
  functionBaseUrl,
  axiosWithToken,
} from "../../../common/firebase";
import { StripeSubscription } from "../../../components/stripeSubscription";
import { StripePayment } from "../../../components/stripePayment";
import { listOfSubscriptions } from "../../../common/envConfig";

function EnterprisePayComponent({ history }) {
  const [uid, setUID] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [isAutoRenewal, setIsAutoRenewal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [currentUser, setCurrentUser] = useState("");

  const getCurrentUser = async () => {
    try {
      const firebaseUser = firebase.auth().currentUser;
      setUID(firebaseUser.uid);
      //const user = await axiosWithToken(`${functionBaseUrl}/api/usersEnterprise/${firebaseUser.uid}`, { method: 'get' });
      const user = await firebase
        .firestore()
        .collection("enterpriseAccounts")
        .where("userId", "==", firebaseUser.uid)
        .get();
      let userData = [];
      user.forEach(function (doc) {
        userData.push({ ...doc.data(), id: doc.id });
      });
      const userDoc = await firebase
        .firestore()
        .collection("users")
        .doc(firebaseUser.uid)
        .get();
      let uData = userDoc.data();
      if (uData) {
        setFirstName(uData.firstName);
        setLastName(uData.lastName);
      }
      if (
        userData.length > 0 &&
        (userData[0].autoRenewal === null ||
          userData[0].autoRenewal === undefined ||
          userData[0].autoRenewal)
      ) {
        setIsAutoRenewal(true);
      }
      const sub = await firebase
        .firestore()
        .collection("subscriptions")
        .where("userId", "==", firebaseUser.uid)
        .get();
      let subData = [];
      sub.forEach(function (doc) {
        subData.push({ ...doc.data(), id: doc.id });
      });
      if (userData.length > 0) {
        if (subData.length > 0 && subData[0].endDate > Date.now()) {
          //if ('autoRenewal' in userData[0] === false || ('autoRenewal' in userData[0] && userData[0].autoRenewal)) {
          alert("You have already paid for Enterprise subscription!");
          history.push("/account");
          //}
        }
        setCurrentUser(userData[0]);
      } else {
        alert("You are not authorized to access this page");
        history.push("/dashboard");
      }
      setIsLoading(false);
    } catch (error) {
      alert("You are not authorized to access this page");
      history.push("/dashboard");
    }
  };

  const createSubscription = async (data) => {
    await axiosWithToken(functionBaseUrl + "/api/subscription", {
      method: "POST",
      data: {
        userId: uid,
        plan: isAutoRenewal ? "Enterprise Master" : "Staff Enterprise",
        planDescription: "",
        planType: isAutoRenewal ? "Recurring" : "One Time",
        transactionId: data.id,
        payload: "",
        packageId: "",
        orderId: "",
        amount: (data.amount / 100).toFixed(2),
        startDate: String(Date.now()),
        endDate: isAutoRenewal
          ? String(new Date().setDate(new Date().getDate() + 366))
          : String(new Date().setDate(new Date().getDate() + 730)),
        lastUpdated: String(Date.now()),
        autoRenewal: isAutoRenewal,
        platform: "Stripe",
        promoCode: "",
        productId: listOfSubscriptions.ENTERPRISE_MASTER,
      },
    })
      .then(async () => {
        if (currentUser && currentUser.id) {
          await firebase
            .firestore()
            .collection("enterpriseAccounts")
            .doc(currentUser.id)
            .update({ status: "Subscription Paid" });
        }
        if (window.location.href.includes("mobileClient")) {
          await history.push("/account?mobileClient");
        } else {
          await history.push("/account");
        }
        if (!isAutoRenewal) {
          await axiosWithToken(functionBaseUrl + "/api/teachableUser", {
            method: "post",
            data: {
              email: currentUser.email,
              uid: uid,
              name: firstName + " " + lastName,
              courseId: 1637249,
            },
          })
            .then(async (response) => {
              await Swal.fire({
                title:
                  '<p style="font-size:70%;">Payment processed successfully.</p>',
                text:
                  "Please check your " +
                  currentUser.email +
                  " inbox with login details for your Teachable account to complete certification",
                icon: "success",
                confirmButtonText: "Ok",
                allowOutsideClick: true,
                customClass: {
                  container: "my-swal",
                },
              }).then(() => {
                if (window.location.href.includes("mobileClient")) {
                  setTimeout(async function () {
                    await firebase
                      .auth()
                      .signOut()
                      .then(() => {
                        if (window.location.href.includes("mobileClient")) {
                          history.push("/sign-in?mobileClient");
                        } else {
                          history.push("/sign-in");
                        }
                      })
                      .catch((error) => {
                        console.log(error);
                      });
                  }, 500);
                  window.location = "sportsboxclient://home";
                }
              });
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          if (window.location.href.includes("mobileClient")) {
            setTimeout(async function () {
              await firebase
                .auth()
                .signOut()
                .then(() => {
                  if (window.location.href.includes("mobileClient")) {
                    history.push("/sign-in?mobileClient");
                  } else {
                    history.push("/sign-in");
                  }
                })
                .catch((error) => {
                  console.log(error);
                });
            }, 500);
            window.location = "sportsboxclient://home";
          } else {
            await Swal.fire({
              title:
                '<p style="font-size:70%;">Payment processed successfully.</p>',
              icon: "success",
              confirmButtonText: "Ok",
              allowOutsideClick: true,
              customClass: {
                container: "my-swal",
              },
            });
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getCurrentUser();
  }, []);

  return (
    <>
      {isLoading && (
        <Container
          maxWidth="xs"
          style={{ textAlign: "center", paddingTop: "50px" }}
        >
          <CircularProgress />
        </Container>
      )}
      {!isLoading && (
        <Container
          component="main"
          maxWidth="xs"
          style={{ paddingTop: "50px" }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {!isAutoRenewal && (
                <Typography inline component="h6" variant="h6" align="center">
                  <b>Staff Program One-Time Setup Fee</b>
                </Typography>
              )}
              <Box
                border={1}
                borderColor="primary.main"
                style={{ width: "100%", marginBottom: "5%" }}
              >
                <Card>
                  <CardContent fullWidth>
                    <Grid container justify="space-between">
                      <Typography
                        inline
                        component="h6"
                        variant="h6"
                        align="left"
                      >
                        <b>Number of Coach Licenses:</b>
                      </Typography>
                      <Typography
                        inline
                        component="h6"
                        variant="h6"
                        align="right"
                      >
                        {currentUser?.coachesCount}
                      </Typography>
                    </Grid>
                    <Grid container justify="space-between">
                      <Typography
                        inline
                        component="h6"
                        variant="h6"
                        align="left"
                      >
                        <b>Payment for Enterprise plan:</b>
                      </Typography>
                      <Typography
                        inline
                        component="h6"
                        variant="h6"
                        align="right"
                      >
                        ${currentUser?.annualPrice}
                      </Typography>
                    </Grid>
                    <Grid container justify="space-between">
                      <Typography
                        inline
                        component="h6"
                        variant="h6"
                        align="left"
                      >
                        <b>Payment Frequency:</b>
                      </Typography>
                      <Typography
                        inline
                        component="h6"
                        variant="h6"
                        align="right"
                      >
                        {isAutoRenewal ? "Annually" : "One Time"}
                      </Typography>
                    </Grid>
                  </CardContent>
                </Card>
              </Box>
            </Grid>
            <Grid item xs={12}>
              {isAutoRenewal && (
                <StripeSubscription
                  email={currentUser.email}
                  productId={listOfSubscriptions.ENTERPRISE_MASTER}
                  handleResult={createSubscription}
                  hideCoupon={true}
                  showDetails={false}
                  fullName={firstName + " " + lastName}
                />
              )}
              {!isAutoRenewal && (
                <StripePayment
                  amount={currentUser.annualPrice}
                  handleResult={createSubscription}
                  name={firstName + " " + lastName}
                  email={currentUser.email}
                />
              )}
            </Grid>
          </Grid>
        </Container>
      )}
    </>
  );
}

export const EnterprisePay = EnterprisePayComponent;
