import React from "react";

export function StatisticsContainer({ children }) {
  return (
    <div
      style={{
        background: "#fff",
        boxShadow: "0px 0px 5px #cccccc",
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      {children}
    </div>
  );
}
