import React from 'react';
import { Modal, Box, List, ListItem, Typography, IconButton, Button } from '@material-ui/core';
import AddIcon from "@material-ui/icons/Add";

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};


function SwingSelect({ selectModalOpen, setSelectModalOpen, jsonData, setSelectSwingIdx, selectSwingIdx }) {

    const handleSwingCompChange = (e, index) => {         
        setSelectSwingIdx([...selectSwingIdx, index]);        
    }

    return (
    <Modal
        open={selectModalOpen}
        // onClose={() => {setSelectModalOpen(false);}}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
    >
        <Box sx={modalStyle}>
            <List>
                {
                jsonData.map((data, index) => (
                    <ListItem key={index}>
                        <IconButton disabled={selectSwingIdx.includes(index)} aria-label="delete" size="small" onClick={(e) => { handleSwingCompChange(e, index)} }>
                            <AddIcon fontSize="small" />
                        </IconButton>
                        <Typography>{data.session_id}</Typography>
                    </ListItem>
                ))
                }
            </List>
            <Button onClick={() => {setSelectModalOpen(false)}}>Attach</Button>
        </Box>
    </Modal>
  );
}

export default SwingSelect;
