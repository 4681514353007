import React, { useState, useEffect } from 'react';

import { Button, Grid } from '@material-ui/core';
import StarIcon from '@material-ui/icons/Star';


function PlansTestimonialsPlayersComponent() {
  const [currentTestimonial, setCurrentTestimonial] = useState(1);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTestimonial(currentTestimonial => (currentTestimonial === 4 ? 1 : currentTestimonial + 1));
    }, 8000);

    return () => clearInterval(interval);
  }, [currentTestimonial]);

  return (<>
    <Grid container>
      <Grid
        item
        xs={12}
        md={8}
        className="plans-testimonials-body"
      >
        <div className="plans-testimonials-stars">
          <StarIcon className="plans-testimonials-star" />
          <StarIcon className="plans-testimonials-star" />
          <StarIcon className="plans-testimonials-star" />
          <StarIcon className="plans-testimonials-star" />
          <StarIcon className="plans-testimonials-star" />
        </div>
        {currentTestimonial == 1 && (<>
          <div className="plans-testimonials-text">
            As someone who practices nearly every day, typically without eyes on more than a few swings, I like being able to have immediate, concrete feedback on my swing.
          </div>
          <div className="plans-testimonials-name">
            — Winnie<br />(collegiate golfer, ~22yo)
          </div>
        </>)}
        {currentTestimonial == 2 && (<>
          <div className="plans-testimonials-text">
            Sportsbox 3D made practicing way easier. I was able to know where my swing was when I was working through a swing change. I was able to focus on 1 specific change and be focused.
          </div>
          <div className="plans-testimonials-name">
            — Caden<br />(Intermediate, junior golfer)
          </div>
        </>)}
        {currentTestimonial == 3 && (<>
          <div className="plans-testimonials-text">
            I haven't taken lessons in about a year, so the interaction with Brian (Jacobs) was great and it was awesome that he knew exactly what areas of my swing to look at and could point me to the numbers in Sportsbox. I can see how this will be really valuable for people who are looking to play better golf, like me!
          </div>
          <div className="plans-testimonials-name">
            — Glenn<br />(14 hdcp, ~50yo)
          </div>
        </>)}
        {currentTestimonial == 4 && (<>
          <div className="plans-testimonials-text">
            The ability to have a coach analyze my swings and point out what I’m doing wrong and what I need to improve on was immensely helpful, especially for a beginner like me. I fully intend to have more practice sessions with a coach to keep learning and improving.
          </div>
          <div className="plans-testimonials-name">
            — Alexis<br />(Beginner, ~30yo)
          </div>
        </>)}
        <div className="plans-testimonials-selectors">
          <Button onClick={() => setCurrentTestimonial(1)} className={currentTestimonial == 1 ? 'active' : ''} />
          <Button onClick={() => setCurrentTestimonial(2)} className={currentTestimonial == 2 ? 'active' : ''} />
          <Button onClick={() => setCurrentTestimonial(3)} className={currentTestimonial == 3 ? 'active' : ''} />
          <Button onClick={() => setCurrentTestimonial(4)} className={currentTestimonial == 4 ? 'active' : ''} />
        </div>
      </Grid>
      <Grid
        item
        xs={12}
        md={4}
        className={`plans-testimonials-photo player${currentTestimonial}`}
      />
    </Grid>
  </>);
}

export default PlansTestimonialsPlayersComponent;