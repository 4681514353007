import React, { useState, useEffect } from 'react';

import { Stepper, Step, StepLabel, Link, Grid } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import './speedAssessment.css';

function CTASignUpComponent() {
  const [showMore, setShowMore] = useState(false);

  const toggleShowMore = () => {
    setShowMore(!showMore);
  };

  useEffect(() => {
    const handleResize = () => {
      const isMobile = window.innerWidth <= 1128; // Adjust the breakpoint as needed
      setShowMore(!isMobile);
    };

    // Initial setup
    handleResize();

    // Event listener for window resize
    window.addEventListener('resize', handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (<>
    <Stepper activeStep={2} className="cta-mobile-stepper">
      <Step key={1} completed={false}>
        <StepLabel></StepLabel>
      </Step>
      <Step key={2} completed={false} className="inactive">
        <StepLabel></StepLabel>
      </Step>
    </Stepper>
    <div className="cta-caption">
      Unlock <span>speed</span> with Sportsbox
    </div>
    <div className="cta-subcaption">
      {window.location.href.includes("speed-assessment-human") && (<><p style={{ fontSize: "150%" }}>Find your power leaks with a Speed Analysis from a Sportsbox 3D Expert.</p><br /></>)}
      {window.location.href.includes("speed-assessment-ai") && (<><p style={{ fontSize: "150%" }}>Find your power leaks with a Sportsbox in-app 3D Speed Assessment.</p><br /></>)}
      <b style={{ fontSize: "125%" }}>Welcome to the smartest way to speed train.<br /><br /></b>
      {window.location.href.includes("speed-assessment-human") && <p>Get a speed assessment from a 3D expert to learn your power leaks and fast track your speed gains.</p>}
      {window.location.href.includes("speed-assessment-ai") && <p>Get a speed assessment from our AI to learn your power leaks and fast track your speed gains.</p>}
      <div className="cta-invitational">
        <div className='gif-v2'>
          {window.location.href.includes("speed-assessment-human") && <div className="cta-invitational">
            <img
              alt="Swing Analysis Offer"
              src="/images/offer/human_speed.gif"
              width={280}
            />
          </div>}
          {window.location.href.includes("speed-assessment-ai") && <div className="cta-invitational">
            <img
              alt="Swing Analysis Offer"
              src="/images/offer/ai_speed.gif"
              width={280}
            />
          </div>}
        </div>
        <div onClick={toggleShowMore} style={{ cursor: 'pointer' }}>
          {showMore ? <u>Show less</u> : <u>Show more</u>}
        </div>
      </div>
    </div>
    <div>
      {showMore && window.location.href.includes("speed-assessment-human") && 
        <ul className="cta-benefits">
          <li>
            <span>What you'll get in the program:</span>
          </li>
          <li>
            <CheckIcon className="cta-benefit-mark" /><span>3D Speed Assessment Report: Created by world renowned biomechanist - Dr. Phil Cheetham - who has helped the best players and long drive champions to optimize their swing for distance ($150 value)</span>
          </li>
          <li>
            <CheckIcon className="cta-benefit-mark" /><span>Video analysis from 3D expert coach: Detailed explanation of your speed assessment ($75 value)</span>
          </li>
          <li>
            <CheckIcon className="cta-benefit-mark" /><span>Goals in Sportsbox: Top power leaks created as GOALS in Sportsbox for you to keep tracking</span>
          </li>
          <li>
            <CheckIcon className="cta-benefit-mark" /><span>Custom Recommended Drills: Depending on your top power leaks, 3D expert coach will assign drills for you to work on</span>
          </li>
          <li>
            <CheckIcon className="cta-benefit-mark" /><Link
              className="offer-link"
              href="https://shop.sportsbox.ai/products/gpod-travel-1"
              target="_blank"
            >
              GPOD Travel for Magsafe
            </Link>&nbsp;($75 value)
          </li>
          <li>
          <Grid
            item
            xs={6}
            className="cta-total-sum"
          >
            <b style={{ fontSize: "125%" }}>Cost: <s>$300.00</s> $150.00</b>
          </Grid>
          </li>
        </ul>
        }
        {showMore && window.location.href.includes("speed-assessment-ai") && 
          <ul className="cta-benefits">
            <li>
              <span>What you'll get in the program:</span>
            </li>
            <li>
              <CheckIcon className="cta-benefit-mark" /><span>3D Speed Assessment Report ($200 value)</span>
            </li>
            <li>
              <CheckIcon className="cta-benefit-mark" /><span>Detailed data / explanation of your speed assessment ($25 value)</span>
            </li>
            <li>
              <CheckIcon className="cta-benefit-mark" /><span>Goals in Sportsbox: Top power leaks created as GOALS in Sportsbox for you to keep tracking</span>
            </li>
            <li>
              <CheckIcon className="cta-benefit-mark" /><span>Custom Recommended Drills: Depending on your top power leaks, AI will assign drills for you to work on</span>
            </li>
            <li>
              <CheckIcon className="cta-benefit-mark" /><Link
                className="offer-link"
                href="https://shop.sportsbox.ai/products/gpod-travel-1"
                target="_blank"
              >
                GPOD Travel for Magsafe
              </Link>&nbsp;($75 value)
            </li>
            <li>
            <Grid
              item
              xs={6}
              className="cta-total-sum"
            >
              <b style={{ fontSize: "125%" }}>Cost: <s>$300.00</s> $150.00</b>
            </Grid>
            </li>
          </ul>
      }
    </div>
  </>);
}

export const CTASignUp = CTASignUpComponent;