import MaterialTable from "material-table";
import React from "react";

export function UserList({ data, onClick }) {
  const tableOptions = {
    toolbar: false,
    selection: false,
    sorting: false,
    search: false,
    paging: false,
    rowStyle: (x) => {
      if (x.tableData.id % 2) {
        return { backgroundColor: "#f2f2f2" };
      }
    },
  };
  const cols = [
    { field: "index", title: "#" },
    { field: "firstName", title: "First Name" },
    { field: "lastName", title: "Last Name" },
    { field: "students.length", title: "Students" },
  ];

  return (
    <>
      <MaterialTable
        style={{ marginTop: "10px", marginBottom: "10px" }}
        key="coach"
        columns={cols}
        onRowClick={(e, rowData) => onClick(rowData)}
        data={data.map((el, i) => ({ ...el, index: i }))}
        options={tableOptions}
      />
    </>
  );
}
