import React, { useEffect, useState } from "react";

import s from "./style.module.css";
import Brightness1Icon from "@material-ui/icons/Brightness1";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import { Avatar, Popover, Switch } from "@material-ui/core";
import moment from "moment";
import PeopleAltOutlinedIcon from "@material-ui/icons/PeopleAltOutlined";
import { listOfSubscriptionNames } from "../../../../common/envConfig";

export const MainPageSubscriptions = ({
  tableOptions,
  onPagination,
  data,
  total,
  onUpgrade,
  onRemove,
  onRemovePending,
}) => {
  const [popover, setPopover] = useState({ id: "", anchorEl: null });

  const totalToPages = (() => {
    const calc = total / 10;
    const fin = calc < 1 ? 1 : Math.ceil(calc);

    const pagesArr = [];
    for (let i = 1; i <= fin; i++) {
      pagesArr.push(i);
    }

    return pagesArr;
  })();

  const handleNextPreviousPagination = (type) => {
    if (type === "next") {
      if (tableOptions.page < totalToPages.length) {
        onPagination(tableOptions.page + 1);
      }
    } else {
      if (tableOptions.page > 1) {
        onPagination(tableOptions.page - 1);
      }
    }
  };

  const calculateDaysLeft = (value) => {
    return Math.max(Math.ceil((value - Date.now()) / (1000 * 60 * 60 * 24)), 0);
  };

  return (
    <div>
      <div className={s.header}>
        <PeopleAltOutlinedIcon style={{ color: "#4009A5" }} />
        <div>Expiring Subscriptions</div>
      </div>
      <div style={{ marginLeft: "30px" }} className={s["table-container"]}>
        <table className={s["styled-table"]}>
          <thead>
            <tr>
              <th></th>
              <th>Name</th>
              <th>Plan</th>
              <th>Days left</th>
            </tr>
          </thead>
          <tbody>
            {data.map((el) => {
              return (
                <tr key={el.id}>
                  <td onClick={() => onUpgrade(el)} className={s.upgradeButton}>
                    Upgrade
                  </td>
                  <td>
                    <div className={s.nameColumn}>
                      <Avatar
                        style={{ width: "40px", height: "40px" }}
                        src={el.picture}
                      />
                      <div>{el.userName}</div>
                    </div>
                  </td>
                  <td>{listOfSubscriptionNames[el.sub.productId]}</td>
                  <td style={{ width: "100px" }}>
                    <span
                      className={
                        calculateDaysLeft(+el.sub.endDate) <= 7
                          ? s.tableDaysLeft
                          : s.tableDaysLeftGreen
                      }
                    >
                      {calculateDaysLeft(+el.sub.endDate) <= 7 ? (
                        <ErrorOutlineIcon style={{ fontSize: "17px" }} />
                      ) : (
                        <Brightness1Icon style={{ fontSize: "12px" }} />
                      )}
                      {calculateDaysLeft(+el.sub.endDate)} days
                    </span>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};
