import React, { useState } from "react";

import {
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Checkbox,
  FormControlLabel,
  Link,
  Button,
  CircularProgress,
  Container,
  TextField,
  Grid,
  Typography,
  Select,
  MenuItem,
  RadioGroup,
  Radio,
  FormLabel,
} from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Swal from "sweetalert2";
import axios from "axios";
import {
  defaultHeightToDefaultHipWidth,
  //defaultHeightToHipWidth,
  femaleHeightToDefaultHipWidth,
  //femaleHeightToHipWidth,
  heightValues,
  maleHeightToDefaultHipWidth,
  //maleHeightToHipWidth,
} from "../students/misc/hipWidthTable";
import "./offerPaid.css";
import firebase, {
  functionBaseUrl,
  axiosWithToken,
} from "../../common/firebase";
import { listOfSubscriptions } from "../../common/envConfig";
import { useHistory } from "react-router-dom";

function SignUpComponent({ finishSignUp }) {
  const history = useHistory();
  const [userFirstName, setUserFirstName] = useState("");
  const [userLastName, setUserLastName] = useState("");
  const [email, setEmail] = useState("");
  //const [dateOfBirth, setDateOfBirth] = useState('');
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [tosAgree, setTosAgree] = useState(false);
  const [dominantHand, setDominantHand] = useState("Right");
  const [height, setHeight] = useState("");
  const [gender, setGender] = useState("MALE");
  const [hipWidth, setHipWidth] = useState("7.1");
  const [handicap, setHandicap] = useState("");
  const [shotShape, setShotShape] = useState("");
  const [desiredShotShape, setDesiredShotShape] = useState("");
  const [marketingAgree, setMarketingAgree] = useState(true);
  const [info, setInfo] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const user = firebase.auth().currentUser;
  if (user && localStorage.getItem("showAlert") === "true") {
    axiosWithToken(`${functionBaseUrl}/api/users/${user.uid}`, {
      method: "GET",
    }).then((userResponse) => {
      if (
        userResponse?.data?.subscriptionType !== "" &&
        window.location.href.includes("/mark-crossfield") &&
        !userResponse?.data?.subscriptionType?.includes("student")
      ) {
        Swal.fire({
          title:
            '<p style="font-size:70%;">This offer is only applicable to Player plans.</p>',
          icon: "warning",
          showCancelButton: false,
          confirmButtonText: "Ok",
          allowOutsideClick: false,
        });
        return;
      }

      if (
        window.location.href.includes("/mark-crossfield") &&
        userResponse?.data?.coaches?.includes("LdYuddckkQSFLPAEd0Ui4jWknHw2")
      ) {
        Swal.fire({
          title:
            '<p style="font-size:70%;">This offer is only applicable to new students, you are an existing student of the coach.</p>',
          icon: "warning",
          showCancelButton: false,
          confirmButtonText: "Ok",
          allowOutsideClick: false,
        });
        return;
      }

      /*if (
        window.location.href.includes("/holiday-offer") &&
        userResponse?.data?.coaches?.includes("91eXr4piarVb1KIt06aZfutKTTs1")
      ) {
        Swal.fire({
          title:
            '<p style="font-size:70%;">This offer is only applicable to new students, you are an existing student of the coach.</p>',
          icon: "warning",
          showCancelButton: false,
          confirmButtonText: "Ok",
          allowOutsideClick: false,
        });
        return;
      }*/

      Swal.fire({
        title:
          '<p style="font-size:70%;">Would you like to accept this offer?</p>' +
          '<p style="font-size:50%;">You are logged in as <b>' +
          user.email +
          "</b></p>",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        allowOutsideClick: false,
      }).then(async (result) => {
        if (result.isConfirmed) {
          if (window.location.href.includes("/mark-crossfield")) {
            await axiosWithToken(functionBaseUrl + "/api/users/" + user.uid, {
              method: "PATCH",
              data: {
                markCrossfield: {
                  newUser: false,
                  date: String(Date.now()),
                },
              },
            }).catch((err) => {
              console.log(err);
            });
          }

          setIsLoading(false);
          localStorage.removeItem("showAlert");
          finishSignUp(
            user.uid,
            user.email.toLowerCase().replace(/ /g, ""),
            userResponse?.data?.firstName?.replace(/ /g, "") +
              " " +
              userResponse?.data?.lastName?.replace(/ /g, "")
          );
        }
      });
      localStorage.removeItem("showAlert");
    });
  }

  const checkUserAcc = async () => {
    try {
      const providers = await firebase
        .auth()
        .fetchSignInMethodsForEmail(email.toLowerCase().replace(/ /g, ""));
      if (providers.length > 0) {
        await axios(functionBaseUrl + "/api/userInAuth", {
          method: "POST",
          data: {
            email: email.toLowerCase().replace(/ /g, ""),
          },
        }).catch((err) => {
          console.log(err);
        });

        return true;
      }
      return providers.length === 0;
    } catch (err) {
      console.log(err);
    }
  };

  const handleChange = (prop) => (event) => {
    if (prop === "height") {
      setHeight(event.target.value);
      let result = 7.1;
      if (event.target.value !== "" && gender !== "") {
        switch (gender) {
          case "MALE":
            result = maleHeightToDefaultHipWidth[event.target.value];
            break;
          case "FEMALE":
            result = femaleHeightToDefaultHipWidth[event.target.value];
            break;
          default:
            result = defaultHeightToDefaultHipWidth[event.target.value];
            break;
        }
        setHipWidth(result);
      }
    }

    if (prop === "gender") {
      setGender(event.target.value);
      let result = 7.1;
      if (event.target.value !== "" && height !== "") {
        switch (event.target.value) {
          case "MALE":
            result = maleHeightToDefaultHipWidth[height];
            break;
          case "FEMALE":
            result = femaleHeightToDefaultHipWidth[height];
            break;
          default:
            result = defaultHeightToDefaultHipWidth[height];
            break;
        }
        setHipWidth(result);
      }
    }

    if (prop === "handicap") {
      setHandicap(event.target.value);
    }

    if (prop === "shotShape") {
      setShotShape(event.target.value);
    }

    if (prop === "desiredShotShape") {
      setDesiredShotShape(event.target.value);
    }
  };

  const validatePassword = (password) => {
    const verify = /^(?=.*\d)(?=.*[.!@#$%^&*])(?=.*[a-z]).{8,}$/;
    return verify.test(password);
  };

  const validateEmail = (email) => {
    const verify = /\S+@\S+\.\S+/;
    return verify.test(email);
  };

  const dobFormat = (dob) => {
    let [year, month, day] = dob.split("-");
    return month + "/" + day + "/" + year;
  };

  const handleSignUp = async () => {
    if (!userFirstName) {
      Swal.fire({
        title: '<p style="font-size:70%;">Please enter your First Name</p>',
        icon: "warning",
        confirmButtonText: "Ok",
        allowOutsideClick: true,
      });
      return;
    }
    if (!userLastName) {
      Swal.fire({
        title: '<p style="font-size:70%;">Please enter your Last Name</p>',
        icon: "warning",
        confirmButtonText: "Ok",
        allowOutsideClick: true,
      });
      return;
    }
    if (!email) {
      Swal.fire({
        title: '<p style="font-size:70%;">Please enter your Email</p>',
        icon: "warning",
        confirmButtonText: "Ok",
        allowOutsideClick: true,
      });
      return;
    }
    if (!validateEmail(email)) {
      Swal.fire({
        title: '<p style="font-size:70%;">Please enter a valid Email</p>',
        icon: "warning",
        confirmButtonText: "Ok",
        allowOutsideClick: true,
      });
      return;
    }
    if (!password) {
      Swal.fire({
        title: '<p style="font-size:70%;">Please enter Password</p>',
        icon: "warning",
        confirmButtonText: "Ok",
        allowOutsideClick: true,
      });
      return;
    }
    if (!validatePassword(password)) {
      Swal.fire({
        title: '<p style="font-size:70%;">Password must contain:</p>',
        html: `
        <ul>
          <li>• At least 8 characters</li>
          <li>• At least one number</li>
          <li>• A special character (e.g., !@#$%&*)</li>
        </ul>
        `,
        icon: "warning",
        confirmButtonText: "Ok",
        allowOutsideClick: true,
      });
      return;
    }
    if (!confirmPassword) {
      Swal.fire({
        title:
          '<p style="font-size:70%;">Please enter Password Confirmation</p>',
        icon: "warning",
        confirmButtonText: "Ok",
        allowOutsideClick: true,
      });
      return;
    }
    if (password !== confirmPassword) {
      Swal.fire({
        title: '<p style="font-size:70%;">Passwords do not match</p>',
        icon: "warning",
        confirmButtonText: "Ok",
        allowOutsideClick: true,
      });
      return;
    }
    if (!height) {
      Swal.fire({
        title: '<p style="font-size:70%;">Please enter Height</p>',
        icon: "warning",
        confirmButtonText: "Ok",
        allowOutsideClick: true,
      });
      return;
    }
    setIsLoading(true);
    const noUsers = await checkUserAcc();
    if (noUsers) {
      await firebase
        .auth()
        .createUserWithEmailAndPassword(
          email.toLowerCase().replace(/ /g, ""),
          password
        )
        .then(async (creds) => {
          await firebase
            .auth()
            .signInWithEmailAndPassword(
              email.toLowerCase().replace(/ /g, ""),
              password
            )
            .catch((err) => {
              console.log(err);
            });

          await axiosWithToken(functionBaseUrl + "/api/userEmailVerf", {
            method: "PATCH",
            data: {
              id: creds.user.uid,
              status: true,
            },
          });

          await axios(functionBaseUrl + "/api/userMobile", {
            method: "POST",
            data: {
              uid: creds.user.uid,
              firstName: userFirstName.replace(/ /g, ""),
              lastName: userLastName.replace(/ /g, ""),
              userName:
                userFirstName.replace(/ /g, "") +
                " " +
                userLastName.replace(/ /g, ""),
              email: email.toLowerCase().replace(/ /g, ""),
              averageScore: 0,
              goals: [],
              dob: "",
              height: height,
              hipSize: hipWidth,
              gender: gender,
              dominantHand: dominantHand,
              picture: "",
              role: "",
              weeklyPractices: 0,
              coachAddress: { city: "", state: "", country: "" },
              coachFacility: [
                {
                  name: "",
                  address: "",
                  students: 0,
                  lessons: 0,
                  environment: [],
                },
              ],
              certification: [],
              userConsent: true,
              subscriptionType: listOfSubscriptions.STUDENT_FREE,
            },
          }).catch((err) => {
            console.log(err);
          });

          if (window.location.href.includes("/mark-crossfield")) {
            await axios(functionBaseUrl + "/api/updateUserNY/" + creds.user.uid, {
              method: "POST",
              data: {
                userInfo: {
                  handicap: handicap,
                  shotShape: shotShape,
                  desiredShotShape: desiredShotShape,
                  mc_marketingEmail: marketingAgree,
                },
              },
            }).catch((err) => {
              console.log(err);
            });
          }

          setIsLoading(false);
          finishSignUp(
            creds.user.uid,
            email.toLowerCase().replace(/ /g, ""),
            userFirstName.replace(/ /g, "") +
              " " +
              userLastName.replace(/ /g, "")
          );
        })
        .catch((err) => {
          setIsLoading(false);
          Swal.fire({
            title: '<p style="font-size:70%;">' + err.message + "</p>",
            icon: "warning",
            confirmButtonText: "Ok",
            allowOutsideClick: false,
          });
          return;
        });
    } else {
      Swal.fire({
        title:
          '<p style="font-size:70%;">User with provided email already exists. Please sign in to accept the offer.</p>',
        confirmButtonText: "Ok",
        allowOutsideClick: true,
        icon: "warning",
        showCancelButton: false,
        showCloseButton: false,
      }).then(() => {
        if (window.location.href.includes("/mark-crossfield")) {
          history.push("/sign-in?redirectTo=mark-crossfield");
        } else if (window.location.href.includes("/holiday-offer")) {
          history.push("/sign-in?redirectTo=holiday-offer");
        }
      });
      setIsLoading(false);
    }
  };

  return (
    <>
      {!isLoading && (
        <Grid container spacing={2} className="offer-signup">
          <Grid item xs={12} className="offer-block-caption-wrapper">
            <span className="offer-block-caption">Sign Up</span>
          </Grid>
          <Grid item xs={12} style={{ textAlign: "center" }}>
            <Button
              className="offer-button"
              variant="contained"
              color="primary"
              onClick={() => {
                if (window.location.href.includes("/mark-crossfield")) {
                  history.push("/sign-in?redirectTo=mark-crossfield");
                } else if (window.location.href.includes("/holiday-offer")) {
                  history.push("/sign-in?redirectTo=holiday-offer");
                }
                localStorage.setItem("showAlert", "true");
              }}
            >
              Already have an account?
            </Button>
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              required
              className="offer-input"
              label="First Name"
              onChange={(event) => setUserFirstName(event.target.value)}
              value={userFirstName}
              variant="outlined"
              inputProps={{ maxLength: 254 }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              required
              className="offer-input"
              label="Last Name"
              onChange={(event) => setUserLastName(event.target.value)}
              value={userLastName}
              variant="outlined"
              inputProps={{ maxLength: 254 }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              required
              className="offer-input"
              label="Email Address"
              onChange={(event) => setEmail(event.target.value)}
              value={email}
              variant="outlined"
              inputProps={{ maxLength: 254 }}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth className="offer-input" variant="outlined">
              <InputLabel htmlFor="outlined-adornment-password">
                Password *
              </InputLabel>
              <OutlinedInput
                label="Password"
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      edge="end"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
                required
                id="outlined-adornment-password"
                onChange={(event) => setPassword(event.target.value)}
                type={showPassword ? "text" : "password"}
                value={password}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth className="offer-input" variant="outlined">
              <InputLabel htmlFor="outlined-adornment-password">
                Confirm Password *
              </InputLabel>
              <OutlinedInput
                required
                id="checkPassword"
                label="Confirm Password"
                onChange={(event) => setConfirmPassword(event.target.value)}
                type={showPassword ? "text" : "password"}
                value={confirmPassword}
              />
            </FormControl>
            {confirmPassword.length > 0 && password !== confirmPassword && (
              <Typography style={{ color: "red" }}>
                The passwords do not match
              </Typography>
            )}
          </Grid>
          <Grid item xs={6}>
            <FormControl component="fieldset" fullWidth>
              <FormLabel style={{ color: "white" }} component="legend">
                Gender*
              </FormLabel>
              <RadioGroup
                id="gender"
                name="gender"
                value={gender}
                onChange={(event) => setGender(event.target.value)}
                row
              >
                <FormControlLabel
                  style={{ color: "white", marginRight: "50px" }}
                  value="FEMALE"
                  control={<Radio style={{ color: "white" }} />}
                  label={<p style={{ color: "white" }}>Female</p>}
                />
                <FormControlLabel
                  style={{ color: "white" }}
                  value="MALE"
                  control={<Radio style={{ color: "white" }} />}
                  label={<p style={{ color: "white" }}>Male</p>}
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl component="fieldset" fullWidth>
              <FormLabel style={{ color: "white" }} component="legend">
                Dominant Hand*
              </FormLabel>
              <RadioGroup
                id="domHand"
                name="dominantHand"
                value={dominantHand}
                onChange={(event) => setDominantHand(event.target.value)}
                row
              >
                <FormControlLabel
                  style={{ color: "white", marginRight: "70px" }}
                  value="Left"
                  control={<Radio style={{ color: "white" }} />}
                  label={<p style={{ color: "white" }}>Left</p>}
                />
                <FormControlLabel
                  style={{ color: "white" }}
                  value="Right"
                  control={<Radio style={{ color: "white" }} />}
                  label={<p style={{ color: "white" }}>Right</p>}
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={window.location.href.includes("/mark-crossfield") ? 6 : 12}>
            <FormControl className="offer-input" variant="outlined" fullWidth>
              <InputLabel className="offer-input" id="height-label">
                Height*
              </InputLabel>
              <Select
                className="offer-input"
                fullWidth
                id="height"
                label="Height"
                labelId="height-label"
                name="height"
                onChange={handleChange("height")}
                value={height}
                variant="outlined"
              >
                {heightValues.map((el) => (
                  <MenuItem value={el}>{el}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          {window.location.href.includes("/mark-crossfield") && (<><Grid item xs={6}>
            <FormControl className="offer-input" variant="outlined" fullWidth>
              <InputLabel className="offer-input" id="handicap-label">
                Handicap*
              </InputLabel>
              <Select
                className="offer-input"
                fullWidth
                id="handicap"
                label="Handicap"
                labelId="handicap-label"
                name="handicap"
                onChange={handleChange("handicap")}
                value={handicap}
                variant="outlined"
              >
                <MenuItem value="">Beginner (no handicap)</MenuItem>
                <MenuItem value="0-5">0 - 5</MenuItem>
                <MenuItem value="6-10">6 - 10</MenuItem>
                <MenuItem value="11-15">11 - 15</MenuItem>
                <MenuItem value="16-20">16 - 20</MenuItem>
                <MenuItem value="20+">20+</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl className="offer-input" variant="outlined" fullWidth>
              <InputLabel className="offer-input" id="shotShape-label">
                What is your current shot shape?*
              </InputLabel>
              <Select
                className="offer-input"
                fullWidth
                id="shotShape"
                label="Shot Shape"
                labelId="shotShape-label"
                name="shotShape"
                onChange={handleChange("shotShape")}
                value={shotShape}
                variant="outlined"
              >
                <MenuItem value="Draw">Draw</MenuItem>
                <MenuItem value="Fade">Fade</MenuItem>
                <MenuItem value="Straight">Straight</MenuItem>
                <MenuItem value="Slice">Slice</MenuItem>
                <MenuItem value="Hook">Hook</MenuItem>
                <MenuItem value="Chunk/Thin">Chunk/Thin</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl className="offer-input" variant="outlined" fullWidth>
              <InputLabel className="offer-input" id="desiredShotShape-label">
                What is your desired shot shape?*
              </InputLabel>
              <Select
                className="offer-input"
                fullWidth
                id="desiredShotShape"
                label="Desired Shot Shape"
                labelId="desiredShotShape-label"
                name="desiredShotShape"
                onChange={handleChange("desiredShotShape")}
                value={desiredShotShape}
                variant="outlined"
              >
                <MenuItem value="Draw">Draw</MenuItem>
                <MenuItem value="Fade">Fade</MenuItem>
                <MenuItem value="Straight">Straight</MenuItem>
              </Select>
            </FormControl>
          </Grid></>)}
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  value={tosAgree}
                  onClick={() => setTosAgree(!tosAgree)}
                  checked={tosAgree ? "checked" : null}
                />
              }
              label={
                <div className="offer-text">
                  <span>I have read and agree to Sportsbox's </span>
                  <Link
                    className="offer-link"
                    onClick={async (e) => {
                      e.preventDefault();
                      window.open(
                        window.location.href.replace(
                          "offer",
                          "terms-of-service"
                        )
                      );
                    }}
                  >
                    Terms of Service
                  </Link>
                  <span> and </span>
                  <Link
                    className="offer-link"
                    onClick={async (e) => {
                      e.preventDefault();
                      window.open(
                        window.location.href.replace("offer", "privacy")
                      );
                    }}
                  >
                    {" "}
                    Privacy Policy.
                  </Link>
                </div>
              }
            />
          </Grid>
          {window.location.href.includes("/mark-crossfield") && (<Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  value={marketingAgree}
                  onClick={() => setMarketingAgree(!marketingAgree)}
                  checked={marketingAgree ? "checked" : null}
                />
              }
              label={
                <div className="offer-text">
                  <span>
                    Yes, sign me up for emails, the newsletter, and allow my
                    swing videos to be featured!
                    <Button
                      color="primary"
                      style={{
                        color: "white",
                        textDecoration: "underline",
                        textTransform: "lowercase",
                      }}
                      onClick={() => setInfo(!info)}
                    >
                      (see more)
                    </Button>
                    <br />
                    {info && (
                      <div>
                        <span style={{ fontStyle: "italic" }}>
                          Stay in the Loop with Mark Crossfield!
                        </span>
                        <br />
                        <span style={{ fontStyle: "italic" }}>
                          Join our community and be the first to receive
                          exclusive content, training tips, and updates from
                          Mark Crossfield. By ticking this box, you agree to:
                        </span>
                        <ul
                          style={{ listStyleType: "disc", paddingLeft: "20px" }}
                        >
                          <li style={{ fontStyle: "italic" }}>
                            &#x2022; Receive marketing emails and exclusive
                            content from Mark Crossfield.
                          </li>
                          <li style={{ fontStyle: "italic" }}>
                            &#x2022; Subscribe to Mark Crossfield's newsletter
                            to stay up-to-date on the latest insights and
                            offers.
                          </li>
                          <li style={{ fontStyle: "italic" }}>
                            &#x2022; Allow Mark to feature your swing videos on
                            his social channels and YouTube, helping others
                            learn and improve along with you!
                          </li>
                        </ul>
                      </div>
                    )}
                  </span>
                </div>
              }
            />
          </Grid>)}
          <Grid item xs={12} style={{ textAlign: "center" }}>
            <Button
              className="offer-button"
              disabled={
                tosAgree === false ||
                email.length === 0 ||
                userFirstName.length === 0 ||
                userLastName.length === 0 ||
                password.length === 0 ||
                confirmPassword.length === 0 ||
                height.length === 0 ||
                (window.location.href.includes("/mark-crossfield") && 
                  (handicap.length === 0 ||
                  shotShape.length === 0 ||
                  desiredShotShape.length === 0)
                )
              }
              variant="contained"
              onClick={() => handleSignUp()}
            >
              Create An Account
            </Button>
          </Grid>
        </Grid>
      )}
      {isLoading && (
        <Container maxWidth="xl" style={{ textAlign: "center", padding: 200 }}>
          <CircularProgress />
        </Container>
      )}
    </>
  );
}

export const SignUp = SignUpComponent;
