import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import "firebase/performance";
import "firebase/messaging";
import "firebase/analytics";
import axios from "axios";

const envConfig = (devValue, prodValue) => {
  if (process.env.REACT_APP_ENVIRONMENT === "DEVELOPMENT") return devValue;
  if (process.env.REACT_APP_ENVIRONMENT === "PRODUCTION") return prodValue;

  return devValue;
};

export const functionBaseUrl = envConfig(
  "https://us-central1-sportsbox-development.cloudfunctions.net",
  "https://us-central1-sportsbox-3dgolf.cloudfunctions.net"
);

export const websiteUrl = envConfig(
  "https://sportsbox-development.web.app",
  "https://3dgolf.sportsbox.ai"
);

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = envConfig(
  {
    apiKey: "AIzaSyDjBC8TeAIhgEArDEZPkmzl0R68ygaBqCc",
    authDomain: "sportsbox-development.firebaseapp.com",
    projectId: "sportsbox-development",
    storageBucket: "sportsbox-development.appspot.com",
    messagingSenderId: "994120652577",
    appId: "1:994120652577:web:aa34b9cea0e40c17f61060",
    measurementId: "G-24EEBMEYZ2",
  },
  {
    apiKey: "AIzaSyB5V1vlPZrGE9BJ5qQQ2olSnBkne7B4u9I",
    authDomain: "sportsbox-3dgolf.firebaseapp.com",
    projectId: "sportsbox-3dgolf",
    storageBucket: "sportsbox-3dgolf.appspot.com",
    messagingSenderId: "249376958558",
    appId: "1:249376958558:web:c162108d4efb7a472daccf",
    measurementId: "G-M3DPV0E61S",
  }
);

export const storageBucketURL = envConfig(
  "https://console.firebase.google.com/u/0/project/sportsbox-development/storage/sportsbox-development.appspot.com/files/~2Fswing_data/",
  "https://console.firebase.google.com/u/0/project/sportsbox-3dgolf/storage/sportsbox-3dgolf.appspot.com/files/~2Fswing_data/"
);

export const esIndex = envConfig(
  "docs",
  "docs-prod"
);

export const riIndex = envConfig(
  "report_issues",
  "report_issues-prod"
);

export const stripeKey = envConfig(
  "pk_test_51JS3jaHK8QnjuqRNSlSVNOX9mD3Be02vIQZb2S5HHozM145GjpeVvuSHadBPcQSnJfHpZvPBnPmgHeFwXqjk7M5100Ez2ZTngL",
  "pk_live_51JS3jaHK8QnjuqRNKU8o7ZtGtUeF03nX0szre7AcvaiZYntkJrVweqbd12NNnSfkXonSTUFc33zAMhGNnr0PnHu400upbbHg6u"
);

export const loginWithGoogle = () => {
  let provider = new firebase.auth.GoogleAuthProvider();
  provider.setCustomParameters({
    prompt: "select_account",
  });
  return firebase.auth().signInWithPopup(provider);
};

export const loginWithFacebook = () => {
  const provider = new firebase.auth.FacebookAuthProvider();
  return firebase.auth().signInWithPopup(provider);
};

export const loginWithApple = () => {
  var provider = new firebase.auth.OAuthProvider("apple.com");
  return firebase.auth().signInWithPopup(provider);
};

export const axiosWithToken = async (url, options) => {
  const user = firebase.auth().currentUser;
  if (user) {
    const token = await user.getIdToken(true);
    return await axios(url, {
      ...options,
      withCredentials: false,
      headers: {
        authorization: "Bearer " + token,
      },
    });
  }

  const currentUser = localStorage.getItem("currUser");
  if (currentUser) {
    return await axios(url, {
      ...options,
      withCredentials: false,
      headers: {
        authorization: "Bearer " + currentUser,
      },
    });
  }
  throw new Error("User is not signed in");
};

/*export const getToken = () => {
  return firebase.messaging().getToken({ vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY }).then((currentToken) => {
    if (currentToken) {
      //console.log('current token for client: ', currentToken);
      // Track the token -> client mapping, by sending to backend server
      // show on the UI that permission is secured
    } else {
      console.log('No registration token available. Request permission to generate one.');
      // shows on the UI that permission is required 
    }
  }).catch((err) => {
    console.log('An error occurred while retrieving token. ', err);
    // catch error while creating client token
  });
}

export const onMessageListener = () =>
  new Promise((resolve) => {
    firebase.messaging().onMessage((payload) => {
      resolve(payload);
    });
});*/

firebase.initializeApp(firebaseConfig);
firebase.analytics();

const perf = firebase.performance();

firebase
  .firestore()
  .enablePersistence()
  .catch(function (err) {
    if (err.code === "failed-precondition") {
      // Multiple tabs open, persistence can only be enabled
      // in one tab at a a time.
      // ...
      console.log(err);
    } else if (err.code === "unimplemented") {
      // The current browser does not support all of the
      // features required to enable persistence
      // ...
      console.log(err);
    }
  });

export default firebase;
