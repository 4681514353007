import React from "react";

export const ReportIndicatorCardSequencing = ({
  percentage,
  indicatorName,
  indicatorsArray,
  valueToBaseColorOn,
}) => {
  const colorsMapped = {
    red: "#FFFBFA",
    green: "#F6FEF9",
    orange: "#FFFCF5",
  };

  const valueColorsMapped = {
    red: "#B42318",
    green: "#027A48",
    orange: "#B54708",
  };

  const transitionOrder = [
    "PlvTrnSpdOrder",
    "ChstTrnSpdOrder",
    "LUArmSwngSpdOrder",
    "ShftSwngSpdOrder",
  ];

  const movementOrder = [
    "PlvSwyTranOrder",
    "PlvTrnTranOrder",
    "PlvLftTranOrder",
  ];

  const color = valueToBaseColorOn === 100 ? "green" : "orange";

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "5px",
        border: "#D2D6DB 1px solid",
        borderRadius: "6px",
        background: colorsMapped[color],
        width: "150px",
      }}
    >
      <div style={{ marginBottom: "5px" }}>
        <span style={{ fontWeight: "bold" }}>
          {percentage > 100 ? 100 : percentage < 0 ? 0 : percentage}
        </span>
        /100
      </div>
      <div
        style={{
          fontSize: indicatorName.length < 20 ? "10px" : "9px",
          fontWeight: "bold",
          textAlign: "center",
        }}
      >
        {indicatorName}
      </div>
      <div
        style={{
          display: "flex",
          gap: "5px",
          alignItems: "center",
          padding: "10px 0 10px",
        }}
      >
        <div
          style={{
            width: "100%",
            fontSize: "14px",
            fontWeight: "bold",
            textAlign: "center",
            color: valueColorsMapped[color],
            display: "flex",
            gap: "5px",
          }}
        >
          {indicatorName === "Transition Order"
            ? indicatorsArray
                .filter((el) => {
                  return transitionOrder.includes(el.indicatorId);
                })
                .sort((a, b) => a.value - b.value)
                .map((el) => {
                  return (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "10px",
                      }}
                    >
                      <div>{el.value}</div>
                      <div style={{ fontSize: "10px" }}>
                        {el.name.split(" ")[0]}
                      </div>
                    </div>
                  );
                })
            : indicatorsArray
                .filter((el) => {
                  return movementOrder.includes(el.indicatorId);
                })
                .sort((a, b) => a.value - b.value)
                .map((el) => {
                  return (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "10px",
                      }}
                    >
                      <div>{el.value}</div>
                      <div style={{ fontSize: "10px" }}>
                        {el.name.split(" ")[1]}
                      </div>
                    </div>
                  );
                })}
        </div>
      </div>
    </div>
  );
};
