import React from "react";

import { Grid, Stepper, Step, StepLabel } from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";

import "./offerPaid.css";
import { listOfSubscriptions } from "../../common/envConfig";

function CTASelectOfferComponent({ productId }) {
  return (
    <>
      <Stepper activeStep={2} className="cta-mobile-stepper">
        <Step key={1} completed={false}>
          <StepLabel></StepLabel>
        </Step>
        <Step key={2} completed={false}>
          <StepLabel></StepLabel>
        </Step>
        <Step key={2} completed={false} className="inactive">
          <StepLabel></StepLabel>
        </Step>
      </Stepper>
      {window.location.href.includes("/mark-crossfield") && (
        <>
          <div className="cta-caption">
            Try 3d golf
            <span>10% off</span>
          </div>
          <div className="cta-invitational-benefits-wrapper">
            <ul className="cta-invitational-benefits">
              <li>
                <CheckIcon className="cta-benefit-mark" /> Access to 100+
                practice guides
              </li>
              <li>
                <CheckIcon className="cta-benefit-mark" /> Identify areas of
                improvement with 3D analysis
              </li>
              <li>
                <CheckIcon className="cta-benefit-mark" /> Track progress with
                swing assessments
              </li>
            </ul>
            {window.location.href.includes("/invitational") && (
              <div className="cta-invitational">
                <img
                  alt="Invitational"
                  src="/images/offer/invitational.png"
                  width={350}
                />
              </div>
            )}
          </div>
          <div className="cta-total">
            <Grid container spacing={2} className="cta-total-today">
              <Grid item xs={6}>
                Billed Today
              </Grid>
              <Grid item xs={6} className="cta-total-sum">
                $
                {productId === listOfSubscriptions.STUDENT_LITE_ANNUALLY
                  ? "99 annually"
                  : "14.39 monthly"}
              </Grid>
            </Grid>
          </div>
        </>
      )}

      {window.location.href.includes("/holiday-offer") && (
        <>
          {productId === "swing_analysis" && (
            <>
              <div className="cta-caption">
                3D Remote Lesson <br />
                <span>30% off</span>
              </div>

              <div className="cta-invitational-benefits-wrapper">
                <ul className="cta-invitational-benefits">
                  <li>
                    Been asking to be connected to a Sportsbox coach? <br />
                  </li>
                  <li style={{ fontSize: "150%" }}>
                    VIRTUAL 3D LESSON WITH A SPORTSBOX EXPERT COACH
                  </li>
                  <li>
                    <CheckIcon className="cta-benefit-mark" /> Swing analysis
                    video from a 3D expert
                  </li>
                  <li>
                    <CheckIcon className="cta-benefit-mark" /> Custom goal for
                    your swing fix
                  </li>
                  <li>
                    <CheckIcon className="cta-benefit-mark" /> Recommended drill
                  </li>
                </ul>
              </div>

              <div className="cta-total" style={{ marginTop: "-15%" }}>
                <Grid container spacing={2} className="cta-total-today">
                  <Grid item xs={6}>
                    Billed Today
                  </Grid>
                  <Grid item xs={6} className="cta-total-sum">
                    <s>$50</s> $35
                  </Grid>
                </Grid>
              </div>

              <div className="cta-invitational">
                <img alt="App 1" src="/images/app1.png" width={300} />
                <img alt="App 2" src="/images/app2.png" width={300} />
              </div>
            </>
          )}
        </>
      )}

      {/*window.location.href.includes("/holiday-offer") && (
        <>
          {productId === "studio_offer" && (
            <>
              <div className="cta-caption">
                Sportsbox 3D Studio Camera and Software Offer
              </div>

              <div className="cta-invitational-benefits-wrapper">
                <ul className="cta-invitational-benefits">
                  <li style={{ fontSize: "150%" }}>Studio Software</li>
                  <li>
                    <CheckIcon className="cta-benefit-mark" /> Hands free
                    captures with super fast loading instant replay
                  </li>
                  <li>
                    <CheckIcon className="cta-benefit-mark" /> No motion blur -
                    best in class high speed cameras
                  </li>
                  <li>
                    <CheckIcon className="cta-benefit-mark" /> Indicator tiles
                    that auto-populate when 3D data is ready
                  </li>
                  <li>
                    <CheckIcon className="cta-benefit-mark" /> Keep swinging or
                    select from the swing list to analyze 3D video or 3D
                    analysis tools{" "}
                  </li>
                  <li>
                    <CheckIcon className="cta-benefit-mark" /> Drawing tools
                  </li>
                  <li>
                    <CheckIcon className="cta-benefit-mark" /> Compare 2 videos
                    in both 2D and 3D
                  </li>
                  <li>
                    <CheckIcon className="cta-benefit-mark" /> Free form rotate
                    avatar – Examine every angle
                  </li>
                  <li>
                    <CheckIcon className="cta-benefit-mark" /> Fully connected
                    to mobile app (mobile subscription required)
                  </li>
                </ul>
              </div>

              <div
                className="cta-invitational-benefits-wrapper"
                style={{ marginTop: "-20%" }}
              >
                <ul className="cta-invitational-benefits">
                  <li style={{ fontSize: "150%" }}>Cameras</li>
                  <li>
                    2 Models: HE & LE-C Starts at $1290 <br />
                  </li>
                  <li>
                    <CheckIcon className="cta-benefit-mark" /> No motion blur
                    and distortion through global shutter
                  </li>
                  <li>
                    <CheckIcon className="cta-benefit-mark" /> Best in class
                    low-light performance with Dynamic Range
                  </li>
                  <li>
                    <CheckIcon className="cta-benefit-mark" /> USB3.0 interface
                    to reach max frame rate in full resolution{" "}
                  </li>
                  <li>
                    <CheckIcon className="cta-benefit-mark" /> Leading-edge 3D
                    technology capable of 3D motion analysis with a single
                    camera
                  </li>
                  <li>
                    <CheckIcon className="cta-benefit-mark" /> Manual & digital
                    controls for exposure & ISO
                  </li>
                </ul>
              </div>

              <div className="cta-total" style={{ marginTop: "-15%" }}>
                <Grid container spacing={2} className="cta-total-today">
                  <Grid item xs={6}>
                    Billed Today
                  </Grid>
                  <Grid item xs={6} className="cta-total-sum">
                    <s>$1,790</s> $1,499 <br />
                    for <s>12 months</s> 18 months
                  </Grid>
                </Grid>
              </div>

              <div className="cta-invitational">
                <img alt="Studio" src="/images/studio.png" width={1000} />
              </div>
            </>
          )}
        </>
      )*/}
    </>
  );
}

export const CTASelectOffer = CTASelectOfferComponent;
