export const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT;

const envConfig = (devValue, prodValue) => {
  if (ENVIRONMENT === "DEVELOPMENT") return devValue;
  if (ENVIRONMENT === "PRODUCTION") return prodValue;

  return devValue;
};

export const plansEnvConfig = (() =>
  envConfig(
    {
      development_sportsbox_free_student: "Free",
      studentLiteMonthly: 15.99,
      development_sportsbox_student_lite_monthly: 15.99,
      studentLiteAnnually: 110,
      development_sportsbox_student_lite_annually: 110,
      liteMonthly: 79.99,
      development_sportsbox_pro_lite_monthly: 79.99,
      liteAnnually: 799.99,
      development_sportsbox_pro_lite_annually: 799.99,
      premiumMonthly: 79.99,
      development_sportsbox_pro_premium_monthly: 79.99,
      premiumAnnually: 799.99,
      development_sportsbox_pro_premium_annually: 799.99,
      playerPlusMonthly: 15.99,
      development_sportsbox_player_plus_monthly: 15.99,
      playerPlusAnnually: 110,
      development_sportsbox_player_plus_annually: 110,
    },
    {
      sportsbox_free_student: "Free",
      studentLiteMonthly: 15.99,
      sportsbox_student_lite_monthly: 15.99,
      studentLiteAnnually: 110,
      sportsbox_student_lite_annually: 110,
      liteMonthly: 79.99,
      sportsbox_pro_lite_monthly: 79.99,
      liteAnnually: 799.99,
      sportsbox_pro_lite_annually: 799.99,
      premiumMonthly: 79.99,
      sportsbox_pro_premium_monthly: 79.99,
      premiumAnnually: 799.99,
      sportsbox_pro_premium_annually: 799.99,
      playerPlusMonthly: 15.99,
      sportsbox_player_plus_monthly: 15.99,
      playerPlusAnnually: 110,
      sportsbox_player_plus_annually: 110,
    }
  ))();

export const listOfSubscriptions = (() => {
  let subsList = null;

  switch (ENVIRONMENT) {
    case "DEVELOPMENT":
      subsList = {
        STUDENT_FREE: "development_sportsbox_free_student",
        STUDENT_LITE: "development_sportsbox_student_lite",
        STUDENT_LITE_MONTHLY: "development_sportsbox_student_lite_monthly",
        STUDENT_LITE_ANNUALLY: "development_sportsbox_student_lite_annually",
        LITE_MONTHLY: "development_sportsbox_pro_lite_monthly",
        LITE_ANNUALLY: "development_sportsbox_pro_lite_annually",
        PREMIUM: "development_sportsbox_pro_premium",
        PREMIUM_MONTHLY: "development_sportsbox_pro_premium_monthly",
        PREMIUM_ANNUALLY: "development_sportsbox_pro_premium_annually",
        ENTERPRISE_MASTER: "development_sportsbox_enterprise_master",
        ENTERPRISE_COACH: "development_sportsbox_enterprise_coach",
        STUDIO_MASTER: "development_sportsbox_studio_master",
        STUDIO_COACH: "development_sportsbox_studio_coach",
        FORESIGHT_MONTHLY: "development_sportsbox_foresight_monthly",
        FORESIGHT_ANNUALLY: "development_sportsbox_foresight_annually",
        WORLD_WIDE_GOLF: "development_sportsbox_world_wide_golf",
        PLAYER_PLUS_MONTHLY: "development_sportsbox_player_plus_monthly",
        PLAYER_PLUS_ANNUALLY: "development_sportsbox_player_plus_annually",
      };

      break;
    case "PRODUCTION":
      subsList = {
        STUDENT_FREE: "sportsbox_free_student",
        STUDENT_LITE: "sportsbox_student_lite",
        STUDENT_LITE_MONTHLY: "sportsbox_student_lite_monthly",
        STUDENT_LITE_ANNUALLY: "sportsbox_student_lite_annually",
        LITE_MONTHLY: "sportsbox_pro_lite_monthly",
        LITE_ANNUALLY: "sportsbox_pro_lite_annually",
        PREMIUM: "sportsbox_pro_premium",
        PREMIUM_MONTHLY: "sportsbox_pro_premium_monthly",
        PREMIUM_ANNUALLY: "sportsbox_pro_premium_annually",
        ENTERPRISE_MASTER: "sportsbox_enterprise_master",
        ENTERPRISE_COACH: "sportsbox_enterprise_coach",
        STUDIO_MASTER: "sportsbox_studio_master",
        STUDIO_COACH: "sportsbox_studio_coach",
        FORESIGHT_MONTHLY: "sportsbox_foresight_monthly",
        FORESIGHT_ANNUALLY: "sportsbox_foresight_annually",
        WORLD_WIDE_GOLF: "sportsbox_world_wide_golf",
        PLAYER_PLUS_MONTHLY: "sportsbox_player_plus_monthly",
        PLAYER_PLUS_ANNUALLY: "sportsbox_player_plus_annually",
      };
      break;
    default:
      subsList = {
        STUDENT_FREE: "development_sportsbox_free_student",
        STUDENT_LITE: "development_sportsbox_student_lite",
        STUDENT_LITE_MONTHLY: "development_sportsbox_student_lite_monthly",
        STUDENT_LITE_ANNUALLY: "development_sportsbox_student_lite_annually",
        LITE_MONTHLY: "development_sportsbox_pro_lite_monthly",
        LITE_ANNUALLY: "development_sportsbox_pro_lite_annually",
        PREMIUM: "development_sportsbox_pro_premium",
        PREMIUM_MONTHLY: "development_sportsbox_pro_premium_monthly",
        PREMIUM_ANNUALLY: "development_sportsbox_pro_premium_annually",
        ENTERPRISE_MASTER: "development_sportsbox_enterprise_master",
        ENTERPRISE_COACH: "development_sportsbox_enterprise_coach",
        STUDIO_MASTER: "development_sportsbox_studio_master",
        STUDIO_COACH: "development_sportsbox_studio_coach",
        FORESIGHT_MONTHLY: "development_sportsbox_foresight_monthly",
        FORESIGHT_ANNUALLY: "development_sportsbox_foresight_annually",
        WORLD_WIDE_GOLF: "development_sportsbox_world_wide_golf",
        PLAYER_PLUS_MONTHLY: "development_sportsbox_player_plus_monthly",
        PLAYER_PLUS_ANNUALLY: "development_sportsbox_player_plus_annually",
      };

      break;
  }

  return subsList;
})();

export const listOfSubscriptionNames = (() => {
  let subsList = null;

  switch (ENVIRONMENT) {
    case "DEVELOPMENT":
      subsList = {
        development_sportsbox_free_student: "Free",
        development_sportsbox_student_lite_monthly: "3D Player",
        development_sportsbox_student_lite_annually: "3D Player",
        development_sportsbox_pro_lite_monthly: "Pro Lite",
        development_sportsbox_pro_lite_annually: "Pro Lite",
        development_sportsbox_pro_premium_monthly: "3D Pro",
        development_sportsbox_pro_premium_annually: "3D Pro",
        development_sportsbox_enterprise_master: "Enterprise Master",
        development_sportsbox_enterprise_coach: "Enterprise Coach",
        development_sportsbox_studio_master: "Studio Master",
        development_sportsbox_studio_coach: "Studio Coach",
        development_sportsbox_foresight_monthly: "Foresight Monthly",
        development_sportsbox_foresight_annually: "Foresight Annually",
        development_sportsbox_world_wide_golf: "World Wide Golf",
        development_sportsbox_player_plus_monthly: "Player Plus Monthly",
        development_sportsbox_player_plus_annually: "Player Plus Annually",
      };

      break;
    case "PRODUCTION":
      subsList = {
        sportsbox_free_student: "Free",
        sportsbox_student_lite_monthly: "3D Player",
        sportsbox_student_lite_annually: "3D Player",
        sportsbox_pro_lite_monthly: "Pro Lite",
        sportsbox_pro_lite_annually: "Pro Lite",
        sportsbox_pro_premium_monthly: "3D Pro",
        sportsbox_pro_premium_annually: "3D Pro",
        sportsbox_enterprise_master: "Enterprise Master",
        sportsbox_enterprise_coach: "Enterprise Coach",
        sportsbox_studio_master: "Studio Master",
        sportsbox_studio_coach: "Studio Coach",
        sportsbox_foresight_monthly: "Foresight Monthly",
        sportsbox_foresight_annually: "Foresight Annually",
        sportsbox_world_wide_golf: "World Wide Golf",
        sportsbox_player_plus_monthly: "Player Plus Monthly",
        sportsbox_player_plus_annually: "Player Plus Annually",
      };
      break;
    default:
      subsList = {
        development_sportsbox_free_student: "Free",
        development_sportsbox_student_lite_monthly: "3D Player",
        development_sportsbox_student_lite_annually: "3D Player",
        development_sportsbox_pro_lite_monthly: "Pro Lite",
        development_sportsbox_pro_lite_annually: "Pro Lite",
        development_sportsbox_pro_premium_monthly: "3D Pro",
        development_sportsbox_pro_premium_annually: "3D Pro",
        development_sportsbox_enterprise_master: "Enterprise Master",
        development_sportsbox_enterprise_coach: "Enterprise Coach",
        development_sportsbox_studio_master: "Studio Master",
        development_sportsbox_studio_coach: "Studio Coach",
        development_sportsbox_foresight_monthly: "Foresight Monthly",
        development_sportsbox_foresight_annually: "Foresight Annually",
        development_sportsbox_world_wide_golf: "World Wide Golf",
        development_sportsbox_player_plus_monthly: "Player Plus Monthly",
        development_sportsbox_player_plus_annually: "Player Plus Annually",
      };

      break;
  }

  return subsList;
})();
