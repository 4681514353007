import React, { useEffect } from 'react';
import { loadStripe } from "@stripe/stripe-js";

import Swal from 'sweetalert2';
import firebase, { functionBaseUrl, stripeKey } from '../../common/firebase';
import axios from 'axios';

const stripePromise = loadStripe(stripeKey);

function StripeUpdatePaymentComponent({ subscriptionId, onClose }) {

  const init = async () => {
    const firebaseUser = firebase.auth().currentUser;
    try {
      const response = await axios(functionBaseUrl + '/api/stripeUpdateCard', {
        method: 'POST',
        data: { email: firebaseUser.email, subscriptionId },
      });
      const stripe = await stripePromise;
      await stripe.redirectToCheckout({
        sessionId: response.data.id,
      });
    } catch (error) {
      Swal.fire({
        title: '<p style="font-size:70%;">Error occurred, please try again</p>',
        icon: 'warning',
        confirmButtonText: 'Ok',
        allowOutsideClick: true,
        customClass: {
          container : 'my-swal'
        },
      })
      onClose();
      return;
    }
  }

  useEffect(() => {
    init();
  }, []);

  return (<></>);
}

export const StripeUpdatePayment = StripeUpdatePaymentComponent;
