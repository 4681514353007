import React from "react";
import { Pad } from "../../../components";
import { FreeUsersStats } from "./FreeUsersStats";
import { PaidUsersStats } from "./PaidUsersStats";
import { RevenueStats } from "./RevenueStats";
import { StatisticsContainer } from "./StatisticsContainer";
import { UserList } from "./UserList";

export function OverallReportData({ data, onCoachClick }) {
  return (
    <Pad width="100%">
      <StatisticsContainer>
        <FreeUsersStats data={data.freeUsersStats} />
        <div style={{ outline: "1px #e3e3e3 solid" }}></div>
        <PaidUsersStats data={data.paidUsersStats} />
        <div style={{ outline: "1px #e3e3e3 solid" }}></div>
        <RevenueStats data={data.revenueStats} />
      </StatisticsContainer>
      <UserList data={data.coaches} onClick={onCoachClick} />
    </Pad>
  );
}
