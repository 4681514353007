import admin, { axiosWithToken, functionBaseUrl } from "../../common/firebase";

export const getInvites = async () => {
  const userId = admin.auth().currentUser.uid;
  const res = await axiosWithToken(
    `${functionBaseUrl}/api/invitesBySender/${userId}`
  );

  return res.data;
};

export const getInvitesReceived = async (email) => {
  const res = await axiosWithToken(
    `${functionBaseUrl}/api/allInvitesToInvitee/${email}`
  );

  return res.data;
};

export const getNotifications = async (offset) => {
  const userId = admin.auth().currentUser.uid;
  const res = await axiosWithToken(`${functionBaseUrl}/api/v1/notification`, {
    method: "POST",
    data: {
      limit: 10,
      offset,
      userId,
      isSendExpiredInviteNotifications: false,
    },
  });

  return res.data;
};

export const getExpiringSubscriptions = async () => {
  const userId = admin.auth().currentUser.uid;
  const res = await axiosWithToken(
    `${functionBaseUrl}/api/v1/expiring-subscription-students`,
    {
      method: "POST",
      data: {
        limit: 50,
        offset: 0,
        coachId: userId,
      },
    }
  );

  return res.data;
};

export const getDashboardSwingCounts = async () => {
  const userId = admin.auth().currentUser.uid;
  const res = await axiosWithToken(
    `${functionBaseUrl}/api/v1/swingsCount/${userId}`,
    {
      method: "GET",
    }
  );

  return res.data;
};

export const getDashboardUser = async () => {
  const userId = admin.auth().currentUser.uid;

  const res = await axiosWithToken(`${functionBaseUrl}/api/users/${userId}`, {
    method: "get",
  });
  const subRes = !res?.data?.subscriptionType?.includes('free') ? await axiosWithToken(
    `${functionBaseUrl}/api/subscriptionByUserId/${userId}`,
    {
      method: "get",
    }
  ) : { data: {} };
  return { ...res.data, sub: subRes.data.data, id: userId };
};

export const upgradeStudent = async (data) => {
  const res = await axiosWithToken(`${functionBaseUrl}/api/v2/upgradeStudent`, {
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      id: data.studentId,
      coachId: data.coachId,
      coachName: data.coachName,
      subPlan: data.subPlan,
      subPeriod: data.subPeriod,
      autoRenewal: data.autoRenewal,
    },
  });

  return res.data.message;
};

export const verifyStaffEnt = async (id) => {
  const res = await axiosWithToken(
    functionBaseUrl + "/api/verifyStaffEnt/" + id,
    {
      method: "POST",
    }
  ).catch((err) => {
    console.log(err);
  });

  return res.data;
};

export const resendInvite = async (id) => {
  await axiosWithToken(functionBaseUrl + "/api/resendInvite/" + id, {
    method: "POST",
  }).catch((err) => {
    console.log(err);
  });
};

export const removeStudent = async (id) => {
  const userId = admin.auth().currentUser.uid;
  await axiosWithToken(`${functionBaseUrl}/api/removeStudent`, {
    method: "delete",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      studentId: id,
      coachId: userId,
    },
  });
};

export const removePendingStudent = async (inviteId) => {
  await axiosWithToken(`${functionBaseUrl}/api/invite/${inviteId}`, {
    method: "delete",
  });
};

export const checkNewAccount = async (id) => {
  const buffer = await axiosWithToken(functionBaseUrl + '/api/incompleteUserBuffer/' + id, {
    method: 'GET'
  })
    .catch(err => {
      console.log(err)
    });

  return buffer?.data;
}
