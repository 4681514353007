import React, { useState, useRef, useEffect } from 'react';
import { 
  Grid,
  Button,
} from '@material-ui/core';
import { axiosWithToken, functionBaseUrl } from '../../common/firebase';
import styles from "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";

import VideoPlayer from './components/video_player'
import SwingDetails from './components/swing_details/swing_details';
import ChatPanel from './components/chat_panel';
import SwingSelect from './components/swing_select';
import JsonLoader from './components/json_loader';

function LLMChat() {  
  const [jsonData, setJsonData] = useState([]); 
  const [fileIdx, setFileIdx] = useState(0);
  const [messages, setMessages] = useState([]);
  const [models, setModels] = useState([]);
  const [model, setModel] = useState('');
  const [goldsets, setGoldsets] = useState([]);
  const [goldset, setGoldset] = useState('');
  const [toggleDetails, setToggleDetails] = useState(false);
  const [selectModalOpen, setSelectModalOpen] = useState(false);
  const [selectSwingIdx, setSelectSwingIdx] = useState([]);
  const [loadingMessage, setLoadingMessage] = useState(false);
  const [goldsetDescription, setGoldsetDescription] = useState("");

  const videoRef = useRef(null);
  
  const [defaultParameters, setDefaultParameters] = useState({
    temperature: 1,
    max_tokens: 256,
    top_p: 1,
    frequency_penalty: 0,
    presence_penalty: 0
  })

  useEffect(() => {    
    async function fetchData() {
      await getAvailableModels().then((avail_models) => {        
        if (avail_models.length > 0) { // Set model to be latest                              
          setModel(avail_models[avail_models.length-1].id)
          setModels(avail_models)
        }
      })

      const avail_goldsets = await getAvailableGoldsets()      
      if (avail_goldsets && avail_goldsets.length > 0) {
        setGoldsets(avail_goldsets)            
      }
    }
    fetchData()
  }, []);  

  const getResponse = async (newMessage) => {    
    const {openAIResponse, fc_messages} = await callOpenAI(newMessage.message);      
    if (openAIResponse && openAIResponse.choices && openAIResponse.choices.length > 0) {
      const response = openAIResponse.choices[0].message.content;
      const csv_attachment = openAIResponse.choices[0].message.csv_attachment
      const message = {
        message: response,
        csv_attachment: csv_attachment,
        sentTime: "just now",
        sender: "assistant",    
        direction: "incoming",
      }      
      setMessages([...messages, newMessage, ...fc_messages, message])
    }
  };

  async function getAvailableGoldsets() {
    return axiosWithToken(functionBaseUrl+'/api/llm/get-available-goldsets', {    
      method: 'get',      
    }).then(response => {
      let data = response.data            
      return data.available_files
    })
    .catch(err => {
      console.log(err.response?.data)
      return []
    });   
  }

  async function getAvailableModels() {
    return axiosWithToken(functionBaseUrl+'/api/llm/get-available-models', {    
      method: 'get',
    }).then(response => {
      let data = response.data      
      return data
    })
    .catch(err => {
      console.log(err.response?.data)
      return 0
    });   
  }  

  async function callOpenAI(userInput) {
    let message_payload = []

    if (jsonData[fileIdx]['system_message']) {
      message_payload = [
        {
            role: "system",
            content: [{ text: jsonData[fileIdx]['system_message'], type: "text" } ]
        }        
      ]
    }

    // for (let message of messages) {
    //   if (message.message) {
    //     message_payload.push({
    //       role: message.sender,
    //       content: [{ text: message.message, type: "text" }]
    //     })
    //   }
    //   else {
    //     message_payload.push(message)
    //   }
    // }

    message_payload.push({
      role: "user",
      content: [{ text: userInput, type: "text" }]
    })    

    let attached_swings = null

    if (selectSwingIdx.length > 1) {
      let batch_data = []
      selectSwingIdx.forEach((idx) => {
        batch_data.push({
          indicators: jsonData[idx].indicators,
          notes: jsonData[idx].notes
        })
      })
      attached_swings = {
        'target': jsonData[fileIdx].indicators,
        'objective': null,
        'batch': batch_data
      }
    }
    else if (selectSwingIdx.length == 1) {
      attached_swings = {
        'target': jsonData[fileIdx].indicators,
        'objective': jsonData[selectSwingIdx[0]].indicators,
        'batch': null
      }
    }
    else {
      attached_swings = {
        'target': jsonData[fileIdx].indicators,
        'objective': null,
        'batch': null
      }
    }

    const club_type = jsonData[fileIdx].club_type
    const user_id = jsonData[fileIdx].studentId
    
    setLoadingMessage(true);

    return axiosWithToken(functionBaseUrl+'/api/llm/get-completion', {
      method: 'post',
      data: {
        model: model,
        user_id: user_id,
        messages: JSON.stringify(message_payload),
        ...defaultParameters,        
        attached_swings,
        club_type
      }
    }).then(response => {      
      let openAIResponse = response.data.response      
      let fc_messages = response.data.new_messages      
      setSelectSwingIdx([]);
      setLoadingMessage(false);
      return {openAIResponse, fc_messages}
    })
    .catch(err => {
      setLoadingMessage(false);
      console.log(err.response?.data)
      let openAIResponse = {
        "choices": [
          {
            "message": {
              "content": "There was an error processing your message, please try again."
            }
          }
        ]
      }
      let fc_messages = []
      return {openAIResponse, fc_messages}
    });

  }  

  const cacheConversation = (oldIdx, newIdx) => {
    if (messages.length > 0) {
      jsonData[oldIdx]['messages'] = messages
    }
    
    if (jsonData[newIdx]['messages']) {
      setMessages(jsonData[newIdx]['messages'])
    }
    else {
      setMessages([])
    }
    
    setJsonModel(jsonData[newIdx].model);
    setJsonParameters(jsonData[newIdx].model_parameters);
  }

  const setJsonModel = (model) => {    
    if (model != null && models.some(obj => obj.id === model)) {      
      setModel(model)
    }
  }

  const setJsonParameters = (params) => {    
    if (params != null) {      
      setDefaultParameters(params);
    }
  }

  const handleGoldsetChange = (event) => {
    setGoldset(event.target.value);
  }

  useEffect(() => {
    if (jsonData.length > 0 && videoRef.current) {
      videoRef.current.src = jsonData[fileIdx].video_url;
      videoRef.current.load(); // Reload the video element
    }
  }, [fileIdx, jsonData]);

  const handleToggleDetails = () => {
    setToggleDetails(!toggleDetails);
  }

  const downloadCSV = (csvData) => {
    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'data.csv'); // Set the file name
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleExport = () => {
    cacheConversation(fileIdx, fileIdx)
    let exportJsonData = []
    for (let swing of jsonData) {      
      if (swing['messages']) {
          let save_data = []
          if (swing['system_message']) {
            save_data.push({
              "role": "system",
              "content": swing['system_message']
            })
          }
          for (let message of swing['messages']) {
            if (message.message) {
              save_data.push({
                "role": message.sender,
                "content": message.message
              })
            }
            else {
              save_data.push(message)
            }
          }
          exportJsonData.push({
              "session_id": swing['session_id'],
              "model_parameters": defaultParameters,
              "messages": save_data
          })
        }
    }
    const dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(exportJsonData, null, 2));
    const downloadAnchorNode = document.createElement('a');
    downloadAnchorNode.setAttribute("href", dataStr);
    downloadAnchorNode.setAttribute("download", "chat_data.json");
    document.body.appendChild(downloadAnchorNode); // Required for Firefox
    downloadAnchorNode.click();
    downloadAnchorNode.remove();        
  }
  
  return (<>
    <div style={{ padding: 20, overflow: "hidden"}}>      
      <div style={{ padding: 20, overflow: "hidden"}}>
        {jsonData?.length <= 0 && (
          <JsonLoader 
            goldsets={goldsets}
            goldset={goldset}
            handleGoldsetChange={handleGoldsetChange}
            setJsonData={setJsonData}
            setJsonModel={setJsonModel}            
            fileIdx={fileIdx}
            setJsonParameters={setJsonParameters}
            setGoldsetDescription={setGoldsetDescription}
          />
        )}
        {jsonData?.length > 0 && (
          <Button onClick={()=>{ setJsonData([]); setMessages([]); setFileIdx(0) }}>Back</Button>
        )}
        <Grid
          container
          spacing={2}
          padding={10}
        >
          <Grid
            item
            xs={6}
          >
            {
              jsonData?.length > 0 && !toggleDetails && (
                <VideoPlayer
                  jsonData={jsonData}
                  fileIdx={fileIdx}
                  setFileIdx={setFileIdx}
                  toggleDetails={toggleDetails}
                  setToggleDetails={setToggleDetails}
                  videoRef={videoRef}
                  cacheConversation={cacheConversation}
                />
              )
            }
            {
              jsonData?.length > 0 && toggleDetails && (
                <SwingDetails
                  jsonData={jsonData}
                  setJsonData={setJsonData}
                  fileIdx={fileIdx}
                  defaultParameters={defaultParameters}
                  setDefaultParameters={setDefaultParameters}
                  handleToggleDetails={handleToggleDetails}
                  goldsetDescription={goldsetDescription}
                />                
              )
            }
          </Grid>
          <Grid
            item
            xs={6}
          >
            {
              jsonData?.length > 0 && (
                <ChatPanel
                  jsonData={jsonData}
                  messages={messages}
                  setMessages={setMessages}
                  model={model}
                  models={models}
                  setModel={setModel}
                  downloadCSV={downloadCSV}
                  selectSwingIdx={selectSwingIdx}
                  setSelectModalOpen={setSelectModalOpen}
                  getResponse={getResponse}
                  handleExport={handleExport}
                  loadingMessage={loadingMessage}
                />
              )
            }
          </Grid>
          <SwingSelect
            selectModalOpen={selectModalOpen}
            setSelectModalOpen={setSelectModalOpen}
            jsonData={jsonData}
            setSelectSwingIdx={setSelectSwingIdx}
            selectSwingIdx={selectSwingIdx}
          />
        </Grid>      
      </div>
    </div>
  </>);
}

export const LLMChatPage = LLMChat;
