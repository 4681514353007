import React from 'react';

import { Grid, Stepper, Step, StepLabel, Link } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';

import './speedAssessment.css';

function CTAPaymentComponent() {
  return (<>
    <Stepper activeStep={2} className="cta-mobile-stepper">
      <Step key={1} completed={false}>
        <StepLabel></StepLabel>
      </Step>
      <Step key={2} completed={false} className="inactive">
        <StepLabel></StepLabel>
      </Step>
    </Stepper>
    <div className="cta-caption">
      Unlock <span>speed</span> with Sportsbox
    </div>
    <div className="cta-subcaption">
      Make use of expert coaching resources and best-in-class 3D
      analysis to take your golf game to the next level.
    </div>
    <div>
      {window.location.href.includes("speed-assessment-human") ? 
        <ul className="cta-benefits">
          <li>
            <span>What you'll get in the program:</span>
          </li>
          <li>
            <CheckIcon className="cta-benefit-mark" /><span>3D Speed Assessment Report: Created by world renowned biomechanist - Dr. Phil Cheetham - who has helped the best players and long drive champions to optimize their swing for distance ($150 value)</span>
          </li>
          <li>
            <CheckIcon className="cta-benefit-mark" /><span>Video analysis from 3D expert coach: Detailed explanation of your speed assessment ($75 value)</span>
          </li>
          <li>
            <CheckIcon className="cta-benefit-mark" /><span>Goals in Sportsbox: Top power leaks created as GOALS in Sportsbox for you to keep tracking</span>
          </li>
          <li>
            <CheckIcon className="cta-benefit-mark" /><span>Custom Recommended Drills: Depending on your top power leaks, 3D expert coach will assign drills for you to work on</span>
          </li>
          <li>
            <CheckIcon className="cta-benefit-mark" /><Link
              className="offer-link"
              href="https://shop.sportsbox.ai/products/gpod-travel-1"
              target="_blank"
            >
              GPOD Travel for Magsafe
            </Link>&nbsp;($75 value)
          </li>
        </ul>
        :
        <ul className="cta-benefits">
          <li>
            <span>What you'll get in the program:</span>
          </li>
          <li>
            <CheckIcon className="cta-benefit-mark" /><span>3D Speed Assessment Report ($200 value)</span>
          </li>
          <li>
            <CheckIcon className="cta-benefit-mark" /><span>Detailed data / explanation of your speed assessment ($25 value)</span>
          </li>
          <li>
            <CheckIcon className="cta-benefit-mark" /><span>Goals in Sportsbox: Top power leaks created as GOALS in Sportsbox for you to keep tracking</span>
          </li>
          <li>
            <CheckIcon className="cta-benefit-mark" /><span>Custom Recommended Drills: Depending on your top power leaks, AI will assign drills for you to work on</span>
          </li>
          <li>
            <CheckIcon className="cta-benefit-mark" /><Link
              className="offer-link"
              href="https://shop.sportsbox.ai/products/gpod-travel-1"
              target="_blank"
            >
              GPOD Travel for Magsafe
            </Link>&nbsp;($75 value)
          </li>
        </ul>
      }
    </div>
    <div className="cta-total">
      <Grid container spacing={2} className="cta-total-today">
        <Grid
          item
          xs={6}
        >
          Billed Today
        </Grid>
        <Grid
          item
          xs={6}
          className="cta-total-sum"
        >
          <s>$300.00 USD</s> $150.00 USD
        </Grid>
      </Grid>
    </div>
  </>);
}

export const CTAPayment = CTAPaymentComponent;