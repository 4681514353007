import React, {Component} from 'react';
import firebase from '../../common/firebase'
import {Link as RouterLink} from 'react-router-dom';
import PropTypes from 'prop-types';
import {Grid, Button, Container, Avatar, TextField, Link, 
  Typography, CssBaseline, MenuItem, CircularProgress, LinearProgress} from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import {functionBaseUrl, axiosWithToken} from '../../common/firebase';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import {AccountNotFound} from '../account/components';
import {withStyles} from '@material-ui/styles';
import Dropzone from 'react-dropzone';
import CameraMeta from './CameraMeta';
import UserMeta from './UserMeta';
import VideoFileTable from './VideoFileTable';
import Swal from 'sweetalert2';
import NoSubscriptionDialog from '../../components/NoSubscriptionDialog';

const useStyles = theme => ({
  paper: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(8),
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  root: {
    marginTop: theme.spacing(4),
  },
  buttonLink: {  // This is a link component surrounding a button component
    width: '60px',
    maxWidth: '20%'
  },
  linkedButton: {  // This is a button component surrounded by a link
    margin: theme.spacing(1, 0),
  },
  centeredText: {
    textAlign: 'center',
  }
});
class VideoUpload extends Component {
  constructor(props) {
    const firebaseUser = firebase.auth().currentUser;
    super(props)
    this.state = {
      popUpOpen: false,
      userPopUpOpen: false,
      uid : firebaseUser ? firebaseUser.uid : "",
      userEmail : firebaseUser ? firebaseUser.email : "",
      error : "",
      loading : true,
      success : false,
      viewFiles: false,
      uploading : false,
      session: '',
      uploadPercentage : 0,
      videoFiles: [],
      uploadTask: null,
      pendingFiles: [],
      paused: false,
      checker: true,
      uploadChecker: 0,
      currentVideoFileIndex: 0,
      hasPlayerInfo: false,
      userData: {},
      userName: '',
      subData: {},
      role: '',
      subscriptionType: '',
      fullName: '',
      sessions: [],
      activeSub: true,
      isStaffEnterprise: false,
      isCertified: true,
      onboardingCompleted: true,
      dialogLoading: true,
    }
  }

  async componentDidMount() {
    const user = firebase.auth().currentUser;
    if (!user) {   
      this.setState({error : "Please log in to submit a session"});
      this.setState({loading : false});
    }
    else {
      const userDoc = await firebase.firestore().collection("users").doc(this.state.uid).get();
      if (!userDoc.exists && this.state.uid !== "") {
        console.log('No matching documents for UID: ' + this.state.uid);
        this.setState({error : "The signed in user does not exist"});
      }
      this.setState({ role: userDoc.data().role })

      if (userDoc.data().enterpriseAccountId && userDoc.data().enterpriseAccountId) {
        const entUser = await firebase.firestore().collection("enterpriseAccounts").doc(userDoc.data().enterpriseAccountId).get();
        const entData = entUser.data();
        /*if (entData && 'autoRenewal' in entData && entData.autoRenewal === false) {
          this.setState({ isStaffEnterprise: true })
          await axiosWithToken(functionBaseUrl + '/api/teachableUser/' + this.state.uid, {
            method: 'GET',
          })
            .catch(err => {
              console.log(err)
            });
        }
        if (userDoc.data().certification && (userDoc.data().certification.includes('Sportsbox') || userDoc.data().certification.includes('Sportsbox 3D Golf Level 1 Certification'))) {
          this.setState({ isCertified: true })
        } else {
          this.setState({ isCertified: false })
        }
        if ('onboardingCompleted' in entData) {
          this.setState({ onboardingCompleted: entData.onboardingCompleted })
        }*/
      }
      this.setState({ dialogLoading: false })

      axiosWithToken(functionBaseUrl+'/api/users/' + user.uid + '/sessions,sessionCameraSetups', {
        method: 'get',
      }).then(response => {
        const cameraSetups = response.data.cameraSetups ? response.data.cameraSetups.reduce((result, item) => {return {...result, [item.id]: item}}, {}) : {}
        const sessions = response.data.sessions.reduce((result, item) => {return {...result, [item.id]: item}}, {})
        this.setState({ loading : false, fullName: response.data.userInfo.firstName + ' ' + response.data.userInfo.lastName, role: response.data.userInfo.role, subscriptionType: response.data.userInfo.subscriptionType, sessions: sessions, cameraSetups: cameraSetups, popUpOpen: false, userPopUpOpen: false });
        if (response.data.userInfo.subscriptionType.includes('free')) {
          Swal.fire({
            title: '<p style="font-size:70%;">You cannot upload videos with your current plan!</p>',
            icon: 'warning',
            confirmButtonText: 'Ok',
            allowOutsideClick: true,
          });
          return false;
        } else {
          this.subscriptionStatus()
        }
      })
      .catch(err => {
        console.log(err)
        this.setState({loading: false, error : "There was an error retrieving your info"});
      });
    }    
  }

  subscriptionStatus = () => {
    axiosWithToken(functionBaseUrl+'/api/verifySub/' + this.state.uid, {
      method: 'post'
    }).then(response => {
      this.setState({
        subData: response.data.data
      })
      if (this.state.role !== 'admin' && 'success' in response.data) {
        this.setState({ activeSub: response.data.success })
      }
    })
    .catch(err => {
      console.log(err)
    });
  }

  handleChange = (prop) => (event) => {
    this.setState({[prop]: event.target.value});
  }

  handleSession = () => {
    let check = localStorage.getItem('check');
    if (check && typeof(Storage) !== "undefined") {
      this.setState({session: localStorage.getItem('sess')});
      window.localStorage.clear();
      check = false;
    }
    return this.state.session;
  }

  handleVideoChange = (files) => {
    if (this.state.session) {
      const skipped = []
      const pendingFiles = files.map((file) => {
        if (files && !this.state.videoFiles.find(videoFile=>videoFile.file.name.includes(file.name))) {
          return file
        } else {
          skipped.push(file.name)
          console.log('skipped file named ' + file.name + ' because it was either empty or already uploaded')
        }
      })
      if (skipped.length > 0) {
        if (skipped.length > 1) {
          alert('Skipped ' + skipped.length + ' videos because they have already been uploaded')
        } else {
          alert('File with name "' + skipped[0] + '" has already been uploaded so it will be skipped')
        }
      }
      if (skipped.length < files.length) {
        this.setState({pendingFiles: pendingFiles, popUpOpen: true});
      }
    } else {
      alert('Please select session')
    }
  }

  handlePopUpOpen = () => {
    this.setState({popUpOpen: true});
  }
  handlePopUpClose = () => {
    this.setState({pendingFiles: [], popUpOpen : false});
  }
  handleUserButton = (event) => {
    this.setState({userPopUpOpen: true});
  }
  handleUserPopUpClose = () => {
    this.setState({pendingFiles: [], userPopUpOpen : false});
  }

  handleClick = () => {
    let el = document.getElementById("fileElem");
    if (el) {
      el.click();
    }
  };

  pauseUpload = () => {
    this.state.uploadTask.pause();
    this.setState({
      paused: true
    });
  };

  resumeUpload = () => {
    this.state.uploadTask.resume();
    this.setState({
      paused: false
    });
  };

  cancelUpload = () => {
    this.state.uploadTask.cancel();
    this.setState({
      paused: false,
      uploading: false,
      success: false,
      uploadChecker: 0,
      uploadPercentage: 0,
      currentVideoFileIndex: 0
    });
  };

  deleteFile = (file) => (event) => {
    // fastest way to delete an element from a state list
    this.setState({videoFiles: this.state.videoFiles.filter(function(videoFile) { 
        return videoFile.file !== file 
    })});
  }

  deleteMultipleFiles = (files) => {
    const videoFiles = this.state.videoFiles
    files.forEach((file) => {
      videoFiles.splice(file.tableData.id, 1)
    })
    this.setState({videoFiles: videoFiles})
  }

  uploadVideo = async (videoFile) => {
    this.setState({
      currentVideoFileIndex: this.state.currentVideoFileIndex + 1,
      uploadTask: null,
      uploadPercentage: 0,
    });
    try {
      const today = new Date();
      const dateString = "" + today.getFullYear()+ (today.getMonth()+1) + today.getDate();
      const uniqueID = this.state.userEmail.substring(0, 4) + (today.getMonth()+1) + today.getDate() + today.getTime();
      // upload video to firebase storage
      const uploadTask = firebase.storage().ref('/video_upload/web/' + this.state.fullName + '/' + dateString + "/" + uniqueID).put(videoFile.file)
      this.setState({
        uploadTask: uploadTask
      });
      
      // change upload percentage as the bytes are transferred
      uploadTask.on('state_changed', (snapshot) => {
        let newPercentage = Math.round(snapshot.bytesTransferred * 100 / snapshot.totalBytes);
        // we don't want to show the percentage of 100 because it could be confusing to a user clicking the pause button
        if (newPercentage > 99) {
          newPercentage = 99;
          this.setState({
            uploading: false,
            success: true
          });
        }
        /*
        after a pause, firebase sometimes reports an incorrect lower percentage. to make sure that the user
        isn't confused, we only update the percentage if the percentage has increased. 
        */
        if (this.state.uploadPercentage < newPercentage) {
          this.setState({
            uploadPercentage: newPercentage
          });
        }
      });
      const video = document.createElement('video')
      let videoLength = 0
      video.preload = 'metadata'
      video.onloadedmetadata = () => {
        window.URL.revokeObjectURL(video.src)
        videoLength = video.duration
      }
      video.src = URL.createObjectURL(videoFile.file)
      await uploadTask;
      const url = await firebase.storage().ref('/video_upload/web/' + this.state.fullName + '/' + dateString).child(uniqueID).getDownloadURL();
      console.log("Successfully uploaded video to storage!")
      const output = {
        metaData: videoFile.metaData,
        videoPath: url,
        parentSessionId: this.state.session,
        parentUserId: this.state.uid,
        videoIsSplit: false,  // we need to figure out how to get this from the video
        videoSize: String(videoFile.file.size),
        videoOrigName: videoFile.file.name,
        videoLength: String(videoLength),  // we need to figure out how to get this from the video
        videoType: videoFile.file.type,
        actionIds: '',
        userData: {},
        favorite: false,
        videoSource: 'Web'
      }
      await axiosWithToken(functionBaseUrl +'/api/videos', {
        method: 'POST',
        data: output
      });
      console.log("Successfully created video db entry!");
      // update the videoPath in the videos firestore collection
    } catch(err) {
      alert('Upload failed')
      console.log(err)
    }
  }
  handleMetaDataSubmit = (metaData) => {
    this.state.pendingFiles.map((file) => {
      const video = document.createElement('video')
      video.preload = 'metadata'
      video.onloadedmetadata = () => {
        window.URL.revokeObjectURL(video.src)
        const videoFiles = this.state.videoFiles
        videoFiles.push({file: file, videoLength: video.duration || 0, metaData: metaData})
        this.setState({videoFiles: videoFiles, pendingFiles: [], popUpOpen: false})
      }
      video.src = URL.createObjectURL(file)
    })
  }

  handleUserDataSubmit = (userData) => {
    this.setState({userData: userData, userPopUpOpen: false, hasPlayerInfo: true, userName: userData.fullName})
  }

  updateVideos = (videoFiles) => {
    this.setState({videoFiles: videoFiles})
  }

  handleToggleShowVideos = () => {
    if (!this.state.session){
      alert('Please select a session')
    } else {
      this.setState({viewFiles: !this.state.viewFiles})
    }
  }

  handleVideoUpload = async (event) => {
    event.preventDefault();

    this.setState({
      uploading: true,
      success: false,
      uploadChecker: 0,
      currentVideoFileIndex: 0
    });

    for (const videoFile of this.state.videoFiles) {
      try {
        await this.uploadVideo(videoFile);
      }
      catch (error) {
        console.log("Error uploading video: " + error.message)
        this.setState({
          paused: false,
          uploading: false,
          success: false,
          uploadChecker: 0,
          uploadPercentage: 0,
          currentVideoFileIndex: 0
        });
        return;
      }
    }
  }

  render() {
    const videoFilesList = this.state.videoFiles.map(videoFile => (
      <li key={videoFile.file.name}>
        <table style={{width:"100%"}}>
          <tbody>
            <tr>
              <td>
                {videoFile.file.name} - {videoFile.file.size} bytes
              </td>
              <td>
                <Button style={{color:"red", float:'right'}} onClick={this.deleteFile(videoFile.file)}>
                  Delete
                </Button>
              </td>
            </tr>
          </tbody>
        </table>
      </li>
    ));
    return (<div>
      <NoSubscriptionDialog
        activeSub={this.state.activeSub}
        isStaffEnterprise={this.state.isStaffEnterprise}
        isCertified={this.state.isCertified}
        onboardingCompleted={this.state.onboardingCompleted}
        role={this.state.role}
        loading={this.state.dialogLoading}
        subscriptionType={this.state.subscriptionType}
      />
      {this.state.role !== 'Sportsbox-User' ?
      <Container
        component="main"
        className={this.props.classes.paper}
      >
        <CssBaseline />
        { this.state.cameraSetups && !this.state.loading ?
        <div style={{width: '100%'}}>
          <div
            style = {{width: '100%', display: (this.state.error || this.state.loading || this.state.success || this.state.uploading) ? "none" : "flex", 
              flexDirection: 'column',
              alignItems: 'center',}}
          >
            <Avatar className={this.props.classes.avatar}>
              <CloudUploadIcon />
            </Avatar>
            <Typography
              component="h1"
              variant="h4"
            >
              VIDEO UPLOAD
            </Typography>
            { this.state.session ?
              <Grid
                style = {{margin : "auto", width: '100%', display: (this.state.viewFiles) ? "block" : "none"}}
              >
                <VideoFileTable 
                  classes={this.props.classes} 
                  cameraSetup={this.state.cameraSetups[this.state.sessions[this.state.session].cameraSetupId]} 
                  updateVideos={this.updateVideos} videos={this.state.videoFiles} 
                  handleToggleShowVideos={this.handleToggleShowVideos}
                  deleteMultipleFiles={this.deleteMultipleFiles}/>
              </Grid> : null}
            <div style={{display: (!this.state.viewFiles) ? "block" : "none"}}>
              <form
                className={this.props.classes.form}
                onSubmit={this.handleVideoUpload}
              >
                <div className={this.props.classes.root}>
                  <Typography
                    inline='true'
                    variant="h6"
                    align="left"
                  >
                    SESSION
                  </Typography>
                  <TextField
                    fullWidth
                    select
                    label="Session"
                    margin="normal"
                    name="session"
                    onChange={this.handleChange('session')}
                    required
                    value={this.handleSession()}
                    variant="outlined"
                    disabled={Object.keys(this.state.sessions).length === 0}
                  >
                    <MenuItem value=''>
                      <em>None</em>
                    </MenuItem>
                    {Object.keys(this.state.sessions).map((id) => (
                      <MenuItem key={id} value={id}>
                        {this.state.sessions[id].sessionName}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
                <Grid container>
                  <Grid
                    item
                  >
                    <div style={{color: "red", display: (this.state.sessions.length === 0) ? "inline-block" : "none"}}>
                      Error: You have not created any sessions yet.

                      Please <Link
                        component={RouterLink}
                        to="/session-details"
                        variant="body2"
                      >
                        {' create a session'}
                      </Link> to upload a video.
                    </div>

                    <Link
                      style={{display: (this.state.sessions.length === 0) ? "none" : "block"}}
                      component={RouterLink}
                      to="/session-details"
                      variant="body2"
                    >
                      {'Create another session'}
                    </Link>
                  </Grid>
                </Grid>
                <div className={this.props.classes.root}>
                  <Grid
                    item
                    sm={12}
                    xs={12}
                  >
                    <Button display={'inline'} fullWidth variant="contained" color="secondary" onClick={this.handleUserButton}>
                      Add player information
                    </Button>
                  </Grid>
                </div>
                <div className={this.props.classes.root}>
                  <Grid
                    item
                    sm={12}
                    xs={12}
                    style = {{display: this.state.hasPlayerInfo ? "block" : "none"}}
                  >
                    <UserMeta
                      open={this.state.userPopUpOpen}
                      closePopUp={this.handleUserPopUpClose}
                      handleSubmit={this.handleUserDataSubmit}
                    />
                    <div style={{display: this.state.hasPlayerInfo ? 'flex' : 'none', width: '100%', justifyContent: 'space-between', paddingRight: '4px'}}>
                      <Typography variant="h3" display={'inline'}>{this.state.userName}</Typography>
                      <Button display={'inline'} color="primary" size="small" onClick={this.handleUserButton}>
                        Edit
                      </Button>
                    </div>
                  </Grid>
                </div>

                <Dropzone
                  disabled={!this.state.session}
                  onDrop={this.handleVideoChange}
                  maxSize={1000000000}
                  accept={'video/*'}
                  multiple
                >
                  {({getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject}) => (
                    <section className="container">
                      <div {...getRootProps({className: 'dropzone'})} style={{
                        flex: 1,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        padding: "20px",
                        borderWidth: "2px",
                        borderRadius: "2px",
                        borderColor: (isDragActive) ? "#2196f3" : (isDragAccept) ? "#00e676" : (isDragReject) ? "#ff1744" : "rgba(0, 0, 0, 0.38)",
                        borderStyle: "dashed",
                        backgroundColor: "#fafafa",
                        color: "rgba(0, 0, 0, 0.38)",
                        outline: "none",
                        transition: "border .24s ease-in-out",
                        margin: "30px 0px"
                      }}>
                        <input {...getInputProps()} />
                        <p>Drag and drop your files here, or click to select files</p>
                      </div>
                      <aside>
                        <div style={{display: this.state.videoFiles.length > 0 ? 'flex' : 'none', width: '100%', justifyContent: 'space-between', paddingRight: '8px'}}>
                          <h4>Files</h4>
                          <Link onClick={this.handleToggleShowVideos}>Edit Videos</Link>
                        </div>
                        <ul style={{listStyleType: "none"}}>{videoFilesList}</ul>
                      </aside>
                    </section>
                  )}
                </Dropzone>
                
                <Button
                  className={this.props.classes.submit}
                  color="primary"
                  fullWidth
                  type="submit"
                  variant="contained"
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                >
                  UPLOAD
                </Button>
              </form>
            </div>
          </div>

          {/* Uploading screen*/}
          <Grid
            style = {{margin : "auto", padding : 100, display: (this.state.uploading) ? "block" : "none"}}
          >
            <Typography className={this.props.classes.centeredText} variant="h4">
              Uploading ({this.state.currentVideoFileIndex} of {this.state.videoFiles.length})
            </Typography>
            <br />
            <LinearProgress variant="determinate" value={this.state.uploadPercentage} />
            <br />
            <Typography className={this.props.classes.centeredText} variant="h4">
              {this.state.uploadPercentage}%
            </Typography>
            <br />
            <br />
            
            <Grid
              container
              spacing={2}
            >
              <Grid
                item
                sm={6}
                xs={12}
              >
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={(this.state.paused) ? this.resumeUpload : this.pauseUpload}
                >
                {(this.state.paused) ? "Resume" : "Pause"}
                </Button>
              </Grid>
              <Grid
                item
                sm={6}
                xs={12}
              >
                <Button
                  fullWidth
                  variant="contained"
                  color="secondary"
                  onClick={this.cancelUpload}
                >
                Cancel
                </Button>
              </Grid>
            </Grid>
          </Grid>
          {/* Success screen*/}
          <Grid
            style = {{margin : "auto", paddingTop : 100, display: (this.state.success) ? "block" : "none"}}
          >
            <div className={this.props.classes.paper}>
              <Avatar className={this.props.classes.avatar}>
                <CheckCircleIcon />
              </Avatar>
              <Typography component="h1" variant="h2">
                Success!
              </Typography>

              <Typography component="h5" align = "center">
                Successfully uploaded your video(s).
              </Typography>
              <Link component={RouterLink} to="/video-upload">
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={this.props.classes.linkedButton}
                  onClick={() => {
                    this.setState({ success: false })
                  }}
                >
                  Upload more videos
                </Button>
              </Link>
              <Link component={RouterLink} to="/dashboard">
                <Button
                fullWidth
                  variant="contained"
                  color="primary"
                  className={this.props.classes.linkedButton}
                >
                  Back To Dashboard
                </Button>
              </Link>
            </div>
          </Grid>
          {/* Error screen*/}
          <Grid
            item
            lg={12}
            md={12}
            xl={12}
            xs={12}
            style = {{display: (this.state.error && !this.state.loading) ? "block" : "none"}}
          >
            <AccountNotFound            
              error = {{error : this.state.error}}
            />
          </Grid>
          { this.state.session ?
            <CameraMeta
              open={this.state.popUpOpen}
              closePopUp={this.handlePopUpClose}
              cameraSetup={this.state.cameraSetups[this.state.sessions[this.state.session].cameraSetupId]}
              handleSubmit={this.handleMetaDataSubmit}
            /> : null }
        </div> :
          <Grid
            style = {{margin : "auto", padding : 100, display: (this.state.loading) ? "block" : "none"}}
          >
            <CircularProgress />
          </Grid>}
      </Container>
      : <Grid
          item
          lg={12}
          md={12}
          xl={12}
          xs={12}
          //style={{ display: (this.state.error && !this.state.loading) ? "block" : "none" }}
        >
          <AccountNotFound
            error={{ error: "You are not authorized to access this page" }}
          />
        </Grid>
      }</div>
    );
  }
};

VideoUpload.propTypes = {
  classes:PropTypes.object,
  history: PropTypes.object,
};

export default withStyles(useStyles)(VideoUpload);