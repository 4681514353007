import { axiosWithToken, functionBaseUrl } from "../../common/firebase";

export const getWeeklyReport = async ({ from, to }) => {
  const { data } = await axiosWithToken(
    functionBaseUrl + `/api/utility/weekly-user-report?from=${from}&to=${to}`,
    {
      method: "post",
    }
  );

  return data;
};
