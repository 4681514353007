import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import DescriptionIcon from '@material-ui/icons/Description';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import HelpIcon from '@material-ui/icons/Help';
import {
  Grid,
  Box,
  Container,
  Typography,
  Divider,
} from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
    paper: {
      marginTop: theme.spacing(8),
      marginBottom: theme.spacing(8),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',        
    },
    title: {
      padding: theme.spacing(4)
    },
    nested: {
      paddingLeft: theme.spacing(2)
    },
    step: {
      paddingTop: theme.spacing(1)
    }
}));

const Help = (props) => {

  const classes = useStyles();

  const [open1, setOpen1] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const [open3, setOpen3] = React.useState(false);
  const [open4, setOpen4] = React.useState(false);
 
  return (
    <Container
      component="main"
      maxWidth="md"
    >
        <Grid 
        className={classes.paper}
        Container
        >
          <Grid item>
            <HelpIcon color="primary" fontSize="large"/>
          </Grid>
          <Grid
          item
          className={classes.title}
          >
            <Typography variant="h2">Help Page</Typography>
          </Grid>
          <Grid item>
            <Divider/>
            <Box textAlign="center" style={{width:600}} className={classes.title}>
              SPORTSBOX AI data acquisition tool designed to upload videos to SPORTSBOX AI cloud for analysis and training purposes. User needs to create a session first so videos will be associated with this session. Users can add videos to existing sessions. 
            </Box>
          </Grid>

          <Grid item style={{width:700}}>
            <Divider/>
            <List
            component="nav"
            aria-labelledby="nested-list-subheader"
            >

              <ListItem button onClick={() => {setOpen1(!open1)}}>
                <ListItemIcon>
                  <AccountCircleIcon color={open1 ? "primary" : "inherit"}/>
                </ListItemIcon>
                <ListItemText primary="How to create an account" />
                {open1 ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={open1} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItem>
                    <ListItemText>
                      <ol className={classes.nested}>
                        <li className={classes.step}> Click on “First time here? Create an account” link to create an account and follow instructions</li>
                      </ol>
                    </ListItemText>
                  </ListItem>
                </List>
              </Collapse>

              <ListItem button onClick={ () => {setOpen2(!open2)}}>
                <ListItemIcon>
                  <DescriptionIcon color={open2 ? "primary" : "inherit"}/>
                </ListItemIcon>
                <ListItemText primary="How to create a session" />
                {open2 ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={open2} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItem>
                    <ListItemText>
                      <ol className={classes.nested}>
                        <li className={classes.step}> Click on “Session” link  to create new session and follow instructions</li>
                      </ol>
                    </ListItemText>
                  </ListItem>
                </List>
              </Collapse>

              <ListItem button onClick={ () => {setOpen3(!open3)}}>
                <ListItemIcon>
                  <CloudUploadIcon color={open3 ? "primary" : "inherit"}/>
                </ListItemIcon>
                <ListItemText primary="How to upload videos" />
                {open3 ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={open3} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItem>
                    <ListItemText>
                      <ol className={classes.nested}>
                        <li className={classes.step}> Click on the “Upload” link</li>
                        <li className={classes.step}> Click on session to elect session associated with your videos</li>
                        <li className={classes.step}> Click on “Upload video files here”</li>
                        <li className={classes.step}> Select videos from the folder</li>
                        <li className={classes.step}> Click upload button</li>                        
                      </ol>
                    </ListItemText>
                  </ListItem>
                </List>
              </Collapse>

              <ListItem button onClick={ () => {setOpen4(!open4)}}>
                <ListItemIcon>
                  <InboxIcon color={open4 ? "primary" : "inherit"}/>
                </ListItemIcon>
                <ListItemText primary="How to view your account" />
                {open4 ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={open4} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItem>
                    <ListItemText>
                      <ol className={classes.nested}>
                        <li className={classes.step}> Click on “Account” link to see your account information</li>
                        <li className={classes.step}> On this page you can review and update your account information</li>
                      </ol>
                    </ListItemText>
                  </ListItem>
                </List>
              </Collapse>
          
            </List>
          </Grid>
          <Grid item>
            <Divider/>
            <Box textAlign="center" style={{width:600}} className={classes.title}>
              If you're unable to find what you're looking for in the Help Page, we suggest emailing us at <a href="mailto:support@sportsbox.ai">support@sportsbox.ai</a> for more help, reporting a bug or any other questions/suggestions.
            </Box>
          </Grid>
        </Grid>     
    </Container>
  );
};

// Help.propTypes = {
//   history: PropTypes.object,
// };

export default Help;
