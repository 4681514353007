import React from "react";
import { withStyles } from "@material-ui/styles";

import "./plan.css";

import {
  Grid,
  Button,
  Typography,
  TextField,
  FormControlLabel,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  createMuiTheme,
  MuiThemeProvider,
  styled,
  TableHead,
} from "@material-ui/core";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import { listOfSubscriptions } from "../../common/envConfig";
import CheckIcon from "@material-ui/icons/Check";
import { Close } from "@material-ui/icons";
import { isMobile } from "react-device-detect";
const freeTrialPromoCodes = ["5IRON3D", "SB3DDryvebox", "SB3DPNGC", "MICHELLE"];

const theme = createMuiTheme({
  tableCell: {
    display: "flex",
    alignItems: "center",
  },
  typography: {
    fontSize: 16,
    h1: {
      fontSize: 30,
    },
    h2: {
      fontSize: 28,
    },
    h3: {
      fontSize: 26,
    },
    h4: {
      fontSize: 24,
    },
    h5: {
      fontSize: 22,
    },
  },
});

const useStyles = (theme) => ({
  paper: {
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(2),
    background: "#000C66",
    width: "100%",
  },
  newFeature: {
    background: "#000000",
    borderLeft: "10px solid #3b46ff",
    padding: theme.spacing(2),
  },
  tableHeader: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    fontWeight: "bold",
  },
  link: {
    color: "#fff",
    textDecoration: "underline",
  },
  link2: {
    color: "#7f39fb",
  },
  titleText: {
    color: "white",
    fontSize: "2.5rem",
    width: "200px",
    marginBottom: theme.spacing(2),
    textDecoration: "underline",
    textDecorationColor: "#3b46ff",
    textUnderlineOffset: "5px",
    fontFamily: "Anton",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  root: {
    marginTop: theme.spacing(1),
  },
  buttonLink: {
    // This is a link component surrounding a button component
    width: "100%",
  },
  linkedButton: {
    // This is a button component surrounded by a link
    margin: theme.spacing(2),
  },
  title: {
    textAlign: "center",
    fontFamily: "arial, sans-serif",
  },
});

const TableCellCenter = styled(TableCell)({
  textAlign: "center",
  borderLeft: "1px solid #e0e0e0",
});

const TableCellName = styled(TableCell)({
  width: isMobile ? 200 : 300,
});

const Check = styled(CheckIcon)({
  color: "#007be8",
});

const PlansView = (props) => {
  const [tab, setTab] = React.useState(null);
  if (tab === null && !props.loading) {
    if (
      props.subscriptionType &&
      (props.subscriptionType === "" ||
        [
          listOfSubscriptions.STUDENT_LITE_MONTHLY,
          listOfSubscriptions.STUDENT_LITE_ANNUALLY,
        ].includes(props.subscriptionType))
    ) {
      setTab("student");
    } else {
      setTab("coach");
    }
    if (props.tab && props.tab === "student") {
      setTab("student");
    }
    let couponCode = window.location.href;
    couponCode = couponCode.split("couponCode=")[1];
    if (freeTrialPromoCodes.includes(couponCode)) {
      setTab("student");
    }
  }
  const planData = {
    free: {
      title: "Free",
      buttonTitle: "Get Started",
      month: "Free",
      year: "Free",
      numStudents: "None",
      coachLicense: "None",
      devices: "1",
      sessions: "1 sample session",
      cloud: <Close />,
      trackers: "Limited",
      pracWatch: "Only 1 popular Watchlist",
    },
    practice: {
      title: "3D Player",
      buttonTitle: "Get Started",
      text: "Plan automatically renews until canceled",
      month: "$15.99/month",
      year: (
        <p>
          $9/month <br /> ($110 billed annually)
        </p>
      ),
      numStudents: "None",
      coachLicense: "None",
      devices: "1",
      sessions: "7/week",
      cloud: "5GB",
      trackers: "Limited",
      pracWatch: "Max of 3 watchlists at any time",
    },
    lite: {
      title: "3D Pro",
      buttonTitle: "Subscribe Now",
      buttonTitle2: "Start 14-day trial",
      text: "Plan automatically renews until canceled",
      month: "$65/month",
      year: (
        <p>
          $54/month <br /> ($650 billed annually)
        </p>
      ),
      //month: <p><strike>$65/month</strike><br /><b style={{ fontSize: "125%", color: "#0d47a1" }}>20% off - $52/month</b><br /><p style={{ color: "#0d47a1" }}>For the first 3 months</p></p>,
      //year: <p><strike>$54/month <br /> ($650 billed annually)</strike> <br /> <b style={{ fontSize: "125%", color: "#0d47a1" }}>20% off - $43/month <br /> ($520 billed annually)</b></p>,
      numStudents: "15 students",
      coachLicense: "1",
      devices: "1/user",
      sessions: "Sessions capped by cloud storage",
      cloud: "50GB",
      trackers: "All (Except Kinematic Sequence Max Speeds)",
    },
    premium: {
      title: "3D Pro",
      buttonTitle: "Subscribe Now",
      buttonTitle2: "Start 14-day trial",
      text: "Plan automatically renews until canceled",
      month: "$189/month",
      year: (
        <p>
          $134/month <br />
          ($1600 billed annually)
        </p>
      ),
      //month: <p><strike>$189/month</strike><br /><b style={{ fontSize: "125%", color: "#0d47a1" }}>20% off - $151.20/month</b><br /><p style={{ color: "#0d47a1" }}>For the first 3 months</p></p>,
      //year: <p><strike>$158/month <br /> ($1890 billed annually)</strike> <br /> <b style={{ fontSize: "125%", color: "#0d47a1" }}>40% off - $94.50/month <br /> ($1134 billed annually)</b></p>,
      numStudents: "Unlimited students",
      coachLicense: "1",
      devices: "2/user",
      sessions: "Sessions capped by cloud storage",
      cloud: "200GB(~20K swings)",
      trackers: "All + Kinematic Sequence Indicators",
    },
    enterprise: {
      title: (
        <p>
          Enterprise <br /> (Up to 3 accounts)
        </p>
      ),
      buttonTitle: "Contact Sales",
      month: "",
      year: (
        <>
          $98/month/coach <br />
          ($3500 billed annually)
        </>
      ),
      numStudents: "Unlimited students",
      coachLicense: "Up to 3",
      devices: "2/user",
      sessions: "Sessions capped by cloud storage",
      cloud: "1TB(~100K swings)",
      trackers: "All + Kinematic Sequence Indicators",
    },
  };

  const getPlanData = (onlyKeys = false) => {
    let filter = [];
    let filterSub = [];
    if (props.useTabs) {
      if (tab === "coach") {
        filter = ["lite", "premium", "enterprise"];
      } else {
        filter = ["free", "practice"];
      }
    }
    if (
      props.subscriptionType?.includes("free") ||
      props.subscriptionType === ""
    ) {
      filterSub = ["free", "practice", "lite", "premium", "enterprise"];
    } else if (
      [
        listOfSubscriptions.STUDENT_LITE_MONTHLY,
        listOfSubscriptions.STUDENT_LITE_ANNUALLY,
      ].includes(props.subscriptionType)
    ) {
      filterSub = ["free", "practice"];
    } else {
      filterSub = ["free", "lite", "premium", "enterprise"];
    }
    if (onlyKeys) {
      return Object.keys(planData)
        .filter((plan) => !props.useTabs || filter.includes(plan))
        .filter((plan) => !props.checkSub || filterSub.includes(plan));
    }
    return Object.entries(planData)
      .filter(([key, value]) => !props.useTabs || filter.includes(key))
      .filter(([key, value]) => !props.checkSub || filterSub.includes(key));
  };

  const { classes } = props;
  const keys = getPlanData(true);
  const showCoach =
    keys.filter((key) => ["lite", "premium", "enterprise"].includes(key))
      .length > 0;
  const showStudent =
    keys.filter((key) => ["free", "practice"].includes(key)).length > 0;

  const margin = isMobile ? 0 : 20;
  return (
    <MuiThemeProvider theme={theme}>
      <Grid
        style={{
          display: props.error
            ? "none"
            : props.loading
            ? "none"
            : props.success
            ? "none"
            : "block",
          width: "100%",
          paddingBottom: 100,
        }}
      >
        <div className={classes.paper}>
          <Typography variant="h1" className={classes.titleText}>
            PRICING
          </Typography>
          <Typography variant="subtitle1" style={{ color: "white" }}>
            For coaches:{" "}
            <a
              href="https://meetings.hubspot.com/edwin-fuh/demo-team"
              target="_blank"
              className={classes.link}
            >
              {" "}
              Book a demo with a 3DGolf specialist
            </a>
            .
          </Typography>
          <Typography variant="subtitle1" style={{ color: "white" }}>
            For teams of two or more coaches, please contact us for enterprise
            offers{" "}
            <a
              href="https://sportsbox-21571110.hubspotpagebuilder.com/sportsbox-3d-golf-enterprise-form"
              target="_blank"
              className={classes.link}
            >
              HERE
            </a>
            .
          </Typography>
          <div className={classes.newFeature} style={{ marginTop: 15 }}>
            <Typography
              variant="h3"
              style={{ color: "white", marginBottom: 10, fontFamily: "Anton" }}
            >
              ALL NEW: 3D Player
            </Typography>
            <Typography variant="subtitle1" style={{ color: "white" }}>
              <span style={{ color: "#7f39fb" }}>COACHES</span> Assign any
              Practice Guide to help keep your students focused and track
              progress.
            </Typography>
            <Typography variant="subtitle1" style={{ color: "white" }}>
              <span style={{ color: "#3b45ff" }}>PLAYERS</span> The ULTIMATE
              practice experience is now available in the 3D Player app.
              Subscribe now for access to over 50 Practice Guides with
              easy-to-follow series of content to follow on the practice tee!
              Quantify your progress after each practice session.
            </Typography>
            <Typography variant="subtitle1" style={{ color: "white" }}>
              If you don't have a 3DGolf Coach, find one{" "}
              <a
                href="https://www.sportsbox.ai/directory"
                target="_blank"
                className={classes.link2}
              >
                here
              </a>{" "}
              and ask them about 3D Player.
            </Typography>
            {/*<Typography variant='subtitle1' style={{color: 'white'}}>If your Coach doesn't teach with Sportsbox, nominate them <a href='https://share.hsforms.com/1o3VZmMKSQyKZHSD_6uL5bgcucdi' target='_blank' className={classes.link2}>here</a>.</Typography>*/}
          </div>
        </div>
        <Grid
          container
          style={{
            marginTop: 20,
            display: "flex",
            justifyContent: props.useTabs ? "space-between" : "flex-end",
          }}
        >
          <Grid item xs={3} />
          {props.useTabs && (
            <>
              <Grid
                item
                xs={6}
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <Typography
                  variant="h1"
                  style={{ fontFamily: "Anton", color: "black" }}
                >
                  {tab === "coach" ? "COACH" : "PLAYER"} PRICING
                </Typography>
                <ToggleButtonGroup
                  value={tab}
                  color="primary"
                  exclusive
                  onChange={(event, value) => setTab(value ? value : tab)}
                >
                  <ToggleButton size="small" value="coach">
                    Coach Plans
                  </ToggleButton>
                  <ToggleButton size="small" value="student">
                    Player Plans
                  </ToggleButton>
                </ToggleButtonGroup>
              </Grid>
            </>
          )}
          <Grid item xs={3} style={{ paddingRight: 20 }}>
            {/*!isMobile && promoField*/}
          </Grid>
        </Grid>

        <div style={{ paddingLeft: margin, paddingRight: margin }}>
          <TableContainer
            style={{
              marginBottom: 200,
              marginTop: 20,
              border: "1px solid #e0e0e0",
              borderRadius: 10,
            }}
          >
            <Table>
              <TableHead>
                <TableRow>
                  <TableCellName>
                    {!props.disableBillingOptions && (
                      <>
                        <Typography variant="h4">Billed</Typography>
                        <RadioGroup
                          value={props.billing}
                          onChange={props.handleChange("billing")}
                        >
                          <FormControlLabel
                            value="monthly"
                            control={<Radio color="primary" />}
                            label="Monthly"
                          />
                          <FormControlLabel
                            value="annually"
                            control={<Radio color="primary" />}
                            label="Annually (Save 17%)"
                          />
                        </RadioGroup>
                      </>
                    )}
                  </TableCellName>
                  {getPlanData().map(([key, value]) => (
                    <TableCellCenter key={key}>
                      <Typography variant="h4">
                        <b>{value.title}</b>
                      </Typography>
                      <div className="start-or-purchase">
                        <Button
                          className={classes.linkedButton}
                          variant="contained"
                          color="primary"
                          onClick={props.actions?.[key]}
                        >
                          {value.buttonTitle}
                        </Button>
                        <br />
                        {((window.location.href.includes("/sign-up") &&
                          (key === "lite" || key === "premium")) ||
                          (window.location.href.includes("/plans") &&
                            (key === "lite" || key === "premium") &&
                            ("endDate" in props.subData === false ||
                              ("endDate" in props.subData === true &&
                                props.subData.endDate <
                                  String(Date.now()))))) && (
                          <Button
                            className={classes.linkedButton}
                            variant="outlined"
                            color="primary"
                            onClick={props.actions?.[key + "Trial"]}
                          >
                            {value.buttonTitle2}
                          </Button>
                        )}
                        <br />
                        <Typography variant="h4" style={{ fontSize: "90%" }}>
                          <b>{value.text}</b>
                        </Typography>
                      </div>
                    </TableCellCenter>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCellName>
                    <Typography variant="h5">Billed Monthly</Typography>
                  </TableCellName>
                  {getPlanData().map(([key, value]) => (
                    <TableCellCenter key={key}>{value.month}</TableCellCenter>
                  ))}
                </TableRow>
                <TableRow>
                  <TableCellName>
                    <Typography variant="h5">Billed Annually</Typography>
                  </TableCellName>
                  {getPlanData().map(([key, value]) => (
                    <TableCellCenter key={key}>{value.year}</TableCellCenter>
                  ))}
                </TableRow>
                <TableRow style={{ display: showStudent ? "" : "none" }}>
                  <TableCellName>
                    <Typography variant="h5">
                      Online 3D lesson with Sportsbox Certified 3DGolf Experts
                      (add on)
                    </Typography>
                  </TableCellName>
                  <TableCellCenter>$35/lesson</TableCellCenter>
                  <TableCellCenter>$35/lesson</TableCellCenter>
                </TableRow>
                <TableRow>
                  <TableCellName>
                    <Typography variant="h4" className={classes.tableHeader}>
                      General
                    </Typography>
                  </TableCellName>
                  {getPlanData(true).map((key) => (
                    <TableCell key={key} />
                  ))}
                </TableRow>
                <TableRow>
                  <TableCellName>
                    <Typography variant="h5"># of student accounts</Typography>
                  </TableCellName>
                  {getPlanData().map(([key, value]) => (
                    <TableCellCenter key={key}>
                      {value.numStudents}
                    </TableCellCenter>
                  ))}
                </TableRow>
                <TableRow>
                  <TableCellName>
                    <Typography variant="h5"># of coach licenses</Typography>
                  </TableCellName>
                  {getPlanData().map(([key, value]) => (
                    <TableCellCenter key={key}>
                      {value.coachLicense}
                    </TableCellCenter>
                  ))}
                </TableRow>
                <TableRow>
                  <TableCellName>
                    <Typography variant="h5"># of devices</Typography>
                  </TableCellName>
                  {getPlanData().map(([key, value]) => (
                    <TableCellCenter key={key}>{value.devices}</TableCellCenter>
                  ))}
                </TableRow>
                <TableRow>
                  <TableCellName>
                    <Typography variant="h5">Sessions</Typography>
                  </TableCellName>
                  {getPlanData().map(([key, value]) => (
                    <TableCellCenter key={key}>
                      {value.sessions}
                    </TableCellCenter>
                  ))}
                </TableRow>
                <TableRow>
                  <TableCellName>
                    <Typography variant="h5">Auto-upload sessions</Typography>
                  </TableCellName>
                  {getPlanData(true).map((key) => (
                    <TableCellCenter key={key}>
                      {key !== "free" ? <Check /> : <Close />}
                    </TableCellCenter>
                  ))}
                </TableRow>
                <TableRow>
                  <TableCellName>
                    <Typography variant="h5">Cloud storage</Typography>
                  </TableCellName>
                  {getPlanData().map(([key, value]) => (
                    <TableCellCenter key={key}>{value.cloud}</TableCellCenter>
                  ))}
                </TableRow>
                <TableRow>
                  <TableCellName>
                    <Typography variant="h5">
                      Access to 3DGolf education and learning portal
                    </Typography>
                  </TableCellName>
                  {getPlanData(true).map((key) => (
                    <TableCellCenter key={key}>
                      <Check />
                    </TableCellCenter>
                  ))}
                </TableRow>
                <TableRow>
                  <TableCellName>
                    <Typography variant="h5">
                      Create and manage sessions on 3DGolf Website
                    </Typography>
                  </TableCellName>
                  {getPlanData(true).map((key) => (
                    <TableCellCenter key={key}>
                      {key !== "free" ? <Check /> : <Close />}
                    </TableCellCenter>
                  ))}
                </TableRow>

                <TableRow>
                  <TableCellName>
                    <Typography variant="h4" className={classes.tableHeader}>
                      Analysis Tools
                    </Typography>
                  </TableCellName>
                  {getPlanData(true).map((key) => (
                    <TableCell key={key} />
                  ))}
                </TableRow>
                <TableRow>
                  <TableCellName>
                    <Typography variant="h5">
                      Trackers and Indicators
                    </Typography>
                  </TableCellName>
                  {getPlanData().map(([key, value]) => (
                    <TableCellCenter key={key}>
                      {value.trackers}
                    </TableCellCenter>
                  ))}
                </TableRow>
                <TableRow style={{ display: showCoach ? "" : "none" }}>
                  <TableCellName>
                    <Typography variant="h5">
                      Record video for 2D & 3D Analysis
                    </Typography>
                  </TableCellName>
                  {getPlanData(true).map((key) => (
                    <TableCellCenter key={key}>
                      {key !== "free" ? <Check /> : <Close />}
                    </TableCellCenter>
                  ))}
                </TableRow>
                <TableRow style={{ display: showCoach ? "" : "none" }}>
                  <TableCellName>
                    <Typography variant="h5">
                      Import video for 2D & 3D Analysis
                    </Typography>
                  </TableCellName>
                  {getPlanData(true).map((key) => (
                    <TableCellCenter key={key}>
                      {key !== "free" ? <Check /> : <Close />}
                    </TableCellCenter>
                  ))}
                </TableRow>
                <TableRow style={{ display: !showCoach ? "" : "none" }}>
                  <TableCellName>
                    <Typography variant="h5">
                      Record video for 3D Analysis
                    </Typography>
                  </TableCellName>
                  {getPlanData(true).map((key) => (
                    <TableCellCenter key={key}>
                      {key !== "free" ? <Check /> : <Close />}
                    </TableCellCenter>
                  ))}
                </TableRow>
                <TableRow style={{ display: !showCoach ? "" : "none" }}>
                  <TableCellName>
                    <Typography variant="h5">
                      Import video for 3D Analysis
                    </Typography>
                  </TableCellName>
                  {getPlanData(true).map((key) => (
                    <TableCellCenter key={key}>
                      {key !== "free" ? <Check /> : <Close />}
                    </TableCellCenter>
                  ))}
                </TableRow>
                <TableRow style={{ display: showCoach ? "" : "none" }}>
                  <TableCellName>
                    <Typography variant="h5">2D video drawing tools</Typography>
                  </TableCellName>
                  {getPlanData(true).map((key) => (
                    <TableCellCenter key={key}>
                      {key !== "free" && key !== "practice" ? (
                        <Check />
                      ) : (
                        <Close />
                      )}
                    </TableCellCenter>
                  ))}
                </TableRow>
                <TableRow>
                  <TableCellName>
                    <Typography variant="h5">
                      Auto swing detection and voice guidance
                    </Typography>
                  </TableCellName>
                  {getPlanData(true).map((key) => (
                    <TableCellCenter key={key}>
                      {key !== "lite" && key !== "free" ? <Check /> : <Close />}
                    </TableCellCenter>
                  ))}
                </TableRow>

                <TableRow>
                  <TableCellName>
                    <Typography variant="h4" className={classes.tableHeader}>
                      Practice Tools
                    </Typography>
                  </TableCellName>
                  {getPlanData(true).map((key) => (
                    <TableCell key={key} />
                  ))}
                </TableRow>
                <TableRow>
                  <TableCellName>
                    <Typography variant="h5">
                      Inbox to communicate with coaches/students
                    </Typography>
                  </TableCellName>
                  {getPlanData(true).map((key) => (
                    <TableCellCenter key={key}>
                      <Check />
                    </TableCellCenter>
                  ))}
                </TableRow>
                <TableRow>
                  <TableCellName>
                    <Typography variant="h5">Create reference swing</Typography>
                  </TableCellName>
                  {getPlanData(true).map((key) => (
                    <TableCellCenter key={key}>
                      {["free", "practice"].includes(key) ? (
                        <Close />
                      ) : (
                        <Check />
                      )}
                    </TableCellCenter>
                  ))}
                </TableRow>
                <TableRow>
                  <TableCellName>
                    <Typography variant="h5">Compare 2 swings</Typography>
                  </TableCellName>
                  {getPlanData(true).map((key) => (
                    <TableCellCenter key={key}>
                      {["free", "practice"].includes(key) ? (
                        <Close />
                      ) : (
                        <Check />
                      )}
                    </TableCellCenter>
                  ))}
                </TableRow>
                <TableRow>
                  <TableCellName>
                    <Typography variant="h5">
                      Screen recording with voiceover
                    </Typography>
                  </TableCellName>
                  {getPlanData(true).map((key) => (
                    <TableCellCenter key={key}>
                      {["free", "practice"].includes(key) ? (
                        <Close />
                      ) : (
                        <Check />
                      )}
                    </TableCellCenter>
                  ))}
                </TableRow>
                <TableRow>
                  <TableCellName>
                    <Typography variant="h5">
                      Create and assign custom watchlists
                    </Typography>
                  </TableCellName>
                  {getPlanData(true).map((key) => (
                    <TableCellCenter key={key}>
                      {["free", "practice"].includes(key) ? (
                        <Close />
                      ) : (
                        <Check />
                      )}
                    </TableCellCenter>
                  ))}
                </TableRow>

                <TableRow
                  style={{
                    display: showStudent
                      ? props.useTabs
                        ? "none"
                        : ""
                      : "none",
                  }}
                >
                  <TableCellName>
                    <Typography variant="h4" className={classes.tableHeader}>
                      Student Practice Tools
                    </Typography>
                  </TableCellName>
                  {getPlanData(true).map((key) => (
                    <TableCell key={key} />
                  ))}
                </TableRow>
                <TableRow style={{ display: showStudent ? "" : "none" }}>
                  <TableCellName>
                    <Typography variant="h5">
                      Access to popular watchlists
                    </Typography>
                  </TableCellName>
                  {getPlanData(true).map((key) => (
                    <TableCellCenter key={key}>
                      {["free", "practice"].includes(key) ? <Check /> : null}
                    </TableCellCenter>
                  ))}
                </TableRow>
                <TableRow style={{ display: showStudent ? "" : "none" }}>
                  <TableCellName>
                    <Typography variant="h5">
                      Practice with watchlists
                    </Typography>
                  </TableCellName>
                  {getPlanData().map(([key, value]) => (
                    <TableCellCenter key={key}>
                      {["free", "practice"].includes(key)
                        ? value.pracWatch
                        : null}
                    </TableCellCenter>
                  ))}
                </TableRow>
                <TableRow style={{ display: showStudent ? "" : "none" }}>
                  <TableCellName>
                    <Typography variant="h5">
                      Practice Guides and Drill Content Library
                    </Typography>
                  </TableCellName>
                  {getPlanData(true).map((key) => (
                    <TableCellCenter key={key}>
                      {["practice"].includes(key) ? (
                        <p>
                          Acess to over 50* Practice Guides <br />
                          <br /> Assessment feature to measure progress after
                          each Practice Guide/Session <br />
                          <br /> *Many more coming soon
                        </p>
                      ) : (
                        <Close />
                      )}
                    </TableCellCenter>
                  ))}
                </TableRow>

                <TableRow
                  style={{
                    display: showCoach ? (props.useTabs ? "none" : "") : "none",
                  }}
                >
                  <TableCellName>
                    <Typography variant="h4" className={classes.tableHeader}>
                      Coach Tools
                    </Typography>
                  </TableCellName>
                  {getPlanData(true).map((key) => (
                    <TableCell key={key} />
                  ))}
                </TableRow>
                <TableRow style={{ display: showCoach ? "" : "none" }}>
                  <TableCellName>
                    <Typography variant="h5">
                      Assign popular watchlists
                    </Typography>
                  </TableCellName>
                  {getPlanData(true).map((key) => (
                    <TableCellCenter key={key}>
                      {["free", "practice"].includes(key) ? null : <Check />}
                    </TableCellCenter>
                  ))}
                </TableRow>
                <TableRow style={{ display: showCoach ? "" : "none" }}>
                  <TableCellName>
                    <Typography variant="h5">Assign practice guides</Typography>
                  </TableCellName>
                  {getPlanData(true).map((key) => (
                    <TableCellCenter key={key}>
                      {["free", "practice"].includes(key) ? null : (
                        <p>
                          Access to over 50* Practice Guides to assign to your
                          students --including the Assessment feature to measure
                          their progress after each Practice Guide Session{" "}
                          <br />
                          <br /> *Many more coming soon
                        </p>
                      )}
                    </TableCellCenter>
                  ))}
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </Grid>
    </MuiThemeProvider>
  );
};

export default withStyles(useStyles)(PlansView);
