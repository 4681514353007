import moment from "moment";
import { plansEnvConfig } from "./envConfig";

// const planPrice = {
//     sportsbox_free_student: 'Free',
//     studentLiteMonthly: 15.99,
//     sportsbox_student_lite_monthly: 15.99,
//     studentLiteAnnually: 110,
//     sportsbox_student_lite_annually: 110,
//     liteMonthly: 65,
//     sportsbox_pro_lite_monthly: 65,
//     liteAnnually: 650,
//     sportsbox_pro_lite_annually: 650,
//     premiumMonthly: 79.99,
//     sportsbox_pro_premium_monthly: 79.99,
//     premiumAnnually: 799.99,
//     sportsbox_pro_premium_annually: 799.99,
// }

const planPrice = plansEnvConfig;

export function getPlan(plan) {
  if (plan?.includes("student_lite")) {
    return "3D Player";
  } else if (plan?.includes("pro_lite")) {
    return "3D Pro";
  } else if (plan?.includes("premium")) {
    return "3D Pro";
  } else if (plan?.includes("enterprise_master")) {
    return "Enterprise Master";
  } else if (plan?.includes("enterprise_coach")) {
    return "Enterprise Coach";
  } else {
    return "Free";
  }
}

export function getPlanPrice(plan) {
  return planPrice[plan] || 0;
}

export function getEndDate(productId) {
  let endDate = "";
  if (
    productId.includes("yearly") ||
    productId.includes("annually") ||
    productId.includes("enterprise")
  ) {
    endDate = String(moment().add(1, "y").valueOf());
  }
  if (productId.includes("monthly")) {
    endDate = String(moment().add(1, "M").valueOf());
  }

  return endDate;
}
