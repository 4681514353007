import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import firebase from '../../common/firebase'
import { Button, Container, Typography, Grid, CssBaseline, Avatar, Link } from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import Swal from 'sweetalert2';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  typography: {
    padding: theme.spacing(2),
    border: theme.spacing(2),
  }
}));
const EmailVerification = (props) => {
  const [user, setUser] = useState(firebase.auth().currentUser);

  const classes = useStyles();

  const handleResend = async () => {
    const user = firebase.auth().currentUser;
    if (user) {
      user.sendEmailVerification()
        .then(() => {
          Swal.fire({
            icon: 'success',
            title: 'Email sent!',
            text: 'Please check your email for the verification link.',
            timer: 5000,
          });
        })
        .catch(err => console.log(err));
    } else {
      await Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'You are not logged in!',
      });
      props.history.push('/sign-in');
    }
  }

  const handleReload = () => {
    window.location.reload();
  }

  if (!user) {
    firebase.auth().signOut().then(() => {
      props.history.push('/sign-in')
    }).catch((error) => {
      console.log(error)
    });
  }
  else {
    if (user.emailVerified) {
      props.history.push('/dashboard')
    }
  }
  return (
    <Container
      component="main"
      maxWidth="xs"
    >
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Grid
          container
          spacing={2}
        >
          <Grid
            item
            xs={12}
            align="center"
          >
            <Typography
              component="h1"
              variant="h4"
            >
              Please verify your email to continue
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            align="center"
            border={2}
          >
            <Typography align="center"
              className={classes.typography}>You must verify your email before continuing. It may take a few minutes, please check your inbox for email {user.email}. Once verified, please click REFRESH.</Typography>
          </Grid>
          <Grid
            item
            xs={6}
          >
            <Button
              color="primary"
              size="large"
              variant="contained"
              onClick={handleResend}
              fullWidth
            >Resend</Button>
          </Grid>
          <Grid
            item
            xs={6}
          >
            <Button
              size="large"
              variant="contained"
              onClick={handleReload}
              fullWidth
            >Refresh</Button>
          </Grid>
          <Grid
            item
            xs={12}
            align="right">
            <Link
              component={RouterLink}
              to="/sign-in"
              variant="body2"
              onClick={() => {
                firebase.auth().signOut().then(() => {
                  props.history.push('/sign-in')
                }).catch((error) => {
                  console.log(error)
                });
              }}
            >
              <p style={{ fontSize: "150%" }}>{'Back to Sign In'}</p>
            </Link>
          </Grid>
        </Grid>
      </div>
    </Container>)
};

EmailVerification.propTypes = {
  history: PropTypes.object,
};

export default withRouter(EmailVerification);