import React, { useEffect, useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Button, Card, Container, Divider, Grid, Typography, useMediaQuery } from '@material-ui/core';
import firebase, { functionBaseUrl, axiosWithToken } from '../../../common/firebase';
import { AddStudioCoach } from './AddStudioCoach';
import { StudioCoachList } from './StudioCoachList';
import Swal from 'sweetalert2';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    minHeight: 'fit-content',
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    //flexWrap: 'wrap',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
    },
  },
  text: {
    fontFamily: 'Manrope',
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '24px',
    letterSpacing: '0em',
    textAlign: 'left',
  },
  divider: {
    margin: theme.spacing(3, 0),
  },
  vertDivider: {
    margin: theme.spacing(2, 0),
    //padding: theme.spacing(0, 2),
    marginLeft: 30,
    marginRight: 30,
  },
  heading: {
    fontFamily: 'Manrope',
    fontSize: '24px',
    fontWeight: 600,
    lineHeight: '20px',
    letterSpacing: '0em',
    textAlign: 'left',
    marginBottom: 15,
  },
  card: {
    width: '30%',
    minWidth: '30%',
    maxWidth: '300px',
    height: '150px',
    borderRadius: '12px',
    border: '1px solid #EAECF0',
    padding: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      maxWidth: '100%',
    },
  },
  cardText: {
    fontFamily: 'Manrope',
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '20px',
    letterSpacing: '0em',
    textAlign: 'left',
    //marginBottom: 10,
    //color: 'white',
  },
  displaySm: {
    fontFamily: 'Manrope',
    fontSize: '30px',
    fontWeight: 600,
    lineHeight: '38px',
    letterSpacing: '0em',
    textAlign: 'left',
  },
  displayMd: {
    fontFamily: 'Manrope',
    fontSize: '36px',
    fontWeight: 600,
    lineHeight: '44px',
    letterSpacing: '-0.02em',
    textAlign: 'left',
    marginRight: 10,
  },
  smallCard: {
    width: '200px',
    //maxWidth: '200px',
    height: '120px',
    borderRadius: '12px',
    border: '1px solid #EAECF0',
    padding: theme.spacing(2.5),
    marginRight: '3%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      maxWidth: '48%',
      marginRight: '2%',
      marginBottom: '2%',
    },
  },
  button: {
    width: '100%',
    height: '36px',
    padding: '8px 14px',
    borderRadius: '64px',
    border: '1px solid #E9DDFD',
    gap: '8px',
    background: '#E9DDFD',
    textTransform: 'none',
  },
  coachButton: {
    width: 'fit-content',
    height: '36px',
    padding: '8px 14px',
    borderRadius: '64px',
    gap: '8px',
    background: '#F2F4F7',
    textTransform: 'none',
    marginBottom: 15,
  },
}));

function StudioCoachComponent() {
  const [isLoading, setIsLoading] = useState(true);
  const [isAddPopupOpened, setIsAddPopupOpened] = useState(false);
  const [accounts, setAccounts] = useState([]);
  const [currentUser, setCurrentUser] = useState([]);
  const [dialogLoading, setDialogLoading] = useState(true);
  const [sessions, setSessions] = useState({});
  const [role, setRole] = useState("");
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
    defaultMatches: true,
  });

  const openAddPopup = () => {
    if (role !== 'admin' && accounts?.length >= Number(currentUser?.coachesCount || 0)) {
      Swal.fire({
        title: '<p style="font-size:70%;">You have reached the maximum limit' + 
        ' of Studio Coach accounts that can be added for your subscription. ' +
        'Please contact sales to purchase more licenses.</p>',
        icon: 'warning',
        confirmButtonText: 'Ok',
        allowOutsideClick: false,
        customClass : { 
          container : 'my-swal' 
        },
      })
      return false;
    }
    setIsAddPopupOpened(true);
  }

  const closeAddPopup = () => {
    setIsAddPopupOpened(false);
    loadData();
  }

  const loadData = async () => {
    setIsLoading(true);

    const firebaseUser = firebase.auth().currentUser;
    const uid = firebaseUser.uid;

    const currUser = await axiosWithToken(`${functionBaseUrl}/api/users/${uid}`, {
      method: 'GET',
    }).catch((err) => {
      console.log(err);
    });
    setRole(currUser?.data?.role);

    if (!currUser?.data?.subscriptionType?.includes('studio_master')) {
      if (currUser?.data?.role !== 'admin') {
        window.location.href = window.location.origin + '/dashboard';
      }
    }

    const user = await axiosWithToken(`${functionBaseUrl}/api/studioUser/${currUser?.data?.studioAccountId}`, {
      method: 'GET',
    }).catch((err) => {
      console.log(err);
    });
    setCurrentUser(user.data?.studioAccount);

    const response = await axiosWithToken(`${functionBaseUrl}/api/studioCoaches/${currUser?.data?.studioAccountId}`, {
      method: 'GET',
    }).catch((err) => {
      console.log(err);
    });
    let allAccounts = response?.data ? [currUser?.data, ...response.data] : [currUser?.data];
    setAccounts(allAccounts);
    setDialogLoading(false);

    const sessionsCount = await axiosWithToken(`${functionBaseUrl}/api/v1/swingsCount/${uid}`, {
      method: 'GET',
    }).catch((err) => {
      console.log(err);
    });
    setSessions(sessionsCount?.data);
    setIsLoading(false);
  }

  useEffect(() => {
    loadData();
  }, []);

  const classes = useStyles();

  return (
    <Container
      component="main"
      style={{ paddingTop: '20px' }}
    >
      <div className={classes.root}>
        <Typography          
          variant="h4"
          className={classes.heading}
        >
          Coaches
        </Typography>
        <Typography          
          variant="body2"
          className={classes.text}
        >
          Check and manage your Studio account.
        </Typography>
        
      </div>
      <Divider className={classes.divider} />
      <div className={classes.container}>
        <Card className={classes.card}>
          <Typography
            variant="h4"
            className={classes.cardText}
          >
            Studio coach licenses
          </Typography>

          <div className={classes.container}>
            <Typography
              className={classes.displayMd}
            >
              {accounts?.length || 0}/{currentUser?.coachesCount || 0}
            </Typography>
          </div>

          <Typography
            className={classes.text}
          >
            Need more seats? We got you.
          </Typography>
          
          <a href="mailto:sales@sportsbox.ai">
            <Button className={classes.button}><Typography className={classes.cardText}>Contact Sales</Typography></Button>
          </a>
        </Card>

        {isDesktop && (<Divider orientation="vertical" flexItem className={classes.vertDivider} />)}

        <div className={classes.root} style={{ marginTop: isDesktop ? 0 : '5%' }}>
          <Typography
            className={classes.heading}
          >
            <div className={classes.container}>
              <img src="/images/icons/sessions.png" />
              Sessions
            </div>
          </Typography>
          <div className={classes.container}>
            <Card className={classes.smallCard}>
              <Typography
                variant="h4"
                className={classes.cardText}
              >
                Last 24 hours
              </Typography>

              <Typography
                className={classes.displayMd}
                style={{ marginTop: 10 }}
              >
                {sessions?.swingsLast24Hours || 0}
              </Typography>
            </Card>

            <Card className={classes.smallCard}>
              <Typography
                variant="h4"
                className={classes.cardText}
              >
                Last 7 days
              </Typography>

              <Typography
                className={classes.displayMd}
                style={{ marginTop: 10 }}
              >
                {sessions?.swingsLast7Days || 0}
              </Typography>
            </Card>

            <Card className={classes.smallCard}>
              <Typography
                variant="h4"
                className={classes.cardText}
              >
                Last month
              </Typography>

              <Typography
                className={classes.displayMd}
                style={{ marginTop: 10 }}
              >
                {sessions?.swingsLastMonth || 0}
              </Typography>
            </Card>

            <Card className={classes.smallCard}>
              <Typography
                variant="h4"
                className={classes.cardText}
              >
                All time
              </Typography>

              <Typography
                className={classes.displayMd}
                style={{ marginTop: 10 }}
              >
                {sessions?.swingsCount || 0}
              </Typography>
            </Card>
          </div>
        </div>
      </div>
      <Divider className={classes.divider}/>
      {/*<Grid item xs={12}>*/}
        <Button className={classes.coachButton} onClick={() => openAddPopup()}>
          <Typography
            className={classes.cardText}
          >
            <div className={classes.container}>
              <img src="/images/icons/plus.png" />{' '}
              Add coach
            </div>
          </Typography>
        </Button>
        <StudioCoachList accounts={accounts} />
        <AddStudioCoach
          open={isAddPopupOpened}
          close={closeAddPopup}
        />
      {/*</Grid>*/}
    </Container>
  );
}

export const StudioCoach = StudioCoachComponent;