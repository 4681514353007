import React, { useState } from 'react';
import {Link as RouterLink, withRouter} from 'react-router-dom';
import PropTypes from 'prop-types';
import {Grid, Button, Container, Avatar, TextField, Link, Typography, CssBaseline} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import firebase from '../../common/firebase';
import Swal from 'sweetalert2';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const ForgotPassword = (props) => {
  const { history } = props;
  const classes = useStyles();

  const [value, setValue] = useState({ email: '' });

  const handleForgotPassword = async (event) => {
    event.preventDefault();
    await firebase.auth().sendPasswordResetEmail(value.email.toLowerCase().replace(/\s/g, '')).then(() => {
      setValue({ email: '' });
      Swal.fire({
        icon: 'success',
        title: 'Email sent!',
        text: 'Please check your email for the password reset link.',
        timer: 5000,
      });
      //history.push('/');
    }).catch(function(error) {
      console.log(error);
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Something went wrong! Please try again.',
      });
    });
  };

  const handleChange = (event) => {
    setValue({
      ...value,
      [event.target.name]: event.target.value,
    });
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h2">
          Did you forget your password?
        </Typography>

        <Typography component="p1" align = "center">
          Enter your email address you are using for your account below and we will send you a password reset link
        </Typography>

        <form className={classes.form} onSubmit={ handleForgotPassword }>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                onChange={handleChange}
                value={value.email}
              />
            </Grid>

          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Send Email
          </Button>
          <Grid container justify="flex-end">
            <Grid item xs>
              <Link component={RouterLink} to="/sign-in" variant="body2">
                {'Back to sign in page'}
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
    </Container>
  );
};

ForgotPassword.propTypes = {
  history: PropTypes.object,
};

export default withRouter(ForgotPassword);
