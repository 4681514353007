import React, { useEffect } from 'react';

import { Grid } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';


function PlansSuccessComponent() {

  useEffect(() => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ 'event': 'signUpStatus', 'signUpStatus': 'complete' });
  }, []);

  return (<>
    <Grid container>
      <Grid
        item
        xs={12}
        className="plan-success-caption"
      >
        <CheckIcon className="plan-success-mark" />
        <div>Thank you for joining us!</div>
      </Grid>
      <Grid
        item
        xs={12}
        className="plan-success-subcaption"
      >
        Get ready to take your coaching to the next<br />level with our powerful coaching tool
      </Grid>
      <Grid
        item
        xs={12}
        className="plan-success-apps"
      >
        <a href="https://apps.apple.com/us/app/sportsbox-3dgolf/id1578921026">
          <img
            alt="Apple"
            src="/images/apple.svg"
            height={40}
          />
        </a>
        <a href="https://play.google.com/store/apps/details?id=com.sportsbox.golfai">
          <img
            alt="Google"
            src="/images/google.svg"
            height={40}
          />
        </a>
      </Grid>
    </Grid>
  </>);
}

export default PlansSuccessComponent;