import React from 'react';
import { Dialog, DialogTitle, DialogContent, Grid, InputLabel,
  Box, Card, CardContent, Typography, FormControl, Select, MenuItem } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { StripePayment } from '../../components/stripePayment';
import { makeStyles } from '@material-ui/core/styles';

function BuyCreditsComponent({ open, close, handleResult, user, custom, amount, credits, handlePackageChange }) {
  const useStyles = makeStyles((theme) => ({
    container: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexDirection: 'row',
      [theme.breakpoints.down('sm')]: {
        flexWrap: 'wrap',
      },
    },
    text: {
      fontFamily: 'Manrope',
      fontSize: '18px',
      fontWeight: 600,
      lineHeight: '28px',
      letterSpacing: '0em',
      textAlign: 'center',
      color: '#101828',
    },
  }));

  const classes = useStyles();

  return (<div>
    <Dialog
      fullWidth
      open={open}
      onClose={close}>
      <DialogTitle>
        <div className={classes.container}>
          <Typography
            className={classes.text}
          >
            Payment Method
          </Typography>
          <CloseIcon onClick={close} />
        </div>
      </DialogTitle>
      <DialogContent>
        <Grid
          container
          spacing={2}
        >
          <Grid
            item
            xs={12}
          >
            <Box border={1} borderColor="primary.main" style={{ marginBottom: "5%" }}>
              <Card style={{ minWidth: "50%" }}>
                <CardContent>
                  {!custom && (<Grid container justifyContent="space-between">
                  {Date.now() >= new Date(2023, 11, 8).getTime() && Date.now() <= new Date(2023, 11, 31).getTime() ?
                    <FormControl variant="outlined" fullWidth>
                      <Select value={amount} onChange={(e) => handlePackageChange(e.target.value)} style={{ width: '100%' }}>
                        <MenuItem disabled value={0}>Select a Package</MenuItem>
                        <MenuItem style={{ whiteSpace: "normal" }} value={250}>Standard (30 months of 3DP - $8.33/monthly credit) - <s style={{ color: "red" }}><p style={{ color: "black" }}>$300</p></s>&nbsp; <p style={{ color: "red" }}>$250 (For holidays $50 off)</p></MenuItem>
                        <MenuItem style={{ whiteSpace: "normal" }} value={425}>Premium (60 months of 3DP - $7.08/monthly credit) - <s style={{ color: "red" }}><p style={{ color: "black" }}>$500</p></s>&nbsp; <p style={{ color: "red" }}>$425 (For holidays $75 off)</p></MenuItem>
                        <MenuItem style={{ whiteSpace: "normal" }} value={600}>Gold (100 months of 3DP - $6/monthly credit) - <s style={{ color: "red" }}><p style={{ color: "black" }}>$700</p></s>&nbsp; <p style={{ color: "red" }}>$600 (For holidays $100 off)</p></MenuItem>
                        <MenuItem style={{ whiteSpace: "normal" }} value={1600}>Diamond (360 months of 3DP - $4.44/monthly credit) - <s style={{ color: "red" }}><p style={{ color: "black" }}>$1800</p></s>&nbsp; <p style={{ color: "red" }}>$1600 (For holidays $200 off)</p></MenuItem>
                      </Select>
                    </FormControl>
                    :
                    <FormControl variant="outlined" fullWidth>
                      <Select value={amount} onChange={(e) => handlePackageChange(e.target.value)} style={{ width: '100%' }}>
                        <MenuItem disabled value={0}>Select a Package</MenuItem>
                        <MenuItem value={300}>Standard (30 months of 3DP - $10/monthly credit) - $300</MenuItem>
                        <MenuItem value={500}>Premium (60 months of 3DP - $8.33/monthly credit) - $500</MenuItem>
                        <MenuItem value={700}>Gold (100 months of 3DP - $7/monthly credit) - $700</MenuItem>
                        <MenuItem value={1800}>Diamond (360 months of 3DP - $5/monthly credit) - $1800</MenuItem>
                      </Select>
                    </FormControl>
                  }
                  </Grid>)}
                </CardContent>
              </Card>
              {amount !== 0 && (<Card>
                <CardContent fullWidth>
                  <Typography style={{ textAlign: 'center', marginTop: 20, marginBottom: 20 }}>
                    <div className={classes.text}>
                      <span style={{ fontSize: '28px' }}>Credits: <b>{credits} ({credits > 1 ? "months" :  "month"})</b></span><br /><br />
                      <span style={{ fontSize: '20px' }}>Amount: <b>${amount}</b></span><br />
                    </div>
                  </Typography>
                  {amount > 0 && (
                    <StripePayment 
                      amount={amount} 
                      handleResult={handleResult}
                      //useConfirmation={true} 
                      name={user?.userName} 
                      email={user?.email}
                    />
                  )}
                </CardContent>
              </Card>)}
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  </div>);
}

export const BuyCredits = BuyCreditsComponent;