import React from "react";
import { Pad } from "../../../../../components";
import {
  WatchlistItemContainer,
  WatchlistItemSubContainer,
  WatchlistTrackerContainer,
} from "../../practiceGuide/styled";
import { CustomRadio } from "../CustomRadio";

export function PopularWatchlists({ data, selected, onSelect }) {
  return (
    <>
      {data
        ? data.map((el) => (
            <>
              <WatchlistItemContainer onClick={() => onSelect(el.watchlistId)}>
                <CustomRadio active={selected.includes(el.watchlistId)} />
                <WatchlistItemSubContainer>
                  <div style={{ fontWeight: "bold" }}>{el.name}</div>
                  <div>{el.description}</div>
                  {selected.includes(el.watchlistId)
                    ? el.ranges.map((trc) => (
                        <>
                          <Pad height="10px" />
                          <WatchlistTrackerContainer>
                            {/* <div>icon</div> */}
                            <Pad width="10px" />
                            <div>
                              <div style={{ fontSize: "16px" }}>
                                {trc.indicatorId}
                              </div>
                              <div style={{ fontSize: "12px" }}>
                                Range {trc.min} to {trc.max}
                              </div>
                            </div>
                          </WatchlistTrackerContainer>
                        </>
                      ))
                    : ""}
                </WatchlistItemSubContainer>
              </WatchlistItemContainer>
              <Pad height="10px" />
            </>
          ))
        : ""}
    </>
  );
}
