import React, { useEffect, useState } from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    TextField,
    Typography,
    MenuItem,
    Button,
    Checkbox,
} from "@material-ui/core";
import { FlexWrap, Pad } from "../../../../components";
import { PlatformSelect } from "../PlatformSelect";
import { Tags } from "../Tags";
import { Thumbnail } from "../Thumbnail";
import { AccessLevelSelect } from "../AccessLevelSelect";

export function TipsAndDrillsModal({
    onCreate,
    onEdit,
    open,
    onClose,
    type,
    init = null,
}) {
    const [form, setForm] = useState({
        name: "",
        tags: new Set(),
        video: { length: 0, thumbnail: null, url: null, file: null },
        newVideo: { length: 0, thumbnail: null, url: null, file: null },
        isPremium: false,
        platform: "Android",
        accessLevel: "Internal",
    });
    const [video, setVideo] = useState(null);
    const [thumbnail, setThumbnail] = useState(null);

    const clearForm = () => {
        setForm({
            name: "",
            platform: "Android",
            accessLevel: "Internal",
            isPremium: false,
            tags: new Set(),
            video: {},
            newVideo: {},
        });
        setVideo(null);
        setThumbnail(null);
    };

    useEffect(() => {
        if (type === "create") {
            clearForm();
        }
        if (type === "edit" && init) {
            const { name, platform, tags, video, isPremium, accessLevel } =
                init;
            setForm((prev) => ({
                ...prev,
                name,
                platform,
                tags: new Set(tags),
                video,
                isPremium,
                accessLevel,
            }));
            setThumbnail(video.thumbnail);
            setVideo(video.url);
        }
    }, [init, type]);

    const handleInput = (e) => {
        e.persist();
        setForm((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    };

    const handleCheckbox = (e) => {
        e.persist();
        setForm((prev) => ({ ...prev, [e.target.name]: e.target.checked }));
    };

    const handleFile = (e) => {
        const file = e.target.files[0];

        setVideo(URL.createObjectURL(file));

        type === "create"
            ? setForm((prev) => ({ ...prev, video: { ...prev.video, file } }))
            : setForm((prev) => ({
                  ...prev,
                  newVideo: { ...prev.newVideo, file },
              }));
    };

    const handleMetadata = (e) => {
        const length = e.target.duration;
        type === "create"
            ? setForm((prev) => ({
                  ...prev,
                  video: { ...prev.video, length },
              }))
            : setForm((prev) => ({
                  ...prev,
                  newVideo: { ...prev.newVideo, length },
              }));
    };

    const handleThumbnail = (e) => {
        const file = e.target.files[0];
        setThumbnail(URL.createObjectURL(file));
        type === "create"
            ? setForm((prev) => ({
                  ...prev,
                  video: { ...prev.video, thumbnail: file },
              }))
            : setForm((prev) => ({
                  ...prev,
                  newVideo: { ...prev.newVideo, thumbnail: file },
              }));
    };

    const handleClose = () => {
        if (type === "create") clearForm();
        onClose();
    };

    const handleSubmit = () => {
        type === "create"
            ? onCreate({ ...form, tags: [...form.tags] })
            : onEdit(init.uid, { ...form, tags: [...form.tags] });
        clearForm();
    };

    const handlePlatform = (e) => {
        setForm((prev) => ({
            ...prev,
            platform: e.target.value,
        }));
    };

    const handleSelect = (value, field) => {
        setForm((prev) => ({
            ...prev,
            [field]: value,
        }));
    };

    const handleTags = (label) => {
        const newSet = new Set(form.tags);
        if (newSet.has(label)) newSet.delete(label);
        else newSet.add(label);
        setForm((prev) => ({ ...prev, tags: newSet }));
    };

    return (
        <Dialog onClose={handleClose} open={open}>
            <DialogTitle>Tips and Drills</DialogTitle>
            <DialogContent>
                <Pad width="300px" height="fit-content">
                    <Thumbnail
                        display={thumbnail}
                        handleFile={handleThumbnail}
                        text="Upload Video Thumbnail"
                    />
                    <Pad height="20px" />
                    <FlexWrap justify="space-between" align="baseline">
                        <TextField
                            value={form.name}
                            onChange={handleInput}
                            name="name"
                            label="Name"
                        />
                        <PlatformSelect
                            value={form.platform}
                            onChange={handleSelect}
                        />
                    </FlexWrap>
                    <Pad height="10px" />
                    <FlexWrap justify="space-between" align="baseline">
                        <AccessLevelSelect
                            value={form.accessLevel}
                            onChange={handleSelect}
                        />
                    </FlexWrap>
                    <Pad height="10px" />

                    <FlexWrap justify="start" align="center">
                        <Checkbox
                            id="premium-checkbox"
                            name="isPremium"
                            color="primary"
                            checked={form.isPremium}
                            onChange={handleCheckbox}
                        />
                        <label htmlFor="premium-checkbox">Premium</label>
                    </FlexWrap>
                    <Pad height="10px" />
                    <Typography>Tags</Typography>
                    <Pad height="10px" />
                    <Tags selected={form.tags} onSelect={handleTags} />
                    <video
                        style={{ display: "none" }}
                        src={video}
                        onLoadedMetadata={handleMetadata}
                    />
                    <Pad height="10px" />
                    {video ? (
                        <a href={video} target="_blank">
                            Video Preview
                        </a>
                    ) : (
                        ""
                    )}
                    <Pad height="10px" />

                    <FlexWrap justify="space-between">
                        <label htmlFor="tipsAndDrillsVideoUpload">
                            <Button
                                variant="contained"
                                color="primary"
                                component="span"
                            >
                                Upload Video
                            </Button>
                        </label>
                        <input
                            id="tipsAndDrillsVideoUpload"
                            style={{ display: "none" }}
                            onChange={handleFile}
                            type="file"
                        />
                        <Button
                            type="submit"
                            onClick={() => handleSubmit()}
                            variant="contained"
                            color="primary"
                        >
                            Submit
                        </Button>
                    </FlexWrap>
                </Pad>
            </DialogContent>
        </Dialog>
    );
}
