import React, {Component} from 'react';
import {Link as RouterLink} from 'react-router-dom';
import PropTypes from 'prop-types';
import firebase from '../../common/firebase'
import {withStyles} from '@material-ui/styles';
import {Grid, Button, Container, Avatar, Link, Typography,
  CssBaseline, CircularProgress} from '@material-ui/core';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import {AccountNotFound} from '../account/components';
import admin from 'firebase/app';
import 'firebase/firestore';
import DisplaySessionTable from './components/display/DisplaySessionTable';
import {functionBaseUrl, axiosWithToken} from '../../common/firebase';
import Swal from 'sweetalert2';
import NoSubscriptionDialog from '../../components/NoSubscriptionDialog';

const useStyles = theme => ({
  paper: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  tables: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  root: {
    marginTop: theme.spacing(4),
  },
  buttonLink: {  // This is a link component surrounding a button component
    width: '100%',
    textAlign: 'center'
  },
  linkedButton: {  // This is a button component surrounded by a link
    margin: theme.spacing(1, 0),
  },
  centeredText: {
    textAlign: 'center',
  }
});

const firestore = firebase.firestore()

class SessionDetails extends Component {
  constructor(props) {
    const firebaseUser = firebase.auth().currentUser
    super(props)
    this.state = {
      uid : firebaseUser ? firebaseUser.uid : "",
      error : "",
      loading : true,
      success : false,
      sessionNames : [],
      sessionIds : [],
      checker : true,
      sessions : [],
      cameraSetups : [],
      role: '',
      subData: {},
      activeSub: true,
      isStaffEnterprise: false,
      isCertified: true,
      onboardingCompleted: true,
      dialogLoading: true,
      subscriptionType: ''
    }
  }

  async componentDidMount() {
    if (!this.state.uid) {   
      this.setState({ error : "Please log in to view your sessions" })
      this.setState({ loading : false })
    }
    else {
      await firestore.collection("users").doc(this.state.uid).get()
      .then(async userDoc => {
        if (!userDoc.exists && this.state.uid !== "") {
          console.log('No matching documents for UID: ' + this.state.uid);
          this.setState({error : "The signed in user does not exist"});
        }
        this.setState({ role: userDoc.data().role, subscriptionType: userDoc.data().subscriptionType })
        if (!userDoc.data().subscriptionType.includes('free')) {
          this.subscriptionStatus()
        }
        // get the session name of each session id
        const sessionIds = userDoc.get("sessionIds");
        sessionIds.forEach(async (sessionId) => {
          this.setState({
            sessionIds: [...this.state.sessionIds, sessionId]
          })
          await firestore.collection("sessions").doc(sessionId).get().then(sessionDoc => {
            this.setState({
              sessionNames: [...this.state.sessionNames, sessionDoc.get("sessionName")]
            })
          })
          .catch(error => {
            console.log("Error retreiving session types: " + error)
          })
        })

        if (userDoc.data().subscriptionType.includes('enterprise_master') && userDoc.data().enterpriseAccountId) {
          await axiosWithToken(functionBaseUrl + '/api/verifyStaffEnt/' + this.state.uid, {
            method: 'POST',
          })
            .then(async (res) => {
              this.setState({ isStaffEnterprise: res.data.isStaffEnterprise, isCertified: res.data.isCertified, onboardingCompleted: res.data.onboardingCompleted, dialogLoading: false })
            })
            .catch(err => {
              console.log(err)
            });
        }
        this.setState({ dialogLoading: false })

        // get all the cameraSetups
        let tempCameras = []
        await admin.firestore().collection('cameraSetups').get().then(snapshot => {
            snapshot.forEach(function(doc) {
              tempCameras.push({...doc.data(), id: doc.id});
            });
            this.setState({ cameraSetups: tempCameras });
        })
        .catch(err => {
            console.log('Error getting documents', err);
        });

        // get all the sessions
        let tempSessions = []
        await admin.firestore().collection('sessions').where("parentUserId" , "==", this.state.uid).get().then(snapshot => {
          snapshot.forEach(function(doc) {
            tempSessions.push({ ...doc.data(), id: doc.id });
          });
          this.setState({ sessions: tempSessions });
        })
        .catch(err => {
          console.log(err)
          this.setState({loading: false, error : "There was an error retrieving your sessions"});
        });

        await admin.firestore().collection('sessions').where("sessionUserId" , "==", this.state.uid).get().then(snapshot => {
          snapshot.forEach(function(doc) {
            const index = tempSessions.findIndex((item) => item.id === doc.id);
            if (index === -1) {
              tempSessions.push({ ...doc.data(), id: doc.id });
            }
          });
          this.setState({ sessions: tempSessions });
        })
        .catch(err => {
          console.log(err)
          this.setState({ loading: false, error : "There was an error retrieving your sessions" });
        });

        // set loading state to false so screens are displayed
        this.setState({
          loading: false
        })      
      })
      .catch(err => {
        console.log('Error getting documents', err);
        this.setState({error : "There was an error retrieving your info"});
      });
    }    
  }

  subscriptionStatus = async () => {
    await axiosWithToken(functionBaseUrl+'/api/verifySub/' + this.state.uid, {
      method: 'post'
    }).then(response => {
      this.setState({
        subData: response.data.data
      })
      if (this.state.role !== 'admin' && 'success' in response.data) {
        this.setState({ activeSub: response.data.success })
      }
    })
    .catch(err => {
      console.log(err)
    });
  }

  handleDeleteSession = () => {
    const id = localStorage.getItem('deleteSess');
    const fieldValue = admin.firestore.FieldValue;
    admin.firestore().collection('sessions').doc(id).get().then(function(querySnapshot) {
      let res = querySnapshot.data();
      let sessName = res.sessionName;
      let parentId = res.parentUserId;
      let videoId = res.videoIds;
      const sess = admin.firestore().collection('users').doc(parentId);
      sess.update({'sessionIds': fieldValue.arrayRemove(id)});
      for(const vid of videoId) {
        admin.firestore().collection('videos').doc(vid).delete();
      }
      admin.firestore().collection('sessions').doc(id).delete();
      alert(sessName + " was deleted, page will refresh now");
      setTimeout(function() {
        window.location.reload()
      }, 1000);
    })
    .catch(err => {
        console.log("Error deleting documents: ", err);
    });
  }

  render() {
    const classes = this.props.classes;
    return (<div>
      <Container
        className={classes.tables}
        component="main">
      <CssBaseline />
      <Grid
        style = {{display: (this.state.error) ? "none" : (this.state.loading) ? "none" : (this.state.success) ? "none" : "flex"}}
      >
        <div className={classes.tables}>
          <Avatar className={this.props.classes.avatar}>
            <AssignmentIndIcon />
          </Avatar>
          <Typography
            component="h1"
            variant="h4"
            style={{marginBottom: '10px'}}
          >
            SESSION DETAILS
          </Typography>
          <div className={this.props.classes.root}>
            {/*<Typography
              component="h6"
              variant="h6"
              className={this.props.classes.centeredText}
            >
              UPLOAD A VIDEO TO AN EXISTING SESSION
            </Typography>*/}
            {/*this.state.sessionNames.map((sessionName, index) => (
              <table style={{width:"100%"}}>
                <tbody>
                  <tr>
                    <td>
                    <Button
                      style={{marginBottom: '5%'}}
                      fullWidth
                      variant="contained"
                      color="primary"
                      className={this.props.classes.linkedButton}
                      component={RouterLink} to="/video-upload"
                      onClick={() => {
                        this.setState({ success: false })
                        localStorage.setItem('sess', this.state.sessionIds[index])
                        localStorage.setItem('check', this.state.checker)
                      }}
                    >
                      {sessionName}
                    </Button>
                    </td>
                    <td>
                      <Button 
                        style={{color:"blue", float:'right'}} 
                        component={RouterLink} to="/edit-session" 
                        onClick={() => {localStorage.setItem('editSess', this.state.sessionIds[index])}}
                      >
                        Edit
                      </Button>
                    </td>
                    <td>
                      <Button 
                        style={{color:"red", float:'right'}}
                        onClick={() => {
                          localStorage.setItem('deleteSess', this.state.sessionIds[index])
                          this.handleDeleteSession()}}
                      >
                        Delete
                      </Button>
                    </td>
                  </tr>
                </tbody>
              </table>
            ))*/}
          </div>
        </div>
        </Grid>
      </Container>
      <Container
        className={classes.tables}
        component="main">
        <div className={classes.tables}>
          {/*(Object.keys(this.state.subData).length > 0 || this.state.role === 'admin') ?
            <Link className={this.props.classes.buttonLink} component={RouterLink} to="/create-session">
              <Button
                  style={{marginBottom: '5%'}}
                  variant="contained"
                  color="primary"
                  className={this.props.classes.linkedButton}
                  onClick={() => {
                    if (JSON.stringify(this.state.subData) === '{}' && this.state.sessionIds.length > 1) {
                      Swal.fire({
                        title: '<p style="font-size:70%;">You can only create 1 session with the free plan!</p>',
                        icon: 'warning',
                        confirmButtonText: 'Ok',
                        allowOutsideClick: true,
                      });
                      return false;
                    } else {
                      this.setState({ success: false })
                    }
                  }}
              >
                  CREATE NEW SESSION
              </Button>
            </Link> : <div></div>
          */}
          <div style={{width: '80%'}}>
            <DisplaySessionTable sessions={this.state.sessions} cameraSetups={this.state.cameraSetups}/>
          </div>
        </div>
      </Container>

      <Container
        className={classes.tables}
        component="main">
      {/* Loading screen*/}
      <Grid
        style = {{margin : "auto", padding : 100, display: (this.state.loading) ? "block" : "none"}}
      >
        <CircularProgress />
      </Grid>

      {/* Error screen*/}
      <Grid
        item
        lg={12}
        md={12}
        xl={12}
        xs={12}
        style = {{display: (this.state.error && !this.state.loading) ? "block" : "none"}}
      >
        <AccountNotFound            
          error = {{error : this.state.error}}
        />
      </Grid>
      <NoSubscriptionDialog
        activeSub={this.state.activeSub}
        isStaffEnterprise={this.state.isStaffEnterprise}
        isCertified={this.state.isCertified}
        onboardingCompleted={this.state.onboardingCompleted}
        role={this.state.role}
        loading={this.state.dialogLoading}
        subscriptionType={this.state.subscriptionType}
      />
      </Container>
    </div>
  );
  }
};

SessionDetails.propTypes = {
  classes:PropTypes.object,
  history: PropTypes.object,
};

export default withStyles(useStyles)(SessionDetails);