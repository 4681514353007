import admin, {
  axiosWithToken,
  functionBaseUrl,
} from "../../../common/firebase";
import { fbMapper } from "./helper";

import { parseBytes } from "./common";
import {
  dateMapper,
  firestorageExistCheck,
  getThumbnailForVideo,
} from "./helper";

const db = admin.firestore().collection("practiceGuides");

export const getPracticeGuides = async () => {
  const guides = fbMapper(await db.get());
  return guides;
};

export const getPracticeGuidesServer = async ({
  limit,
  offset,
  search,
  sort,
  direction,
  status,
}) => {
  const { data } = await axiosWithToken(
    functionBaseUrl + "/api/web/practice-guides",
    {
      method: "post",
      data: { limit, offset, search, sort, direction, status },
    }
  );

  return data;
};

export const getPublicPracticeGuides = async () => {
  const guides = fbMapper(await db.where("status", "==", "published").get());
  return guides;
};

export const getPracticeGuideById = async (id) => {
  const guide = await db.doc(id).get();
  return guide.data();
};

export const createPracticeGuide = async (data) => {
  const {
    title,
    description,
    skill,
    goal,
    environment,
    fault,
    coverImage,
    introVideo,
    warmup,
    drill,
    benchmarkAssessment,
    finalAssessment,
    status,
    watchlist,
    targetPractice,
    tags,
  } = data;

  const parsedWatchlist = watchlist.map((el) => {
    const { uuid, trackers, ...rest } = el;
    const parsedTrackers = trackers.map(
      ({ trackerName, id, rangeMin, rangeMax, ...restTracker }) => ({
        rangeMin: +rangeMin,
        rangeMax: +rangeMax,
        ...restTracker,
      })
    );
    return { ...rest, trackers: parsedTrackers };
  });

  const parsedTargetPractice = () => {
    switch (targetPractice) {
      case "10 Shots":
        return {
          type: "target_practice",
          title: targetPractice,
          shots: [
            { order: 1, golfClub: "8-iron", direction: "Straight" },
            { order: 2, golfClub: "9-iron", direction: "Straight" },
            { order: 3, golfClub: "Driver", direction: "Right side of range" },
            { order: 4, golfClub: "Any wood", direction: "Left side of range" },
            { order: 5, golfClub: "7-iron", direction: "Straight" },
            { order: 6, golfClub: "PW", direction: "Right side of range" },
            { order: 7, golfClub: "7-iron", direction: "Straight" },
            { order: 8, golfClub: "5-iron", direction: "Right side of range" },
            { order: 9, golfClub: "Driver", direction: "Left side of range" },
            { order: 10, golfClub: "9-iron", direction: "Left side of range" },
          ],
        };
      case "None":
        return {
          type: "target_practice",
          title: targetPractice,
          shots: [],
        };
      default:
        return {
          type: "target_practice",
          title: targetPractice,
          shots: [],
        };
    }
  };

  const parsed = {
    title,
    creatorId: "sportsbox",
    description,
    skillLevel: skill,
    environment,
    goal,
    category: fault,
    watchlist: parsedWatchlist,
    createdAt: Date.now(),
    updatedAt: Date.now(),
    type: "default",
    summary: {
      ...(warmup.length !== 0
        ? { warmup: { type: "warm_up", title: "", videoIds: warmup } }
        : {}),
      ...(drill.length !== 0
        ? { drill: { type: "drill_video", title: "", videoIds: drill } }
        : {}),
      initialAssessment: benchmarkAssessment
        ? {
            type: "initial_assessment",
            golfClub: benchmarkAssessment.title,
            numberOfShots: benchmarkAssessment.reps,
          }
        : null,
      finalAssessment: finalAssessment
        ? {
            type: "final_assessment",
            golfClub: finalAssessment.title,
            numberOfShots: finalAssessment.reps,
          }
        : null,
      targetPractice: parsedTargetPractice(),
    },
    status,
    accessLevel: "private",
    platform: "Cloud",
    tags,
  };
  const created = await db.add(parsed);

  const storageRef = admin.storage().ref();

  if (coverImage.file) {
    const thumbnailExtension = coverImage.file.name.split(".").pop();
    const thumbnailFileRef = storageRef.child(
      `practice_guides/${created.id}/coverImage.${thumbnailExtension}`
    );
    const thumbnailUploadTaskSnapshot = await thumbnailFileRef.put(
      coverImage.file
    );
    const thumbnailDownloadURL =
      await thumbnailUploadTaskSnapshot.ref.getDownloadURL();

    await db.doc(created.id).update({
      coverImage: thumbnailDownloadURL,
      coverImageFileSize: parseBytes(coverImage.file.size),
    });
  } else {
    await db.doc(created.id).update({
      coverImage: "",
      coverImageFileSize: 0,
    });
  }

  if (introVideo.file) {
    const introVideoExtension = introVideo.file.name.split(".").pop();
    const introVideoFileRef = storageRef.child(
      `practice_guides/${created.id}/introVideo.${introVideoExtension}`
    );
    const introVideoUploadTaskSnapshot = await introVideoFileRef.put(
      introVideo.file
    );
    const introVideoDownloadURL =
      await introVideoUploadTaskSnapshot.ref.getDownloadURL();

    await db.doc(created.id).update({
      introVideo: {
        videoPath: introVideoDownloadURL,
        duration: introVideo.duration,
        title: "Title",
        thumbnail: "",
        fileSize: parseBytes(introVideo.file.size),
      },
    });
  } else {
    await db.doc(created.id).update({
      introVideo: {
        videoPath: "",
        duration: 0,
        title: "Title",
        thumbnail: "",
        fileSize: 0,
      },
    });
  }
};

export const updatePracticeGuide = async (id, data) => {
  const { coverImage, introVideo, ...rest } = data;
  const storageRef = admin.storage().ref();

  const {
    title,
    description,
    skill,
    environment,
    goal,
    fault,
    warmup,
    drill,
    finalAssessment,
    benchmarkAssessment,
    watchlist,
    tags,
    targetPractice,
    status,
  } = rest;

  const parsedWatchlist = watchlist.map((el) => {
    const { uuid, trackers, ...rest } = el;
    const parsedTrackers = trackers.map(
      ({ trackerName, id, rangeMin, rangeMax, ...restTracker }) => ({
        rangeMin: +rangeMin,
        rangeMax: +rangeMax,
        ...restTracker,
      })
    );
    return { ...rest, trackers: parsedTrackers };
  });

  const parsedTargetPractice = () => {
    switch (targetPractice) {
      case "10 Shots":
        return {
          type: "target_practice",
          title: targetPractice,
          shots: [
            { order: 1, club: "8-iron", direction: "Straight" },
            { order: 2, club: "9-iron", direction: "Straight" },
            { order: 3, club: "Driver", direction: "Right side of range" },
            { order: 4, club: "Any wood", direction: "Left side of range" },
            { order: 5, club: "7-iron", direction: "Straight" },
            { order: 6, club: "PW", direction: "Right side of range" },
            { order: 7, club: "7-iron", direction: "Straight" },
            { order: 8, club: "5-iron", direction: "Right side of range" },
            { order: 9, club: "Driver", direction: "Left side of range" },
            { order: 10, club: "9-iron", direction: "Left side of range" },
          ],
        };
      case "None":
        return {
          type: "target_practice",
          title: targetPractice,
          shots: [],
        };
    }
  };

  const parsed = {
    title,
    description,
    skillLevel: skill,
    environment,
    goal,
    category: fault,
    watchlist: parsedWatchlist,
    updatedAt: Date.now(),
    tags,
    status,
    summary: {
      ...(warmup.length !== 0
        ? { warmup: { type: "warm_up", title: "", videoIds: warmup } }
        : {}),
      ...(drill.length !== 0
        ? { drill: { type: "drill_video", title: "", videoIds: drill } }
        : {}),
      initialAssessment: benchmarkAssessment
        ? {
            type: "initial_assessment",
            golfClub: benchmarkAssessment.title,
            numberOfShots: benchmarkAssessment.reps,
          }
        : null,
      finalAssessment: finalAssessment
        ? {
            type: "final_assessment",
            golfClub: finalAssessment.title,
            numberOfShots: finalAssessment.reps,
          }
        : null,
      targetPractice: parsedTargetPractice(),
    },
  };

  if (coverImage.file) {
    const thumbnailExtension = coverImage.file.name.split(".").pop();
    const thumbnailFileRef = storageRef.child(
      `practice_guides/${id}/coverImage.${thumbnailExtension}`
    );
    const thumbnailUploadTaskSnapshot = await thumbnailFileRef.put(
      coverImage.file
    );
    const thumbnailDownloadURL =
      await thumbnailUploadTaskSnapshot.ref.getDownloadURL();

    parsed.coverImage = thumbnailDownloadURL;
    parsed.coverImageFileSize = parseBytes(coverImage.file.size);
  }

  if (introVideo.file) {
    const introVideoExtension = introVideo.file.name.split(".").pop();
    const introVideoFileRef = storageRef.child(
      `practice_guides/${id}/introVideo.${introVideoExtension}`
    );

    const introVideoUploadTaskSnapshot = await introVideoFileRef.put(
      introVideo.file
    );
    const introVideoDownloadURL =
      await introVideoUploadTaskSnapshot.ref.getDownloadURL();

    parsed.introVideo = {
      videoPath: introVideoDownloadURL,
      duration: introVideo.duration,
      title: "Title",
      thumbnail: "",
      fileSize: parseBytes(introVideo.file.size),
    };
  }

  await db.doc(id).update(parsed);
};

export const deletePracticeGuide = async (id) => {
  const storageRef = admin.storage().ref(`practice_guides/${id}`);
  storageRef.listAll().then((listResults) => {
    const promises = listResults.items.map((item) => {
      return item.delete();
    });
    Promise.all(promises);
  });

  await db.doc(id).delete();
};

export const copyPracticeGuide = async (id) => {
  const pg = (await db.doc(id).get()).data();
  await db.add({ ...pg, title: `${pg.title} (1)` });
};

export const checkForDeletePracticeGuide = async (id) => {
  const albums = fbMapper(
    await admin
      .firestore()
      .collection("practiceGuideAlbums")
      .where("practiceGuideIds", "array-contains", id)
      .get()
  );

  return albums;
};
