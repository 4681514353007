import React, { useCallback, useEffect, useState } from "react";

import s from "./styles.module.css";
import { DashboardButton } from "./DashboardButton";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Brightness1Icon from "@material-ui/icons/Brightness1";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";

import {
  Checkbox,
  FormControl,
  Input,
  ListItemText,
  MenuItem,
  Popover,
  Select,
  Switch,
} from "@material-ui/core";
import { listOfSubscriptionNames } from "../../../common/envConfig";
import { StudentCoaches } from "../../../components/redesign-components/MultipleSelect/MultipleSelect";
import { assignCoachToStudent, unassignCoachToStudent } from "../api";

export const StudentsTable = ({
  tableOptions,
  onPagination,
  onSort,
  data,
  total,
  onRemove,
  onRemovePending,
  onRenew,
  onRenewSwitch,
  onCoachClick,
  onEditStudent,
  isEnterprise,
  enterpriseCoaches,
  isStudio,
  studioCoaches,
}) => {
  const [popover, setPopover] = useState({ id: "", anchorEl: null });

  const totalToPages = (() => {
    const calc = total / 10;
    const fin = calc < 1 ? 1 : Math.ceil(calc);

    const pagesArr = [];
    for (let i = 1; i <= fin; i++) {
      pagesArr.push(i);
    }

    return pagesArr;
  })();

  const handlePopoverOpen = (event, id) => {
    setPopover({ anchorEl: event.currentTarget, id: id });
  };

  const getSortDirection = (field) => {
    if (tableOptions.sort === field) {
      if (tableOptions.order === "desc")
        return <ArrowDownwardIcon style={{ fontSize: "14px" }} />;
      else return <ArrowUpwardIcon style={{ fontSize: "14px" }} />;
    } else return "";
  };

  const handleNextPreviousPagination = (type) => {
    if (type === "next") {
      if (tableOptions.page < totalToPages.length) {
        onPagination(tableOptions.page + 1);
      }
    } else {
      if (tableOptions.page > 1) {
        onPagination(tableOptions.page - 1);
      }
    }
  };

  const getMultipleSelectText = (arrayOfCoaches, selectedCoachIds) => {
    if (selectedCoachIds.length) {
      const found = arrayOfCoaches.find((el) => selectedCoachIds[0] === el.uid);
      if (found) {
        if (selectedCoachIds.length > 1) {
          return found.firstName + " " + found.lastName + "...";
        } else {
          return found.firstName + " " + found.lastName;
        }
      }
    } else {
      return "None";
    }
  };

  return (
    <div className={s["table-container"]}>
      <table className={s["styled-table"]}>
        <thead>
          <tr>
            <th></th>
            <th onClick={() => onSort("firstName")}>
              Name {getSortDirection("firstName")}
            </th>
            <th onClick={() => onSort("daysLeft")}>
              Days left {getSortDirection("daysLeft")}
            </th>
            <th>Plan</th>
            <th>Email address</th>
            <th>Sessions</th>
            {(isEnterprise || isStudio) && <th>Coaches</th>}
            <th>Status</th>
            <th>Auto-renew</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {data.map((el) => {
            return (
              <tr key={el.id}>
                <td>
                  <DashboardButton
                    text={el?.plan?.includes("free") ? "Upgrade" : "Renew"}
                    color="blue"
                    onClick={() => onRenew(el)}
                  />
                </td>
                <td>{el.name}</td>
                <td>
                  <span
                    className={
                      el.daysLeft <= 7 ? s.tableDaysLeft : s.tableDaysLeftGreen
                    }
                  >
                    {el.daysLeft <= 7 ? (
                      <ErrorOutlineIcon style={{ fontSize: "17px" }} />
                    ) : (
                      <Brightness1Icon style={{ fontSize: "12px" }} />
                    )}
                    {Math.ceil(el.daysLeft)} days
                  </span>
                </td>
                <td>{listOfSubscriptionNames[el.plan]}</td>

                <td>{el.email}</td>
                <td>{el.sessions}</td>

                {(isEnterprise || isStudio) && (
                  <td>
                    {/* <FormControl>
                      <Select
                        multiple
                        value={el.coaches}
                        onChange={handleStudentCoaches}
                        input={<Input />}
                        renderValue={(selected) => selected.length}
                      >
                        {enterpriseCoaches.map((coach) => (
                          <MenuItem
                            //uid
                            key={coach.uid}
                            value={`${coach.firstName} ${coach.lastName}`}
                          >
                            <Checkbox checked={el.coaches.includes(coach.id)} />
                            <ListItemText
                              primary={`${coach.firstName} ${coach.lastName}`}
                            />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl> */}
                    <StudentCoaches
                      enterpriseCoaches={(isEnterprise ? enterpriseCoaches : studioCoaches).map((el) => ({
                        title: `${el.firstName} ${el.lastName}`,
                        value: el.uid,
                      }))}
                      onCoachClick={onCoachClick}
                      studentCoaches={el.coaches ? el.coaches : []}
                      studentId={el.id}
                      value={getMultipleSelectText(
                        isEnterprise ? enterpriseCoaches : studioCoaches,
                        el.coaches
                      )}
                    />
                  </td>
                )}

                <td>
                  <span
                    className={
                      el.status ? s.tableStatusSuccess : s.tableStatusPending
                    }
                  >
                    {el.status ? "Accepted" : "Pending"}
                  </span>
                </td>
                <td>
                  {el.toAutoRenew ? (
                    <Switch
                      checked={el.autoRenew}
                      onChange={(e) =>
                        onRenewSwitch(el.subId, e.target.checked)
                      }
                    />
                  ) : (
                    ""
                  )}
                </td>
                <td>
                  <div onClick={(e) => handlePopoverOpen(e, el.id)}>
                    <MoreVertIcon style={{ cursor: "pointer" }} />
                  </div>

                  <Popover
                    id={`${el.id}-popover`}
                    open={el.id === popover.id}
                    anchorEl={popover.anchorEl}
                    onClose={() => setPopover("")}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                  >
                    <div className={s.popoverContainer}>
                      <div
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          onEditStudent(
                            el.id,
                            el.name,
                            el.email,
                            el.height,
                            el.dominantHand,
                            el.gender,
                            el.status,
                          )
                        }
                      >
                        Edit student
                      </div>
                      <div
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          if (el.status) {
                            onRemove(el.id);
                          } else {
                            onRemovePending(el.id);
                          }
                        }}
                      >
                        Remove student
                      </div>
                    </div>
                  </Popover>
                </td>
              </tr>
            );
          })}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan="10">
              <div className={s.pagination}>
                <button
                  onClick={() => handleNextPreviousPagination("previous")}
                  className={s["pagination-button"]}
                  disabled={tableOptions.page === 1}
                >
                  <ArrowBackIcon /> Previous
                </button>
                <div className={s["pagination-items"]}>
                  {totalToPages.map((el) => {
                    return (
                      <div
                        className={
                          tableOptions.page === el ? s.activePage : s.page
                        }
                        onClick={() => onPagination(el)}
                      >
                        {el}
                      </div>
                    );
                  })}
                </div>
                <button
                  onClick={() => handleNextPreviousPagination("next")}
                  className={s["pagination-button"]}
                  disabled={tableOptions.page === totalToPages.length}
                >
                  Next <ArrowForwardIcon />
                </button>
              </div>
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
};
