

import React, { Component } from 'react';
import { Divider, Grid, Paper, TextField, MenuItem, Typography, FormControlLabel, Checkbox, Button, Slider, Box, IconButton, Tooltip } from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { CheckBox, IndeterminateCheckBox, KeyboardArrowDown, KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';

import { FieldMaps, iconMap } from './AdvancedSearch';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import QueryFieldsToggle from './QueryFieldsToggle';

//Resets all fields to their default values based on their types
const resetFields = () => {
    let fields = {}
    //For each document type (user, session, video, analysis)
    Object.keys(FieldMaps).forEach(key => {
        fields[key] = {}
        //For each field in the document type
        Object.keys(FieldMaps[key]).forEach(field => {
            const fieldDesc = FieldMaps[key][field]
            if (fieldDesc.type === 'dateRange') {
                const d1 = new Date()
                const d2 = new Date()
                //Set time ranges to encompass the whole day
                d1.setHours(0, 0, 0, 0)
                d2.setHours(23, 59, 59, 999)
                fields[key][field] = { value: [d1, d2], enable: false, type: 'between' }
            }
            else if (['range', 'arrayRange'].includes(fieldDesc.type))
                fields[key][field] = { value: fieldDesc.default || [0, 20], enable: false }
            else if (fieldDesc.type === 'bool' || fieldDesc.type === 'exists')
                fields[key][field] = 0
            else
                fields[key][field] = {value: '', negated: false}
        })
    })
    return fields
}

export function getDefaultState() {
    return {
        toggles: {
            users: false,
            sessions: false,
            videos: false,
            analysis: false,
            subscriptions: false,
            invites: false,
            reportIssues: false,
            payments: false,
            partnerSubscriptions: false,
        },
        fields: resetFields(),
        changed: false,
    }
}

class QueryFields extends Component {
    constructor(props) {
        super(props)
        this.state = getDefaultState()
    }
    handleCheckChange = (key) => (event) => {
        let toggles = this.state.toggles
        toggles[key] = !toggles[key]
        this.setState({ toggles: toggles, changed: key })
    }
    handleFieldChange = (type, key) => (event, value) => {
        let fields = this.state.fields
        if (['range', 'arrayRange'].includes(FieldMaps[type][key].type)) {
            fields[type][key].value = value
        } else {
            fields[type][key].value = event.target.value
        }
        this.setState({ fields: fields, changed: type })
    }
    handleRangeTypeChange = (type, key) => (event) => {
        let fields = this.state.fields
        fields[type][key].type = event.target.value
        this.setState({ fields: fields, changed: type })
    }
    handleDateChange = (type, key, index = 0) => (date) => {
        let fields = this.state.fields
        date.setHours(23 * index, 59 * index, 59 * index, 999 * index)
        fields[type][key].value[index] = date
        this.setState({ fields: fields, changed: type })
    }
    handleBool = (type, key) => (event) => {
        let fields = this.state.fields
        fields[type][key] = (fields[type][key] + 1) % 3
        this.setState({ fields: fields, changed: type })
    }
    handleNegation = (type, key) => (event) => {
        let fields = this.state.fields
        fields[type][key].negated = !fields[type][key].negated
        this.setState({ fields: fields, changed: type })
    }
    handleEnable = (type, key) => (event) => {
        let fields = this.state.fields
        fields[type][key].enable = !fields[type][key].enable
        this.setState({ fields: fields, changed: type })
    }
    handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            this.props.handleSubmit(true, this.state)
        } else if (event.altKey) {
            if (event.key === '1') {
                this.handleCheckChange('users')()
            } else if (event.key === '2') {
                this.handleCheckChange('sessions')()
            } else if (event.key === '3') {
                this.handleCheckChange('videos')()
            } else if (event.key === '4') {
                this.handleCheckChange('analysis')()
            }
        }
    }
    selectIcon = (type) => {
        return iconMap[type][0]
    }
    //Returns a list of field elements that are enabled for a specific document type
    createFields = (type) => {
        let fields = []
        //For each field in a document type
        Object.keys(this.state.fields[type]).forEach((field, index) => {
            let negationCheckbox = (
                <Tooltip title={this.state.fields[type][field].negated ? "Exclude" : "Include"}>
                <Checkbox
                    checked={this.state.fields[type][field].negated}
                    onChange={this.handleNegation(type, field)}
                    checkedIcon={<RemoveIcon />}
                    color='default'
                    icon={<AddIcon/>}
                    style={{padding: 0, marginLeft: '5px', height: '22px', width: '22px'}}
                /></Tooltip>)
            if ((this.props.target !== "reportIssues" || (type === 'users' && this.props.target === "reportIssues") ||
                type === "reportIssues" || this.props.reportMappings?.[type]?.properties[field]) && FieldMaps[type][field]?.hidden != "search") {
                const currField = this.state.fields[type][field];
                if (!FieldMaps[type][field]?.adminLock || this.props.isAdmin) {
                    //Basic text fields
                    if (!FieldMaps[type][field]?.type) {
                        fields.push(
                            <Grid
                                item
                                sm={12}
                                key={index} style={{display: 'flex', alignItems: 'center'}}>
                                <TextField
                                    label={FieldMaps[type][field]?.label}
                                    fullWidth
                                    margin='dense'
                                    variant='outlined'
                                    value={this.state.fields[type][field].value}
                                    onChange={this.handleFieldChange(type, field)}
                                />
                                {!FieldMaps[type][field]?.disableNegation && negationCheckbox}
                            </Grid>)
                        //Select fields
                    } else if (FieldMaps[type][field]?.type === 'select') {
                        fields.push(
                            <Grid
                                item
                                sm={12}
                                key={index} style={{display: 'flex', alignItems: 'center'}}>
                                <TextField
                                    label={FieldMaps[type][field]?.label}
                                    select
                                    fullWidth
                                    margin='dense'
                                    variant='outlined'
                                    value={this.state.fields[type][field].value}
                                    onChange={this.handleFieldChange(type, field)}>
                                    {Object.entries(FieldMaps[type][field]?.options).map(([key, label]) => (
                                        <MenuItem key={key} value={key}>{label}</MenuItem>
                                    ))}
                                    <MenuItem key={'none'} value={''}>None</MenuItem>
                                </TextField>
                                {!FieldMaps[type][field]?.disableNegation && negationCheckbox}
                            </Grid>)
                        //Range fields
                    } else if (['range', 'arrayRange'].includes(FieldMaps[type][field]?.type)) {
                        fields.push(
                            <div key={index}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={currField.enable}
                                            onChange={this.handleEnable(type, field)}
                                            color="primary"
                                        />}
                                    label={FieldMaps[type][field]?.label}
                                />
                                {currField.enable && (
                                    <Grid container alignItems='center' style={{display:'flex'}}>
                                        <Grid container alignItems="center">
                                            <Grid item sm={12} style={{
                                                display: 'flex', alignItems: 'center',
                                                marginTop: -10, paddingLeft: 25, paddingRight: 15
                                            }}>
                                                <Slider
                                                    disabled={!currField.enable}
                                                    value={currField.value}
                                                    valueLabelDisplay="auto"
                                                    onChange={this.handleFieldChange(type, field)}
                                                    min={FieldMaps[type][field]?.range?.[0]}
                                                    max={FieldMaps[type][field]?.range?.[1]}
                                                />
                                            </Grid>
                                            <Grid item xs={3}>
                                                <TextField
                                                    fullWidth
                                                    type="number"
                                                    margin='dense'
                                                    variant='outlined'
                                                    value={currField.value[0]}
                                                    onChange={(event) => {
                                                        let fields = this.state.fields
                                                        fields[type][field].value[0] = event.target.value
                                                        this.setState({ fields, change: type })
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={6} style={{ alignItems: 'center' }}>
                                            </Grid>
                                            <Grid item xs={3} style={{ alignItems: 'right' }}>
                                                <TextField
                                                    fullWidth
                                                    type="number"
                                                    margin='dense'
                                                    variant='outlined'
                                                    value={currField.value[1]}
                                                    onChange={(event) => {
                                                        let fields = this.state.fields
                                                        fields[type][field].value[1] = event.target.value
                                                        this.setState({ fields, change: type })
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                        {/* <Grid item sm={1} style={{display:'flex', justifyContent:'flex-end', paddingBottom: '10px'}}>
                                            {negationCheckbox}
                                        </Grid> */}
                                    </Grid>
                                )}
                            </div>)
                        //Date Range fields
                    } else if (FieldMaps[type][field]?.type === 'dateRange') {
                        fields.push(
                            <div key={index}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={currField.enable}
                                            onChange={this.handleEnable(type, field)}
                                            color="primary"
                                        />}
                                    label={FieldMaps[type][field]?.label}
                                />
                                <Grid container alignItems="center" style={{ display: currField.enable ? 'flex' : 'none', paddingLeft: 15, marginRight: -100}}>
                                    <Grid item style={{ width: 100, marginRight: 10 }}>
                                        <TextField
                                            select
                                            fullWidth
                                            margin='dense'
                                            variant='outlined'
                                            value={this.state.fields[type][field].type}
                                            onChange={this.handleRangeTypeChange(type, field)}>
                                            <MenuItem key="between" value="between">Between</MenuItem>
                                            <MenuItem key="before" value="before">Before</MenuItem>
                                            <MenuItem key="after" value="after">After</MenuItem>
                                        </TextField>
                                    </Grid>
                                    <Grid item style={{ width: 100, display: ['between', 'after'].includes(this.state.fields[type][field].type) ? 'block' : 'none' }}>
                                        <KeyboardDatePicker
                                            autoOk
                                            variant='inline'
                                            format="MM/dd/yyyy"
                                            InputAdornmentProps={{ position: "start" }}
                                            value={this.state.fields[type][field].value[0]}
                                            onChange={this.handleDateChange(type, field, 0)}
                                            InputProps={{
                                                style: {
                                                    fontSize: 12
                                                }
                                            }}
                                            KeyboardButtonProps={{ size: 'small', style: { marginRight: -5 } }}
                                        />
                                    </Grid>
                                    <Grid item xs='auto' style={{ display: this.state.fields[type][field].type === 'between' ? 'flex' : 'none', 'justifyContent': 'center', marginLeft: 10, marginRight: 10 }}>
                                        <Typography variant="caption">to</Typography>
                                    </Grid>
                                    <Grid item style={{ width: 100, display: ['between', 'before'].includes(this.state.fields[type][field].type) ? 'block' : 'none'}}>
                                        <KeyboardDatePicker
                                            autoOk
                                            variant='inline'
                                            format="MM/dd/yyyy"
                                            InputAdornmentProps={{ position: "start" }}
                                            value={this.state.fields[type][field].value[1]}
                                            onChange={this.handleDateChange(type, field, 1)}
                                            InputProps={{
                                                style: {
                                                    fontSize: 12
                                                }
                                            }}
                                            KeyboardButtonProps={{ size: 'small', style: { marginRight: -5 } }}
                                        />
                                    </Grid>
                                </Grid>
                            </div>
                        )
                        //Boolean fields
                    } else if (FieldMaps[type][field]?.type === 'bool' || FieldMaps[type][field]?.type === 'exists') {
                        fields.push(
                            <FormControlLabel
                                key={index}
                                control={
                                    <Checkbox
                                        checked={currField === 1}
                                        indeterminate={currField === 2}
                                        onChange={this.handleBool(type, field)}
                                        checkedIcon={<CheckBox color='primary' />}
                                        indeterminateIcon={<IndeterminateCheckBox color='primary' />}
                                    />}
                                label={FieldMaps[type][field]?.label}
                            />)
                    }
                }
            }
        })
        return fields
    }

    createToggle = (type, label) => {
        return (
            <div>
                <Grid
                    item
                    sm={12}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={this.state.toggles[type]}
                                onChange={this.handleCheckChange(type)}
                                checkedIcon={<KeyboardArrowDown/>}
                                icon={<KeyboardArrowRight />}
                                color='primary'
                            />}
                        label={<Typography className={this.props.classes.catagoryTitle}>{label}</Typography>}
                    />
                </Grid>
                <div style={{ display: this.state.toggles[type] ? 'block' : 'none' }}>
                    <Divider className={this.props.classes.divider} />
                    {this.createFields(type)}
                </div>
            </div>
        )
    }
    clearQuery = () => {
        const state = this.state
        state.fields = resetFields()
        state.changed = true
        this.setState(state, () =>
            this.props.handleSubmit(true, this.state))
    }
    render() {
        const { classes } = this.props
        return (
            <Paper className={classes.paper} style={{ minWidth: 140, marginTop: 35, maxWidth: 500 }} onKeyDown={this.handleKeyDown} tabIndex='0'>
                <Box style={{ display: this.props.isMobile ? "none" : "flex", justifyContent: "flex-end" }}>
                    <IconButton onClick={this.props.closeSearch}>
                        <KeyboardArrowLeft />
                    </IconButton>
                </Box>

                <Grid
                    item
                    xs={12}
                    style={{ display: 'flex', justifyContent: 'center' }}>
                    <ToggleButtonGroup
                        exclusive
                        size="small"
                        value={this.props.target}
                        onChange={this.props.handleTargetChange}
                        style={{ maxWidth: '100%', overflow: 'auto' }}>
                        <ToggleButton value="users">{this.selectIcon('users')}</ToggleButton>
                        <ToggleButton value="sessions">{this.selectIcon('sessions')}</ToggleButton>
                        <ToggleButton value="videos">{this.selectIcon('videos')}</ToggleButton>
                        <ToggleButton style={{ display: this.props.isAdmin ? "auto" : "none" }} value="reportIssues">{this.selectIcon('reportIssues')}</ToggleButton>
                        {this.props.isAdmin && (<ToggleButton value="subscriptions">{this.selectIcon('subscriptions')}</ToggleButton>)}
                        {/*this.props.isAdmin && (<ToggleButton value="payments">{this.selectIcon('payments')}</ToggleButton>)*/}
                        <ToggleButton value="invites">{this.selectIcon('invites')}</ToggleButton>
                        {this.props.isAdmin && (<ToggleButton value="partnerSubscriptions">{this.selectIcon('partnerSubscriptions')}</ToggleButton>)}
                        {/* <ToggleButton value="cameraSetups">{this.selectIcon('cameraSetups', this.state.searchWidth)}</ToggleButton> */}
                    </ToggleButtonGroup>
                </Grid>
                <Grid container >
                    <Grid item xs={12} style={{}}>
                        <div style={{ display: this.props.target === 'reportIssues' ? 'block' : 'none'}}>
                            {/* {this.createToggle('reportIssues', 'Issues')} */}
                            <QueryFieldsToggle 
                                type='reportIssues'    
                                label='Issues'
                                createToggle={this.createToggle}
                                changed={this.state.changed}
                            />
                            <Divider className={classes.divider} />
                        </div>
                        {/* {this.props.target === 'subscriptions' && this.props.isAdmin && (<>
                            {this.createToggle('subscriptions', 'Subscriptions')}
                            <Divider className={classes.divider} />
                        </>)}
                        {this.props.target === 'invites' && (<>
                            {this.createToggle('invites', 'Invites')}
                            <Divider className={classes.divider} />
                        </>)} */}
                        {/* {this.createToggle('users', 'User')} */}
                        <QueryFieldsToggle 
                            type='users'    
                            label='User'
                            createToggle={this.createToggle}
                            changed={this.state.changed}
                        />
                        {this.props.isAdmin && (<>
                            <Divider className={classes.divider} />
                            {/* {this.createToggle('subscriptions', 'Subscriptions')} */}
                            <QueryFieldsToggle 
                                type='subscriptions'    
                                label='Subcriptions'
                                createToggle={this.createToggle}
                                changed={this.state.changed}
                            />
                            <Divider className={classes.divider} />
                            {/* {this.createToggle('subscriptions', 'Subscriptions')} */}
                            {/*<QueryFieldsToggle 
                                type='payments'    
                                label='Payments'
                                createToggle={this.createToggle}
                                changed={this.state.changed}
                            />
                            <Divider className={classes.divider} />*/}
                            {/* {this.createToggle('invites', 'Invites')} */}    
                            <QueryFieldsToggle 
                                type='invites'    
                                label='Invites'
                                createToggle={this.createToggle}
                                changed={this.state.changed}
                            />
                            <Divider className={classes.divider} />
                            <QueryFieldsToggle 
                                type='partnerSubscriptions'    
                                label='Partner Subs'
                                createToggle={this.createToggle}
                                changed={this.state.changed}
                            />
                        </>)}
                        <Divider className={classes.divider} />
                        {/* {this.createToggle('sessions', 'Session')} */}
                        <QueryFieldsToggle 
                            type='sessions'    
                            label='Session'
                            createToggle={this.createToggle}
                            changed={this.state.changed}
                        />
                        <Divider className={classes.divider} />
                        {/* {this.createToggle('videos', 'Videos')} */}
                        <QueryFieldsToggle 
                            type='videos'    
                            label='Videos'
                            createToggle={this.createToggle}
                            changed={this.state.changed}
                        />
                        {this.props.isAdmin && (<>
                            <Divider className={classes.divider} />
                            {/* {this.createToggle('analysis', 'Analysis')} */}
                            <QueryFieldsToggle 
                                type='analysis'    
                                label='Analysis'
                                createToggle={this.createToggle}
                                changed={this.state.changed}
                            />
                        </>)}
                    </Grid>
                    <Grid item xs={6}>
                        <Button
                            variant='contained'
                            color='primary'
                            fullWidth
                            style={{ margin: 2 }}
                            onClick={() => { this.props.handleSubmit(true, this.state); this.setState({ changed: false }) }}
                        >Search</Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button
                            variant='outlined'
                            style={{ margin: 2 }}
                            fullWidth
                            onClick={this.clearQuery}
                        >Clear</Button>
                    </Grid>
                </Grid>
            </Paper>)
    }
}

export default QueryFields