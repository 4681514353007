import React, { useState } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Grid, TextField, InputAdornment, FormControlLabel, Checkbox } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Swal from 'sweetalert2';
import { functionBaseUrl, axiosWithToken } from '../../common/firebase';

function RemoveStudioAccountComponent({ open, close }) {
  const [email, setEmail] = useState('');

  const handleEmail = (e) => {
    setEmail(e.target.value);
  }
  const clearState = () => {
    setEmail('');
  }

  const validEmail = (email) => {
    const regex = /\S+@\S+\.\S+/;
    return regex.test(email);
  }

  const handleDowngrade = async () => {
    if (!email) {
      close();
      Swal.fire('<p style="font-size:70%;">Please fill in email</p>');
      return;
    }
    if (!validEmail(email)) {
      close();
      Swal.fire('<p style="font-size:70%;">Please enter a valid Email</p>');
      return;
    }
    
    await axiosWithToken(`${functionBaseUrl}/api/downgradeStudio`, {
      method: 'POST',
      data: {
        email: email.toLowerCase().replace(/ /g, ''),
      }
    })
    .then((response) => {
      close();
      if (response.status === 200) {
        clearState();
        Swal.fire({
          title: '<p style="font-size:70%;">Account successfully downgraded to the Free plan!</p>',
          icon: 'success',
          confirmButtonText: 'Ok',
          allowOutsideClick: false,
          customClass : { 
            container : 'my-swal' 
          },
          timer: 3000
        });
      } else {
        Swal.fire({
          title: '<p style="font-size:70%;">' + response.data.message + '</p>',
          icon: 'error',
          confirmButtonText: 'Ok',
          allowOutsideClick: false,
          customClass : { 
            container : 'my-swal' 
          },
        });
      }
      return false;
    })
    .catch((err) => {
      console.log("Error removing Studio account: ");
      Swal.fire({
        title: '<p style="font-size:70%;">Error downgrading Studio account</p>',
        icon: 'error',
        confirmButtonText: 'Ok',
        allowOutsideClick: false,
        customClass : { 
          container : 'my-swal' 
        },
      });
    })
  }

  return (<>
    <Dialog
      fullWidth
      open={open}
      onClose={close}
      aria-labelledby='form-dialog-title'>
      <DialogTitle id={'form-dialog-title'}>
        <div>
          Downgrade Studio Account To The Free Plan
          <CloseIcon style={{ float: 'right', marginTop: '-10px', marginRight: '-15px' }} onClick={close} />
        </div>
      </DialogTitle>
      <DialogContent>
        <Grid
          container
          spacing={2}
        >
          <Grid
            item
            sm={6}
            xs={12}
          >
            <TextField
              autoComplete="email"
              fullWidth
              required
              label="Email Address"
              onChange={handleEmail}
              value={email}
              variant="outlined"
              inputProps={{ maxLength: 254 }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={close} color="primary">
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleDowngrade}
        >
          DOWNGRADE
        </Button>
      </DialogActions>
    </Dialog>
  </>);
}

export const RemoveStudioAccount = RemoveStudioAccountComponent;