import React from 'react';
import { Typography, Slider, Box } from '@material-ui/core';

export const ParameterSlider = ({ defaultParameters, handleTempChange }) => (
  <Box mb={2}>
    <Typography variant='h2'>Model Parameters</Typography>          
        <Typography gutterBottom>Temperature</Typography>
        <Slider
        id="temperature"
        onChange={handleTempChange}
        aria-label="Temperature"                      
        value={defaultParameters.temperature}
        valueLabelDisplay="auto"                                                                  
        min={0}
        max={1}
        step={.1}
        style={{width: '50%'}}
        />
        <Typography gutterBottom>Max Tokens</Typography>
        <Slider
        id="max_tokens"
        onChange={handleTempChange}
        aria-label="Tokens"                      
        value={defaultParameters.max_tokens}
        valueLabelDisplay="auto"                                                                  
        min={0}
        max={512}
        step={1}
        style={{width: '50%'}}
        />
        <Typography gutterBottom>Top P</Typography>
        <Slider
        id="top_p"
        onChange={handleTempChange}
        aria-label="Top_p"                      
        value={defaultParameters.top_p}
        valueLabelDisplay="auto"                                                                  
        min={0}
        max={1}
        step={.1}
        style={{width: '50%'}}
        />
        <Typography gutterBottom>Frequency Penalty</Typography>
        <Slider
        id="frequency_penalty"
        onChange={handleTempChange}
        aria-label="Frequency_penalty"                      
        value={defaultParameters.frequency_penalty}
        valueLabelDisplay="auto"                                                                  
        min={0}
        max={1}
        step={.1}
        style={{width: '50%'}}
        />
        <Typography gutterBottom>Presence Penalty</Typography>
        <Slider
        id="presence_penalty"
        onChange={handleTempChange}
        aria-label="Presence_penalty"                      
        value={defaultParameters.presence_penalty}
        valueLabelDisplay="auto"                                                                  
        min={0}
        max={1}
        step={.1}
        style={{width: '50%'}}
        />
        <div style={{padding: 20}}></div>
  </Box>
);