import React, { useState } from 'react';
import { Stepper, Step, StepLabel } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import './promoCheckout.css';

function CTASignUpComponent() {
  const [product, setProduct] = useState(
    window.location.href.includes("gpod") ? "gpod" : "sb3d"
  );

  return (<>
    <Stepper activeStep={3} className="cta-mobile-stepper">
      <Step key={1} completed={false}>
        <StepLabel></StepLabel>
      </Step>
      <Step key={2} completed={false} className="inactive">
        <StepLabel></StepLabel>
      </Step>
      <Step key={3} completed={false} className="inactive">
        <StepLabel></StepLabel>
      </Step>
    </Stepper>
    <div className="cta-invitational">
      <img
        alt="Invitational"
        src="/images/offer/golf_pass.png"
        width={350}
      />
    </div>
    {product === "gpod" ? 
      <div className="cta-caption" >
        <span>GPOD + SPORTSBOX<br /></span>        
        BUNDLE <span><br /></span>
      </div>
    :
      <div className="cta-caption" >
        TRY <span>SPORTSBOX<br /></span>        
        AT HOME <span><br /></span>
      </div>
    }
    <div className="cta-subcaption">
      Swing analysis app trusted by the world's best.
      {product === "gpod" && (" Combining the best magnetic tripod for the fastest and easiest swing captures and 3 months of Sportsbox 3D Player! ")}
      <ul className="cta-golfpass-benefits" style={{ marginTop: "-5%" }}>
        You'll be able to practice with:
        <li><CheckIcon className="cta-benefit-mark" /> Auto swing recording</li>
        <li><CheckIcon className="cta-benefit-mark" /> Instant data feedback after each swing</li>
        <li><CheckIcon className="cta-benefit-mark" /> Pro ranges comparison</li>
        <li><CheckIcon className="cta-benefit-mark" /> Drill videos recommended for your swing</li>
      </ul>
      {product === "gpod" ?
        <div className="cta-invitational">
          <img
            alt="Invitational"
            src="/images/offer/gpod.png"
            width={350}
          />
        </div>
        :
        <div className="cta-invitational">
          <img
            alt="Invitational"
            src="/images/offer/sb3d.png"
            width={350}
          />
        </div>
      }
    </div>
  </>);
}

export const CTASignUp = CTASignUpComponent;