import React, { forwardRef, useState, useEffect } from 'react';

import { Box, Card, CardContent, CircularProgress, Container, Grid, Typography } from '@material-ui/core';
import {
  AddBox,
  ArrowDownward,
  Check,
  ChevronLeft,
  ChevronRight,
  Clear,
  DeleteOutline,
  Edit,
  FilterList,
  FirstPage,
  LastPage,
  Remove,
  SaveAlt,
  Search,
  ViewColumn,
  Receipt,
} from "@material-ui/icons";
import MaterialTable from "material-table";
import Swal from 'sweetalert2';
import moment from 'moment';
import { CsvBuilder } from 'filefy';
import jsPDF from 'jspdf';
import firebase, { functionBaseUrl, axiosWithToken } from '../../../common/firebase';
//import { ElavonPayment } from '../../../components/elavonPayment';
import { StripePayment } from '../../../components/stripePayment';

const tableIcons = {
  ExportCSV: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  ExportPDF: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => (
    <ArrowDownward {...props} ref={ref} />
  )),
  ThirdStateCheck: forwardRef((props, ref) => (
    <Remove {...props} ref={ref} />
  )),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  Receipt: forwardRef((props, ref) => <Receipt {...props} ref={ref} />),
};

const tableHeader = [
  { field: "userEmail", title: "Email Address" },
  { field: "userName", title: "Name" },
  { field: "startDate", title: "Start Date",
    render: (rowData) => {
      return moment(String(rowData.startDate), "x").format("MM/DD/YYYY");
    }   
  },
  { field: "coachDetails", title: "Plan", 
    customSort: (a, b) => {
      let aScore = 0;
      let bScore = 0;
      if (a.coachDetails.productId?.includes("student_lite_monthly")) {
        aScore = 1;
      }
      if (a.coachDetails.productId?.includes("student_lite_annually")) {
        aScore = 2;
      }
      if (b.coachDetails.productId?.includes("student_lite_monthly")) {
        bScore = 1;
      }
      if (b.coachDetails.productId?.includes("student_lite_annually")) {
        bScore = 2;
      }
      
      return aScore - bScore;
    },
    render: (rowData) => {
      if (rowData.coachDetails && rowData.coachDetails.productId.includes("student_lite_monthly")) {
        return "3D Player Monthly"
      }
      if (rowData.coachDetails && rowData.coachDetails.productId.includes("student_lite_annually")) {
        return "3D Player Annually"
      }
      return ""
    } 
  },
  { field: "amount", title: "Price",
    render: (rowData) => {
      return "$" + rowData?.amount
    }
  },
  { field: "coachDetails", title: "Period",
    customSort: (a, b) => a?.coachDetails?.period - b?.coachDetails?.period,
    render: (rowData) => {
      return rowData?.coachDetails?.period
    } 
  },
  { field: "revenue", title: "Revenue Share",
    render: (rowData) => {
      return "$" + ((rowData?.amount * parseInt(rowData.revenue) * rowData.coachDetails.period) / 100).toFixed(2) + " (" + rowData.revenue + ")"
    } 
  },
  {
    field: "cost",
    title: "Balance",
    customSort: (a, b) => a.amount * ((100 - parseInt(a.revenue)) / 100) * a.coachDetails.period - b.amount * ((100 - parseInt(b.revenue)) / 100) * b.coachDetails.period,
    render: (rowData) => {
      return "$" + (rowData.amount * ((100 - parseInt(rowData.revenue)) / 100) * rowData.coachDetails.period).toFixed(2);
    },
  },
];

function EnterprisePayStudentsComponent({ history }) {
  const [isLoading, setIsLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [total, setTotal] = useState(0);
  const [amount, setAmount] = useState(0);

  const loadData = async () => {
    try {
      setIsLoading(true);
      const firebaseUser = firebase.auth().currentUser;
      const response = await axiosWithToken(`${functionBaseUrl}/api/unpaidStudentsSub`, {
        method: 'POST',
        data: { userId: firebaseUser.uid }
      })
        .catch((error) => {
          throw error;
        });
      let total = 0;
      let amount = 0;
      let discount = 10;
      for (let index = 0; index < response.data.data.length; index++) {
        if ((response.data.total + index) < 29) {
          discount = 10;
        } else if ((response.data.total + index) < 49) {
          discount = 20; 
        } else {
          discount = 30;
        }
        total += response.data.data[index].amount * response.data.data[index].coachDetails.period;
        amount += response.data.data[index].amount * (100 - discount) / 100 * response.data.data[index].coachDetails.period;
        response.data.data[index].revenue = `${discount}%`;
        //response.data.data[index].amount = (response.data.data[index].amount * (100 - discount) / 100).toFixed(2);
      }
      setUsers(response.data.data);
      setTotal(total.toFixed(2));
      setAmount(amount.toFixed(2));
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      Swal.fire({
        title: '<p style="font-size:70%;">Error loading data</p>',
        icon: 'error',
        confirmButtonText: 'Ok',
        allowOutsideClick: true,
      });
    }
  }

  useEffect(() => {
    loadData();
  }, [])

  async function handleResult(data) {
    for (const user of users) {
      let coachDetails = user.coachDetails;
      coachDetails.status = 'Paid';
      await axiosWithToken(functionBaseUrl + '/api/subscription/' + user.id, {
        method: 'PATCH',
        data: { coachDetails: coachDetails }
      })
        .catch((error) => {
          throw error;
        });
    }
    const firebaseUser = firebase.auth().currentUser;
    await axiosWithToken(functionBaseUrl + '/api/createInvoice', {
      method: 'POST',
      data: {
        coachId: firebaseUser.uid,
        period: moment().unix() * 1000,
        status: 'Paid',
        subscriptions: users.map(sub => sub.id),
        amount: (data?.amount / 100).toFixed(2),
        revenueShare: (total - amount).toFixed(2),
        transactionId: data?.id
      }
    })
      .catch((error) => {
        throw error;
      });
    await Swal.fire({
      title: `<p style="font-size:70%;">Thank you for your payment!</p>`,
      showConfirmButton: false,
      icon: 'success',
      customClass : { 
        container : 'my-swal' 
      },
      timer: 2000,
      showCloseButton: true,
      allowOutsideClick: true
    }).then(async () => {
      await history.push('/students');
    })
  }

  return (<>
    {isLoading && (
      <Container maxWidth="xs" style={{ textAlign: 'center', paddingTop: '50px' }}>
        <CircularProgress />
      </Container>
    )}
    {!isLoading && (
      <Container
        component="main"
        style={{ paddingTop: '50px' }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <h2 style={{ textAlign: 'center' }}>{moment().format("MMMM")} {moment().format("YYYY")} Invoice</h2>
          </Grid>
          <Grid
            item
            xs={12}
          >
            <Grid container justify="center">
              <Grid
                item
                sm={6}
                xs={12}
              >
                <Box border={1} borderColor="primary.main" style={{ marginBottom: "5%" }}>
                <Card style={{ minWidth: "50%" }}>
                  <CardContent>
                    <Grid container justify="space-between">  
                      <Typography
                          inline
                          component="h6"
                          variant="h5"
                          justify="center"
                        >
                          <b>Total Amount:</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      </Typography>
                      <Typography
                          inline
                          component="h6"
                          variant="h5"
                          justify="center"
                          align="right"
                        >
                          ${total}
                      </Typography>
                    </Grid>
                    <Grid container justify="space-between">  
                      <Typography
                          inline
                          component="h6"
                          variant="h5"
                          justify="center"
                        >
                          <b>Revenue Share:</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      </Typography>
                      <Typography
                          inline
                          component="h6"
                          variant="h5"
                          justify="center"
                          align="right"
                        >
                          -${(total - amount).toFixed(2)}
                      </Typography>
                    </Grid>
                  </CardContent>
                </Card>
                  <Card>
                    <CardContent fullWidth>
                      <Typography style={{ textAlign: 'center', marginTop: 20, marginBottom: 20 }}>
                        <span style={{ fontSize: '28px' }}>Current Balance: <b>${amount}</b></span><br/>
                        {/*total - amount > 0 && (
                          <div>
                            <span style={{ fontSize: '18px' }}>Revenue Share Discount: {(total - amount).toFixed(2)}% off</span><br/>
                            <span style={{ fontSize: '18px' }}>Total Amount: ${total}</span>
                          </div>
                        )*/}
                      </Typography>
                      {amount > 0 && (<StripePayment amount={amount} email={firebase.auth().currentUser?.email} handleResult={handleResult} useConfirmation={true} />)}
                    </CardContent>
                  </Card>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
          >
            <h4>Details:</h4>
            <MaterialTable
              style={{ marginTop: "10px", marginBottom: "10px" }}
              icons={tableIcons}
              columns={tableHeader}
              title=""
              data={users}
              options={{
                selection: true,
                sorting: true,
                search: true,
                paging: true,
                exportAllData: true,
                exportButton: true,
                pageSizeOptions: [5, 10, 20, 50, 100],
                exportCsv: (columns, data) => {
                  const columnTitles = columns.map(columnDef => columnDef.title);
                  const csvData = data.map(rowData =>
                    columns.map(columnDef => {
                      const col = tableHeader.find(c => c.field == columnDef.field);
                      if (col.render) {
                        return col.render(rowData);
                      }
                      return rowData[columnDef.field];
                    }),
                  );
                  const builder = new CsvBuilder('data.csv')
                    .setColumns(columnTitles)
                    .addRows(csvData)
                    .exportFile();
                  return builder;
                },
                exportPdf: (columns, data) => {
                  const doc = new jsPDF();
                  const columnTitles = columns.map(columnDef => columnDef.title);
                  const pdfData = data.map(rowData =>
                    columns.map(columnDef => {
                      const col = tableHeader.find(c => c.field == columnDef.field);
                      if (col.render) {
                        return col.render(rowData);
                      }
                      return rowData[columnDef.field];
                    }),
                  );
                  doc.autoTable({
                     head: [columnTitles],
                     body: pdfData,
                  });
                  doc.save(`data.pdf`);
                },
                rowStyle: (x) => {
                  if (x.tableData.id % 2) {
                    return { backgroundColor: "#f2f2f2" };
                  }
                },
              }}
            />
          </Grid>
        </Grid>
      </Container>
    )}
  </>);
}

export const EnterprisePayStudents = EnterprisePayStudentsComponent;