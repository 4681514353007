import React from "react";
import { GeneratePdfButton } from "../swing_library/components/PdfGeneration/GeneratePdfButton";

export const PdfWithCSS = () => {
  return (
    <div>
      <GeneratePdfButton
        videoIds={[
          "vuX7bzA2Bsg56NKq4OgH",
          "9ZVCZIxAKtnfLUKWAzo9",
          "tLD5PsNlbkUjylavgyPz",
        ]}
        golfClub={"D"}
        userName="Jane Doe"
        height="5′11″"
        gender="MALE"
      />
    </div>
  );
};
