import React, { useState, useEffect } from "react";
import {
  Container,
  FormControl,
  Grid,
  InputLabel,
  IconButton,
  Button,
  Select,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  CircularProgress,
} from "@material-ui/core";
import { DateRange } from "@material-ui/icons";
import ClearIcon from '@material-ui/icons/Clear';
import { Bar } from "react-chartjs-2";
import { DateRangePicker, defaultStaticRanges, createStaticRanges } from "react-date-range";
import moment from "moment";
import { functionBaseUrl } from "../../common/firebase";
import axios from "axios";
import {
  parse,
  format,
  startOfDay,
  endOfDay,
  startOfWeek,
  endOfWeek,
  startOfMonth,
  endOfMonth,
  startOfYear,
  endOfYear,
  eachDayOfInterval,
  eachWeekOfInterval,
  eachMonthOfInterval,
  eachYearOfInterval,
  getISOWeek,
  getISOWeekYear,
  getYear,
  addWeeks,
  set,
} from "date-fns";
import { listOfSubscriptions } from "../../common/envConfig";

function sum(arr, n) {
  var total = 0;
  if (n <= arr?.length) {
    for (var i = 0; i < n; i++) {
      total += arr[i];
    }
  }
  return total;
}

function zeroPad(num, places) {
  var zero = places - num.toString()?.length + 1;
  return Array(+(zero > 0 && zero)).join("0") + num;
}

function SummaryChartsComponent({
  users,
  sessions,
  videos,
  subscriptions,
  recurringSubscriptions,
  canceledSubscriptions,
  staffAccounts,
  deviceLimitExceptionList,
}) {
  const [statType, setStatType] = useState("recurringSubscriptions");
  const [statPeriod, setStatPeriod] = useState("byDays");
  const [data, setData] = useState({});
  const [options, setOptions] = useState({});
  const [openRangeDialog, setOpenRangeDialog] = useState(false);
  const [selectionRange, setSelectionRange] = useState({
    startDate: new Date(Date.now() - 14 * 24 * 60 * 60 * 1000).setHours(0, 0, 0),
    endDate: new Date().setHours(23, 59, 59),
    key: "range1",
  });
  const [dates, setDates] = useState([]);
  const [loading, setLoading] = useState(true);
  const [issues, setIssues] = useState([]);
  const [issuesFON, setIssuesFON] = useState([]);
  const [issuesDTL, setIssuesDTL] = useState([]);
  const [analysis, setAnalysis] = useState([]);
  const [analysisFON, setAnalysisFON] = useState([]);
  const [analysisDTL, setAnalysisDTL] = useState([]);
  const [tempUsers, setUsers] = useState([]);
  const [tempCoaches, setCoaches] = useState([]);
  const [tempStudents, setStudents] = useState([]);
  const [tempFreeStudents, setFreeStudents] = useState([]);
  const [tempPaidStudents, setPaidStudents] = useState([]);
  const [tempSessions, setSessions] = useState([]);
  const [tempVideos, setVideos] = useState([]);
  const [videosFON, setVideosFON] = useState([]);
  const [videosDTL, setVideosDTL] = useState([]);
  const [avgCS, setavgCS] = useState([]);
  const [avgCSP, setavgCSP] = useState([]);
  const [issuesPercentage, setIssuesPercentage] = useState([]);
  const [issuesFONPercentage, setIssuesFONPercentage] = useState([]);
  const [issuesDTLPercentage, setIssuesDTLPercentage] = useState([]);
  const [analysisPercentage, setAnalysisPercentage] = useState([]);
  const [analysisFONPercentage, setAnalysisFONPercentage] = useState([]);
  const [analysisDTLPercentage, setAnalysisDTLPercentage] = useState([]);
  const [screenRecordings, setScreenRecordings] = useState([]);
  const [defaultRange, setDefaultRange] = useState(true);
  //const [tempSubscriptions, setSubscriptions] = useState([]);

  const handleStatType = (e) => {
    setStatType(e.target.value);
    getDataWithRange(e.target.value, {}, statPeriod);
  };
  const handleStatPeriod = (e) => {
    setStatPeriod(e.target.value);
    if (defaultRange) {
      switch (e.target.value) {
        case "byDays":
          const tempDateDays = {
            startDate: new Date(Date.now() - 14 * 24 * 60 * 60 * 1000).setHours(0, 0, 0),
            endDate: new Date().setHours(23, 59, 59),
            key: "range1",
          }
          setSelectionRange(tempDateDays);
          getDataWithRange(statType, tempDateDays, e.target.value);
          break;
        case "byWeeks":
          const tempDateWeeks = {
            startDate: new Date(Date.now() - 30 * 24 * 60 * 60 * 1000).setHours(0, 0, 0),
            endDate: new Date().setHours(23, 59, 59),
            key: "range1",
          }
          setSelectionRange(tempDateWeeks);
          getDataWithRange(statType, tempDateWeeks, e.target.value);
          break;
        case "byMonths":
          const tempDateMonths = {
            startDate: new Date(Date.now() - 90 * 24 * 60 * 60 * 1000).setHours(0, 0, 0),
            endDate: new Date().setHours(23, 59, 59),
            key: "range1",
          }
          setSelectionRange(tempDateMonths);
          getDataWithRange(statType, tempDateMonths, e.target.value);
          break;
        case "byYears":
          const tempDateYears = {
            startDate: new Date(Date.now() - 1095 * 24 * 60 * 60 * 1000).setHours(0, 0, 0),
            endDate: new Date().setHours(23, 59, 59),
            key: "range1",
          }
          setSelectionRange(tempDateYears);
          getDataWithRange(statType, tempDateYears, e.target.value);
          break;
        default:
          getDataWithRange(statType, {}, e.target.value);
          break;
      }
    } else {
      getDataWithRange(statType, {}, e.target.value);
    }
  };

  const must_not_terms = ["*sb3d*", "*3dsb*", "*sportsbox*", "*demo*"];
  const must_not = must_not_terms.map((term) => {
    return { wildcard: { email: term } };
  });
  if (deviceLimitExceptionList && deviceLimitExceptionList?.length > 0)
    must_not.push({ terms: { "email.keyword": deviceLimitExceptionList } });

  const analysisParentQuery = {
    has_parent: {
      parent_type: "videos",
      query: {
        has_parent: {
          parent_type: "sessions",
          query: {
            has_parent: {
              parent_type: "users",
              query: { bool: { must_not: must_not } },
            },
          },
        },
      },
    },
  };

  async function fetchAnalysisData(dateRange, index, analysisData, analysisDataFON, analysisDataDTL) {
    let analysisSnapFONPromise = axios(
      functionBaseUrl + "/api/elastic/collection/size/filter",
      {
        method: "POST",
        data: {
          collection: "analysis",
          query: {
            bool: {
              must: [
                { match: { docType: "analysis" } },
                {
                  bool: {
                    should: [
                      { bool: { must_not: { exists: { field: "videoMetadata.cameraAngle" } } } },
                      { wildcard: { "videoMetadata.cameraAngle.keyword": "*FaceOn*" } },
                      { wildcard: { "videoMetadata.cameraAngle.keyword": "*FON*" } },
                      { wildcard: { "videoMetadata.cameraAngle.keyword": "*" } },
                    ],
                    minimum_should_match: 1,
                  },
                },
              ],
              filter: [
                {
                  range: {
                    createdDate: {
                      gte: String(dateRange.start),
                      lte: String(dateRange.end),
                    },
                  },
                },
                analysisParentQuery,
              ],
            },
          },
        },
      }
    );
  
    let analysisSnapDTLPromise = axios(
      functionBaseUrl + "/api/elastic/collection/size/filter",
      {
        method: "POST",
        data: {
          collection: "analysis",
          query: {
            bool: {
              must: [
                { match: { docType: "analysis" } },
                {
                  bool: {
                    should: [
                      { wildcard: { "videoMetadata.cameraAngle.keyword": "*DownTheLine*" } },
                      { wildcard: { "videoMetadata.cameraAngle.keyword": "*DTL*" } },
                    ],
                    minimum_should_match: 1,
                  },
                },
              ],
              filter: [
                {
                  range: {
                    createdDate: {
                      gte: String(dateRange.start),
                      lte: String(dateRange.end),
                    },
                  },
                },
                analysisParentQuery,
              ],
            },
          },
        },
      }
    );
  
    // Wait for both requests to complete
    let [analysisSnapFON, analysisSnapDTL] = await Promise.all([analysisSnapFONPromise, analysisSnapDTLPromise]);
  
    analysisDataFON[index] = analysisSnapFON?.data?.count || 0;
    analysisDataDTL[index] = analysisSnapDTL?.data?.count || 0;
    analysisData[index] = (analysisSnapFON?.data?.count || 0) + (analysisSnapDTL?.data?.count || 0);
  }
  
  async function fetchIssuesData(dateRange, index, issuesData, issuesDataFON, issuesDataDTL) {
    let issuesSnapFONPromise = axios(
      functionBaseUrl + "/api/elastic/collection/size/filter",
      {
        method: "POST",
        data: {
          collection: "reportIssues",
          query: {
            bool: {
              must: [
                {
                  bool: {
                    should: [
                      { bool: { must_not: { exists: { field: "cameraAngle" } } } },
                      { wildcard: { "cameraAngle.keyword": "*FaceOn*" } },
                      { wildcard: { "cameraAngle.keyword": "*FON*" } },
                      { wildcard: { "cameraAngle.keyword": "*" } },
                    ],
                    minimum_should_match: 1,
                  },
                },
              ],
              filter: [
                {
                  range: {
                    createdDate: {
                      gte: String(dateRange.start),
                      lte: String(dateRange.end),
                    },
                  },
                },
              ],
            },
          },
        },
      }
    );
  
    let issuesSnapDTLPromise = axios(
      functionBaseUrl + "/api/elastic/collection/size/filter",
      {
        method: "POST",
        data: {
          collection: "reportIssues",
          query: {
            bool: {
              must: [
                {
                  bool: {
                    should: [
                      { wildcard: { "cameraAngle.keyword": "*DownTheLine*" } },
                      { wildcard: { "cameraAngle.keyword": "*DTL*" } },
                    ],
                    minimum_should_match: 2,
                  },
                },
              ],
              filter: [
                {
                  range: {
                    createdDate: {
                      gte: String(dateRange.start),
                      lte: String(dateRange.end),
                    },
                  },
                },
              ],
            },
          },
        },
      }
    );
  
    // Wait for both requests to complete
    let [issuesSnapFON, issuesSnapDTL] = await Promise.all([issuesSnapFONPromise, issuesSnapDTLPromise]);
  
    issuesDataFON[index] = issuesSnapFON?.data?.count || 0;
    issuesDataDTL[index] = issuesSnapDTL?.data?.count || 0;
    issuesData[index] = (issuesSnapFON?.data?.count || 0) + (issuesSnapDTL?.data?.count || 0);
  }

  async function fetchAnalysisPctData(dateRange, index, analysisData, analysisDataFON, analysisDataDTL) {
    let [analysisSnapFON, analysisSnapDTL] = await Promise.all([
      axios.post(
        functionBaseUrl + "/api/elastic/collection/size/filter",
        {
          collection: "analysis",
          query: {
            bool: {
              must: [
                { match: { docType: "analysis" } },
                {
                  bool: {
                    should: [
                      { bool: { must_not: { exists: { field: "videoMetadata.cameraAngle" } } } },
                      { wildcard: { "videoMetadata.cameraAngle.keyword": "*FaceOn*" } },
                      { wildcard: { "videoMetadata.cameraAngle.keyword": "*FON*" } },
                      { wildcard: { "videoMetadata.cameraAngle.keyword": "*" } },
                    ],
                    minimum_should_match: 1,
                  },
                },
              ],
              filter: [
                { range: { createdDate: { gte: String(dateRange.start), lte: String(dateRange.end) } } },
                analysisParentQuery,
              ],
            },
          },
        }
      ),
      axios.post(
        functionBaseUrl + "/api/elastic/collection/size/filter",
        {
          collection: "analysis",
          query: {
            bool: {
              must: [
                { match: { docType: "analysis" } },
                {
                  bool: {
                    should: [
                      { wildcard: { "videoMetadata.cameraAngle.keyword": "*DownTheLine*" } },
                      { wildcard: { "videoMetadata.cameraAngle.keyword": "*DTL*" } },
                    ],
                    minimum_should_match: 1,
                  },
                },
              ],
              filter: [
                { range: { createdDate: { gte: String(dateRange.start), lte: String(dateRange.end) } } },
                analysisParentQuery,
              ],
            },
          },
        }
      )
    ]);
  
    let analysisTotal = (analysisSnapFON?.data?.count || 0) + (analysisSnapDTL?.data?.count || 0);
    analysisData[index] = analysisTotal;
    analysisDataFON[index] = Math.round((analysisSnapFON?.data?.count / analysisTotal || 0) * 10000) / 100;
    analysisDataDTL[index] = Math.round((analysisSnapDTL?.data?.count / analysisTotal || 0) * 10000) / 100;
  }
  
  async function fetchIssuesPctData(dateRange, index, issuesData, issuesDataFON, issuesDataDTL) {
    let [issuesSnapFON, issuesSnapDTL] = await Promise.all([
      axios.post(
        functionBaseUrl + "/api/elastic/collection/size/filter",
        {
          collection: "reportIssues",
          query: {
            bool: {
              must: [
                {
                  bool: {
                    should: [
                      { bool: { must_not: { exists: { field: "cameraAngle" } } } },
                      { wildcard: { "cameraAngle.keyword": "*FaceOn*" } },
                      { wildcard: { "cameraAngle.keyword": "*FON*" } },
                      { wildcard: { "cameraAngle.keyword": "*" } },
                    ],
                    minimum_should_match: 1,
                  },
                },
              ],
              filter: [
                { range: { createdDate: { gte: String(dateRange.start), lte: String(dateRange.end) } } },
              ],
            },
          },
        }
      ),
      axios.post(
        functionBaseUrl + "/api/elastic/collection/size/filter",
        {
          collection: "reportIssues",
          query: {
            bool: {
              must: [
                {
                  bool: {
                    should: [
                      { wildcard: { "cameraAngle.keyword": "*DownTheLine*" } },
                      { wildcard: { "cameraAngle.keyword": "*DTL*" } },
                    ],
                    minimum_should_match: 1,
                  },
                },
              ],
              filter: [
                { range: { createdDate: { gte: String(dateRange.start), lte: String(dateRange.end) } } },
              ],
            },
          },
        }
      )
    ]);

    let issuesTotal = (issuesSnapFON?.data?.count || 0) + (issuesSnapDTL?.data?.count || 0);
    issuesData[index] = issuesTotal;
    issuesDataFON[index] = Math.round((issuesSnapFON?.data?.count / issuesTotal || 0) * 10000) / 100;
    issuesDataDTL[index] = Math.round((issuesSnapDTL?.data?.count / issuesTotal || 0) * 10000) / 100;
  }

  async function fetchUserData(dateRange, index, dataArr, userType, subscriptionTypes = []) {
    let snap = await axios.post(
      functionBaseUrl + "/api/elastic/collection/size/filter",
      {
        collection: "users",
        query: {
          bool: {
            must: [{ match: { docType: "users" } }],
            filter: [
              { range: { userCreated: { gte: String(dateRange.start), lte: String(dateRange.end) } } },
            ],
            must_not: must_not,
          },
        },
      }
    );
  
    if (userType === "coaches") {
      snap = await axios.post(
        functionBaseUrl + "/api/elastic/collection/size/filter",
        {
          collection: "users",
          query: {
            bool: {
              must: [
                { match: { docType: "users" } },
                {
                  terms: { subscriptionType: subscriptionTypes },
                },
              ],
              filter: [
                { range: { userCreated: { gte: String(dateRange.start), lte: String(dateRange.end) } } },
              ],
              must_not: must_not,
            },
          },
        }
      );
    } else if (userType === "students") {
      snap = await axios.post(
        functionBaseUrl + "/api/elastic/collection/size/filter",
        {
          collection: "users",
          query: {
            bool: {
              must: [
                { match: { docType: "users" } },
                {
                  terms: { subscriptionType: subscriptionTypes },
                },
              ],
              filter: [
                { range: { userCreated: { gte: String(dateRange.start), lte: String(dateRange.end) } } },
              ],
              must_not: must_not,
            },
          },
        }
      );
    }
  
    dataArr[index] = snap?.data?.count || 0;
  }

  async function fetchSessionData(dateRange, index, sessionsData) {
    let sessionsSnap = await axios.post(
      functionBaseUrl + "/api/elastic/collection/size/filter",
      {
        collection: "sessions",
        query: {
          bool: {
            must: [{ match: { docType: "sessions" } }],
            filter: [
              { range: { sessionDate: { gte: String(dateRange.start), lte: String(dateRange.end) } } },
              {
                has_parent: {
                  parent_type: "users",
                  query: { bool: { must_not: must_not } },
                },
              },
            ],
          },
        },
      }
    );
  
    sessionsData[index] = sessionsSnap?.data?.count || 0;
  }

  async function fetchVideoData(dateRange, index, videosData, videosDataFON, videosDataDTL) {
    let videosSnap = await axios.post(
      functionBaseUrl + "/api/elastic/collection/size/filter",
      {
        collection: "videos",
        query: {
          bool: {
            must: [{ match: { docType: "videos" } }],
            filter: [
              { range: { videoCreated: { gte: String(dateRange.start), lte: String(dateRange.end) } } },
              {
                has_parent: {
                  parent_type: "sessions",
                  query: {
                    has_parent: {
                      parent_type: "users",
                      query: { bool: { must_not: must_not } },
                    },
                  },
                },
              },
            ],
          },
        },
      }
    );
  
    videosData[index] = videosSnap?.data?.count || 0;
  
    let videosSnapFON = await axios.post(
      functionBaseUrl + "/api/elastic/collection/size/filter",
      {
        collection: "videos",
        query: {
          bool: {
            must: [
              { match: { docType: "videos" } },
              {
                bool: {
                  should: [
                    { bool: { must_not: { exists: { field: "metaData.cameraAngle" } } } },
                    { match: { "metaData.cameraAngle.keyword": "" } },
                    { match: { "metaData.cameraAngle.keyword": "FaceOn" } },
                    { match: { "metaData.cameraAngle.keyword": "FaceOn (FO)" } },
                    { match: { "metaData.cameraAngle.keyword": "FON" } },
                  ],
                  minimum_should_match: 1,
                },
              },
            ],
            filter: [
              { range: { videoCreated: { gte: String(dateRange.start), lte: String(dateRange.end) } } },
              {
                has_parent: {
                  parent_type: "sessions",
                  query: {
                    has_parent: {
                      parent_type: "users",
                      query: { bool: { must_not: must_not } },
                    },
                  },
                },
              },
            ],
          },
        },
      }
    );
  
    videosDataFON[index] = videosSnapFON?.data?.count || 0;
  
    let videosSnapDTL = await axios.post(
      functionBaseUrl + "/api/elastic/collection/size/filter",
      {
        collection: "videos",
        query: {
          bool: {
            must: [
              { match: { docType: "videos" } },
              {
                bool: {
                  should: [
                    // { bool: { must: { exists: { field: "metaData.cameraAngle" } } } },
                    { match: { "metaData.cameraAngle.keyword": "DownTheLine" } },
                    { match: { "metaData.cameraAngle.keyword": "DTL" } },
                  ],
                  minimum_should_match: 1,
                },
              },
            ],
            filter: [
              { range: { videoCreated: { gte: String(dateRange.start), lte: String(dateRange.end) } } },
              {
                has_parent: {
                  parent_type: "sessions",
                  query: {
                    has_parent: {
                      parent_type: "users",
                      query: { bool: { must_not: must_not } },
                    },
                  },
                },
              },
            ],
          },
        },
      }
    );
  
    videosDataDTL[index] = videosSnapDTL?.data?.count || 0;
  }

  const getDataWithRange = async (collection, range, period) => {
    setLoading(true);

    const must_not_terms = ["*sb3d*", "*3dsb*", "*sportsbox*", "*demo*"];
    const must_not = must_not_terms.map((term) => {
      return { wildcard: { email: term } };
    });
    if (deviceLimitExceptionList && deviceLimitExceptionList?.length > 0)
      must_not.push({ terms: { "email.keyword": deviceLimitExceptionList } });
    let dates = [];
    if (Object.keys(range)?.length > 0) {
      dates = getAllDates(
        String(range.startDate.valueOf()),
        String(range.endDate.valueOf()),
        period.toLowerCase().substring(2)
      );
    } else {
      dates = getAllDates(
        String(selectionRange.startDate.valueOf()),
        String(selectionRange.endDate.valueOf()),
        period.toLowerCase().substring(2)
      );
    }
    setDates(dates);

    const analysisParentQuery = {
      has_parent: {
        parent_type: "videos",
        query: {
          has_parent: {
            parent_type: "sessions",
            query: {
              has_parent: {
                parent_type: "users",
                query: { bool: { must_not: must_not } },
              },
            },
          },
        },
      },
    };

    if (collection === "screenRecordings") {
      setScreenRecordings([]);
      let screenRecordingsData = [];

      let promises = dates.map(date => {
        let dateRange = getDateRange(date);
        return axios.post(
          functionBaseUrl + "/api/elastic/collection/size/filter",
          {
            collection: "userPosts",
            query: {
              bool: {
                must: [
                  {
                    match: {
                      docType: "userPosts",
                    },
                  },
                  {
                    bool: {
                      should: [
                        {
                          wildcard: {
                            "postType.keyword": "*screenRecording*",
                          },
                        },
                      ],
                    },
                  },
                ],
                filter: [
                  {
                    range: {
                      createdAt: {
                        gte: String(dateRange.start),
                        lte: String(dateRange.end),
                      },
                    },
                  },
                ],
                must_not: must_not,
              },
            },
          }
        );
      });
    
      Promise.all(promises)
        .then(responses => {
          responses.forEach(response => {
            screenRecordingsData.push(response.data.count);
          });
          setScreenRecordings(screenRecordingsData);
        })
        .catch(error => {
          console.error("Error fetching screen recordings data:", error);
        });
    }

    if (collection === "analysis" || collection === "issues") {
      setIssues([]);
      setIssuesFON([]);
      setIssuesDTL([]);
      setAnalysis([]);
      setAnalysisFON([]);
      setAnalysisDTL([]);

      let analysisData = new Array(dates.length).fill(0);
      let analysisDataFON = new Array(dates.length).fill(0);
      let analysisDataDTL = new Array(dates.length).fill(0);
      let issuesData = new Array(dates.length).fill(0);
      let issuesDataFON = new Array(dates.length).fill(0);
      let issuesDataDTL = new Array(dates.length).fill(0);
      
      let promises = [];
      
      for (let i = 0; i < dates.length; i++) {
        let date = dates[i];
        let dateRange = getDateRange(date);
        
        promises.push(fetchAnalysisData(dateRange, i, analysisData, analysisDataFON, analysisDataDTL));

        promises.push(fetchIssuesData(dateRange, i, issuesData, issuesDataFON, issuesDataDTL));
      }
    
      await Promise.all(promises);
    
      setIssues(issuesData);
      setIssuesFON(issuesDataFON);
      setIssuesDTL(issuesDataDTL);
      setAnalysis(analysisData);
      setAnalysisFON(analysisDataFON);
      setAnalysisDTL(analysisDataDTL);
    }

    if (
      collection === "analysisByPercentage" ||
      collection === "issuesByPercentage"
    ) {
      setIssuesPercentage([]);
      setIssuesFONPercentage([]);
      setIssuesDTLPercentage([]);
      setAnalysisPercentage([]);
      setAnalysisFONPercentage([]);
      setAnalysisDTLPercentage([]);

      let analysisData = new Array(dates.length).fill(0);
      let analysisDataFON = new Array(dates.length).fill(0);
      let analysisDataDTL = new Array(dates.length).fill(0);
      let issuesData = new Array(dates.length).fill(0);
      let issuesDataFON = new Array(dates.length).fill(0);
      let issuesDataDTL = new Array(dates.length).fill(0);

      let promises = [];

      for (let i = 0; i < dates.length; i++) {
        let date = dates[i];
        let dateRange = getDateRange(date);

        promises.push(
          fetchAnalysisPctData(dateRange, i, analysisData, analysisDataFON, analysisDataDTL)
        );

        promises.push(
          fetchIssuesPctData(dateRange, i, issuesData, issuesDataFON, issuesDataDTL)
        );
      }

      await Promise.all(promises);

      setIssuesPercentage(issuesData);
      setIssuesFONPercentage(issuesDataFON);
      setIssuesDTLPercentage(issuesDataDTL);
      setAnalysisPercentage(analysisData);
      setAnalysisFONPercentage(analysisDataFON);
      setAnalysisDTLPercentage(analysisDataDTL);
    }

    if (collection === "users") {
      setUsers([]);
      setCoaches([]);
      setStudents([]);
      setFreeStudents([]);
      setPaidStudents([]);

      let usersData = new Array(dates.length).fill(0);
      let coachesData = new Array(dates.length).fill(0);
      let studentsData = new Array(dates.length).fill(0);
      let freeStudentsData = new Array(dates.length).fill(0);
      let paidStudentsData = new Array(dates.length).fill(0);

      let promises = [];

      for (let i = 0; i < dates.length; i++) {
        let date = dates[i];
        let dateRange = getDateRange(date);

        promises.push(fetchUserData(dateRange, i, usersData, "users"));

        promises.push(fetchUserData(dateRange, i, coachesData, "coaches", [
          listOfSubscriptions.LITE_MONTHLY,
          listOfSubscriptions.LITE_ANNUALLY,
          listOfSubscriptions.PREMIUM_MONTHLY,
          listOfSubscriptions.PREMIUM_ANNUALLY,
          listOfSubscriptions.ENTERPRISE_MASTER,
          listOfSubscriptions.ENTERPRISE_COACH,
          listOfSubscriptions.STUDIO_MASTER,
          listOfSubscriptions.STUDIO_COACH,
        ]));

        promises.push(fetchUserData(dateRange, i, studentsData, "students", [
          "",
          listOfSubscriptions.STUDENT_FREE,
          listOfSubscriptions.STUDENT_LITE_MONTHLY,
          listOfSubscriptions.STUDENT_LITE_ANNUALLY,
        ]));

        promises.push(fetchUserData(dateRange, i, freeStudentsData, "students", [
          "",
          listOfSubscriptions.STUDENT_FREE,
        ]));

        promises.push(fetchUserData(dateRange, i, paidStudentsData, "students", [
          listOfSubscriptions.STUDENT_LITE_MONTHLY,
          listOfSubscriptions.STUDENT_LITE_ANNUALLY,
        ]));
      }

      await Promise.all(promises);

      setUsers(usersData);
      setCoaches(coachesData);
      setStudents(studentsData);
      setFreeStudents(freeStudentsData);
      setPaidStudents(paidStudentsData);
    }

    if (collection === "sessions") {
      setSessions([]);

      let sessionsData = new Array(dates.length).fill(0);

      let promises = [];

      for (let i = 0; i < dates.length; i++) {
        let date = dates[i];
        let dateRange = getDateRange(date);

        promises.push(fetchSessionData(dateRange, i, sessionsData));
      }

      await Promise.all(promises);

      setSessions(sessionsData);
    }

    if (collection === "videos") {
      setVideos([]);
      setVideosFON([]);
      setVideosDTL([]);

      let videosData = new Array(dates.length).fill(0);
      let videosDataFON = new Array(dates.length).fill(0);
      let videosDataDTL = new Array(dates.length).fill(0);

      let promises = [];

      for (let i = 0; i < dates.length; i++) {
        let date = dates[i];
        let dateRange = getDateRange(date);

        promises.push(fetchVideoData(dateRange, i, videosData, videosDataFON, videosDataDTL));
      }

      await Promise.all(promises);

      setVideos(videosData);
      setVideosFON(videosDataFON);
      setVideosDTL(videosDataDTL);
    }

    if (collection === "confidenceScore") {
      setavgCS([]);

      let avgCSData = [[], [], [], [], []]; // Array of arrays to store counts for different score ranges

      for (const date of dates) {
        let dateRange = getDateRange(date);

        // Define an array to store promises for each score range
        let promises = [];

        // Iterate over different score ranges and push corresponding axios requests into promises array
        for (let i = 0; i < 5; i++) {
          let snap = axios.post(
            functionBaseUrl + "/api/elastic/collection/size/filter",
            {
              collection: "analysis",
              query: {
                bool: {
                  must: [{ match: { docType: "analysis" } }],
                  filter: [
                    { range: { createdDate: { gte: String(dateRange.start), lte: String(dateRange.end) } } },
                    { range: { swingConfidenceScore: getScoreRange(i) } }, // Get range based on index
                    analysisParentQuery,
                  ],
                },
              },
            }
          );
          promises.push(snap.then(response => response?.data?.count || 0));
        }

        // Wait for all promises to resolve for this date
        let counts = await Promise.all(promises);

        // Push counts into respective arrays in avgCSData
        for (let i = 0; i < 5; i++) {
          avgCSData[i].push(counts[i]);
        }
      }

      setavgCS(avgCSData);
    }

    if (collection === "confidenceScoreByPercentage") {
      setavgCSP([]);

      let avgCSPData = [[], [], [], [], []]; // Array of arrays to store percentages for different score ranges
      let avgCSPDataTotal = []; // Array to store total counts

      for (const date of dates) {
        let dateRange = getDateRange(date);

        // Define an array to store promises for each score range
        let promises = [];

        // Fetch total count for the date range
        let snapTotal = axios.post(
          functionBaseUrl + "/api/elastic/collection/size/filter",
          {
            collection: "analysis",
            query: {
              bool: {
                must: [{ match: { docType: "analysis" } }],
                filter: [
                  { range: { createdDate: { gte: String(dateRange.start), lte: String(dateRange.end) } } },
                  analysisParentQuery,
                ],
              },
            },
          }
        );
        promises.push(snapTotal.then(response => response?.data?.count || 0));

        // Iterate over different score ranges and push corresponding axios requests into promises array
        for (let i = 0; i < 5; i++) {
          let snap = axios.post(
            functionBaseUrl + "/api/elastic/collection/size/filter",
            {
              collection: "analysis",
              query: {
                bool: {
                  must: [{ match: { docType: "analysis" } }],
                  filter: [
                    { range: { createdDate: { gte: String(dateRange.start), lte: String(dateRange.end) } } },
                    { range: { swingConfidenceScore: getScoreRange(i) } }, // Get range based on index
                    analysisParentQuery,
                  ],
                },
              },
            }
          );
          promises.push(snap.then(response => response?.data?.count || 0));
        }

        // Wait for all promises to resolve for this date
        let counts = await Promise.all(promises);

        // Total count is the first element of counts array
        let total = counts.shift();
        avgCSPDataTotal.push(total);

        // Push percentages into respective arrays in avgCSPData
        for (let i = 0; i < 5; i++) {
          avgCSPData[i].push(Math.round((counts[i] / total) * 100) / 100);
        }
      }

      setavgCSP(avgCSPData);
    }
  };

  function getScoreRange(index) {
    switch (index) {
      case 0:
        return { gte: "0.89", lte: "1" };
      case 1:
        return { gte: "0.8", lt: "0.89" };
      case 2:
        return { gte: "0.7", lt: "0.8" };
      case 3:
        return { gte: "0.6", lt: "0.7" };
      case 4:
        return { gte: "0", lt: "0.6" };
      default:
        return {}; // Handle default case
    }
  }

  const getDateRange = (dateString) => {
    let startDate, endDate;

    if (/^\d{2}-\d{2}-\d{4}$/.test(dateString)) {
      // days format: "MM-DD-YYYY"
      startDate = startOfDay(parse(dateString, "MM-dd-yyyy", new Date()));
      endDate = endOfDay(parse(dateString, "MM-dd-yyyy", new Date()));
    } else if (/^Week\d{1,2}-\d{4}$/.test(dateString)) {
      // weeks format: "Week##-YYYY"
      const [weekString, year] = dateString.split("-");
      const weekNumber = parseInt(weekString.slice(4), 10);
      const isoWeekStart = startOfWeek(
        parse(`${year}-01-01`, "yyyy-MM-dd", new Date()),
        { weekStartsOn: 1 }
      );
      startDate = addWeeks(isoWeekStart, weekNumber);
      endDate = endOfWeek(startDate, { weekStartsOn: 1 });
      endDate = endOfDay(endDate);
    } else if (/^\d{2}-\d{4}$/.test(dateString)) {
      // months format: "MM-YYYY"
      const [month, year] = dateString.split("-");
      startDate = startOfMonth(
        parse(`${year}-${month}-01`, "yyyy-MM-dd", new Date())
      );
      endDate = endOfMonth(startDate);
    } else if (/^\d{4}$/.test(dateString)) {
      // years format: "YYYY"
      const year = dateString;
      startDate = startOfYear(parse(`${year}-01-01`, "yyyy-MM-dd", new Date()));
      endDate = endOfYear(startDate);
    } else {
      throw new Error("Invalid date format");
    }

    return {
      start: startDate.getTime(),
      end: endDate.getTime(),
      timezone: "PST",
    };
  };

  const getAllDates = (startDate, endDate, period) => {
    const dates = [];
    let intervals;

    const start = new Date(startDate * 1);
    const end = new Date(endDate * 1);

    switch (period) {
      case "days":
        intervals = eachDayOfInterval({ start, end });
        dates.push(...intervals.map((date) => format(date, "MM-dd-yyyy")));
        break;
      case "weeks":
        intervals = eachWeekOfInterval({ start, end });
        dates.push(
          ...intervals.map(
            (date) =>
              `Week${getISOWeek(date) <= 9 ? "0" : ""}${getISOWeek(
                date
              )}-${getISOWeekYear(date)}`
          )
        );
        break;
      case "months":
        intervals = eachMonthOfInterval({ start, end });
        dates.push(...intervals.map((date) => format(date, "MM-yyyy")));
        break;
      case "years":
        intervals = eachYearOfInterval({ start, end });
        dates.push(...intervals.map((date) => String(getYear(date))));
        break;
      default:
        return null;
    }

    return dates;
  };

  useEffect(() => {
    if (
      statType === "users" &&
      tempUsers?.length === dates?.length &&
      tempCoaches?.length === dates?.length &&
      tempStudents?.length === dates?.length &&
      tempFreeStudents?.length === dates?.length &&
      tempPaidStudents?.length === dates?.length
    ) {
      let overall = [];

      for (var i = 0; i < tempUsers?.length; i++) {
        let tot = sum(tempUsers, i + 1);
        overall[i] = tot;
      }

      setOptions({
        options: {
          responsive: true,
          plugins: {
            legend: {
              position: "top",
            },
          },
          title: {
            display: true,
            text: "Users",
            fontSize: "20",
          },
        },
      });

      setData({
        labels: dates,
        datasets: [
          {
            label: "Accumulative",
            backgroundColor: "rgb(155, 99, 132, 0.5)",
            borderColor: "rgb(155, 99, 132)",
            data: overall,
            hidden: true,
            //fill: false,
            //lineTension: 0
          },
          {
            label: "Total",
            backgroundColor: "rgb(255, 99, 132, 0.5)",
            borderColor: "rgb(255, 99, 132)",
            data: tempUsers,
            //fill: false,
            //lineTension: 0
          },
          {
            label: "Coaches",
            backgroundColor: "rgb(70, 185, 89, 0.5)",
            borderColor: "rgb(70, 185, 89)",
            data: tempCoaches,
            //fill: false,
            //lineTension: 0
          },
          {
            label: "Students",
            backgroundColor: "rgb(41, 121, 255, 0.5)",
            borderColor: "rgb(41, 121, 255)",
            data: tempStudents,
            //fill: false,
            //lineTension: 0
          },
          {
            label: "Free Plan Students",
            backgroundColor: "rgb(255, 255, 0, 0.5)",
            borderColor: "rgb(255, 255, 0)",
            data: tempFreeStudents,
            //fill: false,
            //lineTension: 0
          },
          {
            label: "Paid Plan Students",
            backgroundColor: "rgb(0, 0, 128, 0.5)",
            borderColor: "rgb(0, 0, 128)",
            data: tempPaidStudents,
            //fill: false,
            //lineTension: 0
          },
        ],
      });
      setLoading(false);
    }

    if (statType === "sessions" && tempSessions?.length === dates?.length) {
      let overall = [];

      for (var i = 0; i < tempSessions?.length; i++) {
        let tot = sum(tempSessions, i + 1);
        overall[i] = tot;
      }

      setOptions({
        options: {
          responsive: true,
          plugins: {
            legend: {
              position: "top",
            },
          },
          title: {
            display: true,
            text: "Sessions",
            fontSize: "20",
          },
        },
      });

      setData({
        labels: dates,
        datasets: [
          {
            label: "Accumulative",
            backgroundColor: "rgb(155, 99, 132, 0.5)",
            borderColor: "rgb(155, 99, 132)",
            data: overall,
            hidden: true,
            //fill: false,
            //lineTension: 0
          },
          {
            label: "Total",
            backgroundColor: "rgb(255, 99, 132, 0.5)",
            borderColor: "rgb(255, 99, 132)",
            data: tempSessions,
            //fill: false,
            //lineTension: 0
          },
        ],
      });
      setLoading(false);
    }

    if (
      statType === "videos" &&
      tempVideos?.length === dates?.length &&
      videosFON?.length === dates?.length &&
      videosDTL?.length === dates?.length
    ) {
      let overall = [];

      for (var i = 0; i < tempVideos?.length; i++) {
        let tot = sum(tempVideos, i + 1);
        overall[i] = tot;
      }

      setOptions({
        options: {
          responsive: true,
          plugins: {
            legend: {
              position: "top",
            },
          },
          title: {
            display: true,
            text: "Videos",
            fontSize: "20",
          },
        },
      });

      setData({
        labels: dates,
        datasets: [
          {
            label: "Accumulative",
            backgroundColor: "rgb(155, 99, 132, 0.5)",
            borderColor: "rgb(155, 99, 132)",
            data: overall,
            hidden: true,
            //fill: false,
            //lineTension: 0
          },
          {
            label: "Total",
            backgroundColor: "rgb(255, 99, 132, 0.5)",
            borderColor: "rgb(255, 99, 132)",
            data: tempVideos,
            //fill: false,
            //lineTension: 0
          },
          {
            label: "FON Total",
            backgroundColor: "rgb(255, 128, 0, 0.5)",
            borderColor: "rgb(255, 128, 0)",
            data: videosFON,
            //fill: false,
            //lineTension: 0
          },
          {
            label: "DTL Total",
            backgroundColor: "rgb(255, 255, 0, 0.5)",
            borderColor: "rgb(255, 255, 0)",
            data: videosDTL,
            //fill: false,
            //lineTension: 0
          },
        ],
      });
      setLoading(false);
    }

    if (
      statType === "issues" &&
      issues?.length === dates?.length &&
      analysis?.length === dates?.length
    ) {
      let issuesVSanalysis = [];

      for (var i = 0; i < issues?.length; i++) {
        issuesVSanalysis[i] = (
          (issues[i] / (analysis[i] + issues[i])) *
          100
        ).toFixed(0);
      }

      setOptions({
        options: {
          responsive: true,
          plugins: {
            legend: {
              position: "top",
            },
          },
          title: {
            display: true,
            text: "Swing And A Miss",
            fontSize: "20",
          },
        },
      });

      setData({
        labels: dates,
        datasets: [
          {
            label: "S&M Total",
            backgroundColor: "rgb(255, 99, 132, 0.5)",
            borderColor: "rgb(255, 99, 132)",
            data: issues,
            //fill: false,
            //lineTension: 0
          },
          {
            label: "Analysis Total",
            backgroundColor: "rgb(70, 185, 89, 0.5)",
            borderColor: "rgb(70, 185, 89)",
            data: analysis,
            //fill: false,
            //lineTension: 0
          },
          {
            label: "S&M FON Total",
            backgroundColor: "rgb(255, 128, 0, 0.5)",
            borderColor: "rgb(255, 128, 0)",
            data: issuesFON,
            //fill: false,
            //lineTension: 0
          },
          {
            label: "S&M DTL Total",
            backgroundColor: "rgb(255, 255, 0, 0.5)",
            borderColor: "rgb(255, 255, 0)",
            data: issuesDTL,
            //fill: false,
            //lineTension: 0
          },
          {
            label: "Analysis FON Total",
            backgroundColor: "rgb(0, 255, 255, 0.5)",
            borderColor: "rgb(0, 255, 255)",
            data: analysisFON,
            //fill: false,
            //lineTension: 0
          },
          {
            label: "Analysis DTL Total",
            backgroundColor: "rgb(255, 0, 255, 0.5)",
            borderColor: "rgb(255, 0, 255)",
            data: analysisDTL,
            //fill: false,
            //lineTension: 0
          },
          {
            label: "S&M vs Analysis (%)",
            backgroundColor: "rgb(52, 170, 235, 0.5)",
            borderColor: "rgb(52, 160, 235)",
            data: issuesVSanalysis,
            //fill: false,
            //lineTension: 0
          },
        ],
      });
      setLoading(false);
    }

    if (
      statType === "analysis" &&
      analysis?.length === dates?.length &&
      issues?.length === dates?.length
    ) {
      let analysisVSissues = [];

      for (var i = 0; i < analysis?.length; i++) {
        analysisVSissues[i] = (
          (analysis[i] / (analysis[i] + issues[i])) *
          100
        ).toFixed(0);
      }

      setOptions({
        options: {
          responsive: true,
          plugins: {
            legend: {
              position: "top",
            },
          },
          title: {
            display: true,
            text: "Analysis",
            fontSize: "20",
          },
        },
      });

      setData({
        labels: dates,
        datasets: [
          {
            label: "Analysis Total",
            backgroundColor: "rgb(255, 99, 132, 0.5)",
            borderColor: "rgb(255, 99, 132)",
            data: analysis,
            //fill: false,
            //lineTension: 0
          },
          {
            label: "S&M Total",
            backgroundColor: "rgb(70, 185, 89, 0.5)",
            borderColor: "rgb(70, 185, 89)",
            data: issues,
            //fill: false,
            //lineTension: 0
          },
          {
            label: "Analysis FON Total",
            backgroundColor: "rgb(255, 128, 0, 0.5)",
            borderColor: "rgb(255, 128, 0)",
            data: analysisFON,
            //fill: false,
            //lineTension: 0
          },
          {
            label: "Analysis DTL Total",
            backgroundColor: "rgb(0, 255, 255, 0.5)",
            borderColor: "rgb(0, 255, 255)",
            data: analysisDTL,
            //fill: false,
            //lineTension: 0
          },
          {
            label: "S&M FON Total",
            backgroundColor: "rgb(255, 255, 0, 0.5)",
            borderColor: "rgb(255, 255, 0)",
            data: issuesFON,
            //fill: false,
            //lineTension: 0
          },
          {
            label: "S&M DTL Total",
            backgroundColor: "rgb(255, 0, 255, 0.5)",
            borderColor: "rgb(255, 0, 255)",
            data: issuesDTL,
            //fill: false,
            //lineTension: 0
          },
          {
            label: "Analysis vs S&M (%)",
            backgroundColor: "rgb(52, 170, 235, 0.5)",
            borderColor: "rgb(52, 160, 235)",
            data: analysisVSissues,
            //fill: false,
            //lineTension: 0
          },
        ],
      });
      setLoading(false);
    }

    if (
      statType === "issuesByPercentage" &&
      issuesPercentage?.length === dates?.length &&
      analysisPercentage?.length === dates?.length
    ) {
      /*let issuesVSanalysis = [];

      for (var i = 0; i < issues?.length; i++) {
        issuesVSanalysis[i] = ((issues[i] / (analysis[i] + issues[i])) * 100).toFixed(0);
      }*/

      setOptions({
        options: {
          responsive: true,
          plugins: {
            legend: {
              position: "top",
            },
          },
          title: {
            display: true,
            text: "Swing And A Miss by %",
            fontSize: "20",
          },
        },
      });

      setData({
        labels: dates,
        datasets: [
          /*{
            label: 'S&M Total',
            backgroundColor: 'rgb(255, 99, 132, 0.5)',
            borderColor: 'rgb(255, 99, 132)',
            data: issues,
            //fill: false,
            //lineTension: 0
          },
          {
            label: 'Analysis Total',
            backgroundColor: 'rgb(70, 185, 89, 0.5)',
            borderColor: 'rgb(70, 185, 89)',
            data: analysis,
            //fill: false,
            //lineTension: 0
          },*/
          {
            label: "S&M FON Total (%)",
            backgroundColor: "rgb(255, 128, 0, 0.5)",
            borderColor: "rgb(255, 128, 0)",
            data: issuesFONPercentage,
            //fill: false,
            //lineTension: 0
          },
          {
            label: "S&M DTL Total (%)",
            backgroundColor: "rgb(255, 255, 0, 0.5)",
            borderColor: "rgb(255, 255, 0)",
            data: issuesDTLPercentage,
            //fill: false,
            //lineTension: 0
          },
          {
            label: "Analysis FON Total (%)",
            backgroundColor: "rgb(0, 255, 255, 0.5)",
            borderColor: "rgb(0, 255, 255)",
            data: analysisFONPercentage,
            //fill: false,
            //lineTension: 0
          },
          {
            label: "Analysis DTL Total (%)",
            backgroundColor: "rgb(255, 0, 255, 0.5)",
            borderColor: "rgb(255, 0, 255)",
            data: analysisDTLPercentage,
            //fill: false,
            //lineTension: 0
          },
          /*{
            label: 'S&M vs Analysis (%)',
            backgroundColor: 'rgb(52, 170, 235, 0.5)',
            borderColor: 'rgb(52, 160, 235)',
            data: issuesVSanalysis,
            //fill: false,
            //lineTension: 0
          },*/
        ],
      });
      setLoading(false);
    }

    if (
      statType === "analysisByPercentage" &&
      analysisPercentage?.length === dates?.length &&
      issuesPercentage?.length === dates?.length
    ) {
      setOptions({
        options: {
          responsive: true,
          plugins: {
            legend: {
              position: "top",
            },
          },
          title: {
            display: true,
            text: "Analysis by %",
            fontSize: "20",
          },
        },
      });

      setData({
        labels: dates,
        datasets: [
          /*{
            label: 'Analysis Total (%)',
            backgroundColor: 'rgb(255, 99, 132, 0.5)',
            borderColor: 'rgb(255, 99, 132)',
            data: analysisPercentage,
            //fill: false,
            //lineTension: 0
          },
          {
            label: 'S&M Total (%)',
            backgroundColor: 'rgb(70, 185, 89, 0.5)',
            borderColor: 'rgb(70, 185, 89)',
            data: issuesPercentage,
            //fill: false,
            //lineTension: 0
          },*/
          {
            label: "Analysis FON Total (%)",
            backgroundColor: "rgb(255, 128, 0, 0.5)",
            borderColor: "rgb(255, 128, 0)",
            data: analysisFONPercentage,
            //fill: false,
            //lineTension: 0
          },
          {
            label: "Analysis DTL Total (%)",
            backgroundColor: "rgb(0, 255, 255, 0.5)",
            borderColor: "rgb(0, 255, 255)",
            data: analysisDTLPercentage,
            //fill: false,
            //lineTension: 0
          },
          {
            label: "S&M FON Total (%)",
            backgroundColor: "rgb(255, 255, 0, 0.5)",
            borderColor: "rgb(255, 255, 0)",
            data: issuesFONPercentage,
            //fill: false,
            //lineTension: 0
          },
          {
            label: "S&M DTL Total (%)",
            backgroundColor: "rgb(255, 0, 255, 0.5)",
            borderColor: "rgb(255, 0, 255)",
            data: issuesDTLPercentage,
            //fill: false,
            //lineTension: 0
          },
          /*{
            label: 'Analysis vs S&M (%)',
            backgroundColor: 'rgb(52, 170, 235, 0.5)',
            borderColor: 'rgb(52, 160, 235)',
            data: analysisVSissues,
            //fill: false,
            //lineTension: 0
          },*/
        ],
      });
      setLoading(false);
    }

    if (
      statType === "confidenceScore" &&
      avgCS?.length > 0 &&
      avgCS[0]?.length === dates?.length
    ) {
      setOptions({
        options: {
          responsive: true,
          plugins: {
            legend: {
              position: "top",
            },
          },
          title: {
            display: true,
            text: "Confidence Score",
            fontSize: "20",
          },
        },
      });

      setData({
        labels: dates,
        datasets: [
          {
            label: "89 - 100",
            backgroundColor: "rgb(255, 99, 132, 0.5)",
            borderColor: "rgb(255, 99, 132)",
            data: avgCS[0],
            //fill: false,
            //lineTension: 0
          },
          {
            label: "80 - 89",
            backgroundColor: "rgb(70, 185, 89, 0.5)",
            borderColor: "rgb(70, 185, 89)",
            data: avgCS[1],
            //fill: false,
            //lineTension: 0
          },
          {
            label: "70-79",
            backgroundColor: "rgb(52, 170, 235, 0.5)",
            borderColor: "rgb(52, 160, 235)",
            data: avgCS[2],
            //fill: false,
            //lineTension: 0
          },
          {
            label: "60 - 69",
            backgroundColor: "rgb(75, 192, 192, 0.5)",
            borderColor: "rgb(75, 192, 192)",
            data: avgCS[3],
            //fill: false,
            //lineTension: 0
          },
          {
            label: "0 - 59",
            backgroundColor: "rgb(255, 240, 0, 0.5)",
            borderColor: "rgb(255, 240, 0)",
            data: avgCS[4],
            //fill: false,
            //lineTension: 0
          },
        ],
      });
      setLoading(false);
    }

    if (
      statType === "confidenceScoreByPercentage" &&
      avgCSP?.length > 0 &&
      avgCSP[0]?.length === dates?.length
    ) {
      setOptions({
        options: {
          responsive: true,
          plugins: {
            legend: {
              position: "top",
            },
          },
          title: {
            display: true,
            text: "Confidence Score",
            fontSize: "20",
          },
        },
      });

      setData({
        labels: dates,
        datasets: [
          {
            label: "89 - 100",
            backgroundColor: "rgb(255, 99, 132, 0.5)",
            borderColor: "rgb(255, 99, 132)",
            data: avgCSP[0],
            //fill: false,
            //lineTension: 0
          },
          {
            label: "80 - 89",
            backgroundColor: "rgb(70, 185, 89, 0.5)",
            borderColor: "rgb(70, 185, 89)",
            data: avgCSP[1],
            //fill: false,
            //lineTension: 0
          },
          {
            label: "70-79",
            backgroundColor: "rgb(52, 170, 235, 0.5)",
            borderColor: "rgb(52, 160, 235)",
            data: avgCSP[2],
            //fill: false,
            //lineTension: 0
          },
          {
            label: "60 - 69",
            backgroundColor: "rgb(75, 192, 192, 0.5)",
            borderColor: "rgb(75, 192, 192)",
            data: avgCSP[3],
            //fill: false,
            //lineTension: 0
          },
          {
            label: "0 - 59",
            backgroundColor: "rgb(255, 240, 0, 0.5)",
            borderColor: "rgb(255, 240, 0)",
            data: avgCSP[4],
            //fill: false,
            //lineTension: 0
          },
        ],
      });
      setLoading(false);
    }

    if (statType === "subscriptions" && subscriptions?.length > 0) {
      let overall = [];
      let total = [];
      let elavon = [];
      let stripe = [];
      let apple = [];
      let android = [];
      let canceled = [];
      let studio = [];

      subscriptions.forEach((sub) => {
        if (sub.startDate) {
          const created = moment(sub.startDate * 1);
          if (
            created >= moment(selectionRange.startDate) &&
            created <= moment(selectionRange.endDate).endOf("day")
          ) {
            let key = created.format("YYYY-MM-DD");
            if (statPeriod === "byWeeks") {
              key = `${created.format("YYYY")}-${zeroPad(created.week(), 2)}`;
            }
            if (statPeriod === "byMonths") {
              key = created.format("YYYY-MM");
            }
            if (statPeriod === "byYears") {
              key = created.format("YYYY");
            }

            const totalIndex = total.findIndex((item) => item.key === key);
            if (totalIndex < 0) {
              total.push({ key: key, count: 1 });
              overall.push({ key: key, count: 1 });
            } else {
              total[totalIndex].count++;
              overall[totalIndex].count++;
            }
            const elavonIndex = elavon.findIndex((item) => item.key === key);
            if (elavonIndex < 0) {
              elavon.push({
                key: key,
                count: sub.platform === "Elavon" ? 1 : 0,
              });
            } else {
              if (sub.platform === "Elavon") {
                elavon[elavonIndex].count++;
              }
            }
            const stripeIndex = stripe.findIndex((item) => item.key === key);
            if (stripeIndex < 0) {
              stripe.push({
                key: key,
                count: sub.platform === "Stripe" ? 1 : 0,
              });
            } else {
              if (sub.platform === "Stripe") {
                stripe[stripeIndex].count++;
              }
            }
            const appleIndex = apple.findIndex((item) => item.key === key);
            if (appleIndex < 0) {
              apple.push({ key: key, count: sub.platform === "Apple" ? 1 : 0 });
            } else {
              if (sub.platform === "Apple") {
                apple[appleIndex].count++;
              }
            }
            const androidIndex = android.findIndex((item) => item.key === key);
            if (androidIndex < 0) {
              android.push({
                key: key,
                count: sub.platform === "Android" ? 1 : 0,
              });
            } else {
              if (sub.platform === "Android") {
                android[androidIndex].count++;
              }
            }
            if (sub.productId.includes("studio_master")) {
              const studioIndex = studio.findIndex(
                (item) => item.key === key
              );
              if (studioIndex < 0) {
                studio.push({
                  key: key,
                  count: sub.platform === "Stripe" ? 1 : 0,
                });
              } else {
                studio[studioIndex].count++;
              }
            }
            if (
              !sub.productId.includes("enterprise_master") ||
              !sub.productId.includes("studio_master") ||
              (sub.productId.includes("enterprise_master") &&
                ![0, 100, 200].includes(parseInt(sub.amount)))
            ) {
              const canceledIndex = canceled.findIndex(
                (item) => item.key === key
              );
              if (canceledIndex < 0) {
                canceled.push({
                  key: key,
                  count: !sub.autoRenewal && sub.transactionId !== "" ? 1 : 0,
                });
              } else {
                if (!sub.autoRenewal && sub.transactionId !== "") {
                  canceled[canceledIndex].count++;
                }
              }
            }
          }
        }
      });

      overall.sort((a, b) => a.key.localeCompare(b.key));
      total.sort((a, b) => a.key.localeCompare(b.key));
      elavon.sort((a, b) => a.key.localeCompare(b.key));
      stripe.sort((a, b) => a.key.localeCompare(b.key));
      apple.sort((a, b) => a.key.localeCompare(b.key));
      android.sort((a, b) => a.key.localeCompare(b.key));
      canceled.sort((a, b) => a.key.localeCompare(b.key));
      studio.sort((a, b) => a.key.localeCompare(b.key));

      if (overall?.length > 0) {
        for (var i = 0; i < overall?.length; i++) {
          let tot = sum(total, i + 1);
          overall[i].count = tot;
        }
      }

      setOptions({
        options: {
          responsive: true,
          plugins: {
            legend: {
              position: "top",
            },
          },
          title: {
            display: true,
            text: "All Subscriptions",
            fontSize: "20",
          },
        },
      });

      setData({
        labels: total.map((item) => item.key),
        datasets: [
          {
            label: "Accumulative",
            backgroundColor: "rgb(155, 99, 132, 0.5)",
            borderColor: "rgb(155, 99, 132)",
            data: overall.map((item) => item.count),
            hidden: true,
            //fill: false,
            //lineTension: 0
          },
          {
            label: "Total",
            backgroundColor: "rgb(255, 99, 132, 0.5)",
            borderColor: "rgb(255, 99, 132)",
            data: total.map((item) => item.count),
            //fill: false,
            //lineTension: 0
          },
          {
            label: "Elavon",
            backgroundColor: "rgb(70, 185, 89, 0.5)",
            borderColor: "rgb(70, 185, 89)",
            data: elavon.map((item) => item.count),
            //fill: false,
            //lineTension: 0
          },
          {
            label: "Stripe",
            backgroundColor: "rgb(255, 153, 51, 0.5)",
            borderColor: "rgb(255, 153, 51)",
            data: stripe.map((item) => item.count),
            //fill: false,
            //lineTension: 0
          },
          {
            label: "Apple",
            backgroundColor: "rgb(41, 121, 255, 0.5)",
            borderColor: "rgb(41, 121, 255)",
            data: apple.map((item) => item.count),
            //fill: false,
            //lineTension: 0
          },
          {
            label: "Android",
            backgroundColor: "rgb(75, 192, 192, 0.5)",
            borderColor: "rgb(75, 192, 192)",
            data: android.map((item) => item.count),
            //fill: false,
            //lineTension: 0
          },
          {
            label: "Studio",
            backgroundColor: "rgb(0, 0, 0, 0.5)",
            borderColor: "rgb(0, 0, 0)",
            data: studio.map((item) => item.count),
            //fill: false,
            //lineTension: 0
          },
          {
            label: "Canceled",
            backgroundColor: "rgb(255, 240, 0, 0.5)",
            borderColor: "rgb(255, 240, 0)",
            data: canceled.map((item) => item.count),
            //fill: false,
            //lineTension: 0
          },
        ],
      });
      setLoading(false);
    }

    if (statType === "recurringSubscriptions" && subscriptions?.length > 0) {
      let overall = [];
      let total = [];
      let elavon = [];
      let stripe = [];
      let apple = [];
      let android = [];
      let staff = [];

      subscriptions.forEach((sub) => {
        if (
          sub.startDate &&
          (sub.autoRenewal ||
            (sub.productId.includes("enterprise_master") && !sub.autoRenewal))
        ) {
          const created = moment(sub.startDate * 1);
          if (
            created >= moment(selectionRange.startDate) &&
            created <= moment(selectionRange.endDate).endOf("day")
          ) {
            let key = created.format("YYYY-MM-DD");
            if (statPeriod === "byWeeks") {
              key = `${created.format("YYYY")}-${zeroPad(created.week(), 2)}`;
            }
            if (statPeriod === "byMonths") {
              key = created.format("YYYY-MM");
            }
            if (statPeriod === "byYears") {
              key = created.format("YYYY");
            }

            if (
              sub.autoRenewal ||
              parseInt(sub.amount) === 0 ||
              (sub.transactionId !== "" &&
                (parseInt(sub.amount) === 100 || parseInt(sub.amount) === 200))
            ) {
              const totalIndex = total.findIndex((item) => item.key === key);
              if (totalIndex < 0) {
                total.push({ key: key, count: 1 });
                overall.push({ key: key, count: 1 });
              } else {
                total[totalIndex].count++;
                overall[totalIndex].count++;
              }
            }
            if (sub.autoRenewal) {
              const elavonIndex = elavon.findIndex((item) => item.key === key);
              if (elavonIndex < 0) {
                elavon.push({
                  key: key,
                  count: sub.platform === "Elavon" ? 1 : 0,
                });
              } else {
                if (sub.platform === "Elavon") {
                  elavon[elavonIndex].count++;
                }
              }
              const stripeIndex = stripe.findIndex((item) => item.key === key);
              if (stripeIndex < 0) {
                stripe.push({
                  key: key,
                  count: sub.platform === "Stripe" ? 1 : 0,
                });
              } else {
                if (sub.platform === "Stripe") {
                  stripe[stripeIndex].count++;
                }
              }
            }
            const appleIndex = apple.findIndex((item) => item.key === key);
            if (appleIndex < 0) {
              apple.push({ key: key, count: sub.platform === "Apple" ? 1 : 0 });
            } else {
              if (sub.platform === "Apple") {
                apple[appleIndex].count++;
              }
            }
            const androidIndex = android.findIndex((item) => item.key === key);
            if (androidIndex < 0) {
              android.push({
                key: key,
                count: sub.platform === "Android" ? 1 : 0,
              });
            } else {
              if (sub.platform === "Android") {
                android[androidIndex].count++;
              }
            }
            const staffIndex = staff.findIndex((item) => item.key === key);
            if (staffIndex < 0) {
              staff.push({
                key: key,
                count:
                  sub.productId.includes("enterprise_master") &&
                  !sub.autoRenewal &&
                  (parseInt(sub.amount) === 0 ||
                    (sub.transactionId !== "" &&
                      (parseInt(sub.amount) === 100 ||
                        parseInt(sub.amount) === 200)))
                    ? 1
                    : 0,
              });
            } else {
              if (
                sub.productId.includes("enterprise_master") &&
                !sub.autoRenewal &&
                (parseInt(sub.amount) === 0 ||
                  (sub.transactionId !== "" &&
                    (parseInt(sub.amount) === 100 ||
                      parseInt(sub.amount) === 200)))
              ) {
                staff[staffIndex].count++;
              }
            }
          }
        }
      });

      overall.sort((a, b) => a.key.localeCompare(b.key));
      total.sort((a, b) => a.key.localeCompare(b.key));
      elavon.sort((a, b) => a.key.localeCompare(b.key));
      stripe.sort((a, b) => a.key.localeCompare(b.key));
      apple.sort((a, b) => a.key.localeCompare(b.key));
      android.sort((a, b) => a.key.localeCompare(b.key));
      staff.sort((a, b) => a.key.localeCompare(b.key));

      if (overall?.length > 0) {
        for (var i = 0; i < overall?.length; i++) {
          let tot = sum(total, i + 1);
          overall[i].count = tot;
        }
      }

      setOptions({
        options: {
          responsive: true,
          plugins: {
            legend: {
              position: "top",
            },
          },
          title: {
            display: true,
            text: "Recurring Subscriptions",
            fontSize: "20",
          },
        },
      });

      setData({
        labels: total.map((item) => item.key),
        datasets: [
          {
            label: "Accumulative",
            backgroundColor: "rgb(155, 99, 132, 0.5)",
            borderColor: "rgb(155, 99, 132)",
            data: overall.map((item) => item.count),
            hidden: true,
            //fill: false,
            //lineTension: 0
          },
          {
            label: "Total",
            backgroundColor: "rgb(255, 99, 132, 0.5)",
            borderColor: "rgb(255, 99, 132)",
            data: total.map((item) => item.count),
            //fill: false,
            //lineTension: 0
          },
          {
            label: "Elavon",
            backgroundColor: "rgb(70, 185, 89, 0.5)",
            borderColor: "rgb(70, 185, 89)",
            data: elavon.map((item) => item.count),
            //fill: false,
            //lineTension: 0
          },
          {
            label: "Stripe",
            backgroundColor: "rgb(255, 153, 51, 0.5)",
            borderColor: "rgb(255, 153, 51)",
            data: stripe.map((item) => item.count),
            //fill: false,
            //lineTension: 0
          },
          {
            label: "Apple",
            backgroundColor: "rgb(41, 121, 255, 0.5)",
            borderColor: "rgb(41, 121, 255)",
            data: apple.map((item) => item.count),
            //fill: false,
            //lineTension: 0
          },
          {
            label: "Android",
            backgroundColor: "rgb(75, 192, 192, 0.5)",
            borderColor: "rgb(75, 192, 192)",
            data: android.map((item) => item.count),
            //fill: false,
            //lineTension: 0
          },
          {
            label: "Staff",
            backgroundColor: "rgb(175, 192, 192, 0.5)",
            borderColor: "rgb(175, 192, 192)",
            data: staff.map((item) => item.count),
            //fill: false,
            //lineTension: 0
          },
        ],
      });
      setLoading(false);
    }

    if (
      statType === "canceledSubscriptions" &&
      canceledSubscriptions?.length > 0
    ) {
      let overall = [];
      let total = [];
      let elavon = [];
      let stripe = [];
      let apple = [];
      let android = [];

      canceledSubscriptions.forEach((sub) => {
        if (sub.startDate && !sub.autoRenewal && sub.transactionId !== "") {
          const created = moment(sub.startDate * 1);
          if (
            created >= moment(selectionRange.startDate) &&
            created <= moment(selectionRange.endDate).endOf("day")
          ) {
            let key = created.format("YYYY-MM-DD");
            if (statPeriod === "byWeeks") {
              key = `${created.format("YYYY")}-${zeroPad(created.week(), 2)}`;
            }
            if (statPeriod === "byMonths") {
              key = created.format("YYYY-MM");
            }
            if (statPeriod === "byYears") {
              key = created.format("YYYY");
            }

            if (
              !sub.productId.includes("enterprise_master") ||
              (sub.productId.includes("enterprise_master") &&
                ![0, 100, 200].includes(parseInt(sub.amount)))
            ) {
              const totalIndex = total.findIndex((item) => item.key === key);
              if (totalIndex < 0) {
                total.push({ key: key, count: 1 });
                overall.push({ key: key, count: 1 });
              } else {
                total[totalIndex].count++;
                overall[totalIndex].count++;
              }
              const elavonIndex = elavon.findIndex((item) => item.key === key);
              if (elavonIndex < 0) {
                elavon.push({
                  key: key,
                  count: sub.platform === "Elavon" ? 1 : 0,
                });
              } else {
                if (sub.platform === "Elavon") {
                  elavon[elavonIndex].count++;
                }
              }
              const stripeIndex = stripe.findIndex((item) => item.key === key);
              if (stripeIndex < 0) {
                stripe.push({
                  key: key,
                  count: sub.platform === "Stripe" ? 1 : 0,
                });
              } else {
                if (sub.platform === "Stripe") {
                  stripe[stripeIndex].count++;
                }
              }
              const appleIndex = apple.findIndex((item) => item.key === key);
              if (appleIndex < 0) {
                apple.push({
                  key: key,
                  count: sub.platform === "Apple" ? 1 : 0,
                });
              } else {
                if (sub.platform === "Apple") {
                  apple[appleIndex].count++;
                }
              }
              const androidIndex = android.findIndex(
                (item) => item.key === key
              );
              if (androidIndex < 0) {
                android.push({
                  key: key,
                  count: sub.platform === "Android" ? 1 : 0,
                });
              } else {
                if (sub.platform === "Android") {
                  android[androidIndex].count++;
                }
              }
            }
          }
        }
      });

      overall.sort((a, b) => a.key.localeCompare(b.key));
      total.sort((a, b) => a.key.localeCompare(b.key));
      elavon.sort((a, b) => a.key.localeCompare(b.key));
      stripe.sort((a, b) => a.key.localeCompare(b.key));
      apple.sort((a, b) => a.key.localeCompare(b.key));
      android.sort((a, b) => a.key.localeCompare(b.key));

      if (overall?.length > 0) {
        for (var i = 0; i < overall?.length; i++) {
          let tot = sum(total, i + 1);
          overall[i].count = tot;
        }
      }

      setOptions({
        options: {
          responsive: true,
          plugins: {
            legend: {
              position: "top",
            },
          },
          title: {
            display: true,
            text: "Canceled Subscriptions",
            fontSize: "20",
          },
        },
      });

      setData({
        labels: total.map((item) => item.key),
        datasets: [
          {
            label: "Accumulative",
            backgroundColor: "rgb(155, 99, 132, 0.5)",
            borderColor: "rgb(155, 99, 132)",
            data: overall.map((item) => item.count),
            hidden: true,
            //fill: false,
            //lineTension: 0
          },
          {
            label: "Total",
            backgroundColor: "rgb(255, 99, 132, 0.5)",
            borderColor: "rgb(255, 99, 132)",
            data: total.map((item) => item.count),
            //fill: false,
            //lineTension: 0
          },
          {
            label: "Elavon",
            backgroundColor: "rgb(70, 185, 89, 0.5)",
            borderColor: "rgb(70, 185, 89)",
            data: elavon.map((item) => item.count),
            //fill: false,
            //lineTension: 0
          },
          {
            label: "Stripe",
            backgroundColor: "rgb(255, 153, 51, 0.5)",
            borderColor: "rgb(255, 153, 51)",
            data: stripe.map((item) => item.count),
            //fill: false,
            //lineTension: 0
          },
          {
            label: "Apple",
            backgroundColor: "rgb(41, 121, 255, 0.5)",
            borderColor: "rgb(41, 121, 255)",
            data: apple.map((item) => item.count),
            //fill: false,
            //lineTension: 0
          },
          {
            label: "Android",
            backgroundColor: "rgb(75, 192, 192, 0.5)",
            borderColor: "rgb(75, 192, 192)",
            data: android.map((item) => item.count),
            //fill: false,
            //lineTension: 0
          },
        ],
      });
      setLoading(false);
    }

    if (statType === "screenRecordings" && screenRecordings?.length === dates?.length) {
      let overall = [];

      for (var i = 0; i < screenRecordings?.length; i++) {
        let tot = sum(screenRecordings, i + 1);
        overall[i] = tot;
      }

      setOptions({
        options: {
          responsive: true,
          plugins: {
            legend: {
              position: "top",
            },
          },
          title: {
            display: true,
            text: "Screen Recordings",
            fontSize: "20",
          },
        },
      });

      setData({
        labels: dates,
        datasets: [
          {
            label: "Accumulative",
            backgroundColor: "rgb(155, 99, 132, 0.5)",
            borderColor: "rgb(155, 99, 132)",
            data: overall,
            hidden: true,
            //fill: false,
            //lineTension: 0
          },
          {
            label: "Total",
            backgroundColor: "rgb(255, 99, 132, 0.5)",
            borderColor: "rgb(255, 99, 132)",
            data: screenRecordings,
            //fill: false,
            //lineTension: 0
          },
        ],
      });
      setLoading(false);
    }
  }, [
    statType,
    statPeriod,
    selectionRange,
    users,
    sessions,
    videos,
    subscriptions,
    recurringSubscriptions,
    canceledSubscriptions,
    issues,
    analysis,
    staffAccounts,
    tempUsers,
    tempCoaches,
    tempStudents,
    tempFreeStudents,
    tempPaidStudents,
    tempSessions,
    tempVideos,
    avgCS,
    avgCSP,
    videosFON,
    videosDTL,
    issuesFON,
    issuesDTL,
    analysisFON,
    analysisDTL,
    issuesPercentage,
    issuesDTLPercentage,
    issuesFONPercentage,
    analysisPercentage,
    analysisDTLPercentage,
    analysisFONPercentage,
    screenRecordings,
  ]);

  const staticRanges = [
    ...defaultStaticRanges,
    ...createStaticRanges([
      {
        label: 'Last 4 Weeks',
        range: () => ({
          startDate: moment().subtract(4, 'weeks').startOf('week').toDate(),
          endDate: new Date()
        })
      },
      {
        label: 'Last 3 Months',
        range: () => ({
          startDate: moment().subtract(3, 'months').startOf('month').toDate(),
          endDate: new Date()
        })
      },
      {
        label: 'Last Year',
        range: () => ({
          startDate: moment().subtract(1, 'year').startOf('year').toDate(),
          endDate: moment().subtract(1, 'year').endOf('year').toDate()
        })
      },
      {
        label: 'This Year',
        range: () => ({
          startDate: moment().startOf('year').toDate(),
          endDate: new Date()
        })
      },
      {
        label: 'Last Quarter',
        range: () => ({
          startDate: moment().subtract(1, 'quarter').startOf('quarter').toDate(),
          endDate: moment().subtract(1, 'quarter').endOf('quarter').toDate()
        })
      },
      {
        label: 'This Quarter',
        range: () => ({
          startDate: moment().startOf('quarter').toDate(),
          endDate: new Date()
        })
      },
      {
        label: 'All Time',
        range: () => ({
          startDate: new Date(2021, 0, 1),
          endDate: new Date()
        })
      },
    ])
  ];

  return (
    <>
      <Container component="main" style={{ padding: "20px 0" }}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <FormControl fullWidth variant="outlined">
              <InputLabel id="stat-type-select">Statistics</InputLabel>
              <Select
                label="Statistics"
                labelId="stat-type-select"
                onChange={handleStatType}
                value={statType}
              >
                <MenuItem value={"users"}>Users</MenuItem>
                <MenuItem value={"sessions"}>Sessions</MenuItem>
                <MenuItem value={"videos"}>Videos</MenuItem>
                <MenuItem value={"subscriptions"}>All Subscriptions</MenuItem>
                <MenuItem value={"recurringSubscriptions"}>
                  Recurring Subscriptions
                </MenuItem>
                <MenuItem value={"canceledSubscriptions"}>
                  Canceled Subscriptions
                </MenuItem>
                <MenuItem value={"issues"}>Swing And A Miss</MenuItem>
                <MenuItem value={"issuesByPercentage"}>
                  Swing And A Miss by %
                </MenuItem>
                <MenuItem value={"analysis"}>Analysis</MenuItem>
                <MenuItem value={"analysisByPercentage"}>
                  Analysis by %
                </MenuItem>
                <MenuItem value={"confidenceScore"}>Confidence Score</MenuItem>
                <MenuItem value={"confidenceScoreByPercentage"}>
                  Confidence Score by %
                </MenuItem>
                <MenuItem value={"screenRecordings"}>Screen Recordings</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth variant="outlined">
              <InputLabel id="stat-period-select">Period</InputLabel>
              <Select
                label="Period"
                labelId="stat-period-select"
                onChange={handleStatPeriod}
                value={statPeriod}
              >
                <MenuItem value={"byDays"}>By Days</MenuItem>
                <MenuItem value={"byWeeks"}>By Weeks</MenuItem>
                <MenuItem value={"byMonths"}>By Months</MenuItem>
                <MenuItem value={"byYears"}>By Years</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <div style={{ textAlign: "right" }}>
              <IconButton onClick={() => {
                setDefaultRange(true);
                setSelectionRange({
                  startDate: new Date(Date.now() - 14 * 24 * 60 * 60 * 1000).setHours(0, 0, 0),
                  endDate: new Date().setHours(23, 59, 59),
                  key: "range1",
                });
                getDataWithRange(statType, {
                  startDate: new Date(Date.now() - 14 * 24 * 60 * 60 * 1000).setHours(0, 0, 0),
                  endDate: new Date().setHours(23, 59, 59),
                  key: "range1",
                }, statPeriod);
              }}>
                <ClearIcon />
              </IconButton>
              From {moment(selectionRange.startDate).format("MM/DD/YYYY")} to{" "}
              {moment(selectionRange.endDate).format("MM/DD/YYYY")}
              <IconButton onClick={() => setOpenRangeDialog(true)}>
                <DateRange />
              </IconButton>
            </div>
            <Dialog fullWidth open={openRangeDialog}>
              <DialogContent>
                <DateRangePicker
                  retainEndDateOnFirstSelection={true}
                  ranges={[selectionRange]}
                  inputRanges={[]}
                  staticRanges={staticRanges}
                  onChange={(ranges) => {
                    setDefaultRange(false);
                    setSelectionRange(ranges.range1);
                    getDataWithRange(statType, ranges.range1, statPeriod);
                  }}
                />
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => setOpenRangeDialog(false)}
                  color="primary"
                >
                  OK
                </Button>
              </DialogActions>
            </Dialog>
          </Grid>
          <Grid item xs={12}>
            {!loading ? (
              <Bar
                options={{
                  title: {
                    display: true,
                    text:
                      statType
                        .replace(/([A-Z])/g, " $1")
                        .charAt(0)
                        .toUpperCase() +
                      statType.replace(/([A-Z])/g, " $1").slice(1),
                    fontSize: "20",
                  },
                  scales: {
                    yAxes: [
                      {
                        ticks: {
                          beginAtZero: true,
                        },
                      },
                    ],
                  },
                }}
                data={data}
              />
            ) : (
              <Grid
                style={{
                  margin: "auto",
                  padding: 100,
                  display: loading ? "block" : "none",
                }}
              >
                <CircularProgress />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export const SummaryCharts = SummaryChartsComponent;
