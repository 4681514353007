import React, { useState } from "react";
import {
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Checkbox,
  FormControlLabel,
  Link,
  Button,
  CircularProgress,
  Container,
  TextField,
  Grid,
  Typography,
  Select,
  MenuItem,
  RadioGroup,
  Radio,
  FormLabel,
} from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Swal from "sweetalert2";
import axios from "axios";
import {
  defaultHeightToDefaultHipWidth,
  femaleHeightToDefaultHipWidth,
  heightValues,
  maleHeightToDefaultHipWidth,
} from "../students/misc/hipWidthTable";
import "./offer.css";
import firebase, {
  functionBaseUrl,
  axiosWithToken,
} from "../../common/firebase";
import { listOfSubscriptions } from "../../common/envConfig";
import { useHistory } from "react-router-dom";

function SignUpComponent({ finishSignUp }) {
  const history = useHistory();
  const [userFirstName, setUserFirstName] = useState("");
  const [userLastName, setUserLastName] = useState("");
  const [email, setEmail] = useState("");
  //const [dateOfBirth, setDateOfBirth] = useState('');
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [tosAgree, setTosAgree] = useState(false);
  const [dominantHand, setDominantHand] = useState("Right");
  const [height, setHeight] = useState("");
  const [gender, setGender] = useState("MALE");
  const [hipWidth, setHipWidth] = useState("7.1");
  const [isLoading, setIsLoading] = useState(false);

  const user = firebase.auth().currentUser;
  if (user && localStorage.getItem("showAlert") === "true") {
    axiosWithToken(`${functionBaseUrl}/api/users/${user.uid}`, {
      method: 'GET',
    }).then((userResponse) => {
      if (!userResponse?.data?.subscriptionType?.includes('student')) {
        Swal.fire({
          title: '<p style="font-size:70%;">This offer is only applicable to Player plans.</p>',
          icon: "warning",
          showCancelButton: false,
          confirmButtonText: "Ok",
          allowOutsideClick: false,
        })
        return
      }
      if (userResponse?.data?.coaches?.includes('kWql3RYG9Ee6UGMqatr0UNs9ma93') || userResponse?.data?.coaches?.includes('MO74kJpfh6NYvgaEvMgQ1zmZBJW2')) {
        Swal.fire({
          title: '<p style="font-size:70%;">This offer is only applicable to new students, you are an existing student of the coach.</p>',
          icon: "warning",
          showCancelButton: false,
          confirmButtonText: "Ok",
          allowOutsideClick: false,
        })
        return
      }
      
      Swal.fire({
        title: '<p style="font-size:70%;">Would you like to accept this offer?</p>' +
        '<p style="font-size:50%;">You are logged in as <b>' + user.email + '</b></p>',
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        allowOutsideClick: false,
      }).then(async (result) => {
        if (result.isConfirmed) {
          await axiosWithToken(functionBaseUrl + "/api/userCoach", {
            method: "POST",
            data: {
              userEmail: user.email.toLowerCase().replace(/ /g, ""),
              coachEmail: window.location.href.includes("/ccgolf") ? "coachcarolingolf@gmail.com" : "sportsbox.coach@gmail.com",
            },
          }).catch((err) => {
            console.log(err);
          });

          await axiosWithToken(functionBaseUrl + "/api/users/" + user.uid, {
            method: "PATCH",
            data: {
              userInfo: window.location.href.includes("/ccgolf") ? 
                {
                  ccGolf: {
                    newUser: false,
                    date: String(Date.now())
                  }
                } :
                (window.location.href.includes("/dollar-driver-club") ? {
                  dollarDriverClub: {
                    newUser: false,
                    date: String(Date.now())
                  }
                } :
                (window.location.href.includes("/golfpad") ? {
                  golfpad: {
                    newUser: false,
                    date: String(Date.now())
                  },
                  subscriptionType: listOfSubscriptions.STUDENT_LITE_MONTHLY
                } :
                {
                  swingAnalysisOffer: {
                    newUser: false,
                    date: String(Date.now())
                  }
                }))
            }
          }).catch((err) => {
            console.log(err);
          });

          if (window.location.href.includes("/invitational") || window.location.href.includes("/metagolfclub")) {
            await axiosWithToken(functionBaseUrl + '/api/addSub', {
              method: 'POST',
              data: {
                email: user.email.toLowerCase().replace(/ /g, ''),
                plan: listOfSubscriptions.STUDENT_LITE_MONTHLY,
                months: 2,
                bootcampUser: false,
                subLabel: "invitational",
              }
            })
              .catch(err => {
                console.log(err)
              });
          }

          if (window.location.href.includes("/metagolfclub")) {
            await axiosWithToken(functionBaseUrl + '/api/addSub', {
              method: 'POST',
              data: {
                email: user.email.toLowerCase().replace(/ /g, ''),
                plan: listOfSubscriptions.STUDENT_LITE_MONTHLY,
                months: 1,
                bootcampUser: false,
                subLabel: "meta",
              }
            })
              .catch(err => {
                console.log(err)
              });
          }

          if (window.location.href.includes("/golfpad")) {
            await axiosWithToken(functionBaseUrl + '/api/addSub', {
              method: 'POST',
              data: {
                email: user.email.toLowerCase().replace(/ /g, ''),
                plan: listOfSubscriptions.STUDENT_LITE_MONTHLY,
                months: 1,
                bootcampUser: false,
                subLabel: "golfpad",
              }
            })
              .catch(err => {
                console.log(err)
              });

            await axiosWithToken(functionBaseUrl + "/api/users/" + user.uid, {
              method: "PATCH",
              data: {
                userInfo: {
                  subscriptionType: listOfSubscriptions.STUDENT_LITE_MONTHLY
                }
              }
            })
              .catch((err) => {
                console.log(err);
              });

            const analytics = firebase?.analytics;
            if (typeof analytics === "function") {
              analytics().logEvent("golf_pad", { "step": "complete" });
            }
          }

          if (window.location.href.includes("/swing-analysis-offer")) {
            const analytics = firebase?.analytics;
            if (typeof analytics === "function") {
              analytics().logEvent("swing_analysis_offer", { "step": "complete" });
            }
          }

          if (window.location.href.includes("/dollar-driver-club")) {
            await axiosWithToken(functionBaseUrl + '/api/addSub', {
              method: 'POST',
              data: {
                email: user.email.toLowerCase().replace(/ /g, ''),
                plan: listOfSubscriptions.STUDENT_LITE_MONTHLY,
                months: 1,
                bootcampUser: false,
                subLabel: "dollarDriverClub",
              }
            })
              .catch(err => {
                console.log(err)
              });

            const analytics = firebase?.analytics;
            if (typeof analytics === "function") {
              analytics().logEvent("dollar_driver_club", { "step": "complete" });
            }
          }

          /*if (window.location.href.includes("/ccgolf")) {
            let userResponse = await axiosWithToken(`${functionBaseUrl}/api/users/MO74kJpfh6NYvgaEvMgQ1zmZBJW2`, {
              method: 'GET',
            });
            if (userResponse?.data?.creditCount > 0) {
              await axiosWithToken(`${functionBaseUrl}/api/v2/upgradeStudent`, {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                data: {
                  id: user.uid,
                  coachId: "MO74kJpfh6NYvgaEvMgQ1zmZBJW2",
                  coachName: "Carolin Pinegger",
                  subPlan: listOfSubscriptions.STUDENT_LITE_MONTHLY,
                  subPeriod: 1,
                  autoRenewal: true,
                },
              }).catch((err) => {
                console.log(err);
              })
            }
          }*/

          setIsLoading(false);
          localStorage.removeItem("showAlert");
          finishSignUp(user.uid, user.email.toLowerCase().replace(/ /g, ""));
        }
      })
      localStorage.removeItem("showAlert");
    })
  }

  const checkUserAcc = async () => {
    try {
      const providers = await firebase
        .auth()
        .fetchSignInMethodsForEmail(email.toLowerCase().replace(/ /g, ""));
      if (providers.length > 0) {
        await axios(functionBaseUrl + "/api/userInAuth", {
          method: "POST",
          data: {
            email: email.toLowerCase().replace(/ /g, ""),
          },
        }).catch((err) => {
          console.log(err);
        });

        return true;
      }
      return providers.length === 0;
    } catch (err) {
      console.log(err);
    }
  };

  const handleChange = (prop) => (event) => {
    if (prop === "height") {
      setHeight(event.target.value);
      let result = 7.1;
      if (event.target.value !== "" && gender !== "") {
        switch (gender) {
          case "MALE":
            result = maleHeightToDefaultHipWidth[event.target.value];
            break;
          case "FEMALE":
            result = femaleHeightToDefaultHipWidth[event.target.value];
            break;
          default:
            result = defaultHeightToDefaultHipWidth[event.target.value];
            break;
        }
        setHipWidth(result);
      }
    }

    if (prop === "gender") {
      setGender(event.target.value);
      let result = 7.1;
      if (event.target.value !== "" && height !== "") {
        switch (event.target.value) {
          case "MALE":
            result = maleHeightToDefaultHipWidth[height];
            break;
          case "FEMALE":
            result = femaleHeightToDefaultHipWidth[height];
            break;
          default:
            result = defaultHeightToDefaultHipWidth[height];
            break;
        }
        setHipWidth(result);
      }
    }
  };

  const validatePassword = (password) => {
    const verify = /^(?=.*\d)(?=.*[.!@#$%^&*])(?=.*[a-z]).{8,}$/;
    return verify.test(password);
  };

  const validateEmail = (email) => {
    const verify = /\S+@\S+\.\S+/;
    return verify.test(email);
  };

  const dobFormat = (dob) => {
    let [year, month, day] = dob.split("-");
    return month + "/" + day + "/" + year;
  };

  const handleSignUp = async () => {
    if (!userFirstName) {
      Swal.fire({
        title: '<p style="font-size:70%;">Please enter your First Name</p>',
        icon: "warning",
        confirmButtonText: "Ok",
        allowOutsideClick: true,
      });
      return;
    }
    if (!userLastName) {
      Swal.fire({
        title: '<p style="font-size:70%;">Please enter your Last Name</p>',
        icon: "warning",
        confirmButtonText: "Ok",
        allowOutsideClick: true,
      });
      return;
    }
    if (!email) {
      Swal.fire({
        title: '<p style="font-size:70%;">Please enter your Email</p>',
        icon: "warning",
        confirmButtonText: "Ok",
        allowOutsideClick: true,
      });
      return;
    }
    if (!validateEmail(email)) {
      Swal.fire({
        title: '<p style="font-size:70%;">Please enter a valid Email</p>',
        icon: "warning",
        confirmButtonText: "Ok",
        allowOutsideClick: true,
      });
      return;
    }
    /*if (!dateOfBirth) {
      Swal.fire({
        title: '<p style="font-size:70%;">Please enter your Date of Birth</p>',
        icon: 'warning',
        confirmButtonText: 'Ok',
        allowOutsideClick: true
      })
      return;
    }*/
    if (!password) {
      Swal.fire({
        title: '<p style="font-size:70%;">Please enter Password</p>',
        icon: "warning",
        confirmButtonText: "Ok",
        allowOutsideClick: true,
      });
      return;
    }
    if (!validatePassword(password)) {
      Swal.fire({
        title: '<p style="font-size:70%;">Password must contain:</p>',
        html: `
        <ul>
          <li>• At least 8 characters</li>
          <li>• At least one number</li>
          <li>• A special character (e.g., !@#$%&*)</li>
        </ul>
        `,
        icon: "warning",
        confirmButtonText: "Ok",
        allowOutsideClick: true,
      });
      return;
    }
    if (!confirmPassword) {
      Swal.fire({
        title:
          '<p style="font-size:70%;">Please enter Password Confirmation</p>',
        icon: "warning",
        confirmButtonText: "Ok",
        allowOutsideClick: true,
      });
      return;
    }
    if (password !== confirmPassword) {
      Swal.fire({
        title: '<p style="font-size:70%;">Passwords do not match</p>',
        icon: "warning",
        confirmButtonText: "Ok",
        allowOutsideClick: true,
      });
      return;
    }
    if (!height) {
      Swal.fire({
        title: '<p style="font-size:70%;">Please enter Height</p>',
        icon: "warning",
        confirmButtonText: "Ok",
        allowOutsideClick: true,
      });
      return;
    }
    setIsLoading(true);
    const noUsers = await checkUserAcc();
    if (noUsers) {
      localStorage.removeItem("showAlert");
      await firebase
        .auth()
        .createUserWithEmailAndPassword(
          email.toLowerCase().replace(/ /g, ""),
          password
        )
        .then(async (creds) => {
          await firebase
            .auth()
            .signInWithEmailAndPassword(
              email.toLowerCase().replace(/ /g, ""),
              password
            )
            .catch((err) => {
              console.log(err);
            });

          await axiosWithToken(functionBaseUrl + "/api/userEmailVerf", {
            method: "PATCH",
            data: {
              id: creds.user.uid,
              status: true,
            },
          });

          await axios(functionBaseUrl + "/api/userMobile", {
            method: "POST",
            data: {
              uid: creds.user.uid,
              firstName: userFirstName.replace(/ /g, ""),
              lastName: userLastName.replace(/ /g, ""),
              userName:
                userFirstName.replace(/ /g, "") +
                " " +
                userLastName.replace(/ /g, ""),
              email: email.toLowerCase().replace(/ /g, ""),
              averageScore: 0,
              goals: [],
              dob: "",
              height: height,
              hipSize: hipWidth,
              gender: gender,
              dominantHand: dominantHand,
              picture: "",
              role: "",
              weeklyPractices: 0,
              coachAddress: { city: "", state: "", country: "" },
              coachFacility: [
                {
                  name: "",
                  address: "",
                  students: 0,
                  lessons: 0,
                  environment: [],
                },
              ],
              certification: [],
              userConsent: true,
              subscriptionType: listOfSubscriptions.STUDENT_FREE,
            },
          }).catch((err) => {
            console.log(err);
          });

          await axiosWithToken(functionBaseUrl + "/api/userCoach", {
            method: "POST",
            data: {
              userEmail: email.toLowerCase().replace(/ /g, ""),
              coachEmail: window.location.href.includes("/ccgolf") ? "coachcarolingolf@gmail.com" : "sportsbox.coach@gmail.com",
            },
          }).catch((err) => {
            console.log(err);
          });

          await axiosWithToken(functionBaseUrl + "/api/users/" + creds.user.uid, {
            method: "PATCH",
            data: {
              userInfo: window.location.href.includes("/ccgolf") ? 
                {
                  ccGolf: {
                    newUser: true,
                    date: String(Date.now())
                  }
                } :
                (window.location.href.includes("/dollar-driver-club") ? {
                  dollarDriverClub: {
                    newUser: true,
                    date: String(Date.now())
                  }
                } :
                (window.location.href.includes("/golfpad") ? {
                  golfpad: {
                    newUser: true,
                    date: String(Date.now())
                  },
                  subscriptionType: listOfSubscriptions.STUDENT_LITE_MONTHLY
                } :
                {
                  swingAnalysisOffer: {
                    newUser: true,
                    date: String(Date.now())
                  }
                }))
            }
          }).catch((err) => {
            console.log(err);
          });

          if (window.location.href.includes("/invitational")) {
            await axiosWithToken(functionBaseUrl + '/api/addSub', {
              method: 'POST',
              data: {
                email: email.toLowerCase().replace(/ /g, ''),
                plan: listOfSubscriptions.STUDENT_LITE_MONTHLY,
                months: 2,
                bootcampUser: false,
                subLabel: "invitational",
              }
            })
              .catch(err => {
                console.log(err)
              });
          }

          if (window.location.href.includes("/swing-analysis-offer")) {
            const analytics = firebase?.analytics;
            if (typeof analytics === "function") {
              analytics().logEvent("swing_analysis_offer", { "step": "complete" });
            }
          }

          if (window.location.href.includes("/dollar-driver-club")) {
            await axiosWithToken(functionBaseUrl + '/api/addSub', {
              method: 'POST',
              data: {
                email: email.toLowerCase().replace(/ /g, ''),
                plan: listOfSubscriptions.STUDENT_LITE_MONTHLY,
                months: 1,
                bootcampUser: false,
                subLabel: "dollarDriverClub",
              }
            })
              .catch(err => {
                console.log(err)
              });

            const analytics = firebase?.analytics;
            if (typeof analytics === "function") {
              analytics().logEvent("dollar_driver_club", { "step": "complete" });
            }
          }

          if (window.location.href.includes("/golfpad")) {
            await axiosWithToken(functionBaseUrl + '/api/addSub', {
              method: 'POST',
              data: {
                email: email.toLowerCase().replace(/ /g, ''),
                plan: listOfSubscriptions.STUDENT_LITE_MONTHLY,
                months: 1,
                bootcampUser: false,
                subLabel: "golfpad",
              }
            })
              .catch(err => {
                console.log(err)
              });

              const analytics = firebase?.analytics;
              if (typeof analytics === "function") {
                analytics().logEvent("golf_pad", { "step": "complete" });
              }

              await axiosWithToken(functionBaseUrl + "/api/users/" + creds.user.uid, {
                method: "PATCH",
                data: {
                  userInfo: {
                    subscriptionType: listOfSubscriptions.STUDENT_LITE_MONTHLY
                  }
                }
              })
                .catch((err) => {
                  console.log(err);
                });
          }

          if (window.location.href.includes("/metagolfclub")) {
            await axiosWithToken(functionBaseUrl + '/api/addSub', {
              method: 'POST',
              data: {
                email: email.toLowerCase().replace(/ /g, ''),
                plan: listOfSubscriptions.STUDENT_LITE_MONTHLY,
                months: 1,
                bootcampUser: false,
                subLabel: "meta",
              }
            })
              .catch(err => {
                console.log(err)
              });

            const analytics = firebase?.analytics;
            if (typeof analytics === "function") {
              analytics().logEvent("dollar_driver_club", { "step": "complete" });
            }
          }

          /*if (window.location.href.includes("/ccgolf")) {
            let userResponse = await axiosWithToken(`${functionBaseUrl}/api/users/MO74kJpfh6NYvgaEvMgQ1zmZBJW2`, {
              method: 'GET',
            });
            if (userResponse?.data?.creditCount > 0) {
              await axiosWithToken(`${functionBaseUrl}/api/v2/upgradeStudent`, {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                data: {
                  id: creds.user.uid,
                  coachId: "MO74kJpfh6NYvgaEvMgQ1zmZBJW2",
                  coachName: "Carolin Pinegger",
                  subPlan: listOfSubscriptions.STUDENT_LITE_MONTHLY,
                  subPeriod: 1,
                  autoRenewal: true,
                },
              }).catch((err) => {
                console.log(err);
              })
            }
          }*/

          setIsLoading(false);
          finishSignUp(creds.user.uid, email.toLowerCase().replace(/ /g, ""));
        })
        .catch((err) => {
          setIsLoading(false);
          Swal.fire({
            title: '<p style="font-size:70%;">' + err.message + "</p>",
            icon: "warning",
            confirmButtonText: "Ok",
            allowOutsideClick: false,
          });
          return;
        });
    } else {
      Swal.fire({
        title:
          '<p style="font-size:70%;">User with provided email already exists. Please click the "Already have an account?" button to accept the offer.</p>',
        confirmButtonText: "Ok",
        allowOutsideClick: true,
      });
      setIsLoading(false);
    }
  };

  return (
    <>
      {!isLoading && (
        <Grid container spacing={2} className="offer-signup">
          <Grid item xs={12} className="offer-block-caption-wrapper">
            <span className="offer-block-caption">Sign Up</span>
          </Grid>
          <Grid item xs={12} style={{ textAlign: "center" }}>
            <Button
              className="offer-button"
              variant="contained"
              color="primary"
              onClick={() => { 
                if (window.location.href.includes("/ccgolf")) {
                  history.push("/sign-in?redirectTo=ccgolf");
                } else if (window.location.href.includes("/dollar-driver-club")) {
                  history.push("/sign-in?redirectTo=dollar-driver-club");
                } else if (window.location.href.includes("/metagolfclub")) {
                  history.push("/sign-in?redirectTo=metagolfclub");
                } else if (window.location.href.includes("/golfpad")) {
                  history.push("/sign-in?redirectTo=golfpad");
                } else {
                  history.push("/sign-in?redirectTo=swing-analysis-offer"); 
                }
                localStorage.setItem("showAlert", "true");
              }}
            >
              Already have an account?
            </Button>
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              required
              className="offer-input"
              label="First Name"
              onChange={(event) => setUserFirstName(event.target.value)}
              value={userFirstName}
              variant="outlined"
              inputProps={{ maxLength: 254 }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              required
              className="offer-input"
              label="Last Name"
              onChange={(event) => setUserLastName(event.target.value)}
              value={userLastName}
              variant="outlined"
              inputProps={{ maxLength: 254 }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              required
              className="offer-input"
              label="Email Address"
              onChange={(event) => setEmail(event.target.value)}
              value={email}
              variant="outlined"
              inputProps={{ maxLength: 254 }}
            />
          </Grid>
          {/*<Grid
            item
            xs={12}
          >
            <TextField
              InputLabelProps={{
                shrink: true,
              }}
              required
              fullWidth
              className="offer-input"
              variant="outlined"
              label="Date of Birth"
              onChange={(event) => setDateOfBirth(event.target.value)}
              type="date"
              value={dateOfBirth}
            />
          </Grid>*/}
          <Grid item xs={12}>
            <FormControl fullWidth className="offer-input" variant="outlined">
              <InputLabel htmlFor="outlined-adornment-password">
                Password *
              </InputLabel>
              <OutlinedInput
                label="Password"
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      edge="end"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
                required
                id="outlined-adornment-password"
                onChange={(event) => setPassword(event.target.value)}
                type={showPassword ? "text" : "password"}
                value={password}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth className="offer-input" variant="outlined">
              <InputLabel htmlFor="outlined-adornment-password">
                Confirm Password *
              </InputLabel>
              <OutlinedInput
                required
                id="checkPassword"
                label="Confirm Password"
                onChange={(event) => setConfirmPassword(event.target.value)}
                type={showPassword ? "text" : "password"}
                value={confirmPassword}
              />
            </FormControl>
            {confirmPassword.length > 0 && password !== confirmPassword && (
              <Typography style={{ color: "red" }}>
                The passwords do not match
              </Typography>
            )}
          </Grid>
          <Grid item xs={12}>
            <FormControl component="fieldset" fullWidth>
              <FormLabel style={{ color: "white" }} component="legend">
                Gender*
              </FormLabel>
              <RadioGroup
                id="gender"
                name="gender"
                value={gender}
                onChange={(event) => setGender(event.target.value)}
                row
              >
                <FormControlLabel
                  style={{ color: "white", marginRight: "50px" }}
                  value="FEMALE"
                  control={<Radio style={{ color: "white" }} />}
                  label={<p style={{ color: "white" }}>Female</p>}
                />
                <FormControlLabel
                  style={{ color: "white" }}
                  value="MALE"
                  control={<Radio style={{ color: "white" }} />}
                  label={<p style={{ color: "white" }}>Male</p>}
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl component="fieldset" fullWidth>
              <FormLabel style={{ color: "white" }} component="legend">
                Dominant Hand*
              </FormLabel>
              <RadioGroup
                id="domHand"
                name="dominantHand"
                value={dominantHand}
                onChange={(event) => setDominantHand(event.target.value)}
                row
              >
                <FormControlLabel
                  style={{ color: "white", marginRight: "70px" }}
                  value="Left"
                  control={<Radio style={{ color: "white" }} />}
                  label={<p style={{ color: "white" }}>Left</p>}
                />
                <FormControlLabel
                  style={{ color: "white" }}
                  value="Right"
                  control={<Radio style={{ color: "white" }} />}
                  label={<p style={{ color: "white" }}>Right</p>}
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl className="offer-input" variant="outlined" fullWidth>
              <InputLabel className="offer-input" id="height-label">
                Height*
              </InputLabel>
              <Select
                className="offer-input"
                fullWidth
                id="height"
                label="Height"
                labelId="height-label"
                name="height"
                onChange={handleChange("height")}
                value={height}
                variant="outlined"
              >
                {heightValues.map((el) => (
                  <MenuItem value={el}>{el}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  value={tosAgree}
                  onClick={() => setTosAgree(!tosAgree)}
                  checked={tosAgree ? "checked" : null}
                />
              }
              label={
                <div className="offer-text">
                  <span>I have read and agree to Sportsbox's </span>
                  <Link
                    className="offer-link"
                    onClick={async (e) => {
                      e.preventDefault();
                      window.open(
                        window.location.href.replace(
                          "invitational",
                          "terms-of-service"
                        )
                      );
                    }}
                  >
                    Terms of Service
                  </Link>
                  <span> and </span>
                  <Link
                    className="offer-link"
                    onClick={async (e) => {
                      e.preventDefault();
                      window.open(
                        window.location.href.replace("invitational", "privacy")
                      );
                    }}
                  >
                    {" "}
                    Privacy Policy.
                  </Link>
                </div>
              }
            />
          </Grid>
          <Grid item xs={12} style={{ textAlign: "center" }}>
            <Button
              className="offer-button"
              disabled={
                tosAgree === false ||
                email.length === 0 ||
                userFirstName.length === 0 ||
                userLastName.length === 0 ||
                password.length === 0 ||
                confirmPassword.length === 0
              }
              variant="contained"
              onClick={() => handleSignUp()}
            >
              Create An Account
            </Button>
          </Grid>
        </Grid>
      )}
      {isLoading && (
        <Container maxWidth="xl" style={{ textAlign: "center", padding: 200 }}>
          <CircularProgress />
        </Container>
      )}
    </>
  );
}

export const SignUp = SignUpComponent;
