import React, { useState } from 'react';
import UnityPopUp from '../search/components/advance_search/UnityPopUp';
import Swal from 'sweetalert2';

function UnityComponent() {
  const [unityData, setUnityDate] = useState({ open: false, data: null });
  const [chunks, setChunks] = useState([]);
  const [chunksRef, setChunksRef] = useState([]);
  const [chunksLength, setChunksLength] = useState(0);
  const [chunksRefLength, setChunksRefLength] = useState(0);
  const [totalFrames, setTotalFrames] = useState(0);

  const handleFile1 = (event) => {
    const file = event.target.files[0]
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const str = e.target.result;
        const data = JSON.parse(str);
        if (data?.success !== true) {
          Swal.fire({
            title: '<p style="font-size:70%;">This is not the expected JSON file, please upload a valid JSON file.</p>',
            icon: 'error',
            confirmButtonText: 'Ok',
            allowOutsideClick: true,
            customClass : { 
              container : 'my-swal' 
            },
          });
          return;
        }
        setTotalFrames(data?.response?.length);
        setChunksLength(JSON.stringify(data).length);
        splitJSONData(JSON.stringify(data), 100000);
      }
      reader.readAsText(file);
    }
  }

  const handleFile2 = (event) => {
    const file = event.target.files[0]
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const str = e.target.result;
        const data = JSON.parse(str);
        if (data?.success !== true) {
          Swal.fire({
            title: '<p style="font-size:70%;">This is not the expected JSON file, please upload a valid JSON file.</p>',
            icon: 'error',
            confirmButtonText: 'Ok',
            allowOutsideClick: true,
            customClass : { 
              container : 'my-swal' 
            },
          });
          return;
        }
        setChunksRefLength(JSON.stringify(data).length);
        splitJSONDataRef(JSON.stringify(data), 100000);
      }
      reader.readAsText(file);
    }
  }

  function splitJSONData(jsonData, chunkSize) {
    const tempChunks = [];
    let index = 0;
    
    while (index < jsonData.length) {
      tempChunks.push(jsonData.substring(index, index + chunkSize));
      index += chunkSize;
    }
    
    setChunks(tempChunks);
    openUnity(tempChunks);
  }

  function splitJSONDataRef(jsonData, chunkSize) {
    const tempChunks = [];
    let index = 0;
    
    while (index < jsonData.length) {
      tempChunks.push(jsonData.substring(index, index + chunkSize));
      index += chunkSize;
    }
    
    setChunksRef(tempChunks);
  }

  const openUnity = (data) => {
    setUnityDate({ open: true, data: data });
  }

  const closeUnity = () => {
    setUnityDate({ open: false, data: null });
    window.location.reload();
  }

  const clearFile = () => {
    // Get the file input element
    var fileInput = document.getElementsByName("file1");
    console.log(fileInput)
    
    // Clear the selected file by setting the value to an empty string
    fileInput.value = "";
  }

  return (<>
    <div style={{ paddingTop: '50px' }}>
      <div style={{ padding: '30px 20px' }}>
        Select .json file:<br /><br />
        <div>
          Model: <input type="file" accept="application/json" name="file1" onChange={handleFile1} />
          {/*<button onClick={clearFile}>Clear</button>*/}
        </div>
        {/*<div>Model 2: <input type="file" onChange={handleFile2} /></div>*/}
      </div>
      {chunks.length > 0 && (
        <UnityPopUp
          open={unityData.open}
          onClose={closeUnity}
          chunks={chunks}
          chunksRef={chunksRef}
          chunksLength={chunksLength}
          chunksRefLength={chunksRefLength}
          totalFrames={totalFrames}
        />
      )}
    </div>
  </>);
}

export const UnityPage = UnityComponent;
