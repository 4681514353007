import React from "react";

import { Stepper, Step, StepLabel, Grid, Button } from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";

import "./offerPaid.css";

function CTAOffersComponent({ handleBuyNow }) {
  React.useEffect(() => {
    document.body.style.backgroundColor = "#06092F";
    return () => {
      document.body.style.backgroundColor = "";
    };
  }, []);

  return (
    <>
      <Stepper activeStep={2} className="cta-mobile-stepper">
        <Step key={1} completed={false}>
          <StepLabel></StepLabel>
        </Step>
        <Step key={2} completed={false} className="inactive">
          <StepLabel></StepLabel>
        </Step>
        <Step key={2} completed={false} className="inactive">
          <StepLabel></StepLabel>
        </Step>
      </Stepper>

      {window.location.href.includes("/holiday-offer") && (
        <>
          <div className="cta-caption">
            Unwrap your best game yet: Holiday Deals on
            <span>Sportsbox 3DGolf</span>
          </div>
          <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
            <img alt="Gifts" src="/images/gifts.png" width={300} />
            <img alt="Avatar" src="/images/avatar.png" width={700} />
          </div>

          <div className="cta-caption">
            <p style={{ textTransform: "initial", fontSize: "50%" }}>Been asking to be connected to a Sportsbox coach? <br /></p>
            <p style={{ fontSize: "75%" }}>VIRTUAL 3D LESSON WITH A SPORTSBOX EXPERT COACH</p>
          </div>

          <div style={{ display: "flex", alignItems: "center", gap: "100px" }}>
            <div>
              <img alt="App 1" src="/images/app1.png" width={300} />
              <img alt="App 2" src="/images/app2.png" width={300} />
            </div>
            <ul className="cta-invitational-benefits" style={{ fontSize: "125%" }}>
              <li style={{ fontSize: "150%", textDecoration: "line-through", textDecorationColor: "red" }}>$50</li>
              <li style={{ fontSize: "200%" }}>$35</li>
              <li style={{ fontSize: "150%" }}>Includes:</li>
              <li style={{ fontSize: "150%" }}>
                <CheckIcon className="cta-benefit-mark" /> Swing analysis
                video from a 3D expert
              </li>
              <li style={{ fontSize: "150%" }}>
                <CheckIcon className="cta-benefit-mark" /> Custom goal for
                your swing fix
              </li>
              <li style={{ fontSize: "150%" }}>
                <CheckIcon className="cta-benefit-mark" /> Recommended drill
              </li>
              <li><Button className="offer-button" variant="contained" onClick={handleBuyNow}>Buy Now</Button></li>
            </ul>
          </div>

          <div className="cta-caption">
            Sportsbox 3D Studio Camera and Software Offer
            <p style={{ textTransform: "initial", fontSize: "50%" }}>Set up your dream simulator or studio</p>
          </div>

          <div style={{ display: "flex", alignItems: "center", gap: "100px" }}>
            <div>
              <img alt="Studio" src="/images/studio.png" width={800} />
            </div>
            <ul className="cta-invitational-benefits">
              <li style={{ paddingTop: "5%", fontSize: "150%", textDecoration: "line-through", textDecorationColor: "red" }}>$1,790</li>
              <li style={{ paddingTop: "5%", fontSize: "300%" }}>$1,611</li>
              <li style={{ paddingTop: "5%", fontSize: "150%", textDecoration: "line-through", textDecorationColor: "red" }}>12 months</li>
              <li style={{ paddingTop: "5%", fontSize: "300%" }}>18 months</li>
              <li style={{ paddingTop: "5%", fontSize: "300%" }}>for $1,499</li>
              <li style={{ paddingTop: "5%" }}>
                <Button
                  className="offer-button"
                  variant="contained"
                  onClick={() => {
                    window.open(
                      "https://meetings.hubspot.com/edwin-fuh/studio-demos-from-website",
                      "_blank",
                      "noopener,noreferrer"
                    );
                  }}
                >
                  BUY NOW
                </Button>
              </li>
            </ul>
          </div>

          <div className="cta-invitational-benefits-wrapper">
            <ul className="cta-invitational-benefits" style={{ fontSize: "150%" }}>
              <li style={{ fontSize: "150%" }}>Studio Software</li>
              <li>
                <CheckIcon className="cta-benefit-mark" /> Hands free
                captures with super fast loading instant replay
              </li>
              <li>
                <CheckIcon className="cta-benefit-mark" /> No motion blur -
                best in class high speed cameras
              </li>
              <li>
                <CheckIcon className="cta-benefit-mark" /> Indicator tiles
                that auto-populate when 3D data is ready
              </li>
              <li>
                <CheckIcon className="cta-benefit-mark" /> Keep swinging or
                select from the swing list to analyze 3D video or 3D
                analysis tools{" "}
              </li>
              <li>
                <CheckIcon className="cta-benefit-mark" /> Drawing tools
              </li>
              <li>
                <CheckIcon className="cta-benefit-mark" /> Compare 2 videos
                in both 2D and 3D
              </li>
              <li>
                <CheckIcon className="cta-benefit-mark" /> Free form rotate
                avatar – Examine every angle
              </li>
              <li>
                <CheckIcon className="cta-benefit-mark" /> Fully connected
                to mobile app (mobile subscription required)
              </li>
            </ul>

            <ul className="cta-invitational-benefits" style={{ fontSize: "150%" }}>
              <li style={{ fontSize: "150%" }}>Cameras</li>
              <li>
                2 Models: HE & LE-C Starts at $1290 <br />
              </li>
              <li>
                <CheckIcon className="cta-benefit-mark" /> No motion blur
                and distortion through global shutter
              </li>
              <li>
                <CheckIcon className="cta-benefit-mark" /> Best in class
                low-light performance with Dynamic Range
              </li>
              <li>
                <CheckIcon className="cta-benefit-mark" /> USB3.0 interface
                to reach max frame rate in full resolution{" "}
              </li>
              <li>
                <CheckIcon className="cta-benefit-mark" /> Leading-edge 3D
                technology capable of 3D motion analysis with a single
                camera
              </li>
              <li>
                <CheckIcon className="cta-benefit-mark" /> Manual & digital
                controls for exposure & ISO
              </li>
            </ul>
          </div>
        </>
      )}
    </>
  );
}

export const CTAOffers = CTAOffersComponent;
