import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as admin from 'firebase/app';
import { functionBaseUrl, axiosWithToken } from '../../common/firebase'
import firebase from '../../common/firebase'
import { withStyles } from '@material-ui/styles';
import { createMuiTheme, MuiThemeProvider, Typography, CssBaseline, Container, Avatar, Grid, CircularProgress } from '@material-ui/core';
import ReportIcon from '@material-ui/icons/Report';
import palette from '../../theme/palette';
import MaterialTable from 'material-table';
import { AccountNotFound } from '../account/components';
import { forwardRef } from 'react';
import { AddBox, ArrowDownward, Check, ChevronLeft, ChevronRight, Clear, DeleteOutline, Edit, FilterList, FirstPage, LastPage, Remove, SaveAlt, Search, ViewColumn } from '@material-ui/icons';

const useStyles = theme => ({
  paper: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  root: {
    marginTop: theme.spacing(4),
  },
  buttonLink: {  // This is a link component surrounding a button component
    width: '100%'
  },
  linkedButton: {  // This is a button component surrounded by a link
    margin: theme.spacing(1, 0),
  }
});

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

const theme = createMuiTheme({
  typography: {
    h6: {
      fontWeight: 500,
      fontSize: '20px',
      letterSpacing: '-0.05px',
      lineHeight: '20px',
    }
  },
  palette: {
    secondary: {
      main: palette.primary.main,
    },
  },
})

const tableHeads = {
  reportIssues: [
    { field: 'comment', title: 'Comment' },
    { field: 'pose2D', title: 'Pose 2D' },
    { field: 'pose3D', title: 'Pose 3D' },
    { field: 'actionData', title: 'Action Data' },
    { field: 'videoId', title: 'Video ID' },
    { field: 'videoURL', title: 'Video URL' },
    { field: 'platform', title: 'Platform' },
    { field: 'buildVersion', title: 'Build Version' },
    { field: 'date', title: 'Date', defaultSort: 'desc' },
  ]
}

class ReportedIssues extends Component {
  constructor(props) {
    const firebaseUser = firebase.auth().currentUser
    super(props)
    this.state = {
      uid: firebaseUser.uid,
      error: "",
      loading: false,
      issuePage: 0,
      issueFirst: null,
      issueLast: null,
    }
  }

  componentDidMount() {
    if (!this.state.uid) {
      this.setState({ error: "Please log in to submit a session" })
      this.setState({ loading: false })
    }
    else {
      axiosWithToken(functionBaseUrl + '/api/users/' + this.state.uid, {
        method: 'get',
      }).then(response => {
        var role = response.data.role;
        if (!["admin", "data-admin"].includes(role)) {
          this.setState({ loading: false, error: "You are not authorized to access this page." });
        }
      })
        .catch(err => {
          console.log(err)
          this.setState({ loading: false, error: "There was an error retrieving your info" });
        });
    }
  }

  getTableHeader = (name) => {
    return tableHeads[name];
  }

  linkify = (inputText) => {
    var replacedText, replacePattern1, replacePattern2, replacePattern3;

    if (inputText) {
      //URLs starting with http://, https://, or ftp://
      replacePattern1 = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim;
      replacedText = inputText.replace(replacePattern1, '<a href="$1" target="_blank">$1</a>');

      //URLs starting with "www." (without // before it, or it'd re-link the ones done above).
      replacePattern2 = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
      replacedText = replacedText.replace(replacePattern2, '$1<a href="http://$2" target="_blank">$2</a>');

      //Change email addresses to mailto:: links.
      replacePattern3 = /(([a-zA-Z0-9\-\_\.])+@[a-zA-Z\_]+?(\.[a-zA-Z]{2,6})+)/gim;
      replacedText = replacedText.replace(replacePattern3, '<a href="mailto:$1">$1</a>');
    }

    return replacedText;
  }

  getIssues = async (query) => {
    this.setState({ loading: true });
    let orderBy = query.orderBy?.field || 'platform';
    let snapReportIssues = admin.firestore().collection('reportIssues')
    if (query.search) {
      snapReportIssues = snapReportIssues.orderBy('comment').where('comment', '>=', query.search).where('comment', '<=', query.search + '~')
    }

    if (query.page > 0) {
      if (this.state.issuePage < query.page) {
        snapReportIssues = snapReportIssues.limit(query.pageSize).startAfter(this.state.issueLast);
      } else {
        snapReportIssues = snapReportIssues.limitToLast(query.pageSize).endBefore(this.state.issueFirst)
      }
    } else {
      snapReportIssues = snapReportIssues.limit(query.pageSize);
    }
    this.setState({ issuePage: query.page });
    snapReportIssues = await snapReportIssues.get();
    let issues = []
    snapReportIssues.forEach(snapIssue => {
      if (issues.length === 0) {
        this.setState({ issueFirst: snapIssue });
      }
      if (issues.length === snapReportIssues.size - 1) {
        this.setState({ issueLast: snapIssue });
      }
      const issue = snapIssue.data();
      issues.push({
        comment: issue.comment,
        pose2D: this.linkify(issue.pose2D),
        pose3D: this.linkify(issue.pose3D),
        actionData: issue.actionData,
        videoId: issue.videoId,
        videoURL: issue.videoURL,
        platform: issue.platform,
        buildVersion: issue.buildVersion,
        date: (new Date(issue.date).getMonth() + 1) + '/' + new Date(issue.date).getDate() + '/' + new Date(issue.date).getFullYear() % 100,
      });
    });
    let total = query.pageSize * (query.page);
    if (issues.length) {
      total = issues.length !== query.pageSize ?
        query.pageSize * query.page + issues.length :
        query.pageSize * (query.page + 1) + 1;
    }
    if (issues.length === 0 && query.page > 0) {
      issues = [{}];
    }
    this.setState({ loading: false });
    return {
      data: issues,
      page: total > query.pageSize * query.page ? query.page + 1 : query.page,
      total: total
    }
  }


  render() {
    const classes = this.props.classes;

    return (<div >
      <Container
        className={classes.paper}
        component="main">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <ReportIcon />
          </Avatar>
          <Typography
            component="h1"
            variant="h4"
            style={{ marginBottom: '10px' }}
          >
            REPORTED ISSUES
          </Typography>
        </div>
        <MuiThemeProvider theme={theme}>
          <Grid
            style={{ margin: "auto", padding: 100, display: (this.state.loading) ? "block" : "none" }}
          >
            <CircularProgress />
          </Grid>
          <Grid item xs={12}>
            <MaterialTable
              style={{ marginBottom: '10px' }}
              icons={tableIcons}
              key='issuesTable'
              title='Issues'
              columns={this.getTableHeader('reportIssues')}
              data={
                query =>
                  new Promise((resolve) => {
                    this.getIssues(query).then(result => {
                      resolve({
                        data: result.data,
                        page: result.page - 1,
                        totalCount: result.total,
                      })
                    })
                  })
              }
              options={{
                selection: true,
                sorting: true
              }}
            />
          </Grid>
          <Grid
            item
            lg={12}
            md={12}
            xl={12}
            xs={12}
            style={{ display: (this.state.error && !this.state.loading) ? "block" : "none" }}
          >
            <AccountNotFound
              error={{ error: this.state.error }}
            />
          </Grid>
        </MuiThemeProvider>
      </Container>
    </div>
    )
  }
}

ReportedIssues.propTypes = {
  classes: PropTypes.object,
  history: PropTypes.object,
};

export default withStyles(useStyles)(ReportedIssues);