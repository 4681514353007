import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Dialog, DialogTitle, IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import admin from "../../../../common/firebase";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

export const CoachesPopUp = ({ open, onClose, userId }) => {
  const [data, setData] = useState([]);

  const getCoaches = async () => {
    const student = (
      await admin
        .firestore()
        .collection("users")
        .doc(userId)
        .get()
    ).data();

    const coaches = await Promise.all(
      student.coaches.map(async (el) => {
        return (
          await admin.firestore().collection("users").doc(el).get()
        ).data();
      })
    );
    setData(coaches);
  };

  useEffect(() => {
    if (open) {
      getCoaches();
    }
  }, [open, userId]);

  const handleClose = () => {
    setData([]);
    onClose();
  };

  const classes = useStyles();

  return (
    <Dialog
      fullWidth
      maxWidth={"md"}
      style={{ width: "100%" }}
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle>
        <IconButton
          onClick={handleClose}
          style={{ float: "right", marginTop: "-10px", marginRight: "-15px" }}
        >
          <Close />
        </IconButton>
        <h4>Coaches</h4>
      </DialogTitle>
      <div style={{ padding: "50px", fontSize: "16px" }}>
        {data.length ? (<TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Coach Name</TableCell>
                <TableCell>Coach Email</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row) => (
                <TableRow key={row.name}>
                  <TableCell>{row.userName}</TableCell>
                  <TableCell>{row.email}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>)
        : (<div> No coaches found </div>)}
      </div>
    </Dialog>
  );
};
