import React from 'react';

import { Container, Grid } from '@material-ui/core';

import { StudioAccountList } from './StudioAccountList';

function StudioComponent() {
  return (<>
    <Container
      component="main"
      style={{ paddingTop: '20px' }}
    >
      <Grid container spacing={2}>
        <StudioAccountList />
      </Grid>
    </Container>
  </>);
}

export const Studio = StudioComponent;