import React, { useState } from "react";
import {
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  withStyles,
  Button,
  CircularProgress,
} from "@material-ui/core";
import { FlexWrap, Pad } from "../../../components";
import { useCallback } from "react";
import { useEffect } from "react";
import { format } from "date-fns";

export function CreateSessionModal({
  sessionRef,
  coachRef,
  open,
  onClose,
  onCreate,
}) {
  const [form, setForm] = useState({
    sessionName: "",
    purpose: "",
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (open && coachRef && sessionRef) {
      const list = [...coachRef.students, coachRef];
      const found = list.find((el) => el.uid === sessionRef.studentId);

      const { firstName, lastName } = found;

      setForm({
        sessionName: `${firstName} ${lastName}-${sessionRef.golfClub}-${format(
          new Date(),
          "yyMMdd-HHmm"
        )}`,
        purpose: "Studio-Bay",
      });
    }
  }, [sessionRef, coachRef, open]);

  const handleChange = (e) => {
    setForm((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const SaveButton = withStyles({
    root: {
      border: "2px solid",
      borderColor: "#580CE3",
      backgroundColor: "#580CE3",
      color: "#FFF",
      width: "48%",
      paddingTop: "10px",
      paddingBottom: "10px",
      "&:hover": {
        backgroundColor: "#FFF",
        borderColor: "#580CE3",
        color: "#580CE3",
      },
      "&:active": {
        backgroundColor: "#580CE3",
        borderColor: "#580CE3",
        color: "#fff",
      },
      "&:disabled": {
        backgroundColor: "#c4c4c4",
        borderColor: "#c4c4c4",
        color: "#FFF",
      },
    },
  })(Button);

  const CancelButton = withStyles({
    root: {
      border: "2px solid",
      borderColor: "#808080",
      backgroundColor: "#808080",
      color: "#FFF",
      width: "48%",
      paddingTop: "10px",
      paddingBottom: "10px",
      "&:hover": {
        backgroundColor: "#FFF",
        borderColor: "#808080",
        color: "#808080",
      },
      "&:active": {
        backgroundColor: "#FFF",
        borderColor: "#808080",
        color: "#808080",
      },
    },
  })(Button);

  const handleCreate = useCallback(async () => {
    setLoading(true);
    await onCreate(form);
    setForm({
      sessionName: "",
    });
    setLoading(false);
  }, [form]);

  return (
    <Dialog onClose={onClose} open={open}>
      <DialogContent style={{ padding: "32px", width: "550px" }}>
        {loading ? (
          <Pad width="100%">
            <FlexWrap justify="center" align="center">
              <CircularProgress />
            </FlexWrap>
          </Pad>
        ) : (
          <Pad width="100%">
            <Typography variant="h3" component="h3">
              SAVE SESSION
            </Typography>
            <Pad height="40px" />

            <TextField
              fullWidth
              variant="outlined"
              label="Session Name"
              name="sessionName"
              value={form.sessionName}
              onChange={handleChange}
            />
            <Pad height="10px" />
            <TextField
              fullWidth
              variant="outlined"
              label="Purpose"
              name="purpose"
              value={form.purpose}
              onChange={handleChange}
            />

            <Pad height="40px" />
            <FlexWrap justify="space-between">
              <CancelButton onClick={onClose}>CANCEL</CancelButton>
              <SaveButton
                disabled={form.sessionName.length === 0}
                onClick={handleCreate}
              >
                SAVE
              </SaveButton>
            </FlexWrap>
          </Pad>
        )}
      </DialogContent>
    </Dialog>
  );
}
