import React, { useEffect, useState } from "react";

import { Grid } from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import Swal from "sweetalert2";
import moment from "moment";
import "./offerPaid.css";
import { functionBaseUrl, axiosWithToken } from "../../common/firebase";
import { StripeSubscription } from "../../components/stripeSubscription";
import { listOfSubscriptions } from "../../common/envConfig";
import { StripePayment } from "../../components/stripePayment";

function PaymentComponent({ uid, email, name, handleProduct, finishPayment }) {
  const [productId, setProductId] = useState(
    window.location.href.includes("/mark-crossfield")
      ? listOfSubscriptions.STUDENT_LITE_ANNUALLY
      : "swing_analysis"
  );

  useEffect(() => {
    handleProduct(productId);
  }, [productId]);

  const createSubscription = async (data) => {
    let referralCode = window.location.href;
    if (referralCode.includes("REFERRALCODE=")) {
      referralCode = referralCode.split("REFERRALCODE=")[1];
    } else {
      referralCode = "";
    }
    try {
      await axiosWithToken(functionBaseUrl + "/api/subscription", {
        method: "POST",
        data: {
          userId: uid,
          plan: productId,
          planDescription: "",
          planType:
            productId === listOfSubscriptions.STUDENT_LITE_MONTHLY
              ? "Monthly"
              : "Annually",
          transactionId: data.id,
          payload: "",
          packageId: "",
          orderId: "",
          amount: (data.amount / 100).toFixed(2),
          startDate: String(Date.now()),
          endDate:
            productId == listOfSubscriptions.STUDENT_LITE_MONTHLY
              ? String(moment().add(1, "month").valueOf())
              : String(moment().add(1, "year").valueOf()),
          lastUpdated: String(Date.now()),
          autoRenewal: true,
          platform: "Stripe",
          productId: productId,
          referralCode: "GOLFSALE",
          promoCode: "aNRQuT1C",
        },
      })
        .then(async (result) => {
          if (productId === listOfSubscriptions.STUDENT_LITE_ANNUALLY) {
            await axiosWithToken(functionBaseUrl + "/api/referral", {
              method: "POST",
              data: {
                referralCode: "GOLFSALE",
                firstName: name.split(" ")[0] || "",
                lastName: name.split(" ")[1] || "",
                email: email.toLowerCase().replace(/ /g, ""),
                externalIdentifier: result.data.id,
                amount: (data.amount / 100).toFixed(2),
                note: listOfSubscriptions.STUDENT_LITE_ANNUALLY,
              },
            })
              .then(async (refRes) => {
                await axiosWithToken(
                  functionBaseUrl + "/api/updateS/" + result.data.id,
                  {
                    method: "POST",
                    data: {
                      referrals: [refRes.data.referral.id],
                      referralCode: "GOLFSALE",
                      subLabel: "markCrossfield",
                    },
                  }
                ).catch((err) => {
                  console.log(err);
                });
              })
              .catch((err) => {
                console.log(err);
              });
          } else {
            await axiosWithToken(
              functionBaseUrl + "/api/updateS/" + result.data.id,
              {
                method: "POST",
                data: {
                  subLabel: "markCrossfield",
                },
              }
            ).catch((err) => {
              console.log(err);
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });

      await axiosWithToken(functionBaseUrl + "/api/userCoach", {
        method: "POST",
        data: {
          userEmail: email?.toLowerCase()?.replace(/ /g, ""),
          coachEmail: "3dcoach.contest@gmail.com",
        },
      }).catch((err) => {
        console.log(err);
      });

      await axiosWithToken(functionBaseUrl + "/api/users/" + uid, {
        method: "PATCH",
        data: {
          markCrossfield: {
            newUser: true,
            date: String(Date.now()),
          },
        },
      }).catch((err) => {
        console.log(err);
      });

      finishPayment();
    } catch (e) {
      Swal.fire({
        title:
          '<p style="font-size:70%;">There was an error processing your subscription, please try again.</p>',
        icon: "error",
      });
    }
  };

  const handlePaymentComplete = async (data) => {
    try {
      if (productId === "swing_analysis") {
        await axiosWithToken(functionBaseUrl + "/api/userCoach", {
          method: "POST",
          data: {
            userEmail: email?.toLowerCase()?.replace(/ /g, ""),
            coachEmail: "sportsbox.3dcoach@sportsbox.ai",
          },
        }).catch((err) => {
          console.log(err);
        });

        await axiosWithToken(functionBaseUrl + "/api/lsnEmail", {
          method: "POST",
          data: {
            email: email?.toLowerCase()?.replace(/ /g, ""),
          },
        }).catch((err) => {
          console.log(err);
        });
      }

      await axiosWithToken(functionBaseUrl + "/api/users/" + uid, {
        method: "PATCH",
        data: {
          holidayOffer: { offer: productId, date: String(Date.now()) },
        },
      }).catch((err) => {
        console.log(err);
      });

      finishPayment();
    } catch (e) {
      Swal.fire({
        title:
          '<p style="font-size:70%;">There was an error processing your payment, please try again.</p>',
        icon: "error",
      });
    }
  };

  return (
    <>
      {productId?.includes("sportsbox_") && (
        <>
          <div
            className={
              productId == listOfSubscriptions.STUDENT_LITE_ANNUALLY
                ? "offer-product special active"
                : "offer-product special"
            }
            onClick={() =>
              setProductId(listOfSubscriptions.STUDENT_LITE_ANNUALLY)
            }
          >
            <div className="offer-product-cta">Best value</div>
            <Grid container spacing={2}>
              <Grid item xs={11}>
                <div className="offer-product-name">
                  Annual - billed at <s>$110/year</s> $99/year
                </div>
                <div className="offer-product-price">$8.25/month</div>
              </Grid>
              <Grid item xs={1} className="offer-product-check-wrapper">
                <CheckIcon className="offer-product-check" />
              </Grid>
            </Grid>
          </div>
          <div
            className={
              productId == listOfSubscriptions.STUDENT_LITE_MONTHLY
                ? "offer-product active"
                : "offer-product"
            }
            onClick={() =>
              setProductId(listOfSubscriptions.STUDENT_LITE_MONTHLY)
            }
          >
            <Grid container spacing={2}>
              <Grid item xs={11}>
                <div className="offer-product-name">Monthly</div>
                <div className="offer-product-price">
                  <s>$15.99/month</s> $14.39/month
                </div>
              </Grid>
              <Grid item xs={1} className="offer-product-check-wrapper">
                <CheckIcon className="offer-product-check" />
              </Grid>
            </Grid>
          </div>
          <div className="offer-payment">
            <StripeSubscription
              email={email}
              productId={productId}
              handleResult={createSubscription}
              //freeTrial={30}
              hideCoupon={true}
              showDetails={false}
              //buttonText="Start 30-day free trial"
              fullName={""}
            />
            <div className="offer-payment-disclaimer">
              You will be charged $
              {productId == listOfSubscriptions.STUDENT_LITE_ANNUALLY
                ? "99.00"
                : "14.39"}{" "}
              and will auto-renew unless canceled. Manage or cancel anytime in
              your account settings.
            </div>
          </div>
        </>
      )}

      {(productId?.includes("swing_analysis") ||
        productId?.includes("studio_offer")) && (
        <>
          <div
            className={
              productId == "swing_analysis"
                ? "offer-product special active"
                : "offer-product"
            }
            onClick={() => setProductId("swing_analysis")}
          >
            <Grid container spacing={2}>
              <Grid item xs={11}>
                <div className="offer-product-name">Swing Analysis</div>
                <div className="offer-product-price">
                  <s>$50</s> $35
                </div>
              </Grid>
              <Grid item xs={1} className="offer-product-check-wrapper">
                <CheckIcon className="offer-product-check" />
              </Grid>
            </Grid>
          </div>

          {/*<div
            className={
              productId == "studio_offer"
                ? "offer-product special active"
                : "offer-product"
            }
            onClick={() => setProductId("studio_offer")}
          >
            <Grid container spacing={2}>
              <Grid item xs={11}>
                <div className="offer-product-name">
                  SPORTSBOX 3D STUDIO CAMERA AND SOFTWARE OFFER
                </div>
                <div className="offer-product-price">
                  <s>$1,790</s> $1,499
                </div>
              </Grid>
              <Grid item xs={1} className="offer-product-check-wrapper">
                <CheckIcon className="offer-product-check" />
              </Grid>
            </Grid>
          </div>*/}

          <div className="offer-payment">
            <StripePayment
              amount={productId == "swing_analysis" ? 35 : 1499}
              handleResult={handlePaymentComplete}
              email={email}
              buttonText="Pay"
              //hideSavedCard={true}
            />
          </div>
        </>
      )}
    </>
  );
}

export const Payment = PaymentComponent;
