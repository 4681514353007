import React, { useEffect, useState, useCallback } from "react";
import {
    Avatar,
    CircularProgress,
    Container,
    CssBaseline,
    MenuItem,
    MuiThemeProvider,
    Select,
    Typography,
    withStyles,
    Input,
    IconButton,
} from "@material-ui/core";
import AssignmentIcon from "@material-ui/icons/Assignment";
import MaterialTable from "material-table";
import PropTypes from "prop-types";
import { Bar } from "react-chartjs-2";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import SearchIcon from "@material-ui/icons/Search";
import { addMonths, format, startOfYear } from "date-fns";
import { useStyles, theme } from "./config";
import { MonthlyReport } from "./MonthlyReport";
import { FlexWrap, Pad } from "../../components";
import { getReportsData, getInitData, searchCoach } from "./api";
import { getAllUsersReport } from "./api/report";
import { FreeUsersStats } from "./components/FreeUsersStats";
import { PaidUsersStats } from "./components/PaidUsersStats";
import { UserList } from "./components/UserList";
import { getReportByCoach, getReportData } from "./api/temp";
import { CoachReportData } from "./components/CoachReportData";
import { StatisticsContainer } from "./components/StatisticsContainer";
import { OverallReportData } from "./components/OverallReportData";

const Reports = (props) => {
    const [reportData, setReportData] = useState(null);
    const [coachReportData, setCoachReportData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [view, setView] = useState("overall");

    useEffect(() => {
        (async () => {
            const data = await getReportData();
            setReportData(data);
            setLoading(false);
        })();
    }, []);

    useEffect(() => {
        /*if (reportData) {
            //console.log("overall", reportData);
        }
        if (coachReportData) {
            //console.log("coach", coachReportData);
        }*/
    }, [reportData, coachReportData]);

    const chooseCoach = useCallback(async (rowData) => {
        setLoading(true);
        const data = await getReportByCoach(rowData.uid);
        setCoachReportData(data);
        setView("coach");
        setLoading(false);
    }, []);

    const classes = props.classes;

    const ViewSwitch = () => {
        switch (view) {
            case "overall":
                return (
                    <OverallReportData
                        data={reportData}
                        onCoachClick={chooseCoach}
                    />
                );
            case "coach":
                return (
                    <CoachReportData
                        data={coachReportData}
                        onBack={() => setView("overall")}
                    />
                );
            default:
                break;
        }
    };

    return (
        <>
            <div>
                <Container className={classes.paper} component="main">
                    <CssBaseline />
                    <div style={{ width: "100%" }} className={classes.paper}>
                        <Avatar className={classes.avatar}>
                            <AssignmentIcon />
                        </Avatar>
                        <Typography
                            component="h1"
                            variant="h4"
                            style={{ marginBottom: "10px" }}
                        >
                            REPORTS
                        </Typography>
                    </div>
                    {loading ? <CircularProgress /> : <ViewSwitch />}
                </Container>
            </div>
        </>
    );
};

Reports.propTypes = {
    classes: PropTypes.object,
    history: PropTypes.object,
};

export default withStyles(useStyles)(Reports);
