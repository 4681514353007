import React from 'react';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Grid, Button, Container } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  }, 
  padding: {
    padding: theme.spacing(2),
    display: 'flex',
    alignItems: 'flex-end'
  },
  button: {
    color: '#ffffff',
    opacity: 0.8,
    background: '#000C66',
    ///backgroundImage: 'url("images/logos/SportsboxLogo.png")',
    border: '2px solid #ffffff',
    '&:hover': {
      background: '#000C60',
      opacity: 0.8,
      border: '1px solid #ffffff',
    }
  }
}));

const Welcome = (props) => {  

  const classes = useStyles();
  
  return (
    <Container
      component="main"
      maxWidth="xs"
    >
      <div
      className={classes.paper}
      >
        <Grid item>
         <img alt="" src="/images/Background.jpg" style={{position: "fixed", left: 0, top: 10, minWidth: "100%", minHeight: "100%"}}/>
        </Grid>
        <Grid
        item
        className={classes.padding}       
        >
          <img alt="" src="/images/logos/SportsboxLogo.png" style={{position: "absolute", marginTop: "-20%", marginLeft: "-15%", width: "60%", height: "25%"}}/>
          <RouterLink to="/sign-in">
            <Button className={classes.button}
            style={{color:"#FFFFFF", fontSize: "350%", fontFamily:"'Gill Sans', sans-serif", position: "relative", marginTop: "180%", marginLeft: "-5%"/*, marginTop: "300%", marginLeft: "-5%"*/}}
            >
              TEE UP
            </Button>
          </RouterLink>          
        </Grid>
      </div>
    </Container>
  );
};

Welcome.propTypes = {
  history: PropTypes.object,
};

export default withRouter(Welcome);