import React from 'react';

import { Stepper, Step, StepLabel } from '@material-ui/core';

import './offer.css';


function CTASignUpComponent() {

  return (<>
    <Stepper activeStep={2} className="cta-mobile-stepper">
      <Step key={1} completed={false}>
        <StepLabel></StepLabel>
      </Step>
      {/*<Step key={2} completed={false} className="inactive">
        <StepLabel></StepLabel>
      </Step>*/}
      <Step key={2} completed={false} className="inactive">
        <StepLabel></StepLabel>
      </Step>
    </Stepper>
    {window.location.href.includes("/ccgolf") ? 
      <div className="cta-caption" >
        SUCCESS! YOUR <span>COACH CAROLIN GOLF<br /></span>        
        MEMBERSHIP IS <span><br /></span>
        NOW ACTIVE!
      </div>
      :
      (window.location.href.includes("/golfpad") ?
        <div className="cta-caption">
          GOLF PAD PREMIUM USERS:<br />
          HERE IS YOUR<br />
          <span> FREE </span>3D SWING ANALYSIS
        </div>
        :
        <div className="cta-caption">
          GET YOUR <span> FREE </span><br />
          3D SWING ANALYSIS
        </div>
      )
    }
    {window.location.href.includes("/invitational") && <div className="cta-subcaption">
      Sign up for a free 3D Swing analysis from a 
      Sportsbox 3D expert and also get 2 free months 
      of Sportsbox's 3D player app!
    </div>}
    {window.location.href.includes("/swing-analysis-offer") && <div className="cta-subcaption">
      Sign up for a free 3D Swing analysis from a 
      Sportsbox 3D expert.
    </div>}
    {window.location.href.includes("/dollar-driver-club") && <div className="cta-subcaption">
      Sign up for a free 3D Swing analysis from a 
      Sportsbox 3D expert.
    </div>}
    {window.location.href.includes("/ccgolf") && <div className="cta-subcaption">
      To get started with your swing analysis, create your free Sportsbox 3D Account now and 
      enter your own personal Coach Carolin training space.
    </div>}
    {window.location.href.includes("/metagolfclub") && <div className="cta-subcaption">
      Sportsbox AI is offering an exclusive 3D Swing Analysis + 1 Free Month of Sportsbox 3DGolf 
      for all Meta Golf members. Sign up and learn exactly where you're losing consistency and 
      power in your swing!
    </div>}
    {window.location.href.includes("/golfpad") && <div className="cta-subcaption">
      Sportsbox AI is offering an exclusive 3D Swing Analysis + 1 Free Month of Sportsbox 3DGolf 
      for all Golf Pad Premium users. Sign up and learn exactly where you're losing consistency and power 
      in your swing! <br /><br />

      Here’s how to start: <br />
      Step 1: Create an account and download the Sportsbox 3DGolf App <br />
      Step 2: Open the app and you will find directions from your 3D coach via your inbox <br />
      Step 3: Upload a video for your coach and schedule a time with your coach to receive your live 3D video analysis and practice plan! <br /><br />

      You have 30 days to enjoy Sportbox 3D Player absolutely free. <br />
      Like what you see? Your promo code will be automatically applied to your account so you can enjoy 25% off your premium membership!
    </div>}
    {window.location.href.includes("/invitational") && <div className="cta-invitational">
      <img
        alt="Invitational"
        src="/images/offer/invitational.png"
        width={350}
      />
    </div>}
    {(window.location.href.includes("/swing-analysis-offer") || window.location.href.includes("/dollar-driver-club") || window.location.href.includes("/golfpad")) && <div className="cta-invitational">
      <img
        alt="Swing Analysis Offer"
        src="/images/offer/free-swing-analysis.gif"
        width={280}
      />
    </div>}
    {window.location.href.includes("/ccgolf") && <div className="cta-invitational">
      <img
        alt="Swing Analysis Offer"
        src="/images/offer/ccgolf.png"
        width={500}
        style={{ marginTop: "-40%", marginBottom: "-40%" }}
      />
    </div>}
    {window.location.href.includes("/dollar-driver-club") && <div className="cta-invitational">
      <img
        alt="Dollar Driver Club"
        src="/images/offer/dollar-driver-club.png"
        width={500}
        style={{ marginTop: "-10%" }}
      />
    </div>}
    {window.location.href.includes("/metagolfclub") && <div className="cta-invitational">
      <img
        alt="Swing Analysis Offer"
        src="/images/offer/meta.png"
        width={280}
      />
    </div>}
    {window.location.href.includes("/golfpad") && <div className="cta-invitational">
      <img
        alt="GolfPad"
        src="/images/offer/golfpad.svg"
        width={280}
        style={{ marginBottom: "5%" }}
      />
    </div>}
  </>);
}

export const CTASignUp = CTASignUpComponent;