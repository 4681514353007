import React from "react";
import { ReportPageFooter } from "./ReportPageFooter";

export const ReportPageTemplate = ({
  pageRef,
  pageName,
  firstData,
  secondData,
  indicatorBlocks,
  userName,
  date,
  club,
  page,
  totalPages,
}) => {
  return (
    <div
      ref={pageRef}
      style={{
        width: "800px",
        height: "1150px",
        margin: "20px auto",
        padding: "80px",
        backgroundColor: "#fff",
        color: "#333",
        fontSize: "8px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <h2 style={{ fontSize: "16px" }}>{pageName}</h2>
        <img src="/images/Logo.svg" alt="logo" />
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "20px 0",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "35%",
          }}
        >
          <div>
            <div style={{ fontSize: "12px", fontWeight: "bold" }}>
              {firstData.value}
            </div>
            <div>{firstData.name}</div>
          </div>
          {secondData && (
            <div>
              <div style={{ fontSize: "12px", fontWeight: "bold" }}>
                {typeof secondData.value === "number"
                  ? secondData.value.toFixed(2)
                  : secondData.value}
              </div>
              <div>{secondData.name}</div>
            </div>
          )}
        </div>
        <div
          style={{
            width: secondData ? "60%" : "80%",
            background: "#F9FAFB",
            border: "#D0D5DD 1px solid",
            borderRadius: "4px",
            padding: "8px",
          }}
        >
          <div style={{ color: "#101828" }}>
            {pageName !== "Consistency Report"
              ? "Z Score"
              : "How do we measure Consistency"}
          </div>
          <div style={{ color: "#475467" }}>
            {pageName !== "Consistency Report"
              ? "Z Score refers to how many standard deviations your data point is from the mean"
              : "This is measured based on how much variance you have across all your swings. The ideal value here is 0"}
          </div>
        </div>
      </div>

      {indicatorBlocks}

      <ReportPageFooter
        club={club}
        userName={userName}
        date={date}
        page={page}
        totalPages={totalPages}
      />
    </div>
  );
};
