import React, { forwardRef, useEffect, useState } from 'react';
import {
  AddBox,
  ArrowDownward,
  Check,
  ChevronLeft,
  ChevronRight,
  Clear,
  DeleteOutline,
  Edit,
  FilterList,
  FirstPage,
  LastPage,
  Remove,
  SaveAlt,
  Search,
  ViewColumn,
  Receipt,
} from '@material-ui/icons';
import { Button, Grid, CircularProgress, Container } from '@material-ui/core';
import MaterialTable from 'material-table';
import Swal from 'sweetalert2';
import { functionBaseUrl, axiosWithToken } from '../../common/firebase';
import { AddStudioAccount } from './AddStudioAccount';
import { EditStudioAccount } from './EditStudioAccount';
import { RemoveStudioAccount } from './RemoveStudioAccount';

const tableHeader = [
  { field: 'email', title: 'Email Address' },
  { field: 'userName', title: 'Name' },
  { field: 'studioAccount.coachesCount', title: '# of Coaches Licenses' },
  { field: 'studioAccount.baysCount', title: '# of Bays' },
  { field: 'studioAccount.prosLicCount', title: '# of 3D Pro Licenses' },
  { field: 'studioAccount.playersLicCount', title: '# of 3D Player Licenses' },
  { field: 'studioAccount.annualPrice', title: 'Annual Pricing' },
  //{ field: 'studioAccount.students.length', title: 'Number of Students' },
  //{ field: 'studioAccount.status', title: 'Status' },
  //{ field: 'studioAccount.exception', title: 'Exception' },
  //{ field: 'studioAccount.note', title: 'Note' },
];

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  Receipt: forwardRef((props, ref) => <Receipt {...props} ref={ref} />),
};

function StudioAccountListComponent() {
  const [accounts, setAccounts] = useState([]);
  const [isAddPopupOpened, setIsAddPopupOpened] = useState(false);
  const [isRemovePopupOpened, setIsRemovePopupOpened] = useState(false);
  const [userData, setUserData] = useState(false);
  const [isEditPopupOpened, setIsEditPopupOpened] = useState(false);

  const openCreateAccount = () => {
    setIsAddPopupOpened(true);
  }

  const closeCreateAccount = () => {
    setIsAddPopupOpened(false);
    loadData();
  }

  const closeEditAccount = () => {
    setIsEditPopupOpened(false);
    loadData();
  }

  const handleEdit = (event, data) => {
    setUserData(data);
    setIsEditPopupOpened(true);
  }

  const openRemoveAccount = () => {
    setIsRemovePopupOpened(true);
  }

  const closeRemoveAccount = () => {
    setIsRemovePopupOpened(false);
    loadData();
  }

  const handleDelete = async (event, data) => {
    Swal.fire({
      title: '<p style="font-size:70%;">Are you sure you want to delete this account?</p>',
      icon: 'warning',
      confirmButtonText: 'Yes',
      showCancelButton: true,
      cancelButtonText: 'No',
      cancelButtonColor: 'red',
      allowOutsideClick: false
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await axiosWithToken(`${functionBaseUrl}/api/users/${data.uid}`, {
            method: 'delete',
          })
          Swal.fire({
            title: '<p style="font-size:70%;">Account successfully deleted!</p>',
            icon: 'success',
            showCancelButton: false,
            showConfirmButton: false,
            showCloseButton: false,
            allowOutsideClick: false,
            timer: 3000
          }).then(() => {
            window.location.reload();
          })
        } catch (e) {
          Swal.fire('<p style="font-size:70%;">Error occured</p>');
        }
      }
    });
  }

  const loadData = async () => {
    await axiosWithToken(`${functionBaseUrl}/api/studioUsers`, {
      method: 'GET',
    })
      .then((response) => {
        setAccounts(response.data.userInfo);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    loadData();
  }, [])

  return (<>
    <Grid item xs={12} style={{ textAlign: 'right', paddingTop: '2rem' }}>
      <Button
        variant="outlined"
        color="primary"
        style={{ marginRight: '2rem' }}
        onClick={openRemoveAccount}
      >
        DOWNGRADE ACCOUNT
      </Button>
      <Button
        variant="contained"
        color="primary"
        onClick={openCreateAccount}
      >
        CREATE NEW ACCOUNT
      </Button>
    </Grid>
    <Grid item xs={12}>
      <h3>Studio Accounts</h3>
      {accounts.length > 0 ? (
        <MaterialTable
          style={{ marginTop: '10px', marginBottom: '10px' }}
          icons={tableIcons}
          columns={tableHeader}
          title=""
          data={accounts}
          options={{
            selection: false,
            sorting: true,
            search: true,
            paging: true,
            pageSizeOptions: [5, 10, 20, 50, 100],
            exportAllData: true,
            exportButton: true,
          }}
          actions={[rowData => ({
            tooltip: 'Edit',
            icon: tableIcons.Edit,
            disabled: !rowData.subscriptionType.includes('studio_master') || !rowData?.studioAccount?.coachesCount,
            onClick: handleEdit,
          }), rowData => ({
            tooltip: 'Delete Account',
            icon: tableIcons.Delete,
            disabled: rowData.role === 'admin',
            onClick: handleDelete,
          })]}
        />
      ) : 
        <Container style={{ textAlign: 'center', paddingTop: '50px' }}>
          <CircularProgress />
        </Container>
      }
      <AddStudioAccount
        open={isAddPopupOpened}
        close={closeCreateAccount}
      />
      <RemoveStudioAccount
        open={isRemovePopupOpened}
        close={closeRemoveAccount}
      />
      {isEditPopupOpened &&
        <EditStudioAccount
          user={userData}
          open={isEditPopupOpened}
          close={closeEditAccount}
        />
      }
    </Grid>
  </>);
}

export const StudioAccountList = StudioAccountListComponent;