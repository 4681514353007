import React, { useCallback, useEffect, useRef, useState } from "react";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import { ReportPageTemplate } from "./components/ReportPageTemplate";
import { ReportIndicatorCardGroup } from "./components/ReportIndicatorCardGroup";
import { ReportIndicatorCard } from "./components/ReportIndicatorCard";

import { ReportMainPage } from "./components/ReportMainPage";
import { axiosWithToken, functionBaseUrl } from "../../../../common/firebase";
import moment from "moment";
import { Tooltip } from "@material-ui/core";
import { testData } from "./components/test";
import { ReportIndicatorCardSequencing } from "./components/ReportIndicatorCardSequencing";
import Swal from "sweetalert2";

export const GeneratePdfButton = ({
  videoIds,
  userName,
  golfClub,
  gender,
  height,
}) => {
  const mainPageRef = useRef();
  const pageRefs = useRef([]);
  const [firstPageReportData, setFirstPageReportData] = useState(null);
  const [splitPages, setSplitPages] = useState([]);

  const [generatePdfState, setGeneratePdfState] = useState(0);

  const generatePDF = async (userNameForFileName) => {
    const pdf = new jsPDF("p", "mm", "a4");
    const pageWidth = 210; // A4 width in mm

    const newRefArr = [mainPageRef, ...pageRefs.current];

    //console.log(newRefArr, pageRefs);

    //newRefArr.forEach((el) => console.log(el));

    //console.log(newRefArr);

    const addPageToPDF = async (ref, isFirstPage) => {
      if (!ref.current) return; // Check if ref is valid
      const canvas = await html2canvas(ref.current, { scale: 2 });
      const imgData = canvas.toDataURL("image/png");
      const imgHeight = (canvas.height * pageWidth) / canvas.width;

      if (!isFirstPage) pdf.addPage();
      pdf.addImage(
        imgData,
        "JPEG",
        0,
        0,
        pageWidth,
        imgHeight,
        undefined,
        "FAST"
      );
    };

    for (let i = 0; i < newRefArr.length; i++) {
      //console.log(newRefArr[i]);
      await addPageToPDF(newRefArr[i], i === 0);
    }

    pdf.save(userNameForFileName + " - Swing Score Report.pdf");
  };

  const splitPagesRecursive = (page, blockLimit) => {
    const calculateBlockSize = (block) =>
      Math.ceil(block.indicatorGroup.length / 3);

    const processBlocks = (blocks) => {
      if (blocks.length === 0) {
        return [];
      }

      let currentPageBlocks = [];
      let blockCount = 0;

      for (let i = 0; i < blocks.length; i++) {
        const blockName = blocks[i].name;

        const blockSize = calculateBlockSize(blocks[i]);

        if (blockCount + blockSize > blockLimit) {
          return [
            {
              ...page,
              indicatorGroups: currentPageBlocks,
            },
            ...processBlocks(blocks.slice(i)),
          ];
        }

        currentPageBlocks.push(blocks[i]);
        blockCount += blockName === "Sequencing" ? 1 : blockSize;
      }

      return [
        {
          ...page,
          indicatorGroups: currentPageBlocks,
        },
      ];
    };

    return processBlocks(page.indicatorGroups);
  };

  const splitAllPages = (pagesTest, blockLimit) => {
    return pagesTest.flatMap((page) => splitPagesRecursive(page, blockLimit));
  };

  const sortIndicatorCardsBasedOnIndicatorGroup = (groupName, block) => {
    const parseIndicatorArray = (indicatorNameSubstrings) => {
      const arr = [];
      for (const indName of indicatorNameSubstrings) {
        const val = block.indicatorGroup.find((el) =>
          el.name.includes(indName)
        );
        if (val) arr.push(val);
      }

      return arr;
    };

    switch (groupName) {
      case "Rotational ROM":
        return parseIndicatorArray(["Chest", "Pelvis", "X-Factor"]);
      case "Horizontals":
        return parseIndicatorArray(["Chest", "Pelvis"]);
      case "Verticals":
        return parseIndicatorArray(["Drop", "Lift"]);
      case "Rotational Speed":
        return parseIndicatorArray(["Pelvis", "Chest", "Arm", "Shaft"]);
      case "Release":
        return parseIndicatorArray(["Angle", "Release", "Gain"]);
      case "Contributions":
        return parseIndicatorArray(["Legs", "Core", "Shoulder", "Wrist"]);
      case "Gain Factors":
        return parseIndicatorArray(["Core", "Shoulder", "Wrist", "Release"]);
      case "Address":
        return parseIndicatorArray([
          "Chest Turn",
          "Pelvis Turn",
          "Chest Bend",
          "Chest Side Bend",
          "Pelvis Side Bend",
        ]);
      case "Top of Backswing":
        return parseIndicatorArray([
          "Chest Turn",
          "Pelvis Turn",
          "Chest Bend",
          "Chest Side Bend",
          "Pelvis Side Bend",
          "Sway Gap",
          "Chest Sway",
          "Pelvis Sway",
          "Chest Lift",
          "Pelvis Lift",
          "Hand Sway",
          "Hand Lift",
        ]);
      case "Transition":
        return parseIndicatorArray(["Pelvis", "Chest", "Arm", "Tempo"]);
      case "Impact":
        return parseIndicatorArray([
          "Chest Turn",
          "Pelvis Turn",
          "Chest Bend",
          "Chest Side Bend",
          "Pelvis Side Bend",
          "Sway Gap",
          "Chest Sway",
          "Pelvis Sway",
          "Chest Lift",
          "Pelvis Lift",
          "Hand Sway",
          "Hand Lift",
        ]);
      default:
        return block.indicatorGroup;
    }
  };

  const renderPages = () => {
    return splitPages.map((el, i) => (
      <ReportPageTemplate
        key={`page-${i}`}
        pageRef={pageRefs.current[i]}
        club={"Driver"}
        date={moment().format("MM/DD/YYYY")}
        firstData={el.firstData}
        secondData={el.secondData}
        page={i + 2}
        totalPages={splitPages.length + 1}
        pageName={el.pageName}
        userName={userName}
        indicatorBlocks={el.indicatorGroups.map((block, blockIndex) => (
          <ReportIndicatorCardGroup
            key={`block-${blockIndex}`}
            groupName={block.name}
            percentage={
              block.avgPercentile ||
              block.avgConsistencyScore ||
              block.avgEfficiencyScore ||
              0
            }
            cards={
              block.name === "Sequencing"
                ? [
                    <ReportIndicatorCardSequencing
                      percentage={100}
                      indicatorName="Transition Order"
                      indicatorsArray={block.indicatorGroup}
                      valueToBaseColorOn={el.transitionOrderSequenceScore}
                    />,
                    <ReportIndicatorCardSequencing
                      percentage={100}
                      indicatorName="Pelvis Movement Order"
                      indicatorsArray={block.indicatorGroup}
                      valueToBaseColorOn={el.movementSequenceOrderScore}
                    />,
                  ]
                : sortIndicatorCardsBasedOnIndicatorGroup(
                    block.name,
                    block
                  ).map((indicator, cardIndex) => {
                    return (
                      <ReportIndicatorCard
                        showAdditionalData={
                          el.pageName === "Consistency Report" ||
                          block.name === "Sequencing"
                            ? false
                            : true
                        }
                        showIcons={
                          el.pageName === "Consistency Report" ? false : true
                        }
                        key={`indicator-${cardIndex}`}
                        indicatorName={indicator.name}
                        percentage={indicator.percentileRank}
                        indicatorValue={
                          indicator.value
                          // el.pageName === "Consistency Report"
                          //   ? indicator.zScore.zScore
                          //   : indicator.value
                        }
                        tourRange={`${(
                          indicator.ranges.indicatorRange -
                          indicator.ranges.indicatorStDeviation
                        ).toFixed(1)} to ${(
                          indicator.ranges.indicatorRange +
                          indicator.ranges.indicatorStDeviation
                        ).toFixed(1)}`}
                        zScore={indicator?.zScore?.zScore}
                        color={indicator?.zScore?.color?.j2_1.toLowerCase()}
                        unit={indicator.unit}
                      />
                    );
                  })
            }
          />
        ))}
      />
    ));
  };

  const fetchDataForGeneration = async (
    videoIdsForRequest,
    golfClubForRequest,
    genderForRequest,
    heightForRequest
  ) => {
    try {
      const data = await axiosWithToken(
        //"http://localhost:5000/sportsbox-3dgolf/us-central1/api/swing-library/pdf-report",
        functionBaseUrl + "/api/swing-library/pdf-report",
        {
          method: "post",
          data: {
            videoIds: videoIdsForRequest,
            gender: genderForRequest,
            height: heightForRequest,
            golfClub: golfClubForRequest,
          },
        }
      );
      // const data = { data: testData };

      setFirstPageReportData({
        descriptionSections: data.data.reportData,
        speedScore: data.data.speedData.speedPercentileScore,
        efficiencyScore: data.data.efficiencyData.efficiencyScore,
        consistencyScore: data.data.consistencyData.consistencyScore,
        clubSpeed: data.data.speedData.speedIndicatorValue,
      });

      const split = splitAllPages(
        [
          {
            ...data.data.speedData,
            firstData: {
              value: data.data.speedData.speedPercentileScore.toFixed(0),
              name: "Speed Score",
            },
            secondData: {
              value: `${data.data.speedData.speedIndicatorValue.toFixed(
                0
              )} mph`,
              name: "Avg Club Head Speed",
            },
            pageName: "Speed Report",
            indicatorGroups: [
              data.data.speedData.indicatorGroups.find(
                (el) => el.name === "Rotational ROM"
              ),
              data.data.speedData.indicatorGroups.find(
                (el) => el.name === "Horizontals"
              ),
              data.data.speedData.indicatorGroups.find(
                (el) => el.name === "Verticals"
              ),
              data.data.speedData.indicatorGroups.find(
                (el) => el.name === "Rotational Speed"
              ),
              data.data.speedData.indicatorGroups.find(
                (el) => el.name === "Release"
              ),
            ],
          },
          {
            ...data.data.efficiencyData,
            firstData: {
              value: data.data.efficiencyData.efficiencyScore.toFixed(0),
              name: "Efficiency Score",
            },
            pageName: "Efficiency Report",
            indicatorGroups: [
              data.data.efficiencyData.indicatorGroups.find(
                (el) => el.name === "Contributions"
              ),
              data.data.efficiencyData.indicatorGroups.find(
                (el) => el.name === "Gain Factors"
              ),
              data.data.efficiencyData.indicatorGroups.find(
                (el) => el.name === "Sequencing"
              ),
              data.data.efficiencyData.indicatorGroups.find(
                (el) => el.name === "Height Factor"
              ),
            ].filter((el) => el.indicatorGroup.length),
          },
          {
            ...data.data.consistencyData,
            firstData: {
              value: data.data.consistencyData.consistencyScore.toFixed(0),
              name: "Consistency Score",
            },
            secondData: {
              value: `#${videoIds.length}`,
              name: "Swings Taken",
            },
            pageName: "Consistency Report",
            indicatorGroups: [
              data.data.consistencyData.indicatorGroups.find(
                (el) => el.name === "Address"
              ),
              data.data.consistencyData.indicatorGroups.find(
                (el) => el.name === "Top of Backswing"
              ),
              data.data.consistencyData.indicatorGroups.find(
                (el) => el.name === "Transition"
              ),
              data.data.consistencyData.indicatorGroups.find(
                (el) => el.name === "Impact"
              ),
            ],
          },
        ],
        7
      );
      setSplitPages(split);

      pageRefs.current = split.map(() => React.createRef());
      return true;
    } catch (err) {
      const errorMessage =
        err?.response?.data?.message ||
        err?.message ||
        "An unexpected error occurred. Please try again later.";

      Swal.fire({
        title: '<p style="font-size:70%;">' + errorMessage + "</p>",
        icon: "error",
        confirmButtonText: "Ok",
        allowOutsideClick: false,
        customClass: {
          container: "my-swal",
        },
      });
      return false;
    }
  };

  const startPdfGenerationPipeline = useCallback(async () => {
    setGeneratePdfState(1);
    const success = await fetchDataForGeneration(
      videoIds,
      golfClub,
      gender,
      height
    );
    if (success) {
      setGeneratePdfState(2);
    } else {
      setGeneratePdfState(0);
    }
  }, [videoIds, golfClub, gender, height, pageRefs]);

  const startPdfDownload = useCallback(async () => {
    setGeneratePdfState(3);
    await generatePDF(userName);
    setGeneratePdfState(0);
  }, [userName]);

  const getSwingType = (string) => {
    const match = string.match(/<b><u>(.*?)<\/u><\/b>/);
    const firstItem = match ? match[1] : null;

    return firstItem;
  };

  return (
    <>
      {!videoIds && !userName && !golfClub && !gender && !height && (
        <Tooltip title="You need to select more than 2 swings with the same golf club and student">
          <button
            onClick={() => ""}
            disabled={true}
            style={{
              padding: "12px 16px",
              fontSize: "16px",
              fontWeight: "600",
              fontFamily: "Manrope",
              cursor: "pointer",
              backgroundColor: "#F2F4F7",
              border: "none",
              borderRadius: "64px",
              cursor: "not-allowed",
            }}
          >
            {generatePdfState === 0
              ? "Prepare PDF Data"
              : generatePdfState === 1
              ? "Preparing PDF Data"
              : generatePdfState === 2
              ? "Download PDF"
              : "Downloading PDF"}
          </button>
        </Tooltip>
      )}

      {videoIds && userName && golfClub && gender && height && (
        <button
          onClick={
            generatePdfState === 0
              ? startPdfGenerationPipeline
              : generatePdfState === 2
              ? startPdfDownload
              : () => ""
          }
          disabled={generatePdfState === 1 || generatePdfState === 3}
          style={{
            padding: "12px 16px",
            fontSize: "16px",
            fontWeight: "600",
            fontFamily: "Manrope",
            cursor: "pointer",
            backgroundColor: "#F2F4F7",
            border: "none",
            borderRadius: "64px",
            cursor:
              generatePdfState === 1 || generatePdfState === 3
                ? "not-allowed"
                : "pointer",
            display: "flex",
            alignItems: "center",
            gap: "10px",
          }}
        >
          {generatePdfState === 1 || generatePdfState === 3 ? (
            <img height={16} src="images/loading.gif" alt="loading" />
          ) : (
            ""
          )}
          {generatePdfState === 0
            ? "Prepare PDF Data"
            : generatePdfState === 1
            ? "Preparing PDF Data"
            : generatePdfState === 2
            ? "Download PDF"
            : "Downloading PDF"}
        </button>
      )}

      <div
        style={{ position: "absolute", left: "5000px", fontFamily: "Manrope" }}
      >
        {firstPageReportData && (
          <ReportMainPage
            club={golfClub}
            date={moment().format("DD MMM, YYYY")}
            overallScore={(
              (firstPageReportData.speedScore +
                firstPageReportData.consistencyScore +
                firstPageReportData.efficiencyScore) /
              3
            ).toFixed(0)}
            userName={userName}
            pageRef={mainPageRef}
            totalPages={splitPages.length + 1}
            speedScore={firstPageReportData.speedScore.toFixed(0)}
            clubSpeed={`${firstPageReportData.clubSpeed.toFixed(0)} mph`}
            efficiencyScore={firstPageReportData.efficiencyScore.toFixed(0)}
            consistencyScore={firstPageReportData.consistencyScore.toFixed(0)}
            descriptionSections={firstPageReportData.descriptionSections}
            swingsNumber={videoIds.length}
            swingType={getSwingType(
              firstPageReportData.descriptionSections[0].template
            )}
          />
        )}

        {renderPages()}
      </div>
    </>
  );
};
