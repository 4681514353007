import React, { useEffect, useState } from "react";

import { Grid } from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import Swal from "sweetalert2";
import moment from "moment";
import axios from "axios";
import "./promoCheckout.css";
import { functionBaseUrl } from "../../common/firebase";
import { StripePayment } from "../../components/stripePayment";

function PaymentComponent({ email, product, finishPayment }) {

  const createUser = async (data) => {
    let referralCode = window.location.href;
    if (referralCode.includes("REFERRALCODE=")) {
      referralCode = referralCode.split("REFERRALCODE=")[1];
    } else {
      referralCode = "";
    }
    try {
      await axios(functionBaseUrl + "/api/offerUser", {
        method: "POST",
        data: {
          email: email,
          name: data?.userDetails?.name,
          product: product,
          address: data?.userDetails?.address
        },
      });
      finishPayment();
    } catch (e) {
      Swal.fire({
        title:
          '<p style="font-size:70%;">There was an error processing your subscription, please try again.</p>',
        icon: "error",
      });
    }
  };

  return (
    <>
      <div className="offer-payment">
        <StripePayment 
          amount={product === "gpod" ? 100 : 14.39} 
          handleResult={createUser}
          name={""} 
          email={email}
        />
        <div className="offer-payment-disclaimer">
          You will be charged $15.99{" "}
          when your promo period ends and it will automatically renew after{" "}
          {product === "gpod"
            ? "3 months"
            : "1 month"}{" "}
          unless you update your renewal settings.
        </div>
      </div>
    </>
  );
}

export const Payment = PaymentComponent;
