import React from 'react';

import { Grid, Button } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import moment from 'moment';

import { getPlan, getPlanPrice } from '../../common/plans';


function PlansSummaryComponent({ plan, bootcampUser, pay, freeTrial, back, subData, addOn }) {

  return (<>
    <Grid container>
      <Grid
        item
        xs={12}
        md={2}
      />
      <Grid
        item
        xs={12}
        md={8}
      >
        <Grid container>
          <Grid
            item
            xs={12}
            className="plans-step-back"
          >
            {/*<a href="#" onClick={() => back()}>
              <ArrowBack />
            </a>*/}
          </Grid>
          <Grid
            item
            xs={12}
            className="plans-summary-caption"
          >
            Summary
          </Grid>
          <Grid
            item
            xs={12}
            className="plans-summary-row"
          >
            <span>Plan</span>
            {bootcampUser ? 'Bootcamp' : getPlan(plan)}
          </Grid>
          {!plan.includes('free') && <Grid
            item
            xs={12}
            className="plans-summary-row"
          >
            <span>Amount</span>
            ${bootcampUser ? '400' : addOn ? getPlanPrice(plan) + (plan.includes('monthly') ? 40 : 400) : getPlanPrice(plan)}
          </Grid>}
          {!plan.includes('free') && <Grid
            item
            xs={12}
            className="plans-summary-row"
          >
            <span>Billed</span>
            {bootcampUser ? 'One-Time' : plan?.includes('annually') ? 'Annually' : 'Monthly'}
          </Grid>}
          <Grid
            item
            xs={12}
            className="plans-summary-row"
          >
            <span>Payment Date</span>
            {(bootcampUser === true || plan.includes('free') || subData?.freeTrialUsed === true || !freeTrial) ? moment().format('MM/DD/YYYY') : moment().add(14, "d").format('MM/DD/YYYY')}
          </Grid>
          <Grid
            item
            xs={12}
          >
            <Button
              className="plan-button"
              onClick={() => pay()}
            >
              Continue
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        md={2}
      />
    </Grid>
  </>);
}

export default PlansSummaryComponent;