import React, { useEffect, useState } from "react";

import s from "./styles.module.css";
import { DashboardButton } from "./DashboardButton";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Brightness1Icon from "@material-ui/icons/Brightness1";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";

import { Popover, Switch } from "@material-ui/core";
import { listOfSubscriptionNames } from "../../../common/envConfig";

export const DashboardStudentsTable = ({
  tableOptions,
  onPagination,
  onSort,
  data,
  total,
  onRemove,
  onRemovePending,
  onRenew,
  onRenewSwitch,
}) => {
  const [popover, setPopover] = useState({ id: "", anchorEl: null });

  const totalToPages = (() => {
    const calc = total / 10;
    const fin = calc < 1 ? 1 : Math.ceil(calc);

    const pagesArr = [];
    for (let i = 1; i <= fin; i++) {
      pagesArr.push(i);
    }

    return pagesArr;
  })();

  const handlePopoverOpen = (event, id) => {
    setPopover({ anchorEl: event.currentTarget, id: id });
  };

  const getSortDirection = (field) => {
    if (tableOptions.sort === field) {
      if (tableOptions.order === "desc")
        return <ArrowDownwardIcon style={{ fontSize: "14px" }} />;
      else return <ArrowUpwardIcon style={{ fontSize: "14px" }} />;
    } else return "";
  };

  //useEffect(() => console.log(data), [data]);

  const handleNextPreviousPagination = (type) => {
    if (type === "next") {
      if (tableOptions.page < totalToPages.length) {
        onPagination(tableOptions.page + 1);
      }
    } else {
      if (tableOptions.page > 1) {
        onPagination(tableOptions.page - 1);
      }
    }
  };

  return (
    <div className={s["table-container"]}>
      <table className={s["styled-table"]}>
        <thead>
          <tr>
            <th></th>
            <th onClick={() => onSort("firstName")}>
              Name {getSortDirection("firstName")}
            </th>
            <th>Plan</th>
            <th onClick={() => onSort("daysLeft")}>
              Days left {getSortDirection("daysLeft")}
            </th>
            <th>Email address</th>
            <th>Sessions</th>
            <th>Status</th>
            <th>Auto-renew</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {data.map((el) => {
            return (
              <tr key={el.id}>
                <td>
                  <DashboardButton
                    text={el?.plan?.includes('free') ? "Upgrade" : "Renew"}
                    color="blue"
                    onClick={() => onRenew(el)}
                  />
                </td>
                <td>{el.name}</td>
                <td>{listOfSubscriptionNames[el.plan]}</td>
                <td>
                  <span
                    className={
                      el.daysLeft <= 7 ? s.tableDaysLeft : s.tableDaysLeftGreen
                    }
                  >
                    {el.daysLeft <= 7 ? (
                      <ErrorOutlineIcon style={{ fontSize: "17px" }} />
                    ) : (
                      <Brightness1Icon style={{ fontSize: "12px" }} />
                    )}
                    {Math.ceil(el.daysLeft)} days
                  </span>
                </td>

                <td>{el.email}</td>
                <td>{el.sessions}</td>
                <td>
                  <span
                    className={
                      el.status ? s.tableStatusSuccess : s.tableStatusPending
                    }
                  >
                    {el.status ? "Accepted" : "Pending"}
                  </span>
                </td>
                <td>
                  {el.toAutoRenew ? (
                    <Switch
                      checked={el.autoRenew}
                      onChange={(e) =>
                        onRenewSwitch(el.subId, e.target.checked)
                      }
                    />
                  ) : (
                    ""
                  )}
                </td>
                <td>
                  <div onClick={(e) => handlePopoverOpen(e, el.id)}>
                    <MoreVertIcon style={{ cursor: "pointer" }} />
                  </div>

                  <Popover
                    style={{
                      borderRadius: "10px",
                    }}
                    id={`${el.id}-popover`}
                    open={el.id === popover.id}
                    anchorEl={popover.anchorEl}
                    onClose={() => setPopover("")}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                  >
                    <div
                      style={{
                        border: "1px solid lightgray",
                      }}
                    >
                      <div
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          if (el.status) {
                            onRemove(el.id);
                          } else {
                            onRemovePending(el.id);
                          }
                        }}
                      >
                        Remove student
                      </div>
                    </div>
                  </Popover>
                </td>
              </tr>
            );
          })}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan="9">
              <div className={s.pagination}>
                <button
                  onClick={() => handleNextPreviousPagination("previous")}
                  className={s["pagination-button"]}
                  disabled={tableOptions.page === 1}
                >
                  <ArrowBackIcon /> Previous
                </button>
                <div className={s["pagination-items"]}>
                  {totalToPages.map((el) => {
                    return (
                      <div
                        className={
                          tableOptions.page === el ? s.activePage : s.page
                        }
                        onClick={() => onPagination(el)}
                      >
                        {el}
                      </div>
                    );
                  })}
                </div>
                <button
                  onClick={() => handleNextPreviousPagination("next")}
                  className={s["pagination-button"]}
                  disabled={tableOptions.page === totalToPages.length}
                >
                  Next <ArrowForwardIcon />
                </button>
              </div>
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
};
