import { Button } from "@material-ui/core";
import React, { useEffect } from "react";
import { Pad } from "../../../components";
import { FreeUsersStats } from "./FreeUsersStats";
import { PaidUsersStats } from "./PaidUsersStats";
import { RevenueStats } from "./RevenueStats";
import { StatisticsContainer } from "./StatisticsContainer";
import { StudentList } from "./StudentList";

export function CoachReportData({ data, onBack }) {
  return (
    <Pad width="100%">
      <StatisticsContainer>
        <FreeUsersStats data={data.freeUsersStats} />
        <PaidUsersStats data={data.paidUsersStats} />
        <RevenueStats data={data.revenueStats} />
      </StatisticsContainer>
      <StudentList data={data.students} />
      <Button onClick={onBack}>Back</Button>
    </Pad>
  );
}
