import React from "react";
import { ButtonV2 } from "../../../../components/redesign-components/ButtonV2/ButtonV2";
import s from "./style.module.css";
import CloseIcon from "@material-ui/icons/Close";

export const IntroductionCard = ({ onClose }) => {
  return (
    <div className={s.outerContainer}>
      <div className={s.contentContainer}>
        <img src="/images/logos/Logo.png" width="50" height="50" />
        <div className={s.textContainer}>
          <div>Introducing 3D Studio ✨</div>
          <div>
            We’re launching a brand new Windows software to bring 3D to your
            indoor studio next season.
          </div>
          <a
            href="https://sportsbox-21571110.hs-sites.com/3dstudio"
            target="_blank"
          >
            <ButtonV2 text="Sign up for the waitlist" color="purple" fit />
          </a>
        </div>
      </div>
      <CloseIcon onClick={onClose} />
    </div>
  );
};

export const ForesightCard = ({ onClose }) => {
  return (
    <div className={s.outerContainer}>
      <div
        style={{ justifyContent: "space-between", width: "100%" }}
        className={s.contentContainer}
      >
        {/* <img src="/images/logos/Logo.png" width="50" height="50" /> */}
        <div className={s.textContainer}>
          <div>New Foresight Integration</div>
          <div>Have a Foresight device? Then this is for you</div>
          {/* <a
            href="https://sportsbox-21571110.hs-sites.com/3dstudio"
            target="_blank"
          >
            <ButtonV2 text="Check it out" color="purple" fit />
          </a> */}
          <a href={`/account?view=plan`}>
            <ButtonV2 text="Check it out" color="purple" fit />
          </a>
        </div>
      </div>
      <div className={s.secondContainer}>
        <img
          src="/images/logos/foresight_integration.svg"
          width="200"
          height="100"
        />
        <div style={{ cursor: "pointer" }}>
          <CloseIcon onClick={onClose} />
        </div>
      </div>
    </div>
  );
};
