import React from 'react';
import { withStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  TextField,
  InputAdornment,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Typography,
  Dialog,
  DialogContent,
} from '@material-ui/core';
import {Link as RouterLink} from 'react-router-dom';
import MuiPhoneNumber from "material-ui-phone-number";
import firebase, { functionBaseUrl, axiosWithToken } from '../../../../common/firebase';
import 'firebase/firestore';
import Swal from 'sweetalert2';
import moment from 'moment';
import axios from 'axios';
import { StripeUpdatePayment } from '../../../../components/stripeUpdatePayment';
import withReactContent from 'sweetalert2-react-content';
import { ENVIRONMENT } from '../../../../common/envConfig';
const MySwal = withReactContent(Swal);

const useStyles = () => ({
  root: {},
  number: {borderColor: "#ffffff",}
});

class AccountDetails extends React.Component {
  constructor (props) {
    super(props)
    const currUser = firebase.auth().currentUser;

    this.state = {
      firstName : "",
      email : currUser.email,
      dominantHand : "",
      lastName : "",
      userName : "",
      dob : "",      
      height : "",
      hipSize : "",
      weight : "",
      disability : "",
      gender : "", 
      uid : currUser.uid,
      roles : [],
      role : "",
      subscriptionType : "",
      address : "",
      //addressId : this.props.user.addressId,
      popUpOpen : false,
      requestedRole : "",
      roleRequest : false,
      popUpLoading : false,
      street_number: "",
      route: "",
      locality: "",
      administrative_area_level_1: "",
      country: "",
      postal_code: "",
      subscriptionId: "",
      subStatus: "Not Active",
      subData: {},
      plan: "",
      promoCode: "",
      last4: "",
      brand: "",
      openUpdateCard: false,
    }
  }

  componentDidMount() {
    if (this.state.uid) {
      this.setState({
        firstName: this.props.user.firstName || "",
        userName: this.props.user.userName || "",
        email: this.props.user.email || "",
        dominantHand: this.props.user.dominantHand || "",
        lastName: this.props.user.lastName || "",
        dob: this.props.user.dob || "",      
        height: this.props.user.height || "",
        hipSize: this.props.user.hipSize || "",
        weight: this.props.user.weight || "",
        disability: this.props.user.disability || "",
        gender: this.props.user.gender || "", 
        uid: this.props.user.uid || "", 
        role: this.props.user.role || "",
        subscriptionType: this.props.user.subscriptionType || "",
        street_number: this.props.user.street_number || "",
        route: this.props.user.route || "",
        locality: this.props.user.locality || "",
        administrative_area_level_1: this.props.user.administrative_area_level_1 || "",
        country: this.props.user.country || "",
        postal_code: this.props.user.postal_code || "",
        contactNumber: this.props.user.contactNumber || "",
        //addressId: this.props.user.addressId || "",
      })
    }
    if (!this.props.user.subscriptionType.includes('free')) {
      this.subscriptionStatus()
    }
    const query = new URLSearchParams(window.location.search);

    if (query.get("session_id")) {
      this.getSession(query.get("session_id"));
    }
  }

  getSession = async (sessionId) => {
    try {
      const response = await axios(`${functionBaseUrl}/api/stripeSession/${sessionId}`);
      const setupIntent = response.data.setup_intent;
      await axios(`${functionBaseUrl}/api/stripeUpdatePaymentMethod`, {
        method: 'POST',
        data: { email: this.state.email, setupIntent },
      });
      Swal.fire({
        title: '<p style="font-size:70%;">Card details successfully updated</p>',
        icon: 'success',
        confirmButtonText: 'Ok',
        allowOusideClick: false,
        timer: 3000,
        customClass: {
          container : 'my-swal'
        },
      });
    } catch (error) {
      console.error(error);
      Swal.fire({
        title: '<p style="font-size:70%;">Error updating card details</p>',
        icon: 'warning',
        confirmButtonText: 'Ok',
        allowOutsideClick: false,
        timer: 3000,
        customClass: {
          container : 'my-swal'
        },
      });
    }
  }

  handleChange = event => {
    this.setState({      
      [event.target.name]: event.target.value
    });    
  }

  handleContactChange = (event) => {
    this.setState({ contactNumber: event });
  }

  handlePromoCodeChange = (prop) => (event) => {
    this.setState({[prop]: event.target.value});
  }

  titleCase = (str) => {
    if (str) {
      str = str.replaceAll('-', ' ');
      var splitStr = str.toLowerCase().split(' ');
      for (var i = 0; i < splitStr.length; i++) {
          splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);     
      }
      return splitStr.join(' '); 
    } else {
      return ""
    }
  }

  getPlanDescription = (productId) => {
    if (productId) {
      productId = productId.replaceAll('_', ' ');
      var splitStr = productId.toLowerCase().split(' ');
      for (var i = 0; i < splitStr.length; i++) {
          splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);     
      }
      return splitStr.join(' '); 
    } else {
      return ""
    }
  }

  subscriptionStatus = async () => {
    try {
      const response = await axiosWithToken(functionBaseUrl + '/api/verifySub/' + this.state.uid, {
        method: 'post'
      });

      this.setState({
        //transactionId: 'transactionId' in response.data.data !== undefined ? response.data.data.transactionId : "",
        subscriptionId: 'id' in response.data.data !== undefined ? response.data.data.id : "",
        subscriptionType: 'productId' in response.data.data ? response.data.data.productId : "",
        subStatus: response.data.success,
        subData: JSON.stringify(response.data.data) !== undefined ? response.data.data : {}
      })

      if (response?.data?.data?.platform == 'Stripe') {
        const responseMethods = await axios(functionBaseUrl + '/api/stripePaymentMethods', {
          method: 'POST',
          data: { email: this.state.email },
        })
          .catch(err => {
            console.log(err);
          });
        if (responseMethods?.data?.paymentMethods?.data?.length > 0) {
          this.setState({ brand: responseMethods.data.paymentMethods.data[0].card.brand.toUpperCase(), last4: responseMethods.data.paymentMethods.data[0].card.last4 });
        }
      }
    } catch (err) {
      console.log(err)
    };


    await axiosWithToken(functionBaseUrl+'/api/verifySub/' + this.state.uid, {
      method: 'post'
    }).then(response => {
      this.setState({
        subscriptionId: 'id' in response.data.data !== undefined ? response.data.data.id : "",
        subscriptionType: 'productId' in response.data.data ? response.data.data.productId : "",
        subStatus: response.data.success,
        subData: JSON.stringify(response.data.data) !== undefined ? response.data.data : {}
      })
    })
    .catch(err => {
      console.log(err)
    });
  }

  checkSubStatus = () => {
    if (this.state.subscriptionType.includes('free')) {
      return "Active"
    }
    if (this.state.subStatus === true) {
      return "Active"
    } else {
      return "Not Active"
    }
  }

  currentPlan = () => {
    if (this.state.subscriptionType.includes('student_lite')) {
      if (this.state.subscriptionType.includes('monthly')) {
        return '3D Player Monthly';
      } else {
        return '3D Player Annually';
      }
    } else if (this.state.subscriptionType.includes('pro_lite')) {
      if (this.state.subscriptionType.includes('monthly')) {
        return '3D Pro Monthly';
      } else {
        return '3D Pro Annually';
      }
    } else if (this.state.subscriptionType.includes('pro_premium')) {
      if (this.state.subscriptionType.includes('monthly')) {
        return '3D Pro Monthly';
      } else {
        return '3D Pro Annually';
      }
    } else if (this.state.subscriptionType.includes('enterprise_master')) {
      if (this.props.isStaffEnterprise) {
        return 'Enterprise Staff'
      } else {
        return 'Enterprise Master';
      }
    } else if (this.state.subscriptionType.includes('enterprise_coach')) {
      return 'Enterprise Coach';
    } else {
      return "Free";
    }
  }

  checkAmount = () => {
    let amount = ""
    if (JSON.stringify(this.state.subData) !== undefined && JSON.stringify(this.state.subData) !== '{}') {
      if (this.state.subData.amount) {
        amount = this.state.subData.amount
      }
    }
    return amount
  }

  checkAutoRenewal = () => {
    if (this.state.subscriptionType.includes('free')) {
      return ""
    }
    if (JSON.stringify(this.state.subData) !== undefined && JSON.stringify(this.state.subData) !== '{}') {
      if (this.state.subData.autoRenewal) {
        return "On"
      }
    }
    return "Off"
  }

  checkSubPlatform = () => {
    let platform = ""
    if (JSON.stringify(this.state.subData) !== undefined && JSON.stringify(this.state.subData) !== '{}') {
      if (this.state.subData.platform) {
        platform = this.state.subData.platform
      }
    }
    return platform
  }

  getSubEndDate = () => {
    let date = "";
    if (JSON.stringify(this.state.subData) !== undefined && JSON.stringify(this.state.subData) !== '{}') {
      if (this.state.subData.endDate) {
        date = moment(String(this.state.subData.endDate), "x").format("MM/DD/YYYY");
      }
    }
    return date;
  }

  cancelStripeSubscription = () => {
    Swal.fire({
      title: '<p style="font-size:70%;">Are you sure you want to cancel your subscription?</p>',
      icon: 'warning',
      confirmButtonText: 'Yes',
      showCancelButton: true,
      cancelButtonText: 'No',
      cancelButtonColor: 'red',
      allowOutsideClick: false,
      customClass : { 
        container : 'my-swal' 
      },
    }).then((result) => {
      if (result.isConfirmed) {
        axiosWithToken(functionBaseUrl + '/api/cancelStripeSub/' + this.state.uid, {
          method: 'POST',
        })
          .then(() => {
            Swal.fire({
              title: '<p style="font-size:70%;">Subscription successfully canceled</p>',
              icon: 'success',
              confirmButtonText: 'Ok',
              allowOutsideClick: false,
              customClass : { 
                container : 'my-swal' 
              },
            }).then((result) => {
              if (result.isConfirmed) {
                window.location.reload()
              }
            })
          })
          .catch(err => {
            console.log(err);
          });
      } else {
        return false
      }
    })
  }

  pauseStripeSubscription = () => {
    Swal.fire({
      title: '<p style="font-size:70%;">Are you sure you want to cancel your subscription?</p>',
      icon: 'warning',
      confirmButtonText: 'Yes',
      showCancelButton: true,
      cancelButtonText: 'No',
      cancelButtonColor: 'red',
      allowOutsideClick: false,
      customClass : { 
        container : 'my-swal' 
      },
    }).then((result) => {
      if (result.isConfirmed) {
        axiosWithToken(functionBaseUrl + '/api/pauseStripeSub/' + this.state.uid, {
          method: 'POST',
        })
          .then(() => {
            Swal.fire({
              title: '<p style="font-size:70%;">Subscription successfully canceled</p>',
              icon: 'success',
              confirmButtonText: 'Ok',
              allowOutsideClick: false,
              customClass : { 
                container : 'my-swal' 
              },
            }).then((result) => {
              if (result.isConfirmed) {
                window.location.reload()
              }
            })
          })
          .catch(err => {
            console.log(err);
            Swal.fire({
              title: '<p style="font-size:70%;">Error canceling subscription</p>',
              icon: 'error',
              confirmButtonText: 'Ok',
              allowOutsideClick: false,
              timer: 3000,
            });
          });
      } else {
        return false
      }
    })
  }

  resumeStripeSubscription = async () => {
    await Swal.fire({
      title: '<p style="font-size:70%;">Are you sure you want to resume your subscription?</p>',
      icon: 'warning',
      confirmButtonText: 'Yes',
      showCancelButton: true,
      cancelButtonText: 'No',
      cancelButtonColor: 'red',
      allowOutsideClick: false,
      customClass : { 
        container : 'my-swal' 
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        await axiosWithToken(functionBaseUrl + '/api/resumeStripeSub/' + this.state.uid, {
          method: 'POST',
        })
          .then(async () => {
            await Swal.fire({
              title: '<p style="font-size:70%;">Subscription successfully resumed</p>',
              icon: 'success',
              confirmButtonText: 'Ok',
              allowOutsideClick: false,
              customClass : { 
                container : 'my-swal' 
              },
            }).then((result) => {
              if (result.isConfirmed) {
                window.location.reload()
              }
            })
          })
          .catch(err => {
            console.log(err);
          });
      } else {
        return false
      }
    })
  }

  cancelButton = async () => {
    await Swal.fire({
      title: '<p style="font-size:70%;">Are you sure you want to cancel your subscription?</p>',
      icon: 'warning',
      confirmButtonText: 'Yes',
      showCancelButton: true,
      cancelButtonText: 'No',
      cancelButtonColor: 'red',
      allowOutsideClick: false,
      customClass : { 
        container : 'my-swal' 
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        await axiosWithToken(functionBaseUrl+'/api/cancelElavonTxn/' + this.state.uid, {
          method: 'POST',
        })
        .then(async () => {
          await Swal.fire({
            title: '<p style="font-size:70%;">Subscription successfully canceled</p>',
            icon: 'success',
            confirmButtonText: 'Ok',
            allowOutsideClick: false,
            timer: 2000,
            customClass : { 
              container : 'my-swal' 
            },
          }).then(() => {
            window.location.reload()
          })
        })
        .catch(err => {
          console.log(err);
        });
      } else {
        return false
      }
    })
  }

  resumeButton = async () => {
    await Swal.fire({
      title: '<p style="font-size:70%;">Are you sure you want to resume your subscription?</p>',
      icon: 'warning',
      confirmButtonText: 'Yes',
      showCancelButton: true,
      cancelButtonText: 'No',
      cancelButtonColor: 'red',
      allowOutsideClick: false,
      customClass : { 
        container : 'my-swal' 
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        await axiosWithToken(functionBaseUrl + '/api/activateElavonTxn', {
          method: 'POST',
          data: {
            "ssl_recurring_id": this.state.subData.transactionId,
            "ssl_billing_cycle": this.state.subData.amount > 200 ? "ANNUALLY" : "MONTHLY",
            "ssl_next_payment_date": this.changeDateFormat(this.state.subData.startDate, this.state.subData.endDate)
          },
          headers: {
            'Content-Type': 'application/json'
          }
        })
        .then(async response => {
          await axiosWithToken(functionBaseUrl+'/api/subscription/' + this.state.subscriptionId, {
            method: 'patch',
            data: { autoRenewal: true }
          }).then(() => {
            Swal.fire({
              title: '<p style="font-size:70%;">Subscription successfully activated</p>',
              confirmButtonText: 'Ok',
              allowOutsideClick: false,
              icon: 'success',
              timer: 2000,
              customClass : { 
                container : 'my-swal' 
              },
            }).then(() => {
              window.location.reload()
            })
          })
          .catch(err => {
            console.log(err)
          });
        })
        .catch(err => {
          console.log(err)
          Swal.fire('<p style="font-size:70%;">There was an error activating your subscription, please try again</p>')
        });
      } else {
        return false
      }
    })
  }

  addPromoCode = async () => {
    await MySwal.fire({
      title: '<p style="font-size:80%;">Add a Promo Code</p>',
      allowOutsideClick: false,
      confirmButtonText: 'Add',
      showCloseButton: true,
      allowOutsideClick: false,
      showCancelButton: true,
      customClass : { 
        container : 'my-swal' 
      },
      html:
        <div style={{ maxWidth: '90%', margin: '0 auto', marginTop: '5%' }}>
          <Grid container spacing={2}>
            <Grid
              item
              sm
              xs={12}
            >
              <TextField
                fullWidth
                required
                label="Promo Code"
                onChange={this.handlePromoCodeChange('promoCode')}
                defaultValue={this.state.promoCode.toUpperCase().replace(/ /g, '')}
                variant="outlined"
                inputProps={{ maxLength: 50 }}
              />
            </Grid>
          </Grid>
        </div>
    }).then(async (response) => {
      if (response.isConfirmed) {
        if (this.state.promoCode.length === 0) {
          Swal.fire({
            title: '<p style="font-size:70%;">Please enter a Promo Code</p>',
            icon: 'warning',
            confirmButtonText: 'Ok',
            allowOutsideClick: true
          })
          return false;
        }

        await axiosWithToken(functionBaseUrl + '/api/stripeCoupon/' + this.state.promoCode.toUpperCase().replace(/ /g, ''), {
          method: 'GET',
        })
          .then(response => {
            if (response.data.valid) {
              
            }
          })
          .catch(err => {
            console.log(err)
            Swal.fire({
              title: '<p style="font-size:70%;">' + this.state.promoCode + ' is not a valid promo code!</p>',
              icon: 'error',
              confirmButtonText: 'Ok',
              allowOutsideClick: false,
            })
          })

        /*await axiosWithToken(functionBaseUrl + '/api/stripeSub', {
          method: 'PATCH',
          data: {
            subscriptionId: this.state.subData.transactionId,
            body: {
              coupon: this.state.promoCode.toUpperCase().replace(/ /g, '')
            }
          }
        })
          .then(response => {
            Swal.fire({
              title: '<p style="font-size:70%;">Promo code was successfully applied!</p>',
              icon: 'success',
              confirmButtonText: 'Ok',
              allowOutsideClick: false,
              timer: 2000
            }).then(async () => {
              console.log(response?.data?.discount?.coupon?.name)
              if (response?.data?.discount?.coupon?.name) {
                await firebase.firestore().collection('subscriptions').doc(this.state.subData.id).update({ promoCode: response?.data?.discount?.coupon?.name })
              }
              //window.location.reload();
            })
          })
          .catch(err => {
            console.log(err)
            Swal.fire({
              title: '<p style="font-size:70%;">' + this.state.promoCode + ' is not a valid promo code!</p>',
              icon: 'error',
              confirmButtonText: 'Ok',
              allowOutsideClick: false,
            })
          })*/
      }
      this.setState({ promoCode: "" });
      return false;
    })
  }

  getEndDate = (sub) => {
    let date = ''
    if (sub.productId.includes('free')) {
      date = ''
    } else if (sub.productId.includes('monthly')) {
      date = String((Number(sub.endDate) + 86400000 * 31))
    } else {
      date = String((Number(sub.endDate) + 86400000 * 365))
    }
    return date
  }

  changeDateFormat = (startDate, endDate) => {
    let today
    if(new Date(Number(startDate) + 86400000 * 7) > Date.now()) {
      today = new Date(Number(startDate) + 86400000 * 7)
    } else {
      today = new Date(Number(endDate))
    }
    let dd = today.getDate();
    let mm = today.getMonth() + 1; 
    let yyyy = today.getFullYear();
    if(dd < 10) {
        dd = '0' + dd;
    } 
    if(mm < 10) {
        mm = '0' + mm;
    } 
    today = mm + '/' + dd + '/' + yyyy;
    return today
  }

  handleSubmit = (event) => {
    event.preventDefault()
    if (!this.state.firstName.trim()) {
      Swal.fire('<p style="font-size:70%;">Please enter valid first name</p>');
      return;
    }
    if (!this.state.lastName.trim()) {
      Swal.fire('<p style="font-size:70%;">Please enter valid last name</p>');
      return;
    }
    axiosWithToken(functionBaseUrl+'/api/users/'+ this.state.uid, {
      method: 'patch',
      data: {    
        userInfo: {    
          firstName: this.state.firstName,
          userName: this.state.userName,
          email: this.state.email,
          lastName: this.state.lastName,              
          height: this.state.height,
          hipSize: this.state.hipSize,
          //weight: this.state.weight,
          gender: this.state.gender,        
          //dob: this.state.dob,
          dominantHand: this.state.dominantHand,
          //disability: this.state.disability,
          //role: this.state.role,
          contactNumber: this.state.contactNumber
        }
      }
    })
    .then(() => {
      Swal.fire({
        title: '<p style="font-size:70%;">Your changes have been saved!</p>',
        icon: 'success',
        confirmButtonText: 'Ok',
        allowOutsideClick: false,
        timer: 2000
      }).then(() => {
        window.location.reload()
      })
    })
    .catch(err => {        
      alert('There was an error updating your info')
      console.log(err);
    });
  }

  render () {
    const { classes } = this.props
    return (
      <div>
        <Card style={{maxWidth:400}}>
          <form
            autoComplete="off"
            //noValidate
            onSubmit = {this.handleSubmit}
          >
            <CardHeader
              subheader="This information can be edited"
              title="Profile"
              titleTypographyProps={{variant:"h5", color:"primary"}}
            />
            <Divider />
            <CardContent>
              <Grid
                container
                spacing={1}
              >
                <Grid
                  item
                  md={12}
                  xs={12}
                >
                  <TextField  
                    style={{width:"50%"}}              
                    label="First name"
                    margin="dense"
                    name="firstName"
                    id = "firstName"
                    onChange={this.handleChange}
                    required
                    variant="outlined"
                    value={this.state.firstName}
                  />
                  <TextField                
                    style={{width:"50%"}}              
                    label="Last name"
                    margin="dense"
                    name="lastName"
                    onChange={this.handleChange}
                    required                
                    variant="outlined"
                    value={this.state.lastName}
                  />              
                </Grid>           
                <Grid
                  item
                  md={12}
                  xs={12}
                >
                  <Grid
                  item
                  md={12}
                  xs={12}
                  >
                    <TextField            
                      label="DOB (MM/DD/YYYY)"            
                      name="dob"
                      disabled
                      style={{width: "50%"}}
                      margin="dense"
                      onChange={this.handleChange}
                      required
                      //type="date"                
                      variant="outlined"
                      value={this.state.dob}
                      InputLabelProps={{
                        shrink: true,
                      }}                
                    />
                    <MuiPhoneNumber
                      style={{width: "50%"}}
                      //fullWidth
                      margin="dense"
                      label="Phone Number"
                      name="contactNumber"
                      defaultCountry={'us'}
                      onChange={this.handleContactChange}
                      value={this.state.contactNumber}
                      variant="outlined"
                      className={classes.number}
                    />
                  </Grid>
                  <Grid
                  item
                  md={12}
                  xs={12}
                  >              
                    <FormControl              
                    variant="outlined"
                    style={{width: "50%"}}
                    margin="dense"
                    >
                      <InputLabel id="gender-select">Gender</InputLabel>
                      <Select
                        id="gender"
                        label="Gender"
                        labelId="gender-select"
                        name="gender"
                        value={this.state.gender.toUpperCase()}
                        onChange={this.handleChange}
                      >
                        <MenuItem value={'MALE'}>Male</MenuItem>
                        <MenuItem value={'FEMALE'}>Female</MenuItem>
                        <MenuItem value={'PREFER NOT TO ANSWER'}>Prefer not to answer</MenuItem>
                      </Select>
                    </FormControl>
                    <FormControl              
                    variant="outlined"
                    style={{width:"50%"}}
                    margin="dense"
                    >
                      <InputLabel id="dominantHand-select">Dominant Hand</InputLabel>
                      <Select
                        id="dominantHand"
                        label="Dominant Hand"
                        labelId="dominantHand-select"
                        name='dominantHand'
                        value={this.state.dominantHand}
                        onChange={this.handleChange}
                      >
                        <MenuItem value={'Right'}>Right</MenuItem>
                        <MenuItem value={'Left'}>Left</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>               
                </Grid>
                <Grid
                  item
                  md={12}
                  xs={12}
                >
                  <div>
                    <TextField                
                      label={"Height (e.g. 5'5\")"}
                      margin="dense"
                      name="height"
                      style={{width:"50%"}}
                      onChange={this.handleChange}
                      variant="outlined"
                      value={this.state.height}
                    />
                    <TextField                
                      label={"Hip Width (Inches)"}
                      margin="dense"
                      name="hipSize"
                      style={{width:"50%"}}
                      onChange={this.handleChange}
                      variant="outlined"
                      InputProps={{
                        endAdornment: <InputAdornment position="end">in</InputAdornment>,
                      }}
                      value={this.state.hipSize}
                    />         
                    {/*<TextField                
                      label="Weight"
                      margin="dense"
                      name="weight"
                      style={{width:"50%"}}
                      onChange={this.handleChange}
                      variant="outlined"
                      InputProps={{
                        endAdornment: <InputAdornment position="end">lbs</InputAdornment>,
                      }}
                      value={this.state.weight}
                    />*/}             
                  </div>
                </Grid>
                {/*<Grid
                  item
                  md={12}
                  xs={12}
                >
                  <div>
                    <TextField                
                      label={"Hip Width (Inches)"}
                      margin="dense"
                      name="hipSize"
                      style={{width:"50%"}}
                      onChange={this.handleChange}
                      variant="outlined"
                      InputProps={{
                        endAdornment: <InputAdornment position="end">mm</InputAdornment>,
                      }}
                      value={this.state.hipSize}
                    />               
                  </div>
                </Grid>*/}                 
              </Grid>
            </CardContent>      
            <CardActions>
              <Button
                color="primary"
                variant="outlined"
                type="submit"
                //onClick={this.handleSave}
              >
                Save details
              </Button>
            </CardActions>
          </form>
          
          <CardContent>
            <Divider/>
            <CardHeader
              title="Subscription"
              titleTypographyProps={{variant: "h5", color: "primary"}}
            />
            <Typography
              color="textSecondary"
              variant="body1"
              marginTop="theme.spacing(1)"
              >
                <b>Status:</b> {" " + this.checkSubStatus()}
            </Typography>
            <Typography
              color="textSecondary"
              variant="body1"
              marginTop="theme.spacing(1)"
              >
                <b>Current Plan:</b> {" " + this.currentPlan()}
            </Typography>
            {!this.state.subscriptionType.includes('free') && (
              <div>
                <Typography
                color="textSecondary"
                variant="body1"
                marginTop="theme.spacing(1)"
                >
                  <b>Amount:</b> {" $" + this.checkAmount()}
              </Typography>
                <Typography
                  color="textSecondary"
                  variant="body1"
                  marginTop="theme.spacing(1)"
                  //marginBottom="theme.spacing(1)"
                  >
                    <b>Auto Renewal:</b> {" " + this.checkAutoRenewal()}
                </Typography>
                <Typography
                  color="textSecondary"
                  variant="body1"
                  marginTop="theme.spacing(1)"
                  >
                    <b>Subscription Platform:</b> {" " + this.checkSubPlatform()}
                    {this.state.brand && this.state.last4 && (
                      <> ({this.state.brand} •••• {this.state.last4})</>
                    )}
                </Typography>
                {ENVIRONMENT === "DEVELOPMENT" && !this.state.subscriptionType.includes('free') && this.state.subData.platform === 'Stripe' && this.state.subData.transactionId !== '' && (
                  <>
                    <div>
                      <Button
                        style={{ marginTop: '10px' }}
                        variant="contained"
                        color="primary"
                        onClick={() => { this.setState({ openUpdateCard: true }) }}
                      >
                        Update Card
                      </Button>
                    </div>
                    <Dialog
                      fullWidth
                      open={this.state.openUpdateCard}
                    >
                      <StripeUpdatePayment subscriptionId={this.state.transactionId} onClose={() => { this.setState({ openUpdateCard: false }) }} />
                    </Dialog>
                  </>
                )}

                <Typography
                  color="textSecondary"
                  variant="body1"
                  marginTop="theme.spacing(1)"
                  marginBottom="theme.spacing(1)"
                  >
                    <b>Subscription End Date:</b> {" " + this.getSubEndDate()}
                </Typography>
                {(this.state.subData.platform === 'Apple' || this.state.subData.platform === 'Android') ?
                  <Typography
                    color="textSecondary"
                    variant="body1"
                    marginTop="theme.spacing(1)"
                    >
                    <p>To cancel your subscription, please go to the platform that you originally subscribed from</p>
                  </Typography> : <div></div>
                }
              </div>
            )}
            <CardActions>
            {!this.state.subscriptionType.includes('enterprise') && (
              <div>
                <RouterLink to="/plans">
                  <Button fullWidth variant="contained" color="primary">Plans</Button>
                </RouterLink>
              </div>
            )}
            </CardActions>
            <CardActions>
              {(this.state.subscriptionType !== '' && !this.props.isStaffEnterprise && !this.state.subscriptionType.includes('free') && this.state.subData.autoRenewal && this.state.subData.platform === 'Elavon' && this.state.subData.transactionId !== '') && (
                <Button fullWidth variant="contained" color="secondary" onClick={() => this.cancelButton()}>Cancel Subscription</Button>
              )}
              {(this.state.subscriptionType !== '' && !this.props.isStaffEnterprise && !this.state.subscriptionType.includes('free') && this.state.subData.autoRenewal === false && this.state.subData.platform === 'Elavon' && this.state.subData.transactionId !== '') && (
                <Button fullWidth variant="contained" color="secondary" onClick={() => this.resumeButton()}>Resume Subscription</Button>
              )}
              {this.state.subData.autoRenewal && this.state.subData.platform === 'Stripe' && this.state.subData.transactionId !== '' && (<>
                {/*<div>
                  <Button fullWidth color="primary" variant="outlined" onClick={() => this.cancelStripeSubscription()}>Cancel Subscription</Button>
                </div>*/}
                <Button color="primary" variant="outlined" onClick={() => this.pauseStripeSubscription()}>Cancel Subscription</Button>
              </>)}
              {!this.state.subscriptionType.includes('enterprise') && !this.state.subData.autoRenewal && this.state.subData.platform === 'Stripe' && this.state.subData.endDate > Date.now() && this.state.subData.transactionId !== '' && (<>
                <Button color="primary" variant="outlined" onClick={() => this.resumeStripeSubscription()}>Resume Subscription</Button>
              </>)}
              {/*this.state.subData.platform === 'Stripe' && this.state.subData.status === 'active' && this.state.subData.endDate > Date.now() && (<>
                <Button color="primary" variant="outlined" onClick={() => this.addPromoCode()}>Add a Promo Code</Button>
              </>)*/}
            </CardActions>
          </CardContent>
        </Card>
      </div>
    );
  };
}

export default withStyles(useStyles)(AccountDetails);