import React, { useState } from "react";

import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  TextField,
  FormControlLabel,
  Checkbox,
  Link,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Swal from "sweetalert2";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import firebase, {
  functionBaseUrl,
  axiosWithToken,
} from "../../../common/firebase";

import PersonAddIcon from "@material-ui/icons/PersonAdd";
import { InputV2 } from "../../../components/redesign-components/InputV2/InputV2";
import { makeStyles } from "@material-ui/styles";
import { ButtonV2 } from "../../../components/redesign-components/ButtonV2/ButtonV2";
import {
  listOfSubscriptionNames,
  listOfSubscriptions,
} from "../../../common/envConfig";
import { SelectV2 } from "../../../components/redesign-components/SelectV2/SelectV2";

function AddStudioCoachComponent({ open, close }) {
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [dob, setDOB] = useState("");
  const [secondarySubType, setSecondarySubType] = useState("");
  const [agree, setAgree] = useState(false);
  const [isUserExists, setIsUserExists] = useState(false);

  const handleEmail = async (name, value) => {
    setEmail(value);
    setIsUserExists(false);
    const userSnap = await firebase
      .firestore()
      .collection("users")
      .where("email", "==", value)
      .get();
    userSnap.forEach((snap) => {
      const user = snap.data();
      setFirstName(user.firstName);
      setLastName(user.lastName);
      let [month, day, year] = user.dob.split("/");
      setDOB(`${year}-${month}-${day}`);
      if (user.secondarySubType) setSecondarySubType(user.secondarySubType);
      setIsUserExists(true);
    });
  };
  const handleFirstName = (name, value) => {
    setFirstName(value);
  };
  const handleLastName = (name, value) => {
    setLastName(value);
  };
  const handleDOB = (name, value) => {
    setDOB(value.substring(0, 10));
  };
  const handleSecondarySubType = (name, value) => {
    setSecondarySubType(value);
  };
  const handleAgree = (e) => {
    setAgree(!agree);
  };
  const dobFormat = (dob) => {
    let [year, month, day] = dob.split("-");
    return month + "/" + day + "/" + year;
  };
  const validEmail = (email) => {
    const regex = /\S+@\S+\.\S+/;
    return regex.test(email);
  };

  const listOfSecondarySubscriptions = [
    { text: "None", value: "" },
    { text: "Free Plan", value: listOfSubscriptions.STUDENT_FREE },
    {
      text: "3D Player",
      value: listOfSubscriptions.STUDENT_LITE_ANNUALLY,
    },
    { text: "3D Pro", value: listOfSubscriptions.PREMIUM_ANNUALLY },
  ];

  const handleCreate = async () => {
    if (!isUserExists) {
      if (!email || !firstName || !lastName || !dob) {
        close();
        Swal.fire({
          title:
            '<p style="font-size:70%;">Please fill in all required fields</p>',
          confirmButtonText: "Ok",
          icon: "warning",
          allowOutsideClick: false,
          customClass: {
            container: "my-swal",
          },
        });
        return;
      }
      if (!validEmail(email)) {
        close();
        Swal.fire({
          title: '<p style="font-size:70%;">Please enter a valid Email</p>',
          confirmButtonText: "Ok",
          icon: "warning",
          allowOutsideClick: false,
          customClass: {
            container: "my-swal",
          },
        });
        return;
      }
      if (dob.length > 10) {
        close();
        Swal.fire({
          title: '<p style="font-size:70%;">Incorrect date of birth</p>',
          confirmButtonText: "Ok",
          icon: "warning",
          allowOutsideClick: false,
          customClass: {
            container: "my-swal",
          },
        });
        return;
      }
    }
    const options = { year: "numeric", month: "numeric", day: "numeric" };
    const date = new Date(new Date().setFullYear(new Date().getFullYear() - 13))
      .toLocaleDateString("en-ZA", options)
      .replaceAll("/", "-");
    if (Date.parse(dob) > Date.parse(date)) {
      close();
      Swal.fire({
        title:
          '<p style="font-size:70%;">Sorry, this user is not eligible for a Sportsbox.ai account at this time.</p>',
        confirmButtonText: "Ok",
        icon: "warning",
        allowOutsideClick: false,
        customClass: {
          container: "my-swal",
        },
      }).then(() => {
        window.location.reload();
      });
      return false;
    }
    try {
      const firebaseUser = firebase.auth().currentUser;
      const uid = firebaseUser.uid;
      const currUser = await axiosWithToken(
        `${functionBaseUrl}/api/users/${uid}`,
        {
          method: "GET",
        }
      ).catch((err) => {
        console.log(err);
      });
      const userData = currUser?.data;
      await axiosWithToken(`${functionBaseUrl}/api/studioCoach`, {
        method: "POST",
        data: {
          email: email.toLowerCase().replace(/ /g, ""),
          firstName: firstName,
          lastName: lastName,
          studioAccountId: userData?.studioAccountId,
          dob: dobFormat(dob),
          secondarySubType: secondarySubType,
        },
      }).then((response) => {
        if (response.status === 201) {
          Swal.fire({
            title:
              '<p style="font-size:70%;">' + response.data.message + "</p>",
            icon: "warning",
            confirmButtonText: "Ok",
            allowOutsideClick: false,
            customClass: {
              container: "my-swal",
            },
          });
        } else {
          close();
          setEmail("");
          setFirstName("");
          setLastName("");
          setSecondarySubType("");
          setDOB("");
          setAgree(false);
          setIsUserExists(false);

          Swal.fire({
            title:
              '<p style="font-size:70%;">Account successfully created for ' +
              email +
              "</p>",
            icon: "success",
            confirmButtonText: "Ok",
            allowOutsideClick: false,
            customClass: {
              container: "my-swal",
            },
          });
        }
      });
    } catch (e) {
      close();
      Swal.fire('<p style="font-size:70%;">Error occurred</p>');
    }
  };

  return (
    <div>
      <Dialog
        fullWidth
        maxWidth="xs"
        open={open}
        onClose={close}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id={"form-dialog-title"}>
          <div
            style={{
              padding: "10px 10px 0px 10px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: "40px",
                height: "40px",
                pading: "5px",
                border: "1px solid #EAECF0",
                borderRadius: "5px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img src="/images/icons/add-user.png" />
            </div>
            <CloseIcon
              style={{
                float: "right",
                marginTop: "-10px",
                marginRight: "-15px",
              }}
              onClick={close}
            />
          </div>
        </DialogTitle>
        <DialogContent>
          <div>
            <div style={{ fontWeight: "600", fontSize: "18px" }}>Add coach</div>
            <div
              style={{ fontWeight: "500", fontSize: "14px", color: "#475467" }}
            >
              Enter your coach’s information.
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              marginTop: "10px",
            }}
          >
            <InputV2
              label="First Name"
              onChange={handleFirstName}
              value={firstName}
            />
            <InputV2
              label="Last Name"
              onChange={handleLastName}
              value={lastName}
            />
            <InputV2
              label="Email"
              icon={<MailOutlineIcon />}
              onChange={handleEmail}
              value={email}
            />
            <InputV2
              label="Date Of Birth"
              icon={<CalendarTodayIcon />}
              onChange={handleDOB}
              value={dob}
              name="dob"
              type="date"
            />
            <SelectV2
              name="secondarySubscription"
              label="Secondary Subscription"
              value={secondarySubType}
              options={listOfSecondarySubscriptions}
              onChange={handleSecondarySubType}
            />
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    value={agree}
                    onClick={handleAgree}
                    checked={agree ? "checked" : null}
                  />
                }
                label={
                  <div>
                    <span>I have read and agree to Sportsbox's </span>
                    <Link
                      onClick={(e) => {
                        e.preventDefault();
                        window.open(
                          window.location.href.replace(
                            "studio-coaches",
                            "terms-of-service"
                          )
                        );
                      }}
                    >
                      Terms of Service
                    </Link>
                    <span> and </span>
                    <Link
                      onClick={(e) => {
                        e.preventDefault();
                        window.open(
                          window.location.href.replace(
                            "studio-coaches",
                            "privacy"
                          )
                        );
                      }}
                    >
                      Privacy Policy
                    </Link>
                  </div>
                }
              />
            </Grid>
          </div>
        </DialogContent>
        <DialogActions style={{ padding: "25px" }}>
          <ButtonV2 onClick={close} color="gray" text="Cancel" />

          <ButtonV2
            color="purple"
            text="Create"
            onClick={handleCreate}
            disabled={agree === false}
          />
        </DialogActions>
      </Dialog>
    </div>
  );
}

export const AddStudioCoach = AddStudioCoachComponent;
