import React from 'react';

import { Stepper, Step, StepLabel } from '@material-ui/core';

import './offer.css';


function CTASignUpComponent() {

  return (<>
    <Stepper activeStep={2} className="cta-mobile-stepper">
      <Step key={1} completed={false}>
        <StepLabel></StepLabel>
      </Step>
      {/*<Step key={2} completed={false} className="inactive">
        <StepLabel></StepLabel>
      </Step>*/}
      <Step key={2} completed={false} className="inactive">
        <StepLabel></StepLabel>
      </Step>
    </Stepper>
    <div className="cta-caption">
      Unlock your <span>exclusive</span>
      Sportsbox offer
    </div>
    <div className="cta-subcaption">
      With Sportsbox's 3D Player app, you now have a launch
      monitor for your body anytime you practice. Validate your
      swing on your own with 3D analysis, and superpower your
      learning with hundreds of immersive guided practice
      experiences.
    </div>
  </>);
}

export const CTASignUp = CTASignUpComponent;