import React, { useState } from 'react';

import {
  FormControl, InputLabel, OutlinedInput, InputAdornment, IconButton, Checkbox, FormControlLabel, Link, Button,
  CircularProgress, Container, TextField, Grid, Typography
} from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Swal from 'sweetalert2';
import axios from 'axios';

import './speedAssessment.css';
import firebase, { functionBaseUrl, axiosWithToken } from '../../common/firebase';
import { listOfSubscriptions } from '../../common/envConfig';
import { useHistory } from "react-router-dom";

function SignUpComponent({ finishSignUp }) {
  const history = useHistory();
  const [userFirstName, setUserFirstName] = useState('');
  const [userLastName, setUserLastName] = useState('');
  const [email, setEmail] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [tosAgree, setTosAgree] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const sbCoach = "coach.human@sportsbox.ai";
  const aiCoach = "coach.ai@sportsbox.ai";

  const user = firebase.auth().currentUser;
  if (user && localStorage.getItem("showAlert") === "true") {
    axiosWithToken(`${functionBaseUrl}/api/users/${user.uid}`, {
      method: 'GET',
    }).then((userResponse) => {
      if (!userResponse?.data?.subscriptionType?.includes('student')) {
        Swal.fire({
          title: '<p style="font-size:70%;">This offer is only applicable to Player plans.</p>',
          icon: "warning",
          showCancelButton: false,
          confirmButtonText: "Ok",
          allowOutsideClick: false,
        })
        return
      }
      axiosWithToken(`${functionBaseUrl}/api/sa_users`, {
        method: 'GET',
      }).then(async (countResponse) => {
        if (window.location.href.includes("/speed-assessment-human")) {
          if (!countResponse?.data?.human > 50) {
            await Swal.fire({
              title: '<p style="font-size:70%;">This offer is sold out, would you like to join the waitlist?</p>',
              icon: "warning",
              showCancelButton: true,
              confirmButtonText: "Yes",
              allowOutsideClick: false,
            }).then(async (result) => {
              if (result.isConfirmed) {
                await axios(functionBaseUrl + "/api/updateUserNY/" + user.uid, {
                  method: "POST",
                  data: {
                    userInfo: window.location.href.includes("/speed-assessment-human") ? 
                      {
                        speedAssessmentHuman: ""
                      } 
                      :
                      {
                        speedAssessmentAI: ""
                      }
                  }
                }).catch((err) => {
                  console.log(err);
                });
              }
            })
            return
          }
        } else {
          if (!countResponse?.data?.ai > 50) {
            await Swal.fire({
              title: '<p style="font-size:70%;">This offer is sold out, would you like to join the waitlist?</p>',
              icon: "warning",
              showCancelButton: true,
              confirmButtonText: "Yes",
              allowOutsideClick: false,
            }).then(async (result) => {
              if (result.isConfirmed) {
                await axios(functionBaseUrl + "/api/updateUserNY/" + user.uid, {
                  method: "POST",
                  data: {
                    userInfo: window.location.href.includes("/speed-assessment-human") ? 
                      {
                        speedAssessmentHuman: ""
                      } 
                      :
                      {
                        speedAssessmentAI: ""
                      }
                  }
                }).catch((err) => {
                  console.log(err);
                });
              }
            })
            return
          }
        }
      }).catch((err) => {
        console.log(err);
      });
      if (userResponse?.data?.coaches?.includes(sbCoach) || userResponse?.data?.coaches?.includes(aiCoach)) {
        Swal.fire({
          title: '<p style="font-size:70%;">This offer is only applicable to new students, you are an existing student of the coach.</p>',
          icon: "warning",
          showCancelButton: false,
          confirmButtonText: "Ok",
          allowOutsideClick: false,
        })
        return
      }
      
      Swal.fire({
        title: '<p style="font-size:70%;">Would you like to accept this offer?</p>' +
        '<p style="font-size:50%;">You are logged in as <b>' + user.email + '</b></p>',
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        allowOutsideClick: false,
      }).then(async (result) => {
        if (result.isConfirmed) {
          setIsLoading(false);
          localStorage.removeItem("showAlert");
          finishSignUp(user.uid, user.email.toLowerCase().replace(/ /g, ""), userResponse?.data?.userName);
        }
      })
      localStorage.removeItem("showAlert");
    })
  }

  const checkUserAcc = async () => {
    try {
      return await axios(functionBaseUrl + '/api/userInAuth', {
        method: 'POST',
        data: {
          email: email.toLowerCase().replace(/ /g, '')
        }
      })
        .then(res => {
          if (res.status === 200) {
            return false;
          } else {
            return true;
          }
        })
        .catch(err => {
          console.log(err)
        });
    } catch (err) {
      console.log(err)
    }
  }

  const validatePassword = (password) => {
    const verify = /^(?=.*\d)(?=.*[.!@#$%^&*])(?=.*[a-z]).{8,}$/;
    return verify.test(password);
  }

  const validateEmail = (email) => {
    const verify = /\S+@\S+\.\S+/;
    return verify.test(email);
  }

  const dobFormat = (dob) => {
    let [year, month, day] = dob.split('-');
    return (month + "/" + day + "/" + year)
  }

  const handleSignUp = async () => {
    if (!userFirstName) {
      Swal.fire({
        title: '<p style="font-size:70%;">Please enter your First Name</p>',
        icon: 'warning',
        confirmButtonText: 'Ok',
        allowOutsideClick: true
      })
      return;
    }
    if (!userLastName) {
      Swal.fire({
        title: '<p style="font-size:70%;">Please enter your Last Name</p>',
        icon: 'warning',
        confirmButtonText: 'Ok',
        allowOutsideClick: true
      })
      return;
    }
    if (!email) {
      Swal.fire({
        title: '<p style="font-size:70%;">Please enter your Email</p>',
        icon: 'warning',
        confirmButtonText: 'Ok',
        allowOutsideClick: true
      })
      return;
    }
    if (!validateEmail(email)) {
      Swal.fire({
        title: '<p style="font-size:70%;">Please enter a valid Email</p>',
        icon: 'warning',
        confirmButtonText: 'Ok',
        allowOutsideClick: true
      })
      return;
    }
    if (!dateOfBirth) {
      Swal.fire({
        title: '<p style="font-size:70%;">Please enter your Date of Birth</p>',
        icon: 'warning',
        confirmButtonText: 'Ok',
        allowOutsideClick: true
      })
      return;
    }
    if (!password) {
      Swal.fire({
        title: '<p style="font-size:70%;">Please enter Password</p>',
        icon: 'warning',
        confirmButtonText: 'Ok',
        allowOutsideClick: true
      })
      return;
    }
    if (!validatePassword(password)) {
      Swal.fire({
        title: '<p style="font-size:70%;">Password must contain:</p>',
        html: `
        <ul>
          <li>• At least 8 characters</li>
          <li>• At least one number</li>
          <li>• A special character (e.g., !@#$%&*)</li>
        </ul>
        `,
        icon: 'warning',
        confirmButtonText: 'Ok',
        allowOutsideClick: true
      })
      return;
    }
    if (!confirmPassword) {
      Swal.fire({
        title: '<p style="font-size:70%;">Please enter Password Confirmation</p>',
        icon: 'warning',
        confirmButtonText: 'Ok',
        allowOutsideClick: true
      })
      return;
    }
    if (password !== confirmPassword) {
      Swal.fire({
        title: '<p style="font-size:70%;">Passwords do not match</p>',
        icon: 'warning',
        confirmButtonText: 'Ok',
        allowOutsideClick: true
      })
      return;
    }
    setIsLoading(true);
    const noUsers = await checkUserAcc();
    if (noUsers) {
      await firebase.auth().createUserWithEmailAndPassword(email.toLowerCase().replace(/ /g, ''), password)
        .then(async creds => {
          await axiosWithToken(functionBaseUrl + '/api/userEmailVerf', {
            method: 'PATCH',
            data: {
              id: creds.user.uid,
              status: true
            }
          })

          await axios(functionBaseUrl + '/api/userMobile', {
            method: 'POST',
            data: {
              uid: creds.user.uid,
              firstName: userFirstName.replace(/ /g, ''),
              lastName: userLastName.replace(/ /g, ''),
              userName: userFirstName.replace(/ /g, '') + ' ' + userLastName.replace(/ /g, ''),
              email: email.toLowerCase().replace(/ /g, ''),
              averageScore: 0,
              goals: [],
              dob: dobFormat(dateOfBirth),
              height: '',
              hipSize: '7.2',
              gender: '',
              dominantHand: '',
              picture: '',
              role: '',
              weeklyPractices: 0,
              coachAddress: { city: "", state: "", country: "" },
              coachFacility: [{ name: "", address: "", students: 0, lessons: 0, environment: [] }],
              certification: [],
              userConsent: true,
              subscriptionType: listOfSubscriptions.STUDENT_FREE,
            }
          })

          const countResponse = await axiosWithToken(`${functionBaseUrl}/api/sa_users`, {
            method: 'GET',
          });

          if (window.location.href.includes("/speed-assessment-human")) {
            if (!countResponse?.data?.human > 50) {
              await Swal.fire({
                title: '<p style="font-size:70%;">This offer is sold out, would you like to join the waitlist?</p>',
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Yes",
                allowOutsideClick: false,
              }).then(async (result) => {
                if (result.isConfirmed) {
                  await axios(functionBaseUrl + "/api/updateUserNY/" + creds.user.uid, {
                    method: "POST",
                    data: {
                      userInfo: window.location.href.includes("/speed-assessment-human") ? 
                        { speedAssessmentHuman: "" } 
                        :
                        { speedAssessmentAI: "" }
                    }
                  }).then(() => {
                    Swal.fire({
                      title: '<p style="font-size:70%;">You have been added to the waitlist!</p>',
                      icon: "success",
                      confirmButtonText: "Ok",
                      timer: 3000,
                      allowOutsideClick: false,
                    }).then(() => {
                      window.location.reload();
                    });
                  }).catch((err) => {
                    console.log(err);
                  });
                }
              })
              return
            }
          } else {
            if (!countResponse?.data?.ai > 50) {
              await Swal.fire({
                title: '<p style="font-size:70%;">This offer is sold out, would you like to join the waitlist?</p>',
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Yes",
                allowOutsideClick: false,
              }).then(async (result) => {
                if (result.isConfirmed) {
                  await axios(functionBaseUrl + "/api/updateUserNY/" + creds.user.uid, {
                    method: "POST",
                    data: {
                      userInfo: window.location.href.includes("/speed-assessment-human") ? 
                        { speedAssessmentHuman: "" } 
                        :
                        { speedAssessmentAI: "" }
                    }
                  }).then(() => {
                    Swal.fire({
                      title: '<p style="font-size:70%;">You have been added to the waitlist!</p>',
                      icon: "success",
                      confirmButtonText: "Ok",
                      timer: 3000,
                      allowOutsideClick: false,
                    }).then(() => {
                      window.location.reload();
                    });
                  }).catch((err) => {
                    console.log(err);
                  });
                }
              })
              return
            }
          }

          setIsLoading(false);
          finishSignUp(creds.user.uid, email.toLowerCase().replace(/ /g, ''), userFirstName.replace(/ /g, '') + ' ' + userLastName.replace(/ /g, ''));
        })
        .catch(err => {
          setIsLoading(false);
          Swal.fire({
            title: '<p style="font-size:70%;">' + err.message + '</p>',
            icon: 'warning',
            confirmButtonText: 'Ok',
            allowOutsideClick: false
          })
          return;
        })
    } else {
      await axios(functionBaseUrl + '/api/checkUser', {
        method: 'POST',
        data: {
          email: email.toLowerCase().replace(/ /g, '')
        }
      })
        .then(res => {
          if (res.status === 200 && res.data && 'subscriptionType' in res.data === true && 
              (res.data.subscriptionType === '' || res.data.subscriptionType.includes('free'))) {
            setIsLoading(false);
            finishSignUp(res.data.id, email.toLowerCase().replace(/ /g, ''), res.data.userName);
          } else {
            setIsLoading(false);
            Swal.fire({
              title: '<p style="font-size:70%;">An account already exists with this email</p>',
              icon: 'error',
              confirmButtonText: 'Ok',
              allowOutsideClick: true
            })
          }
        })
        .catch(err => {
          setIsLoading(false);
          console.log(err)
        });
    }
  }

  return (<>
    {!isLoading && (
      <Grid
        container
        spacing={2}
        className="offer-signup"
      >
        <Grid
          item
          xs={12}
          className="offer-block-caption-wrapper"
        >
          <span className="offer-block-caption">Sign Up</span>
        </Grid>
        <Grid item xs={12} style={{ textAlign: "center" }}>
          <Button
            className="offer-button"
            variant="contained"
            color="primary"
            onClick={() => {
              if (window.location.href.includes("/speed-assessment-human")) {
                history.push("/sign-in?redirectTo=speed-assessment-human");
              } else {
                history.push("/sign-in?redirectTo=speed-assessment-ai"); 
              }
              localStorage.setItem("showAlert", "true");
            }}
          >
            Already have an account?
          </Button>
        </Grid>
        <Grid
          item
          xs={6}
        >
          <TextField
            fullWidth
            required
            className="offer-input"
            label="First Name"
            onChange={(event) => setUserFirstName(event.target.value)}
            value={userFirstName}
            variant="outlined"
            inputProps={{ maxLength: 254 }}
          />
        </Grid>
        <Grid
          item
          xs={6}
        >
          <TextField
            fullWidth
            required
            className="offer-input"
            label="Last Name"
            onChange={(event) => setUserLastName(event.target.value)}
            value={userLastName}
            variant="outlined"
            inputProps={{ maxLength: 254 }}
          />
        </Grid>
        <Grid
          item
          xs={12}
        >
          <TextField
            fullWidth
            required
            className="offer-input"
            label="Email Address"
            onChange={(event) => setEmail(event.target.value)}
            value={email}
            variant="outlined"
            inputProps={{ maxLength: 254 }}
          />
        </Grid>
        <Grid
          item
          xs={12}
        >
          <TextField
            InputLabelProps={{
              shrink: true,
            }}
            required
            fullWidth
            className="offer-input"
            variant="outlined"
            label="Date of Birth"
            onChange={(event) => setDateOfBirth(event.target.value)}
            type="date"
            value={dateOfBirth}
          />
        </Grid>
        <Grid
          item
          xs={12}
        >
          <FormControl
            fullWidth
            className="offer-input"
            variant="outlined"
          >
            <InputLabel htmlFor="outlined-adornment-password">Password *</InputLabel>
            <OutlinedInput
              label='Password'
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    edge="end"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
              required
              id="outlined-adornment-password"
              onChange={(event) => setPassword(event.target.value)}
              type={showPassword ? 'text' : 'password'}
              value={password}
            />
          </FormControl>
        </Grid>
        <Grid
          item
          xs={12}
        >
          <FormControl
            fullWidth
            className="offer-input"
            variant="outlined"
          >
            <InputLabel htmlFor="outlined-adornment-password">Confirm Password *</InputLabel>
            <OutlinedInput
              required
              id="checkPassword"
              label='Confirm Password'
              onChange={(event) => setConfirmPassword(event.target.value)}
              type={showPassword ? 'text' : 'password'}
              value={confirmPassword}
            />
          </FormControl>
          {confirmPassword.length > 0 && password !== confirmPassword &&
            <Typography style={{ color: "red" }}>
              The passwords do not match
            </Typography>
          }
        </Grid>
        <Grid
          item
          xs={12}
        >
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                value={tosAgree}
                onClick={() => setTosAgree(!tosAgree)}
                checked={tosAgree ? 'checked' : null}
              />
            }
            label={
              <div className="offer-text">
                <span>I have read and agree to Sportsbox's </span>
                <Link
                  className="offer-link"
                  onClick={async (e) => {
                    e.preventDefault();
                    if (window.location.href.includes("/speed-assessment-human")) {
                      window.open(window.location.href.replace('speed-assessment-human', 'terms-of-service'));
                    } else {
                      window.open(window.location.href.replace('speed-assessment-ai', 'terms-of-service'));
                    }
                  }}
                >
                  Terms of Service
                </Link> and <Link
                  className="offer-link"
                  onClick={async (e) => {
                    e.preventDefault();
                    if (window.location.href.includes("/speed-assessment-human")) {
                      window.open(window.location.href.replace('speed-assessment-human', 'privacy'));
                    } else {
                      window.open(window.location.href.replace('speed-assessment-ai', 'privacy'));
                    }
                  }}
                >
                  Privacy Policy.
                </Link>
              </div>
            }
          />
        </Grid>
        <Grid
          item
          xs={12}
          style={{ textAlign: 'center' }}
        >
          <Button
            className="offer-button"
            disabled={tosAgree === false || email.length === 0 || userFirstName.length === 0 ||
              userLastName.length === 0 || password.length === 0 || confirmPassword.length === 0}
            variant="contained"
            onClick={() => handleSignUp()}
          >
            Create An Account
          </Button>
        </Grid>
        <div className='gif'>
          {window.location.href.includes("speed-assessment-human") && <div className="cta-invitational">
            <img
              alt="Swing Analysis Offer"
              src="/images/offer/human_speed.gif"
              width={280}
            />
          </div>}
          {window.location.href.includes("speed-assessment-ai") && <div className="cta-invitational">
            <img
              alt="Swing Analysis Offer"
              src="/images/offer/ai_speed.gif"
              width={280}
            />
          </div>}
        </div>
      </Grid>
    )}
    {isLoading && (
      <Container maxWidth="xl" style={{ textAlign: 'center', padding: 200 }}>
        <CircularProgress />
      </Container>
    )}
  </>);
}

export const SignUp = SignUpComponent;
