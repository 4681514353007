import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Typography,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { functionBaseUrl, axiosWithToken } from "../../common/firebase";
import Swal from "sweetalert2";
import moment from "moment";
import { listOfSubscriptions } from "../../common/envConfig";

class AddCoupon extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      code: "",
      createdAt: "",
      expiredAt: "",
      type: "",
      discount: "",
      onlyForFirstTimeUsers: "No",
      limitedToAUser: "No",
      userEmail: "",
      redemptionLimit: "No",
      limit: "",
      applicability: ["all"],
    };
  }

  handleChange = (prop) => (event) => {
    this.setState({ [prop]: event.target.value });
  };

  handleApplicabilityChange = (prop) => (event) => {
    this.setState({
      [prop]:
        event.target.type === "checkbox"
          ? event.target.checked
          : event.target.value,
    });
  };

  getInitialState = () => {
    return {
      name: "",
      email: "",
      code: "",
      createdAt: "",
      expiredAt: "",
      type: "",
      discount: "",
      onlyForFirstTimeUsers: "",
      limitedToAUser: "",
      userEmail: "",
      redemptionLimit: "",
      limit: "",
      applicability: [],
    };
  };

  handleClear = () => {
    const confirmVal = window.confirm("Do you want to clear your query?");
    if (confirmVal) {
      this.setState(this.getInitialState());
    }
  };

  validEmail = (email) => {
    const regex = /\S+@\S+\.\S+/;
    return regex.test(email);
  };

  todaysDate = () => {
    return moment().format("MM/DD/YYYY");
  };

  handleSubmit = () => {
    const output = {
      code: this.state.code.toUpperCase().replace(/ /g, ""),
      expiredAt:
        this.state.expiredAt.length === 0
          ? String(new Date().setDate(new Date().getDate() + 1))
          : String(new Date(this.state.expiredAt).getTime()),
      type: this.state.type,
      discount: this.state.discount,
      onlyForFirstTimeUsers: this.state.onlyForFirstTimeUsers,
      limitedToAUser: this.state.limitedToAUser,
      userEmail: this.state.userEmail,
      redemptionLimit: this.state.redemptionLimit,
      limit: this.state.limit,
      applicability: this.state.applicability,
    };

    if (output.code.length === 0) {
      alert("Code is blank");
      return false;
    }
    if (output.expiredAt <= Date.now()) {
      alert("End date cannot be equal to or less than today");
      return false;
    }
    if (output.type.length === 0) {
      alert("Discount type is blank");
      return false;
    }
    if (output.discount.length === 0) {
      alert("Discount amount is blank");
      return false;
    }
    if (output.discount <= 0) {
      alert("Discount has to be greater than 0");
      return false;
    }
    if (output.limitedToAUser === "Yes") {
      if (output.userEmail.length === 0) {
        alert("User email is blank");
        return false;
      }
      if (!this.validEmail(output.userEmail)) {
        alert("Please enter a valid email");
        return false;
      }
    }
    if (output.redemptionLimit === "Yes") {
      if (output.limit.length === 0) {
        alert("Limit is blank");
        return false;
      }
      if (output.limit <= 0) {
        alert("Limit has to be greater than 0");
        return false;
      }
    }
    if (output.applicability.length === 0) {
      alert("Applicability is blank");
      return false;
    }

    axiosWithToken(functionBaseUrl + "/api/promoCode", {
      method: "post",
      data: output,
    })
      .then((response) => {
        this.setState({ loading: false });
        Swal.fire({
          title: '<p style="font-size:70%;">Coupon created successfully</p>',
          icon: "success",
          confirmButtonText: "Ok",
          allowOutsideClick: true,
          timer: 2000,
        }).then(() => {
          this.setState(this.getInitialState());
          window.location.reload();
        });
      })
      .catch((err) => {
        console.log(err);
      });

    this.props.onClose();
  };

  render() {
    return (
      <Dialog
        fullWidth
        open={this.props.open}
        onClose={this.props.onClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id={"form-dialog-title"}>
          <div>
            Create Coupon
            <CloseIcon
              style={{
                float: "right",
                marginTop: "-10px",
                marginRight: "-15px",
              }}
              onClick={this.props.onClose}
            />
          </div>
        </DialogTitle>
        <DialogContent>
          <Grid container component="main" maxWidth="xs">
            <Grid item sm={12}>
              <Typography variant="h5" color="primary">
                Coupon Details
              </Typography>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  required
                  label="Code"
                  name="code"
                  margin="dense"
                  onChange={this.handleChange("code")}
                  value={this.state.code}
                  variant="outlined"
                  inputProps={{ style: { textTransform: "uppercase" } }}
                />
              </Grid>
              <Grid item sm={6}>
                <TextField
                  fullWidth
                  id="expiredAt"
                  margin="dense"
                  label="End Date"
                  type="date"
                  defaultValue={this.todaysDate()}
                  onChange={this.handleChange("expiredAt")}
                  required
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={6}>
                <FormControl
                  variant="outlined"
                  style={{ width: "100%" }}
                  margin="dense"
                >
                  <InputLabel id="type-select">Discount Type*</InputLabel>
                  <Select
                    required
                    id="type"
                    label="Type"
                    labelId="type-select"
                    name="type"
                    value={this.state.type}
                    onChange={this.handleChange("type")}
                  >
                    <MenuItem value={"percentage"}>
                      Percentage Discount
                    </MenuItem>
                    <MenuItem value={"fixedAmount"}>
                      Fixed Amount Discount
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item sm={6}>
                <TextField
                  fullWidth
                  required
                  label="Discount Amount"
                  name="discount"
                  margin="dense"
                  type="number"
                  InputProps={{
                    inputProps: { min: 0 },
                  }}
                  onChange={this.handleChange("discount")}
                  value={this.state.discount}
                  variant="outlined"
                />
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item sm={6}>
                <FormControl
                  variant="outlined"
                  style={{ width: "100%" }}
                  margin="dense"
                >
                  <InputLabel id="limitedToAUser-select">
                    Limited To A User
                  </InputLabel>
                  <Select
                    required
                    id="limitedToAUser"
                    label="Limited To A User"
                    labelId="limitedToAUser-select"
                    name="limitedToAUser"
                    value={this.state.limitedToAUser}
                    onChange={this.handleChange("limitedToAUser")}
                  >
                    <MenuItem value={"Yes"}>Yes</MenuItem>
                    <MenuItem value={"No"}>No</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              {this.state.limitedToAUser === "Yes" ? (
                <Grid item sm={6}>
                  <TextField
                    fullWidth
                    label="User Email*"
                    name="userEmail"
                    margin="dense"
                    onChange={this.handleChange("userEmail")}
                    value={this.state.userEmail}
                    variant="outlined"
                  />
                </Grid>
              ) : (
                <div></div>
              )}
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={6}>
                <FormControl
                  variant="outlined"
                  style={{ width: "100%" }}
                  margin="dense"
                >
                  <InputLabel id="onlyForFirstTimeUsers-select">
                    Only for First Time Users
                  </InputLabel>
                  <Select
                    id="onlyForFirstTimeUsers"
                    label="Only for First Time Users"
                    labelId="onlyForFirstTimeUsers-select"
                    name="onlyForFirstTimeUsers"
                    value={this.state.onlyForFirstTimeUsers}
                    onChange={this.handleChange("onlyForFirstTimeUsers")}
                  >
                    <MenuItem value={"Yes"}>Yes</MenuItem>
                    <MenuItem value={"No"}>No</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              {/*<Grid item xs={6}>
                  <FormControl
                    variant="outlined"
                    style={{width:"100%"}}
                    margin="dense"
                  >
                  <InputLabel id="redemptionLimit-select">Redemption Limit*</InputLabel>
                  <Select
                    required
                    id="redemptionLimit"
                    label="Redemption Limit"
                    labelId="redemptionLimit-select"
                    name='redemptionLimit'
                    value={this.state.redemptionLimit}
                    onChange={this.handleChange('redemptionLimit')}
                  >
                    <MenuItem value={'Yes'}>Yes</MenuItem>
                    <MenuItem value={'No'}>No</MenuItem>
                  </Select>
                </FormControl>
                </Grid>
                {this.state.redemptionLimit === 'Yes' ?
                  <Grid item sm={6}>
                    <TextField
                      fullWidth
                      label='Limit*'
                      name='limit'
                      margin='dense'
                      type='number'
                      onChange={this.handleChange('limit')}
                      value={this.state.limit}
                      variant='outlined'/>
                  </Grid> : <div></div>
                }*/}
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  select
                  //fullWidth
                  style={{ minWidth: "49%" }}
                  required
                  margin="dense"
                  name="applicability"
                  id="applicability"
                  variant="outlined"
                  label="Applicability"
                  SelectProps={{
                    multiple: true,
                    value: this.state.applicability,
                    onChange: this.handleApplicabilityChange("applicability"),
                  }}
                >
                  <MenuItem value={"all"}>All Plans</MenuItem>
                  <MenuItem value={"allMonthly"}>All Monthly Plans</MenuItem>
                  <MenuItem value={"allAnnually"}>All Annually Plans</MenuItem>
                  <MenuItem value={listOfSubscriptions.PREMIUM_MONTHLY}>
                    Premium - Monthly
                  </MenuItem>
                  <MenuItem value={listOfSubscriptions.LITE_ANNUALLY}>
                    Lite - Annually
                  </MenuItem>
                  <MenuItem value={listOfSubscriptions.PREMIUM_ANNUALLY}>
                    Premium - Annually
                  </MenuItem>
                </TextField>
              </Grid>
            </Grid>
            <Grid container spacing={2}></Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleClear} color="primary">
            Clear
          </Button>
          <Button onClick={this.handleSubmit} color="secondary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

AddCoupon.propTypes = {
  item: PropTypes.object,
  open: PropTypes.bool,
  closePopUp: PropTypes.func,
  submitQuery: PropTypes.func,
  values: PropTypes.object,
};

export default AddCoupon;
