import React from 'react';

import { Grid } from '@material-ui/core';

import './speedAssessment.css';


function RoadMapComponent({ currentStep }) {

  return (<>
    <Grid
      container
      spacing={2}
      className="offer-roadmap"
    >
      <div className="offer-active-step">
        <span>1</span> Sign Up
      </div>
      <div
        className={currentStep > 1 ? 'offer-active-step' : 'offer-inactive-step'}
      >
        <span>2</span> Get Started
      </div>
    </Grid>
  </>);
}

export const RoadMap = RoadMapComponent;