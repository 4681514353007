import React, { } from 'react';
import {withRouter} from 'react-router-dom';
import PropTypes from 'prop-types';
import {Grid, Container, Avatar, Typography, CssBaseline, CircularProgress} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import UserInfoController from './user_info/UserInfoController';
//import ActivitiesController from './activities/ActivitiesController';
//import AddressController from './address/AddressController';
import { axiosWithToken, functionBaseUrl } from '../../common/firebase';
import firebase from '../../common/firebase';
import axios from 'axios';
import Swal from 'sweetalert2';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginBottom: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    marginTop: theme.spacing(8),
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  }
}));

const SignUp = (props) => {
  const stepMax = 1;
  const {history} = props;
  const classes = useStyles();
  /*const [address, setAddress] = React.useState({
    submitted: false,
    street_number: '',
    route: '',
    locality: '',
    administrative_area_level_1: '',
    country: '',
    postal_code: ''
  });*/
  const [values, setValues] = React.useState({
    loading: false,
    userInfo:{},
    step: 1,
    firstName: '',
    lastName: '',
    //weight: '',
    height: '',
    gender: '',
    //contact: '',
    dob: '',
    dominantHand: '',
    activityIds: [],
    showPassword: false,
  });

  const dobFormat = (dob) => {
    let [year, month, day] = dob.split('-');
    return (month + "/" + day + "/" + year)
  }

  const createUserEntry = () => {
    axios(functionBaseUrl + '/api/userMobile', {
      method: 'POST',
      data: {
        uid: values.userInfo.uid,
        firstName: values.firstName,
        lastName: values.lastName,
        userName: values.firstName + ' ' + values.lastName,
        email: values.userInfo.email,
        averageScore: 0,
        goals: [],
        dob: dobFormat(values.dob),
        height: values.height,
        hipSize: '7.2',
        gender: values.gender,
        dominantHand: values.dominantHand,
        picture: '',
        role: values.userInfo.role,
        weeklyPractices: 0,
        coachAddress: {city: "", state: "", country: ""},
        coachFacility: [{name: "", address: "", students: 0, lessons: 0, environment: []}],
        certification: [],
        userConsent: values.userInfo.userConsent
      }
    })
    .then((response) => {
      axiosWithToken(functionBaseUrl + '/api/acceptInvite/' + values.userInfo.email + '/' + values.userInfo.code, {
        method: 'POST'
      })
      setValues({loading:false});
      history.push('/');
    })
    .catch(err => {
      setValues({loading:false});
      console.log(err);
    });
  }
  const nextStep = () => {
    const currentStep = values.step;
    if (currentStep < stepMax) {
      setValues({...values, loading: false, step: currentStep+1 });
    }
  }
  const prevStep = () => {
    const currentStep = values.step;
    if (currentStep > 1) {
      setValues({...values, loading: false, step: currentStep-1 });
    }
  }
  const handleSignUp = () => {
    setValues({loading: true});
    firebase.auth().createUserWithEmailAndPassword(values.userInfo.email.toLowerCase().replace(/ /g, ''), values.userInfo.password)
        .then(creds => {
          values.userInfo.uid = creds.user.uid;
          createUserEntry();
          creds.user.sendEmailVerification();
        })
        .catch(err => {
          setValues({loading:false});
          Swal.fire({
            title: '<p style="font-size:70%;">' + err.message + '</p>',
            icon: 'warning',
            confirmButtonText: 'Ok',
            allowOutsideClick: false
          })
          .then(() => {
            if(err.code === 'auth/email-already-in-use') {
              history.push('/sign-in');
            }
            return false
          })
          //alert(err.message);
          //history.push('/');
          console.log(err.code);
          console.log(err.message);
        })
    /*if(values.userInfo.authOption === 'email'){
      
    } else if (values.userInfo.authOption === 'google' || values.userInfo.authOption === 'facebook') {
      createUserEntry();
    }
    else {
      setValues({loading:false});
      console.log('Authentication choice was never chosen');
    }*/
  };
  let page;

  if (values.step === 1 ) {
    page = <UserInfoController
      classes={classes}
      setValues={setValues}
      submitPage={handleSignUp}
      values={values}
    />;
  }
  /*else if (values.step === 2) {
    page = <AddressController
      classes={classes}
      prevStep={prevStep}
      setAddress={setAddress}
      setValues={setValues}
      submitPage={nextStep}
      values={values}
      address={address}
    />
  }
  else if (values.step === 2) {
    page = <ActivitiesController
      classes={classes}
      prevStep={prevStep}
      setValues={setValues}
      submitPage={handleSignUp}
      values={values}
    />
  }*/
  return (
    <div
      component="main"
    >
      <CssBaseline />
      <div
        style={{display: (values.loading) ? "none" : ""}}>
        {page}
      </div>
      <Grid
        style = {{margin : "auto", padding : 100, display: (values.loading) ? "block" : "none"}}
      >
        <CircularProgress/>
      </Grid>
    </div>
  );
};

SignUp.propTypes = {
  history: PropTypes.object,
};

export default withRouter(SignUp);