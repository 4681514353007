import React, { useState } from 'react';

import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Grid, TextField, 
  InputAdornment } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Swal from 'sweetalert2';

import { functionBaseUrl, axiosWithToken } from '../../common/firebase';

function EditStudioAccountComponent({ user, open, close }) {
  const [coachesNumber, setCoachesNumber] = useState(user?.studioAccount?.coachesCount);
  const [annualPricing, setAnnualPricing] = useState(user?.studioAccount?.annualPrice);
  const [baysCount, setBaysCount] = useState(user?.studioAccount?.baysCount);
  const [prosLicCount, setProsLicCount] = useState(user?.studioAccount?.prosLicCount);
  const [playersLicCount, setPlayersLicCount] = useState(user?.studioAccount?.playersLicCount);
  /*const [exception, setException] = useState(user?.studioAccount?.exception);
  const [note, setNote] = useState(user?.studioAccount?.note);*/

  const handleCoachesNumber = (e) => {
    setCoachesNumber(e.target.value);
  }
  const handleAnnualPricing = (e) => {
    setAnnualPricing(e.target.value);
  }
  const handleBaysNumber = (e) => {
    setBaysCount(e.target.value);
  }
  const handle3DProLicensesNumber = (e) => {
    setProsLicCount(e.target.value);
  }
  const handle3DPlayerLicensesNumber = (e) => {
    setPlayersLicCount(e.target.value);
  }
  /*const handleException = (e) => {
    setException(e.target.value);
  }
  const handleNote = (e) => {
    setNote(e.target.value);
  }*/
  const handleUpdate = async () => {
    if (!coachesNumber || !annualPricing) {
      close();
      Swal.fire('<p style="font-size:70%;">Please fill in all required fields</p>');
      return;
    }
    try {
      await axiosWithToken(`${functionBaseUrl}/api/studioUser/${user.studioAccountId}`, {
        method: 'patch',
        data: {
          accountInfo: {
            coachesCount: coachesNumber,
            annualPrice: annualPricing,
            baysCount: baysCount,
            prosLicCount: prosLicCount,
            playersLicCount: playersLicCount,
            //exception: exception,
            //note: note
          }
        }
      });
      close();
      Swal.fire({
        title: '<p style="font-size:70%;">Updated successfully</p>',
        icon: 'success',
        confirmButtonText: 'Ok',
        allowOutsideClick: false,
        timer: 2000
      });
    } catch (e) {
      close();
      Swal.fire('<p style="font-size:70%;">Error occured</p>');
    }
  }

  return (<>
    <Dialog
      fullWidth
      open={open}
      onClose={close}
      aria-labelledby='form-dialog-title'>
      <DialogTitle id={'form-dialog-title'}>
        <div>
          Edit Studio account
          <CloseIcon style={{ float: 'right', marginTop: '-10px', marginRight: '-15px' }} onClick={close} />
        </div>
      </DialogTitle>
      <DialogContent>
        <Grid
          container
          spacing={2}
        >
          <Grid
            item
            sm={6}
            xs={12}
          >
            <TextField
              autoComplete="email"
              fullWidth
              required
              disabled
              label="Email Address"
              value={user.email}
              variant="outlined"
              inputProps={{ maxLength: 254 }}
            />
          </Grid>
          <Grid
            item
            sm={6}
            xs={12}
          >
            <TextField
              fullWidth
              required
              label="# of Coaches Licenses"
              onChange={handleCoachesNumber}
              value={coachesNumber}
              variant="outlined"
            />
          </Grid>
          <Grid
            item
            sm={6}
            xs={12}
          >
            <TextField
              fullWidth
              required
              label="Annual Pricing"
              onChange={handleAnnualPricing}
              value={annualPricing}
              variant="outlined"
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
              }}
            />
          </Grid>
          <Grid
            item
            sm={6}
            xs={12}
          >
            <TextField
              fullWidth
              required
              label="# of Bays"
              onChange={handleBaysNumber}
              value={baysCount}
              variant="outlined"
            />
          </Grid>
          <Grid
            item
            sm={6}
            xs={12}
          >
            <TextField
              fullWidth
              required
              label="# of 3D Pro Licenses"
              onChange={handle3DProLicensesNumber}
              value={prosLicCount}
              variant="outlined"
            />
          </Grid>
          <Grid
            item
            sm={6}
            xs={12}
          >
            <TextField
              fullWidth
              required
              label="# of 3D Player Licenses"
              onChange={handle3DPlayerLicensesNumber}
              value={playersLicCount}
              variant="outlined"
            />
          </Grid>
          {/*<Grid
            item
            sm={6}
            xs={12}
          >
            <FormControl
              variant="outlined"
              fullWidth
              //margin="dense"
            >
              <InputLabel id="exception-select">Exception</InputLabel>
              <Select
                id="exception"
                label="Exception"
                labelId="exception-select"
                name="exception"
                defaultValue={exception}
                onChange={handleException}
              >
                <MenuItem value={true}>True</MenuItem>
                <MenuItem value={false}>False</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid
            item
            //sm={6}
            xs={12}
          >
            <TextField
              fullWidth
              label="Note"
              value={note}
              onChange={handleNote}
              variant="outlined"
              //inputProps={{ maxLength: 254 }}
            />
          </Grid>*/}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={close} color="primary">
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleUpdate}
        >
          UPDATE
        </Button>
      </DialogActions>
    </Dialog>
  </>);
}

export const EditStudioAccount = EditStudioAccountComponent;