/**
 * ListItem.js
 * Displays results from query
 * Includes UI elements for sorting and pagination
 */
import React, { Component } from "react";
import axios from "axios";
import firebase, { storageBucketURL } from "../../../../common/firebase";
import {
  Grid,
  MenuItem,
  Paper,
  Typography,
  Checkbox,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableSortLabel,
  TableContainer,
  Table,
  TablePagination,
  Button,
  Box,
  Menu,
  FormControlLabel,
  Divider,
  FormControl,
  InputLabel,
  Select,
} from "@material-ui/core";
import ItemRow from "./ItemRow";
import { Add, Search, YoutubeSearchedForOutlined } from "@material-ui/icons";
import { FieldMaps, parentChildMap } from "./AdvancedSearch";
import ExportDialog from "./ExportDialog";
import UnityPopUp from "./UnityPopUp";
import { analyzeVideo } from "../../util/VideoAnalysis";
import { SubscriptionLabel } from "./SubscriptionLabel";
import { TagDialog } from "./TagDialog";
import { LabelDialog } from "./LabelDialog";
import { AutoLabelDialog } from "./AutoLabelDialog";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import JSZip from "jszip";
import { CoachesPopUp } from "./CoachesPopUp";
const FileDownload = require("js-file-download");
const MySwal = withReactContent(Swal);

export const typeToLabel = {
  users: "User ",
  sessions: "Session ",
  videos: "Video ",
  analysis: "Analysis ",
  reportIssues: "Issues ",
  subscriptions: "Subscriptions ",
  invites: "Invites ",
  payments: "Payments ",
  userPosts: "User Posts ",
  partnerSubscriptions: "Partner Subs ",
  swingAnalysis: "Swing Analysis ",
};
const typeAdminLock = {
  subscriptions: false,
  users: false,
  sessions: false,
  analysis: true,
  videos: false,
  reportIssues: false,
  invites: false,
  partnerSubscriptions: false,
};

const getReportValues = (data) => {
  let values = [];

  values.push(data?.indicators?.LEGSPRCNTCONTRIB);
  values.push(data?.indicators?.COREPRCNTCONTRIB);
  values.push(data?.indicators?.SHLDRPRCNTCONTRIB);
  values.push(data?.indicators?.WRSTPRCNTCONTRIB);

  values.push(data?.indicators?.PLVTRNSPDMAX);
  values.push(data?.indicators?.CHSTTRNSPDMAX);
  values.push(data?.indicators?.LUARMSWNGSPDMAX);
  values.push(data?.indicators?.SHFTSWNGSPDMAX);

  values.push(data?.indicators?.CORESPDGAIN);
  values.push(data?.indicators?.SHLDRSPDGAIN);
  values.push(data?.indicators?.WRSTSPDGAIN);

  values.push(data?.indicators?.CORESPDGAINFCTR);
  values.push(data?.indicators?.SHLDRSPDGAINFCTR);
  values.push(data?.indicators?.WRSTSPDGAINFCTR);

  values.push(data?.indicators?.CHSTTRNROM);
  values.push(data?.indicators?.PLVTRNROM);
  values.push(data?.indicators?.CHSTSBNDROM);
  values.push(data?.indicators?.PLVSBNDROM);
  values.push(data?.indicators?.CHSTBNDROM);

  values.push(data?.indicators?.XFCTRMAXNRTOP);
  values.push(data?.indicators?.XFCTRSTRTCH);
  values.push(data?.indicators?.CHSTBNDTOP);
  values.push(data?.indicators?.CHSTSBNDMAX);
  values.push(data?.indicators?.SPNSBNDMAX);
  values.push(data?.indicators?.LDWRSTANGTOP);
  values.push(data?.indicators?.LDWRSTANGARMPARALLEL);

  values.push(data?.indicators?.LDWRSTANGARM30);
  values.push(data?.indicators?.CHSTTRNFIN);
  values.push(data?.indicators?.PLVTRNFIN);
  values.push(data?.indicators?.XFCTRFIN);
  values.push(data?.indicators?.SPNBNDFIN);
  values.push(data?.indicators?.SPNSBNDFIN);

  return values;
};

const getSpeedReportValues = (data) => {
  let values = [];

  values.push(data?.indicators?.PLVTRNSPDMAX);
  values.push(data?.indicators?.CHSTTRNSPDMAX);
  values.push(data?.indicators?.LUARMSWNGSPDMAX);
  values.push(data?.indicators?.SHFTSWNGSPDMAX);

  values.push(data?.indicators?.CHSTTRNMAXNRTOP);
  values.push(data?.indicators?.PLVTRNMAXNRTOP);
  values.push(data?.indicators?.XFCTRMAXNRTOP);
  values.push((data?.indicators?.HNDSPDMAX / 447).toFixed(2));

  values.push((data?.indicators?.PLVDRPMAXNRIMP / 25.4).toFixed(2));
  values.push((data?.indicators?.PLVLFTINTOIMP / 25.4).toFixed(2));
  values.push(data?.indicators?.LDWRSTANGARM30);

  values.push((data?.indicators?.WRSTRLSPRCNT * 100).toFixed(2));
  values.push((data?.indicators?.SWYGAPTOP / 25.4).toFixed(2));
  values.push((data?.indicators?.CHSTSLDTRANS / 25.4).toFixed(2));
  values.push((data?.indicators?.PLVSLDTRANS / 25.4).toFixed(2));
  values.push((data?.indicators?.CLBHDSPDMAX / 447).toFixed(2));

  return values;
};

function downloadJSONAsCSV(jsonData, filename) {
  // Convert JSON data to CSV
  let csvData = jsonToCsv(jsonData);

  // Create a CSV file and allow the user to download it
  let blob = new Blob([csvData], { type: "text/csv" });
  let url = window.URL.createObjectURL(blob);

  // Create a link element to simulate a click and trigger the download
  let a = document.createElement("a");
  a.href = url;
  a.download = filename + ".csv";
  document.body.appendChild(a);
  a.click();

  // Clean up: remove the link element
  document.body.removeChild(a);
}

function jsonToCsv(jsonData) {
  let csv = "";
  // Get the headers
  let headers = Object.keys(jsonData[0]);
  csv += headers.join(",") + "\n";
  // Add the data
  jsonData.forEach(function (row) {
    let data = headers.map((header) => JSON.stringify(row[header])).join(","); // Add JSON.stringify statement
    csv += data + "\n";
  });
  return csv;
}

class ListItems extends Component {
  constructor(props) {
    super(props);
    this.state = {
      headCells: {
        users: props.isAdmin
          ? [
              "users:fullName",
              "users:email",
              "users:userCreated",
              "users:subscriptionType",
            ]
          : ["users:fullName", "users:email", "users:userCreated"],
        sessions: [
          "sessions:sessionName",
          "sessions:sessionDate",
          "sessions:sessionEnv",
          "sessions:sessionType",
          "sessions:sessionPurpose",
          "sessions:temperature",
          "sessions:weatherDesc",
        ],
        videos: props.isAdmin
          ? [
              "videos:videoPath",
              "videos:videoOrigName",
              "videos:videoType",
              "videos:videoSource",
              "videos:metaData.cameraAngle",
              "sessions:sessionName",
              "videos:videoCreated",
              "analysis:swingConfidenceScore",
              "analysis:swingScore",
            ]
          : [
              "videos:videoPath",
              "videos:videoOrigName",
              "videos:videoType",
              "sessions:sessionName",
              "videos:videoCreated",
            ],
        reportIssues: [
          "reportIssues:videoURL",
          "videos:videoOrigName",
          "reportIssues:urgent",
          "reportIssues:createdDate",
          "reportIssues:comment",
          "users:fullName",
          "reportIssues:pose2D",
          "reportIssues:pose3D",
          "reportIssues:reviewed",
        ],
        subscriptions: [
          "subscriptions:userEmail",
          "subscriptions:userName",
          "subscriptions:startDate",
          "subscriptions:lastUpdated",
          "subscriptions:endDate",
          "subscriptions:productId",
          "subscriptions:autoRenewal",
          "subscriptions:platform",
        ],
        payments: [
          "payments:platform",
          "payments:paymentType",
          "payments:amount",
          "payments:paymentDate",
        ],
        invites: [
          "invites:invitee",
          "invites:senderName",
          "invites:createdAt",
          "invites:status",
        ],
        partnerSubscriptions: [
          "partnerSubscriptions:userEmail",
          "partnerSubscriptions:userName",
          "partnerSubscriptions:startDate",
          "partnerSubscriptions:lastUpdated",
          "partnerSubscriptions:endDate",
          "partnerSubscriptions:productId",
          "partnerSubscriptions:autoRenewal",
          "partnerSubscriptions:isAddOn",
        ],
      },
      selected: {
        users: {},
        sessions: {},
        videos: {},
        reportIssues: {},
        subscriptions: {},
        payments: {},
        invites: {},
        partnerSubscriptions: {},
      },
      changed: {},
      anchor: null,
      exportOpen: false,
      fileDownloadUrl: "",
      fileDownloadName: "",
      unityData: {
        open: false,
        data: null,
      },
      coachesData: {
        open: false,
        userId: null,
      },
      subTagDialog: false,
      tagDialog: false,
      labelDialog: false,
      autoLabelDialog: false,
      editAll: false,
      chunks: [],
      chunksLength: 0,
      totalFrames: 0,
    };
  }

  openCoaches = (userId) => {
    this.setState({ coachesData: { open: true, userId: userId } });
  };

  closeCoaches = () => {
    this.setState({ coachesData: { open: false, userId: null } });
    //window.location.reload();
  };

  openUnity = (data) => {
    const storageRef = firebase
        .storage()
        .ref()
        .child("/swing_data/" + data._id);
    storageRef
        .listAll()
        .then(async (res) => {
            for (const itemRef of res.items) {
                if (itemRef.name.includes("_ai.json")) {
                    const itemRes = await itemRef.getDownloadURL();
                    const response = await axios.get(itemRes, {
                        responseType: "json",
                    });
                    this.splitJSONData(JSON.stringify(response.data), 100000);
                    this.setState({ 
                        unityData: { open: true, data: response.data }, 
                        totalFrames: response.data?.response?.length,
                        chunksLength: JSON.stringify(response.data)?.length,
                    });
                }
            }
        })
        .catch((error) => {
            console.log(error);
        })
   
  };
  closeUnity = () => {
    this.setState({ unityData: { open: false, data: null } });
    window.location.reload();
  };

  splitJSONData(jsonData, chunkSize) {
    const tempChunks = [];
    let index = 0;
    
    while (index < jsonData.length) {
      tempChunks.push(jsonData.substring(index, index + chunkSize));
      index += chunkSize;
    }
    
    this.setState({ chunks: tempChunks });
  }

  //Analyzes videos and adds a delayed update in order to reflect changes on elastic
  analyzeVideo = (video, user) => {
    analyzeVideo(video, user).then(() =>
      setTimeout(this.props.handleDataChange, 2000)
    );
  };

  //Gets number of selected items
  getNumSelected = () => {
    const selected = this.state.selected[this.props.target];
    return this.props.results.hits.filter(
      (v) => v[this.props.target]?._id && selected[v[this.props.target]?._id]
    ).length;
  };

  //Changes what fields the items are sorted by
  sortCatagoryChange = (target, type, field) => (event) => {
    const sort = this.props.sort;
    const isAsc = sort[target].key === field && sort[target].dir === "asc";
    sort[target].key = field;
    sort[target].dir = isAsc ? "desc" : "asc";
    sort[target].type = type;
    sort.changed = true;
    this.props.handleSortChange(sort);
  };

  //Changes the number of items per page
  sortSizeChange = (event) => {
    let sort = this.props.sort;
    sort.pagination.size = event.target.value;
    this.props.handleSortChange(sort);
  };

  onSelectAll = (numSelected) => () => {
    const selected = JSON.parse(
      JSON.stringify(this.state.selected[this.props.target])
    );
    if (numSelected === this.props.sort.pagination.size) {
      //If all are selected, deselect all
      this.props.results.hits.forEach(
        (v) => delete selected[v[this.props.target]._id]
      );
      this.setState({
        selected: { ...this.state.selected, [this.props.target]: selected },
      });
    } else {
      //Adds all items in current page to selected
      this.props.results.hits.forEach((hit) => {
        const id = hit[this.props.target]._id;
        if (id) {
          selected[id] = hit;
        }
      });

      this.setState({
        selected: { ...this.state.selected, [this.props.target]: selected },
      });
    }
  };

  //Adds or removes a field from the headers of the table
  changeFieldOption = (field) => () => {
    const headCells = JSON.parse(
      JSON.stringify(this.state.headCells[this.props.target])
    );
    const index = headCells.indexOf(field);
    if (index === -1) headCells.push(field);
    else headCells.splice(index, 1);
    this.setState({
      ...this.state.headCells,
      headCells: { [this.props.target]: headCells },
    });
  };

  handleSelect = (id, data) => (event) => {
    const selected = JSON.parse(
      JSON.stringify(this.state.selected[this.props.target])
    );
    if (selected[id]) {
      delete selected[id];
    } else {
      selected[id] = data;
    }
    this.setState({
      selected: { ...this.state.selected, [this.props.target]: selected },
    });
  };

  //Downloads a blob with filename name
  downloadFile = (name, blob) => {
    const fileDownloadUrl = URL.createObjectURL(blob);
    this.setState(
      { fileDownloadUrl: fileDownloadUrl, fileDownloadName: name },
      () => {
        this.dofileDownload.click();
        URL.revokeObjectURL(fileDownloadUrl);
        this.setState({ fileDownloadUrl: "", fileDownloadName: "" });
      }
    );
  };
  downloadFileUrl = (name, url) => {
    this.setState({ fileDownloadUrl: url, fileDownloadName: name }, () => {
      this.dofileDownload.click();
      this.setState({ fileDownloadUrl: "", fileDownloadName: "" });
    });
  };

  //Download a file given a url with given file name
  downloadFileUrl = (name, url) => {
    axios.get(url, { responseType: "blob" }).then((res) => {
      this.downloadFile(name, res.data);
    });
  };
  //Returns elements for table had
  SortTableHead = () => {
    const currSort = this.props.sort[this.props.target];
    const numSelected = this.getNumSelected();
    return (
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox">
            <Checkbox
              color="primary"
              indeterminate={
                numSelected > 0 && numSelected < this.props.sort.pagination.size
              }
              checked={numSelected >= this.props.sort.pagination.size}
              onClick={this.onSelectAll(numSelected)}
            />
          </TableCell>
          <TableCell
            padding="none"
            style={{
              display:
                this.props.target === "videos" ||
                this.props.target === "reportIssues" ||
                this.props.target === "users"
                  ? "table-cell"
                  : "none",
            }}
          >
            Actions
          </TableCell>
          {this.state.headCells[this.props.target].map((headCell, index) => {
            const [type, field] = headCell.split(":");
            return (
              <TableCell
                key={headCell}
                sortDirection={
                  currSort.key === field && currSort.type === type
                    ? currSort.dir
                    : false
                }
              >
                {type === this.props.target ||
                FieldMaps[type][field].sortable ? (
                  <TableSortLabel
                    active={currSort.key === field && currSort.type === type}
                    direction={
                      currSort.key === field && currSort.type === type
                        ? currSort.dir
                        : "asc"
                    }
                    onClick={this.sortCatagoryChange(
                      this.props.target,
                      type,
                      field
                    )}
                  >
                    {/* If the field type is not the same as the target type, add a note displaying its type */}
                    {(type !== this.props.target ? typeToLabel[type] : "") +
                      FieldMaps[type][field]?.label}
                  </TableSortLabel>
                ) : (
                  (type !== this.props.target ? typeToLabel[type] : "") +
                  FieldMaps[type][field]?.label
                )}
              </TableCell>
            );
          })}
        </TableRow>
      </TableHead>
    );
  };

  handleChange = (prop) => (event) => {
    this.setState({ [prop]: event.target.value });
  };

  openSubTag = () => {
    this.setState({ subTagDialog: true });
  };

  closeSubTag = () => {
    this.setState({ subTagDialog: false });
    setTimeout(this.props.handleDataChange, 2000);
  };

  openTag = () => {
    this.setState({ tagDialog: true });
  };

  closeTag = () => {
    this.setState({ tagDialog: false });
    setTimeout(this.props.handleDataChange, 2000);
  };

  openLabel = () => {
    this.setState({ labelDialog: true });
  };

  closeLabel = () => {
    this.setState({ labelDialog: false });
    setTimeout(this.props.handleDataChange, 2000);
  };

  openAutoLabeling = () => {
    this.setState({ autoLabelDialog: true });
  };

  closeAutoLabeling = () => {
    this.setState({ autoLabelDialog: false });
    //setTimeout(this.props.handleDataChange, 2000);
  };

  openDownloadSwingData = () => {
    this.downloadSwingData(this.state.selected["videos"]);
  };

  enableEditAll = () => {
    this.setState({ editAll: true });
  };

  cancelEditAll = () => {
    this.setState({ editAll: false, changed: {} });
  };

  cancelEdit = (id) => {
    const changed = this.state.changed;
    delete changed[id];
    this.setState({ changed: changed });
  };

  handleChangedField = (id, field, value, original) => {
    const changed = this.state.changed;
    if (changed[id] && value === original) {
      delete changed[id][field];
      if (Object.keys(changed[id]).length === 0) {
        delete changed[id];
      }
    } else if (changed[id]) {
      changed[id][field] = value;
    } else {
      changed[id] = { [field]: value };
    }
    this.setState({ changed: changed });
  };

  updateItem = (id) => {
    if (this.state.changed[id]) {
      const data = this.state.changed[id];
      this.props.updateItem(id, data);
    }
  };

  checkSwingData = (doc) => {
    let folder = "id" in doc ? doc.id : doc._id;
    var storageRef = firebase
      .storage()
      .ref()
      .child("/swing_data/" + folder);
    storageRef
      .listAll()
      .then(function (res) {
        if (res.items.length < 3) {
          Swal.fire({
            icon: "error",
            text: "No analysis data found for this video",
            allowOutsideClick: true,
            showConfirmButton: false,
            timer: 2000,
            customClass: {
              container: "my-swal",
            },
          });
        } else {
          window.open(storageBucketURL + folder);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  downloadSwingData = async (swings) => {
    Swal.fire({
      title:
        '<p style="font-size:70%;">Downloading swing data...</p><br /><img width="10%" src="images/loading.gif" alt="Loading" />',
      allowOutsideClick: false,
      showConfirmButton: false,
      showCancelButton: false,
      showCloseButton: false,
      customClass: {
        container: "my-swal",
      },
    });

    const zip = new JSZip();

    // Array to hold all promises for fetching data
    const promises = [];

    for (const swing in swings) {
      const storageRef = firebase
        .storage()
        .ref()
        .child("/swing_data/" + swing);
      promises.push(
        storageRef
          .listAll()
          .then(async (res) => {
            for (const itemRef of res.items) {
              if (itemRef.name.includes("_ai.json")) {
                const itemRes = await itemRef.getDownloadURL();
                const response = await axios.get(itemRes, {
                  responseType: "blob",
                });
                // Add downloaded file to the zip
                zip.file(itemRef.name, response.data);
              }
            }
          })
          .catch((error) => {
            console.log(error);
          })
      );
    }

    // Wait for all promises to resolve
    await Promise.all(promises);

    // Generate the zip content
    const content = await zip.generateAsync({ type: "blob" });

    // Trigger the download of the zip file
    FileDownload(content, "swing_data.zip");
    Swal.close();
  };

  generateReport = (doc) => {
    Swal.fire({
      title:
        '<p style="font-size:70%;">Generating report...</p><br /><img width="10%" src="images/loading.gif" alt="Loading" />',
      allowOutsideClick: false,
      showConfirmButton: false,
      showCancelButton: false,
      showCloseButton: false,
      customClass: {
        container: "my-swal",
      },
    });
    let folder = "id" in doc ? doc.id : doc._id;
    var storageRef = firebase
      .storage()
      .ref()
      .child("/swing_data/" + folder);
    storageRef
      .listAll()
      .then(function (res) {
        if (res.items.length < 3) {
          Swal.fire({
            icon: "error",
            text: "No analysis data found for this video",
            allowOutsideClick: true,
            showConfirmButton: false,
            timer: 2000,
            customClass: {
              container: "my-swal",
            },
          });
        } else {
          for (const itemRef of res.items) {
            if (itemRef.name.includes("_ai.json")) {
              itemRef
                .getDownloadURL()
                .then(async (itemRes) => {
                  const report = await axios.get("report.json");
                  const speedReport = await axios.get("speed_report.json");

                  var config = {
                    method: "GET",
                    url: itemRes,
                    responseType: "JSON",
                  };
                  await axios(config)
                    .then((response) => {
                      if (
                        !("indicators" in response.data) ||
                        Object.keys(response.data.indicators).length === 0
                      ) {
                        Swal.fire({
                          title:
                            '<p style="font-size:70%;">No indicators data found for this video</p>',
                          icon: "warning",
                          confirmButtonText: "Ok",
                          allowOutsideClick: true,
                          customClass: {
                            container: "my-swal",
                          },
                        });
                        return;
                      } else {
                        let values = getReportValues(response.data);
                        for (let i = 0; i < report.data.length; i++) {
                          report.data[i].Value = values[i];
                        }
                        downloadJSONAsCSV(report.data, "report");
                        let speedValues = getSpeedReportValues(response.data);
                        for (let i = 0; i < speedReport.data.length; i++) {
                          speedReport.data[i].Value = speedValues[i];
                        }
                        downloadJSONAsCSV(speedReport.data, "speed_report");
                        Swal.fire({
                          title:
                            '<p style="font-size:70%;">Report successfully generated!</p>',
                          icon: "success",
                          confirmButtonText: "Ok",
                          allowOutsideClick: true,
                          timer: 2000,
                          customClass: {
                            container: "my-swal",
                          },
                        });
                      }
                    })
                    .catch(function (error) {
                      console.log(error);
                    });
                })
                .catch(function (error) {
                  console.log(error);
                });
              break;
            }
          }
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  //Determines if the target is a decendent of type. Also returns true if type is in the exceptions list of the target
  isLowerRank = (target, type) => {
    let helper = (target, type) => {
      const pcMap = parentChildMap[target];
      if (pcMap) {
        if (pcMap.parent === type) {
          return true;
        } else {
          return helper(pcMap.parent, type);
        }
      }
      return false;
    };
    if (parentChildMap[target]?.exceptions?.includes(type)) {
      return true;
    }
    return helper(target, type);
  };

  render() {
    return (
      <Box>
        {/* Menu for selecting table heads */}
        <Menu
          anchorEl={this.state.anchor}
          open={Boolean(this.state.anchor)}
          onClose={() => this.setState({ anchor: null })}
        >
          <Typography style={{ marginLeft: 10 }}>
            {typeToLabel[this.props.target]}
          </Typography>
          <Divider />

          {/* Display possible table head options for target type*/}
          {Object.entries(FieldMaps[this.props.target]).map(([key, value]) =>
            ((value.adminLock || typeAdminLock[this.props.target]) &&
              !this.props.isAdmin) ||
            value.hidden === "table" ? (
              <></>
            ) : (
              <MenuItem key={key} dense={true}>
                <FormControlLabel
                  margin="none"
                  size="small"
                  label={value.label}
                  style={{ marginBottom: -5, marginTop: -5 }}
                  control={
                    <Checkbox
                      checked={this.state.headCells[
                        this.props.target
                      ]?.includes(`${this.props.target}:${key}`)}
                      onClick={this.changeFieldOption(
                        `${this.props.target}:${key}`
                      )}
                    />
                  }
                />
              </MenuItem>
            )
          )}

          {/* Display possible table head options for types other than target*/}
          {Object.keys(FieldMaps).map((type) => {
            if (
              type !== this.props.target &&
              this.isLowerRank(this.props.target, type) &&
              (!typeAdminLock[type] || this.props.isAdmin)
            ) {
              return (
                <div key={type}>
                  <Typography style={{ marginLeft: 10 }}>
                    {typeToLabel[type]}
                  </Typography>
                  <Divider />
                  {Object.entries(FieldMaps[type]).map(([key, value]) => {
                    if (
                      ((!value.adminLock || this.props.isAdmin) &&
                        value.hidden !== "table" &&
                        this.props.target !== "reportIssues") ||
                      type === "reportIssues" ||
                      this.props.reportMappings?.[type]?.properties[key]
                    ) {
                      return (
                        <MenuItem key={key} dense={true}>
                          <FormControlLabel
                            margin="none"
                            size="small"
                            label={value.label}
                            style={{ marginBottom: -5, marginTop: -5 }}
                            control={
                              <Checkbox
                                checked={this.state.headCells[
                                  this.props.target
                                ]?.includes(`${type}:${key}`)}
                                onClick={this.changeFieldOption(
                                  `${type}:${key}`
                                )}
                              />
                            }
                          />
                        </MenuItem>
                      );
                    } else {
                      return null;
                    }
                  })}
                </div>
              );
            }
            return null;
          })}
        </Menu>

        {/* Export button */}
        <Grid container style={{ marginBottom: 10 }}>
          <Grid item xs={6} style={{ display: "flex" }}>
            <Button
              variant="contained"
              color="primary"
              endIcon={<Search />}
              size="small"
              style={{
                display: this.props.isMobile ? "none" : "auto",
                marginRight: 5,
              }}
              onClick={this.props.openSearch}
            >
              Search
            </Button>
            <Button
              style={{
                display:
                  Object.keys(this.state.selected[this.props.target]).length > 0
                    ? "block"
                    : "none",
                marginRight: 5,
              }}
              variant="contained"
              color="secondary"
              onClick={() => this.setState({ exportOpen: "selected" })}
            >
              {`Export ${
                Object.keys(this.state.selected[this.props.target]).length
              } Item(s)`}
            </Button>
            <Button
              style={{
                display:
                  this.props.isAdmin &&
                  this.props.target === "subscriptions" &&
                  Object.keys(this.state.selected[this.props.target]).length > 0
                    ? "block"
                    : "none",
                marginRight: 5,
              }}
              variant="contained"
              onClick={() => this.openSubTag()}
            >
              Add Subscription Label
            </Button>
            <Button
              style={{
                display:
                  this.props.isAdmin &&
                  this.props.target === "videos" &&
                  Object.keys(this.state.selected[this.props.target]).length > 0
                    ? "block"
                    : "none",
                marginRight: 5,
              }}
              variant="contained"
              onClick={() => this.openTag()}
            >
              Add Tag
            </Button>
            <SubscriptionLabel
              open={this.state.subTagDialog}
              close={this.closeSubTag}
              subscriptions={this.state.selected[this.props.target]}
            />
            <TagDialog
              open={this.state.tagDialog}
              close={this.closeTag}
              videos={this.state.selected[this.props.target]}
            />
            <Button
              style={{
                display:
                  this.props.isAdmin &&
                  this.props.target === "videos" &&
                  Object.keys(this.state.selected[this.props.target]).length > 0
                    ? "block"
                    : "none",
                marginRight: 5,
              }}
              variant="contained"
              onClick={() => this.openLabel()}
            >
              Add Label
            </Button>
            <Button
              style={{
                display:
                  this.props.isAdmin &&
                  this.props.target === "videos" &&
                  Object.keys(this.state.selected[this.props.target]).length > 0
                    ? "block"
                    : "none",
                marginRight: 5,
              }}
              variant="contained"
              onClick={() => this.openAutoLabeling()}
            >
              Run Auto-Labeling
            </Button>
            <Button
              style={{
                display:
                  this.props.isAdmin &&
                  this.props.target === "videos" &&
                  Object.keys(this.state.selected[this.props.target]).length > 0
                    ? "block"
                    : "none",
                marginRight: 5,
              }}
              variant="contained"
              onClick={() => this.openDownloadSwingData()}
            >
              Download Swing Data
            </Button>
            <LabelDialog
              open={this.state.labelDialog}
              close={this.closeLabel}
              videos={this.state.selected[this.props.target]}
            />
            <AutoLabelDialog
              open={this.state.autoLabelDialog}
              close={this.closeAutoLabeling}
              videos={this.state.selected["videos"]}
              //videosNames={this.state.selected[this.props.target].map(a => a.videoOrigName)}
              //videosLinks={this.state.selected[this.props.target].map(a => a.videoPath)}
            />
          </Grid>
          <Grid
            item
            xs={6}
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <Button
              size="small"
              style={{ display: this.props.isAdmin ? "block" : "none" }}
              onClick={() => this.props.openAdmin()}
            >
              Admin
            </Button>
            <Button
              style={{
                display:
                  this.props.isAdmin && !this.state.editAll ? "block" : "none",
                marginRight: 5,
              }}
              variant="contained"
              onClick={() => this.setState({ exportOpen: "all" })}
            >
              Export All
            </Button>
            <Button
              style={{
                display:
                  this.props.isAdmin && !this.state.editAll ? "block" : "none",
                marginRight: 5,
              }}
              variant="contained"
              onClick={() => this.enableEditAll()}
            >
              Edit All
            </Button>
            <Button
              style={{
                display:
                  this.props.isAdmin && this.state.editAll ? "block" : "none",
                marginRight: 5,
              }}
              variant="contained"
              color="secondary"
              onClick={() => this.cancelEditAll()}
            >
              Cancel Edits
            </Button>
            <Button
              style={{
                display:
                  this.props.isAdmin && this.state.editAll ? "block" : "none",
                marginRight: 5,
              }}
              variant="contained"
              color="primary"
              onClick={() => {
                this.setState({ changed: {}, editAll: false });
                this.props.updateMultipleItems(this.state.changed);
              }}
            >
              Submit Edits
            </Button>
            <Button
              variant="outlined"
              endIcon={<Add />}
              size="small"
              onClick={(event) =>
                this.setState({ anchor: event.currentTarget })
              }
            >
              More
            </Button>
          </Grid>
        </Grid>

        <Paper
          style={{
            display:
              this.props.results.hits?.length === 0 && !this.props.loading
                ? "none"
                : "block",
          }}
        >
          <TableContainer>
            <Table sx={{ minWidth: 750 }} size={"small"}>
              <this.SortTableHead />
              <TableBody
                style={{
                  opacity: this.props.loading ? "20%" : "100%",
                  pointerEvents: this.props.loading ? "none" : "auto",
                }}
              >
                {this.props.results.hits.map((item, index) => (
                  <ItemRow
                    key={index}
                    item={item}
                    isAdmin={this.props.isAdmin}
                    headCells={this.state.headCells[this.props.target]}
                    loading={this.props.loading}
                    selected={this.state.selected[this.props.target]}
                    handleSelect={this.handleSelect}
                    target={this.props.target}
                    openPopUp={this.openUnity}
                    openCoachesPopUp={this.openCoaches}
                    analyzeVideo={this.analyzeVideo}
                    checkSwingData={this.checkSwingData}
                    generateReport={this.generateReport}
                    downloadFileUrl={this.downloadFileUrl}
                    mappings={this.props.mappings}
                    updateItem={this.updateItem}
                    deleteItem={this.props.deleteItem}
                    editAll={this.state.editAll}
                    cancelEdit={this.cancelEdit}
                    changed={this.state.changed}
                    handleChangedField={this.handleChangedField}
                  />
                ))}
                <TableRow
                  key={"pagination"}
                  style={{
                    display:
                      this.props.results.hits?.length > 0
                        ? "table-row"
                        : "none",
                  }}
                >
                  <TablePagination
                    rowsPerPageOptions={this.props.sort.pagination.sizeOptions}
                    rowsPerPage={this.props.sort.pagination.size}
                    count={this.props.results.total}
                    page={this.props.sort.pagination.page - 1}
                    onChangePage={this.props.changePage}
                    onChangeRowsPerPage={this.sortSizeChange}
                  />
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
        <div
          style={{
            display:
              this.props.results.hits?.length === 0 && !this.props.loading
                ? "flex"
                : "none",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <YoutubeSearchedForOutlined
            color="primary"
            style={{ fontSize: 120 }}
          />
          <Typography>No items were found matching your search</Typography>
        </div>
        <ExportDialog
          open={this.state.exportOpen}
          isAdmin={this.props.isAdmin}
          handleClose={() => this.setState({ exportOpen: false })}
          selected={this.state.selected[this.props.target]}
          setSelected={(selected) =>
            this.setState({
              selected: {
                ...this.state.selected,
                [this.props.target]: selected,
              },
            })
          }
          target={this.props.target}
          headCells={this.state.headCells[this.props.target]}
          downloadFile={this.downloadFile}
          exportAll={this.props.exportAll}
        />
        <a
          style={{ display: "none" }}
          target="_blank"
          rel="noopener noreferrer"
          download={this.state.fileDownloadName}
          href={this.state.fileDownloadUrl}
          ref={(e) => (this.dofileDownload = e)}
        >
          download it
        </a>
        {this.state.chunks?.length > 0 && (<UnityPopUp
          open={this.state.unityData.open}
          onClose={this.closeUnity}
          chunks={this.state.chunks}
          chunksLength={this.state.chunksLength}
          chunksRef={this.state.chunks}
          chunksRefLength={this.state.chunksLength}
          totalFrames={this.state.totalFrames}
        />)}
        <CoachesPopUp
          open={this.state.coachesData.open}
          userId={this.state.coachesData.userId}
          onClose={this.closeCoaches}
        />
      </Box>
    );
  }
}

export default ListItems;
